import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createWallet, fetchUsers, setConfig} from "../../index";
import {Chip} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment/moment";
import Typography from "@mui/material/Typography";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const UserTable = (props) => {

    const dispatch = useDispatch();
    const users = useSelector((store) => store.users);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const filters = useSelector((store) => store.users?.config?.filters);

    useEffect(() => {
        fetchItems();
    },[filters]);

    const fetchItems = async () => {
        setIsLoading(true);

        await fetchUsers({dispatch, filters}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const handleRepair = async (user_id) => {
        await createWallet({dispatch, data:{user_id}}).then((res)=>{
            console.log(res)
        })
    }

    const WalletColumnRenderer = ({ params }) => {
        if (params.row.wallet) {
            return <Typography sx={{cursor:'pointer', color: '#996633'}}>Check Wallet</Typography>;
        } else {
            return <Typography sx={{cursor:'pointer', color: '#336699'}} onClick={() => handleRepair(params.id)}>Repair</Typography>;
        }
    };

    const columns = React.useMemo(
        () => [
            { field: 'user_id', headerName:'User ID', type: 'string', flex:1, minWidth:100 },
            { field: 'first_name', headerName:'First Name', type: 'string', flex:1, minWidth:100 },
            { field: 'last_name', headerName:'Last Name', type: 'string', flex:1, minWidth:100 },
            { field: 'role_type', headerName:'Role Type', type: 'string', flex:1, minWidth:100,
                renderCell: (params) => ( params.value === 'CLIENT' ? <StatusBall label={params.value} size="small" color="info" /> : <StatusBall label={params.value} size="small" color="warning" />)
            },

            { field: 'email', headerName:'Email', type: 'string', flex:1, minWidth: 220 },
            { field: 'wallet', headerName:'Wallet', type: 'string', flex:1, minWidth: 100,
                renderCell: (params) => <WalletColumnRenderer params={params} />,
            },
            { field: 'is_active', headerName:'Status', type: 'string', flex:1, minWidth: 120,
                renderCell: (params) => ( params.value === 1 ? <StatusBall label="Active" size="small" color="info" /> : <StatusBall label="Suspend" size="small" color="error" />)
            },
            { field: 'last_login_ip', headerName:'IP', type: 'string', flex:1, minWidth: 120 },
            { field: 'last_login', headerName:'Last Login At', type: 'dateTime', flex:1, minWidth:150,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            { field: 'last_seen', headerName:'Last Seen', type: 'dateTime', flex:1, minWidth:150,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', flex:1, minWidth:150,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth: 120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<VpnKeyIcon />}
                        label="Change Password"
                        onClick={props.changePassword(params.id)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={props.deleteItem(params.id)}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={props.editItem(params.id)}

                    />,
                ],
            },
        ],
        [props],
    );

    return (
        <DataGridPro
            autoHeight
            getRowId={(row) => row.user_id}
            columns={columns}
            rows={users.list}
            sx={{ border:'none'}}
            pagination
            paginationMode="server"
            paginationModel={filters}
            rowCount={users?.config?.total ?? 0}
            pageSizeOptions={[5, 10, 25, 100]}
            loading={isLoading}
            onPaginationModelChange={(params) => dispatch(setConfig({filters: {...filters, page:params.page, pageSize: params.pageSize}}))}
            onPageChange={(newPageNo) => dispatch(setConfig({filters: {...filters, pageNo: newPageNo}}))}
            onPageSizeChange={(newPageSize) => dispatch(setConfig({filters: {...filters, pageSize: newPageSize}}))}
            initialState={{
                columns:{
                    columnVisibilityModel:{
                        user_id:false,
                    }
                },
                sorting: {
                    sortModel: [{ field: 'group', sort: 'asc' }],
                },
                pinnedColumns: {  right: ['actions'] },
                pagination: { paginationModel: { ...filters } }
            }}
        />
    );
}

import * as React from 'react';
import {useDispatch} from "react-redux";
import {
    Button,
    DialogTitle,
    DialogContent,
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Tooltip, Table as TableUI
} from "@mui/material";
import {setModal} from "../../../../Common/App";
import DialogActions from "@mui/material/DialogActions";
import {useEffect} from "react";
import {fetchServices} from "../../../../Corporate/Services";
import {currencyFormatter} from "../../../../../Setup/Config";
import {styled} from "@mui/material/styles";

export const ServicesAtStatusModal = ({status}) => {

    const dispatch = useDispatch();
    const [response, setResponse] = React.useState([]);

    useEffect(() => {
        fetchData();
    },[status]);

    const fetchData = async () => {
        await fetchServices({dispatch, data:{status}}).then((res) => {
            if(res.success === true){
                setResponse(res?.data?.data?.data);
            }
        });
    }

    const Table = styled(TableUI)(({ theme }) => ({
        '& .link': {
            cursor: 'pointer',
            color: theme.palette.info.main,
            fontSize:'0.85rem',
        },
        '& .link:hover': {
            color: theme.palette.primary.dark,
        },
        '& .MuiTableCell-root': {  // Make sure this selector matches your TableCell class
            padding: '16px 6px',
        },
    }));

    const title = () => {
        switch(status[0]){
            case 'PENDING':
                return 'Business Analyst Team Queue';
            case 'VERIFIED':
                return "Credit Manager's Queue";
            default:
                return '';
        }
    }

    return (
        <>
            <DialogTitle>{title()}</DialogTitle>
            <DialogContent>
                {response.length === 0 ? <p>No data found</p> :
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>S No</TableCell>
                                <TableCell>Business Name</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Assigment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {response.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.service_no}</TableCell>
                                    <TableCell>{item.business?.name}</TableCell>
                                    <TableCell>{ currencyFormatter.format(item.amount)}</TableCell>
                                    <TableCell><Tooltip title={item.assigned_to?.email}>{(item.assigned_to?.first_name ?? "") + ' ' + (item.assigned_to?.last_name ?? "") ?? "-"}</Tooltip></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Close</Button>
            </DialogActions>
        </>
    );
}
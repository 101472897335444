import React,{ useRef, useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import {Grid, Stack, TextField, Typography } from "@mui/material";
import ContainerUI from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom";
import {doLogin, doLogout} from "../Redux/Action";
import {useDispatch} from "react-redux";
import {isBrowser} from "react-device-detect";
import {styled, useTheme} from "@mui/material/styles";
import Toastr from "../../../../Components/Elements/Toastr";
import PoweredBy from "../../../../Components/Elements/PoweredBy";

export const Logout = () => {
    return (
        <>{isBrowser ? <BrowserView /> : <MobileView />}</>
    )
}

const Container = styled(ContainerUI)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
    }
}));

const BrowserView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        doLogout(dispatch);
    }, [])


    return (
        <Grid item container xs={12} sx={{ height: '80vh'}}>

            <Grid item alignSelf={"center"} xs={12} md={4}>
                <Typography color={"#ffffff"} mb={3} sx={{ fontWeight: '300', fontSize:'30px', fontFamily:'Open Sans', lineHeight: '4rem' }} variant={"h3"}>Explore The World of Opportunities</Typography>
                <Typography variant={"h5"} sx={{ fontSize: '1.2rem' }} color={"#ffffff"}>What type of business are you forming?</Typography>
            </Grid>

            <Grid item container justifyContent={"center"} alignContent={"center"} xs={12} md={8} sx={{background:'#ffffff'}}>
                <Stack spacing={3} sx={{minWidth:400}}>

                    <Typography variant={"h3"} sx={{fontWeight:'300', fontSize:40, fontFamily:'Open Sans'}} color={"primary"}>Successfully Logged Out</Typography>

                    <Button color="primary" sx={{ width:'100%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={()=>navigate("/login")}>Sign In</Button>

                </Stack>
            </Grid>
        </Grid>
    )
}
const MobileView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef();
    const theme = useTheme();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState(false);

    useEffect(() => {
        //userRef.current.focus();
    }, [])

    const handleSubmit = () => {

        doLogin(dispatch, {username,password}).then((res) => {
            setResponse(res);
            if(res['success']){
                navigate('/dashboard');
            }
        });
    }

    return (<Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', }}>

                <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                    <Box sx={{flexGrow:2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={'/images/cm-logo.png'} width={150} />
                    </Box>

                    <Box sx={{p:2,flexGrow:0.75}}>
                        <Stack spacing={2} sx={{ minWidth: '310px' }}>
                            <TextField
                                type="text"
                                id="username"
                                ref={userRef}
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                required
                                label="Username"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                            <TextField
                                type="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                ref={userRef}
                                required
                                label="Password"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                            <Button color="primary" className={'text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Sign in</Button>

                            <Stack direction={"row"} spacing={2} justifyContent="space-between">
                                <Link to={'/forgot-password'} className={'mb-3 d-block text-right'}>Forgot Password</Link>
                                <Link to={'/register'}>Register Now</Link>
                            </Stack>
                            <Toastr response={response} />
                        </Stack>
                    </Box>
                    <Box sx={{p:2}}>
                        <PoweredBy />
                    </Box>
                </Stack>
            </Container>)
}


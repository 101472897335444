import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchPermissions, clearData, setConfig} from "../../";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import moment from "moment";

export const PermissionTable = (props) => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.permissions);
    const [isLoading, setIsLoading] = React.useState(true);
    const filters = useSelector((store) => store.permissions?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.permissions.config.pagination);
    const [response, setResponse] = React.useState({});

    useEffect(() => {
        dispatch(clearData());
    },[]);

    useEffect(() => {

        fetchItems();
    },[pagination]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchPermissions({dispatch, filters:{...pagination,...filters}}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const columns = React.useMemo(
        () => [
            { field: 'name', headerName:'Permissions Name', type: 'string', minWidth:300, flex: 1 },
            { field: 'label', headerName:'Label', type: 'string', minWidth: 300, flex:1 },
            { field: 'type', headerName:'Type', type: 'string', flex:1, minWidth: 130,
                renderCell: (params) => ( params.value === 'menu' ? <StatusBall label="Menu" size="small" color="primary" /> : <StatusBall label="Feature" size="small" color="warning" />)
            },
            { field: 'group', headerName:'Group', type: 'string', flex:1, minWidth: 180 },
            { field: 'is_active', headerName:'Status', flex:1, type: 'string', minWidth: 120,
                renderCell: (params) => ( params.value === 1 ? <StatusBall label="Active" size="small" color="info" /> : <StatusBall label="Suspend" size="small" color="error" />)
            },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', flex:1, minWidth:200,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            { field: 'updated_at', headerName:'Updated At', type: 'dateTime', minWidth:200, flex:1,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth: 120,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={props.deleteItem(params.id)}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={props.editItem(params.id)}
                    />,
                ],
            },
        ],
        [props.deleteItem, props.editItem],
    );

    return (
        <DataGridPro
            autoHeight
            getRowId={(row) => row.id}
            columns={columns}
            rows={items?.list ?? []}
            sx={{ border:'none'}}
            pagination
            paginationMode="server"
            paginationModel={pagination}
            rowCount={items?.config?.rowCount ?? 0}
            pageSizeOptions={[5, 10, 25, 100]}
            loading={isLoading}
            onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'group', sort: 'asc' }],
                },
                pinnedColumns: {  right: ['actions'] },
                pagination: { paginationModel: { ...pagination }}
            }}
        />
    );
}

import CONST from "./Constant";

const INITIAL_STATE = {
    selected:{},
    error: [],
    message:[],
    code:0,
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.FETCH_ITEM_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Profile loaded successfully"],
                code:action.payload.status,
            }
        case CONST.FETCH_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Profile updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_PROFILE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Bank details updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_BANK_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload.data.settings,
                error: [],
                message: ["Settings updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_EMPLOYMENT_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Employment details updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_EMPLOYMENT_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Address details updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_ADDRESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
import * as React from 'react';
import {
    Card as CardUI,
    CardContent,
    CardHeader,
    Paper,
    Table as TableUI,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {currencyFormatter} from "../../../../../Setup/Config";

export const FinancialsPanel = ({}) =>{

    const financials = useSelector(state => state.listings.selected.business?.financials) || [];

    // Extracting years from financial data
    // const years = financials.map(entry => entry.financial_year.split('-')[0]);
    const years = financials.map(entry => entry && entry.financial_year ? entry.financial_year.split('-')[0] : '');


    // Extracting unique years and sorting them in ascending order
    const uniqueYears = [...new Set(years)].sort((a, b) => a - b);

    // Function to get financial data for a specific metric and year
    const getFinancialData = (metric, year) => {
        //const entry = financials.find(data => data.financial_year.includes(year));
        const entry = financials && financials.find(data => data && data.financial_year && data.financial_year.includes(year));

        return entry ? currencyFormatter.format(entry[metric]) : "N/A";
    };

    // Define financial metrics
    const financialMetrics = [
        { name: 'Revenue', key: 'total_revenue' },
        { name: 'Gross Profit', key: 'gross_profit' },
        { name: 'Net Profit', key: 'net_profit' },
        { name: 'Equity', key: 'equity' },
        { name: 'Loan Liability', key: 'loan_liability' },
        { name: 'Trade Payable', key: 'trade_payable' },
        { name: 'Other Payable', key: 'other_payable' },
        { name: 'Trade Receivable', key: 'trade_receivable' },
        { name: 'Other Receivable', key: 'other_receivable' },
        { name: 'Stock', key: 'stock' },
        { name: 'Fixed Assets', key: 'fixed_assets' },
        { name: 'Bank Balance', key: 'bank_balance' },
        { name: 'Cash Balance', key: 'cash_balance' },
        { name: 'Staff Salary', key: 'staff_salary' }
    ];

    const Table = styled(TableUI)({
        '& .MuiTableCell-head':{
            fontWeight:'bold!important',
        },
        '& .MuiTableCell-root':{
            //borderBottom:'none',
        },
    });

    return (
        <TableContainer component={Paper}>
            <Box p={2}>
                <Typography fontSize={'1.1rem'}>Financials</Typography>
            </Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <caption>All the figures in AED</caption>
                <TableHead>
                    <TableRow>
                        <TableCell>Financial Metric</TableCell>
                        {uniqueYears.map(year => (
                            <TableCell align="right" key={year}>{year}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {financialMetrics.map(metric => (
                        <TableRow key={metric.name}>
                            <TableCell component="th" scope="row">{metric.name}</TableCell>
                            {uniqueYears.map(year => (
                                <TableCell align="right" key={year}>{getFinancialData(metric.key, year)}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


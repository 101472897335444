import React, { useState} from 'react'
import {useDispatch} from "react-redux";
import {
    TextField,
    DialogTitle,
    DialogContent,
    Alert,
    DialogActions,
    Button,
    Stack,
    DialogContentText
} from "@mui/material";
import { setModal } from "../../../../Common/App";
import { addRequest } from "../../../Requests"

export const AddFundPanel = () => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [amount, setAmount] = useState(0);
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        setDisabled(true);
        await addRequest({dispatch, data:{type:"DEPOSIT", amount}}).then((res)=>{
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    const handleCreditChange = (e) => {
        const newValue = parseFloat(e.target.value); // Convert input value to a number
        setDisabled(newValue <= 0);
        setAmount(newValue);
    }

    return(
        <>
            <DialogTitle>Add Funds</DialogTitle>
            <DialogContent sx={{paddingTop:'20px!important', paddingBottom:'0!important'}}>
                <Stack spacing={2}>
                    <DialogContentText>A reference number will be generated after the Add Fund request, please use the reference number while depositing or transfering the payment to our bank.</DialogContentText>

                    <TextField
                        placeholder={"Enter the amount"}
                        fullWidth
                        required
                        onBlur={handleCreditChange}
                        type={"number"}
                    />
                    {response.error && <Alert severity="error">{response.error.data.message}</Alert> }
                </Stack>
            </DialogContent>
            <DialogActions sx={{p:3}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Request</Button>
            </DialogActions>
        </>
    )
}

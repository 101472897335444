import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import {
    Stack, Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    List as ListUI, ListItem, ListItemText
} from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchAccount } from "../../Redux/Action";
import { styled } from "@mui/material/styles";
import { currencyFormatter } from "../../../../../Setup/Config";
import moment from "moment/moment";
import { StatusBall } from "../../../../../Components/Elements/StatusBall";
import { PaymentStatusChart } from "../Charts/PaymentStatusChart";
import { Link } from "react-router-dom";

export const AccountExpandDetails = ({ data, setPanelHeight }) => {

    const dispatch = useDispatch();
    const panelRef = useRef(null);
    const [item, setItem] = useState({});
    const subPayments = item?.sub_payments;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        await fetchAccount({ dispatch, id: data.account_id }).then((res) => {
            if (res.success) {
                setItem(res.data.data);
            }
            setIsLoading(false);
        });
    };

    useLayoutEffect(() => {

        if (panelRef.current) {
            setPanelHeight(panelRef.current.scrollHeight);
        }
    }, [item]);

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
        '& .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0,
            '& .MuiListItemText-root': {
                '& .MuiListItemText-primary': {
                    fontSize: 11,
                    color: 'rgba(0, 0, 0, 0.54)',
                },
                '& .MuiListItemText-secondary': {
                    fontSize: 12,
                    fontWeight: 500,
                    color: 'rgba(0, 0, 0, 0.87)',
                }
            }
        }
    });

    const status = {
        'PLANNED': 'warning',
        'PAID': 'success',
        'PARTIAL': 'warning',
        'OVERDUE': 'error',
        'EXTENDED': 'warning',
        'EXTENDED_PAID': 'success',
        'DELAYED': 'error',
        'DELAYED_PAID': 'success',
    };

    return (
        <Stack ref={panelRef}
               sx={{ height: 'auto', boxSizing: 'border-box' }}
               direction="column"
        >
            <Paper variant="outlined" square sx={{ flex: 1, mx: 'auto', width: '100%', p: 1, backgroundColor: '#efefef' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} md={3} alignContent={"center"}>
                                <PaymentStatusChart />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Payment Method" secondary={item?.payment_method ?? "-"} />
                                    </ListItem>
                                    { (item?.status && item.status === 'EXTENDED') && (
                                    <ListItem>
                                        <ListItemText primary="Initial Due Date" secondary={moment(item?.initial_due_date).format("DD/MM/YYYY") ?? "-"} />
                                    </ListItem>
                                    )}
                                    <ListItem>
                                        <ListItemText primary="Payment Type" secondary={item?.account_type?.name ?? "-"} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Service" secondary={ item?.service?.service_type?.name + '(' + item?.service?.service_no + ')' ?? "-"} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Description" secondary={item?.description ?? "-"} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Contract No" secondary={
                                            <Link to={`/business-finance/${item?.loan?.loan_id}`} target="_blank" color="primary">
                                                {item?.loan?.loan_no ?? "-"}
                                            </Link>
                                        } />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Cheque No" secondary={item?.cheque_no ?? "-"} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Cheque Date" secondary={moment(item?.cheque_date).format("DD/MM/YYYY") ?? "-"} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Remarks" secondary={item?.remarks ?? "-"} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="Contract Amount" secondary={currencyFormatter.format(item?.loan?.amount) ?? "-"} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Trx Type" secondary={item?.transaction_type ?? "-"} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Deposited In" secondary={item?.company_bank?.bank_name ?? "-"} />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    {subPayments && subPayments.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h4" fontWeight="bolder" py={2}>Payments</Typography>
                            <TableContainer component={Paper} variant="outlined">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell align="right">Discount</TableCell>
                                            <TableCell align="right">Due Date</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell align="right">Balance</TableCell>
                                            <TableCell align="right">Discount</TableCell>
                                            <TableCell align="right">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subPayments.map((row) => (
                                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.description}
                                                </TableCell>
                                                <TableCell align="right">{row.discount ?? "-"}</TableCell>
                                                <TableCell align="right">{moment(row.due_date).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell align="right">{currencyFormatter.format(row.amount)}</TableCell>
                                                <TableCell align="right">{currencyFormatter.format(row.balance)}</TableCell>
                                                <TableCell align="right">{currencyFormatter.format(row.discount)}</TableCell>
                                                <TableCell align="right">
                                                    <StatusBall
                                                        variant="contained"
                                                        label={row.status?.name}
                                                        color={status[row.status?.name]}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Stack>
    );
}

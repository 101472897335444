import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../../Common/App";
import {
    deleteBusiness,
} from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export const DeleteBusinessModal = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.business);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);
        await deleteBusiness({dispatch, data:{business_id : items.selected.business_id || ''}});
        dispatch(setModal({component:null, open:false}))
    };

    return(
        <>
            <DialogTitle>Delete Business</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to remove this business?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Delete Business</Button>
            </DialogActions>
        </>
    )
}

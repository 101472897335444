import React from "react";
import {
    FormControl, FormHelperText, Grid, InputLabel, Select, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {setSelectedPartner, updatePartner} from "../../index";
import MuiDatepicker from "../Datepicker";
import MenuItem from "@mui/material/MenuItem";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import Toastr from "../../../../../Components/Elements/Toastr";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import {PhoneField} from "../../../../../Components/Elements/PhoneField";

export const PartnerForm = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [showCompanyFields, setShowCompanyFields] = useState(false);


    useEffect(() => {
        setData(business.partners.selected);
    }, [])

    useEffect(() => {
        setShowCompanyFields(data?.type === 'Company');
    },[data]);

    const handleChange = (e) => {

        setDisabled(false);

        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updatePartner({dispatch, data}).then((res)=>{
            setResponse(res);
            if(res['success']){
                //closeAndClearForm();
            }
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component:null, open:false}));
        dispatch(setSelectedPartner(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Partner Details
            </DialogTitle>
            <DialogContent sx={{p:'1rem!important'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="add-user-form-label-office">Owner Type</InputLabel>
                                        <Select
                                            defaultValue={data?.type || ""}
                                            name="type"
                                            MenuProps={{
                                                style: {zIndex: 35001}
                                            }}
                                            fullWidth
                                            label="Owner Type"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <MenuItem key={"Agent"} value={'Agent'}>Agent</MenuItem>
                                            <MenuItem key={"Company"} value={'Company'}>Company</MenuItem>
                                            <MenuItem key={"Investor"} value={'Investor'}>Investor</MenuItem>
                                            <MenuItem key={"Manager"} value={'Manager'}>Manager</MenuItem>
                                            <MenuItem key={"Partner"} value={'Partner'}>Partner</MenuItem>
                                            <MenuItem key={"Signatory"} value={'Signatory'}>Signatory</MenuItem>
                                            <MenuItem key={"Sponsor"} value={'Sponsor'}>Sponsor</MenuItem>
                                            <MenuItem key={"Guarantor"} value={'Guarantor'}>Guarantor</MenuItem>
                                        </Select>
                                        <FormHelperText>{business?.error?.type}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={ "Owner Name" }
                                            name="name"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.name}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.name}
                                            onBlur={handleChange} />
                                    </Grid>
                                :
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={ "Company Name" }
                                            name="company_name"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.company_name}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.company_name}
                                            onBlur={handleChange} />
                                    </Grid>
                                }

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        type={"number"}
                                        required
                                        label="Shares Percentage Holding"
                                        name="holding"
                                        inputProps={{
                                            min: 0, max: 100,
                                            autoComplete: 'none'
                                        }}
                                        defaultValue={data?.holding}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.holding}
                                        onBlur={handleChange} />
                                </Grid>

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <MuiDatepicker error={business?.error?.date_of_birth} setDisabled={setDisabled} label={"Date of Birth"} name="date_of_birth" past={true} data={data} state={setData} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <MuiDatepicker error={business?.error?.license_issue_date} setDisabled={setDisabled} label={"License Issue Date"} name="license_issue_date" past={true} data={data} state={setData} />
                                    </Grid>
                                }

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <SelectCountry error={business?.error?.nationality} disabled={disabled} label={"Nationality"} name={"nationality"} data={data} state={setData} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <SelectCountry error={business?.error?.license_issue_country} disabled={disabled} label={"License Issue Country"} name={"license_issue_country"} data={data} state={setData} />
                                    </Grid>
                                }

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={ "Passport No" }
                                            name="passport_no"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.passport_no}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.passport_no}
                                            onBlur={handleChange} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={ "License No" }
                                            name="license_no"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.license_no}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.license_no}
                                            onBlur={handleChange} />
                                    </Grid>
                                }

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <MuiDatepicker error={business?.error?.passport_expiry_date} setDisabled={setDisabled} label={"Passport Expiry Date"} name="passport_expiry_date" past={false} data={data} state={setData} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <MuiDatepicker error={business?.error?.license_expiry_date} setDisabled={setDisabled} label={"License Expiry Date" } name="license_expiry_date" past={false} data={data} state={setData} />
                                    </Grid>
                                }

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <AttachDoc doc_expiry={data?.passport_expiry_date} setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','jpeg','png']} onBlur={handleChange} label={"Passport Copy"} entity_name={"partners"} entity_id={data?.id} name="passport_doc" data={data} state={setData} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <AttachDoc doc_expiry={data?.license_expiry_date} setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','jpeg','png']} onBlur={handleChange} label={"License Copy"} entity_name={"partners"} entity_id={data?.id} name="license_doc" data={data} state={setData} />
                                    </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-residential_status">Residential Status</InputLabel>
                                        <Select
                                            defaultValue={data?.residential_status || ""}
                                            name="residential_status"
                                            MenuProps={{
                                                style: {zIndex: 35001}
                                            }}
                                            fullWidth
                                            label="residential_status"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <MenuItem key={"Resident"} value={'Resident'}>Resident</MenuItem>
                                            <MenuItem key={"Non-Resident"} value={'Non-Resident'}>Non-Resident</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <MuiDatepicker error={business?.error?.in_uae_since}  setDisabled={setDisabled} label={"Residing in UAE Since"} name="in_uae_since" past={true} data={data} state={setData} />
                                </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Visa No"
                                        name="visa_no"
                                        hidden={auth.user.role_type === 'Client'}
                                        inputProps={{
                                            autoComplete: 'none'
                                        }}
                                        defaultValue={data?.visa_no}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.visa_no}
                                        onBlur={handleChange} />
                                </Grid>
                                }

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={ "Visa Sponsor Name" }
                                            name="visa_issued_by"
                                            hidden={auth.user.role_type === 'Client'}
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.visa_issued_by}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.visa_issued_by}
                                            onBlur={handleChange} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={ "Shareholder Name" }
                                            name="shareholder_name"
                                            hidden={auth.user.role_type === 'Client'}
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.shareholder_name}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.shareholder_name}
                                            onBlur={handleChange} />
                                    </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <MuiDatepicker error={business?.error?.visa_expiry_date} past={false} setDisabled={setDisabled} label={"Visa Expiry Date"} name="visa_expiry_date" data={data} state={setData} />
                                </Grid>
                                }

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <AttachDoc doc_expiry={data?.visa_expiry_date} setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','jpeg','png']} onBlur={handleChange} label={"Visa Copy"} entity_name={"partners"} entity_id={data?.id} name="visa_doc" data={data} state={setData} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <AttachDoc doc_expiry={data?.passport_expiry_date} setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','jpeg','png']} onBlur={handleChange} label={"Shareholder Passport"} entity_name={"partners"} entity_id={data?.id} name="shareholder_passport_doc" data={data} state={setData} />
                                    </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Emirates ID Number"
                                        name="eid_no"
                                        hidden={auth.user.role_type === 'Client'}
                                        inputProps={{
                                            autoComplete: 'none'
                                        }}
                                        defaultValue={data?.eid_no}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.eid_no}
                                        onBlur={handleChange} />
                                </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <MuiDatepicker error={business?.error?.eid_expiry_date} setDisabled={setDisabled} label={"Emirates ID Expiry Date"} past={false} name="eid_expiry_date" data={data} state={setData} />
                                </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <AttachDoc doc_expiry={data?.eid_expiry_date} setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','jpeg','png']} onBlur={handleChange} label={"Emirates ID Copy"} entity_name={"partners"} entity_id={data?.id} name="eid_doc" data={data} state={setData} />
                                </Grid>
                                }

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <PhoneField
                                            fullWidth
                                            label="Mobile No"
                                            name="mobile"
                                            type="tel"
                                            placeholder={"e.g. 9714XXXXXXX"}
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            size="large"
                                            defaultValue={data?.mobile || ""}
                                            helperText={business.error?.mobile}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <PhoneField
                                            fullWidth
                                            label="Contact No"
                                            name="contact_no"
                                            type="tel"
                                            placeholder={"e.g. 9714XXXXXXX"}
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            size="large"
                                            defaultValue={data?.contact_no || ""}
                                            helperText={business.error?.contact_no}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        inputProps={{
                                            autoComplete: 'none'
                                        }}
                                        defaultValue={data?.email}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.email}
                                        onBlur={handleChange} />
                                </Grid>

                                { !showCompanyFields ?
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={"Current Address"}
                                            name="current_address"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.current_address}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.current_address}
                                            onBlur={handleChange} />
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label={"Company Address"}
                                            name="company_address"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.company_address}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.company_address}
                                            onBlur={handleChange} />
                                    </Grid>
                                }

                                <Grid item xs={12} md={6}>
                                    <AttachDoc setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','jpeg','png']} onBlur={handleChange} label={!showCompanyFields ? "Residence Utility Bill" : "Utility Bill"} entity_name={"partners"} entity_id={data?.id} name="current_utility_bill_doc" data={data} state={setData} />
                                </Grid>

                                { auth.user.role_type !== 'CLIENT' &&
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Home Country Address"
                                        name="home_address"
                                        inputProps={{
                                            autoComplete: 'none'
                                        }}
                                        defaultValue={data?.home_address}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.home_address}
                                        onBlur={handleChange} />
                                </Grid>
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                    <Grid item xs={12} md={6}>
                                        <AttachDoc setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','jpeg','png']} onBlur={handleChange} label={"Home Country Utility Bill"} entity_name={"partners"} entity_id={data?.id} name="home_utility_bill_doc" data={data} state={setData} />
                                    </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Owner AECB Score"
                                        name="aecb_score"
                                        inputProps={{
                                            autoComplete: 'none'
                                        }}
                                        defaultValue={data?.aecb_score}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.aecb_score}
                                        onBlur={handleChange} />
                                </Grid>
                                }

                                { !showCompanyFields &&
                                <Grid item xs={12} md={6}>
                                    <MuiDatepicker error={business?.error?.aecb_score_date} setDisabled={setDisabled} onBlur={handleChange} label={"Owner AECB Report Date"} past={true} name="aecb_score_date" data={data} state={setData} />
                                </Grid>
                                }

                                <Grid item xs={12} md={6}>
                                    <AttachDoc setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf']} onBlur={handleChange} label={!showCompanyFields ? "Owner AECB Report" : "AECB Report"} entity_name={"partners"} entity_id={data?.id} name="aecb_score_doc" data={data} state={setData} />
                                </Grid>

                                { auth.user.role_type !== 'CLIENT' &&
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Life Insurance Value"
                                            name="life_insurance_value"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.life_insurance_value}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.life_insurance_value}
                                            onBlur={handleChange} />
                                    </Grid>
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Personal Net Worth"
                                            name="personal_net_worth"
                                            inputProps={{
                                                autoComplete: 'none'
                                            }}
                                            defaultValue={data?.personal_net_worth}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={business?.error?.personal_net_worth}
                                            onBlur={handleChange} />
                                    </Grid>
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                    <Grid item xs={12} md={6}>
                                        <AttachDoc setResponse={setResponse} updateFunction={updatePartner} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Personal Net Worth Doc"} entity_name={"partners"} entity_id={data?.id} name="personal_net_worth_doc" data={data} state={setData} />
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}


import {Card, CardContent, CardHeader, List as ListUI, ListItem, ListItemText} from "@mui/material";
import * as React from "react";
import styled from "@mui/material/styles/styled";
export const WalletNoticePanel = () => {


    const List = styled(ListUI)({
        '& .MuiListItem-root': {
            paddingLeft: 0,
        }
    })

    return (
        <Card>
            <CardHeader title={"Important Notice"} />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText primary={"Please ensure that your Beehive reference # is included in the description field so we can quickly credit your account."} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"If you would like to add more than AED 500,000, please contact the Beehive team at accounts@ifundfactoring.com and we’ll help you with the transfer."} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Please allow 1-3 business days for funds to be processed and credited to your account. All funds credited are net of any bank fees.\n"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Please note, while cash is not acceptable, you may add funds via an issued cheque from your nominated bank account"} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}

import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect, useRef} from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {setAppBarHeight} from "../../Modules/Common/App";
import {BusinessAccountMenu} from "../Menu/BusinessAccountMenu";
import {Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {BusinessMainMenu} from "../Menu/BusinessMainMenu";
import {isBrowser} from "react-device-detect";
import {BusinessNotificationMenu} from "../Menu/BusinessNotificationMenu";
import {Container} from "./Container";

export const AppBarBusiness = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);

    const AppBar = styled(MuiAppBar)(({ theme }) => ({
        background: theme.appBarPublic.backgroundColor,
        zIndex: theme.zIndex.drawer + 1,
        height: 80,
        '& .MuiToolbar-root': {
            minHeight: 80,
        },
    }));

    const appBarRef = useRef(null)

    //To get the height of appbar and use it somewhere.
    useEffect(()=>{
        dispatch(setAppBarHeight(appBarRef.current.clientHeight));
    },[])

    return (
        <AppBar position="fixed" ref={appBarRef}>
            <Container maxWidth={'xl'} styles={{p:'0!important', margin:'0 auto'}}>
                <Toolbar>
                    <Stack direction={"row"}>
                        <Link to="/">
                            <Box component="img" src={'/images/ifund-logo.png'} alt="Logo" sx={{ height: '44px', marginRight: '16px' }} />
                        </Link>
                        <Typography color={"primary"} sx={{paddingTop:{md:'20px', xs:'0'}}}>{auth.user.business}</Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { md: 'flex' } }}>
                        <Stack direction={"row"} spacing={1}>
                            { isBrowser &&  <BusinessMainMenu /> }
                            <BusinessNotificationMenu />
                            <BusinessAccountMenu />
                        </Stack>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

const CONST = {
    'SET_CONFIG':'SERVICES/SET_CONFIG',
    'RESET_ALL':'SERVICES/RESET_ALL',
    'SET_SELECTED_SERVICE':'SERVICES/SET_SELECTED_SERVICE',
    'SET_SELECTED_SERVICE_SUCCESS':'SERVICES/SET_SELECTED_SERVICE_SUCCESS',
    'FETCH_SERVICE_SUCCESS':'SERVICES/FETCH_SERVICE_SUCCESS',
    'FETCH_SERVICE_FAILED':'SERVICES/FETCH_SERVICE_FAILED',
    'FETCH_SERVICE_CHARGES_SUCCESS':'SERVICES/FETCH_SERVICE_CHARGES_SUCCESS',
    'FETCH_SERVICE_CHARGES_FAILED':'SERVICES/FETCH_SERVICE_CHARGES_FAILED',
    'FETCH_SERVICES_SUCCESS':'SERVICES/FETCH_SERVICES_SUCCESS',
    'FETCH_SERVICES_FAILED':'SERVICES/FETCH_SERVICES_FAILED',
    'ADD_SERVICE_SUCCESS':'SERVICES/ADD_SERVICE_SUCCESS',
    'ADD_SERVICE_FAILED':'SERVICES/ADD_SERVICE_FAILED',
    'ADD_SERVICE_LISTING_SUCCESS':'SERVICE/ADD_SERVICE_LISTING_SUCCESS',
    'ADD_SERVICE_LISTING_FAILED':'SERVICE/ADD_SERVICE_LISTING_FAILED',
    'ADD_INVOICE_SERVICE_SUCCESS':'SERVICES/ADD_INVOICE_SERVICE_SUCCESS',
    'ADD_INVOICE_SERVICE_FAILED':'SERVICES/ADD_INVOICE_SERVICE_FAILED',
    'UPDATE_SERVICE_SUCCESS':'SERVICES/UPDATE_SERVICE_SUCCESS',
    'UPDATE_SERVICE_FAILED':'SERVICES/UPDATE_SERVICE_FAILED',
    'VERIFY_SERVICE_SUCCESS':'SERVICES/VERIFY_SERVICE_SUCCESS',
    'VERIFY_SERVICE_FAILED':'SERVICES/VERIFY_SERVICE_FAILED',
    'LIST_SERVICE_SUCCESS':'SERVICES/LIST_SERVICE_SUCCESS',
    'LIST_SERVICE_FAILED':'SERVICES/LIST_SERVICE_FAILED',
    'FUND_SERVICE_SUCCESS':'SERVICES/FUND_SERVICE_SUCCESS',
    'FUND_SERVICE_FAILED':'SERVICES/FUND_SERVICE_FAILED',
    'APPROVE_SERVICE_SUCCESS':'SERVICES/APPROVE_SERVICE_SUCCESS',
    'APPROVE_SERVICE_FAILED':'SERVICES/APPROVE_SERVICE_FAILED',
    'UPDATE_SERVICE_CHARGE_SUCCESS':'SERVICES/UPDATE_SERVICE_CHARGE_SUCCESS',
    'UPDATE_SERVICE_CHARGE_FAILED':'SERVICES/UPDATE_SERVICE_CHARGE_FAILED',
    'DELETE_SERVICE_SUCCESS':'SERVICES/DELETE_SERVICE_SUCCESS',
    'DELETE_SERVICE_FAILED':'SERVICES/DELETE_SERVICE_SUCCESS',
}

export default CONST;
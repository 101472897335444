import * as React from 'react';
import {CardContent, CardHeader, Collapse, IconButton, Stack, Typography} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useState} from "react";
import {Panel, PanelList} from "../../../../../Components";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import moment from "moment/moment";

export function BusinessPropertiesPanel() {

    const auth = useSelector((state) => state.auth);
    const business = useSelector((state) => state.business.selected);
    const [isExpanded, setIsExpanded] = useState(false);

    const statusDecoder = {
        "DRAFT": { name: "Draft", org_name: "Draft", color: "primary" },
        "SUBMITTED": { name: "Submitted", org_name: "Submitted", color: "secondary" },
        "VERIFIED": { name: "Verified", org_name: "Verified", color: "info" },
        "BH_APPROVED": { name: "In Progress", org_name: "Business Head Approval", color: "warning" },
        "REVIEWED": { name: "Reviewed", org_name: "Reviewed", color: "info" },
        "CREDIT_APPROVED": { name: "In Progress", org_name: "Credit Approved", color: "success" },
        "CEO_APPROVED": { name: "In Progress", org_name: "CEO Approved", color: "info" },
        "BOD_APPROVED": { name: "In Progress", org_name: "Board of Director Approved", color: "warning" },
        "CHAIRMAN_APPROVED": { name: "Approved", org_name:"Chairman Approved", color: "success" }, //TODO: this is extra, remove this
        "APPROVED": { name: "Approved", org_name: "Approved", color: "success" },
        "DECLINED": { name: "Rejected", org_name: "Declined", color: "error" },
        "DELETED": { name: "Deleted", org_name: "Deleted", color: "default" }
    };

    return (
        <>
            { business !== undefined &&
                <Panel>
                    <CardHeader title={"Properties"} titleTypographyProps={{variant: 'headline'}}
                        action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }
                    />
                    <Collapse in={isExpanded}>
                        <CardContent>
                            <PanelList>
                                <ListItem>
                                    <ListItemText primary={"Status"} />
                                    <StatusBall color={statusDecoder[business?.status]?.color} label={auth.user.role_type === 'CLIENT' ? statusDecoder[business?.status]?.name : business?.status} size={"small"} />
                                </ListItem>
                                {(business?.is_returned === 1) &&
                                    <ListItem>
                                        <ListItemText primary={"Returned"} secondary={ business?.reason } />
                                    </ListItem>
                                }
                                {business?.proposal &&
                                    <ListItem>
                                        <ListItemText primary={"Proposal No"} secondary={ business?.proposal?.proposal_no } />
                                    </ListItem>
                                }

                                {(business?.assigned_to !== null && business?.assigned_to !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Assigned To"} secondary={
                                            <Stack direction={"column"}>
                                                <Stack direction={"row"} justifyContent={"space-between"}>
                                                    <Stack>
                                                        <Typography sx={{fontWeight:'400!important'}}>By {business.assigned_to?.first_name + ' ' + business.assigned_to?.last_name}</Typography>
                                                        <Typography color={"primary"} sx={{fontWeight:'400!important', fontSize:11}}> {business.assigned_to.email}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        } />
                                    </ListItem>
                                }
                                {business?.proposal?.status && (
                                    business.proposal.status.map((s, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <ListItemText primary={statusDecoder[s.status?.name]?.org_name} secondary={
                                                    <Stack direction={"column"}>
                                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                                            <Stack>
                                                                <Typography sx={{fontWeight:'400!important'}}>By {s.created_by?.first_name + ' ' + s.created_by?.last_name}</Typography>
                                                                <Typography color={"primary"} sx={{fontWeight:'400!important', fontSize:11}}> {s.created_by?.email}</Typography>
                                                            </Stack>
                                                            <Typography sx={{fontWeight:'400!important'}}>{moment(s.created_at).diff(moment(), 'minutes') < 10 ? moment(s.created_at).fromNow() : moment(s.created_at).format("DD/MM/YYYY H:m")}</Typography>
                                                        </Stack>
                                                    </Stack> ?? ""} />
                                            </ListItem>
                                        )
                                    })
                                )}
                                {(business?.created_by !== null && business?.created_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Created By"} secondary={
                                            <Stack direction={"column"}>
                                                <Stack direction={"row"} justifyContent={"space-between"}>
                                                    <Stack>
                                                        <Typography sx={{fontWeight:'400!important'}}>By {business.created_by.first_name + ' ' + business.created_by.last_name}</Typography>
                                                        <Typography color={"primary"} sx={{fontWeight:'400!important', fontSize:11}}> {business.created_by.email}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        } />
                                    </ListItem>
                                }
                                {(business?.updated_by !== null && business?.updated_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Last Updated By"} secondary={
                                            <Stack direction={"column"}>
                                                <Stack direction={"row"} justifyContent={"space-between"}>
                                                    <Stack>
                                                        <Typography sx={{fontWeight:'400!important'}}>By {business.updated_by.first_name + ' ' + business.updated_by.last_name}</Typography>
                                                        <Typography color={"primary"} sx={{fontWeight:'400!important', fontSize:11}}> {business.updated_by.email}</Typography>
                                                    </Stack>
                                                    <Typography sx={{fontWeight:'400!important'}}>{moment(business.updated_by).diff(moment(), 'minutes') < 10 ? moment(business.updated_by).fromNow() : moment(business.updated_by).format("DD/MM/YYYY H:m")}</Typography>
                                                </Stack>
                                            </Stack>
                                        } />
                                    </ListItem>
                                }
                            </PanelList>
                        </CardContent>
                    </Collapse>
                </Panel>
            }
        </>
    );
}



const CONST = {
    'FETCH_ITEM_SUCCESS':'PROFILE/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'PROFILE/FETCH_ITEM_FAILED',
    'UPDATE_PROFILE_SUCCESS':'PROFILE/UPDATE_PROFILE_SUCCESS',
    'UPDATE_PROFILE_FAILED':'PROFILE/UPDATE_PROFILE_FAILED',
    'UPDATE_BANK_DETAILS_SUCCESS':'PROFILE/UPDATE_BANK_DETAILS_SUCCESS',
    'UPDATE_BANK_DETAILS_FAILED':'PROFILE/UPDATE_BANK_DETAILS_FAILED',
    'UPDATE_SETTINGS_SUCCESS':'PROFILE/UPDATE_SETTINGS_SUCCESS',
    'UPDATE_SETTINGS_FAILED':'PROFILE/UPDATE_SETTINGS_FAILED',
    'UPDATE_ADDRESS_SUCCESS':'PROFILE/UPDATE_ADDRESS_SUCCESS',
    'UPDATE_ADDRESS_FAILED':'PROFILE/UPDATE_ADDRESS_FAILED',
    'UPDATE_EMPLOYMENT_SUCCESS':'PROFILE/UPDATE_EMPLOYMENT_SUCCESS',
    'UPDATE_EMPLOYMENT_FAILED':'PROFILE/UPDATE_EMPLOYMENT_FAILED',
}

export default CONST;
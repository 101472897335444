import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

/****************************************
 SET SELECTED ADDRESS
 ****************************************/
export const setSelectedAddress = (payload) => ({
    type: CONST.SET_SELECTED_ADDRESS,
    payload: payload
});

/****************************************
 FETCH ADDRESS
 ****************************************/
export const fetchAddressesSuccess = (payload) => ({
    type: CONST.FETCH_ADDRESSES_SUCCESS,
    payload: payload
});

export const fetchAddressesFailed = (payload) => ({
    type: CONST.FETCH_ADDRESSES_FAILED,
    payload: payload
});

export const fetchAddresses = async ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/address`),
        [fetchAddressesSuccess],
        [fetchAddressesFailed]
    );
};

/****************************************
 ADD Business ADDRESS
 ****************************************/
export const addAddressSuccess = (payload) => ({
    type: CONST.ADD_ADDRESS_SUCCESS,
    payload: payload
});

export const addAddressFailed = (payload) => ({
    type: CONST.ADD_ADDRESS_FAILED,
    payload: payload
});

export const addAddress = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/address', data),
        [addAddressSuccess],
        [addAddressFailed]
    );
};

/****************************************
 Update Business ADDRESS
 ****************************************/
export const updateAddressSuccess = (payload) => ({
    type: CONST.UPDATE_ADDRESS_SUCCESS,
    payload: payload
});

export const updateAddressFailed = (payload) => ({
    type: CONST.UPDATE_ADDRESS_FAILED,
    payload: payload
});

export const updateAddress = async ({ dispatch , data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/address/' + data['id'], data),
        [updateAddressSuccess],
        [updateAddressFailed]
    );
};

/****************************************
 DELETE Business ADDRESS
 ****************************************/
export const deleteAddressSuccess = (payload) => ({
    type: CONST.DELETE_ADDRESS_SUCCESS,
    payload: payload
});

export const deleteAddressFailed = (payload) => ({
    type: CONST.DELETE_ADDRESS_FAILED,
    payload: payload
});

export const deleteAddress = async ({ dispatch , id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete('corporate/address/' + id),
        [deleteAddressSuccess],
        [deleteAddressFailed]
    );
};

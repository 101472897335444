import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import { updateBusinessStatus } from "../../index";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Toastr from "../../../../../Components/Elements/Toastr";

export const CompleteBusinessRegistration = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector((store)=> store.business);
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {

        setDisabled(true);
        let payload = {};
        payload['business_id'] = business.selected['business_id'];
        payload['status'] = 'SUBMITTED';

        await updateBusinessStatus({dispatch, data:{...payload}}).then((res) => {
            setResponse(res);
            setDisabled(false);
            if(res['success']){
                dispatch(setModal({component:null, open:true}));
                navigate('/dashboard');
            }
        });
    };

    return (
        <>
            <DialogTitle>Confirm Submission!</DialogTitle>
            <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                Are you sure, you want to submit now?
            </DialogContent>
            <DialogActions sx={{p:3, pt:0}}>
                <Button onClick={() => dispatch(setModal({component:null, open:true}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Proceed</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    );
}

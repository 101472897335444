import React, {useEffect} from 'react'
import {CardContent, CardHeader, Tooltip,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, } from "@mui/x-data-grid-pro";
import moment from "moment";
import {Panel} from "../../../../../Components";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import {fetchTasks, setTaskConfig, setSelectedTask} from "../../index";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import {setModal} from "../../../../Common/App";
import {ViewTaskModal} from "../Modals/ViewTaskModal";

export const TasksPanel = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const items = useSelector((store) => store.dashboard?.tasks);
    const filters = useSelector((store) => store.dashboard?.tasks?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.dashboard?.tasks?.config?.pagination);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();
    },[pagination])

    const fetchData = async () => {
        setIsLoading(true);
        await fetchTasks({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setResponse(res);
            setIsLoading(false);
        })
    }

    const handleActionBtn = async (params) => {
        dispatch(setSelectedTask(params.id));
        dispatch(setModal({component:<ViewTaskModal />, open:true}));
    }

    const columns = React.useMemo(
        () => [
            { field: 'doc_expiry', headerName: 'Exp Date', type:'string', flex: 1, width: 80,
                valueGetter: (params) => moment(params.row?.meta?.requested?.doc_expiry).format('DD/MM/YYYY'),},
            { field: 'business', headerName:'Business', type: 'string', flex: 1, valueGetter: (params) => params.row?.business?.name,},
            { field: 'title', headerName:'Documents Description', type: 'string', flex: 1, minWidth: 220,},
            { field: 'status', headerAlign:'center', align:'center', headerName:'Status', type: 'string',flex:1, minWidth:100,
                renderCell: (params) => {

                    const status = {
                        'SUBMITTED':'warning',
                        'VERIFIED':'success',
                        'PENDING':'secondary',
                    }

                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value?.name}
                        color={status[params?.value?.name]}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: auth.user.role_type !== "CLIENT" ? 'Action' : 'Details',
                width:70,
                getActions: (params) => {
                    return [
                        <Tooltip title={"Show Details"}>
                            <GridActionsCellItem
                                hidden={!(params.row.loan !== null) }
                                icon={<PageviewIcon sx={{zIndex:-1}} />}
                                color={"primary"}
                                label={"View"}
                                onClick={() => handleActionBtn(params)}
                            />
                        </Tooltip>
                    ];
                },
            },

        ],
        [],
    );

    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'To-Do List: Documents Update'} />
                <CardContent sx={{p:0, minHeight:'300px'}}>
                    <DataGridPro
                        autoHeight={false}
                        getRowId={(row) => row.id}
                        columns={columns}
                        rows={items?.list || []}
                        sx={{ border:'none'}}
                        rowThreshold={0}
                        pagination
                        paginationMode="server"
                        paginationModel={pagination}
                        rowCount={items?.config?.rowCount ?? 0}
                        pageSizeOptions={[5]}
                        loading={isLoading}
                        onPaginationModelChange={(params) => dispatch(setTaskConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                        initialState={{
                            pinnedColumns: { right: ['actions'] },
                            columns:{
                                columnVisibilityModel:{
                                    business: auth.user.role_type !== 'CLIENT',
                                }
                            },
                            pagination: { paginationModel: { ...pagination } },
                        }}
                    />
                </CardContent>
            </Panel>
        </>
    );
}

import * as React from 'react';
import { CardContent, CardHeader} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {Panel, PanelList} from "../../../../../Components";
import {useLocation} from "react-router-dom";

export const ServiceRequestDetailsPanel = () => {

    const location = useLocation();
    const business = useSelector((store) => store.business);
    const customers = useSelector((store) => store.business?.customers);

    return (
        <>
            <Panel>
                <CardHeader title={'Request Details'} />
                <CardContent>
                    <PanelList>
                        <ListItem>
                            <ListItemText primary={'Business'} secondary={business?.selected?.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Service'} secondary={location?.state?.data?.service_type} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Customer'} secondary={customers?.selected?.company_name} />
                        </ListItem>
                    </PanelList>
                </CardContent>
            </Panel>
        </>
    );
}

import * as React from 'react';
import { useState, useRef } from 'react';
import { FormControl, Stack, Box } from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {uploadVatReturn} from "../Redux/VatReturnAction";


export default function VatAttachDoc(props) {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const businessId = business.selected.business_id;

    const [files, setFiles] = useState([]);
    const [showFiles, setShowFiles] = useState(false);
    const fileInputRef = useRef(null);

    const UploadButton = styled(Button)({
        width: '400px', 
        position: 'relative',
        display: 'flex!important',
        flexDirection: 'column',
        border: '1px dashed #aaa',
        borderRadius: '4px',
        height: '55px',
        '&:hover': {
            backgroundColor: '#EFEFEF',
        },
    });

    const FileCount = styled(Box)({
        position: 'absolute',
        top: '5px',
        right: '5px',
        backgroundColor: '#0099FF',
        color: 'black',
        borderRadius: '50%',
        width: '19px',
        height: '19px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: '10px',
    });

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles([...files, ...newFiles]);
    };

    const openFilePicker = () => {
        fileInputRef.current.click();
    };

    const handleRemoveFile = (fileToRemove) => {
        setFiles(files.filter(file => file !== fileToRemove));
    };

    const toggleShowFiles = (event) => {
        event.stopPropagation();
        setShowFiles(!showFiles);
    };

    const handleSubmit = async () => {

        const selectedFiles = fileInputRef.current.files;
        const payload = new FormData();
    
        for (let i = 0; i < selectedFiles.length; i++) {
            payload.append('files[]', selectedFiles[i], selectedFiles[i].name);
        }
    
        payload.append('business_id', businessId);
        payload.append('entity_id', "01j30b9yjrzwvaf54d7c8bv70y");
        payload.append('entity_name', "vatReturns");
        payload.append('type', "vat_return_doc");
        payload.append('multiple', "true");
        payload.append('doc_size', "87505");
    
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Access': '1000',
            },
        };

        await uploadVatReturn({dispatch, data: payload}).then((res) => {
            if (res['success']) {
                console.log('VAT Return uploaded successfully');
            }
        })
        // try {
        //     const response = await axiosPrivate.post('http://127.0.0.1:8000/api/v1/corporate/upload-vatReturn', payload, config);
        // } catch (error) {
        //     console.error('Error uploading VAT Return:', error);
        // }
    };
    
    

    return (
        <Stack spacing={1}>
            <FormControl>
                <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    multiple
                    onChange={handleFileChange}
                />
                <UploadButton fullWidth size="large" component="span" onClick={openFilePicker}>
                    <CloudUploadIcon />
                    <Typography sx={{ fontSize: '11px', textTransform: 'Capitalize' }}>
                        Supported Files: {"PDF ONLY"}
                    </Typography>
                    {files.length > 0 && (
                        <FileCount onClick={toggleShowFiles}>
                            <Typography sx={{ fontSize: '12px' }}>{files.length}</Typography>
                        </FileCount>
                    )}
                </UploadButton>
            </FormControl>
            {showFiles && (
                <Box>
                    {files.map((file, index) => (
                        <FormControl key={index} sx={{ background: '#F7F7FF', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '65px', borderRadius: '4px', padding: '8px 15px', marginBottom: '8px', width: "400px" }}>
                            <Typography sx={{ fontSize: '13px' }}>{file.name}<span style={{ fontSize: '11px', display: 'block' }}>{file.size} bytes</span></Typography>
                            <Button onClick={() => handleRemoveFile(file)} sx={{ padding: '0' }}>
                                <DeleteForeverIcon sx={{ fontSize: '24px', color: '#990000' }} />
                            </Button>
                        </FormControl>
                    ))}
                </Box>
            )}
            <Button variant="contained" onClick={handleSubmit}>
                Submit
            </Button>
        </Stack>
    );
}

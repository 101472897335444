import React, {useState} from "react";
import { CardContent,Collapse, CardHeader, Chip, Grid, IconButton } from "@mui/material";
import { useSelector} from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AuditDetailTemplate from "../../../Business/Components/AuditDetailTemplate";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Panel, PanelList } from "../../../../../Components";

export const CustomerPropertiesPanel = () => {

    const customer = useSelector(state => state.business.customers.selected);
    const [isExpanded, setIsExpanded] = useState(false);

    return(
        <>
            { customer !== undefined &&
            <Panel sx={{mb:2}}>
                <CardHeader title={"Customer Details"}
                            action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }/>
                <Collapse in={isExpanded}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <PanelList>
                                    <ListItem>
                                        <ListItemText primary={"Status"}  />
                                        <ListItemIcon><Chip color={"primary"} label={customer?.status} size={"small"} /></ListItemIcon>
                                    </ListItem>
                                    {(customer?.created_by !== null && customer?.created_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Created"} secondary={<AuditDetailTemplate type={"created"} data={customer} />}/>
                                    </ListItem>
                                    }
                                    {(customer?.updated_by !== null && customer?.updated_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Last Updated"} secondary={<AuditDetailTemplate type={"updated"} data={customer} />}/>
                                    </ListItem>
                                    }
                                    {(customer?.verified_by !== null && customer?.verified_by !== undefined) &&
                                        <ListItem>
                                            <ListItemText primary={"Verified"} secondary={<AuditDetailTemplate type={"verified"} data={customer} />}/>
                                        </ListItem>
                                    }
                                    {(customer?.approved_by !== null && customer?.approved_by !== undefined) &&
                                        <ListItem>
                                            <ListItemText primary={"Approved"} secondary={<AuditDetailTemplate type={"approved"} data={customer} />}/>
                                        </ListItem>
                                    }
                                </PanelList>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Panel>
            }
        </>
    )
}
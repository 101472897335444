import React from 'react'
import {Outlet} from "react-router-dom";
import LayoutProvider from "../Providers/LayoutProvider";

const PublicRoutes = ({layout}) => {

    return (
      <LayoutProvider layout={layout}>
          <Outlet />
      </LayoutProvider>
  )
}

export default PublicRoutes;
import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {PartnerForm} from "../Forms/PartnerForm";
import {Card, CardContent, CardHeader} from "@mui/material";
import { addPartner, clearErrors, proceed, setSelectedPartner, PartnerDeleteModal } from "../../index";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect} from "react";
import {currencyFormatter, countries, checkExpiryAndDisplayDate} from "../../../../../Setup/Config";
import {Link} from "react-router-dom";
import {ShowAttachmentsModal} from "../Modals/ShowAttachmentsModal";

export const PartnerCard = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const business = useSelector((store) => store.business);
    const documents = useSelector((store) => store.business.selected?.documents);
    let items = Object.keys(business.partners).length > 0 ? business.partners.list.filter(i => i.is_draft !== 1) : [];

    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const documentLink = (document_type, value, id) => {

        const type = {
            'eid_no': 'eid_doc',
            'passport_no': 'passport_doc',
            'aecb_score': 'aecb_score_doc',
            'visa_no': 'visa_doc',
        };

        if (documents && documents.length > 0) {

            let doc = documents.filter((doc) => doc.type === type[document_type] && doc.entity_id === id);
            if (doc.length === 1) {
                return (
                    <Link style={{ textDecoration: 'none' }} to={process.env.REACT_APP_BASE_URL + 'document/' + doc[0].document_id} target="_blank" rel="noopener noreferrer">
                        {value}
                    </Link>
                );
            } else if (doc.length > 1) {

                return (
                    <Typography
                        sx={{ cursor:'pointer', textDecoration:'underline', color:'#0d6efd' }}
                        onClick={() => dispatch(setModal({component: <ShowAttachmentsModal doc={doc} />, open:true}))}
                    >
                        {value}
                    </Typography>
                );
            }
        }

        return value;
    };

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addPartner({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedPartner(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<PartnerForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<PartnerDeleteModal />, open:true}));
        dispatch(setSelectedPartner(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status) && auth.user.role_type === 'CLIENT') {
                return ['add-business-partners'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-partner') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
            return false;
        },[business.status, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />
                ];
            }

            return [];

        },[editable]
    );

    const columns = React.useMemo(
        () => [
            { field: 'name', headerName:'Partner Name', type: 'string', minWidth: 300, flex:1,
                renderCell: (params) => {
                    return params.row.type === 'Company' ? params.row.company_name : params.row.name;
                }
            },
            { field: 'type', headerName:'Type', type: 'string', minWidth: 100, flex:1  },
            { field: 'holding', headerAlign:'center', align:'center', headerName:'Holding', type: 'string', valueFormatter: ({ value }) => `${value}%`, minWidth: 80, flex:1 },
            { field: 'eid_no', headerName:'Emirates ID No', type: 'string', minWidth: 150, flex:1,
                renderCell: (params) => {
                    return documentLink('eid_no', params?.row?.eid_no, params?.row?.id);
                },
            },
            { field: 'eid_expiry_date', headerName:'EID Expiry', type: 'dateTime', flex: 1, minWidth: 100, renderCell: ({value}) => (<Typography>{checkExpiryAndDisplayDate(value)}</Typography>), valueFormatter: ({ value }) => checkExpiryAndDisplayDate(value),},
            { field: 'visa_no', headerName:'Visa No', type: 'string', minWidth: 130, flex:1,
                renderCell: (params) => {
                    return documentLink('visa_no', params?.row?.visa_no, params?.row?.id);
                },
            },
            { field: 'visa_issued_by', headerName:'Visa Sponsor Name', type: 'string', minWidth: 230, flex:1},
            { field: 'visa_expiry_date', headerName:'Visa Expiry', type: 'string', minWidth: 100, flex:1, renderCell: ({value}) => (<Typography>{checkExpiryAndDisplayDate(value)}</Typography>), valueFormatter: ({ value }) => checkExpiryAndDisplayDate(value), },
            { field: 'date_of_birth', headerName:'Date of Birth', type: 'string', minWidth: 100, flex:1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"), },
            { field: 'nationality', headerAlign:'center', align:'center', headerName:'Nationality', type: 'string', flex:1, minWidth:180, valueFormatter: ({value}) =>
                    value && (countries.filter((obj) => obj.iso3 === value))[0]?.name
            },
            { field: 'passport_no', headerAlign:'center', align:'center', headerName:'Passport #', type: 'string', minWidth: 100, flex:1,
                renderCell: (params) => {
                    return documentLink('passport_no', params?.row?.passport_no, params?.row?.id);
                },
            },
            { field: 'passport_expiry_date', headerAlign:'center', align:'center', headerName:'Passport Expiry', type: 'dateTime', minWidth: 120, flex:1, renderCell: ({value}) => (<Typography>{checkExpiryAndDisplayDate(value)}</Typography>), valueFormatter: ({ value }) => checkExpiryAndDisplayDate(value)},
            { field: 'current_address', headerName:'Current Address', type: 'string', minWidth: 180, flex:1},
            { field: 'home_address', headerName:'Home Address', type: 'string', minWidth: 220, flex:1},
            { field: 'residential_status', headerAlign:'center', align:'center', headerName:'Residential Status', type: 'string', minWidth: 150, flex:1},
            { field: 'in_uae_since', headerAlign:'center', align:'center', headerName:'Residing in UAE Since', type: 'string', minWidth: 200, flex:1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")},
            { field: 'mobile', headerAlign:'center', align:'center', headerName:'Mobile', type: 'string', minWidth: 150, flex:1 },
            { field: 'contact_no', headerAlign:'center', align:'center', headerName:'Contact No', type: 'string', minWidth: 150, flex:1 },
            { field: 'email', headerAlign:'center', align:'center', headerName:'Email', type: 'string', minWidth: 220, flex:1 },
            { field: 'aecb_score', headerAlign:'center', align:'center', headerName:'AECB Score', type: 'string', minWidth: 100, flex:1,
                renderCell: (params) => {
                    return documentLink('aecb_score', params?.row?.aecb_score, params?.row?.id);
                },
            },
            { field: 'aecb_score_date', headerAlign:'center', align:'center', headerName:'AECB Score Date', type: 'string', minWidth: 130, flex:1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"), },
            { field: 'life_insurance_value', headerAlign:'center', align:'center', headerName:'LIC Value', type: 'number', minWidth: 120, flex:1, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'personal_net_worth', headerAlign:'center', align:'center', headerName:'Personal Net Worth', type: 'number', minWidth: 160, flex:1, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'actions', flex:1, minWidth: 120, type: 'actions', headerName:'Action', getActions: (params) => actions(params)},
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Partners Details'}
                    action={editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button>}
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={items || []}
                            sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left:['name'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        actions: editable(),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}



import * as React from 'react';
import { Box } from "@mui/material";
import { PersonalDetailsTab, AccountDetailsTab, AddressDetailsTab, BankDetailsTab, EmploymentDetailsTab, SettingsTab } from "../../index";

export const ProfileTabsPanel = ({ value }) => {

    let tabComponent;

    switch (value) {
        case 0:
            tabComponent = <PersonalDetailsTab />;
            break;
        case 1:
            tabComponent = <AccountDetailsTab />;
            break;
        case 2:
            tabComponent = <AddressDetailsTab />;
            break;
        case 3:
            tabComponent = <BankDetailsTab />;
            break;
        case 4:
            tabComponent = <EmploymentDetailsTab />;
            break;
        case 5:
            tabComponent = <SettingsTab />;
            break;
        default:
            tabComponent = null;
            break;
    }

    return (
        <Box role="tabpanel">
            {tabComponent}
        </Box>
    );
}

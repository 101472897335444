import React from 'react'
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {StatsTemplate} from "./StatsTemplate";

export const AccountTotalStats = () => {

    const count = useSelector(store => store.dashboard?.account_total);
    const displayedCount = useCounterEffect(0, count);

    return (
        <>
            <StatsTemplate title={"Account Total"} displayedCount={displayedCount} link={"/wallet"} linkText={"Check Wallet"} />
        </>
    );
}

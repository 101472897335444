import * as React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer as TableContainerUI, TableHead, TableRow, Chip as ChipUI, } from '@mui/material';
import { useSelector } from 'react-redux';
import {currencyFormatterDecimal} from '../../../../../Setup/Config';
import {styled} from "@mui/material/styles";
import moment from "moment";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const RepaymentDetailsPanel = () => {

    const repayments = useSelector((store) => store.repayments.list);

    const TableContainer = styled(TableContainerUI)({
        overflow: 'initial',
        '& th.MuiTableCell-root': {
            background:'#f2f2f2',
            borderTop:'1px solid #e0e0e0',
        }
    })

    const Chip = styled(ChipUI)({
        border:'1px solid #e0e0e0',
    })

    const status = {
        'PAID':'success',
        'PENDING':'warning',
        'OVERDUE':'error',
    }

    return (
        <Box sx={{backgroundColor:'#f8f8f8'}}>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Capital</TableCell>
                            <TableCell>Profit</TableCell>
                            <TableCell>Charge</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {repayments.map((repayment, index) => (
                            <TableRow key={repayment.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{moment(repayment.due_date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{currencyFormatterDecimal.format(repayment.amount)}</TableCell>
                                <TableCell>{currencyFormatterDecimal.format(repayment.capital)}</TableCell>
                                <TableCell>{currencyFormatterDecimal.format(repayment.profit)}</TableCell>
                                <TableCell>{currencyFormatterDecimal.format(repayment.charge)}</TableCell>
                                <TableCell><StatusBall
                                    variant={"contained"}
                                    label={repayment?.status}
                                    color={status[repayment?.status]}
                                /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

export const Datepicker = ({views, label, value, format, name, error, handleChange, disableFuture, disablePast, styles}) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                fullWidth
                views={views || ['year', 'month', 'day']}
                label={label}
                format={format || 'DD/MM/YYYY'}
                value={value ? dayjs(value) : null}
                onChange={(newValue) => {
                    handleChange({
                        target: {
                            name: name,
                            value: newValue ? newValue.format(format || 'YYYY-MM-DD') : null,
                        },
                    });
                }}
                slotProps={{ textField: { helperText: error } }}
                sx={{...styles}}
                disableFuture={disableFuture || false}
                disablePast={disablePast || false}
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

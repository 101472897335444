import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../App";
import {addNotification} from "../../Redux/Action";

export const AddNotification = () => {
    const dispatch = useDispatch();
    const items = useSelector((store)=> store.permissions);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] : e.target.value,
        }));
    }

    const handleSubmit = async() => {
        setDisabled(true);
        await addNotification({dispatch}).then((res) => {
            setResponse(res);
            if(res?.success){
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    return(
        <>
            <Card sx={{ height: '100%', borderRadius:0, position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
                <CardHeader
                    action={
                        <IconButton aria-label="share" onClick={ () => dispatch(setModal({component:null, open:false})) }>
                            <CloseIcon />
                        </IconButton>
                    }
                    avatar={
                        <Typography>Add Notification</Typography>
                    }
                />
                <CardContent sx={{ flex:1, overflow:'auto' }}>
                    <TextField
                        fullWidth
                        label="Notification"
                        name="message"
                        defaultValue=""
                        variant="outlined"
                        size="large"
                        multiline={true}
                        rows={4}
                        helperText={items.error?.message}
                        onChange={(e) => handleChange(e)}
                    />
                </CardContent>
                <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                    <Button disabled={disabled} variant={'contained'} color={'primary'} onClick={ handleSubmit }>Add Notification</Button>
                    <Button variant={'contained'} color={"secondary"} onClick={() => dispatch(setModal({component:null, open:false}))} >Close</Button>
                </CardActions>
            </Card>

        </>
    )
}
import * as React from 'react';
import { Card, CardHeader, CardActions, List as ListUI, CardContent, ListItem, ListItemText, TextField, Button, Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateSettings} from "../../Redux/Action";

export const SettingsTab = () => {

    const dispatch = useDispatch();
    const [editMode, setEditMode] = React.useState(false);
    const profile = useSelector((store) => store.profile);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);

    const handleEditBtn = () => {
        setEditMode(!editMode);
    };

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(profile.settings);
    }, [])

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
    });

    const handleSaveBtn = async () => {

        setDisabled(true);
        await updateSettings({dispatch, data}).then((res) => {
            if(res['success']){
                setDisabled(false);
                setEditMode(false);
            }
        });
    }

    const handleCancelBtn = () => {
        // Reset data and set editMode to false
        setData(profile?.settings);
        setEditMode(false);
    };

    return (
        <Card>
            <CardHeader title={"Settings"} action={!editMode &&<Button onClick={handleEditBtn}>Edit</Button>} />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <List>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Test Setting 1"
                                        name="test_setting_1"
                                        defaultValue={data?.test_setting_1}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.test_setting_1}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Test Setting 1" secondary={data?.test_setting_1 ?? "-" } />
                                )}
                            </ListItem>

                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <List>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Test Setting 2"
                                        name="test_settings_2"
                                        defaultValue={data?.test_settings_2}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.test_settings_2}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Test Setting 2" secondary={data?.test_settings_2 ?? "-"} />
                                )}
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

import React, {useEffect} from 'react';
import {
    Card, CardActions,
    CardContent,
    CardHeader, FormControl, FormHelperText,
    Grid, InputLabel, Select, TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {fetchRoles} from "../../../Roles";
import {updateSetting} from "../../Redux/Action";
import Toastr from "../../../../../Components/Elements/Toastr";

export const BusinessRoleTab = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.settings.list);
    const roles = useSelector(state => state.roles.list);
    const [data, setData] = React.useState({});
    const [response, setResponse] = React.useState({});

    useEffect(() => {
        const desiredParameters = [
            'role_create_business',
            'role_business_analyst',
            'role_business_head',
            'role_credit_review',
            'role_credit_manager',
            'role_ceo',
            'role_bod',
            'role_chairman',
            'credit_manager_approval_limit',
            'ceo_approval_limit',
            'bod_approval_limit',
            'role_sr_create',
            'role_sr_verify',
            'role_sr_bh_approval',
            'role_sr_credit_review',
            'role_sr_credit_approval',
            'role_operation',
            'role_loan_documentation',
            'role_loan_disbursement',
            'role_account',
        ];

        const newData = {};

        items.forEach(item => {
            if (desiredParameters.includes(item.parameter_name)) {
                newData[item.parameter_name] = item.parameter_value;
            }
        });

        setData(newData);
    }, [items]);

    useEffect(() => {
        fetchData();
    },[]);

    const handleChange = (event) => {

        const { name, value } = event.target;
        const updatedValue = Array.isArray(value) ? value.join(',') : value;

        setData({
            ...data,
            [name]: updatedValue
        });
    }

    const fetchData = async () => {

        await fetchRoles({dispatch, filters:{all:true, fields:['id','name']}});
    }

    const handleSubmit = async () => {

        const formattedData = Object.keys(data).map((key) => {
            return {parameter_name: key, parameter_value: data[key]}
        });

        await updateSetting({dispatch, data:{...formattedData}}).then((res) => {
            setResponse(res)
        });
    }

    return (
        <>
            <Card>
                <CardHeader title={"Business Role Mapping"} />
                <CardContent>
                    <Grid container spacing={2} sx={{mb:4}}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Business</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Delegated Lending Authority
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Create Business</InputLabel>
                                        <Select
                                            fullWidth
                                            multiple
                                            label="Create Business"
                                            name="role_create_business"
                                            variant="outlined"
                                            value={data?.role_create_business ? data.role_create_business.split(',') : []}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Verify Business</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Verify Business"
                                            name="role_business_analyst"
                                            variant="outlined"
                                            value={data?.role_business_analyst ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Business Head Approval</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Business Head Approval"
                                            name="role_business_head"
                                            variant="outlined"
                                            value={data?.role_business_head ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Credit Review</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Credit Review"
                                            name="role_credit_review"
                                            variant="outlined"
                                            value={data?.role_credit_review ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Credit Approval</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Credit Approval"
                                            name="role_credit_manager"
                                            variant="outlined"
                                            value={data?.role_credit_manager ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        fullWidth
                                        label="Credit Manager Limit"
                                        name="credit_manager_approval_limit"
                                        variant="outlined"
                                        value={data?.credit_manager_approval_limit ?? ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={5}><Typography>if amount is less than threshold value then approve it otherwise send it to CEO for Approval</Typography></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">CEO Approval</InputLabel>
                                        <Select
                                            fullWidth
                                            label="CEO Approval"
                                            name="role_ceo"
                                            variant="outlined"
                                            value={data?.role_ceo ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        fullWidth
                                        label="CEO Limit"
                                        name="ceo_approval_limit"
                                        variant="outlined"
                                        value={data?.ceo_approval_limit ?? ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={5}><Typography>if amount is less than threshold value then approve it otherwise send it to Board of Director for Approval</Typography></Grid>


                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">BOD Approval</InputLabel>
                                        <Select
                                            fullWidth
                                            label="BOD Approval"
                                            name="role_bod"
                                            variant="outlined"
                                            value={data?.role_bod ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <TextField
                                        fullWidth
                                        label="BOD Limit"
                                        name="bod_approval_limit"
                                        variant="outlined"
                                        value={data?.bod_approval_limit ?? ""}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={5}><Typography>if amount is less than threshold value then approve it otherwise send it to Chairman for Approval</Typography></Grid>


                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Chairman Approval</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Chairman Approval"
                                            name="role_chairman"
                                            variant="outlined"
                                            value={data?.role_chairman ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{mb:4}}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Services</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Adjust your notification preferences here.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Create Service Request</InputLabel>
                                        <Select
                                            fullWidth
                                            multiple
                                            label="Create Service Request"
                                            name="role_sr_create"
                                            variant="outlined"
                                            value={data?.role_sr_create ? data.role_sr_create.split(',') : []}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Verify Service Request</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Verify Service Request"
                                            name="role_sr_verify"
                                            variant="outlined"
                                            value={data?.role_sr_verify ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Service Request Credit Approval</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Service Request Credit Approval"
                                            name="role_sr_credit_approval"
                                            variant="outlined"
                                            value={data?.role_sr_credit_approval ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{mb:4}}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Business Finance</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Adjust your preferences here.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-Title">Operations</InputLabel>
                                        <Select
                                            fullWidth
                                            multiple
                                            label="Operations"
                                            name="role_operation"
                                            variant="outlined"
                                            value={data?.role_operation ? data.role_operation.split(',') : []}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-documentation">Documentation</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Documentation"
                                            name="role_loan_documentation"
                                            variant="outlined"
                                            value={ data?.role_loan_documentation ?? "" }
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-disbursement">Disbursement</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Disbursement"
                                            name="role_loan_disbursement"
                                            variant="outlined"
                                            value={ data?.role_loan_disbursement ?? "" }
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8}></Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ width:'100%' }}>
                                        <InputLabel id="label-disbursement">Accounts</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Accounts"
                                            name="role_account"
                                            variant="outlined"
                                            value={ data?.role_account ?? "" }
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            { roles.map((role) => ( <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)) }
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={8}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant={"contained"} onClick={()=> handleSubmit()}>Save</Button>
                </CardActions>
            </Card>
            <Toastr response={response} />
        </>
    )
}
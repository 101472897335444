import * as React from 'react';
import {styled, useTheme} from "@mui/material/styles";
import {
    Card,
    CardHeader,
    IconButton,
    Popover as PopoverUI
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Popover = ({title, anchorEl, onClose, children}) => {

    const theme = useTheme();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const Popover = styled(PopoverUI)({
        '& .MuiPaper-root':{
            boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.2)',
            [theme.breakpoints.down('md')]: {
                minWidth: '100%', // Minimum width for desktop view
                minHeight:'100%',
                left:'0!important',
                top:'0!important',
                height:'100%',
            },
        }
    });

    const PopoverCard = styled(Card)({
        width: '100%',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardHeader-root':{
            padding: '1rem',
            borderBottom: '1px solid #e0e0e0',
            '& .MuiCardHeader-title':{
                fontSize: '1rem',
                fontWeight: 'normal',
            },
        },
        '& .MuiCardContent-root':{
            flex: '1',
            overflowY:'auto',
            maxHeight:'300px',
            padding: '1rem',
            paddingBottom:'1rem!important',
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'inherit',
            },
            '& .MuiListItemAvatar-root':{
                minWidth: 'auto',
                marginRight: '15px',
                '& svg':{
                    width:'0.8rem'
                }
            },
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 300,
        },
    });

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <PopoverCard>
                <CardHeader
                    title={title}
                    titleTypographyProps={{fontWeight:'bold', fontSize:'1rem'}}
                    action={<IconButton
                                edge="end"
                                color="inherit"
                                onClick={onClose}
                                aria-label="close"
                                size={'small'}
                                sx={{ position: 'absolute', top: '8px', right: '15px' }}
                            >
                                <CloseIcon />
                            </IconButton>}>
                </CardHeader>
                {children}
            </PopoverCard>
        </Popover>
    );
}



import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {CustomerForm} from "../Forms/CustomerForm";
import {Grid, List, ListItem, Stack, Typography,} from "@mui/material";
import {
    addCustomer,
    clearErrors,
    proceed,
    setSelectedCustomer,
    CustomerDeleteModal
} from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";
import {ExistingCustomerSelectionModal} from "../Modals/ExistingCustomerSelectionModal";

export const CustomerStep = () => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.customers).length > 0 ? business.customers.list?.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCustomer({dispatch, data:{business_id: business.selected.business_id, for_invoice_discounting: 1}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCustomer(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<CustomerForm />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<CustomerDeleteModal />, open:true}));
        dispatch(setSelectedCustomer(e.currentTarget.dataset.id));
    }

    const handleExistingBtn = async (e) => {
        dispatch(setModal({component:<ExistingCustomerSelectionModal />, open:true}));
    }


    const columns = React.useMemo(
        () => [
            { field: 'company_name', headerName:'Customer Name', type: 'string', flex:1, minWidth:250},
            { field: 'credit_limit_requested', headerName:'Credit Limit Req', type: 'string', flex:1, minWidth:140,
                valueGetter: (params) => {
                    return (params.row?.config?.find((o) => o.config_type === 'credit_limit_requested'))?.config_value;
                }
            },
            { field: 'dealing_since', headerName:'Dealing Since', type: 'string', flex:1, minWidth: 120},
            { field: 'payment_terms', headerName:'Payment Terms', type: 'string', flex:1, minWidth:120},
            { field: 'currency', headerName:'Currency', type: 'string', flex:1, minWidth:80},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.customer_id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items || []}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { left: ['company_name'], right: ['actions'] }
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction={"row"} spacing={2}>
                            <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Customer</Button>
                            <Button variant="contained" color={"warning"} data-id={"draft"} data-action={"AddItem"} onClick={handleExistingBtn} sx={{ display:'inline-block'}}>Add Existing Customer</Button>
                        </Stack>
                    </Grid>
                    { items && items.length === 0 &&
                        <Grid item xs={12}>
                            <Instructions />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
        <Typography variant={"h3"}>Invoice Discounting</Typography>
        <Typography variant={"body1"}>
            To complete this section, please provide the details of your customers for whom you require invoice discounting. You will be required to attach the following documents.
        </Typography>
        <List>
            <ListItem>Customer profile, if available</ListItem>
            <ListItem>Trade license</ListItem>
            <ListItem>Customer Ledger (last 1 year)</ListItem>
        </List>
    </InfoBox>)
}
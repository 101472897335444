import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import {styled} from "@mui/material/styles";

export const CountDownTimer = () => {

    const listing = useSelector((state) => state.listings.selected);
    const endDate = listing.status === 'FUNDED' ? null : listing.listing_end_date;

    const calculateTimeLeft = () => {
        if (!endDate) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        const difference = +new Date(endDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        } else {
            // Countdown has ended
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        if (!endDate) {
            return;
        }

        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const formatTime = (value) => {
        return value < 10 ? `0${value}` : value;
    };

    const TimerUnit = styled(Box)({
        textAlign: 'center',
        padding: '10px 10px',
        borderRadius: '8px',
        backgroundColor: '#f0f0f0', /* Light grey background */
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', /* Subtle shadow */
        transition: 'background-color 0.3s ease', /* Smooth transition */
        width:'90px',
        '&:hover': {
            backgroundColor: '#e0e0e0', /* Darker grey background on hover */
        },

        '& > p': {
            fontSize: '0.8rem',
            color: '#000',
            fontWeight: 300,
            marginBottom: '10px', /* Spacing between text and number */
        },
        '& > h1': {
            fontSize: '2.2rem',
            color: '#000',
            fontWeight: 300,
        },
    });


    return (
        <Stack direction="row" spacing={2} justifyContent="center">
            <TimerUnit>
                <Typography variant="body1">Days</Typography>
                <Typography variant="h4">{formatTime(timeLeft.days)}</Typography>
            </TimerUnit>
            <TimerUnit>
                <Typography variant="body1">Hours</Typography>
                <Typography variant="h4">{formatTime(timeLeft.hours)}</Typography>
            </TimerUnit>
            <TimerUnit>
                <Typography variant="body1">Minutes</Typography>
                <Typography variant="h4">{formatTime(timeLeft.minutes)}</Typography>
            </TimerUnit>
            <TimerUnit>
                <Typography variant="body1">Seconds</Typography>
                <Typography variant="h4">{formatTime(timeLeft.seconds)}</Typography>
            </TimerUnit>
        </Stack>
    );
};

import * as React from 'react';
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

export const ShowAttachmentsModal = ({ doc }) => {

    const dispatch = useDispatch();

    const handleOpenAll = () => {
        // Iterate through each document and trigger click event on their corresponding links with a delay
        doc?.forEach((d, i) => {
            setTimeout(() => {
                const link = document.querySelector(`a[href="${process.env.REACT_APP_BASE_URL}document/${d.document_id}"]`);
                if (link) {
                    link.click();
                    console.log('Opening document ' + d.doc_name + ' in a new tab');
                }
            }, i * 100); // Adjust the delay (100 milliseconds in this example)
        });
    };


    return (
        <>
            <DialogTitle>Documents</DialogTitle>
            <DialogContent sx={{ justifyContent: 'center', alignContent: 'center', padding: '10px!important', display: 'flex', flexDirection: 'column' }}>
                <List>
                    {doc?.map((d, i) => (
                        <ListItem key={i}>
                            <ListItemText>
                                <Link to={process.env.REACT_APP_BASE_URL + 'document/' + d.document_id} target="_" rel="noreferrer">
                                    {d.doc_name}
                                </Link>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions sx={{ p: 3, pt: 0 }}>
                <Button onClick={() => dispatch(setModal({ component: null, open: false }))}>Cancel</Button>
                <Button variant={"contained"} color={'primary'} onClick={handleOpenAll}>Open All</Button>
            </DialogActions>
        </>
    );
}

import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import Toastr from "../../../../../Components/Elements/Toastr";
import {DefaultContractDetails} from "../Cards/DefaultContractDetails";
import {IconButton} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmailIcon from '@mui/icons-material/Email';
import PropTypes from "prop-types";
import {useEffect} from "react";
import {fetchDefaultBusinesses} from "../../../Business";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {setModal} from "../../../../Common/App";
import {SendCustomEmailModal} from "../Modals/SendCustomEmailModal";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import moment from "moment";

export const DefaultContractTable = () => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const permissions = useSelector((store) => store.app.permissions);
    const [response, setResponse] = React.useState({});
    const [panelHeight, setPanelHeight] = React.useState(0);
    const [items, setItems] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {

        await fetchDefaultBusinesses({ dispatch }).then((res) => {
            setResponse(res);
            setItems(res.data?.data.data)
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/dashboard/' + id);
        },
        [],
    );

    const columns = [
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
                <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
            ),
        },
        { field: 'name', headerName: 'Business Name', headerAlign:'left', align:'left', type: 'month', flex:1, maxWidth:400,
            renderCell: (params) => {
                return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.id)}>{params.value}</Typography>
            }
        },
        { field: 'default_date', headerName: 'Default Date', headerAlign:'center', align:'center', type: 'date', flex:1, maxWidth:120,
            valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY')
        },
        { field: 'default_amount', headerName: 'Amount', type: 'number', flex:1, maxWidth:120,
            valueGetter: (params) => currencyFormatter.format(params.row?.stats?.outstanding)
        },
        { field: 'owner_id', headerName: 'Contact Name', headerAlign:'left', align:'left', type: 'month', flex:1, maxWidth:200,
            valueGetter: (params) => params.row.owner.first_name + ' ' + params.row.owner.last_name
        },
        { field: 'email', headerName: 'Email', headerAlign:'left', align:'left', type: 'month', flex:1, maxWidth:200,
            valueGetter: (params) => params.row.owner.email
        },
        { field: 'mobile', headerName: 'Contact No', headerAlign:'left', align:'left', type: 'month', flex:1, maxWidth:150,
            valueGetter: (params) => params.row.owner.mobile
        },
        {
            field: 'actions', headerName: 'Actions', headerAlign:'left', align:'left', type: 'actions', flex:1, maxWidth:70,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EmailIcon sx={{zIndex:-1}} />}
                    label="Edit"
                    onClick={() => dispatch(setModal({component:<SendCustomEmailModal row={params.row} />, open:true}))}
                />
            ],
        }
    ];

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <DefaultContractDetails data={row} setPanelHeight={setPanelHeight} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => panelHeight, [panelHeight]);

    return (
        <>
            <DataGridPro
                autoHeight
                getRowId={(row) => row.business_id}
                rows={items || []}
                columns={columns}
                sx={{ border:'none' }}
                loading={isLoading}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            actions: permissions.includes('send-custom-email'),
                        },
                    }
                }}
                hideFooter={true}
            />
            <Toastr response={response} />
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    const shouldShowToggle = true;//!['PENDING','VERIFIED'].includes(props.row.status);

    const hasDetail = React.isValidElement(contentCache[id]);

    return shouldShowToggle && (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};

const CONST = {
    'SET_CONFIG':'REQUEST/SET_CONFIG',
    'RESET_ALL':'REQUEST/RESET_ALL',
    'SET_SELECTED_ITEM':'REQUEST/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'REQUEST/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'REQUEST/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'REQUEST/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'REQUEST/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'REQUEST/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'REQUEST/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'REQUEST/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'REQUEST/UPDATE_ITEM_FAILED',
    'APPROVE_ITEM_SUCCESS':'REQUEST/APPROVE_ITEM_SUCCESS',
    'APPROVE_ITEM_FAILED':'REQUEST/APPROVE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'REQUEST/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'REQUEST/DELETE_ITEM_SUCCESS',
}

export default CONST;
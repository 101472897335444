import React from 'react'
import {CardContent, CardHeader, Grid, Stack,} from "@mui/material";
import {Panel} from "../../../../Components";
import {Breadcrumbs} from "../../../../Components";
import {CurrentOutstandingTable} from "./Tables/CurrentOutstandingTable";

export const CurrentOutstanding = () => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Breadcrumbs items={'DASHBOARD/PAYMENTS'} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Panel>
                        <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'Current Outstanding'} />
                        <CardContent sx={{p:0}}>
                            <CurrentOutstandingTable />
                        </CardContent>
                    </Panel>
                </Grid>
            </Grid>
        </>
    );
}

import * as React from 'react';
import { Card, CardHeader, CardActions, List as ListUI, CardContent, ListItem, ListItemText, TextField, Button, Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateAddress} from "../../Redux/Action";
import Toastr from "../../../../../Components/Elements/Toastr";

export const AddressDetailsTab = () => {

    const dispatch = useDispatch();
    const [editMode, setEditMode] = React.useState(false);
    const profile = useSelector((store) => store.profile);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleEditBtn = () => {
        setEditMode(!editMode);
    };

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(profile.selected.profile);
    }, [])

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
    });

    const handleSaveBtn = async () => {

        setDisabled(true);

        await updateAddress({dispatch, data}).then((res) => {
            setResponse(res);
            if(res['success']){
                setDisabled(false);
                setEditMode(false);
            }
        });
    }

    const handleCancelBtn = () => {
        // Reset data and set editMode to false
        setData(profile?.selected?.profile);
        setEditMode(false);
    };

    return (
        <>
            <Card>
                <CardHeader title={"Address Details"} action={!editMode &&<Button onClick={handleEditBtn}>Edit</Button>} />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <List>

                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Street Address"
                                            name="address_street"
                                            defaultValue={data?.address_street}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.address_street}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Street Address" secondary={data?.address_street} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="City"
                                            name="address_city"
                                            defaultValue={data?.address_city}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.address_city}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="City" secondary={data?.address_city} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Country of Residence"
                                            name="residence_country"
                                            defaultValue={data?.residence_country}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.residence_country}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Country of Residence" secondary={data?.residence_country} />
                                    )}
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <List>

                                <ListItem>
                                    {editMode ? (

                                        <TextField
                                            fullWidth
                                            label="Postal Code"
                                            name="address_postcode"
                                            defaultValue={data?.address_postcode}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.address_postcode}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Postal Code" secondary={data?.address_postcode} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="State"
                                            name="address_state"
                                            defaultValue={data?.address_state}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.address_state}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="State" secondary={data?.address_state} />
                                    )}
                                </ListItem>

                            </List>
                        </Grid>
                    </Grid>

                </CardContent>
                {editMode &&
                    <CardActions sx={{p:2, justifyContent:'end'}}>
                        <Stack spacing={2} justifyContent={"space-between"} direction={"row"}>
                            <Button variant="contained" color="secondary" size="small" onClick={handleCancelBtn}>Cancel</Button>
                            <Button variant="contained" size="small" onClick={handleSaveBtn}>Save</Button>
                        </Stack>
                    </CardActions>
                }
            </Card>
            <Toastr response={response} />
        </>
    );
};

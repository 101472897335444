import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import {handleAsyncAction} from "../../../../Setup/Axios/ApiHandlers";

/****************************************
 FETCH SETTINGS
 ****************************************/
export const fetchSettingsSuccess = (payload) => ({
    type: CONST.FETCH_SETTINGS_SUCCESS,
    payload: payload
})

export const fetchSettingsFailed = (payload) => ({
    type: CONST.FETCH_SETTINGS_FAILED,
    payload: payload
})

export const fetchSettings = async ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('setting', { params: filters }),
        [fetchSettingsSuccess],
        [fetchSettingsFailed]
    );
}


/****************************************
 UPDATE SETTING
 ****************************************/
export const updateSettingSuccess = (payload) => ({
    type: CONST.UPDATE_SETTING_SUCCESS,
    payload: payload
})

export const updateSettingFailed = (payload) => ({
    type: CONST.UPDATE_SETTING_FAILED,
    payload: payload
})

export const updateSetting = async ({dispatch, data}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('setting', data),
        [updateSettingSuccess],
        [updateSettingFailed]
    );
}


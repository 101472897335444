import React from 'react'
import { Routes as Switch, Route } from 'react-router-dom'
import PrivateRoutes from './PrivateRoutes'

import { Dashboard } from "../Modules/Investor/Dashboard";
import { Profile } from "../Modules/Investor/Profile";
import { Settings } from "../Modules/Administration/Settings";
import PublicRoutes from "./PublicRoutes";
import {
    EmailVerification,
    ForgotPassword,
    ResetPassword,
    Login,
    Logout,
    Register,
    RegisterVerify
} from "../Modules/Common/Auth";
import {ListingView} from "../Modules/Investor/Listings";
import {MyWallet} from "../Modules/Investor/Wallets";
import {Position} from "../Modules/Investor/Positions";
import {Repayment} from "../Modules/Investor/Repayments";
import {Statements} from "../Modules/Investor/Statements";
import {StatementView} from "../Modules/Investor/Statements/Components/StatementView";
import {RegisterComplete} from "../Modules/Common/Auth/Components/RegisterComplete";

export const InvestorRoutes = () => {

    return (
        <Switch>
            <Route element={<PublicRoutes layout={"Auth"} />} >
                <Route exact path='/register' element={<Register/>}/>
                <Route exact path='/email-verification' element={<EmailVerification />}/>
                <Route exact path='/forgot-password' element={<ForgotPassword />}/>
                <Route exact path='/reset-password/:token' element={<ResetPassword />}/>
                <Route exact path='/verify/:token' element={<RegisterVerify />}/>
                <Route exact path='/login' element={<Login />}/>
                <Route exact path='/logout' element={<Logout />}/>
            </Route>

            <Route element={<PrivateRoutes layout={'Auth'} allowedRoles={['CLIENT']} />} >
                <Route exact path='/complete-registration' element={<RegisterComplete />}/>
            </Route>

            <Route element={<PrivateRoutes layout={'Investor'} allowedRoles={['ADMIN','USER','CLIENT']} />} >
                <Route exact path='/' element={<Dashboard />}/>
                <Route exact path='/complete-registration' element={<RegisterComplete />}/>
                <Route exact path='/dashboard' element={<Dashboard />}/>
                <Route exact path='/profile' element={<Profile />}/>
                <Route exact path='/settings' element={<Settings />}/>
            </Route>

            <Route element={<PrivateRoutes layout={'Investor'} allowedRoles={['CLIENT']} />} >
                <Route exact path='/listing/:listing_id' element={<ListingView />}/>
                <Route exact path='/wallet' element={<MyWallet />}/>
                <Route exact path='/position' element={<Position />}/>
                <Route exact path='/repayments' element={<Repayment />}/>
                <Route exact path='/statements' element={<Statements />}/>
                <Route exact path='/statements/:year/:month' element={<StatementView />}/>
            </Route>

        </Switch>
    )
}

import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    error: [],
    code: "",
    config:{
        total:0,
        filters:{
            page:0,
            pageSize:5,
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.SET_SELECTED_ROLE:
            return {
                ...state,
                selected: state.list.filter(item => item.id === action.payload)[0],
            }
        case CONST.FETCH_ROLE_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Role loaded successfully"],
                code:action.payload.status
            }
        case CONST.FETCH_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_ROLES_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                error: [],
                message: ["Roles loaded successfully"],
                code:action.payload.status,
                config:{
                    ...state.config,
                    total:action.payload.data.total
                },
            }
        case CONST.FETCH_ROLES_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_ROLE_SUCCESS:
            return {
                ...state,
                list:[...state.list,action.payload.data],
                selected: action.payload.data,
                error: [],
                message: ["Role added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_ROLE_SUCCESS:
            const indexU = state.list.findIndex(role => role.id === action.payload.data.id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Role updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }

        case CONST.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.id !== action.payload.data),
                selected: {},
                error: [],
                message: ["Role deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_ROLE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
import React from "react";
import {
    Grid, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {setSelectedCheque, updateCheque} from "../../index";
import MuiDatepicker from "../Datepicker";
import Toastr from "../../../../../Components/Elements/Toastr";
import BankAutocomplete from "../BankAutocomplete";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import Typography from "@mui/material/Typography";

export const ChequeForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [hideReturnReason,setHideReturnReason] = useState(true);

    useEffect(() => {
        setData(business.cheques.selected);
        setHideReturnReason(business.cheques?.selected?.cheque_type === 'PDC');
    }, [])

    const handleChange = (e) => {
        console.log(e.target.name);
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
        setHideReturnReason(e.target.name === 'cheque_type' && e.target.value === 'PDC');

        // Validate settlement_date > cheque_date
        if (e.target.name === 'settlement_date' || e.target.name === 'cheque_date') {
            const chequeDate = new Date(data.cheque_date);
            const settlementDate = new Date(data.settlement_date);
            if (chequeDate >= settlementDate) {
                setData(prevData => ({
                    ...prevData,
                    error: "Settlement date must be greater than cheque date"
                }));
            } else {
                setData(prevData => ({
                    ...prevData,
                    error: null
                }));
            }
        }
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updateCheque({dispatch, data}).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component:null, open:false}));
        dispatch(setSelectedCheque(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Returned Cheque Details
                <Typography>Details of your returned cheques (if any)</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>

                                <MuiDatepicker error={business?.error?.cheque_date} setDisabled={setDisabled} label={"Cheque Date"} past={true} name="cheque_date" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Cheque No"
                                    name="cheque_no"
                                    defaultValue={data?.cheque_no}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.cheque_no}
                                    onBlur={handleChange} />

                                <BankAutocomplete setDisabled={setDisabled} setData={setData} data={data} />

                                <TextField
                                    fullWidth
                                    label="Cheque Issued To/Name"
                                    name="party_name"
                                    defaultValue={data?.party_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.party_name}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Cheque Amount"
                                    name="amount"
                                    defaultValue={data?.amount}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.amount}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Return Reason"
                                    name="return_reason"
                                    hidden={hideReturnReason}
                                    defaultValue={data?.return_reason}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.return_reason}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.settlement_date} setDisabled={setDisabled} label={"Settlement Date"} past={true} name="settlement_date" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Legal Case Reference No (if any)"
                                    name="case_reference_no"
                                    defaultValue={data?.case_reference_no}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.case_reference_no}
                                    onBlur={handleChange} />

                                <AttachDoc multiple={true} setResponse={setResponse} updateFunction={updateCheque} allowedFileType={['pdf']} onBlur={handleChange} label={" Settlement Documents / Legal Case Documents"} entity_name={"cheques"} entity_id={data?.cheque_id} name="settlement_doc" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Remarks"
                                    name="remarks"
                                    multiline={true}
                                    rows={3}
                                    defaultValue={data?.remarks}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.remarks}
                                    onBlur={handleChange} />
                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}


import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuickList } from "../Redux/Action";
import { debounce } from 'lodash';

const filter = createFilterOptions();

export const BusinessAutocomplete = (props) => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.business.quickList) || [];
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const debouncedFetchData = useMemo(() => debounce((searchTerm) => {
        if (searchTerm.length >= 3) {
            fetchData(searchTerm);
        }
    }, 500), []); // Ensure debounce function is created once

    const fetchData = async (searchTerm) => {
        setLoading(true);
        await fetchQuickList({ dispatch, data: { keyword: searchTerm } });
        setLoading(false);
    };

    const handleInputChange = (event, value, reason) => {
        if (reason === "input") {
            debouncedFetchData(value);
        }
    };

    const handleChange = (event, value) => {
        if (value && value.business_id) {
            props.setDisabled(false);
            props.setData(prevData => ({
                ...prevData,
                related_business_id: value.business_id,
            }));
        }
    };

    useEffect(() => {
        if (items.length > 0) {
            setOpen(true);
        }
    }, [items]);

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedFetchData.cancel();
        };
    }, [debouncedFetchData]);


    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)} // Opens the dropdown when input is focused or clicked
            onClose={() => setOpen(false)}
            onInputChange={handleInputChange}
            onChange={handleChange}
            options={items}
            loading={loading}
            filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();
                return options.filter((option) =>
                    option.name.toLowerCase().includes(inputValue)
                );
            }}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText={props.errors?.related_business_id}
                    label="Search"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    onFocus={() => setOpen(true)} // Ensure dropdown opens on input focus
                />
            )}
        />
    );
};

import * as React from "react";
import { Box, Card, CardContent, CardHeader, Grid, Accordion as AccordionUI, AccordionSummary, AccordionDetails, Typography, TableContainer, Table as TableUI, TableHead, TableBody, TableCell, TableRow } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { fetchStatement, fetchStatements } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import {currencyFormatterDecimal} from "../../../../../Setup/Config";

export const StatementPanel = () => {
    const dispatch = useDispatch();
    const statements = useSelector(state => state.statements.list);

    useEffect(() => {
        // Fetch statements
        fetchData();
    }, []);

    const fetchData = async () => {
        // Fetch statements
        await fetchStatements({ dispatch, data: {} });
    }

    const Accordion = styled(AccordionUI)({
        width: '100%',
        margin: '0',
        '& .MuiAccordionSummary-root': {
            backgroundColor: '#f4f4f4',
            borderBottom: '1px solid #e0e0e0',
            padding: '0 16px',
            '& .MuiAccordionSummary-content': {
                margin: '0',
            }
        },
    })

    const Table = styled(TableUI)({

    })

    // State variable to track the index of the currently expanded accordion
    const [expandedIndex, setExpandedIndex] = useState(0);
    const [firstAccordionExpanded, setFirstAccordionExpanded] = useState(true);

    const handleAccordionChange = async(index, year, isExpanded) => {
        // Fetch data for the expanded year
        if(isExpanded) {
            await fetchStatement({dispatch, data: {year}});
        }
        // Update the expanded index state
        setExpandedIndex(isExpanded ? index : null);
        // Update the first accordion state
        if (index === 0) {
            setFirstAccordionExpanded(isExpanded);
        }
    }

    return (
        <>
            {Object.entries(statements).reverse().map(([year, months], index) => (
                <Accordion
                    key={year}
                    expanded={(index === 0 && firstAccordionExpanded) || (index !== 0 && expandedIndex === index)}
                    onChange={(event, isExpanded) => handleAccordionChange(index, year, isExpanded)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>{`Statements - ${year}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Month</TableCell>
                                        <TableCell>Deposits</TableCell>
                                        <TableCell>Investments</TableCell>
                                        <TableCell>Repayments</TableCell>
                                        <TableCell>Earnings</TableCell>
                                        <TableCell>Charges</TableCell>
                                        <TableCell>Deficits</TableCell>
                                        <TableCell>Withdrawals</TableCell>
                                        <TableCell>Recoveries</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        // If months is not an array, assume it's an object with month-wise data
                                        months && typeof months === 'object' &&
                                        Object.entries(months).map(([month, data], index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Link to={`/statements/${year}/${month}`}>{month}</Link>
                                                </TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Deposits) }</TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Investments) }</TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Repayments) }</TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Earnings) }</TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Charges) }</TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Deficits) }</TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Withdrawals) }</TableCell>
                                                <TableCell>{ currencyFormatterDecimal.format(data.Recoveries) }</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}

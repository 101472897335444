import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";


export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload: payload
});

export const resetRepayments = (payload) => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedRepayment = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchRepaymentSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchRepaymentFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchRepayment = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/repayment/${id}`),
        [fetchRepaymentSuccess],
        [fetchRepaymentFailed]
    );
};


export const fetchRepaymentsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchRepaymentsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchRepayments = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/repayment', { params: data }),
        [fetchRepaymentsSuccess],
        [fetchRepaymentsFailed]
    );
};


export const addRepaymentSuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload
});

export const addRepaymentFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload
});

export const addRepayment = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`investor/repayment`, data),
        [addRepaymentSuccess],
        [addRepaymentFailed]
    );
};
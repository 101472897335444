import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

/****************************************
 SET SELECTED PARTNER
 ****************************************/
export const setSelectedPartner = (payload) => ({
    type: CONST.SET_SELECTED_PARTNER,
    payload: payload
});

/****************************************
 FETCH PARTNER
 ****************************************/
export const fetchPartnerSuccess = (payload) => ({
    type: CONST.FETCH_PARTNERS_SUCCESS,
    payload: payload
});

export const fetchPartnerFailed = (payload) => ({
    type: CONST.FETCH_PARTNERS_FAILED,
    payload: payload
});

export const fetchPartner = async ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/partner`),
        [fetchPartnerSuccess],
        [fetchPartnerFailed]
    );
};

/****************************************
 ADD Business Partner
 ****************************************/
export const addPartnerSuccess = (payload) => ({
    type: CONST.ADD_PARTNER_SUCCESS,
    payload: payload
});

export const addPartnerFailed = (payload) => ({
    type: CONST.ADD_PARTNER_FAILED,
    payload: payload
});

export const addPartner = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/partner', data),
        [addPartnerSuccess],
        [addPartnerFailed]
    );
};

/****************************************
 Update Business Partner
 ****************************************/
export const updatePartnerSuccess = (payload) => ({
    type: CONST.UPDATE_PARTNER_SUCCESS,
    payload: payload
});

export const updatePartnerFailed = (payload) => ({
    type: CONST.UPDATE_PARTNER_FAILED,
    payload: payload
});

export const updatePartner = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/partner/${data.id}`, data),
        [updatePartnerSuccess],
        [updatePartnerFailed]
    );
};

/****************************************
 DELETE Business Partner
 ****************************************/
export const deletePartnerSuccess = (payload) => ({
    type: CONST.DELETE_PARTNER_SUCCESS,
    payload: payload
});

export const deletePartnerFailed = (payload) => ({
    type: CONST.DELETE_PARTNER_FAILED,
    payload: payload
});

export const deletePartner = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/partner/${id}`),
        [deletePartnerSuccess],
        [deletePartnerFailed]
    );
};

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const clearData = () => ({
    type: CONST.CLEAR_DATA,
})

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});

/************************************************************
 * Fetch Activity
*************************************************************/
export const fetchActivitySuccess = (payload) => ({
    type: CONST.FETCH_ACTIVITY_SUCCESS,
    payload: payload
});

export const fetchActivityFailed = (payload) => ({
    type: CONST.FETCH_ACTIVITY_FAILED,
    payload: payload
});

export const fetchActivity = ({dispatch, filters}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`activities/`, {params: filters}),
        [fetchActivitySuccess],
        [fetchActivityFailed]
    );
};

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import {handleAsyncAction} from '../../../../Setup/Axios/ApiHandlers';

export const initialize = (payload) => ({
    type: CONST.INITIALIZE,
    payload,
});


export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});

export const setMode = (payload) => ({
    type: CONST.SET_MODE,
    payload,
});

export const setAppBarHeight = (payload) => ({
    type: CONST.SET_APPBAR_HEIGHT,
    payload:payload,
})

export const setActiveMenu = (payload) => ({
    type: CONST.SET_ACTIVE_MENU,
    payload:payload,
})

export const setBlockUI = (payload) => ({
    type: CONST.BLOCKUI_STATE,
    payload:payload,
})

export const setModal = (payload) => ({
    type: CONST.SET_MODAL,
    payload:payload,
})

export const setLeftDrawer = (payload) => ({
    type: CONST.SET_LEFT_DRAWER,
    payload:payload,
})

export const setRightDrawer = (payload) => ({
    type: CONST.SET_RIGHT_DRAWER,
    payload:payload,
})

// Fetch Modules
export const fetchAppConfigSuccess = (payload) => ({
    type: CONST.FETCH_CONFIG_SUCCESS,
    payload,
});

export const fetchAppConfigFailed = (payload) => ({
    type: CONST.FETCH_CONFIG_FAILED,
    payload,
});

export const fetchAppConfig = ({ dispatch }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('app/config'),
        [fetchAppConfigSuccess],
        fetchAppConfigFailed
    );
}


/****************************************
 FETCH ALL BANKS
 ****************************************/
export const fetchBanksSuccess = (payload) => ({
    type: CONST.FETCH_BANKS_SUCCESS,
    payload: payload,
});

export const fetchBanksFailed = (payload) => ({
    type: CONST.FETCH_BANKS_FAILED,
    payload: payload,
});

export const fetchBanks = ({dispatch}) => {
    // Use handleAsyncAction for async operations
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('bank'),
        fetchBanksSuccess,
        fetchBanksFailed
    );
};

/****************************************
 FETCH ALL LOV
 ****************************************/
export const fetchLOVSuccess = (payload) => ({
    type: CONST.FETCH_LOVS_SUCCESS,
    payload: payload,
});

export const fetchLOVFailed = (payload) => ({
    type: CONST.FETCH_LOVS_FAILED,
    payload: payload,
});

export const fetchLOV = ({dispatch}) => {
    // Use handleAsyncAction for async operations
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('lov'),
        fetchLOVSuccess,
        fetchLOVFailed
    );
};
import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

/****************************************
 SET SELECTED CUSTOMER
 ****************************************/
export const setSelectedCustomer = (payload) => ({
    type: CONST.SET_SELECTED_CUSTOMER,
    payload: payload
});

/****************************************
 FETCH CUSTOMER
 ****************************************/
export const fetchCustomerSuccess = (payload) => ({
    type: CONST.FETCH_CUSTOMER_SUCCESS,
    payload: payload
});

export const fetchCustomerFailed = (payload) => ({
    type: CONST.FETCH_CUSTOMER_FAILED,
    payload: payload
});

export const fetchCustomer = ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/customer/${id}`),
        [fetchCustomerSuccess],
        [fetchCustomerFailed]
    );
};

/****************************************
 FETCH CUSTOMERS
 ****************************************/
export const fetchCustomersSuccess = (payload) => ({
    type: CONST.FETCH_CUSTOMERS_SUCCESS,
    payload: payload
});

export const fetchCustomersFailed = (payload) => ({
    type: CONST.FETCH_CUSTOMERS_FAILED,
    payload: payload
});

export const fetchCustomers = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/customer', { params: data }),
        [fetchCustomersSuccess],
        [fetchCustomersFailed]
    );
};

/****************************************
 ADD Business CUSTOMER
 ****************************************/
export const addCustomerSuccess = (payload) => ({
    type: CONST.ADD_CUSTOMER_SUCCESS,
    payload: payload
});

export const addCustomerFailed = (payload) => ({
    type: CONST.ADD_CUSTOMER_FAILED,
    payload: payload
});

export const addCustomer = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/customer', data),
        [addCustomerSuccess],
        [addCustomerFailed]
    );
};

/****************************************
 Update Business CUSTOMER
 ****************************************/
export const updateCustomerSuccess = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_SUCCESS,
    payload: payload
});

export const updateCustomerFailed = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_FAILED,
    payload: payload
});

export const updateCustomer = ({ dispatch, data }) => {
    data['status'] = 'SUBMITTED';
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/customer/${data.customer_id}`, data),
        [updateCustomerSuccess],
        [updateCustomerFailed]
    );
};

/****************************************
 Verify a Customer
 ****************************************/
export const verifyCustomerSuccess = (payload) => ({
    type: CONST.VERIFY_CUSTOMER_SUCCESS,
    payload: payload
});

export const verifyCustomerFailed = (payload) => ({
    type: CONST.VERIFY_CUSTOMER_FAILED,
    payload: payload
});

export const verifyCustomer = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`corporate/customer/verify/${data.customer_id}`, data),
        [verifyCustomerSuccess],
        [verifyCustomerFailed]
    );
};

/****************************************
 Reject a Customer
 ****************************************/
export const rejectCustomerSuccess = (payload) => ({
    type: CONST.REJECT_CUSTOMER_SUCCESS,
    payload: payload
});

export const rejectCustomerFailed = (payload) => ({
    type: CONST.REJECT_CUSTOMER_FAILED,
    payload: payload
});

export const rejectCustomer = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`corporate/customer/reject/${data.customer_id}`, data),
        [rejectCustomerSuccess],
        [rejectCustomerFailed]
    );
};

/****************************************
 Return a Customer
 ****************************************/
export const returnCustomerSuccess = (payload) => ({
    type: CONST.RETURN_CUSTOMER_SUCCESS,
    payload: payload
});

export const returnCustomerFailed = (payload) => ({
    type: CONST.RETURN_CUSTOMER_FAILED,
    payload: payload
});

export const returnCustomer = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`corporate/customer/return/${data.customer_id}`, data),
        [returnCustomerSuccess],
        [returnCustomerFailed]
    );
};

/****************************************
 Approve a Customer
 ****************************************/
export const approveCustomerSuccess = (payload) => ({
    type: CONST.APPROVE_CUSTOMER_SUCCESS,
    payload: payload
});

export const approveCustomerFailed = (payload) => ({
    type: CONST.APPROVE_CUSTOMER_FAILED,
    payload: payload
});

export const approveCustomer = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`corporate/customer/approve/${data.customer_id}`, data),
        [approveCustomerSuccess],
        [approveCustomerFailed]
    );
};

/****************************************
 DELETE Business CUSTOMER
 ****************************************/
export const deleteCustomerSuccess = (payload) => ({
    type: CONST.DELETE_CUSTOMER_SUCCESS,
    payload: payload
});

export const deleteCustomerFailed = (payload) => ({
    type: CONST.DELETE_CUSTOMER_FAILED,
    payload: payload
});

export const deleteCustomer = ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/customer/${id}`),
        [deleteCustomerSuccess],
        [deleteCustomerFailed]
    );
};


/****************************************
  Mark existing customers for invoice discounting
 ****************************************/
export const markCustomersForInvoiceDiscountingSuccess = (payload) => ({
    type: CONST.MARK_CUSTOMERS_FOR_INVOICE_DISCOUNTING_SUCCESS,
    payload: payload
});

export const markCustomersForInvoiceDiscountingFailed = (payload) => ({
    type: CONST.MARK_CUSTOMERS_FOR_INVOICE_DISCOUNTING_FAILED,
    payload: payload
});

export const markCustomersForInvoiceDiscounting = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`corporate/customer/mark_customers_for_invoice_discounting`, data),
        [markCustomersForInvoiceDiscountingSuccess],
        [markCustomersForInvoiceDiscountingFailed]
    );
};
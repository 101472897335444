import React, { useState} from 'react'
import { TextField} from "@mui/material";
import {useDispatch, useSelector,} from "react-redux";
import {useNavigate, } from "react-router-dom";
import { returnCustomer, } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";

export const ReturnCustomerModal = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customer = useSelector((store) => store.business.customers.selected);
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();

    const handleSubmit = async () => {
        let data = {
            'status': 'RETURNED',
            'reason':reason,
            'customer_id': customer.customer_id
        }
        setDisabled(true);
        await returnCustomer({dispatch, data});
        dispatch(setModal({component:null, open:false}));
        navigate('/customers');
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value === '');
    }

    return(
        <>
            <DialogTitle>Return Customer</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to Return this customer?
                </DialogContentText>
                <TextField
                    placeholder={"Reason for Return"}
                    fullWidth
                    required
                    onBlur={handleReasonChange}
                    type={"text"}
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Return Customer</Button>
            </DialogActions>
        </>
    )
}

import React, {useEffect} from 'react';
import {
    CardContent,
    CardHeader,
    List as ListUI,
    ListItem,
    ListItemText as ListItemTextUI,
    ListItemText
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Panel} from "../../../../../Components";
import {useDispatch, useSelector} from "react-redux";
import {fetchFundingStats} from "../../Redux/Action";
import {setModal} from "../../../../Common/App";
import {LoansAtStatusModal} from "../Modals/LoansAtStatusModal";
import {ServicesAtStatusModal} from "../Modals/ServicesAtStatusModal";

export const FundingStatsPanel = () => {

    const dispatch = useDispatch();
    const stats = useSelector(state => state.dashboard.stats);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        await fetchFundingStats({dispatch});
    }

    const handleClick = (secondary, type) => {

        if(type === "SERVICE"){
            dispatch(setModal({component:<ServicesAtStatusModal status={secondary} />, open:true, fullScreen:false}));
        }else{
            dispatch(setModal({component:<LoansAtStatusModal status={secondary} />, open:true, fullScreen:false}));
        }
    };

    const List = styled(ListUI)({
        '& .MuiListItem-root':{
            paddingTop:'6px!important',
            paddingBottom:'6px!important',
            borderBottom:'1px dashed #ddd',
            cursor:'pointer',
            '&:hover':{
                backgroundColor:'#f5f5f5',
            }
        },
        '& .MuiListItem-root:last-child':{
            borderBottom:'none',
        },
        '& .MuiListItemText-root':{
            display:'flex',
            marginTop:'6px!important',
            marginBottom:'6px!important',
            justifyContent:'space-between',
            '& span':{
                fontSize:14,
                fontWeight:'normal',
            },
            '& p':{
                fontSize:11,
                fontWeight:'normal',
            }
        }
    })

    const ListItemText = styled(ListItemTextUI)(({ theme, secondary }) => ({
        '& span': {
            color: secondary > 5 ? theme.palette.error.main : '#444444',
        },
        '& p': {
            backgroundColor: '#efefef',
            padding:'0.15rem 0.3rem',
            borderRadius:'0.15rem',
            minWidth:'25px',
            textAlign:'center',
            color: secondary > 5 ? theme.palette.error.main : '#222222',
        },
    }));

    return (
        <Panel>
            <CardHeader title={"Funding Queue"} />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={"Business Analyst"}
                            secondary={stats?.loanRequested}
                            onClick={() => handleClick(["PENDING"], "SERVICE")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Credit Manager"}
                            secondary={stats?.loanApproved}
                            onClick={() => handleClick(["VERIFIED"],"SERVICE")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Operations"}
                            secondary={stats?.loanWithOperation}
                            onClick={() => handleClick(["DRAFT"],"LOAN")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Documentation Team"}
                            secondary={stats?.loanWithDocumentation}
                            onClick={() => handleClick(["VERIFIED"],"LOAN")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Disbursement Team"}
                            secondary={stats?.loanWithDisbursed}
                            onClick={() => handleClick(["DOCUMENTED"],"LOAN")}
                        />
                    </ListItem>
                </List>
            </CardContent>
        </Panel>
    )
}
import * as React from 'react';
import {Box, Grid, } from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {StatementPanel} from "./Panels/StatementPanel";

export const Statements = () => {

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/STATEMENTS'} sx={{display:'inline-block'}} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <StatementPanel />
                </Grid>
            </Grid>
    );
}
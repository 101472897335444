import * as React from 'react';
import {useDispatch} from "react-redux";
import {documentLoan} from "../../Redux/Action";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {setModal} from "../../../../Common/App";
import Toastr from "../../../../../Components/Elements/Toastr";

export const DocumentLoanRequestModal = ({loan_id}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState(null);

    const handleSubmit = async () => {
        setDisabled(true);
        await documentLoan({dispatch, data:{loan_id}}).then((res) => {
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
                navigate('/business-finance');
            }
            setDisabled(false);
            setResponse(res);
        });
    };

    return(
        <>
            <DialogTitle>Documentation Completed</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, the documentation for this loan is completed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Done</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}
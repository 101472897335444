import * as React from 'react';
import { TextField as TextFieldUI, MenuItem, Select, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { countries } from '../../Setup/Config';
import debounce from "lodash.debounce";

export const PhoneField = ({ styles, onChange, ...props }) => {

    const TextField = styled(TextFieldUI)({
        border: 0,
        borderRadius: 10,
    });

    // Split defaultValue into countryCode and phoneNumber
    const [countryCode, setCountryCode] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");

    React.useEffect(() => {
        if (props.defaultValue) {
            const [initialCountryCode, initialPhoneNumber] = props.defaultValue.split('-');
            setCountryCode(initialCountryCode);
            setPhoneNumber(initialPhoneNumber);
        }
    }, [props.defaultValue]);

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        if (onChange) {
            onChange({ target: { name: props.name, value: `${event.target.value}-${phoneNumber}` } });
        }
    };

    const debouncedHandlePhoneNumberChange = React.useCallback(
        debounce((event) => {
            setPhoneNumber(event.target.value);
            if (onChange) {
                onChange({ target: { name: props.name, value: `${countryCode}-${event.target.value}` } });
            }
        }, 1500),
        [countryCode, onChange]
    );

    const handlePhoneNumberChange = (event) => {
        event.persist();
        debouncedHandlePhoneNumberChange(event);
    };

    const handleKeyPress = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Grid container sx={{ ...styles }}>
            <Grid item md={3} pr={2}>
                <Select
                    fullWidth
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    renderValue={(selected) => {
                        const country = countries.find(c => c.phonecode === selected);
                        return (
                            <span>{country ? country.emoji : ''}</span>
                        );
                    }}
                    sx={{
                        '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft:'0',
                            padding:'12px 0!important',
                            fontSize:'22px',
                            justifyContent:'center'
                        },
                    }}
                >
                    {countries.map((country) => (
                        <MenuItem key={country.iso3} value={country.phonecode}>
                            {country.emoji} {country.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item md={9}>
                <TextField
                    {...props}
                    defaultValue={phoneNumber || ""}
                    onChange={handlePhoneNumberChange}
                    fullWidth
                    onKeyPress={handleKeyPress}
                />
            </Grid>
        </Grid>
    );
}

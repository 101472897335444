import {adminReducer, investorReducer, corporateReducer, staffReducer, defaultReducer} from "./AppReducer";
import storage from 'redux-persist/lib/storage'

const reducerMap = {
    admin: adminReducer,
    office: adminReducer,
    "backoffice-dev": adminReducer,
    user: staffReducer,
    investor: investorReducer,
    "investor-dev": investorReducer,
    business: corporateReducer,
    "business-dev": corporateReducer,
    default: defaultReducer,
};

const rootReducer = (state, action) => {

    if (action.type === 'APP/LOGOUT_SUCCESS') {
        storage.removeItem('persist:ifund');
        const selectedReducer = reducerMap.default;
        return selectedReducer(undefined, action);
    }

    let mode = state?.app?.mode;

    if(action.type === 'APP/FETCH_CONFIG_SUCCESS'){
        mode = action.payload.data?.mode;
    }

    const selectedReducer = reducerMap[mode] || reducerMap.default;
    return selectedReducer(state, action);
};

export default rootReducer;
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Link as LinkUI, useNavigate} from "react-router-dom";
import {setBlockUI, } from "../../../App";
import {addSupportQuery} from "../../Redux/Action";
import {styled} from "@mui/material/styles";
import {Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";

export const SupportQueryForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        dispatch(setBlockUI({open:false}));
    }, [])

    const handleChange = (e) => {
        setDisabled(false)
        setData({...data, [e.target.id]: e.target.value});
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await addSupportQuery({dispatch, data}).then((res) => {
            setResponse(res);
            if (res['success']) {
                setDisabled(false)
                setTimeout(() => { navigate('/login'); }, 2000);
            }
        });
    }

    const Link = styled(LinkUI)(({ theme }) => ({
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontSize:'1.2rem',
        '&:hover': {
            cursor:'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    return (
        <Stack spacing={3} sx={{minWidth:400}}>

            <Typography variant={"h3"} sx={{fontWeight:'300', fontSize:40, fontFamily:'Open Sans'}} color={"primary"}>Share your query</Typography>

            <>
                <TextField
                    type="text"
                    id="name"
                    onChange={handleChange}
                    value={ data?.name || ''}
                    required
                    label="Full Name"
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                        style: {fontSize: 20}
                    }}
                    helperText={response?.error?.data?.errors?.name}
                />
                <TextField
                    type="text"
                    id="email"
                    onChange={handleChange}
                    value={ data?.email || ''}
                    required
                    label="Email"
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                        style: {fontSize: 20}
                    }}
                    helperText={response?.error?.data?.errors?.email}
                />
                <TextField
                    type="text"
                    id="phone"
                    onChange={handleChange}
                    value={ data?.phone || ''}
                    required
                    label="Phone"
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                        style: {fontSize: 20}
                    }}
                    helperText={response?.error?.data?.errors?.phone}
                />
                <TextField
                    type="text"
                    id="message"
                    name={"message"}
                    multiline={true}
                    rows={4}
                    onChange={handleChange}
                    value={ data?.message || ''}
                    required
                    label="Message"
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                        style: {fontSize: 20}
                    }}
                    helperText={response?.error?.data?.errors?.message}
                />

            </>

            <Button disabled={disabled} color="primary" sx={{ width:'100%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Submit</Button>

            <Stack direction={"row"} spacing={2} justifyContent="space-between">
                <Link to={'/login'} className={'mb-3 d-block text-right'}>Login</Link>
            </Stack>
            <Toastr response={response} />
        </Stack>
    )
}
import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {PartnerForm} from "../Forms/PartnerForm";
import {Grid, Typography, List, ListItem} from "@mui/material";
import { addPartner, clearErrors, proceed, setSelectedPartner,PartnerDeleteModal } from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect} from "react";
import {countries} from "../../../../../Setup/Config";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";

export const PartnerDetailStep = () => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.partners).length > 0 ? business.partners.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}))
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addPartner({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedPartner(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<PartnerForm business={business} />, open:true}))
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<PartnerDeleteModal />, open:true}))
        dispatch(setSelectedPartner(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'name', headerName:'Name', type: 'string', flex:1, minWidth: 200,
                valueGetter: (params) => params.row.type === 'Company' ? params.row.company_name : params.row.name,
            },
            { field: 'type', headerName:'Type', type: 'string', flex:1, minWidth: 100},
            { field: 'holding', headerName:'Holding', type: 'string', flex:1, minWidth: 100 },
            { field: 'nationality', headerName:'Nationality', type: 'string', flex:1, minWidth:200,
                valueGetter: (params) => params.row.type === 'Company' ? params.row.license_issue_country: params.row.nationality,
                valueFormatter: ({value}) => {
                    return value && (countries.filter((obj) => obj.iso3 === value))[0]?.name
                },
            },
            { field: 'passport_no', headerName:'Passport No', type: 'string', flex:1, minWidth: 120,
                valueGetter: (params) => params.row.type === 'Company' ? params.row.license_no: params.row.passport_no,
            },
            { field: 'aecb_score', headerName:'AECB Score', type: 'string', flex:1, minWidth: 120 },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );



    return (
        <Grid container>
            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    rowHeight={50}
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items ?? []}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Partner</Button>
                    </Grid>
                    { items && items.length === 0 &&
                    <Grid item xs={12}>
                        <Instructions />
                    </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
                <Typography variant={"h3"}>Owners Details</Typography>
                <Typography variant={"body1"}>
                    To complete this section, you need to enter the details of all the shareholders and the following documents are required for attachment.
                    </Typography>
                <List>
                    <ListItem>Passport Copy</ListItem>
                    <ListItem>UAE Visa for resident</ListItem>
                    <ListItem>Emirates ID</ListItem>
                    <ListItem>Residence Utility bill / Tenancy Contract</ListItem>
                    <ListItem>AECB Report</ListItem>
                </List>
                <Typography variant={"body2"}>Please note if the shareholder is Company, then attached the Trade License of holding company and other related documents.</Typography>
            </InfoBox>)
}
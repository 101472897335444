import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    MenuItem,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl, FormControlLabel, FormLabel, InputLabel,
    Radio, RadioGroup, Select,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addPermission} from "../../index";
import {setRightDrawer} from "../../../../Common/App";

export const AddPermission = () => {
    const dispatch = useDispatch();
    const items = useSelector((store)=> store.permissions);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] : e.target.name === 'is_active'? parseInt(e.target.value) : e.target.value,
        }));
    }

    const handleSubmit = async() => {
        setDisabled(true);
        await addPermission({dispatch, data}).then((res) => setResponse(res))
        dispatch(setRightDrawer({component:null, open:false}));
    };

    return(
        <Card sx={{ height: '100%', borderRadius:0, position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
            <CardHeader
                action={
                    <IconButton aria-label="share" onClick={ () => dispatch(setRightDrawer({component:null, open:false})) }>
                        <CloseIcon />
                    </IconButton>
                }
                avatar={
                    <Typography>Add Permission</Typography>
                }
            />
            <CardContent sx={{ flex:1, overflow:'auto' }}>
                <TextField
                    fullWidth
                    label="Permission Name"
                    name="name"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.name}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Label"
                    name="label"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.label}
                    onChange={(e) => handleChange(e)}
                />
                <FormControl sx={{ width:'100%', mb:3  }}>
                    <InputLabel id="add-user-form-label-office">Type</InputLabel>
                    <Select
                        defaultValue={''}
                        name="type"
                        MenuProps={{
                            style: {zIndex: 35001}
                        }}
                        fullWidth
                        label="Type"
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="feature">Feature</MenuItem>
                        <MenuItem value="menu">Menu</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ width:'100%', mb:2  }}>
                    <InputLabel id="add-user-form-label-office">Group</InputLabel>
                    <Select
                        defaultValue={''}
                        name="group"
                        MenuProps={{
                            style: {zIndex: 35001}
                        }}
                        fullWidth
                        label="Type"
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="accounts">Accounts</MenuItem>
                        <MenuItem value="dashboard">Dashboard</MenuItem>
                        <MenuItem value="services">Services</MenuItem>
                        <MenuItem value="business">Business</MenuItem>
                        <MenuItem value="investor">Investor</MenuItem>
                        <MenuItem value="wallet">Wallet</MenuItem>
                        <MenuItem value="request">Request</MenuItem>
                        <MenuItem value="funding">Funding</MenuItem>
                        <MenuItem value="transaction">Transaction</MenuItem>
                        <MenuItem value="invoice-discounting">Invoice Discounting</MenuItem>
                        <MenuItem value="invoices">Invoices</MenuItem>
                        <MenuItem value="customers">Customers</MenuItem>
                        <MenuItem value="business-finance">Business Finance</MenuItem>
                        {/*<MenuItem value="loan">Loan</MenuItem>*/}
                        <MenuItem value="permission">Permission</MenuItem>
                        <MenuItem value="role">Role</MenuItem>
                        <MenuItem value="user">User</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    multiline
                    rows={3}
                    helperText={items.error?.description}
                    onChange={(e) => handleChange(e)}
                />

                <FormControl>
                    <FormLabel id="status-label">Status</FormLabel>
                    <RadioGroup
                        row
                        onChange={(e) => handleChange(e)}
                        aria-labelledby="status-label"
                        name="is_active"
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Active" />
                        <FormControlLabel value="0" control={<Radio />} label="Suspend" />
                    </RadioGroup>
                </FormControl>
            </CardContent>
            <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                <Button disabled={disabled} variant={'contained'} color={'primary'} onClick={ handleSubmit }>Add Permission</Button>
                <Button variant={'contained'} color={"secondary"} onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>Close</Button>
            </CardActions>
        </Card>
    )
}

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const proceed = (payload) => ({
    type: CONST.CLEAR_PROCEED_STATE,
    payload: payload,
});

export const clearErrors = (payload) => ({
    type: CONST.CLEAR_ERROR_STATE,
    payload: payload,
});

export const setSelectedSummary = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload,
});

/**************************************/
/*          Fetch Summary             */
/**************************************/
export const fetchSummarySuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload,
});

export const fetchSummaryFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload,
});

export const fetchSummary = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/summary/${id}`),
        [fetchSummarySuccess],
        [fetchSummaryFailed]
    );
};

/**************************************/
/*          Fetch Summaries            */
/**************************************/
export const fetchSummariesSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload,
});

export const fetchSummariesFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload,
});

export const fetchSummaries = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/summary', { params: data }),
        [fetchSummariesSuccess],
        [fetchSummariesFailed]
    );
};

/**************************************/
/*          Add a Summary             */
/**************************************/
export const addSummarySuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload,
});

export const addSummaryFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload,
});

export const addSummary = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/summary', data),
        [addSummarySuccess],
        [addSummaryFailed]
    );
};

/**************************************/
/*          Update a Summary          */
/**************************************/
export const updateSummarySuccess = (payload) => ({
    type: CONST.UPDATE_ITEM_SUCCESS,
    payload: payload,
});

export const updateSummaryFailed = (payload) => ({
    type: CONST.UPDATE_ITEM_FAILED,
    payload: payload,
});

export const updateSummary = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/summary/${data.id}`, data),
        [updateSummarySuccess],
        [updateSummaryFailed]
    );
};

/**************************************/
/*          Delete a Summary          */
/**************************************/
export const deleteSummarySuccess = (payload) => ({
    type: CONST.DELETE_ITEM_SUCCESS,
    payload: payload,
});

export const deleteSummaryFailed = (payload) => ({
    type: CONST.DELETE_ITEM_FAILED,
    payload: payload,
});

export const deleteSummary = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/summary/${id}`),
        [deleteSummarySuccess],
        [deleteSummaryFailed]
    );
};

import * as React from 'react';
import {Typography as TypographyUI} from "@mui/material";
import moment from "moment/moment";
import {styled} from "@mui/material/styles";

export default function AuditDetailTemplate(props) {

    const Typography = styled(TypographyUI)({
        display: 'inline-block',
        fontWeight: 'normal!important',
        fontSize:'0.875rem',
        marginRight:'5px',
        whiteSpace: 'nowrap',
    });

    return (
        <>
            {
                (()=>{
                    switch (props.type) {
                        case "assigned":
                            return <>
                                <Typography>to </Typography>
                                <Typography color={"primary"}>{ props?.data?.assigned_to?.first_name + " " + props?.data?.assigned_to?.last_name }</Typography>
                                <Typography> at </Typography>
                                <Typography color={"primary"} variant={"body1"}>{ moment(props?.data?.updated_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "created":
                            return <>
                                <Typography>by </Typography>
                                <Typography color={"primary"}>{ props?.data?.created_by?.first_name + " " + props?.data?.created_by?.last_name }</Typography>
                                <Typography> at </Typography>
                                <Typography color={"primary"} variant={"body1"}>{ moment(props?.data?.created_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "updated":
                            return <>
                                <Typography>by </Typography>
                                <Typography color={"primary"}>{ props?.data?.updated_by?.first_name + " " + props?.data?.updated_by?.last_name }</Typography>
                                <Typography> at </Typography>
                                <Typography color={"primary"} variant={"body1"}>{ moment(props?.data?.updated_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "verified":
                            return <>
                                <Typography>by </Typography>
                                <Typography color={"primary"}>{ props?.data?.verified_by?.first_name + " " + props?.data?.verified_by?.last_name }</Typography>
                                <Typography> at </Typography>
                                <Typography color={"primary"} variant={"body1"}>{ moment(props?.data?.verified_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        case "approved":
                            return <>
                                <Typography>by </Typography>
                                <Typography color={"primary"}>{ props?.data?.approved_by?.first_name + " " + props?.data?.approved_by?.last_name }</Typography>
                                <Typography> at </Typography>
                                <Typography color={"primary"} variant={"body1"}>{ moment(props?.data?.approved_at).format('DD/MM/YYYY, h:mm a') }</Typography>
                            </>
                        }
                })()
            }
        </>
    );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {PersonalForm, AddressForm, BankDetailsForm, DeclarationForm, EmploymentForm} from "../../index";
import Divider from "@mui/material/Divider";
import {useRef} from "react";
import {Grid} from "@mui/material";

const steps = [
    { "step": 1, "name": 'Personal Details' },
    { "step": 2, "name": 'Address Details' },
    { "step": 3, "name": 'Employment Details' },
    { "step": 4, "name": 'Bank Details' },
    { "step": 5, "name": 'Declaration' },
];

export const RegistrationCompleteForm = () => {

    const personalFormRef = useRef();
    const addressFormRef = useRef();
    const employmentFormRef = useRef();
    const bankDetailsFormRef = useRef();
    const declarationFormRef = useRef();

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [response, setResponse] = React.useState({});

    const isStepOptional = (step) => {
        return step === 10;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = async () => {

        let r = {};
        if(steps[activeStep].step === 1){
            await personalFormRef.current.handleSubmit().then((res)=>{
                r = res
                setResponse(res)
            });
        }else if(steps[activeStep].step === 2){
            await addressFormRef.current.handleSubmit().then((res)=>{
                r = res
                setResponse(res)
            });
        }else if (steps[activeStep].step === 3){
            await employmentFormRef.current.handleSubmit().then((res)=>{
                r = res
                setResponse(res)
            });
        }else if (steps[activeStep].step === 4){
            await bankDetailsFormRef.current.handleSubmit().then((res)=>{
                r = res
                setResponse(res)
            });
        }else if (steps[activeStep].step === 5){
            await declarationFormRef.current.handleSubmit().then((res)=>{
                r = res
                setResponse(res)
            });
        }

        // If the form is valid, then move to the next step
        if(r !== false && !r['error']){
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderForm = () => {
        switch (steps[activeStep].step) {
            case 1:
                return <PersonalForm ref={personalFormRef} setResponse={setResponse} />;
            case 2:
                return <AddressForm ref={addressFormRef} setResponse={setResponse} />;
            case 3:
                return <EmploymentForm ref={employmentFormRef} setResponse={setResponse} />;
            case 4:
                return <BankDetailsForm ref={bankDetailsFormRef} setResponse={setResponse} />;
            case 5:
                return <DeclarationForm ref={declarationFormRef} setResponse={setResponse} />;
            default:
                return null;
        }
    };

    return (
        <Grid item container>
            <Grid item md={2} justifyContent={"center"}>
                <Stepper activeStep={activeStep} orientation="vertical" sx={{paddingTop:'105px', marginLeft:'50px'}}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label.name} {...stepProps}>
                                <StepLabel {...labelProps}>{/*label.name*/}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item container xs={12} md={10}>

                {activeStep === steps.length ? (
                    <Grid item xs={12}>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item container xs={12}>
                        {renderForm()}
                        {/*<Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>*/}

                        <Divider height={1} />
                        <Grid item sx={{ display: 'flex', flexDirection: 'row', pt: 4 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}

                            <Button color={"primary"} onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

import React, {useEffect, useState} from 'react'
import {Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../../Common/App";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {UserAutocomplete} from "../../../../Administration/Users/Components/UserAutocomplete";
import {assignBusiness} from "../../Redux/Action";
import {clearData} from "../../../../Administration/Users";
import {useNavigate} from "react-router-dom";

export const BusinessAssignmentModal = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector((store) => store.business.selected);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        dispatch(clearData());
        setData({
            business_id: business.business_id,
            user_id: null,
        });
    }, [business]);

    const handleSubmit = async () => {
        setDisabled(true);
        await assignBusiness({dispatch, data}).then((res) => {
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
                navigate('/dashboard')
            }
        });
    };

    return(
        <>
            <DialogTitle>Business Assignment</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText>
                        Select the user you want to assign this business to.
                    </DialogContentText>
                    <UserAutocomplete setDisabled={setDisabled} setData={setData} data={data} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Assign</Button>
            </DialogActions>
        </>
    )
}

import React from 'react';
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {MetricTemplateCard} from "./MetricTemplateCard";

export const OverdueCard = () => {

    const count = useSelector(store => store.dashboard.stats?.overdue);
    const displayedCount = useCounterEffect(0, count);

    return (
        <>
            <MetricTemplateCard unit={"AED"} title={"Overdue"} color={ count > 0 ? "error" : "dark" } displayedCount={displayedCount} link={"/overdue"} linkText={"See All"} />
        </>
    );
}

import React, { useState} from 'react'
import { TextField} from "@mui/material";
import {useDispatch, useSelector,} from "react-redux";
import {useNavigate, } from "react-router-dom";
import { rejectCustomer } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";
import { setModal } from "../../../../Common/App";



export const RejectCustomerModal = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customer = useSelector((store) => store.business.customers.selected);
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        let data = {
            'reason': reason,
            'customer_id': customer.customer_id
        }
        setDisabled(true);
        await rejectCustomer({dispatch, data}).then((res) => {
            setResponse(res);
            if(res['success']){
                dispatch(setModal({component:null, open:false}))
                navigate('/customers');
            }
        });
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value === '');
    }

    return(
        <>
            <DialogTitle>Reject Customer</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to reject this customer?
                </DialogContentText>
                <TextField
                    placeholder={"Reason for Rejection"}
                    fullWidth
                    required
                    onBlur={handleReasonChange}
                    defaultValue={""}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'error'} onClick={ handleSubmit }>Reject Business</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}
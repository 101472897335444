import React, {useEffect, useState} from 'react'
import {
    CardContent,
    CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import {useSelector} from "react-redux";
import {currencyFormatter, currencyFormatterDecimal} from "../../../../../Setup/Config"
import {Panel} from "../../../../../Components";
import {SkeletonTable} from "../../../../../Components/Elements/SkeletonTable";
import Typography from "@mui/material/Typography";

export const ProductWiseStatsPanel = () => {

    const investments = useSelector(store => store.dashboard?.product_wise_stats);

    const serviceNameMap = {
        "INVOICE_DISCOUNTING" : "Invoice Discounting",
        "RECEIVABLE_DISCOUNTING" : "Receivable Discounting",
    }


    return (
        <>
            <Panel>
                <CardHeader
                    title={'Investment Projection'}
                    subheader={"253 investments over 248 businesses"}
                    sx={{
                        '& .MuiCardHeader-subheader': { fontSize: '0.7rem' },
                        '& .MuiCardHeader-title': { fontSize: '0.9rem' }
                    }}
                />
                <CardContent sx={{ p: 0 }}>
                    {investments.length > 0 ? (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell>Portfolio Share</TableCell>
                                        <TableCell>Aggregate Investment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {investments.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{serviceNameMap[row.service_type] || "Unknown Product"}</TableCell>
                                            <TableCell>{row.percentage ? currencyFormatterDecimal.format(row.percentage) + "%" : "-"}</TableCell>
                                            <TableCell>{row.repayment ? currencyFormatter.format(row.repayment) : "-"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography align={"center"} variant={"h4"} sx={{margin:'20px auto'}}>
                            No Records Available
                        </Typography>
                    )}
                </CardContent>
            </Panel>
        </>
    );

}

const CONST = {
    'SET_CONFIG':'ITEMS/SET_CONFIG',
    'SET_SELECTED_ITEM':'ITEMS/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'ITEMS/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'ITEMS/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'ITEMS/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'ITEMS/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'ITEMS/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'ITEMS/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'ITEMS/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'ITEMS/UPDATE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'ITEMS/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'ITEMS/DELETE_ITEM_SUCCESS',
}

export default CONST;
import React from 'react'
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {MetricTemplateCard} from "./MetricTemplateCard";

export const ApprovedCustomersCard = () =>  {

    const customers = useSelector(store => store.dashboard.stats?.customers);
    const displayedCount = useCounterEffect(0, customers);

    return (
        <>
            <MetricTemplateCard title={"Approved Customers"} displayedCount={displayedCount} link={"/customers"} linkText={"See All"} />
        </>
    );
}

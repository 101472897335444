import React, {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    List as ListUI,
    ListItemSecondaryAction,
    ListItemText, TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Button from "@mui/material/Button";
import {updateSetting} from "../../Redux/Action";
import {useDispatch, useSelector} from "react-redux";

export const SecuritySettingsTab = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.settings.list);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const desiredParameters = ['two_factor_authentication_activated','max_login_attempts'];
        const newData = {};

        items.forEach(item => {
            if (desiredParameters.includes(item.parameter_name)) {
                newData[item.parameter_name] = item.parameter_value;
            }
        });

        setData(newData);
    }, [items]);

    const ActionList = styled(ListUI)({
        width: '100%',
        '& .MuiListItem-root': {
            backgroundColor: '#f6f6f6',
            padding: '0.3rem',
            borderRadius: '5px',
            marginBottom: '0.5rem',
        },
        '& .MuiListItemIcon-root': {
            justifyContent: 'center',
        }
    });

    const handleChange = (event) => {

        const { name, value } = event.target;
        const updatedValue = Array.isArray(value) ? value.join(',') : value;

        setData({
            ...data,
            [name]: updatedValue
        });
    }

    const handleSubmit = async () => {

        const formattedData = Object.keys(data).map((key) => {
            return {parameter_name: key, parameter_value: data[key]}
        });

        setLoading(true)
        await updateSetting({dispatch, data:{...formattedData}}).then((res) => {
            setLoading(false)
        });
    }

    const handleStateButton = async (parameter_name) => {

        const newData = [{parameter_name, 'parameter_value':data[parameter_name] === 'true' ? 'false' : 'true'}];
        await updateSetting({dispatch, data:{ ...newData }});
    }

    return (
        <>
            <Card>
                <CardHeader title={"Security Settings"} />
                <CardContent sx={{pb:'1rem!important'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Two-Factor Authentication (2FA)</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Enable an extra layer of security for your account.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.two_factor_authentication_activated === 'true' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Two-factor authentication (2FA)" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.two_factor_authentication_activated === 'true' ? 'error' : 'primary'} onClick={() => handleStateButton('two_factor_authentication_activated')}>
                                                    {data.two_factor_authentication_activated === 'true' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Mail Configurations</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    No of days to review the business.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data?.max_login_attempts ?? ""}
                                        label="Max Login Attempts"
                                        variant="outlined"
                                        defaultValue=""
                                        name={"max_login_attempts"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant={"contained"} onClick={()=> handleSubmit()}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
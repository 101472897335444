import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {ChequeForm} from "../Forms/ChequeForm";
import {Grid, List, ListItem, Stack, Typography} from "@mui/material";
import { addCheque, clearErrors, proceed, setSelectedCheque, ChequeDeleteModal} from "../../index";
import moment from "moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";

export const ChequesDetailsStep = () => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.cheques).length > 0 ? business.cheques.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCheque({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCheque(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<ChequeForm />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<ChequeDeleteModal />, open:true}));
        dispatch(setSelectedCheque(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'bank_id', headerName:'Bank/Institution', type: 'string', flex:1, minWidth: 300,
                valueGetter: (params) => {
                    return params.row?.bank?.bank_name;
                }
            },
            { field: 'cheque_no', headerName:'Cheque No', type: 'string', flex:1, minWidth:90 },
            { field: 'party_name', headerName:'Issued To', type: 'string', flex:1, minWidth:260 },
            { field: 'cheque_date', headerName:'Date', type: 'date', flex:1, minWidth:100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'amount', headerName:'Amount', type: 'number',  flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.cheque_id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Cheque</Button>
                    </Grid>
                    { items && items.length === 0 &&
                        <Grid item xs={12}>
                            <Instructions />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
        <Typography variant={"h3"}>Returned Cheques (if any)</Typography>
        <Typography variant={"body1"}>
            To complete this section, fill in the required details for the cheque returns. Also mentioned reason for such return and how it was settled. Attach the following documents:
        </Typography>
        <List>
            <ListItem>Settlement Document (if any)</ListItem>
            <ListItem>Legal Case Document (if any)</ListItem>
        </List>
    </InfoBox>)
}
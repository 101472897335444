import * as React from 'react';
import { Card, CardHeader, CardActions, List as ListUI, CardContent, ListItem, ListItemText, TextField, Button, Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../../Common/App";
import {SupportingDocumentBankDetailsModal} from "../Modals/SupportingDocumentBankDetailsModal";
import {addTask} from "../../../../Common/Tasks";

export const BankDetailsTab = () => {

    const dispatch = useDispatch();
    const [editMode, setEditMode] = React.useState(false);
    const profile = useSelector((store) => store.profile);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);

    const handleEditBtn = () => {
        setEditMode(!editMode);
    };

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(profile.selected?.bank);
    }, [])

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
        '& .MuiListItem-root': {
            marginBottom:'10px',
        },
    });

    const handleSaveBtn = async () => {

        setDisabled(true);

        await addTask({dispatch, data: {...data, title:'Bank Details Verification', type:'BANK_DETAILS_VERIFICATION', description:'Bank Details Verification'}}).then((res) => {
            if(res['success']){
                dispatch(setModal({component:<SupportingDocumentBankDetailsModal />, open:true}));
                setDisabled(false);
                setEditMode(false);
            }
        });
    }

    const handleCancelBtn = () => {
        // Reset data and set editMode to false
        setData(profile?.selected?.bank);
        setEditMode(false);
    };

    return (
        <Card>
            <CardHeader title={"Bank Details"} action={!editMode &&<Button onClick={handleEditBtn}>Edit</Button>} />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <List>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Account Name"
                                        name="account_name"
                                        defaultValue={data?.account_name}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.account_name}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Account Name" secondary={data?.account_name ?? "-" } />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (

                                    <TextField
                                        fullWidth
                                        label="Bank Name"
                                        name="bank_name"
                                        defaultValue={data?.bank_name}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.bank_name}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Bank Name" secondary={data?.bank_name ?? "-" } />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="IBAN"
                                        name="iban_no"
                                        defaultValue={data?.iban_no}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.iban_no}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="IBAN" secondary={data?.iban_no ?? "-"} />
                                )}
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <List>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Account No"
                                        name="account_no"
                                        defaultValue={data?.account_no}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.account_no}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Account Number" secondary={data?.account_no ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Branch Name"
                                        name="bank_branch"
                                        defaultValue={data?.bank_branch}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.bank_branch}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Branch Name" secondary={data?.bank_branch ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Swift Code"
                                        name="swift_code"
                                        defaultValue={data?.swift_code}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.swift_code}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Swift Code" secondary={data?.swift_code ?? "-"} />
                                )}
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
            {editMode &&
                <CardActions sx={{p:2, justifyContent:'end'}}>
                    <Stack spacing={2} justifyContent={"space-between"} direction={"row"}>
                        <Button variant="contained" color="secondary" size="small" onClick={handleCancelBtn}>Cancel</Button>
                        <Button variant="contained" size="small" onClick={handleSaveBtn}>Save</Button>
                    </Stack>
                </CardActions>
            }
        </Card>
    );
};

import * as React from 'react';
import {
    Badge,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    ListItemAvatar,
    Popover as PopoverUI
} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {List as ListUI} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import moment from "moment/moment";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {markAllReadNotification} from "../../Modules/Common/Auth";

export const InvestorNotificationMenu = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const notifications = useSelector(store => store.app.notifications);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMarkAllRead = async () => {
        markAllReadNotification(dispatch).then((res) => {
            // add loader
        });
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const Popover = styled(PopoverUI)({
        '& .MuiPaper-root':{
            boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.2)',
            [theme.breakpoints.down('md')]: {
                minWidth: '100%', // Minimum width for desktop view
                minHeight:'100%',
                left:'0!important',
                top:'0!important',
                height:'100%',
            },
        }
    });

    const PopoverCard = styled(Card)({
        width: '100%',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root':{
            flex: '1',
            overflowY:'auto',
            maxHeight:'300px',
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'inherit',
            },
            '& .MuiListItemAvatar-root':{
                minWidth: 'auto',
                marginRight: '15px',
                '& svg':{
                    width:'0.8rem'
                }
            },
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 300,
        },
    });

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        padding:0,
        '& .MuiListItem-root':{
            padding: '5px 15px',

            '& .MuiListItemText-root':{
                borderBottom:'1px solid #f7f7f7',
            },
        },
        '& .MuiListItem-root:last-child':{
            '& .MuiListItemText-root':{
                borderBottom:'none',
            }
        }
    });

    return (
        <>
            <IconButton
                size="large"
                aria-label="show 2 new notifications"
                color="dark"
                onClick={handleClick}
            >
                <Badge badgeContent={notifications?.length} color="error">
                    <NotificationsIcon sx={{ fontSize: '1.7rem'}} />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PopoverCard>
                    <CardHeader title={"Notifications"} titleTypographyProps={{fontWeight:'bold', fontSize:'1rem'}}
                                action={<IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={handleClose}
                                            aria-label="close"
                                            size={'small'}
                                            sx={{ position: 'absolute', top: '8px', right: '15px' }}
                                        >
                                            <CloseIcon fontSize={'0.5rem'} />
                                        </IconButton>}>
                    </CardHeader>
                    <CardContent sx={{p:0, pb:'0!important'}}>
                        { notifications.length !== 0 ?
                        <List>
                            {notifications.map(notification => (
                                <ListItem key={notification.id}>
                                    <ListItemAvatar>
                                        <FiberManualRecordIcon sx={{ color: notification.read ? theme.palette.primary.dark : theme.palette.primary.main}} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={decodeTemplate(notification.data.template, notification.data)}
                                        secondary={ moment(notification.created_at).fromNow() + '' }
                                    />

                                </ListItem>
                            ))}
                        </List>
                            :
                            <Typography sx={{p:2}}>No New Notifications</Typography>
                        }
                    </CardContent>
                    { notifications.length > 0 &&
                        <CardActions><Button onClick={ handleMarkAllRead }>Mark all as read</Button></CardActions>
                    }
                </PopoverCard>

            </Popover>
        </>
    );
}


const decodeTemplate = (template, data) => {

    switch (template) {
        case 'GENERAL':
            return `${data.message}`;
        case 'CUSTOMER_SUBMITTED':
            return `Customer "${data.company_name}" is submitted for verification`;
        case 'CUSTOMER_VERIFIED':
            return `Customer "${data.company_name}" is verified, pending for approval`;
        case 'CUSTOMER_APPROVED':
            return `Customer "${data.company_name}" is approved, proceed for service request`;
        case 'SERVICE_CREATED':
            return `Service "${data.service_type}" is created`;
        case 'SERVICE_VERIFIED':
            return `Service "${data.service_type}" is verified`;
        case 'SERVICE_APPROVED':
            return `Service "${data.service_type}" is approved`;
        case 'BUSINESS_SUBMITTED':
            return `Business "${data.name}" is submitted for verification`;
        case 'BUSINESS_VERIFIED':
            return `Business "${data.name}" is verified`;
        case 'BUSINESS_APPROVED':
            return `Business "${data.name}" is approved`;
        case 'BUSINESS_REJECTED':
            return `Business "${data.name}" is rejected`;
        case 'BUSINESS_RETURNED':
            return `Business "${data.name}" is returned`;
        case 'INVOICE_SUBMITTED':
            return `Invoice "${data.invoice_no}" is submitted for verification`;
        case 'INVOICE_VERIFIED':
            return `Invoice "${data.invoice_no}" is verified`;
        case 'INVOICE_APPROVED':
            return `Invoice "${data.invoice_no}" is approved`;
        case 'LOAN_CREATED':
            return `Business Finance No. "${data.loan_number}" is issued`;
        default:
            return `Unknown template: ${template}`;
    }
};
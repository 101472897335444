import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl, FormControlLabel, FormLabel,
    InputLabel, Radio, RadioGroup, Select,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState, useEffect} from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector} from "react-redux";
import { updateUser } from "../../index";
import { setRightDrawer} from "../../../../Common/App";

export const EditUser = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.users);
    const roles = useSelector((store) => store.roles);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    useEffect(() => {
        setData(items.selected);
    },[items.selected])

    const handleChange = (e) => {

        const { name, value } = e.target;

        setDisabled(false);
        setData(data => ({
            ...data,
            [name]: value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updateUser({dispatch, data}).then((res) => setResponse(res));
    };

    return(
        <>
            { (data !== undefined && Object.keys(data).length > 0) &&
                <Card sx={{ height: '100%', position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
                <CardHeader
                    action={
                        <IconButton aria-label="share" onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>
                            <CloseIcon />
                        </IconButton>
                    }
                    avatar={
                        <Typography>Edit User</Typography>
                    }
                />
                <CardContent sx={{ flex:1 }}>
                    <TextField
                        fullWidth
                        label="First Name"
                        name="first_name"
                        onChange={(e) => handleChange(e)}
                        variant="outlined"
                        size="large"
                        value={ data['first_name'] }
                        sx={{mb:3}}
                        helperText={items.error?.first_name}
                    />
                    <TextField
                        fullWidth
                        label="Last Name"
                        name="last_name"
                        onChange={(e) => handleChange(e)}
                        value={ data['last_name'] }
                        variant="outlined"
                        size="large"
                        sx={{mb:3}}
                        helperText={items.error?.last_name}
                    />

                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={ data['email'] }
                        variant="outlined"
                        size="large"
                        sx={{mb:3}}
                        helperText={items.error?.email}
                    />
                    <TextField
                        fullWidth
                        label="Mobile"
                        name="mobile"
                        onChange={(e) => handleChange(e)}
                        value={ data['mobile'] }
                        variant="outlined"
                        size="large"
                        sx={{mb:3, flex:1}}
                        helperText={items.error?.mobile}
                    />
                    <FormControl sx={{ width:'100%', mb:3 }}>
                        <InputLabel id="add-user-form-label-office">Role</InputLabel>
                        <Select
                            multiple
                            value={data['roles']}
                            name="roles"
                            MenuProps={{
                                style: {zIndex: 35001}
                            }}
                            fullWidth
                            label="Role"
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem value="">None</MenuItem>
                            {roles.list && roles.list.length > 0 && roles.list.map((role) => (
                                <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width:'100%', mb:3  }}>
                        <InputLabel id="add-user-form-label-office">Role Type</InputLabel>
                        <Select
                            value={ data['role_type'] }
                            name="role_type"
                            MenuProps={{
                                style: {zIndex: 35001}
                            }}
                            fullWidth
                            label="Role Type"
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem key={ "ADMIN" } value={ "ADMIN" }> { "Admin" } </MenuItem>
                            <MenuItem key={ 'USER' } value={ 'USER' }> { 'User' } </MenuItem>
                            <MenuItem key={ 'CLIENT' } value={ 'CLIENT' }> { 'Client' } </MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        label="OTP"
                        onChange={(e) => handleChange(e)}
                        value={ data['otp'] }
                        variant="outlined"
                        size="large"
                        disabled
                        sx={{mb:3, flex:1}}
                        helperText={items.error?.otp}
                    />


                    <FormControl>
                        <FormLabel id="status-label">Status</FormLabel>
                        <RadioGroup
                            row
                            value={ data['is_active'] }
                            onChange={(e) => handleChange(e)}
                            aria-labelledby="status-label"
                            name="is_active"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Active" />
                            <FormControlLabel value="0" control={<Radio />} label="Suspend" />
                        </RadioGroup>
                    </FormControl>

                </CardContent>
                <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                    <Button disabled={disabled} color="primary" variant="contained" onClick={ handleSubmit }>Save</Button>
                    <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>Close</Button>
                </CardActions>
            </Card>}
        </>
    )
}

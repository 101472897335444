import * as React from 'react';
import { useDispatch } from "react-redux";
import { loanStatement, earlySettlement } from "../../Redux/Action";
import {
    Grid,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import { setModal } from "../../../../Common/App";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import { styled } from "@mui/material/styles";
import {currencyFormatter} from "../../../../../Setup/Config";

export const EarlySettlementLoanModal = ({ loan_id }) => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({
        settlement_date: null,
        remaining_tenor: 0,
        settlement_fee: 0,
        loan_id
    });
    const [response, setResponse] = useState({});
    const [isReview, setIsReview] = useState(true); // Track whether we're in "Review" state

    useEffect(() => {
        setIsReview(true); // Reset to "Review" when date changes
        setDisabled(false);
    },[data]);

    const handleLoanStatement = async () => {
        setDisabled(true);
        await loanStatement({ dispatch, data }).then(res => {
            if (res['success']) {
                setIsReview(false); // Move to "Settle" state
                setDisabled(false); // Enable the button again
            }
            setResponse(res);
        });
    };

    const handleEarlySettlement = async () => {

        setDisabled(true);

        await earlySettlement({ dispatch, data }).then(res => {
            if (res['success']) {
                dispatch(setModal({ component: null, open: false }));
            }
        });
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData({...data, [e.target.name]: e.target.value});
    }

    const StyledList = styled(List)(({ theme }) => ({
        '& .MuiListItem-root': {
            background: '#f9f9f9',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1),
            display: 'flex', // Use Flexbox to align items in one row
            justifyContent: 'space-between', // Space between primary and secondary text
            alignItems: 'center', // Center items vertically
            padding: theme.spacing(1, 2), // Adjust padding as needed
            '&:last-child': {
                marginBottom: 0,
            },
            '& .MuiListItemText-root': {
                display: 'flex', // Flexbox for the text container
                flexDirection: 'row', // Ensure primary and secondary text are in a row
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%', // Full width for flexbox to work
                '& .MuiListItemText-primary': {
                    fontWeight: 'normal',
                    fontSize: '.85rem',
                    color: '#666',
                    marginRight: theme.spacing(2), // Space between primary and secondary text
                },
                '& .MuiListItemText-secondary': {
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#000',
                    marginBottom: 0,
                }
            }
        }
    }));


    return (
        <>
            <DialogTitle>Early Settlement</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MuiDatepicker
                            error={response?.error?.data?.errors?.settlement_date}
                            setDisabled={setDisabled}
                            label={"Settlement Date"}
                            name="settlement_date"
                            data={data}
                            state={setData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Remaining Tenor"}
                            name="remaining_tenor"
                            defaultValue={data?.remaining_tenor}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.remaining_tenor}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Settlement Fees"}
                            name="settlement_fee"
                            defaultValue={data?.settlement_fee}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.settlement_fee}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        { !isReview && (
                            <StyledList>
                                <ListItem>
                                    <ListItemText primary={"Loan Amount"} secondary={ currencyFormatter.format(response?.data?.data?.loan_amount)} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Paid"} secondary={currencyFormatter.format(response?.data?.data?.paid)} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Balance"} secondary={currencyFormatter.format(response?.data?.data?.balance)} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Refund Amount"} secondary={currencyFormatter.format(response?.data?.data?.refund)} />
                                </ListItem>
                            </StyledList>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({ component: null, open: false }))}>Cancel</Button>
                <Button
                    disabled={disabled}
                    variant={"contained"}
                    color={'primary'}
                    onClick={isReview ? handleLoanStatement : handleEarlySettlement}
                >
                    {isReview ? "Review" : "Settle"}
                </Button>
            </DialogActions>
        </>
    );
};

import * as React from 'react';
import {
    Alert,
    Container as ContainerUI,
    Stack,
    TextField as TextFieldUI,
    TextField,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {resendOtp, verifyOtp} from "../../Redux/Action";

export const VerifyEmailForm = () => {

    const otpLength = 6;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showLoginButton, setShowLoginButton] = useState(false);
    const user = useSelector(state => state.auth.user);

    const [counter, setCounter] = useState(60); // Initial counter value in seconds
    const [disabled, setDisabled] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [response, setResponse] = useState({});

    const [otp, setOtp] = useState(Array(otpLength).fill(''));
    const inputRefs = Array(otpLength).fill(0).map((_, i) => React.createRef());
    const [focused, setFocused] = useState(0);

    let intervalId;

    const Container = styled(ContainerUI)(({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
            //backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
        }
    }));

    useEffect(() => {
        if (counter > 0 && showTimer) {
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);
        } else {
            // Reset the counter and disable the timer
            setCounter(60);
            clearInterval(intervalId);
            setShowTimer(false);
            setDisabled(false);
        }

        return () => clearInterval(intervalId);
    }, [counter, showTimer]);

    const handleInputChange = (index, value) => {
        // Allow only one character per TextField
        if (value.length > 1) {
            return;
        }

        // Update the value in the array
        const newValues = [...otp];
        newValues[index] = value;
        setOtp(newValues);

        // Move focus to the next TextField
        if (value && index < otpLength - 1) {
            setFocused(index + 1);
        }
    };

    const handlePaste = (event, index) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text');
        if (pastedText.length <= otpLength) {
            const newValues = [...otp];
            newValues.splice(index, pastedText.length, ...pastedText.split('').slice(0, otpLength - index));
            setOtp(newValues);
            setFocused(index + pastedText.length - 1);
        }
    };

    const TextField = styled(TextFieldUI)({
        width: '50px',
        height: '50px',
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        '& input': {
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
        }
    });

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
            setFocused(index - 1);
        }
    };
    const handleResendClick = async () => {

        setDisabled(true);
        setShowTimer(true);

        await resendOtp({ dispatch, data: { email: user.email } }).then((res) => {
            setResponse(res);
        });
    };

    const handleSubmitBtn = async () => {

        await verifyOtp({ dispatch, data: { otp: otp.join(""), email: user.email } }).then((res) => {
            // Handle response
            setResponse(res);
            setShowLoginButton(res["success"]);
        });
    }



    return (
        <>
            <Stack spacing={3} sx={{width:400}}>
                {!showLoginButton ? (
                    <Box p={4} sx={{ width: '100%', alignItems:'center',}}>

                        <Typography variant={"h3"} sx={{ fontWeight: '600' }} color={"primary"}>Email Verification</Typography>

                        <Stack  spacing={2} justifyContent={"left"} sx={{mt:2, maxWidth:400}}>

                            <Stack direction={"row"} justifyContent={"space-between"} sx={{maxWidth:400, marginTop:3}}>
                                {otp.map((value, index) => (
                                    <TextField
                                        key={index}
                                        variant="outlined"
                                        size="small"
                                        value={value}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        onKeyDown={(event) => handleKeyDown(event, index)}
                                        onPaste={(event) => handlePaste(event, index)}
                                        inputRef={inputRefs[index]}
                                        autoFocus={index === focused}
                                    />
                                ))}
                            </Stack>

                            {showTimer && (<Alert color={"info"}>{counter > 0 ? `Resend in ${counter} seconds` : ''}</Alert>)}

                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mt: 5 }}>

                                <Button disabled={disabled} variant={"contained"} color={"warning"} onClick={handleResendClick}>Resend</Button>

                                <Button variant={"contained"} color={"primary"} onClick={handleSubmitBtn}>Submit</Button>

                            </Stack>
                        </Stack>
                    </Box>
                ) : (
                    <Box p={4} sx={{ height: '50vh', width: '100%', alignItems:'center',}}>
                        <Typography variant={"h3"} sx={{ fontWeight: '600' }} color={"primary"}>Email Verified</Typography>
                        <Alert sx={{my:3}} color={"success"}>Your email has been verified successfully.</Alert>
                        <Button variant={"contained"} color={"primary"} onClick={() => navigate('/login')}>Login</Button>
                    </Box>
                )}
            </Stack>
        </>
    )
}
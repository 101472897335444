import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import { CreditCardForm } from "../Forms/CreditCardForm";
import {Grid, Stack} from "@mui/material";
import { addCreditCard, clearErrors, proceed, setSelectedCreditCard,CreditCardDeleteModal } from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";

export const CreditCardStep = () => {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.creditCards).length > 0 ? business.creditCards.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCreditCard({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCreditCard(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<CreditCardForm />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<CreditCardDeleteModal />, open:true}));
        dispatch(setSelectedCreditCard(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'bank_id', headerName:'Bank/Institution', type: 'string', flex:1, minWidth:220,
                valueGetter: (params) => {
                    return params.row?.bank?.bank_name;
                }
            },
            { field: 'type', headerName:'Card Type', type: 'string', flex:1, minWidth: 100 },
            { field: 'card_holder_name', headerName:'Card Holder Name', type: 'string', flex:1, minWidth: 135 },
            { field: 'limit', headerName:'Limit', type: 'number', flex:1, minWidth: 120, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'outstanding', headerName:'Outstanding', type: 'number', flex:1, minWidth: 130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'overdue', headerName:'Overdue', type: 'number', flex:1, minWidth: 130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>

            <Grid item xs={12}  >
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.card_id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={objects || []} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Credit Card</Button>
                </Stack>
            </Grid>
        </Grid>
    );
}
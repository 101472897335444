import * as React from 'react';
import {Box, } from "@mui/material";
import {fetchListings, ListingCard, SearchPanel} from '../index'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {Pagination} from "./Pagination";

export const Sidebar = () => {

    const dispatch = useDispatch();
    const listings = useSelector(state => state.listings.list);
    const config = useSelector(state => state.listings.config);
    const filters = config.filters;
    const pagination = config.pagination;

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async() => {
        await fetchListings({dispatch, data:{...filters, ...pagination}})
    }

    return (
        <Box sx={{ height:'100%', display: 'flex', flexDirection: 'column'}}>
            <SearchPanel />
            <Box sx={{ flex: '1 1 auto', height:'100px', overflow: 'auto', p:2 }}>
                {listings.map((item, index) => (
                    <ListingCard item={item} key={index} />
                ))}
            </Box>
            <Box sx={{ p:1, borderTop:'1px solid #ddd', backgroundColor:'#efefef'}}>
                <Pagination config={config} />
            </Box>
        </Box>
    );
}
import * as React from 'react';
import {
    Grid,
    TextField,
    Typography,
    Button,
    Stack,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {doRegister} from "../../Redux/Action";
import Toastr from "../../../../../Components/Elements/Toastr";
import {useNavigate} from "react-router-dom";
import {setBlockUI} from "../../../App";
import {captureLead, resetUsers} from "../../../../Administration/Users";
import MuiDatepicker from "../../../../Corporate/Business/Components/Datepicker";
import {RegistrationFailedCard} from "../Cards/RegistrationFailedCard";
import {RegistrationSuccessCard} from "../Cards/RegistrationSuccessCard";


const checkValidCompany = (date) => {

    const corporation_date = new Date(date);
    let last_year = new Date();
    last_year = last_year.setDate(last_year.getDate() - 180);
    return corporation_date < last_year;
}

export const CorporateRegistrationForm =()  => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [leadCaptured, setLeadCaptured] = useState(false);
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const [data, setData] = useState({});

    useEffect(()=>{
        setLeadCaptured(false);
        setRegistrationComplete(false);
        dispatch(setBlockUI({open:false}));
        dispatch(resetUsers(false));
    },[]);

    const handleSubmit = async () => {
        setResponse(false);
        setDisabled(true);
        dispatch(setBlockUI({open:true}));

        if(checkValidCompany(data['company_incorporation_date'])){
            data['service'] = 'RECEIVABLE_DISCOUNTING';
            data['total_revenue'] = 0;
            await doRegister({dispatch, data}).then((res)=>{
                dispatch(setBlockUI({open:false}));
                setResponse(res);
                setRegistrationComplete(res['success']);
                if(res['success']){
                    navigate('/email-verification')
                }
            });
        }else{
            await captureLead({dispatch, data}).then((res)=>{
                dispatch(setBlockUI({open:false}));
                setLeadCaptured(res['success']);
                setResponse(res);
            });
        }
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const passwordError = (response) => {
        if (response?.error?.data?.errors?.password) {
            const passwordErrors = response.error.data.errors.password.filter(msg => !msg.includes('confirmation'));
            return passwordErrors.join(',\n');
        }
        return "";
    };

    const passwordConfirmationError = (response) => {
        if (response?.error?.data?.errors?.password) {
            const passwordErrors = response.error.data.errors.password.filter(msg => msg.includes('confirmation'));
            return passwordErrors.join(', ');
        }
        return "";
    }

    const ALPHABETS = /^[a-zA-Z]+$/;

    return (
        <>
            { (!leadCaptured && !registrationComplete) ?
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12}>
                    <Typography variant={"h2"} sx={{fontSize:'1.7rem'}} my={3}>Let's get started</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        type="text"
                        name="first_name"
                        onKeyDown={(e) => {
                            if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                e.preventDefault();
                            }
                        }}
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={ data['first_name'] ?? ""}
                        required
                        label="First Name"
                        helperText={response?.error?.data?.errors?.first_name}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        type="text"
                        name="last_name"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={data['last_name'] ?? ""}
                        required
                        onKeyDown={(event) => {
                            if (!ALPHABETS.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        label="Last Name"
                        helperText={response?.error?.data?.errors?.last_name}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        type="email"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={data['email'] ?? ""}
                        required
                        label="Email"
                        inputProps={{
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        helperText={response?.error?.data?.errors?.email}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        type="tel"
                        name="mobile"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={data['mobile'] ?? ""}
                        required
                        label="Mobile"
                        inputProps={{
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        helperText={response?.error?.data?.errors?.mobile}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        name={"password"}
                        label={"Password"}
                        variant={"outlined"}
                        required
                        type={"password"}
                        onChange={handleChange}
                        helperText={passwordError(response)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        name={"password_confirmation"}
                        label={"Re-Password"}
                        variant={"outlined"}
                        required
                        type={"password"}
                        inputProps={{
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        onChange={handleChange}
                        helperText={passwordConfirmationError(response)}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <MuiDatepicker error={response?.error?.data?.errors?.company_incorporation_date} setDisabled={setDisabled} label={"Company Incorporation Date"} past={true} name="company_incorporation_date" data={data} state={setData} />
                </Grid>

                <Grid item xs={12} md={7}>
                    <TextField
                        fullWidth
                        type="text"
                        name="company_name"
                        onKeyDown={(e) => {
                            if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                e.preventDefault();
                            }
                        }}
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={ data['company_name'] ?? ""}
                        required
                        label="Company Name"
                        helperText={response?.error?.data?.errors?.company_name}
                    />
                </Grid>

                {/*<Grid item xs={12} md={6}>*/}
                {/*    <TextField*/}
                {/*        fullWidth*/}
                {/*        type="text"*/}
                {/*        name="total_revenue"*/}
                {/*        onChange={(e) => handleChange(e)}*/}
                {/*        value={data['total_revenue'] ?? 0}*/}
                {/*        required*/}
                {/*        label="Total Revenue"*/}
                {/*        inputProps={{*/}
                {/*            form: {*/}
                {/*                autocomplete: 'off',*/}
                {/*            },*/}
                {/*        }}*/}
                {/*        helperText={response?.error?.data?.errors?.total_revenue}*/}
                {/*    />*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="text"
                        name="amount"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={data['amount'] ?? ""}
                        required
                        label="Required Funding Amount"
                        inputProps={{
                            form: {
                                autocomplete: 'off',

                            },
                        }}
                        helperText={response?.error?.data?.errors?.amount}
                    />
                </Grid>

                {/*<Grid item xs={12} md={6}>*/}
                {/*    <FormControl sx={{ width:'100%' }}>*/}
                {/*        <InputLabel id="card-type">Funding Tenor</InputLabel>*/}
                {/*        <Select*/}
                {/*            value={""}*/}
                {/*            name="tenor"*/}
                {/*            MenuProps={{*/}
                {/*                style: {zIndex: 35001}*/}
                {/*            }}*/}
                {/*            fullWidth*/}
                {/*            label="Funding Tenor"*/}
                {/*            onChange={(e) => handleChange(e)}*/}
                {/*        >*/}
                {/*            <MenuItem key={""} value=" " disabled>Select Tenor</MenuItem>*/}
                {/*            <MenuItem key={"3M"} value={'3M'}>3 Months</MenuItem>*/}
                {/*            <MenuItem key={"6M"} value={'6M'}>6 Months</MenuItem>*/}
                {/*            <MenuItem key={"12M"} value={'12M'}>12 Months</MenuItem>*/}
                {/*        </Select>*/}
                {/*        <FormHelperText>{response?.error?.data?.errors?.tenor}</FormHelperText>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12}>*/}
                {/*    <FormControl sx={{ width:'100%' }} hidden={true}>*/}
                {/*        <InputLabel id="card-type">Funding Tenor</InputLabel>*/}
                {/*        <Select*/}
                {/*            value={""}*/}
                {/*            name="tenor"*/}
                {/*            MenuProps={{*/}
                {/*                style: {zIndex: 35001}*/}
                {/*            }}*/}
                {/*            fullWidth*/}
                {/*            label="Funding Tenor"*/}
                {/*            onChange={(e) => handleChange(e)}*/}
                {/*        >*/}
                {/*            <MenuItem key={""} value=" " disabled>Select Tenor</MenuItem>*/}
                {/*            <MenuItem key={"3M"} value={'3M'}>3 Months</MenuItem>*/}
                {/*            <MenuItem key={"6M"} value={'6M'}>6 Months</MenuItem>*/}
                {/*            <MenuItem key={"12M"} value={'12M'}>12 Months</MenuItem>*/}
                {/*        </Select>*/}
                {/*        <FormHelperText>{response?.error?.data?.errors?.tenor}</FormHelperText>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12}>*/}
                {/*    <FormControl sx={{ width:'100%' }}>*/}
                {/*        <InputLabel id="service">Service</InputLabel>*/}
                {/*        <Select*/}
                {/*            defaultValue={"RECEIVABLE_DISCOUNTING"}*/}
                {/*            name="service"*/}
                {/*            MenuProps={{*/}
                {/*                style: {zIndex: 35001}*/}
                {/*            }}*/}
                {/*            fullWidth*/}
                {/*            label="Service"*/}
                {/*            onChange={(e) => handleChange(e)}*/}
                {/*        >*/}
                {/*            <MenuItem key={""} value={""} disabled>Select Service</MenuItem>*/}
                {/*            <MenuItem key={"RECEIVABLE_DISCOUNTING"} value={'RECEIVABLE_DISCOUNTING'}>Receivable Discounting</MenuItem>*/}
                {/*            /!*<MenuItem key={"INVOICE_DISCOUNTING"} value={'INVOICE_DISCOUNTING'}>Invoice Discounting</MenuItem>*!/*/}
                {/*        </Select>*/}
                {/*        <FormHelperText>{response?.error?.data?.errors?.service}</FormHelperText>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="text"
                        name="purpose"
                        multiline
                        rows={4}
                        onChange={(e) => handleChange(e)}
                        value={data['purpose'] ?? ""}
                        autoComplete="off"
                        required
                        label="Purpose of Funding"
                        inputProps={{
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        helperText={response?.error?.data?.errors?.purpose}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                        <Button variant={"contained"} size={"large"} color={"warning"} onClick={() => navigate('/login')}>Already have an Account?</Button>
                        { !leadCaptured && <Button disabled={disabled} variant={"contained"} size={"large"} color={"primary"} onClick={handleSubmit}>Proceed</Button> }
                    </Stack>
                </Grid>
            </Grid>
            :
            <Grid container spacing={4} justifyContent={"center"}>
                <Grid item xs={12}>
                    { leadCaptured && <RegistrationFailedCard /> }
                    { registrationComplete && <RegistrationSuccessCard /> }
                </Grid>
            </Grid>
            }
        <Toastr response={response} />
        </>
    )
}

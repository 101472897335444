import * as React from "react";
import {styled} from "@mui/material/styles";
import List from "@mui/material/List";
import {Avatar, Card as CardUI, CardActions, CardHeader, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {currencyFormatter} from "../../../../../Setup/Config";
import LinearProgress from "@mui/material/LinearProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useEffect, useState} from "react";
import {fetchDocument} from "../../../../Common/Documents";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

export const ListingCard = React.memo(({ item }) => {

    const dispatch = useDispatch();
    const [avatarUrl, setAvatarUrl] = useState(null);

    useEffect(() => {
        fetchAvatar()
    }, []);

    const fetchAvatar = async () => {
        const defaultAvatar = 'https://www.w3schools.com/howto/img_avatar.png';
        if (item.business?.avatar?.document_id === undefined) return setAvatarUrl(defaultAvatar);
        await fetchDocument({dispatch, id: item.business.avatar?.document_id}).then((res) => {
            setAvatarUrl(res.data);
        });
    }

    const StatsList = styled(List)({
        flexGrow:1,
        padding:0,
        '& .MuiListItem-root':{
            padding:0,
            '& .MuiListItemText-root':{
                margin:'5px',
                textAlign:'center',
                '& span':{
                    fontSize:'0.85rem',
                    fontWeight:'bold',
                },
                '& p':{
                    fontSize:'0.75rem',
                    marginBottom:'0!important'
                }
            }
        }
    });

    const Card = styled(CardUI)({
        color: 'darkslategray',
        borderRadius:'0',
        padding:'0',
        backgroundColor:'#ffffff',
        border:'1px solid #ddd',
        boxShadow:'0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
        '& .MuiCardHeader-root':{
            borderBottom:'none',
            padding:'8px',
            alignItems: 'flex-start',
            '& .MuiCardHeader-avatar':{
                marginRight:'8px',
                '& .MuiAvatar-root':{
                    borderRadius:'4px'
                }
            },
            '& .MuiCardHeader-content':{
                '& a':{
                    color:'#000',
                    textDecoration:'none',
                },
                '& .MuiCardHeader-subheader p':{
                    fontSize:'.70rem'
                }
            },
            '& .MuiCardHeader-action':{
                fontSize:'1rem',
                marginRight:0,
                marginLeft:'5px',
                marginTop:'14px',
                position:'relative',
                width:'100px',
                textAlign:'center',
                '& p':{
                    fontWeight:'bold'
                }
            }
        },
        '& .MuiCardContent-root':{
            position:'relative',
            padding:'5px',
            '& .amount':{
                fontSize:'1.5rem',
                padding:0,
                fontWeight:'bold',
                textAlign:'center',
                color:'rgb(56 41 0 / 60%)',
            }
        },
        '& .MuiLinearProgress-root':{
            height:'2px',
            width:'100%',
            position:'absolute',
            bottom:'-15px',
            left:'0',
        },
        '& .MuiCardActions-root':{
            padding:'0px 5px',
            background:'#ededed',
            borderTop: '1px solid #ccc',
            //display:'none'
        },
    });

    return(
        <Card style={{ marginBottom: '1rem' }}>
            <CardHeader
                avatar={<Avatar alt={item.business.name} src={avatarUrl} />}
                title={<Link to={'listing/' + item.id}>{item.business.name}</Link>}
                subheader={
                    <>
                        <Typography>Purpose: {item.service.purpose ?? '-'}</Typography>
                        <Typography>Industry: {item.business.trade_license_authority ?? '-'}</Typography>
                    </>
                }
                action={
                    <>
                        <Typography className={"amount"} variant="body2" color="textSecondary" component="p">
                            AED {item.service.amount ? currencyFormatter.format(item.service.amount) : '000'}
                        </Typography>
                        <LinearProgress variant="determinate" value={(item.total_funding / item.service.amount) * 100} />
                    </>
                }
            />
            <CardActions>
                <StatsList component={Stack} direction="row">
                    <ListItem>
                        <ListItemText primary={item.service.profit_rate ?? '-'} secondary={"Profit"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={item.service.tenor ?? '-'} secondary={"Tenure"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={item.business.rating ?? '-'} secondary={"Rating"} />
                    </ListItem>
                </StatsList>
            </CardActions>
        </Card>
    );
})
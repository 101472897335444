import React, {useEffect, useState} from 'react'
import {Card, CardContent, CardHeader, Grid, Stack,} from "@mui/material";
import {useDispatch} from "react-redux";
import {Breadcrumbs} from "../../../../Components";
import {
    ActionButtons,
    InvoiceTable,
    fetchInvoices,
    fetchInvoice,
} from "../index";
import InvoiceDetailsCard from "./Cards/InvoiceDetailsCard";
import {InvoicePropertiesPanel} from "./Panels/InvoicePropertiesPanel";
import {fetchBusiness, fetchCustomer} from "../../Business";
import {useParams} from "react-router-dom";
import DocumentsList from "../../../Common/Documents/Components/DocumentsList";

export const Invoices = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        await fetchInvoices({dispatch, data:{}}).then((res) => {
            //console.log(res);
        });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumbs items={'DASHBOARD/INVOICES'} />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={"Invoices"} />
                        <CardContent sx={{p:0}}>
                            <InvoiceTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export const InvoiceView = () => {

    const { invoice_id } = useParams();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async() => {
        await fetchInvoice({dispatch, id:invoice_id}).then(async (res) => {

            setResponse(res);
            if(res['success'] && res?.success?.data?.business_id !== undefined){
                await fetchBusiness({dispatch, business_id: res?.success?.data?.business_id});
                await fetchCustomer({dispatch, customer_id: res?.success?.data?.customer_id});
            }
        });
    }

    return (
        <>
            {response !== undefined && response.hasOwnProperty('success') && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <Breadcrumbs items={'DASHBOARD/INVOICES'} />
                            <ActionButtons />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <InvoiceDetailsCard />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={2}>
                            <InvoicePropertiesPanel />
                            <DocumentsList entityId={invoice_id} tab={"invoices"} />
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </>
    );

}


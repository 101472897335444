import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const fetchStatsSuccess = (payload) => ({
    type: CONST.FETCH_STATS_SUCCESS,
    payload: payload
});

export const fetchStatsFailed = (payload) => ({
    type: CONST.FETCH_STATS_FAILED,
    payload: payload
});

export const fetchStats = async ({ dispatch }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/dashboard/stats'),
        fetchStatsSuccess,
        fetchStatsFailed
    );
};


export const fetchProductWiseStatsSuccess = (payload) => ({
    type: CONST.FETCH_PRODUCT_WISE_STATS_SUCCESS,
    payload: payload
});

export const fetchProductWiseStatsFailed = (payload) => ({
    type: CONST.FETCH_PRODUCT_WISE_STATS_FAILED,
    payload: payload
});

export const fetchProductWiseStats = async ({ dispatch }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/dashboard/product-wise-stats'),
        fetchProductWiseStatsSuccess,
        fetchProductWiseStatsFailed
    );
}


export const fetchMonthlyStatsSuccess = (payload) => ({
    type: CONST.FETCH_MONTHLY_STATS_SUCCESS,
    payload: payload
});

export const fetchMonthlyStatsFailed = (payload) => ({
    type: CONST.FETCH_MONTHLY_STATS_FAILED,
    payload: payload
});

export const fetchMonthlyStats = async ({ dispatch, dates }) => {

    const params = {
        startDate: dates[0],
        endDate: dates[1],
    };

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('account/monthly-stats', { params }),
        fetchMonthlyStatsSuccess,
        fetchMonthlyStatsFailed
    );
};

import * as React from 'react';
import {useDispatch} from "react-redux";
import {
    DialogTitle,
    DialogContent,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell, Table as TableUI, Grid, Typography, IconButton, TablePagination
} from "@mui/material";
import {useEffect, useState} from "react";
import {currencyFormatter} from "../../../../../Setup/Config";
import {styled, useTheme} from "@mui/material/styles";
import {fetchLoans} from "../../../../Corporate/Loan";
import PageviewIcon from "@mui/icons-material/Pageview";
import {useNavigate} from "react-router-dom";

export const ShowDefaultContractsModal = ({data}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [pagination, setPagination] = useState({
        current_page: 0,
        pageSize: 10,
        total: 0,
        from: 0,
        to: 0,
        last_page: 0,
        data: []
    });


    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const fetchData = async (page, rowsPerPage) => {
        setIsLoading(true);
        const status = ['ACTIVE', 'OVERDUE', 'DEFAULT'];
        await fetchLoans({ dispatch, data: { business_id: data['business_id'], status: status, page, pageSize: rowsPerPage } })
            .then((res) => {
                if (res['success']) {
                    setPagination(res.data.data);
                    setItems(res.data.data.data);
                }
                setIsLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const Table = styled(TableUI)(({ theme }) => ({
        '& .link': {
            cursor: 'pointer',
            color: theme.palette.info.main,
            fontSize:'0.85rem',
        },
        '& .link:hover': {
            color: theme.palette.primary.dark,
        },
        '& .MuiTableCell-root': {
            padding: '16px 6px',
        },
    }));

    return (
        <>
            <DialogTitle>Default Loan</DialogTitle>
            <DialogContent sx={{p:0}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {isLoading && <Typography>Loading...</Typography>}
                        {!isLoading && items.length === 0 && <Typography>No data found</Typography>}
                        {!isLoading && items.length > 0 &&
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Contract No</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Default Amount</TableCell>
                                            <TableCell>Service Type</TableCell>
                                            <TableCell>Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row) => (
                                            <TableRow key={row.account_id}>
                                                <TableCell><Typography
                                                    onClick={() => navigate("/business-finance/" + row.loan_id)}
                                                    sx={{
                                                        fontSize: '0.86rem',
                                                        cursor: 'pointer',
                                                        textDecoration: 'none',
                                                        color: theme.text.link
                                                    }}>{row.loan_no}</Typography>
                                                </TableCell>
                                                <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                                                <TableCell>{currencyFormatter.format('0')}</TableCell>
                                                <TableCell>{row.service?.service_type?.name}</TableCell>
                                                <TableCell><IconButton onClick={() => navigate(`/business-finance/${row.loan_id}`) }><PageviewIcon /></IconButton></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={pagination.total}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                                />
                            </TableContainer>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}
const CONST = {
    'FETCH_CONFIGS_SUCCESS':'DASHBOARD/FETCH_CONFIGS_SUCCESS',
    'FETCH_CONFIGS_FAILED':'DASHBOARD/FETCH_CONFIGS_FAILED',
    'FETCH_STATS_SUCCESS':'DASHBOARD/FETCH_STATS_SUCCESS',
    'FETCH_STATS_FAILED':'DASHBOARD/FETCH_STATS_FAILED',
    'FETCH_PRODUCT_WISE_STATS_SUCCESS':'DASHBOARD/FETCH_PRODUCT_WISE_STATS_SUCCESS',
    'FETCH_PRODUCT_WISE_STATS_FAILED':'DASHBOARD/FETCH_PRODUCT_WISE_STATS_FAILED',
    'FETCH_MONTHLY_STATS_SUCCESS':'DASHBOARD/FETCH_MONTHLY_STATS_SUCCESS',
    'FETCH_MONTHLY_STATS_FAILED':'DASHBOARD/FETCH_MONTHLY_STATS_FAILED',
}

export default CONST;
import React, { useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    TextField,
    DialogTitle,
    DialogContent,
    Alert,
    DialogActions,
    Button,
    Stack,
    DialogContentText
} from "@mui/material";
import { setModal } from "../../../../Common/App";
import { addRequest } from "../../../Requests"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const WithdrawFundPanel = () => {

    const wallet = useSelector((store) => store.wallets.selected);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [amount, setAmount] = useState(0);
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        setDisabled(true);
        await addRequest({dispatch, data:{ type:"WITHDRAWAL", amount }}).then((res)=>{
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    const handleMaxBalance = () => {

        setAmount(wallet.balance);
    }

    const handleAmountChange = (e) => {
        const newValue = parseFloat(e.target.value);
        if(newValue <= wallet.balance) {
            setAmount(newValue);
        }
    }

    return(
        <>
            <DialogTitle>Withdraw Funds</DialogTitle>
            <DialogContent sx={{paddingTop:'20px!important', paddingBottom:'0!important'}}>
                <DialogContentText>To notify us of your transfer for faster processing please enter the amount you have or plan to transfer here.</DialogContentText>
                <Stack spacing={2}>
                    <Box sx={{position:'relative'}}>
                        <Typography color={"primary"} sx={{position:'absolute', zIndex:99999, right:20, top:15, fontSize:'.85rem', fontWeight:'bold', cursor:'pointer'}} onClick={handleMaxBalance}>MAX</Typography>
                        <TextField
                            placeholder={"Enter the bidding amount"}
                            fullWidth
                            required
                            defaultValue={amount}
                            onChange={(e) => handleAmountChange(e)}
                            type={"number"}
                        />
                    </Box>
                    {response.error
                        ?<Alert severity="error">{response.error.data.message}</Alert>
                        :<Alert severity="info">Available Balance { wallet.balance } AED</Alert>
                    }
                </Stack>
            </DialogContent>
            <DialogActions sx={{p:3}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Request</Button>
            </DialogActions>
        </>
    )
}

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});

export const setSelectedNotification = (payload) => ({
    type: CONST.SET_SELECTED_NOTIFICATION,
    payload,
});

export const fetchNotificationSuccess = (payload) => ({
    type: CONST.FETCH_NOTIFICATION_SUCCESS,
    payload,
});

export const fetchNotificationFailed = (payload) => ({
    type: CONST.FETCH_NOTIFICATION_FAILED,
    payload,
});

export const fetchNotification = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`notification/${id}`),
        [fetchNotificationSuccess],
        [fetchNotificationFailed]
    );
};

export const fetchNotificationsSuccess = (payload) => ({
    type: CONST.FETCH_NOTIFICATIONS_SUCCESS,
    payload,
});

export const fetchNotificationsFailed = (payload) => ({
    type: CONST.FETCH_NOTIFICATIONS_FAILED,
    payload,
});

export const fetchNotifications = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('notification', { params: data }),
        [fetchNotificationsSuccess],
        [fetchNotificationsFailed]
    );
};

export const addNotificationSuccess = (payload) => ({
    type: CONST.ADD_NOTIFICATION_SUCCESS,
    payload,
});

export const addNotificationFailed = (payload) => ({
    type: CONST.ADD_NOTIFICATION_FAILED,
    payload,
});

export const addNotification = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('notification', data),
        [addNotificationSuccess],
        [addNotificationFailed]
    );
};

export const updateNotificationSuccess = (payload) => ({
    type: CONST.UPDATE_NOTIFICATION_SUCCESS,
    payload,
});

export const updateNotificationFailed = (payload) => ({
    type: CONST.UPDATE_NOTIFICATION_FAILED,
    payload,
});

export const updateNotification = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`notification/${data['notification_id']}`, data),
        [updateNotificationSuccess],
        [updateNotificationFailed]
    );
};

export const deleteNotificationSuccess = (payload) => ({
    type: CONST.DELETE_NOTIFICATION_SUCCESS,
    payload,
});

export const deleteNotificationFailed = (payload) => ({
    type: CONST.DELETE_NOTIFICATION_FAILED,
    payload,
});

export const deleteNotification = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`notification/${data['id']}`),
        [deleteNotificationSuccess],
        [deleteNotificationFailed]
    );
};

import CONST from './Constant';
import axiosPrivate, {axiosPublic} from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const clearData = () => ({
    type: CONST.CLEAR_DATA,
})

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const resetUsers = () => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedUser = (payload) => ({
    type: CONST.SET_SELECTED_USER,
    payload: payload
});

export const fetchUserSuccess = (payload) => ({
    type: CONST.FETCH_USER_SUCCESS,
    payload: payload
});

export const fetchUserFailed = (payload) => ({
    type: CONST.FETCH_USER_FAILED,
    payload: payload
});

export const fetchUser = ({dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`user/${id}`),
        [fetchUserSuccess],
        [fetchUserFailed]
    );
};

export const fetchUsersSuccess = (payload) => ({
    type: CONST.FETCH_USERS_SUCCESS,
    payload: payload
});

export const fetchUsersFailed = (payload) => ({
    type: CONST.FETCH_USERS_FAILED,
    payload: payload
});

export const fetchUsers = ({dispatch, filters}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('user', { params: filters }),
        [fetchUsersSuccess],
        [fetchUsersFailed]
    );
};

export const fetchColleagueSuccess = (payload) => ({
    type: CONST.FETCH_COLLEAGUE_SUCCESS,
    payload: payload
});

export const fetchColleagueFailed = (payload) => ({
    type: CONST.FETCH_COLLEAGUE_FAILED,
    payload: payload
});

export const fetchColleague = ({dispatch, filters}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('user/colleague', { params: filters }),
        [fetchColleagueSuccess],
        [fetchColleagueFailed]
    );
};

export const createWalletSuccess = (payload) => ({
    type: CONST.CREATE_WALLET_SUCCESS,
    payload: payload
});

export const createWalletFailed = (payload) => ({
    type: CONST.CREATE_WALLET_FAILED,
    payload: payload
});

export const createWallet = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('wallet', data),
        [createWalletSuccess],
        [createWalletFailed]
    );
}

export const doRegisterSuccess = (payload) => ({
    type: CONST.REGISTER_USER_SUCCESS,
    payload: payload
});

export const doRegisterFailed = (payload) => ({
    type: CONST.REGISTER_USER_FAILED,
    payload: payload
});

export const doRegister = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPublic.post('register', data),
        [doRegisterSuccess],
        [doRegisterFailed]
    );
};


export const captureLeadSuccess = (payload) => ({
    type: CONST.CAPTURE_LEAD_SUCCESS,
    payload: payload
});

export const captureLeadFailed = (payload) => ({
    type: CONST.CAPTURE_LEAD_FAILED,
    payload: payload
});

export const captureLead = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('lead', data),
        [captureLeadSuccess],
        [captureLeadFailed]
    );
};

export const sendVerificationEmailSuccess = (payload) => ({
    type: CONST.SEND_VERIFICATION_EMAIL_SUCCESS,
    payload: payload
});

export const sendVerificationEmailFailed = (payload) => ({
    type: CONST.SEND_VERIFICATION_EMAIL_FAILED,
    payload: payload
});

export const sendVerificationEmail = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('send-verification-email', data),
        [sendVerificationEmailSuccess],
        [sendVerificationEmailFailed]
    );
};

export const doVerifySuccess = (payload) => ({
    type: CONST.VERIFY_EMAIL_SUCCESS,
    payload: payload
});

export const doVerifyFailed = (payload) => ({
    type: CONST.VERIFY_USER_FAILED,
    payload: payload
});

export const doVerify = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('verify', data),
        [doVerifySuccess],
        [doVerifyFailed]
    );
};

export const doForgotPasswordSuccess = (payload) => ({
    type: CONST.FORGOT_PASSWORD_SUCCESS,
    payload: payload
});

export const doForgotPasswordFailed = (payload) => ({
    type: CONST.FORGOT_PASSWORD_FAILED,
    payload: payload
});

export const doForgotPassword = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('forgot-password', data),
        [doForgotPasswordSuccess],
        [doForgotPasswordFailed]
    );
};

export const doResetPasswordSuccess = (payload) => ({
    type: CONST.RESET_PASSWORD_SUCCESS,
    payload: payload
});

export const doResetPasswordFailed = (payload) => ({
    type: CONST.RESET_PASSWORD_FAILED,
    payload: payload
});

export const doResetPassword = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('reset-password', data),
        [doResetPasswordSuccess],
        [doResetPasswordFailed]
    );
};

export const addUserSuccess = (payload) => ({
    type: CONST.ADD_USER_SUCCESS,
    payload: payload
});

export const addUserFailed = (payload) => ({
    type: CONST.ADD_USER_FAILED,
    payload: payload
});

export const addUser = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('user', data),
        [addUserSuccess],
        [addUserFailed]
    );
};

export const updateUserSuccess = (payload) => ({
    type: CONST.UPDATE_USER_SUCCESS,
    payload: payload
});

export const updateUserFailed = (payload) => ({
    type: CONST.UPDATE_USER_FAILED,
    payload: payload
});

export const updateUser = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`user/${data['user_id']}`, data),
        [updateUserSuccess],
        [updateUserFailed]
    );
};

export const deleteUserSuccess = (payload) => ({
    type: CONST.DELETE_USER_SUCCESS,
    payload: payload
});

export const deleteUserFailed = (payload) => ({
    type: CONST.DELETE_USER_FAILED,
    payload: payload
});

export const deleteUser = ({dispatch, data}) => {
    console.log(data)
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`user/${data['user_id']}`),
        [deleteUserSuccess],
        [deleteUserFailed]
    );
};

export const changePasswordSuccess = (payload) => ({
    type: CONST.CHANGE_PASSWORD_SUCCESS,
    payload: payload
});

export const changePasswordFailed = (payload) => ({
    type: CONST.CHANGE_PASSWORD_FAILED,
    payload: payload
});

export const changePassword = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`change-password/${data['user_id']}`, data),
        [changePasswordSuccess],
        [changePasswordFailed]
    );
};

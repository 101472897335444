import * as React from 'react';
import { Chip as ChipUI, Tooltip } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

export const StatusBall = ({ label, color, light = false, ...props }) => {

    const theme = useTheme();

    const colorToMainColor = {
        primary: theme.palette.primary.main,
        secondary: theme.palette.secondary.main,
        info: theme.palette.info.main,
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        success: theme.palette.success.main,
        // Add more mappings as needed
    };

    const colorToLightColor = {
        primary: theme.palette.primary.light,
        secondary: theme.palette.secondary.light,
        info: theme.palette.info.light,
        error: theme.palette.error.light,
        warning: theme.palette.warning.light,
        success: theme.palette.success.light,
        // Add more mappings as needed
    };

    const colorToDarkColor = {
        primary: theme.palette.primary.dark,
        secondary: theme.palette.secondary.dark,
        info: theme.palette.info.dark,
        error: theme.palette.error.dark,
        warning: theme.palette.warning.dark,
        success: theme.palette.success.dark,
        // Add more mappings as needed
    };

    const mainColor = colorToMainColor[color] || theme.palette.primary.main;
    const lightColor = colorToLightColor[color] || theme.palette.primary.light;
    const darkColor = colorToDarkColor[color] || theme.palette.primary.dark;

    const Chip = styled(ChipUI)(({ light }) => ({
        border: light ? `1px solid ${darkColor}` : 0,
        borderRadius: 4,
        height: 20,
        width: 'auto',
        cursor: 'pointer',
        color: theme.palette.getContrastText(light ? lightColor : mainColor),
        backgroundColor: light ? lightColor : mainColor,
        '& .MuiChip-label': {
            fontSize: 10,
            fontWeight: 300,
            letterSpacing: '1px',
            textTransform: 'uppercase',
            padding: '8px',
        }
    }));

    return (
        <Tooltip title={label}>
            <Chip label={label} {...props} />
        </Tooltip>
    );
};

import React from "react";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { deletePermission} from "../../index";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {setModal} from "../../../../Common/App";

export const DeletePermission = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.permissions);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);
        await deletePermission({dispatch, data:{id : items.selected.permission_id || ''}});
        dispatch(setModal({component:null, open:false}));
    };

    return(
        <>
            <DialogTitle>Delete Permission</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to remove this permission?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'error'} onClick={ handleSubmit }>Delete Permission</Button>
            </DialogActions>
        </>
    )
}
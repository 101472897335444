import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

/****************************************
 FETCH LOAN_SCHEDULES
 ****************************************/
export const fetchLoanSchedulesSuccess = (payload) => ({
    type: CONST.FETCH_LOAN_SCHEDULES_SUCCESS,
    payload: payload
});

export const fetchLoanSchedulesFailed = (payload) => ({
    type: CONST.FETCH_LOAN_SCHEDULES_FAILED,
    payload: payload
});

export const fetchLoanSchedules = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/loan', data),
        [fetchLoanSchedulesSuccess],
        [fetchLoanSchedulesFailed]
    );
};

/****************************************
 Update LoanSchedule - Details
 ****************************************/
export const updateLoanScheduleSuccess = (payload) => ({
    type: CONST.UPDATE_LOAN_SCHEDULE_SUCCESS,
    payload: payload
});

export const updateLoanScheduleFailed = (payload) => ({
    type: CONST.UPDATE_LOAN_SCHEDULE_FAILED,
    payload: payload
});

export const updateLoanSchedule = async ({ dispatch, data }) => {
    data['is_active'] = 1;
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/loan/schedule/' + data['id'], data),
        [updateLoanScheduleSuccess],
        [updateLoanScheduleFailed]
    );
};

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const proceed = (payload) => ({
    type: CONST.CLEAR_PROCEED_STATE,
    payload: payload,
});

export const clearErrors = (payload) => ({
    type: CONST.CLEAR_ERROR_STATE,
    payload: payload,
});

export const setSelectedComment = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload,
});

/**************************************/
/*          Fetch Comment             */
/**************************************/
export const fetchCommentSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload,
});

export const fetchCommentFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload,
});

export const fetchComment = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/comment/${id}`),
        [fetchCommentSuccess],
        [fetchCommentFailed]
    );
};

/**************************************/
/*          Fetch Comments            */
/**************************************/
export const fetchCommentsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload,
});

export const fetchCommentsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload,
});

export const fetchComments = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/comment', { params: data }),
        [fetchCommentsSuccess],
        [fetchCommentsFailed]
    );
};

/**************************************/
/*          Add a Comment             */
/**************************************/
export const addCommentSuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload,
});

export const addCommentFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload,
});

export const addComment = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/comment', data),
        [addCommentSuccess],
        [addCommentFailed]
    );
};

/**************************************/
/*          Update a Comment          */
/**************************************/
export const updateCommentSuccess = (payload) => ({
    type: CONST.UPDATE_ITEM_SUCCESS,
    payload: payload,
});

export const updateCommentFailed = (payload) => ({
    type: CONST.UPDATE_ITEM_FAILED,
    payload: payload,
});

export const updateComment = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/comment/${data.id}`, data),
        [updateCommentSuccess],
        [updateCommentFailed]
    );
};

/**************************************/
/*          Delete a Comment          */
/**************************************/
export const deleteCommentSuccess = (payload) => ({
    type: CONST.DELETE_ITEM_SUCCESS,
    payload: payload,
});

export const deleteCommentFailed = (payload) => ({
    type: CONST.DELETE_ITEM_FAILED,
    payload: payload,
});

export const deleteComment = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/comment/${id}`),
        [deleteCommentSuccess],
        [deleteCommentFailed]
    );
};

import {List, ListItem, ListItemText, Paper} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const BankDetailsPanel = () => {

    const BankDetails = styled(List)(({ theme }) => ({
        width: '100%',
        padding:0,
        backgroundColor: theme.palette.background.paper,
        '& .MuiListItem-root': {
            padding: '0',
            marginBottom:'10px',
        },
        '& .MuiListItemText-root': {
            display: 'flex',
            justifyContent: 'space-between',
        }
    }));

    return (
        <Paper sx={{p:2}}>
            <Typography variant={"h6"} sx={{marginBottom:'10px', fontSize:'1.1rem', fontWeight:'bold'}}>Converge Money Bank Details:</Typography>
            <BankDetails>
                <ListItem>
                    <ListItemText primary={"Account Name"} secondary={"Converge Money"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Account Number"} secondary={"1234567890"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Bank Name"} secondary={"Emirates NBD"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Branch Name"} secondary={"Dubai Mall Branch"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"IBAN"} secondary={"AE1234567890"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Swift Code"} secondary={"EMIRAEAD"} />
                </ListItem>
            </BankDetails>
        </Paper>
    )
}

import React, {useEffect, useState} from 'react'
import { Grid, Paper} from "@mui/material";
import BusinessTable from './Tables/BusinessTable';
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "../../../../Components";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setConfig} from "../index";
import useDeepEqualSelector from "../../../../Hooks/useDeepEqualSelector";

export const BusinessByStatus = () => {

    const dispatch = useDispatch();
    const {status} = useParams();
    const filters = useDeepEqualSelector((store) => store.business.config.filters);

    const [isFiltersSet, setIsFiltersSet] = useState(false);

    useEffect(() => {
        const setFilters = () => {
            switch (status) {
                case 'overdue':
                    dispatch(setConfig({ filters: { ...filters, is_overdue: 1, status:[] } }));
                    break;
                case 'draft':
                    dispatch(setConfig({ filters: { ...filters, is_overdue: 0, status: ['DRAFT'] } }));
                    break;
                case 'submitted':
                    dispatch(setConfig({ filters: { ...filters, is_overdue: 0, status: ['SUBMITTED'] } }));
                    break;
                default:
                    break;
            }
            setIsFiltersSet(true);
        };

        setFilters();
    }, [status, dispatch, filters]);

    if (!isFiltersSet) {
        return null; // Or a loading indicator can be placed here
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumbs items={'DASHBOARD/BUSINESS'} />
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ display:'flex', flexDirection:'column', flex:1}}>
                        <Typography sx={{ flex: '1', p:2 }} variant="h6" component="div">
                            Business
                        </Typography>
                        <BusinessTable />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

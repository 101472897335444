import React, { useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {DialogTitle, DialogContent, DialogActions, Button, Stack, TextField} from "@mui/material";
import { setModal } from "../../../../Common/App";

export const ChangePasswordModal = () => {

    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [data, setData] = useState({});

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    // const handleSubmit = async () => {
    //     setDisabled(true);
    //     await updateListing({dispatch, data:{id:listing.id, status:'ACTIVE'}}).then((res)=>{
    //         if(res['error']){
    //             setDisabled(false);
    //             setResponse(res);
    //         }else{
    //             fetchListings({dispatch, data:{...filters}});
    //             fetchListing({dispatch, id:listing.id});
    //             dispatch(setModal({component:null, open:false}));
    //         }
    //     });
    // };

    return(
        <>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent sx={{paddingTop:'20px!important', paddingBottom:'0!important'}}>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        label="Old Password"
                        name="old_password"
                        //defaultValue={data?.email}
                        inputlabelprops={{ shrink: true }}
                        helperText={response?.error?.old_password}
                        onBlur={handleChange} />
                    <TextField
                        fullWidth
                        label="Password"
                        name="password"
                        //defaultValue={data?.email}
                        inputlabelprops={{ shrink: true }}
                        helperText={response?.error?.password}
                        onBlur={handleChange} />
                    <TextField
                        fullWidth
                        label="Re Password"
                        name="re_password"
                        //defaultValue={data?.email}
                        inputlabelprops={{ shrink: true }}
                        helperText={response?.error?.re_password}
                        onBlur={handleChange} />
                </Stack>
            </DialogContent>
            <DialogActions sx={{p:3}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'}>Change Password</Button>
            </DialogActions>
        </>
    )
}

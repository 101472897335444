import React from 'react'
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {StatsTemplate} from "./StatsTemplate";

export const ReserveFundsStats = () => {

    const count = useSelector(store => store.wallets?.selected?.reserved_funds || 0);
    const displayedCount = useCounterEffect(0, count);

    return (
        <>
            <StatsTemplate title={"Reserve Funds"} displayedCount={displayedCount} link={"/wallet"} linkText={"Show Details"} />
        </>
    );
}

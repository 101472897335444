import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {useDispatch, useSelector} from "react-redux";
import {
    ApproveCustomerModal,
    RejectCustomerModal,
    ReturnCustomerModal,
    VerifyCustomerModal
} from "../index";
import { setModal } from "../../../Common/App";

export default function ActionButtons() {

    const dispatch = useDispatch();
    const customer = useSelector((store) => store.business.customers.selected);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedColor, setSelectedColor] = React.useState();
    const [options, setOptions] = React.useState([]);
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);

    const handleClick = (e) => {

        switch (options[selectedIndex].key) {
            case 'verify-customer':
                return dispatch(setModal({component:<VerifyCustomerModal customer_id={customer.customer_id} />, open:true}));
            case 'return-customer':
                return dispatch(setModal({component:<ReturnCustomerModal customer_id={customer.customer_id} />, open:true}));
            case 'approve-customer':
                return dispatch(setModal({component:<ApproveCustomerModal customer_id={customer.customer_id} />, open:true}));
            case 'reject-customer':
                return dispatch(setModal({component:<RejectCustomerModal customer_id={customer.customer_id} />, open:true}));
            default:
                return dispatch(setModal({component:null, open:false}));
        }
    };

    React.useEffect(()=>{

        const o = [];
        if ((['verify-customer'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['SUBMITTED'].includes(customer.status)) {
            o.push({'key':'verify-customer','value':'Verify','color':'success'});
        }
        if ((['return-customer'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['SUBMITTED'].includes(customer.status)) {
            o.push({'key':'return-customer','value':'Return','color':'warning'});
        }
        if ((['approve-customer'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['VERIFIED'].includes(customer.status)) {
            o.push({'key':'approve-customer','value':'Approve','color':'success'});
        }
        if ((['reject-customer'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && ['VERIFIED'].includes(customer.status)) {
            o.push({'key':'reject-customer','value':'Reject','color':'error'});
        }

        const u = [...new Map(o.map(item => [item['key'], item])).values()];
        u.length && setSelectedColor(u[0].color);
        setOptions(u);

    },[customer]);

    const handleMenuItemClick = (event, index, color) => {
        setSelectedIndex(index);
        setSelectedColor(color);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            {options.length > 0 &&
                <>
                    <ButtonGroup variant="contained" color={selectedColor} ref={anchorRef} aria-label="split button">
                        <Button onClick={handleClick}>{options[selectedIndex].value}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option.key}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index, option.color)}
                                                >
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            }
        </>
    );
}
import * as React from 'react';
import {GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { setModal } from "../../../../Common/App";
import {fetchCustomer, setSelectedCustomer} from "../../../Business";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import {CustomerDeleteModal} from "../../../Business";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const CustomerTable = () => {

    const customers = useSelector((store) => store.business.customers);
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const dispatch = useDispatch();

    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const viewItem = React.useCallback(
        (id) => async () => {
            //await fetchCustomer({dispatch, id});
            navigate('/customers/' + id);
        },
        [],
    );

    const editItem = React.useCallback(
        (id) => async () => {
            await fetchCustomer({dispatch, id});
            navigate('/customers/' + id);
        },
        [],
    );

    const deleteItem = React.useCallback(

        (id) => () => {
            dispatch(setSelectedCustomer(id));
            dispatch(setModal({component: <CustomerDeleteModal />, open:true}));
        },
        [],
    );

    const columns = React.useMemo(
        () => [
            { field: 'company_name', headerName:'Company Name', type: 'string', width:200},
            { field: 'dealing_since', headerName:'Dealing Since', type: 'string', width: 120},
            { field: 'payment_terms', headerName:'Payment Terms', type: 'string', width:120},
            { field: 'currency', headerName:'Currency', type: 'string', width:120},
            { field: 'address', headerName:'Address', type: 'string', width:120},
            { field: 'company_contact_no', headerName:'Company Contact No', type: 'string', width:160},
            { field: 'country', headerName:'Country', type: 'string', width:120},
            { field: 'company_website', headerName:'Company Website', type: 'string', width:160},
            { field: 'products_desc', headerName:'Product Description', type: 'string', width:220},
            { field: 'payment_method', headerName:'Payment Method', type: 'string', width:120},
            { field: 'amount_previous', headerName:'Amount Previous', type: 'number', width:150, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'amount_projected', headerName:'Amount Projected', type: 'number', width:150, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'avg_invoice_value', headerName:'Avg Invoice Value', type: 'number', width:160, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'no_of_invoice', headerName:'No Of Invoice', type: 'string', width:120},
            { field: 'cp_name', headerName:'Contact Person Name', type: 'string', width:160},
            { field: 'cp_phone', headerName:'Contact Person Phone', type: 'string', width:160},
            { field: 'cp_mobile', headerName:'Contact Person Mobile', type: 'string', width:160},
            { field: 'cp_email', headerName:'Contact Person Email', type: 'string', width:180},
            { field: 'credit_limit_requested', headerName:'Credit Limit Requested', type: 'string', width:160,
                valueGetter: (params) => {
                    return (params.row?.config.find((o) => o.config_type === 'credit_limit_requested'))?.config_value;
                }
            },
            { field: 'flag', headerName:'Overdue/Default', type: 'string', width:120 },
            { field: 'status', headerName:'Status', type: 'string', width:130,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'SUBMITTED':'info',
                        'PENDING':'warning',
                        'PROCESSING':'primary',
                        'VERIFIED':'success',
                        'APPROVED':'success',
                        'REJECTED':'error'
                    }
                    return(<StatusBall
                        variant="contained"
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                width: 150,
                headerName:'Action',
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={ deleteItem(params.id) }
                    />,
                    (['DRAFT','RETURNED'].includes(params.row.status) && auth.user.role_type === 'CLIENT' ||
                    ['SUBMITTED','PROCESSING','PENDING'].includes(params.row.status)) && auth.user.role_type !== 'CLIENT'
                        ?
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            onClick={ editItem(params.id) }

                        />
                    :
                        <GridActionsCellItem
                            icon={<FindInPageIcon />}
                            label="View"
                            onClick={ viewItem(params.id) }

                        />
                    ,
                ],
            },
        ],
        [deleteItem, editItem],
    );

    return (
            <>
                <DataGridPro
                    getRowId={(row) => row.customer_id}
                    pageSize={filters.pageSize}
                    pageSizeOptions={[5, 10, 20]}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    rows={(customers?.list || []).filter(customer => customer.status !== 'DRAFT')}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { left: ['company_name'], right: ['status','actions'] }
                    }}
                    autoHeight={true}
                />
            </>
        );
}

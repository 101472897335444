import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";

export default function PoweredBy(props) {

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Ifund Copyright © 2023 '}
        </Typography>
    );
}



import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload: payload
});

export const resetListings = (payload) => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedListing = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchListingSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchListingFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchListing = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/listing/${id}`),
        fetchListingSuccess,
        fetchListingFailed
    );
};

export const fetchListingsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchListingsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchListings = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/listing', { params: data }),
        fetchListingsSuccess,
        fetchListingsFailed
    );
};

export const updateListingsSuccess = (payload) => ({
    type: CONST.UPDATE_ITEM_SUCCESS,
    payload: payload
});

export const updateListingsFailed = (payload) => ({
    type: CONST.UPDATE_ITEM_FAILED,
    payload: payload
});

export const updateListing = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`listing/${data['id']}`, data),
        updateListingsSuccess,
        updateListingsFailed
    );
};

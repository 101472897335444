import * as React from 'react';
import {Card as CardUI} from "@mui/material";
import {styled} from "@mui/material/styles";

export const Panel = ({children, styles}) => {

    const Card = styled(CardUI)({
        display: 'flex',
        flexDirection: 'column',
        height:'100%',
        marginBottom:'0rem',
        '& .MuiCardContent-root': {
            flex:'1 1 auto',
            padding: 0,
            paddingBottom: '0 !important',
        },
        '& .MuiCardHeader-title': {
            fontSize: '1rem',
        }
    });

    return (
        <Card sx={{...styles}}>
            {children}
        </Card>
    )
}
import React from "react";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useDispatch} from "react-redux";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {addRole} from "../../index";
import {setModal} from "../../../../Common/App";
import {TextField} from "@mui/material";
import Toastr from "../../../../../Components/Elements/Toastr";
export const AddRoleModal = () => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await addRole({dispatch, data}).then((res)=>{
            setResponse(res);
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
            }
        });

    };

    return(
        <>
            <DialogTitle>Add Role</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        fullWidth
                        label="Role Name"
                        name="name"
                        onChange={(e) => handleChange(e)}
                        variant="outlined"
                        helperText={response?.error?.data?.errors?.name}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Add Role</Button>
            </DialogActions>

            <Toastr response={response} />
        </>
    )
}
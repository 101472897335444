import * as React from 'react';
import {TextField as TextFieldUI} from "@mui/material";
import {styled} from "@mui/material/styles";
export const TextField = ({ styles, ...props }) => {

    const StyledTextField = styled(TextFieldUI)({
        border: 0,
        borderRadius: 10,
    });

    return (
        <>
            <StyledTextField {...props} sx={styles} />
        </>
    );
}
import React, { useState, useEffect } from 'react';
import {
    Button as ButtonUI,
    Container as ContainerUI, FormControl, FormHelperText, Grid,
    InputLabel, Select,
    Stack, TextField as TextFieldUI,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Link as LinkUI, Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {captureLead, doRegister, resetUsers} from "../../../../Administration/Users";
import { styled, useTheme } from "@mui/material/styles";
import Toastr from "../../../../../Components/Elements/Toastr";
import { setBlockUI } from "../../../App";
import MuiDatepicker from "../../../../Corporate/Business/Components/Datepicker";
import MenuItem from "@mui/material/MenuItem";


const checkValidCompany = (date) => {
    const corporation_date = new Date(date);
    let last_year = new Date();
    last_year = last_year.setDate(last_year.getDate() - 180);
    return corporation_date < last_year;
}

export const BusinessRegistrationSm = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [leadCaptured, setLeadCaptured] = useState(false);
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        dispatch(resetUsers(false));
    }, []);

    const handleSubmit = async () => {
        setResponse(false);
        setDisabled(true);
        dispatch(setBlockUI({open:true}));

        if(checkValidCompany(data['company_incorporation_date'])){
            data['service'] = 'RECEIVABLE_DISCOUNTING';
            data['total_revenue'] = 0;
            await doRegister({dispatch, data}).then((res)=>{
                dispatch(setBlockUI({open:false}));
                setResponse(res);
                setRegistrationComplete(res['success']);
                if(res['success']){
                    navigate('/email-verification')
                }
            });
        }else{
            await captureLead({dispatch, data}).then((res)=>{
                dispatch(setBlockUI({open:false}));
                setLeadCaptured(res['success']);
                setResponse(res);
            });
        }
    };

    const handleChange = (e) => {
        setDisabled(false);

        setData(data => ({
            ...data,
            [e.target.id]: e.target.value,
        }));
    }

    const ALPHABETS = /^[a-zA-Z]+$/;

    const TextField = styled(TextFieldUI)(({ theme }) => ({
        '.MuiFormLabel-root': {
            color: '#cccccc88',
            fontSize: '1rem',
            fontWeight: '400',
            top: '0px',
            left: '0px'
        },
        '& .MuiInputBase-input': {
            color: '#ffffff',
            borderRadius: '5px',
            fontSize: '1.1rem',
            padding: '5px 0',
            fontWeight: '300',
        },
        '& .MuiInputBase-root': {
            '&::before': {
                content: "''",
                borderBottom: '1px solid #222222',
                borderColor: '#ffffff'
            },
            '&::after': {
                content: "''",
                borderBottom: `1px solid #ffffff`
            },
        }
    }));

    const Link = styled(LinkUI)(({ theme }) => ({
        color: '#8d8d8d',
        textDecoration: 'none',
        fontWeight: '500',
        letterSpacing: '1px',
        textShadow: '0 0 3px rgba(0,0,0,1)',
        fontSize: '1rem',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    const Button = styled(ButtonUI)(({ theme }) => ({
        color: '#ffffff',
        textShadow: '0 0 3px rgba(0,0,0,0.6)',
        borderRadius: '10px',
        background: 'linear-gradient(to bottom right, #4cc6b9 50%, #0ab5d0)',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.contrastText,
        }
    }));

    const passwordError = (response) => {
        if (response?.error?.data?.errors?.password) {
            const passwordErrors = response.error.data.errors.password.filter(msg => !msg.includes('confirmation'));
            return passwordErrors.join(',\n');
        }
        return "";
    };

    const passwordConfirmationError = (response) => {
        if (response?.error?.data?.errors?.password) {
            const passwordErrors = response.error.data.errors.password.filter(msg => msg.includes('confirmation'));
            return passwordErrors.join(', ');
        }
        return "";
    }

    return (
        <Grid
            container
            sx={{ height: '100%', overflow:'auto' }}
        >
            <Grid item xs={12} sx={{my: 5}} textAlign={"center"}>
                <img src={'/images/ifund-logo-light.png'} width={150} alt={"Logo"} />
            </Grid>
            <Grid item xs={12} sx={{ width: '85%', maxWidth: { xs: '400px', sm:'none' } }}>
                {response.status !== 201 &&
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="first_name"
                                onKeyDown={(e) => {
                                    if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                        e.preventDefault();
                                    }
                                }}
                                autoComplete="off"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['first_name'] ?? ""}
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="First Name"
                                helperText={response?.error?.data?.errors?.first_name}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="last_name"
                                autoComplete="off"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['last_name'] ?? ""}
                                required
                                variant={"standard"}
                                color={"secondary"}
                                onKeyDown={(event) => {
                                    if (!ALPHABETS.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                label="Last Name"
                                helperText={response?.error?.data?.errors?.last_name}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="email"
                                id="email"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['email'] ?? ""}
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="Email"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                helperText={response?.error?.data?.errors?.email}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="tel"
                                id="phone"
                                autoComplete="off"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['phone'] ?? ""}
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="Phone"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                helperText={response?.error?.data?.errors?.phone}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="password"
                                id="password"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['password'] ?? ""}
                                autoComplete="off"
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="Password"
                                sx={{ width: '100%' }}
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                helperText={passwordError(response)}
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                name={"password_confirmation"}
                                label={"Re-Password"}
                                variant={"standard"}
                                color={"secondary"}
                                defaultValue={data['password_confirmation'] ?? ""}
                                required
                                type={"password"}
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onBlur={(e) => handleChange(e)}
                                helperText={passwordConfirmationError(response)}
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="company_name"
                                onKeyDown={(e) => {
                                    if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                        e.preventDefault();
                                    }
                                }}
                                autoComplete="off"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['company_name'] ?? ""}
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="Company Name"
                                sx={{mb:2}}
                                helperText={response?.error?.data?.errors?.company_name}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.company_incorporation_date} setDisabled={setDisabled} label={"Company Incorporation Date"} past={true} name="company_incorporation_date" data={data} state={setData} />
                        </Grid>
                        {/*<Grid item sm={6} xs={12}>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        type="text"*/}
                        {/*        id="total_revenue"*/}
                        {/*        onBlur={(e) => handleChange(e)}*/}
                        {/*        defaultValue={data['total_revenue']}*/}
                        {/*        required*/}
                        {/*        variant={"standard"}*/}
                        {/*        color={"secondary"}*/}
                        {/*        hidden={true}*/}
                        {/*        label="Total Revenue"*/}
                        {/*        inputProps={{*/}
                        {/*            form: {*/}
                        {/*                autocomplete: 'off',*/}
                        {/*            },*/}
                        {/*        }}*/}
                        {/*        helperText={response?.error?.data?.errors?.total_revenue}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="amount"
                                autoComplete="off"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['amount'] ?? ""}
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="Required Funding Amount"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                helperText={response?.error?.data?.errors?.amount}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                type="text"
                                id="tenor"
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['tenor'] ?? ""}
                                autoComplete="off"
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="Funding Tenor"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                helperText={response?.error?.data?.errors?.tenor}
                            />
                        </Grid>
                        {/*<Grid item sm={6} xs={12}>*/}
                        {/*    <FormControl sx={{ width: '100%', display: 'none' }}>*/}
                        {/*        <InputLabel id="service">Service</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            defaultValue={"RECEIVABLE_DISCOUNTING"}*/}
                        {/*            name="service"*/}
                        {/*            MenuProps={{*/}
                        {/*                style: { zIndex: 35001 }*/}
                        {/*            }}*/}
                        {/*            fullWidth*/}
                        {/*            variant={"standard"}*/}
                        {/*            color={"secondary"}*/}
                        {/*            label="Service"*/}
                        {/*            onBlur={(e) => handleChange(e)}*/}
                        {/*        >*/}
                        {/*            <MenuItem key={""} value={""} disabled>Select Service</MenuItem>*/}
                        {/*            <MenuItem key={"RECEIVABLE_DISCOUNTING"} value={'RECEIVABLE_DISCOUNTING'}>Receivable Finance</MenuItem>*/}
                        {/*            /!*<MenuItem key={"INVOICE_DISCOUNTING"} value={'INVOICE_DISCOUNTING'}>Invoice Discounting</MenuItem>*!/*/}
                        {/*        </Select>*/}
                        {/*        <FormHelperText>{response?.error?.data?.errors?.service}</FormHelperText>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                id="purpose"
                                fullWidth
                                onBlur={(e) => handleChange(e)}
                                defaultValue={data['purpose'] ?? ""}
                                autoComplete="off"
                                required
                                variant={"standard"}
                                color={"secondary"}
                                label="Purpose of Funding"
                                inputProps={{
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                helperText={response?.error?.data?.errors?.purpose}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <Button
                                fullWidth={false}
                                disabled={disabled}
                                color="secondary"
                                sx={{ width: {xs:'100%',sm:'50%'}, mt: 3, mb: 2 }}
                                className={'text-uppercase fs-5 p-2'}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Sign up
                            </Button>
                        </Grid>

                        <Grid item xs={12} textAlign={"center"} py={5}>
                            <Link to={'/login'} className={'mb-3 bold d-block text-right'}>
                                Already have an account? Login
                            </Link>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Toastr response={response} />
        </Grid>
    )
}

import React, {useEffect} from 'react'
import { setModal, fetchBanks, fetchLOV } from "../../../Common/App";
import {useDispatch} from "react-redux";
import {DashboardBusiness} from "./DashboardBusiness";
import {fetchStats} from "../Redux/Action";
import {useParams} from "react-router-dom";

export const Dashboard = () => {

    const dispatch = useDispatch();
    const {business_id} = useParams();

    dispatch(setModal({ component:null, open: false}))

    useEffect(() => {
        //fetchBusinesses({dispatch})
        fetchBanks({dispatch})
        fetchLOV({dispatch})
        fetchStats({dispatch, business_id})

    },[]);

    return (<DashboardBusiness />);
}

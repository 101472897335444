import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {fetchLoan} from "../Redux/Action";
import {fetchBankDetails, fetchPartner} from "../../Business";
import {Card, CardContent, CardHeader, Grid, Stack} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import ActionButtons from "./ActionButtons";
import {LoanScheduleTable} from "./Tables/LoanScheduleTable";
import {LoanDetailsPanel} from "./Panels/LoanDetailsPanel";
import LoanConfigPanel from "./Panels/LoanConfigPanel";
import {useEffect, useState} from "react";
import {ServiceChargeTable} from "../../Services";
import LoanDocumentPanel from "./Panels/LoanDocumentPanel";
import Toastr from "../../../../Components/Elements/Toastr";
import {fetchConfig} from "../../Business/Redux/ConfigAction";
import {setModal} from "../../../Common/App";

export const LoanView = () => {

    const dispatch = useDispatch();
    const [dataReady, setDataReady] = useState(false);
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const { loan_id } = useParams();
    const [response, setResponse] = useState({});

    useEffect( () => {
        fetchData();
        dispatch(setModal({ component:null, open: false}))
    },[]);

    const fetchData = async () => {
        const res = await fetchLoan({dispatch, loan_id});
        setDataReady(true);

        if(res['success']) {
            await Promise.all([
                fetchPartner({dispatch, business_id: res.data.data.business_id}),
                fetchBankDetails({dispatch, business_id: res.data.data.business_id}),
                fetchConfig({dispatch, business_id: res.data.data.business_id}),
            ]);
        }
    };

    return (
        <>
            {loan_id === "undefined" ? (
                <Card>
                    <CardHeader title={"Invalid Loan ID"} subheader={"404"} />
                    <CardContent>There is an error in the URL, please visit to <Link to={"/business-finance"}>Business Finance</Link> menu and select again</CardContent>
                </Card>
            ) : (
                dataReady && (
                    <Grid container justifyContent={"center"} spacing={2}>
                        <Grid item xs={12}>
                            <Stack justifyContent={'space-between'} direction={'row'}>
                                <Breadcrumbs items={'DASHBOARD/BUSINESS_FINANCE'} />
                                <ActionButtons />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Stack spacing={2} direction={"column"}>
                                <LoanScheduleTable loan_id={loan_id} />
                                {auth.user.role_type !== 'CLIENT' && <ServiceChargeTable loan_id={loan_id} />}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Stack spacing={2} direction={"column"}>
                                {(permissions.includes('show-loan-documents')) && <LoanDocumentPanel loan_id={loan_id} setResponse={setResponse} />}
                                <LoanDetailsPanel loan_id={loan_id} />
                                <LoanConfigPanel loan_id={loan_id} />
                            </Stack>
                        </Grid>
                    </Grid>
                )
            )}
            {Object.keys(response).length > 0 && <Toastr response={response} />}
        </>
    );
}
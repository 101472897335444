import * as React from 'react';
import {Box, Grid, Card, CardHeader, CardContent} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {useParams} from "react-router-dom";
import {TransactionTable, setConfig} from "../../Transactions";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StatementSummaryPanel} from "./Panels/StatementSummaryPanel";

export const StatementView = () => {

    const dispatch = useDispatch();
    const {year, month} = useParams();
    const transactions = useSelector(state => state.transactions.list);
    const config = useSelector(state => state.transactions.config);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        dispatch(setConfig({...config, filters: {...config.filters, year, month}}));
    }

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/STATEMENTS/'+year} sx={{display:'inline-block'}} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <StatementSummaryPanel />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={"Statement"} />
                        <CardContent sx={{p:0}}>
                            <TransactionTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
    );
}
import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {CompanyLocationForm} from "../Forms/CompanyLocationForm";
import {Grid, Stack} from "@mui/material";
import { addAddress, clearErrors, proceed, setSelectedAddress,CompanyLocationDeleteModal } from "../../index";
import moment from "moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";

export const CompanyLocationStep = () => {

    const business = useSelector((store) => store.business);
    let objects = Object.keys(business.addresses).length > 0 ? business.addresses.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addAddress({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedAddress(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<CompanyLocationForm />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<CompanyLocationDeleteModal />, open:true}));
        dispatch(setSelectedAddress(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'address_type', headerName:'Address Type', type: 'string', flex:1, minWidth: 120 },
            { field: 'landlord', headerName:'Landlord Name', type: 'string', flex:1, minWidth: 150},
            { field: 'annual_rent_amount', headerName:'Annual Rent', type: 'number',  flex:1, minWidth: 120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'tenancy_start_date', headerName:'Tenancy Start Date', type: 'date', flex:1, minWidth: 150, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'tenancy_end_date', headerName:'Tenancy End Date', type: 'date', flex:1, minWidth: 150, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>
            <Grid item xs={12} >
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={objects}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] },
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Company Location</Button>
                </Stack>
            </Grid>
        </Grid>
    );
}
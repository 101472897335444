import React from "react";
import {
    FormControl, Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import { setSelectedCustomerSupplier, updateCustomerSupplier } from "../../index";
import MuiDatepicker from "../Datepicker";
import MenuItem from "@mui/material/MenuItem";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import Toastr from "../../../../../Components/Elements/Toastr";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import Typography from "@mui/material/Typography";
import {PhoneField} from "../../../../../Components/Elements/PhoneField";

export const CustomerSupplierForm = (props) => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const auth = useSelector((store) => store.auth);

    useEffect(() => {
        setData(business.customersSuppliers.selected);
    }, [])

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updateCustomerSupplier({dispatch, data}).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component:null, open:false}));
        dispatch(setSelectedCustomerSupplier(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                {props.formName}
                <Typography>{props.formSubTitle}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>
                                <TextField
                                    fullWidth
                                    label="Company Name"
                                    name="company_name"
                                    defaultValue={data?.company_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.company_name}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Company Address"
                                    name="address"
                                    defaultValue={data?.address}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.address}
                                    onBlur={handleChange} />

                                <PhoneField
                                    fullWidth
                                    label="Company Contact No"
                                    name="company_contact_no"
                                    type="tel"
                                    placeholder={"e.g. 9714XXXXXXX"}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    size="large"
                                    defaultValue={data?.company_contact_no || ""}
                                    helperText={business.error?.company_contact_no}
                                />

                                <SelectCountry error={business?.error?.country} disabled={disabled} label={"Country"} name={"country"} data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Company Website"
                                    name="company_website"
                                    defaultValue={data?.company_website}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.company_website}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Product/Service Description"
                                    name="products_desc"
                                    defaultValue={data?.products_desc}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.products_desc}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.dealing_since} setDisabled={setDisabled} format={"YYYY"} views={['year']} label={"Dealing Since"} past={true} name="dealing_since" data={data} state={setData} />

                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-office">Payment Terms</InputLabel>
                                    <Select
                                        defaultValue={data?.payment_terms}
                                        name="payment_terms"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Payment Terms"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem key={"0"} value={"0"}>Immediate</MenuItem>
                                        <MenuItem key={"15"} value={'15'}>15 days</MenuItem>
                                        <MenuItem key={"30"} value={'30'}>30 days</MenuItem>
                                        <MenuItem key={"45"} value={'45'}>45 days</MenuItem>
                                        <MenuItem key={"60"} value={'60'}>60 days</MenuItem>
                                        <MenuItem key={"75"} value={'75'}>75 days</MenuItem>
                                        <MenuItem key={"90"} value={'90'}>90 days</MenuItem>
                                        <MenuItem key={"120"} value={'120'}>120 days</MenuItem>
                                        <MenuItem key={"150"} value={'150'}>150 days</MenuItem>
                                        <MenuItem key={"180"} value={'180'}>180 days</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-payment-method">Payment Method</InputLabel>
                                    <Select
                                        defaultValue={data?.payment_method}
                                        name="payment_method"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Payment Method"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem key={"All"} value={'All'}>All</MenuItem>
                                        <MenuItem key={"Cash"} value={'Cash'}>Cash</MenuItem>
                                        <MenuItem key={"Cheque"} value={'Cheque'}>Cheque</MenuItem>
                                        <MenuItem key={"Bank Transfer"} value={'Bank Transfer'}>Bank Transfer</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    label="Total Sales Amount(Last 12 Months)"
                                    name="amount_previous"
                                    defaultValue={data?.amount_previous}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.amount_previous}
                                    onBlur={handleChange} />

                                { auth.user.role_type !== 'CLIENT' &&
                                <TextField
                                    fullWidth
                                    label="Total Projected Sales Amount (Next 12 Months)"
                                    name="amount_projected"
                                    defaultValue={data?.amount_projected}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.amount_projected}
                                    onBlur={handleChange} />
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                <TextField
                                    fullWidth
                                    label="Average Invoice Value"
                                    name="avg_invoice_value"
                                    defaultValue={data?.avg_invoice_value}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.avg_invoice_value}
                                    onBlur={handleChange} />
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                <TextField
                                    fullWidth
                                    label="No of Invoices (Monthly)"
                                    name="no_of_invoice"
                                    defaultValue={data?.no_of_invoice}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.no_of_invoice}
                                    onBlur={handleChange} />
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-currency">Currency</InputLabel>
                                    <Select
                                        defaultValue={data?.currency || "AED"}
                                        name="currency"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Currency"
                                        onChange={(e) => handleChange(e)}
                                        helperText={business?.error?.currency}
                                    >
                                        <MenuItem key={"AED"} value={'AED'}>AED</MenuItem>
                                        <MenuItem key={"USD"} value={'USD'}>USD</MenuItem>
                                        <MenuItem key={"EUR"} value={'EUR'}>EUR</MenuItem>
                                        <MenuItem key={"GBP"} value={'GBP'}>GBP</MenuItem>
                                    </Select>
                                </FormControl>
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                <TextField
                                    fullWidth
                                    label="Contact Person Name"
                                    name="cp_name"
                                    defaultValue={data?.cp_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.cp_name}
                                    onBlur={handleChange} />
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                    <PhoneField
                                        fullWidth
                                        label="Contact Person Phone"
                                        name="cp_phone"
                                        type="tel"
                                        placeholder={"e.g. 9714XXXXXXX"}
                                        onChange={(e) => handleChange(e)}
                                        variant="outlined"
                                        size="large"
                                        defaultValue={data?.cp_phone || ""}
                                        helperText={business.error?.cp_phone}
                                    />
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                    <PhoneField
                                        fullWidth
                                        label="Contact Person Mobile"
                                        name="cp_mobile"
                                        type="tel"
                                        placeholder={"e.g. 9714XXXXXXX"}
                                        onChange={(e) => handleChange(e)}
                                        variant="outlined"
                                        size="large"
                                        defaultValue={data?.cp_mobile || ""}
                                        helperText={business.error?.cp_mobile}
                                    />
                                }

                                { auth.user.role_type !== 'CLIENT' &&
                                <TextField
                                    fullWidth
                                    label="Contact Person Email"
                                    name="cp_email"
                                    defaultValue={data?.cp_email}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.cp_email}
                                    onBlur={handleChange} />
                                }

                                <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateCustomerSupplier} allowedFileType={['pdf']} onBlur={handleChange} label={"Customer Profile"} entity_name={"customersSuppliers"} entity_id={data?.customer_id} name="customer_profile_doc" data={data} state={setData} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateCustomerSupplier} allowedFileType={['pdf']} onBlur={handleChange} label={"Trade License"} entity_name={"customersSuppliers"} entity_id={data?.customer_id} name="trade_license_doc" data={data} state={setData} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateCustomerSupplier} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Ledger Copy"} entity_name={"customers"} entity_id={data?.customer_id} name="ledger_doc" data={data} state={setData} />

                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}


import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import LayoutProvider from "../Providers/LayoutProvider";
import {useSelector} from "react-redux";

const PrivateRoutes = ({ allowedRoles, layout }) => {

    const {isLoggedIn, user} = useSelector((store) => store.auth);

    return isLoggedIn && (allowedRoles.includes(user?.role_type) || user.is_super_admin) ?
                (<LayoutProvider layout={layout}>
                    <Outlet />
                </LayoutProvider>)
                : <Navigate to="/login" />;
}

export default PrivateRoutes;
import * as React from "react";
import {Card, Card as CardUI, CardContent, CardHeader, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import {currencyFormatter} from "../../../../../Setup/Config";
import Typography from "@mui/material/Typography";


export const AccountSummary = ({ selectedRows }) => {

    const items = useSelector(state => state.accounts.list);
    const filteredItems = items.filter(item => selectedRows.includes(item.account_id));

    const sumAmounts = (status) => {
        return filteredItems
            .filter(item => item.status === status)
            .reduce((sum, item) => sum + parseFloat(item.amount), 0);
    };

    const plannedTotal = sumAmounts("PLANNED");
    const paidTotal = sumAmounts("PAID");
    const overdueTotal = sumAmounts("OVERDUE");
    const extendedTotal = sumAmounts("EXTENDED");

    const Card = styled(CardUI)(({ theme }) => ({
        //backgroundColor: theme.palette.primary.light,
        '& .MuiCardHeader-root': {
            fontSize: '1.25rem',
            padding:'0.8rem',
        },
        '& .MuiCardContent-root': {
            padding:'1rem!important',
            '& .MuiTypography-root': {
                fontSize: '1.5rem',
                textAlign:'right'
            }
        }
    }));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardHeader title={"Planned"} />
                    <CardContent>
                        <Typography>{currencyFormatter.format(plannedTotal)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardHeader title={"Paid"} />
                    <CardContent>
                        <Typography>{currencyFormatter.format(paidTotal)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardHeader title={"Overdue"} />
                    <CardContent>
                        <Typography>{currencyFormatter.format(overdueTotal)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Card>
                    <CardHeader title={"Extended"} />
                    <CardContent>
                        <Typography>{currencyFormatter.format(extendedTotal)}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
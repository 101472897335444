import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

/****************************************
 SET SELECTED BANK BORROWINGS
 ****************************************/
export const setSelectedBankBorrowing = (payload) => ({
    type: CONST.SET_SELECTED_BANK_BORROWING,
    payload: payload
});

/****************************************
 FETCH BANK_BORROWINGS
 ****************************************/
export const fetchBankBorrowingsSuccess = (payload) => ({
    type: CONST.FETCH_BANK_BORROWINGS_SUCCESS,
    payload: payload
});

export const fetchBankBorrowingsFailed = (payload) => ({
    type: CONST.FETCH_BANK_BORROWINGS_FAILED,
    payload: payload
});

export const fetchBankBorrowings = async ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/bankBorrowing`),
        [fetchBankBorrowingsSuccess],
        [fetchBankBorrowingsFailed]
    );
};

/****************************************
 ADD Business BANK BORROWINGS
 ****************************************/
export const addBankBorrowingSuccess = (payload) => ({
    type: CONST.ADD_BANK_BORROWING_SUCCESS,
    payload: payload
});

export const addBankBorrowingFailed = (payload) => ({
    type: CONST.ADD_BANK_BORROWING_FAILED,
    payload: payload
});

export const addBankBorrowing = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/bankBorrowing', data),
        [addBankBorrowingSuccess],
        [addBankBorrowingFailed]
    );
};

/****************************************
 Update Business BANK BORROWING
 ****************************************/
export const updateBankBorrowingSuccess = (payload) => ({
    type: CONST.UPDATE_BANK_BORROWING_SUCCESS,
    payload: payload
});

export const updateBankBorrowingFailed = (payload) => ({
    type: CONST.UPDATE_BANK_BORROWING_FAILED,
    payload: payload
});

export const updateBankBorrowing = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/bankBorrowing/' + data['id'], data),
        [updateBankBorrowingSuccess],
        [updateBankBorrowingFailed]
    );
};

/****************************************
 DELETE Business BANK BORROWING
 ****************************************/
export const deleteBankBorrowingSuccess = (payload) => ({
    type: CONST.DELETE_BANK_BORROWING_SUCCESS,
    payload: payload
});

export const deleteBankBorrowingFailed = (payload) => ({
    type: CONST.DELETE_BANK_BORROWING_FAILED,
    payload: payload
});

export const deleteBankBorrowing = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete('corporate/bankBorrowing/' + id),
        [deleteBankBorrowingSuccess],
        [deleteBankBorrowingFailed]
    );
};

import './App.css';
import { BrowserRouter } from "react-router-dom";
import {persistor, store} from "./Setup/Redux/Store";
import {Provider} from "react-redux";
import React from "react";
import {PersistGate} from "redux-persist/integration/react";
import { LicenseInfo } from '@mui/x-license-pro';
import {withPrivateRoutes} from "./HOC/withPrivateRoutes";

const App = ({routes}) => {

    LicenseInfo.setLicenseKey('ed4dd4294edc759124b662d61c8c65f9Tz05NTQ0NixFPTE3NTQyMTgwNDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==');

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    {routes}
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default withPrivateRoutes(App);

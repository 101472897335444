import * as React from 'react';
import {GridActionsCellItem } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {fetchWallet, setConfig, fetchWallets} from "../../";
import moment from "moment/moment";
import {useEffect} from "react";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const WalletTable = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const wallets = useSelector((store) => store.wallets);
    const filters = useSelector((store) => store.wallets.config.filters);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchItems();
    },[filters]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchWallets({dispatch, data:{...filters}}).then((res)=>{
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            await fetchWallet({dispatch, id});
            navigate('/wallets/' + id);
        },
        [],
    );

    const columns = React.useMemo(
        () => [
            { field: 'user_id', headerName:'User', type: 'string', width:200, valueGetter: (params) => params.row?.user?.first_name},
            { field: 'currency', headerName:'Currency', type: 'string', width: 80},
            { field: 'balance', headerName:'Balance', type: 'string', width: 100},
            { field: 'withdrawal_limit', headerName:'Withdrawal Limit', type: 'string', width: 120},
            { field: 'reserved_funds', headerName:'Reserved Funds', type: 'string', width: 120},
            { field: 'last_transaction_at', headerName:'Last Transaction', type: 'date', width: 150,
                valueFormatter: ({value}) => value ? moment(value).format('YYYY-MM-DD') : '-'
            },
            { field: 'status', headerName:'Status', type: 'string', width:130,
                renderCell: (params) => {
                    const status = {
                        'ACTIVE':'primary',
                        'LOCKED':'error',
                        'FROZEN':'success',
                        'REVIEW':'success',
                        'ERROR':'warning',
                    }
                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                width: 150,
                headerName:'Action',
                getActions: (params) => [
                        <GridActionsCellItem
                            icon={<FindInPageIcon />}
                            label="View"
                            onClick={ viewItem(params.id) }

                        />
                ],
            },
        ],
        [],
    );

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={wallets.list}
                    sx={{ border:'none'}}
                    pagination
                    paginationMode="server"
                    paginationModel={filters}
                    rowCount={wallets?.config?.total ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    onPaginationModelChange={(params) => dispatch(setConfig({filters: {...filters, page:params.page, pageSize: params.pageSize}}))}
                    onPageChange={(newPageNo) => dispatch(setConfig({filters: {...filters, pageNo: newPageNo}}))}
                    onPageSizeChange={(newPageSize) => dispatch(setConfig({filters: {...filters, pageSize: newPageSize}}))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'group', sort: 'asc' }],
                        },
                        pinnedColumns: {  right: ['actions'] },
                        pagination: { paginationModel: { ...filters } }
                    }}
                />
            </>
        );
}

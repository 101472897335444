import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {markCustomersForInvoiceDiscounting} from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {FormControl, FormHelperText, Grid, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export const ExistingCustomerSelectionModal = () => {

    const dispatch = useDispatch();
    const customers = useSelector((store)=> store.business.customersSuppliers.list);

    const filteredCustomer = customers.filter((customer) => customer.is_customer === 1);

    const [data, setData] = React.useState({
        business_id: 0,
        customer_ids: [],
    });

    const handleSubmit = async () => {

        await markCustomersForInvoiceDiscounting({dispatch, data}).then((res) => {
            dispatch(setModal({component:null, open:false}));
        });
    };

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }


    return (
        <>
            <DialogTitle>Select Customers</DialogTitle>
            <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="label-Title">Select Customers</InputLabel>
                            <Select
                                fullWidth
                                multiple
                                label="Select Customers"
                                name="customer_ids"
                                variant="outlined"
                                value={data?.customer_ids || []}
                                onChange={handleChange}
                            >
                                <MenuItem value="">Select</MenuItem>
                                { filteredCustomer.map((customer) => ( <MenuItem key={customer.customer_id} value={customer.customer_id}>{customer.company_name}</MenuItem>)) }
                            </Select>
                            <FormHelperText></FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{p:3, pt:0}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button variant={"contained"} color={'primary'} onClick={ handleSubmit }>Confirm</Button>
            </DialogActions>
        </>
    );
}

import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {fetchAccounts} from "../../Redux/Action";
import {currencyFormatter} from "../../../../../Setup/Config";
import {setConfig} from "../../../Accounts";
import {ServiceRequestDetailsCard} from "../Cards/ServiceRequestDetailsCard";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";

export const CurrentOutstandingTable = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const items = useSelector((store) => store.accounts);
    const business_id = useSelector((store) => store.business.selected.business_id);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const filters = useSelector((store) => store.accounts?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.accounts?.config?.pagination);

    useEffect(() => {
        fetchData();
    },[pagination])

    const fetchData = async () => {

        //delete filters['status'];
        delete filters['business_name'];
        delete filters['cheque_no'];
        delete filters['cheque_deposit_date'];
        delete filters['payment_date'];
        delete filters['due_date'];
        filters['status'] = ['PARTIAL','PLANNED','DEFAULT','OVERDUE'];
        filters['transaction_type'] = ['CREDIT'];
        filters['pageSize'] = 10;
        filters['business_id'] = business_id;
        setIsLoading(true);

        await fetchAccounts({dispatch, filters:{...filters, ...pagination}}).then((res) => {
            setIsLoading(false);
            setResponse(res);
        })
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }) => row.loan !== null ? <ServiceRequestDetailsCard data={row.loan} /> : null,
        [],
    );
    const getDetailPanelHeight = React.useCallback(() => 50, []);

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} />
                ),
            },
            { field: 'service_type', headerName:'Payment for', type: 'string', flex:1, minWidth: 220,
                valueGetter: (params) => {
                    return (params.row?.service?.service_type?.name);
                }
            },
            { field: 'amount', headerName:'Amount', type: 'number',flex:1, minWidth: 80, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'balance', headerName:'Balance', type: 'number',flex:1, minWidth: 80, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'due_date', headerName:'Due Dates', type: 'string',flex:1, minWidth: 100, valueFormatter: ({ value }) => value && moment(value).format('DD/MM/YYYY')},
            { field: 'status', headerAlign:'center', align:'center', headerName:'Status', type: 'string', width:120,
                renderCell: (params) => {

                    const status = {
                        'PLANNED':'primary',
                        'PAID':'success',
                        'PARTIAL':'error',
                        'OVERDUE':'error',
                        'DEFAULT':'error',
                    }
                    return(<StatusBall
                        variant="contained"
                        label={params?.value}
                        color={status[params?.value]}
                    />)
                },
            },

        ],
        [],
    );

    return (
        <>
            <DataGridPro
                autoHeight
                getRowId={(row) => row.account_id}
                columns={columns}
                rows={items.list || []}
                sx={{ border:'none'}}
                rowThreshold={0}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                pagination
                paginationMode="server"
                paginationModel={pagination}
                rowCount={items?.config?.rowCount ?? 0}
                pageSizeOptions={[5, 10, 15, 25, 100]}
                loading={isLoading}
                onPaginationModelChange={(params) =>  dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}})) }
                initialState={{
                    pinnedColumns: { right: [ 'status','actions'] },
                    columns:{
                        columnVisibilityModel:{
                            actions: auth.user.role_type !== "CLIENT",
                        }
                    },
                    pagination: { paginationModel: { ...pagination } },
                }}

            />
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

import React, {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    List as ListUI,
    ListItemSecondaryAction,
    ListItemText, TextField,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {updateSetting} from "../../Redux/Action";

export const NotificationSettingsTab = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.settings.list);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const desiredParameters = [
            'email_notification',
            'push_notification',
            'business_submitted_recipient',
            'notify_business_submitted',
            'notify_business_verified',
            'notify_business_head_approved',
            'notify_business_credit_reviewed',
            'notify_business_credit_approved',
            'notify_business_ceo_approved',
            'notify_business_bod_approved',
            'notify_business_approved',
            'notify_invoice_confirmation',
            'notify_customer_approval',
            'notify_service_created',
            'notify_otp',
            'notify_user_registration',
            'user_registration_alert_recipient',
            'notify_document_expired',
            'notify_vat_expired',
            'notify_financial_expired',
            'notify_wps_expired',
            'notify_task_submitted',
            'notify_task_returned',
            'notify_task_verified',
            'task_notification_recipient',
            'task_notification_recipient',
            'management_recipient',
            'notify_customer',
            'notify_default_loan',
            'notify_default_loan_report',
            'send_custom_email'
        ];
        const newData = {};

        items.forEach(item => {
            if (desiredParameters.includes(item.parameter_name)) {
                newData[item.parameter_name] = item.parameter_value;
            }
        });

        setData(newData);
    }, [items]);

    const ActionList = styled(ListUI)({
        width: '100%',
        '& .MuiListItem-root': {
            backgroundColor: '#f6f6f6',
            padding: '0.3rem',
            borderRadius: '5px',
            marginBottom: '0.5rem',
        },
        '& .MuiListItemIcon-root': {
            justifyContent: 'center',
        }
    });

    const handleChange = (event) => {

        const { name, value } = event.target;
        const updatedValue = Array.isArray(value) ? value.join(',') : value;

        setData({
            ...data,
            [name]: updatedValue
        });
    }

    const handleSubmit = async () => {

        const formattedData = Object.keys(data).map((key) => {
            return {parameter_name: key, parameter_value: data[key]}
        });

        setLoading(true)
        await updateSetting({dispatch, data:{...formattedData}}).then((res) => {
            setLoading(false)
        });
    }

    const handleStateButton = async (parameter_name) => {

        const newData = [{parameter_name, 'parameter_value':data[parameter_name] === 'enabled' ? 'disabled' : 'enabled'}];
        await updateSetting({dispatch, data:{ ...newData }});
    }

    return (
        <>
            <Card>
                <CardHeader title={"Notification Settings"} />
                <CardContent sx={{pb:'1rem!important'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Global Settings</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Adjust your notification preferences here.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.email_notification === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Email Notification" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.email_notification === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('email_notification')}>
                                                    {data.email_notification === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.push_notification === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Push Notification" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.push_notification === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('push_notification')}>
                                                    {data.push_notification === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_customer === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Notify Customer" secondary="Notify business's owner about activities" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_customer === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_customer')}>
                                                    {data.notify_customer === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.send_custom_email === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Send Custom Email" secondary="Send Custom Email" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.send_custom_email === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('send_custom_email')}>
                                                    {data.send_custom_email === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Mail Configurations</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    No of days to review the business.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data?.management_recipient ?? ""}
                                        label="General Management Recipient"
                                        variant="outlined"
                                        defaultValue="30"
                                        name={"management_recipient"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data?.business_submitted_recipient ?? ""}
                                        label="Business Submitted Recipient"
                                        variant="outlined"
                                        defaultValue="30"
                                        name={"business_submitted_recipient"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data?.user_registration_alert_recipient ?? ""}
                                        label="New User Notification Recipient"
                                        variant="outlined"
                                        defaultValue="30"
                                        name={"user_registration_alert_recipient"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant={"contained"} onClick={()=> handleSubmit()}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Business Events</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    No of days to review the business.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_submitted === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Business Submitted" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_submitted === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_submitted')}>
                                                    {data.notify_business_submitted === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_verified === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Business Verified" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_verified === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_verified')}>
                                                    {data.notify_business_verified === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_head_approved === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Business Head Approved" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_head_approved === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_head_approved')}>
                                                    {data.notify_business_head_approved === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_credit_reviewed === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Credit Reviewed" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_credit_reviewed === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_credit_reviewed')}>
                                                    {data.notify_business_credit_reviewed === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_credit_approved === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Credit Approved" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_credit_approved === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_credit_approved')}>
                                                    {data.notify_business_credit_approved === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_ceo_approved === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="CEO Approved" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_ceo_approved === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_ceo_approved')}>
                                                    {data.notify_business_ceo_approved === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_bod_approved === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Board of Director Approved" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_bod_approved === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_bod_approved')}>
                                                    {data.notify_business_bod_approved === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_business_approved === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Chairman Approved" secondary="Enables an extra layer of security for your account" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_business_approved === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_business_approved')}>
                                                    {data.notify_business_approved === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Customer Events</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    No of days to review the business.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_customer_approval === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Customer Approval" secondary="Notify business's customer if there is a new invoice submitted" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_customer_approval === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_customer_approval')}>
                                                    {data.notify_customer_approval === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Invoice Events</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    No of days to review the business.
                                </Typography>
                            </>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_invoice_confirmation === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Invoice Confirmation" secondary="Notify business's customer if there is a new invoice submitted" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_invoice_confirmation === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_invoice_confirmation')}>
                                                    {data.notify_invoice_confirmation === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Service Events</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Notification for service events.
                                </Typography>
                            </>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_service_created === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Service Creation" secondary="Notify business's customer if there is a new invoice submitted" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_service_created === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_service_created')}>
                                                    {data.notify_service_created === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">User</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    User & Registration related settings
                                </Typography>
                            </>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_otp === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Send OTP" secondary="Notify business's customer if there is a new invoice submitted" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_otp === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_otp')}>
                                                    {data.notify_otp === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_user_registration === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="New User Registration Notification" secondary="Notify business's customer if there is a new invoice submitted" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_user_registration === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_user_registration')}>
                                                    {data.notify_user_registration === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Business Finance</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Business Finance related settings
                                </Typography>
                            </>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_default_loan === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Defaulted Contract" secondary="Notify stakeholders about the default loan" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_default_loan === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_default_loan')}>
                                                    {data.notify_default_loan === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_default_loan_report === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Defaulted Contract Report" secondary="Send a report on Defaulted Contract to Management" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_default_loan_report === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_default_loan_report')}>
                                                    {data.notify_default_loan_report === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Tasks</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Task notification related settings
                                </Typography>
                            </>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_document_expired === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Document Expiry" secondary="Notify business's owner if there is any document expired" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_document_expired === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_document_expired')}>
                                                    {data.notify_document_expired === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_vat_expired === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Expired VAT Return " secondary="Notify business's owner about the expected VAT Return" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_vat_expired === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_vat_expired')}>
                                                    {data.notify_vat_expired === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_financial_expired === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Expired Financial Record" secondary="Notify business's owner about the expected Financial Data" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_financial_expired === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_financial_expired')}>
                                                    {data.notify_financial_expired === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_wps_expired === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Expired WPS Details" secondary="Notify business's owner about the expected WPS Details" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_wps_expired === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_wps_expired')}>
                                                    {data.notify_wps_expired === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_task_submitted === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Task Submitted" secondary="Notify business analyst team about the new task submitted" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_task_submitted === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_task_submitted')}>
                                                    {data.notify_task_submitted === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_task_returned === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Task Return" secondary="Notify business's owner about the invalid document uploaded" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_task_returned === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_task_returned')}>
                                                    {data.notify_task_returned === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.notify_task_verified === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Task Verified" secondary="Notify business's owner about the Verified Document" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.notify_task_verified === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('notify_task_verified')}>
                                                    {data.notify_task_verified === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
const CONST = {
    'SET_CONFIG':'SUMMARY/SET_CONFIG',
    'CLEAR_PROCEED_STATE':'SUMMARY/CLEAR_PROCEED_STATE',
    'CLEAR_ERROR_STATE':'SUMMARY/CLEAR_ERROR_STATE',
    'SET_SELECTED_ITEM':'SUMMARY/SET_SELECTED_ITEM',
    'SET_SELECTED_ITEM_SUCCESS':'SUMMARY/SET_SELECTED_ITEM_SUCCESS',
    'FETCH_ITEM_SUCCESS':'SUMMARY/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'SUMMARY/FETCH_ITEM_FAILED',
    'FETCH_SUMMARY_SUCCESS':'SUMMARY/FETCH_SUMMARY_SUCCESS',
    'FETCH_SUMMARY_FAILED':'SUMMARY/FETCH_SUMMARY_FAILED',
    'FETCH_ITEMS_SUCCESS':'SUMMARY/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'SUMMARY/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'SUMMARY/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'SUMMARY/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'SUMMARY/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'SUMMARY/UPDATE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'SUMMARY/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'SUMMARY/DELETE_ITEM_FAILED',
}

export default CONST;
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import { extendAccount } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useEffect } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, Select, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import Divider from "@mui/material/Divider";

export const PaymentExtensionModal = () => {
    const dispatch = useDispatch();
    const item = useSelector((store) => store.accounts.selected);
    const [data, setData] = React.useState({});
    const [response, setResponse] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);

    useEffect(() => {
        setData({
            account_id: item?.account_id,
            discount:"",
        });
    }, [item]);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await extendAccount({ dispatch, data }).then((res) => {
            setResponse(res);
            if (res['success']) {
                dispatch(setModal({ component: null, open: false }));
            }
            setDisabled(false);
        });
    };

    return (
        <>
            <DialogTitle>
                Extend Payment for {item?.business?.name}
            </DialogTitle>
            <DialogContent sx={{ justifyContent: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                {data !== undefined && Object.keys(data).length > 0 && (
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={item?.account_type?.name }
                                label={"Payment Type"}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                value={item?.amount}
                                label={"Amount"}
                                readOnly
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                value={item?.balance}
                                label={"Balance"}
                                readOnly
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                defaultValue={data?.discount}
                                label={"Discount(optional)"}
                                fullWidth
                                name="discount"
                                onBlur={handleChange}
                                type={"number"}
                                helperText={response?.error?.data?.errors?.discount}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="label-payment-type">Payment Method</InputLabel>
                                <Select
                                    name="payment_method"
                                    label="Payment Method"
                                    value={data?.payment_method ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem key={"BANK_TRANSFER"} value={"BANK_TRANSFER"}>Bank Transfer</MenuItem>
                                    <MenuItem key={"CHEQUE"} value={"CHEQUE"}>Cheque</MenuItem>
                                    <MenuItem key={"CASH"} value={"CASH"}>Cash</MenuItem>
                                </Select>
                                <FormHelperText>{response?.error?.data?.errors?.payment_method}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} item><Divider /></Grid>

                        {data.payment_method === 'CHEQUE' && (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <MuiDatepicker
                                        error={response?.error?.data?.errors?.cheque_date}
                                        setDisabled={setDisabled}
                                        format={"YYYY-MM-DD"}
                                        views={['year', 'month', 'day']}
                                        label={"Cheque Date"}
                                        past={false}
                                        name="cheque_date"
                                        data={data}
                                        state={setData}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        defaultValue={data?.cheque_no ?? ""}
                                        label={"Cheque No"}
                                        fullWidth
                                        name="cheque_no"
                                        onBlur={handleChange}
                                        type={"number"}
                                        helperText={response?.error?.data?.errors?.cheque_no}
                                    />
                                </Grid>
                            </>
                        )}

                        {data.payment_method === 'BANK_TRANSFER' && (
                            <Grid item xs={12} sm={6}>
                                <MuiDatepicker
                                    error={response?.error?.data?.errors?.due_date}
                                    setDisabled={setDisabled}
                                    format={"YYYY-MM-DD"}
                                    views={['year', 'month', 'day']}
                                    label={"Due Date"}
                                    past={false}
                                    name="due_date"
                                    data={data}
                                    state={setData}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <TextField
                                defaultValue={data?.remarks ?? ""}
                                label={"Remarks"}
                                fullWidth
                                name="remarks"
                                onBlur={handleChange}
                                type={"text"}
                                helperText={response?.error?.data?.errors?.remarks}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions sx={{ p: 3, pt: 0 }}>
                <Button onClick={() => dispatch(setModal({ component: null, open: false }))}>Cancel</Button>
                <Button variant={"contained"} color={'primary'} onClick={handleSubmit} disabled={disabled}>
                    Extend
                </Button>
            </DialogActions>
        </>
    );
}

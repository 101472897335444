import React from 'react'
import { Routes as Switch, Route } from 'react-router-dom'
import PublicRoutes from "./PublicRoutes";

import {
    Login,
    Logout,
    Register,
    ForgotPassword,
    EmailVerification,
    RegisterVerify,
    ResetPassword,
} from '../Modules/Common/Auth';

export const CommonRoutes = () => {

    return (
        <Switch>

            <Route element={<PublicRoutes layout={'Auth'} />} >
                <Route exact path='/register' element={<Register/>}/>
                <Route exact path='/forgot-password' element={<ForgotPassword/>}/>
                <Route exact path='/reset-password/:token' element={<ResetPassword/>}/>
                <Route exact path='/verify/:token' element={<RegisterVerify/>}/>
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='/logout' element={<Logout/>}/>
                <Route exact path='/email-verification/:token' element={<EmailVerification/>}/>

            </Route>

        </Switch>
    )
}

import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from "@mui/material";

export default function Toastr({ response }) {

    const [msg, setMsg] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (response) {
            const hasError = response.hasOwnProperty('error') && response.error !== undefined;
            const statusMessage = hasError ? response?.error?.data?.message : "Success";
            const statusCode = hasError ? response?.error?.status : response?.data?.status;

            setOpen(hasError || response.hasOwnProperty('success'));
            setMsg({
                'code': statusCode,
                'message': statusMessage,
            });
        }
    }, [response]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const determineAlertSeverity = (code) => {
        switch(true) {
            case code >= 200 && code < 300:
                return 'success';
            case code === 422:
                return 'warning';
            case code >= 403:
                return 'error';
            default:
                return 'info';
        }
    };

    return (
        <>
            {
                msg.code &&
                <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={handleClose}>
                    <Alert severity={determineAlertSeverity(msg.code)} sx={{ mb: 3 }}>
                        {msg.message ?? 'Successfully Completed'}
                    </Alert>
                </Snackbar>
            }
        </>
    );
}

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});


/************************************************************
 *  Fetch Fund Stats
 *************************************************************/
export const fetchAccountStats = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/account/fund-stats`, {params: filters}),
        [],
        []
    );
}

/************************************************************
 *  Fetch Fund Statement
 *************************************************************/
export const fetchFundStatementSuccess = (payload) => ({
    type: CONST.FETCH_FUND_STATEMENT_SUCCESS,
    payload: payload
});

export const fetchFundStatementFailed = (payload) => ({
    type: CONST.FETCH_FUND_STATEMENT_FAILED,
    payload: payload
});

export const fetchFundStatement = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/account/fund-statement`, {params: filters}),
        [fetchFundStatementSuccess],
        [fetchFundStatementFailed]
    );
}

/************************************************************
 *  Fetch Fund Details
 *************************************************************/
export const fetchFundDetails = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/account/fund-statement-by-month/`,{params: filters}),
        [],
        []
    );
}
import React, { useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { DialogTitle, DialogContent, Alert, DialogActions, Button, Stack } from "@mui/material";
import { setModal } from "../../../../Common/App";
import {fetchListings, fetchListing, updateListing} from "../../../Listings";
import Typography from "@mui/material/Typography";

export const DistributeRecoveryModal = () => {

    const dispatch = useDispatch();
    // const listing = useSelector((store) => store.listings.selected);
    // const filters = useSelector((store) => store.listings.config.filters);
    //
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    //
    // const handleSubmit = async () => {
    //     setDisabled(true);
    //     await updateListing({dispatch, data:{id:listing.id, status:'ACTIVE'}}).then((res)=>{
    //         if(res['error']){
    //             setDisabled(false);
    //             setResponse(res);
    //         }else{
    //             fetchListings({dispatch, data:{...filters}});
    //             fetchListing({dispatch, id:listing.id});
    //             dispatch(setModal({component:null, open:false}));
    //         }
    //     });
    // };

    return(
        <>
            <DialogTitle>Distribute Recovery Amount</DialogTitle>
            <DialogContent sx={{paddingTop:'20px!important', paddingBottom:'0!important'}}>
                <Stack spacing={2}>
                    <Typography sx={{mb:2}}>This feature is not ready.</Typography>
                    <Typography sx={{mb:2}}>The plan is to select any listing which is marked as defaulted and after recovery, distribute the amount using request & transaction.</Typography>
                    <Typography sx={{mb:2}}>The request will create deposit or settlement transaction for each of the wallet.</Typography>
                    {/*{response.error && <Alert severity="error">{response.error.data.message}</Alert> }*/}
                </Stack>
            </DialogContent>
            <DialogActions sx={{p:3}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'}>Proceed</Button>
            </DialogActions>
        </>
    )
}

import React, {useEffect, useState} from 'react'
import {Breadcrumbs} from "../../../../Components";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {ServiceTable} from "./Tables/ServiceTable";
import {useDispatch, useSelector} from "react-redux";
import { setModal } from "../../../Common/App";
import {setConfig} from "../Redux/Action";

export const Service = () => {

    const dispatch = useDispatch();
    const filters = useSelector((store) => store.services?.config?.filters);
    const [configLoaded, setConfigLoaded] = useState(false);

    useEffect(() => {
        dispatch(setModal({ component:null, open: false}))
        setFilters(filters);
    }, []);

    const setFilters = async (filters) => {

        dispatch(setConfig({filters: {...filters, status: []} }))
        setConfigLoaded(true);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={8} xs={6}>
                    <Breadcrumbs items={'DASHBOARD/FUNDING_REQUESTS'} />
                </Grid>
                <Grid item sm={4} xs={6} sx={{display:'grid'}}>

                </Grid>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader title={"Funding Requests"} />
                        <CardContent sx={{p:0, pb:0}}>
                            {configLoaded ? <ServiceTable /> : <p>Loading...</p>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
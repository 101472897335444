import CONST from './constant';
import {axiosPublic} from "../../../../Setup/Axios/SetupAxios";
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';
import axiosPrivate from "../../../../Setup/Axios/SetupAxios";


/****************************************************
 * Register
 ****************************************************/
export const doRegisterSuccess = (payload) => ({
    type: CONST.REGISTER_USER_SUCCESS,
    payload: payload
});

export const doRegisterFailed = (payload) => ({
    type: CONST.REGISTER_USER_FAILED,
    payload: payload
});

export const doRegister = ({dispatch, data}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPublic.post('register', data),
        [doRegisterSuccess],
        [doRegisterFailed]
    );
};

/****************************************************
 * Register Investor
 ****************************************************/
export const doRegisterInvestorSuccess = (payload) => ({
    type: CONST.REGISTER_USER_SUCCESS,
    payload: payload
});

export const doRegisterInvestorFailed = (payload) => ({
    type: CONST.REGISTER_USER_FAILED,
    payload: payload
});

export const doRegisterInvestor = ({dispatch, data}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPublic.post('register/investor', data),
        [doRegisterInvestorSuccess],
        [doRegisterInvestorFailed]
    );
};

/****************************************************
 * Verify OTP
 ****************************************************/
export const verifyOtpSuccess = (payload) => ({
    type: CONST.VERIFY_OTP_SUCCESS,
    payload: payload
});

export const verifyOtpFailed = (payload) => ({
    type: CONST.VERIFY_OTP_FAILED,
    payload: payload
});

export const verifyOtp = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPublic.post('verify-otp', data),
        [verifyOtpSuccess],
        [verifyOtpFailed]
    );
}

/****************************************************
 * Resend OTP
 ****************************************************/
export const resendOtpSuccess = (payload) => ({
    type: CONST.RESEND_OTP_SUCCESS,
    payload: payload
});

export const resendOtpFailed = (payload) => ({
    type: CONST.RESEND_OTP_FAILED,
    payload: payload
});

export const resendOtp = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPublic.post('resend-otp', data),
        [resendOtpSuccess],
        [resendOtpFailed]
    );
}


/****************************************************
 * Login
 ****************************************************/
export const doLoginSuccess = (payload) => ({
    type: CONST.LOGIN_SUCCESS,
    payload: payload
});

export const doLoginFailed = (payload) => ({
    type: CONST.LOGIN_FAILED,
    payload: payload
});

export const doLogin = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPublic.post('login/', data),
        [doLoginSuccess],
        [doLoginFailed]
    );
}

/****************************************************
 * Logout
 ****************************************************/
export const doLogoutSuccess = (payload) => ({
    type: CONST.PERFORM_LOGOUT_SUCCESS,
    payload,
});

export const doLogoutFailed = (payload) => ({
    type: CONST.PERFORM_LOGOUT_FAILED,
    payload,
});

export const doLogout = async (dispatch) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('logout'),
        [doLogoutSuccess],
        [doLogoutFailed]
    );
};

/****************************************************
 * Mark Notification Read
 ****************************************************/
export const markAllReadNotificationSuccess = (payload) => ({
    type: CONST.MARK_NOTIFICATION_READ_SUCCESS,
    payload: payload
});

export const markAllReadNotificationFailed = (payload) => ({
    type: CONST.MARK_NOTIFICATION_READ_FAILED,
    payload: payload
});

export const markAllReadNotification = async ({dispatch}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('notification/all/read'),
        [markAllReadNotificationSuccess],
        [markAllReadNotificationFailed]
    );
};

/****************************************************
 * Verify Email
 ****************************************************/
export const verifyEmailSuccess = (payload) => ({
    type: CONST.VERIFY_EMAIL_SUCCESS,
    payload: payload
});

export const verifyEmailFailed = (payload) => ({
    type: CONST.VERIFY_EMAIL_FAILED,
    payload: payload
});

export const verifyEmail = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPublic.post('verify-email-registration', data),
        [verifyEmailSuccess],
        [verifyEmailFailed]
    );
}
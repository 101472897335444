import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import { FinancialForm } from "../Forms/FinancialForm";
import {Grid, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import { addFinancial,  clearErrors, proceed, setSelectedFinancial, FinancialDeleteModal} from "../../index";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";

export const FinancialDetailStep = () => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.financials).length > 0 ? business.financials.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addFinancial({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedFinancial(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<FinancialForm />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<FinancialDeleteModal />, open:true}));
        dispatch(setSelectedFinancial(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'auditor_name', headerName:'Auditor Name', type: 'string', flex:1, minWidth: 160 },
            { field: 'financial_year', headerName:'Financial Year', type: 'date', flex:1, minWidth:100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"), },
            { field: 'total_revenue', headerName:'Total Revenue', type: 'number', flex:1, minWidth:130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'gross_profit', headerName:'Gross Profit', type: 'number', flex:1, minWidth:130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'net_profit', headerName:'Net Profit', type: 'number', flex:1, minWidth:130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    const CustomFooter = () => {
        return (
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Financials</Button>
            </Stack>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items || []} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                    slots={{
                        footerRow: CustomFooter,
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Financials</Button>
                    </Grid>
                    { items && items.length === 0 &&
                        <Grid item xs={12}>
                            <Instructions />
                        </Grid>
                    }

                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
                <Typography variant={"h3"}>Financial Performance
                </Typography>
                <Typography variant={"body1"}>
                    To complete this section, be ready with the Audit Report / Management Report (Inhouse) for the previous 2 years including Year to Date Financials. You need to attach the financials for respective period. Please fill in the required headings from the P&L Statement and Balance Sheet. In case any of the below section does not apply to your company, leave it as blank. The explanation to the fields is as follows:

                </Typography>
                <List>
                    <ListItemText primary="Auditor Name" className={'fw-bold'} secondary="Name of the Auditor / Inhouse" />
                    <ListItemText primary="Financial Year" secondary="Enter the financial year ending period." />
                    <ListItemText primary="Total Revenue" secondary="Enter the Total Revenue / Sales" />
                    <ListItemText primary="Gross Profit" secondary="Enter the Gross Profit" />
                    <ListItemText primary="Net Profit" secondary="Enter the Net Profit" />
                    <ListItemText primary="Staff Salary" secondary="It is the amount paid to staff during the period." />
                    <ListItemText primary="Equity" secondary="It is the total shareholder’s fund. (Share capital + statutory reserve + retained earnings + shareholders current account)" />
                    <ListItemText primary="Loan Liabilities" secondary="All borrowings made by the company. [long term + short term)" />
                    <ListItemText primary="Trade Receivables" secondary="Accounts/Trade receivables as mentioned in the balance sheet." />
                    <ListItemText primary="Other Receivables" secondary="As mentioned in the balance sheet, if any" />
                    <ListItemText primary="Trade Payables" secondary="Accounts/Trade payables as mentioned in the balance sheet." />
                    <ListItemText primary="Other Payables" secondary="Other Payables as mentioned in the balance sheet." />
                    <ListItemText primary="Stock" secondary="Enter the amount mentioned as Inventory/Stock in the balance sheet." />
                    <ListItemText primary="Fixed Asset" secondary="Fixed Asset as mentioned in the Balance Sheet." />
                    <ListItemText primary="Bank Balance" secondary="Enter the bank figure from the balance sheet." />
                    <ListItemText primary="Cash Balance" secondary="Enter the cash in hand figure from the balance sheet." />
                    <ListItemText primary="Remarks" secondary="Enter the remarks if you would like to comment related to your financials" />
                </List>
            </InfoBox>)
}
import * as React from 'react';
import {Card, CardContent, CardHeader, Grid, Stack} from "@mui/material";
import {useSelector} from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {CardList} from "../../../../../Components";
import moment from "moment";
import {currencyFormatter} from "../../../../../Setup/Config";
export default function InvoiceDetailsCard() {

    const invoice = useSelector((state) => state.invoices.selected);

    return (
        <>
            { invoice !== undefined &&
                <Stack spacing={2}>
                    <Card>
                        <CardHeader title={"Invoice Details"} subheader={invoice.invoice_no} />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <CardList>
                                        <ListItem>
                                            <ListItemText primary={"Invoice No"} secondary={invoice.invoice_no} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Invoice Date"} secondary={invoice.invoice_date ? moment(invoice.invoice_date).format("DD/MM/YYYY") : '-'} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Invoice Due Date"} secondary={ invoice.invoice_due_date ? moment(invoice.invoice_due_date).format("DD/MM/YYYY") : '-'} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Invoice Acceptable Date"} secondary={ invoice.invoice_acceptable_date ? moment(invoice.invoice_acceptable_date).format("DD/MM/YYYY") : '-'} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Invoice Amount"} secondary={currencyFormatter.format(invoice.invoice_amount)} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Purchase Order No"} secondary={invoice.purchase_order_no} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Purchase Order No"} secondary={invoice.purchase_order_no} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Purchase Order No"} secondary={ invoice.purchase_order_date ? moment(invoice.purchase_order_date).format("DD/MM/YYYY") : '-'} />
                                        </ListItem>
                                    </CardList>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CardList>
                                        <ListItem>
                                            <ListItemText primary={"Inco Terms"} secondary={invoice.inco_terms} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Currency"} secondary={invoice.currency} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Origin"} secondary={invoice.origin} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Destination"} secondary={invoice.destination} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Remarks"} secondary={invoice.remarks} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary={"Rejection Reason"} secondary={invoice.reason} />
                                        </ListItem>
                                    </CardList>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            }
        </>
    );
}
import React, {useEffect} from 'react'
import {Alert, Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DiscountedInvoiceCard} from "./Cards/DiscountedInvoiceCard";
import {FundingRequestsPanel} from "./Panels/FundingRequestsPanel";
import {RegisterBusinessCard} from "./Cards/RegisterBusinessCard";
import {MyInvoicesPanel} from "../../Invoices";
import {MyCustomersPanel} from "../../Customers";
import { CurrentFundingCard, CurrentOutstandingCard, ApprovedCustomersCard, TotalFundedCard } from "../index";
import {OverdueCard} from "./Cards/OverdueCard";
import {fetchBusinesses} from "../../Business";
import {MonthlyPaymentPanel} from "./Panels/MonthlyPaymentPanel";
import {UpcomingPaymentPanel} from "./Panels/UpcomingPaymentPanel";
import {OverduePaymentPanel} from "./Panels/OverduePaymentPanel";
import {Breadcrumbs} from "../../../../Components";
import {Loader} from "../../../../Components/Elements/Loader";
import {NewRequestButton} from "./Buttons/NewRequestButton";
import {useParams} from "react-router-dom";
import {setConfig as setServiceConfig} from "../../Services";
import {setConfig as setAccountConfig} from "../../Accounts";
import {TasksPanel} from "./Panels/TasksPanel";

export const DashboardBusiness = () => {

    const dispatch = useDispatch();
    const {business_id} = useParams();
    const business = useSelector(store => store.business.list);
    const permissions = useSelector((store) => store.app.permissions);
    const services = useSelector((store) => store.app.services);
    const broadcast = useSelector((store) => store.app.config?.broadcast);
    const auth = useSelector((store) => store.auth);
    const serviceFilter = useSelector((store) => store.services.config.filters);
    const accountFilter = useSelector((store) => store.accounts.config.filters);
    const [response, setResponse] = React.useState({});

    useEffect(() => {
        fetchData()
    },[]);

    useEffect(() => {
        const updatedServiceFilter = {...serviceFilter, status: ['PENDING','VERIFIED','APPROVED','IN_PROGRESS','ACTIVE','CLOSED','RESTRUCTURED']}
        dispatch(setServiceConfig({filters:updatedServiceFilter}));

        const updatedAccountFilter = {...accountFilter, status: ['PARTIAL','PENDING','OVERDUE','DEFAULT']};
        dispatch(setAccountConfig({filters:updatedAccountFilter}));
    },[]);

    const fetchData = async () => {
        await fetchBusinesses({dispatch, data:{business_id}}).then((res) => {
            setResponse(res);
        });
    }

    const business_name = auth.user.role_type === 'CLIENT' ? "" : '/' + business[0]?.name;

    const renderBusinessApprovedContent = () => (
        <>
            <Grid item container direction="row" spacing={2}>
                <Grid item sm={8} xs={6}>
                    <Breadcrumbs items={'DASHBOARD'+ business_name} />
                </Grid>
                <Grid item sm={4} xs={6} sx={{ display: 'grid' }}>
                    <NewRequestButton status={business[0]?.status} />
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
                <Grid item container spacing={2} md={6} xs={12}>
                    <Grid item xs={12}>
                        <Alert severity={business[0]?.status === 'DEFAULT' ? "error" : "info"}>{ business[0]?.status === 'DEFAULT' ? "Your Business Has Been Set as the Default, please contact customer support." : broadcast}</Alert>
                    </Grid>
                    <Grid item sm={4} xs={6}><TotalFundedCard /></Grid>
                    <Grid item sm={4} xs={6}><CurrentFundingCard /></Grid>
                    <Grid item sm={4} xs={6}><CurrentOutstandingCard /></Grid>
                    <Grid item sm={4} xs={6}><ApprovedCustomersCard /></Grid>
                    <Grid item sm={4} xs={6}><DiscountedInvoiceCard /></Grid>
                    <Grid item sm={4} xs={6}><OverdueCard /></Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <MonthlyPaymentPanel />
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
                {permissions.includes("show-upcoming-payments-panel-bd") && (
                    <Grid item md={6} xs={12}><UpcomingPaymentPanel /></Grid>
                )}
                {permissions.includes("show-overdue-payments-panel-bd") && (
                    <Grid item md={6} xs={12}><OverduePaymentPanel /></Grid>
                )}
                {permissions.includes("show-service-requests-panel-bd") && (
                    <Grid item md={6} xs={12}><FundingRequestsPanel /></Grid>
                )}
                {permissions.includes("show-invoices-panel-bd") && (services !== undefined && services.some((s) => s.short_code === 'ID')) && (
                    <Grid item md={6} xs={12}><MyInvoicesPanel /></Grid>
                )}
                {permissions.includes("show-customers-panel-bd") && (services !== undefined && services.some((s) => s.short_code === 'ID')) && (
                    <Grid item md={6} xs={12}><MyCustomersPanel /></Grid>
                )}
                {permissions.includes("show-task-panel") && (
                    <Grid item md={12} lg={6} xs={12}><TasksPanel /></Grid>
                )}
            </Grid>
        </>
    );

    const renderRegisterBusinessCard = () => (
        <Grid item container direction="row" spacing={2} mt={2}>
            <Grid item xs={12}>
                <RegisterBusinessCard />
            </Grid>
        </Grid>
    );

    return (
        <>
            {response?.data?.data?.total !== undefined ? (
                <Grid container spacing={2} direction="row" justifyContent="top" alignItems="center">
                    {(business.filter(value => ['APPROVED','DEFAULT'].includes(value.status))).length > 0
                        ? renderBusinessApprovedContent()
                        : renderRegisterBusinessCard()}
                </Grid>
            ) : (
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{height:'80vh'}}>
                    <Loader type="linear" height={3} label="Preparing Dashboard..." fontSize={28} />
                </Grid>
            )}
        </>
    );
}

import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import { deleteBankBorrowing } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const BankBorrowingDeleteModal = () => {

    const dispatch = useDispatch();
    const bankBorrowing = useSelector((store)=> store.business.bankBorrowings.selected);

    const handleSubmit = async () => {
        await deleteBankBorrowing({dispatch, id:bankBorrowing.id}).then((res) => {
            if(res['success']){
                console.log('Bank Borrowing Deleted Successfully')
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    return (
        <>
            <DialogTitle>Confirm Delete!</DialogTitle>
            <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                Are you sure, you want to delete this entry?
            </DialogContent>
            <DialogActions sx={{p:3, pt:0}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button variant={"contained"} color={'primary'} onClick={ handleSubmit }>Proceed</Button>
            </DialogActions>
        </>
    );
}
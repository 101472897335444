import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";
import {Card as CardUI, CardActions, CardContent, CardHeader, LinearProgress as LinearProgressUI} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {currencyFormatter} from "../../../../../Setup/Config";
import {setModal} from "../../../../Common/App";
import {useDispatch, useSelector} from "react-redux";
import {AddFundingPanel} from "../../../Fundings/Components/Modals/AddFundingPanel";

export const ListingRegistrationPanel = React.memo(() => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const listing = useSelector((store) => store.listings.selected);
    const progress = parseFloat(((listing.total_funding / listing.service.amount) * 100).toFixed(2));

    const Card = styled(CardUI)({
        boxShadow:'0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
        '& .MuiCardHeader-root':{
            borderBottom:'none',
            paddingBottom:'9px',
            '& .MuiCardHeader-content':{
                '& .MuiCardHeader-title':{
                    fontSize:'1.1rem'
                },
                '& .MuiCardHeader-subheader p':{
                    fontSize:'.70rem'
                }
            },

        },
        '& .MuiCardActions-root':{
            background:'#fefefe',
            borderTop: '1px solid #ccc',
            justifyContent:'center',
            //display:'none'
        },
    });

    const LinearProgress = styled(LinearProgressUI)(({ theme }) => ({
        position:'relative!important',
        height: '40px',
        border: `2px solid ${theme.palette.primary.dark}`, // Use theme primary color
        boxShadow: '0px 0px 14px -5px rgb(49 65 166)',
        width: '100%',
        borderRadius: '10px',
        backgroundColor: theme.palette.primary.light,
        '& .MuiLinearProgress-bar1Determinate': {
            boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
        },
    }));

    const ProgressBar = styled(Box, {
        shouldForwardProp: (prop) => prop !== "value",
    })(
        ({ theme, value }) => ({
            position: "relative",

            "& .left": {
                position: "absolute",
                top: "50%",
                left: "15px",
                fontWeight:'bold',
                transform: "translateY(-50%)",
                color: value > 20 ? theme.text.light : theme.text.dark,
            },
            "& .right": {
                position: "absolute",
                top: "50%",
                right: "15px",
                transform: "translateY(-50%)",
                fontWeight:'bold',
                color: value > 70 ?  theme.text.light : theme.palette.primary.dark,
            },
        })
    );

    const handleBidButton = async () => {

        dispatch(setModal({component: <AddFundingPanel />, open: true}))
    }

    return(
        <Card>
            <CardHeader title={"Place your Bid"} />
            <CardContent>
                <ProgressBar value={progress}>
                    <LinearProgress variant="determinate" value={progress} />
                    <Typography className={"left"} variant="body2">Funded {progress + "%"}</Typography>
                    <Typography className={"right"} variant="body2">AED {currencyFormatter.format(listing.total_funding)}</Typography>
                </ProgressBar>
            </CardContent>
            <CardActions>
                <Button disabled={listing.status === "FUNDED"} color={"primary"} variant={"contained"} onClick={handleBidButton}>Bid Now</Button>
            </CardActions>
        </Card>
    );
})
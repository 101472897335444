import React from 'react';
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {MetricTemplateCard} from "./MetricTemplateCard";

export const CurrentFundingCard = () => {

    const count = useSelector(store => store.dashboard.stats?.currentFunding);
    const displayedCount = useCounterEffect(0, count);

    return (
        <>
            <MetricTemplateCard unit={"AED"} title={"Current Funding"} displayedCount={displayedCount} link={"/business-finance/current-funding"} linkText={"See All"} />
        </>
    );
}

import * as React from 'react';
import {
    Grid,
    InputLabel,
    Select,
    Stack,
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    FormHelperText, FormLabel, FormGroup, RadioGroup, Radio
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {updateEmployment} from "../../../../Investor/Profile";
import {useDispatch, useSelector} from "react-redux";
import Toastr from "../../../../../Components/Elements/Toastr";


export const EmploymentForm = forwardRef((props, ref)  => {

    const dispatch = useDispatch();
    const [data, setData] = React.useState({});
    const [response, setResponse] = React.useState({});
    const user = useSelector(state => state.profile.selected);

    useImperativeHandle(ref, ()=>{
        return {
            handleSubmit:() => handleSubmit()
        }
    });

    useEffect(()=>{
        setData({
            occupation: user?.employment?.occupation ?? "",
            employment_status: user?.employment?.employment_status ?? "",
            company_name: user?.employment?.company_name ?? "",
            industry: user?.employment?.industry ?? "aa" ?? "",
            statutory_director: user?.employment?.statutory_director ?? "",
            funding_source: user?.employment?.funding_source ?? "",
            public_function: user?.employment?.public_function ?? "",
            investment_preference: user?.employment?.investment_preference ?? "",
            is_beneficiary: user?.employment?.is_beneficiary ?? "",
            beneficiary_name: user?.employment?.beneficiary_name ?? "",
            us_citizen_resident: user?.employment?.us_citizen_resident ?? "",
            tax_identification_number: user?.employment?.tax_identification_number ?? "",
        });
    },[]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;

        setData(data => ({
            ...data,
            [name]: newValue,
        }));
    }

    const handleSubmit = async () => {
        let response = {}
        await updateEmployment({dispatch, data}).then((res)=>{
            setResponse(res);
            response = res;
        });
        return response;
    };

    return (
        <>
            {(data !== undefined && Object.keys(data).length > 0) &&
                <Grid container spacing={3}  justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Typography variant={"h2"} sx={{fontSize:'1.7rem'}} my={3}>Employment Details</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id={"label-employment-status"}>Employment Status</InputLabel>
                            <Select
                                fullWidth
                                name={"employment_status"}
                                onBlur={handleChange}
                                label={"Employment Status"}
                                variant={"outlined"}
                                required
                                defaultValue={data?.employment_status}
                            >
                                <MenuItem value={""}>Please select</MenuItem>
                                <MenuItem value={"employed"}>Employed</MenuItem>
                                <MenuItem value={"self-employed"}>Self-Employed</MenuItem>
                                <MenuItem value={"unemployed"}>Unemployed</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.employment_status}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id={"industry"}>What sector does your employer operate in?</InputLabel>
                            <Select
                                fullWidth
                                name={"industry"}
                                onBlur={handleChange}
                                label={"What sector does your employer operate in?"}
                                variant={"outlined"}
                                required
                                defaultValue={data?.industry ?? ""}
                            >
                                <MenuItem value={""}>Please Select</MenuItem>
                                <MenuItem value={"auditing-firms"}>Auditing firms</MenuItem>
                                <MenuItem value={"car-plane-boat-trading-dealership"}>Car, Plane or Boat Trading/Dealership</MenuItem>
                                <MenuItem value={"cash-intensive-business"}>Cash Intensive Business</MenuItem>
                                <MenuItem value={"corporate-service-providers"}>Corporate Service Providers</MenuItem>
                                <MenuItem value={"defense-military"}>Defense & Military</MenuItem>
                                <MenuItem value={"gambling-pawn-brokerage-antiquities-auctions"}>Gambling, Pawn-Brokerage, Antiquities or Auctions</MenuItem>
                                <MenuItem value={"industrial-plant-machinery-manufacturing"}>Industrial Plant & Machinery Manufacturing</MenuItem>
                                <MenuItem value={"mining-metals"}>Mining & Metals</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.industry}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            onBlur={handleChange}
                            name={"company_name"}
                            label={"Company Name"}
                            variant={"outlined"}
                            required
                            defaultValue={data?.company_name}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.company_name}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id={"label-investment-preference"}>Investment Preference</InputLabel>
                            <Select
                                fullWidth
                                label={"Investment Preference"}
                                name={"investment_preference"}
                                onBlur={handleChange}
                                variant={"outlined"}
                                required
                                defaultValue={data?.investment_preference}
                            >
                                <MenuItem value={""}>Please select</MenuItem>
                                <MenuItem value={"SHARIA"}>Sharia Complaint</MenuItem>
                                <MenuItem value={"CONVENTIONAL"}>Conventional</MenuItem>
                                <MenuItem value={"BOTH"}>Both</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.investment_preference}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id={"label-"}>Occupation</InputLabel>
                            <Select
                                fullWidth
                                label={"Occupation"}
                                name={"occupation"}
                                onBlur={handleChange}
                                variant={"outlined"}
                                required
                                defaultValue={data?.occupation}
                            >
                                <MenuItem value={""}>Please select</MenuItem>
                                <MenuItem value={"owner"}>Owner</MenuItem>
                                <MenuItem value={"director"}>Director</MenuItem>
                                <MenuItem value={"executive"}>Executive</MenuItem>
                                <MenuItem value={"manager"}>Manager</MenuItem>
                                <MenuItem value={"accountant"}>Accountant</MenuItem>
                                <MenuItem value={"administrator"}>Administrator</MenuItem>
                                <MenuItem value={"medical-professional"}>Medical professional</MenuItem>
                                <MenuItem value={"economists"}>Economists</MenuItem>
                                <MenuItem value={"engineer"}>Engineer</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.occupation}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id={"label-"}>Funding Source for your Planned Investment</InputLabel>
                            <Select
                                fullWidth
                                label={"Funding Source for your Planned Investment"}
                                variant={"outlined"}
                                name={"funding_source"}
                                onBlur={handleChange}
                                required
                                defaultValue={data?.funding_source ?? ""}
                            >
                                <MenuItem value={""}>Please select</MenuItem>
                                <MenuItem value={"auditing-firms"}>Auditing Firms</MenuItem>
                                <MenuItem value={"car-plane-or-boat-trading-dealership"}>Car, Plane or Boat Trading/Dealership</MenuItem>
                                <MenuItem value={"cash-intensive-business"}>Cash Intensive Business</MenuItem>
                                <MenuItem value={"corporate-service-providers"}>Corporate Service Providers</MenuItem>
                                <MenuItem value={"defense-military"}>Defense & Military</MenuItem>
                                <MenuItem value={"gambling-pawn-brokerage-antiquities-or-auctions"}>Gambling, Pawn-Brokerage, Antiquities or Auctions</MenuItem>
                                <MenuItem value={"industrial-plant-machinery-manufacturing"}>Industrial Plant & Machinery Manufacturing</MenuItem>
                                <MenuItem value={"mining-metals"}>Mining & Metals</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.funding_source}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id={"label-"}>Do you have an Important Public Function?</InputLabel>
                            <Select
                                fullWidth
                                label={"Do you have an Important Public Function?"}
                                variant={"outlined"}
                                name={"public_function"}
                                onBlur={handleChange}
                                required
                                defaultValue={data?.public_function ?? ""}
                            >
                                <MenuItem value={""}>Please select</MenuItem>
                                <MenuItem value={"none"}>None</MenuItem>
                                <MenuItem value={"senior-government-official"}>Senior Government Official</MenuItem>
                                <MenuItem value={"senior-military-official"}>Senior Military Official</MenuItem>
                                <MenuItem value={"direct-relation-to-a-senior-government-official"}>Direct Relation to a Senior Government Official</MenuItem>
                                <MenuItem value={"direct-relation-to-a-senior-military-official"}>Direct Relation to a Senior Military Official</MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.public_function}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            onBlur={handleChange}
                            name={"statutory_director"}
                            //helperText={response?.error?.data?.errors?.statutory_director}
                            control={<Checkbox checked={data?.statutory_director === 1} onChange={handleChange} />}
                            label={"Are you a statutory director or own > 5% of the company’s equity?"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            name={"us_citizen_resident"}
                            onBlur={handleChange}
                            control={<Checkbox checked={data?.us_citizen_resident === 1} onChange={handleChange} />}
                            label={"Are you a citizen of the United States?"}
                            //helperText={response?.error?.data?.errors?.us_citizen_resident}
                        />
                    </Grid>
                    { data?.us_citizen_resident === 1 &&
                    <Grid item xs={12} md={6} sx={{marginRight:'auto'}}>

                            <TextField
                                fullWidth
                                label={"Tax Identification Number"}
                                name={"tax_identification_number"}
                                onBlur={handleChange}
                                defaultValue={data?.tax_identification_number}
                                variant={"outlined"}
                                required
                                helperText={response?.error?.data?.errors?.tax_identification_number}
                            />

                    </Grid>
                    }

                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Are you the Beneficial Owner?</FormLabel>
                            <RadioGroup aria-label="is_beneficiary" name="is_beneficiary" defaultValue={data?.is_beneficiary} onChange={handleChange}>
                                <FormControlLabel
                                    value={"1"}
                                    control={<Radio checked={data?.is_beneficiary === 1} />}
                                    label="The person registering is the beneficial owner of funds on Beehive."
                                />
                                <FormControlLabel
                                    value={"0"}
                                    control={<Radio checked={data?.is_beneficiary === 0} />}
                                    label="The person registering declares that the beneficial owner of the assets transferred is:"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    { data?.is_beneficiary === 0 &&
                    <Grid item xs={12} md={6} sx={{marginRight:'auto'}}>
                        <TextField
                            fullWidth
                            label={"Beneficiary Name"}
                            name={"beneficiary_name"}
                            onBlur={handleChange}
                            variant={"outlined"}
                            required
                            defaultValue={data?.beneficiary_name}
                            helperText={response?.error?.data?.errors?.beneficiary_name}
                        />
                    </Grid>
                    }

                    <Toastr response={response} />
                </Grid>
            }
        </>
    )
});

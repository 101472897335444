import * as React from 'react';
import {
    Card,
    CardHeader,
    CardActions,
    List as ListUI,
    CardContent,
    ListItem,
    ListItemText,
    TextField,
    Button,
    Stack,
    Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateEmployment} from "../../Redux/Action";
import Toastr from "../../../../../Components/Elements/Toastr";

export const EmploymentDetailsTab = () => {

    const dispatch = useDispatch();
    const [editMode, setEditMode] = React.useState(false);
    const profile = useSelector((store) => store.profile);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleEditBtn = () => {
        setEditMode(!editMode);
    };

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData(profile.selected?.employment);
    }, [])

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
    });

    const handleSaveBtn = async () => {

        setDisabled(true);

        await updateEmployment({dispatch, data}).then((res) => {
            setResponse(res);
            if(res['success']){
                setDisabled(false);
                setEditMode(false);
            }
        });
    }

    const handleCancelBtn = () => {
        // Reset data and set editMode to false
        setData(profile?.selected?.employment);
        setEditMode(false);
    };

    return (
        <>
            <Card>
                <CardHeader title={"Employment Details"} action={!editMode &&<Button onClick={handleEditBtn}>Edit</Button>} />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <List>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Employment"
                                            name="employment_status"
                                            defaultValue={data?.employment_status}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.employment_status}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Employment" secondary={data?.employment_status ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Company Name"
                                            name="company_name"
                                            defaultValue={data?.company_name}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.company_name}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Company Name" secondary={data?.company_name ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (

                                        <TextField
                                            fullWidth
                                            label="Is your employer involved in a high risk industry?"
                                            name="is_high_risk_industry"
                                            defaultValue={data?.is_high_risk_industry}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.is_high_risk_industry}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Is your employer involved in a high risk industry?" secondary={data?.is_high_risk_industry ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Are you a statutory director or own > 5% of the company’s equity?"
                                            name="statutory_director"
                                            defaultValue={data?.statutory_director}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.statutory_director}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Are you a statutory director or own > 5% of the company’s equity?" secondary={data?.statutory_director ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Profession"
                                            name="profession"
                                            defaultValue={data?.profession}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.profession}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Profession" secondary={data?.profession ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Funding Source"
                                            name="funding_source"
                                            defaultValue={data?.funding_source}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.funding_source}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Funding Source" secondary={data?.funding_source ?? "-"} />
                                    )}
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <List>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Public Function"
                                            name="public_function"
                                            defaultValue={data?.public_function}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.public_function}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Public Function" secondary={data?.public_function ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="is_beneficiary"
                                            name="is_beneficiary"
                                            defaultValue={data?.is_beneficiary}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.is_beneficiary}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="is_beneficiary" secondary={data?.is_beneficiary ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="beneficiary_name"
                                            name="beneficiary_name"
                                            defaultValue={data?.beneficiary_name}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.beneficiary_name}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="beneficiary_name" secondary={data?.beneficiary_name ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="US Citizen, US Resident or US Resident for US tax purposes"
                                            name="us_citizen_resident"
                                            defaultValue={data?.us_citizen_resident}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.us_citizen_resident}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="US Citizen, US Resident or US Resident for US tax purposes" secondary={data?.us_citizen_resident ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Tax Identification Number"
                                            name="tax_identification_number"
                                            defaultValue={data?.tax_identification_number}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.tax_identification_number}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Tax Identification Number" secondary={data?.tax_identification_number ?? "-"} />
                                    )}
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
                {editMode &&
                    <CardActions sx={{p:2, justifyContent:'end'}}>
                        <Stack spacing={2} justifyContent={"space-between"} direction={"row"}>
                            <Button variant="contained" color="secondary" size="small" onClick={handleCancelBtn}>Cancel</Button>
                            <Button variant="contained" size="small" onClick={handleSaveBtn}>Save</Button>
                        </Stack>
                    </CardActions>
                }
            </Card>
            <Toastr response={response} />
        </>
    );
};

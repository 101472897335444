import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import { setModal } from "../../../../Common/App";
import {InvoiceForm, fetchInvoice, fetchInvoices, setSelectedInvoice, DeleteInvoiceModal} from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment/moment";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const InvoiceTable = () => {

    const invoices = useSelector((store) => store.invoices);
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchInvoices({dispatch, data:{}});
    },[]);

    const viewItem = React.useCallback(
        (id) => async () => {
            await fetchInvoice({dispatch, id});
            navigate('/invoices/' + id);
        },
        [],
    );

    const editItem = React.useCallback(
        (id) => async () => {
            await fetchInvoice({dispatch, id});
            dispatch(setModal({component: <InvoiceForm/>, state:true}));
        },
        [],
    );

    const deleteItem = React.useCallback(

        (id) => () => {
            dispatch(setModal({component:<DeleteInvoiceModal />, state:true}))
            dispatch(setSelectedInvoice(id));
        },
        [],
    );

    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });


    const columns = React.useMemo(
        () => [
            { field: 'invoice_id', headerName:'Invoice ID', type: 'string', hide: true, filterable: false },
            { field: 'customer', headerName:'Customer', type: 'string', width:160, valueGetter: params => params.row?.customer?.company_name},
            { field: 'invoice_no', headerName:'Invoice No', type: 'string', width:90,
                renderCell: (params) => {
                    return <Link to={`/invoices/${params.id}`}>{params.value}</Link>;
                }
            },
            { field: 'purchase_order_no', headerName:'Purchase Order No', type: 'string', width:140 },
            { field: 'purchase_order_date', headerName:'Purchase Order Date', type: 'dateTime', width:155, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY") },
            { field: 'invoice_date', headerName:'Invoice Date', type: 'dateTime', width:100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")},
            { field: 'currency', headerName:'Currency', type: 'string', width:80 },
            { field: 'invoice_amount', headerName:'Invoice Amount', type: 'string', width:120 },
            { field: 'delivery_shipping_date', headerName:'Delivery Shipping Date', type: 'dateTime', width:170, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY") },
            { field: 'invoice_acceptance_date', headerName:'Invoice Acceptance Date', type: 'dateTime', width:180, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY") },
            { field: 'payment_terms', headerName:'Payment terms', type: 'string', width:110 },
            { field: 'inco_terms', headerName:'Inco Terms', type: 'string', width:90 },
            { field: 'origin', headerName:'Country of Origin', type: 'string', width:130 },
            { field: 'destination', headerName:'Country of Destination', type: 'string', width:160 },
            { field: 'invoice_due_date', headerName:'Invoice Due Date', type: 'dateTime', width:150, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY") },
            { field: 'assignedTo', headerName:'Assigned to', type: 'string', width:120, hide: auth?.user?.role_type !== 'ADMIN' },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', width:120,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")
            },
            { field: 'status', headerName:'Status', type: 'string', width: 120,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'SUBMITTED':'info',
                        'PENDING':'warning',
                        'PROCESSING':'primary',
                        'VERIFIED':'success',
                        'APPROVED':'success',
                        'RETURNED':'warning',
                        'REJECTED':'error',
                        'DELETED':'error'
                    }
                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:130,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={ deleteItem(params.id) }
                    />,
                    ['DRAFT','RETURNED'].includes(params.row.status) && auth.user.role_type === 'CLIENT' ||
                    ['SUBMITTED','PROCESSING','PENDING'].includes(params.row.status) && auth.user.role_type !== 'CLIENT'
                        ?
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            onClick={ editItem(params.id) }

                        />
                    :
                        <GridActionsCellItem
                            icon={<FindInPageIcon />}
                            label="View"
                            onClick={ viewItem(params.id) }

                        />
                    ,
                ],
            },
        ],
        [deleteItem, editItem],
    );

    return (
        <>
            <DataGridPro
                autoHeight={true}
                getRowId={(row) => row.invoice_id}
                pageSize={filters.pageSize}
                pagination
                pageSizeOptions={[5, 10, 20]}
                onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                columns={columns}
                rows={invoices?.list || []}
                sx={{ border:'none'}}
                initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                    sorting: {
                        sortModel: [{ field: 'created_at', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            invoice_id: false,
                        },
                    },
                    pinnedColumns: { left: ['invoice_no'], right: ['status','actions'] }
                }}
            />
        </>
    );
}

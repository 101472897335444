import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";


/****************************************
 SET SELECTED CHEQUE
 ****************************************/
export const setSelectedCheque = (payload) => ({
    type: CONST.SET_SELECTED_CHEQUE,
    payload: payload
})

/****************************************
 FETCH CHEQUE
 ****************************************/
export const fetchChequesSuccess = (payload) => ({
    type: CONST.FETCH_CHEQUES_SUCCESS,
    payload: payload
});

export const fetchChequesFailed = (payload) => ({
    type: CONST.FETCH_CHEQUES_FAILED,
    payload: payload
});

export const fetchCheques = async ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/cheque`),
        [fetchChequesSuccess],
        [fetchChequesFailed]
    );
};

/****************************************
 ADD Business CHEQUE
 ****************************************/
export const addChequeSuccess = (payload) => ({
    type: CONST.ADD_CHEQUE_SUCCESS,
    payload: payload
});

export const addChequeFailed = (payload) => ({
    type: CONST.ADD_CHEQUE_FAILED,
    payload: payload
});

export const addCheque = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/cheque', data),
        [addChequeSuccess],
        [addChequeFailed]
    );
};

/****************************************
 Update Business CHEQUE
 ****************************************/
export const updateChequeSuccess = (payload) => ({
    type: CONST.UPDATE_CHEQUE_SUCCESS,
    payload: payload
});

export const updateChequeFailed = (payload) => ({
    type: CONST.UPDATE_CHEQUE_FAILED,
    payload: payload
});

export const updateCheque = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/cheque/' + data['cheque_id'], data),
        [updateChequeSuccess],
        [updateChequeFailed]
    );
};

/****************************************
 DELETE Business CHEQUE
 ****************************************/
export const deleteChequeSuccess = (payload) => ({
    type: CONST.DELETE_CHEQUE_SUCCESS,
    payload: payload
});

export const deleteChequeFailed = (payload) => ({
    type: CONST.DELETE_CHEQUE_FAILED,
    payload: payload
});

export const deleteCheque = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete('corporate/cheque/' + id),
        [deleteChequeSuccess],
        [deleteChequeFailed]
    );
};

import * as React from 'react';
import {Link} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    MenuItem as MenuItemUI,
    Popover as PopoverUI
} from "@mui/material";
import { doLogout } from "../../Modules/Common/Auth";
import {useDispatch, useSelector} from "react-redux";
import LockIcon from '@mui/icons-material/Lock';
import { isBrowser } from 'react-device-detect';
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { setModal } from "../../Modules/Common/App";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import PeopleIcon from "@mui/icons-material/People";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import {styled, useTheme} from "@mui/material/styles";

export const InvestorAccountMenu = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const auth = useSelector((store)=> store.auth);

    //Account Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        if(isBrowser){
            doLogout(dispatch)
        }else{
            setAnchorEl(event.currentTarget);
        }

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const Popover = styled(PopoverUI)({
        '& .MuiPaper-root':{
            boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.2)',
            [theme.breakpoints.down('md')]: {
                minWidth: '100%', // Minimum width for desktop view
                minHeight:'100%',
                left:'0!important',
                top:'0!important',
                height:'100%',
            },
        }
    });

    const MenuItem = styled(MenuItemUI)({
        '& .MuiMenuItem-root':{
            fontSize:'1.5rem',
            color:'#444',
            borderBottom: '1px solid #efefef',
            minHeight: '65px',
            background: '#f8f8f8',
        }
    });

    const PopoverCard = styled(Card)({
        width: '100%',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root':{
            flex: '1',
            overflowY:'auto',
            maxHeight:'300px',
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'inherit',
            },
            '& .MuiListItemAvatar-root':{
                minWidth: 'auto',
                marginRight: '15px',
                '& svg':{
                    width:'0.8rem'
                }
            },
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 300,
        },
    });

    return (
        <>
                { isBrowser &&
                    <Button
                        size="large"
                        variant={"accountButton"}
                        color={auth.user.role_type === 'CLIENT' ? 'primary' : 'default'}
                        onClick={handleClick}
                        sx={{
                            display: {  md: 'flex' },
                            alignItems: 'center',
                            borderRadius: '4px',
                            px: 2,
                        }}
                    >
                        <Typography color="primary" component="div" sx={{ marginRight: 1 }}>
                            {auth.user.first_name}
                        </Typography>
                        <LockIcon sx={{ fontSize: '1.7rem'}} />
                    </Button>
                }
                { !isBrowser && auth.user.role_type === 'CLIENT' &&
                    <Button
                        size="large"
                        variant={"accountButton"}
                        color={auth.user.role_type === 'CLIENT' ? 'primary' : 'default'}
                        onClick={handleClick}
                        sx={{border:'none', p:1}}
                    >
                        <MenuIcon sx={{ fontSize: '1.7rem'}} />
                    </Button>
                }
                { !isBrowser && auth.user.role_type !== 'CLIENT' &&
                    <Button
                        size="small"
                        variant={"accountButton"}
                        color={auth.user.role_type === 'CLIENT' ? 'primary' : 'default'}
                        onClick={() => doLogout(dispatch)}
                        sx={{
                            display: {  md: 'flex' },
                            alignItems: 'center',
                            border:'none',
                            minWidth:30,
                            p: 1,
                        }}
                    >
                        <LockIcon sx={{ fontSize: '1.3rem'}} />
                    </Button>
                }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PopoverCard>
                    <CardHeader title={"Menu"} titleTypographyProps={{fontWeight:'bold', fontSize:'1rem'}}
                                action={<IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                    size={'small'}
                                    sx={{ position: 'absolute', top: '8px', right: '15px' }}
                                >
                                    <CloseIcon fontSize={'0.5rem'} />
                                </IconButton>}>
                    </CardHeader>
                    <CardContent sx={{p:0, pb:'0!important'}}>
                        <Link style={{ textDecoration:'none'}} to={'/dashboard'} >
                            <MenuItem>Dashboard </MenuItem>
                        </Link>
                        <Link style={{ textDecoration:'none'}} to={'/customers'} >
                            <MenuItem>Customers </MenuItem>
                        </Link>
                        <Link style={{ textDecoration:'none'}} to={'/business-finance'} >
                            <MenuItem>Requests </MenuItem>
                        </Link>
                        <MenuItem onClick={() => doLogout(dispatch)} > Logout </MenuItem>
                    </CardContent>
                </PopoverCard>

            </Popover>
        </>
    );
}


export const MenuItems = () =>{

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setModal({component: <MenuItems />, open:false, title:''}))
    }
    return (
        <>
            <DialogTitle>Menu</DialogTitle>
            <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                <List>
                    <ListItem button component={Link} to={'/dashboard'} >
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button component={Link} to={'/customers'} >
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Customers" />
                    </ListItem>
                    <ListItem button component={Link} to={'/business-finance'} >
                        <ListItemIcon>
                            <RequestPageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Requests" />
                    </ListItem>
                </List>
            </DialogContent>
        </>
    )
}
import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import {
    Stack, Typography, Tooltip, Box
} from "@mui/material";
import { useDispatch } from "react-redux";
import { currencyFormatter } from "../../../../../Setup/Config";
import moment from "moment/moment";
import {fetchBusinessOverviewByBusiness} from "../../Redux/Action";
import {useNavigate} from "react-router-dom";
import {styled, useTheme} from "@mui/material/styles";
import {
    DataGridPro,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid-pro";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {fetchBusiness} from "../../../../Corporate/Business";

export const BusinessExpandDetailsCard = ({ data, setPanelHeight }) => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const panelRef = useRef(null);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const businessIds = data['related'].map(item => item.related_business_id);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        fetchData();
    }, [data['business_id']]);

    const fetchData = async () => {
        setIsLoading(true);
        await fetchBusinessOverviewByBusiness({ dispatch, data: { business_id:businessIds }}).then((res) => {
            if (res.success) {
                setItems(res.data.data);
                setLoaded(true);
            }
            setIsLoading(false);
        });
    };

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/business/' + id);
        },
        [navigate],
    );

    const viewLoan = React.useCallback(
        (id) => async () => {
            navigate('/business-finance/' + id);
        },
        [navigate],
    );

    const viewDashboard = React.useCallback(
        (id) => async () => {
            await fetchBusiness({dispatch, id});
            navigate(`/dashboard/${id}`)
        },
        [],
    );

    const getDetailPanelContent = React.useCallback(
        ({ row }) => (row.related && row.related.length > 0) ? <BusinessExpandDetailsCard data={row} setPanelHeight={setPanelHeight} /> : null,
        [setPanelHeight],
    );

    useLayoutEffect(() => {
        if (panelRef.current && loaded) {
            setPanelHeight((items.data).length * 50);
        }
    }, [loaded]);

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} />
                ),
            },
            {
                field: 'rowNumber',
                headerName: 'S No',
                align:'center',
                headerAlign:'center',
                width: 70,
                renderCell: (params) => {
                    const apiRef = params.api;
                    const page = apiRef.state.pagination.paginationModel.page;
                    const pageSize = apiRef.state.pagination.paginationModel.pageSize;
                    return page * pageSize + (params.api.getAllRowIds().indexOf(params.id) + 1);
                },
            },
            { field: 'name', headerName:'Business Name', type: 'string', flex: 1, minWidth: 350,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.id)}>{params.value}</Typography>
                }
            },
            { field:'registration_date', headerName:'Registration', align:'center', headerAlign:'center', type: 'string', minWidth: 100, flex:1,
                valueFormatter: ({ value }) =>  value && moment(value).format("DD/MM/YYYY")
            },
            { field:'latest_loan_date', headerName:'Last Funding', align:'center', headerAlign:'center', type: 'string', minWidth: 100, flex:1,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewLoan(params.row.loan_id)}>{params.value && moment(params.value).format("DD/MM/YYYY")}</Typography>
                }
            },
            { field:'total_funding', headerName:'Total Funding', type: 'number', minWidth: 120, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.total_funding ?? 0);
                },
            },
            { field:'current_funding', headerName:'Current Contracts', type: 'number', minWidth: 150, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.current_funding ?? 0);
                },
            },
            { field:'outstanding', headerName:'Outstanding', type: 'number', minWidth: 100, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.outstanding ?? 0);
                },
            },
            { field:'earning', headerName:'Earning', type: 'number', minWidth: 100, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.earning ?? 0);
                },
            },
            { field:'overdue', headerName:'Overdue', type: 'number', minWidth: 120, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.overdue ?? 0);
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width:70,
                getActions: (params) => {
                    if(params.row.status?.name === 'APPROVED'){
                        return [
                            <Tooltip title={"Show Details"}>
                                <GridActionsCellItem
                                    icon={<PageviewIcon sx={{zIndex:-1}} />}
                                    label={"View"}
                                    color={"primary"}
                                    onClick={ viewDashboard(params.id) }
                                />
                            </Tooltip>
                        ];
                    }
                    return [];
                },
            },

        ],
        [currencyFormatter, theme.text.link, viewItem, viewLoan],
    );

    const DataGridProUI = styled(DataGridPro)({
        '& .MuiDataGrid-columnHeaders': {
            display: 'none',
        },
        '& .MuiDataGrid-footerContainer': {
            display: 'none',
        },
    });

    return (
        <Stack ref={panelRef}
               sx={{ minHeight: '50px', boxSizing: 'border-box' }}
               direction="column"
        >
            <DataGridProUI
                autoHeight
                getRowId={(row) => row.business_id}
                columns={columns}
                rows={items?.data || []}
                sx={{ border:'none', height:'100%'}}
                getDetailPanelContent={getDetailPanelContent}
                loading={isLoading}
                initialState={{
                    pinnedColumns: { right: ['actions'] },
                    sorting: {
                        sortModel: [{ field: 'overdue', sort: 'desc' }],
                    },
                }}
                hideFooter
            />
        </Stack>
    );
};

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={true}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >

        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

import React, {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    Input,
    Button,
    TextField, Box, CardActions
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateSetting} from "../../Redux/Action";

export const GeneralSettingsTab = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.settings.list);
    const [data, setData] = useState({});
    const [image, setImage] = useState(null);
    const [response, setResponse] = React.useState({});

    useEffect(() => {
        const desiredParameters = ['review_days','app_name'];
        const newData = {};

        items.forEach(item => {
            if (desiredParameters.includes(item.parameter_name)) {
                newData[item.parameter_name] = item.parameter_value;
            }
        });

        setData(newData);
    }, [items]);

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    const handleChange = (event) => {

        const { name, value } = event.target;
        const updatedValue = Array.isArray(value) ? value.join(',') : value;

        setData({
            ...data,
            [name]: updatedValue
        });
    }

    const handleSubmit = async () => {

        const formattedData = Object.keys(data).map((key) => {
            return {parameter_name: key, parameter_value: data[key]}
        });

        await updateSetting({dispatch, data:{...formattedData}}).then((res) => {
            setResponse(res)
        });
    }

    return (
        <>
            <Card>
                <CardHeader title={"General Settings"} />
                <CardContent sx={{pb:'1rem!important'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Identity</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Adjust your notification preferences here.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="end">
                                        {image && <img src={image} alt="Preview" style={{ width: 200, height: 200, marginRight: 16 }} />}
                                        {!image && <img src="https://placehold.co/100x100@2x.png?text=Your+Business+Logo" alt="Default" style={{ width: 100, height: 100, marginRight: 16 }} />}
                                        <Input
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button variant="contained" component="span">
                                                Upload Image
                                            </Button>
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data?.app_name ?? ""}
                                        label="App Name"
                                        variant="outlined"
                                        name={"app_name"}
                                        defaultValue="App Name"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Business</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    No of days to review the business.
                                </Typography>
                            </>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        value={data?.review_days ?? ""}
                                        label="Review Days"
                                        variant="outlined"
                                        defaultValue="30"
                                        name={"review_days"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant={"contained"} onClick={()=> handleSubmit()}>Save</Button>
                </CardActions>
            </Card>
        </>
    )
}
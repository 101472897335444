import { useEffect, useState } from 'react';
import {currencyFormatter} from "../Setup/Config";

const useCounterEffect = (initialValue, finalValue, maximumInterval = 1000) => {
    const [displayedValue, setDisplayedValue] = useState(initialValue);

    // useEffect(() => {
    //     const difference = Math.abs(finalValue - initialValue);
    //
    //     // Adjust the steps based on the magnitude of the difference
    //     const steps = difference > 1000 ? 10000 : Math.max(1, difference);
    //
    //     const dynamicInterval = Math.min(maximumInterval, difference / steps);
    //
    //     // Decrease the interval duration for faster speed
    //     const fasterMultiplier = 0.2; // Adjust this multiplier to increase or decrease speed
    //     const intervalId = setInterval(() => {
    //         if (displayedValue < finalValue) {
    //             setDisplayedValue((prev) => prev + 1);
    //         } else {
    //             clearInterval(intervalId);
    //         }
    //     }, dynamicInterval * fasterMultiplier);
    //
    //     return () => clearInterval(intervalId);
    // }, [finalValue, displayedValue, maximumInterval]);

    return  currencyFormatter.format(!isNaN(finalValue) && isFinite(finalValue) ? finalValue : 0);
};

export default useCounterEffect;

const CONST = {
    'CLEAR_PROCEED_STATE':'LOAN/CLEAR_PROCEED_STATE',
    'CLEAR_ERROR_STATE':'LOAN/CLEAR_ERROR_STATE',

    'SET_CONFIG':'LOAN/SET_CONFIG',
    'SET_SELECTED_LOAN':'LOAN/SET_SELECTED_LOAN',
    'SET_SELECTED_LOAN_SUCCESS':'LOAN/SET_SELECTED_LOAN_SUCCESS',
    'FETCH_LOAN_SUCCESS':'LOAN/FETCH_LOAN_SUCCESS',
    'FETCH_LOAN_FAILED':'LOAN/FETCH_LOAN_FAILED',
    'FETCH_LOANS_SUCCESS':'LOAN/FETCH_LOANS_SUCCESS',
    'FETCH_LOANS_FAILED':'LOAN/FETCH_LOANS_FAILED',
    'ADD_LOAN_SUCCESS':'LOAN/ADD_LOAN_SUCCESS',
    'ADD_LOAN_FAILED':'LOAN/ADD_LOAN_FAILED',
    'UPDATE_LOAN_SUCCESS':'LOAN/UPDATE_LOAN_SUCCESS',
    'UPDATE_LOAN_FAILED':'LOAN/UPDATE_LOAN_FAILED',

    'VERIFY_LOAN_SUCCESS':'LOAN/VERIFY_LOAN_SUCCESS',
    'VERIFY_LOAN_FAILED':'LOAN/VERIFY_LOAN_FAILED',
    'DOCUMENT_LOAN_SUCCESS':'LOAN/DOCUMENT_LOAN_SUCCESS',
    'DOCUMENT_LOAN_FAILED':'LOAN/DOCUMENT_LOAN_FAILED',
    'DISBURSEMENT_SUCCESS':'LOAN/DISBURSEMENT_SUCCESS',
    'DISBURSEMENT_FAILED':'LOAN/DISBURSEMENT_FAILED',
    'RETURN_LOAN_SUCCESS':'LOAN/RETURN_LOAN_SUCCESS',
    'RETURN_LOAN_FAILED':'LOAN/RETURN_LOAN_FAILED',
    'DELETE_LOAN_SUCCESS':'LOAN/DELETE_LOAN_SUCCESS',
    'DELETE_LOAN_FAILED':'LOAN/DELETE_LOAN_FAILED',

    'FETCH_LOAN_SCHEDULES_SUCCESS':'LOAN/FETCH_LOAN_SCHEDULES_SUCCESS',
    'FETCH_LOAN_SCHEDULES_FAILED':'LOAN/FETCH_LOAN_SCHEDULES_FAILED',
    'UPDATE_LOAN_SCHEDULE_SUCCESS':'LOAN/UPDATE_LOAN_SCHEDULE_SUCCESS',
    'UPDATE_LOAN_SCHEDULE_FAILED':'LOAN/UPDATE_LOAN_SCHEDULE_FAILED',

    'RESTRUCTURE_LOAN_SUCCESS':'LOAN/RESTRUCTURE_LOAN_SUCCESS',
    'RESTRUCTURE_LOAN_FAILED':'LOAN/RESTRUCTURE_LOAN_FAILED',

    'FETCH_LOAN_STATEMENT_SUCCESS':'LOAN/FETCH_LOAN_STATEMENT_SUCCESS',
    'FETCH_LOAN_STATEMENT_FAILED':'LOAN/FETCH_LOAN_STATEMENT_FAILED',
    'EARLY_SETTLEMENT_SUCCESS':'LOAN/EARLY_SETTLEMENT_SUCCESS',
    'EARLY_SETTLEMENT_FAILED':'LOAN/EARLY_SETTLEMENT_FAILED',

}

export default CONST;
import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Grid, Stack, TextField, Divider, ListItemText as ListItemTextUI, Button, DialogActions, Checkbox, DialogTitle,DialogContent } from "@mui/material";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import Toastr from "../../../../../Components/Elements/Toastr";
import {useEffect, useState} from "react";
import {verifyService} from "../../index";
import {currencyFormatter} from "../../../../../Setup/Config";
import { setModal } from "../../../../Common/App";
import {styled} from "@mui/material/styles";

export const VerifyServiceRequestModal = () => {

    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const permissions = useSelector((store) => store.app.permissions);
    const loans = useSelector(state => state.loans);
    const service = useSelector(state => state.services?.selected);
    const business = useSelector(state => state.business);
    const [showFees, setShowFees] = useState(false);
    const business_config = business.selected?.config;
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    useEffect(() => {

        setDisabled(business.selected.status !== 'APPROVED');
        setData(data => ({
            ...data,
            'start_date': moment().set({'hour':0, 'minute':0, 'second':0}).format('YYYY-MM-DD'),
            'service_id': service.service_id,
            'amount': service.amount ?? 0,
            'service_type': service.service_type,
            'profit_rate_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'profit_rate')?.config_value : 0,
            'processing_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'processing_fee')?.config_value : 0,
            'document_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'document_fee')?.config_value : 0,
            'due_diligence_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'due_diligence_fee')?.config_value : 0,
            'account_setup_fee_config': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'account_setup_fee')?.config_value : 0,
            'no_of_repayments': business_config?.length > 0 && service.service_type !== 'INVOICE_DISCOUNTING' ? business_config.find((config) => config.config_type === 'no_of_repayments')?.config_value : 1,
            'max_allowed_tenor': business_config?.length > 0 ? business_config.find((config) => config.config_type === 'max_allowed_tenor')?.config_value : 0,
            'status': service.status
        }));

    },[]);

    const handleReviewBtn = () => {

        setShowFees(data?.amount !== '' && data?.max_allowed_tenor !== '' && data?.profit_rate !== '' && data?.no_of_repayments !== '' && data?.profit_rate !== '');

        const vat = 0.05;
        let profit_fee = (parseFloat(data?.amount) * (parseFloat(data?.profit_rate_config) / 100) * (parseFloat(data?.max_allowed_tenor)));
        profit_fee += profit_fee * vat;

        let processing_fee = (parseFloat(data?.amount) * parseFloat(data?.processing_fee_config) / 100);
        processing_fee += processing_fee * vat;

        let document_fee = parseFloat(data?.document_fee_config);
        document_fee += document_fee * vat;

        let due_diligence_fee = parseFloat(data?.due_diligence_fee_config);
        due_diligence_fee += due_diligence_fee * vat;

        let account_setup_fee = parseFloat(data?.account_setup_fee_config);
        account_setup_fee += account_setup_fee * vat;

        setData(data => ({
            ...data,
            'profit_fee': profit_fee,
            'processing_fee': processing_fee,
            'document_fee': document_fee,
            'due_diligence_fee': due_diligence_fee,
            'account_setup_fee': account_setup_fee,
        }));

    }

    const handleChange = (e) => {
        setShowFees(false);
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {

        setDisabled(true);

        let payload = {
            service_id: data['service_id'],
            amount: data['amount'],
            remarks: data['remarks'],
        };

        await verifyService({ dispatch, data: { ...payload } }).then(async (res) => {
            setResponse(res);
            if (res['success']) {
                dispatch(setModal({ component: null, open: false }));
            }
        });
    }

    const ActionButton = () => {

        let action;
        let label;

        if (permissions.includes('verify-service-request') && showFees && data?.status !== 'VERIFIED') {
            action = () => handleSubmit();
            label = "Save";
        } else {
            action = handleReviewBtn;
            label = "Review";
        }

        return ( <Button disabled={disabled} variant="contained" color="info" onClick={action}> {label} </Button> );
    };

    //TODO: fix the style later
    const ListItemText = styled(ListItemTextUI)({
        background:'#f9f9f9',
        maxWidth: 360,
        padding:'0.8rem',
        border:'1px solid rgba(0,0,0,0.2)',
        margin:0,
        width:'100%',
        '& .MuiListItemText-primary': {
            fontWeight: 300,
            fontSize: 12,
            color: '#888888'
        },
        '& .MuiListItemText-secondary': {
            fontSize: 16,
            fontWeight: 600,
            color: '#333333'
        }
    })

    return (
        <>
            <DialogTitle>Verify Request Details</DialogTitle>
            <DialogContent>
                { (data !== undefined && Object.keys(data).length > 0) &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Request Amount"
                            name="amount"
                            defaultValue={data?.amount}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.amount}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiDatepicker setDisabled={setDisabled} disabled={true} label={"Start Date"} name="start_date" data={data} state={setData} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="Tenor (months)"
                            name="max_allowed_tenor"
                            defaultValue={data?.max_allowed_tenor}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.max_allowed_tenor}
                            readOnly
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="Forfaiting/ Discounting Fee % (profit %)"
                            name="profit_rate_config"
                            defaultValue={data?.profit_rate_config}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.profit_rate}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="Processing Fee"
                            name="processing_fee_config"
                            defaultValue={data?.processing_fee_config}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.processing_fee_config}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="No of Repayment"
                            name="no_of_repayments"
                            defaultValue={data?.no_of_repayments}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.no_of_repayments}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="Documentation Fee"
                            name="document_fee_config"
                            defaultValue={data?.document_fee_config}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.document_fee}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="Due Diligence Fee"
                            name="due_diligence_fee_config"
                            defaultValue={data?.due_diligence_fee_config}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.due_diligence_fee}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={true}
                            label="Account Setup Fee"
                            name="account_setup_fee_config"
                            defaultValue={data?.account_setup_fee_config}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.account_setup_fee}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Notes"
                            name="remarks"
                            multiline
                            rows={3}
                            defaultValue={data?.remarks}
                            inputlabelprops={{ shrink: true }}
                            helperText={loans?.error?.remarks}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    {showFees &&
                        <>
                            <Grid item xs={12}>
                                <Divider>Fees</Divider>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ListItemText
                                    primary={"Forfaiting / Discounting Fee"}
                                    secondary={currencyFormatter.format(data?.profit_fee)}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ListItemText
                                    primary={"Processing Fees"}
                                    secondary={currencyFormatter.format(data?.processing_fee)}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ListItemText
                                    primary={"Documentation Fee"}
                                    secondary={currencyFormatter.format(data?.document_fee)}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ListItemText
                                    primary={"Due Diligence Fee"}
                                    secondary={currencyFormatter.format(data?.due_diligence_fee)}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <ListItemText
                                    primary={"Account Setup Fee"}
                                    secondary={currencyFormatter.format(data?.account_setup_fee)}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
                }
            </DialogContent>
            <DialogActions sx={{p:2, justifyContent:"space-between"}}>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    target={"_blank"}
                    component={Link}
                    to={
                        data['service_type'] !== 'INVOICE_DISCOUNTING'
                            ? `/business/${business.selected.business_id}`
                            : `/invoices/${service?.invoice_id}`
                    }
                >
                    {data['service_type'] !== 'INVOICE_DISCOUNTING' ? 'Show Business' : 'Show Invoice'}
                </Button>
                <Stack direction={"row"} spacing={2}>
                    <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                    <ActionButton />
                </Stack>
            </DialogActions>
            <Toastr response={response} />
        </>
    );
}
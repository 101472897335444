const CONST = {
    'CLEAR_DATA':'PERMISSION/CLEAR_DATA',
    'SET_CONFIG':'PERMISSION/SET_CONFIG',
    'FETCH_PERMISSION_SUCCESS':'PERMISSION/FETCH_PERMISSION_SUCCESS',
    'FETCH_PERMISSION_FAILED':'PERMISSION/FETCH_PERMISSION_FAILED',
    'FETCH_PERMISSIONS_SUCCESS':'PERMISSION/FETCH_PERMISSIONS_SUCCESS',
    'FETCH_PERMISSIONS_FAILED':'PERMISSION/FETCH_PERMISSIONS_FAILED',
    'SET_SELECTED_PERMISSION':'PERMISSION/SET_SELECTED_PERMISSION',
    'ADD_PERMISSION_SUCCESS':'PERMISSION/ADD_PERMISSION_SUCCESS',
    'ADD_PERMISSION_FAILED':'PERMISSION/ADD_PERMISSION_FAILED',
    'UPDATE_PERMISSION_SUCCESS':'PERMISSION/UPDATE_PERMISSION_SUCCESS',
    'UPDATE_PERMISSION_FAILED':'PERMISSION/UPDATE_PERMISSION_FAILED',
    'DELETE_PERMISSION_SUCCESS':'PERMISSION/DELETE_PERMISSION_SUCCESS',
    'DELETE_PERMISSION_FAILED':'PERMISSION/DELETE_PERMISSION_SUCCESS',
}

export default CONST;
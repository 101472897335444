import CONST from "./Constant";

const INITIAL_STATE = {
    config:{},
    mode: null,
    activeMenu:'dashboard',
    broadcast: "",
    modal: {open: false, component: null, title: '', size: 'md', fullScreen: false, maxWidth: 'md', maxHeight: 'md',},
    leftDrawer: {open: false, title: '', width:240,},
    rightDrawer: {open: false, component: null, title: '', width: 500,},
    blockUI:{open:false, message:''},
    appBarHeight:'',
    notifications:[],
    permissions:[],
    services:[],
    banks:'',
    error: [],
    message: [],
};

const Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONST.INITIALIZE:
            return {
                ...state,
                mode: action.payload,
                activeMenu: 'dashboard',
            };
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.SET_MODE:
            return {
                ...state,
                mode: action.payload,
            };
        case CONST.FETCH_CONFIG_SUCCESS:
            return {
                ...state,
                config: action.payload.data?.config,
                mode: action.payload.data?.mode,
                permissions: action.payload.data?.permissions,
                notifications: action.payload.data?.notifications,
                services: action.payload.data?.services,
                error: [],
                message: ["Config loaded successfully"],
            };
        case CONST.FETCH_CONFIG_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code: action.payload.status,
            };
        case CONST.SET_APPBAR_HEIGHT:
            return {
                ...state,
                appBarHeight: action.payload,
                message: ["Appbar height set successfully"],
            }
        case CONST.SET_ACTIVE_MENU:
            return {
                ...state,
                leftDrawer: { ...state.leftDrawer, open: false},
                activeMenu: action.payload
            }
        case CONST.SET_MODAL:
            return {
                ...state,
                modal: { ...state.modal, ...action.payload }
            }
        case CONST.SET_LEFT_DRAWER:
            return {
                ...state,
                leftDrawer: { ...state.leftDrawer, ...action.payload }
            }
        case CONST.SET_RIGHT_DRAWER:
            return {
                ...state,
                rightDrawer: { ...state.rightDrawer, ...action.payload }
            }
        case CONST.BLOCKUI_STATE:
            return {
                ...state,
                blockUI: { ...state.blockUI, ...action.payload }
            }
        case CONST.FETCH_LOVS_SUCCESS:
            return {
                ...state,
                lov: action.payload?.data
            }
        case CONST.FETCH_BANKS_SUCCESS:
            return {
                ...state,
                banks: action.payload?.data
            }
        case CONST.HAS_BUSINESS_SUCCESS:
            return {
                ...state,
                hasBusiness: action.payload?.data ?? false,
            }
        default:
            return state;
    }
};

export default Reducer;

import * as React from 'react';
import { Grid,Typography } from "@mui/material";
import {fetchListing, ListingRegistrationPanel, ListingStats, CompanyInfoPanel, FinancialsPanel, TimerPanel} from '../index'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useParams} from "react-router-dom";

export const ListingView = () => {

    const dispatch = useDispatch();
    const {listing_id} = useParams();
    const listing = useSelector(store => store.listings.selected);

    useEffect(() => {
        fetchData();
    },[listing_id]);

    const fetchData = async() => {
        await fetchListing({dispatch, id:listing_id})
    }

    return (
        <>
            { (listing !== undefined &&  Object.keys(listing).length > 0) &&
            <Grid>
                <Grid container spacing={2}>
                    <Grid item spacing={2} container xs={12}>
                        <Grid item xs={12}>
                            <Typography variant={"h3"}>{listing.business.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item spacing={2} container xs={12}>
                        <Grid item xs={12}>
                            <ListingStats />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={7}>
                            <TimerPanel />
                        </Grid>
                        <Grid item xs={5}>
                            <ListingRegistrationPanel />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={12}>
                            <CompanyInfoPanel />
                        </Grid>
                    </Grid>
                    <Grid item spacing={2} container xs={12}>
                        <Grid item xs={12}>
                            <FinancialsPanel business_id={listing.business_id} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }
        </>
    );
}
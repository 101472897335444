import React from "react";
import {
    CardContent,
    CardHeader, Chip,
    Grid
} from "@mui/material";
import { useSelector} from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import AuditDetailTemplate from "../../../Business/Components/AuditDetailTemplate";
import {Panel, PanelList} from "../../../../../Components";

export const InvoicePropertiesPanel = () => {

    const invoice = useSelector(state => state.invoices.selected);

    return(
        <>
            { invoice !== undefined &&
            <Panel>
                <CardHeader title={"Invoice Details"} titleTypographyProps={{variant:'subtitle1' }}  />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <PanelList>
                                <ListItem>
                                    <ListItemText primary={"Status"} />
                                    <ListItemIcon><Chip color={"primary"} label={invoice?.status} size={"small"} /></ListItemIcon>
                                </ListItem>
                                {(invoice?.created_by !== null && invoice?.created_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Created"} secondary={<AuditDetailTemplate type={"created"} data={invoice} />}/>
                                    </ListItem>
                                }
                                {(invoice?.updated_by !== null && invoice?.updated_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Last Updated"} secondary={<AuditDetailTemplate type={"updated"} data={invoice} />}/>
                                    </ListItem>
                                }
                                {(invoice?.verified_by !== null && invoice?.verified_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Verified"} secondary={<AuditDetailTemplate type={"verified"} data={invoice} />}/>
                                    </ListItem>
                                }
                                {(invoice?.approved_by !== null && invoice?.approved_by !== undefined) &&
                                    <ListItem>
                                        <ListItemText primary={"Approved"} secondary={<AuditDetailTemplate type={"approved"} data={invoice} />}/>
                                    </ListItem>
                                }
                                {invoice.loan_id &&
                                    <ListItem>
                                        <ListItemText primary={"Loan issued"} secondary={<Link to={ '../../../business-finance/'+invoice.loan_id}>View Loan</Link>}></ListItemText>
                                    </ListItem>
                                }
                            </PanelList>
                        </Grid>
                    </Grid>
                </CardContent>
            </Panel>
            }
        </>
    )
}
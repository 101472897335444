import * as React from 'react';
import {Card as CardUI, CardContent, CardHeader} from "@mui/material";
import {CountDownTimer} from "./CountDownTimer";
import {styled} from "@mui/material/styles";

export const TimerPanel = () =>{

    const Card = styled(CardUI)({
        boxShadow:'0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
        border:'none',
        '& .MuiCardHeader-root':{
            borderBottom:'none',
            '& .MuiCardHeader-content':{
                '& .MuiCardHeader-title':{
                    fontSize:'1.1rem',
                    textAlign:'center'
                },
                '& .MuiCardHeader-subheader p':{
                    fontSize:'.70rem'
                }
            },
        }
    });

    return (
        <>
            <Card>
                <CardHeader title={"Auction will close when the request is 100% funded."} />
                <CardContent>
                    <CountDownTimer />
                </CardContent>
            </Card>
        </>
    )
}


import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    clearData,
    DeleteBusinessModal,
    fetchBusinesses,
    setConfig,
    setSelectedBusiness
} from "../../index";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment";
import {useTheme} from "@mui/material/styles";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {setModal} from "../../../../Common/App";

export default function BusinessTable(props) {

    const dispatch = useDispatch();
    const theme = useTheme();
    const auth = useSelector((store) => store.auth);
    const items = useSelector((store) => store.business);
    const permissions = useSelector((store) => store.app.permissions);
    const filters = useDeepEqualSelector((store) => store.business.config.filters);
    const pagination = useDeepEqualSelector((store) => store.business.config.pagination);
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(clearData());
    },[]);

    useEffect(() => {
        fetchData();
    },[pagination, filters]);

    const fetchData = async () => {

        setIsLoading(true);
        await fetchBusinesses({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/business/' + id);
        },
        [],
    );

    const deleteItem = React.useCallback(

        (id) => () => {
            dispatch(setModal({component:<DeleteBusinessModal />, open:true}));
            dispatch(setSelectedBusiness(id));
        },
        [],
    );

    const actions = React.useCallback(
        (params) => {
            const delete_button = (
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={deleteItem(params.id)}
                />
            );
            const edit_button = (
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={viewItem(params.id)}
                />
            );
            const view_button = (
                <GridActionsCellItem
                    icon={<FindInPageIcon />}
                    label="View"
                    onClick={viewItem(params.id)}
                />
            );

            if (
                (['DRAFT', 'RETURNED'].includes(params.row.status) && auth.user.role_type === 'CLIENT') ||
                (['SUBMITTED', 'PROCESSING', 'PENDING'].includes(params.row.status) && auth.user.role_type !== 'CLIENT')
            ) {
                return [delete_button, edit_button];

            }else if(permissions.includes('su-edit-business')){
                return [edit_button];
            }

            return [view_button];
        },
        [deleteItem, viewItem, auth.user.role_type],
    );


    const columns = React.useMemo(
        () => [
            { field: 'business_id', headerName:'Business ID', type: 'string', filterable: false },
            { field: 'business_no', align:'center', headerAlign:'center', headerName:'No', type: 'string', width:80 },
            { field: 'name', headerName:'Business Name', type: 'string', flex: 1, minWidth: 350,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.id)}>{params.value}</Typography>
                }
            },
            { field: 'owner_name', headerName: 'Owner ID', type: 'string', flex: 1, minWidth: 200,
                valueGetter: (params) => params.row.owner?.email
            },
            { field: 'trade_license_number', headerName:'Trade License Number', type: 'string', flex: 1, minWidth: 200 },
            { field: 'updated_at', headerName:'Last Updated', type: 'dateTime', flex: 1, minWidth: 200, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY H:MM A"),},
            { field: 'status', headerAlign:'left', align:'left', headerName:'Status', type: 'string', flex:1, minWidth: 120,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'SUBMITTED':'info',
                        'PENDING':'warning',
                        'PROCESSING':'primary',
                        'VERIFIED':'success',
                        'APPROVED':'success',
                        'DECLINED':'error',
                        'DEFAULT':'error',
                    }
                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
            { field: 'actions', type: 'actions', headerName:'Action', Width: 100,
                getActions: (params) => actions(params),
            },
        ],
        [props.deleteItem, props.editItem],
    );

    return (
        <div style={{ flex: '1 1 100%', width: '100%', borderTop:'1px solid #ddd' }}>
            <DataGridPro
                getRowId={(row) => row.business_id}
                columns={columns}
                autoHeight={true}
                pagination
                paginationMode="server"
                paginationModel={pagination}
                rowCount={items?.config?.rowCount ?? 0}
                pageSizeOptions={[5, 10, 20, 100]}
                loading={isLoading}
                onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                rows={items.list || []}
                sx={{ border:'none', height:'100%'}}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'created_at', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            business_id: false,
                        },
                    },
                    pinnedColumns:{ right: ['actions'] },
                    pagination: { paginationModel: { ...pagination } },
                }}
            />
        </div>
    );
}

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

/****************************************
 SET SELECTED CREDIT CARD
 ****************************************/
export const setSelectedCreditCard = (payload) => ({
    type: CONST.SET_SELECTED_CREDIT_CARD,
    payload: payload
});

/****************************************
 FETCH CREDIT_CARD
 ****************************************/
export const fetchCreditCardsSuccess = (payload) => ({
    type: CONST.FETCH_CREDIT_CARDS_SUCCESS,
    payload: payload
});

export const fetchCreditCardsFailed = (payload) => ({
    type: CONST.FETCH_CREDIT_CARDS_FAILED,
    payload: payload
});

export const fetchCreditCards = async ({dispatch, business_id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/creditCard`),
        [fetchCreditCardsSuccess],
        [fetchCreditCardsFailed]
    );
};

/****************************************
 ADD Business CREDIT CARD
 ****************************************/
export const addCreditCardSuccess = (payload) => ({
    type: CONST.ADD_CREDIT_CARD_SUCCESS,
    payload: payload
});

export const addCreditCardFailed = (payload) => ({
    type: CONST.ADD_CREDIT_CARD_FAILED,
    payload: payload
});

export const addCreditCard = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/creditCard', data),
        [addCreditCardSuccess],
        [addCreditCardFailed]
    );
};

/****************************************
 Update Business CREDIT CARD
 ****************************************/
export const updateCreditCardSuccess = (payload) => ({
    type: CONST.UPDATE_CREDIT_CARD_SUCCESS,
    payload: payload
});

export const updateCreditCardFailed = (payload) => ({
    type: CONST.UPDATE_CREDIT_CARD_FAILED,
    payload: payload
});

export const updateCreditCard = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/creditCard/${data.card_id}`, data),
        [updateCreditCardSuccess],
        [updateCreditCardFailed]
    );
};

/****************************************
 DELETE Business CREDIT CARD
 ****************************************/
export const deleteCreditCardSuccess = (payload) => ({
    type: CONST.DELETE_CREDIT_CARD_SUCCESS,
    payload: payload
});

export const deleteCreditCardFailed = (payload) => ({
    type: CONST.DELETE_CREDIT_CARD_FAILED,
    payload: payload
});

export const deleteCreditCard = async ({dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/creditCard/${id}`),
        [deleteCreditCardSuccess],
        [deleteCreditCardFailed]
    );
};

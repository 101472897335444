import React, {useEffect} from 'react';
import {CardContent, CardHeader, List as ListUI, ListItem, ListItemText as ListItemTextUI} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Panel} from "../../../../../Components";
import {useDispatch, useSelector} from "react-redux";
import {fetchProposalStats} from "../../Redux/Action";
import {setModal} from "../../../../Common/App";
import {BusinessAtProposalModal} from "../Modals/BusinessAtProposalModal";

export const ProposalStatsPanel = () => {

    const dispatch = useDispatch();
    const stats = useSelector(state => state.dashboard.stats);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        await fetchProposalStats({dispatch});
    }

    const handleClick = (secondary) => {
        dispatch(setModal({component:<BusinessAtProposalModal status={secondary} />, open:true, fullScreen:false}));
    };

    const List = styled(ListUI)({
        '& .MuiListItem-root':{
            paddingTop:'6px!important',
            paddingBottom:'6px!important',
            borderBottom:'1px dashed #ddd',
            cursor:'pointer',
            '&:hover':{
                backgroundColor:'#f5f5f5',
            }
        },
        '& .MuiListItem-root:last-child':{
            borderBottom:'none',
        },
        '& .MuiListItemText-root':{
            display:'flex',
            marginTop:'6px!important',
            marginBottom:'6px!important',
            justifyContent:'space-between',
            '& span':{
                fontSize:14,
                fontWeight:'normal',
            },
            '& p':{
                fontSize:11,
                fontWeight:'normal',
            }
        }
    })

    const ListItemText = styled(ListItemTextUI)(({ theme, secondary }) => ({
        '& span': {
            color: secondary > 5 ? theme.palette.error.main : '#444444',
        },
        '& p': {
            backgroundColor: '#efefef',
            padding:'0.15rem 0.3rem',
            borderRadius:'0.15rem',
            minWidth:'25px',
            textAlign:'center',
            color: secondary > 5 ? theme.palette.error.main : '#222222',
        },
    }));

    return (
        <Panel>
            <CardHeader title={"Proposal Queue"} />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={"Business"}
                            secondary={ stats?.businessRegistered }
                            onClick={() => handleClick(["DRAFT"])}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Business Analyst"}
                            secondary={ stats?.businessSubmitted }
                            onClick={() => handleClick(["SUBMITTED"])}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Head of Business"}
                            secondary={ stats?.businessVerified }
                            onClick={() => handleClick(["VERIFIED"])}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Credit Analyst"}
                            secondary={ stats?.businessBHApproved }
                            onClick={() => handleClick(["BH_APPROVED"])}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={"Credit Manager"}
                            secondary={ stats?.businessReviewed + stats?.businessCreditApproved + stats?.businessCEOApproved + stats?.businessBODApproved }
                            onClick={() => handleClick(["REVIEWED","CREDIT_APPROVED","CEO_APPROVED","BOD_APPROVED"])}
                        />
                    </ListItem>

                    {/*<ListItem>*/}
                    {/*    <ListItemText*/}
                    {/*        primary={"CEO"}*/}
                    {/*        secondary={ stats?.businessCreditApproved }*/}
                    {/*        onClick={() => handleClick(["CREDIT_APPROVED"])}*/}
                    {/*    />*/}
                    {/*</ListItem>*/}
                    {/*<ListItem>*/}
                    {/*    <ListItemText*/}
                    {/*        primary={"Board of Director"}*/}
                    {/*        secondary={ stats?.businessCEOApproved }*/}
                    {/*        onClick={() => handleClick(["CEO_APPROVED"])}*/}
                    {/*    />*/}
                    {/*</ListItem>*/}
                    {/*<ListItem>*/}
                    {/*    <ListItemText*/}
                    {/*        primary={"Chairman"}*/}
                    {/*        secondary={ stats?.businessBODApproved }*/}
                    {/*        onClick={() => handleClick(["BOD_APPROVED"])}*/}
                    {/*    />*/}
                    {/*</ListItem>*/}
                </List>
            </CardContent>
        </Panel>
    )
}
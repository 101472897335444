import * as React from 'react';
import {Card as CardUI} from "@mui/material";
import {styled} from "@mui/material/styles";

export const DrawerCard = ({children}) => {

    const Card = styled(CardUI)(() => ({
        width: '100%',
        height: '100%',
        position:'relative',
        display: 'flex',
        flexDirection:'column',
        alignItems:'stretch',
        justifyContent:'flex-start',
        alignContent:'stretch',
    }));

    return (
        <Card>
            {children}
        </Card>
    );
}




import React from 'react'
import {Typography, Typography as TypographyUI, Card, CardContent, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";

export const MetricTemplateCard = (props) => {

    const Value = styled(TypographyUI)(({ theme }) => ({
        fontSize: '1.3rem',
        fontWeight: 400,
        margin: '0px 0px 10px',
        lineHeight:1.167,
        '& a': {
            color: props.color ? `${theme.text[props.color]}` : '#444444',
            textDecoration: 'none',
            '&:hover': {
                color: '#000000',
            }
        }
    }));

    return (
        <>
            <Card sx={{display:'flex', height:'100%', justifyItems:'space-between', flexDirection:'column'}}>
                <CardContent sx={{padding:'10px!important', flex:1}}>
                    <Grid>
                        <Typography sx={{ fontSize: '0.75rem', marginBottom:'8px' }} variant="h3" color="text.primary">
                            {props.title}
                        </Typography>
                        <Value sx={{ fontSize: '1.2rem', mb:0 }} variant="h4" color={props.color} gutterBottom>
                            {props.link !== false ? (
                                <Link to={props.link}>{props.unit} {props.displayedCount}</Link>
                            ): (<>{props?.unit} {props?.displayedCount}</>)
                            }
                        </Value>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

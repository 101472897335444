const CONST = {
    'SET_CONFIG':'POSITION/SET_CONFIG',
    'RESET_ALL':'POSITION/RESET_ALL',
    'SET_SELECTED_ITEM':'POSITION/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'POSITION/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'POSITION/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'POSITION/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'POSITION/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'POSITION/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'POSITION/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'POSITION/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'POSITION/UPDATE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'POSITION/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'POSITION/DELETE_ITEM_SUCCESS',
}

export default CONST;
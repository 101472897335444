import * as React from 'react';
import {
    Grid,
    Select,
    TextField,
    Typography,
    Button,
    InputLabel,
    FormControl,
    Stack,
    FormControlLabel, FormHelperText
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useState} from "react";
import {useDispatch} from "react-redux";
import {doRegisterInvestor} from "../../Redux/Action";
import Toastr from "../../../../../Components/Elements/Toastr";
import {useNavigate} from "react-router-dom";

export const InvestorRegistrationForm =()  => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await doRegisterInvestor({dispatch, data}).then((res)=>{
            setDisabled(false);
            setResponse(res);
            if(res['success']){
                navigate('/email-verification')
            }
        })
    };

    const passwordError = (response) => {
        if (response?.error?.data?.errors?.password) {
            const passwordErrors = response?.error?.data?.errors?.password.filter(msg => !msg.includes('Password confirmation'));
            return passwordErrors.join(', ');
        }
        return "";
    };

    const passwordConfirmationError = (response) => {
        if(response?.error?.data?.errors?.password && response?.error?.data?.errors?.password.find(msg => msg.includes('Password confirmation'))){
            return "Passwords do not match";
        }
        return response?.error?.data?.errors?.password;
    }

    return (
        <>
            <Grid container spacing={4} justifyContent={"center"}>
                <Grid item xs={12}>
                    <Typography variant={"h2"} sx={{fontSize:'1.7rem'}} my={3}>Let's get started</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl sx={{ width:'100%' }}>
                        <InputLabel id="label-Title">Title</InputLabel>
                        <Select
                            fullWidth
                            label="Title"
                            name="salutation"
                            variant="outlined"
                            required
                            defaultValue="Select" // Set the default value to "Select"
                            onChange={handleChange}
                        >
                            <MenuItem value="Select">Select</MenuItem>
                            <MenuItem value="Mr">Mr</MenuItem>
                            <MenuItem value="Mrs">Mrs</MenuItem>
                            <MenuItem value="Miss">Miss</MenuItem>
                        </Select>
                        <FormHelperText>{response?.error?.data?.errors?.salutation}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <TextField
                        fullWidth
                        label={"First Name"}
                        name={"first_name"}
                        variant={"outlined"}
                        required
                        onChange={handleChange}
                        helperText={response?.error?.data?.errors?.first_name}
                    />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <TextField
                        fullWidth
                        label={"Last Name"}
                        name={"last_name"}
                        variant={"outlined"}
                        required
                        onChange={handleChange}
                        helperText={response?.error?.data?.errors?.last_name}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Email"}
                        name={"email"}
                        variant={"outlined"}
                        sx={{ fontSize: '1.2rem' }}
                        required
                        onChange={handleChange}
                        helperText={response?.error?.data?.errors?.email}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Mobile"}
                        name={"mobile"}
                        variant={"outlined"}
                        sx={{ fontSize: '1.2rem' }}
                        required
                        onChange={handleChange}
                        helperText={response?.error?.data?.errors?.mobile}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name={"password"}
                        label={"Password"}
                        variant={"outlined"}
                        required
                        type={"password"}
                        onChange={handleChange}
                        helperText={passwordError(response)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name={"password_confirmation"}
                        label={"Re-Password"}
                        variant={"outlined"}
                        required
                        type={"password"}
                        onChange={handleChange}
                        helperText={passwordConfirmationError(response)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                        <Button variant={"contained"} size={"large"} color={"warning"} onClick={() => navigate('/login')}>Already have an Account?</Button>
                        <Button disabled={disabled} variant={"contained"} size={"large"} color={"primary"} onClick={handleSubmit}>Proceed</Button>
                    </Stack>
                </Grid>
            </Grid>
        <Toastr response={response} />
        </>
    )
}

import React, { useState} from 'react'
import { TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import {setModal} from "../../../../Common/App";
import { returnBusiness } from "../../index";
import {useNavigate} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export const ReturnBusinessModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();

    const handleSubmit = async () => {
        let data = {
            'reason':reason,
            'business_id': props.business_id
        }
        setDisabled(true);
        await returnBusiness({dispatch, data});
        dispatch(setModal({component:null, open:false}));
        navigate('/business');
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value === '');
    }

    return(
        <>
            <DialogTitle>Return Business</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to Return this business?
                </DialogContentText>
                <TextField
                    sx={{mt:2}}
                    label={"Reason for Return"}
                    fullWidth
                    rows={4}
                    required
                    onBlur={handleReasonChange}
                    type={"text"}
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Return Business</Button>
            </DialogActions>
        </>
    )
}

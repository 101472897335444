import * as React from 'react';
import {Card, CardContent, CardHeader, Grid, Stack, } from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {AccountsTable, setConfig} from "../index";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import dayjs from 'dayjs';
import {AccountFilterMenu} from "./Menu/AccountFilterMenu";
import {FilterCard} from "./Cards/FilterCard";
import {AccountSummary} from "./Cards/AccountSummary";

export const Accounts = () => {

    const dispatch = useDispatch();
    const filters = useSelector((store) => store.accounts?.config?.filters);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedRows, setSelectedRows] = React.useState([]);

    useEffect(() => {
        dispatch(setConfig({filters: {...filters, transaction_type:['CREDIT','DEBIT'], page:0, pageSize:25, type:[], due_date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'), status:[]}}));
    },[]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Breadcrumbs items={'DASHBOARD/PAYMENTS'} />
                        <MemoizedAccountFilterMenu2 setResponse={setResponse} setIsLoading={setIsLoading} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <AccountSummary selectedRows={selectedRows} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FilterCard />
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Card>
                        <CardHeader title={"All Accounts"} titleTypographyProps={{variant:'subtitle1'}} />
                        <CardContent sx={{p:0}}>
                            <AccountsTable isLoading={isLoading} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}


const MemoizedAccountFilterMenu2 = React.memo(AccountFilterMenu);

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import {handleAsyncAction} from "../../../../Setup/Axios/ApiHandlers";

/****************************************
 SET SELECTED BANK DETAILS
 ****************************************/
export const setSelectedBankDetail = (payload) => ({
    type: CONST.SET_SELECTED_BANK_DETAIL,
    payload: payload
});

/****************************************
 FETCH BANK_DETAILS
 ****************************************/
export const fetchBankDetailsSuccess = (payload) => ({
    type: CONST.FETCH_BANK_DETAILS_SUCCESS,
    payload: payload
});

export const fetchBankDetailsFailed = (payload) => ({
    type: CONST.FETCH_BANK_DETAILS_FAILED,
    payload: payload
});

export const fetchBankDetails = async ({ dispatch , business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/bankDetail`),
        [fetchBankDetailsSuccess],
        [fetchBankDetailsFailed]
    );
};

/****************************************
 ADD Business BANK DETAILS
 ****************************************/
export const addBankDetailSuccess = (payload) => ({
    type: CONST.ADD_BANK_DETAIL_SUCCESS,
    payload: payload
});

export const addBankDetailFailed = (payload) => ({
    type: CONST.ADD_BANK_DETAIL_FAILED,
    payload: payload
});

export const addBankDetail = async ({ dispatch , data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/bankDetail', data),
        [addBankDetailSuccess],
        [addBankDetailFailed]
    );
};


/****************************************
 Update Business BANK DETAILS
 ****************************************/
export const updateBankDetailSuccess = (payload) => ({
    type: CONST.UPDATE_BANK_DETAIL_SUCCESS,
    payload: payload
});

export const updateBankDetailFailed = (payload) => ({
    type: CONST.UPDATE_BANK_DETAIL_FAILED,
    payload: payload
});

export const updateBankDetail = async ({ dispatch , data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/bankDetail/' + data['bank_detail_id'], data),
        [updateBankDetailSuccess],
        [updateBankDetailFailed]
    );
};

/****************************************
 DELETE Business BANK DETAILS
 ****************************************/
export const deleteBankDetailSuccess = (payload) => ({
    type: CONST.DELETE_BANK_DETAIL_SUCCESS,
    payload: payload
});

export const deleteBankDetailFailed = (payload) => ({
    type: CONST.DELETE_BANK_DETAIL_FAILED,
    payload: payload
});

export const deleteBankDetail = async ({ dispatch , id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete('corporate/bankDetail/' + id),
        [deleteBankDetailSuccess],
        [deleteBankDetailFailed]
    );
};

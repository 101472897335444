import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../../Common/App";
import {
    deleteBusiness, deleteService,
} from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export const DeleteServiceModal = ({service_id, setResponse}) => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async () => {
        setDisabled(true);

        await deleteService({dispatch, data:{service_id}}).then((res) => {
            setDisabled(false);
            setResponse(res);
            dispatch(setModal({component:null, open:false}))
        });
    };

    return(
        <>
            <DialogTitle>Delete Funding Request</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to remove this funding request?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Delete</Button>
            </DialogActions>
        </>
    )
}

import React from "react";
import {
    FormControl,
    Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector,} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {setSelectedBankDetail, updateBankDetail} from "../../index";
import BankAutocomplete from "../BankAutocomplete";
import Toastr from "../../../../../Components/Elements/Toastr";
import MenuItem from "@mui/material/MenuItem";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import {PhoneField} from "../../../../../Components/Elements/PhoneField";

export const BankDetailForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const NUMBERS = /^[0-9\b]+$/;

    useEffect(() => {
        setData(business.bankDetails.selected);
    }, [])

    const handleChange = (e) => {

        setDisabled(false);

        setData(data => ({
            ...data,
            [e.target.name] : ['swift_code','iban_no'].includes(e.target.name) ? (e.target.value).toUpperCase() : e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updateBankDetail({dispatch, data}).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component:null, open:false}));
        dispatch(setSelectedBankDetail(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Banking Relationship : Account Details
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sx={{py:1}}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} mt={1}>

                                <BankAutocomplete setDisabled={setDisabled} setData={setData} data={data} />

                                <TextField
                                    fullWidth
                                    label="IBAN No"
                                    name="iban_no"
                                    defaultValue={data?.iban_no}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.iban_no}
                                    inputProps={{style: { textTransform: "uppercase" }}}
                                    onBlur={handleChange} />

                                <TextField
                                    fullWidth
                                    label="Account No"
                                    name="account_no"
                                    defaultValue={data?.account_no}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.account_no}
                                    onBlur={handleChange} />

                                <TextField
                                    fullWidth
                                    label="Bank Branch"
                                    name="branch_name"
                                    defaultValue={data?.branch_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.branch_name}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Swift Code"
                                    name="swift_code"
                                    defaultValue={data?.swift_code}
                                    inputlabelprops={{ shrink: true }}
                                    inputProps={{style: { textTransform: "uppercase" }}}
                                    helperText={business?.error?.swift_code}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onBlur={handleChange} />
                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-currency">Currency</InputLabel>
                                    <Select
                                        defaultValue={data?.currency || ""}
                                        name="currency"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Currency"
                                        onChange={(e) => handleChange(e)}
                                        helperText={business?.error?.currency}
                                    >
                                        <MenuItem key={"AED"} value={'AED'}>AED</MenuItem>
                                        <MenuItem key={"USD"} value={'USD'}>USD</MenuItem>
                                        <MenuItem key={"EUR"} value={'EUR'}>EUR</MenuItem>
                                        <MenuItem key={"GBP"} value={'GBP'}>GBP</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ width:'100%', mb:3  }}>
                                    <InputLabel id="label-currency">Bank Statement Password Protected?</InputLabel>
                                    <Select
                                        defaultValue={data?.bank_statement_protected || ""}
                                        name="bank_statement_protected"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Bank Statement Password Protected?"
                                        onChange={(e) => handleChange(e)}
                                        helperText={business?.error?.bank_statement_protected}
                                    >
                                        <MenuItem key={"1"} value={'1'}>Yes</MenuItem>
                                        <MenuItem key={"0"} value={'0'}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Bank Statement Password"
                                    name="bank_statement_password"
                                    defaultValue={data?.bank_statement_password}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.bank_statement_password}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Relationship Manager Name"
                                    name="rm_name"
                                    defaultValue={data?.rm_name}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.rm_name}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="Relationship Manager Email"
                                    name="rm_email"
                                    defaultValue={data?.rm_email}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.rm_email}
                                    onBlur={handleChange} />
                                <PhoneField
                                    fullWidth
                                    label="Relationship Manager Phone/Mobile"
                                    name="rm_phone"
                                    type="tel"
                                    placeholder={"e.g. 9714XXXXXXX"}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    size="large"
                                    defaultValue={data?.rm_phone || ""}
                                    helperText={business.error?.rm_phone}
                                />
                                <TextField
                                    fullWidth
                                    multiline={true}
                                    rows={3}
                                    label="Remarks/Notes"
                                    name="remarks"
                                    defaultValue={data?.remarks}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.remarks}
                                    onBlur={handleChange} />

                                <AttachDoc multiple={true} setResponse={setResponse} updateFunction={updateBankDetail} allowedFileType={['pdf','xls','xlsx','csv']} onBlur={handleChange} label={"Last 1 year bank statement"} entity_name={"bankDetails"} entity_id={data?.bank_detail_id} name="bank_statement_doc" data={data} state={setData} />

                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}


const CONST = {
    'SET_CONFIG':'FUNDING/SET_CONFIG',
    'RESET_ALL':'FUNDING/RESET_ALL',
    'SET_SELECTED_ITEM':'FUNDING/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'FUNDING/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'FUNDING/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'FUNDING/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'FUNDING/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'FUNDING/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'FUNDING/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'FUNDING/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'FUNDING/UPDATE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'FUNDING/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'FUNDING/DELETE_ITEM_SUCCESS',
}

export default CONST;
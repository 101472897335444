import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {updateAccount} from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useEffect} from "react";
import {FormControl, FormHelperText, Grid, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import {TextField} from "../../../../../Components/Elements/TextField";
import Divider from "@mui/material/Divider";

export const AccountDetailsModal = () => {

    const dispatch = useDispatch();
    const item = useSelector((store)=> store.accounts.selected);
    const [data, setData] = React.useState({});
    const [response, setResponse] = React.useState({});
    const [disabled, setDisabled] = React.useState(false);
    const [amountReceivedError, setAmountReceivedError] = React.useState("");

    useEffect(() => {
        setData({
            account_id: item?.account_id,
            due_date: item?.due_date,
            type: item?.account_type?.name,
            cheque_deposit_date: item?.cheque_deposit_date ?? "",
            cheque_date: item?.cheque_date ?? "",
            cheque_no: item?.cheque_no ?? "",
            payment_date: "",
            company_bank_id: item?.company_bank_id ?? "",
            payment_method: item?.payment_method,
            amount: item?.amount ?? "",
            balance: item?.balance ?? "",
            status: item?.status,
            remarks: "",
        });
    }, []);

    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        if (name === 'amount_received') {
            let amountReceived = Number(value);
            let balance = Number(item?.balance);

            if (amountReceived <= balance) {
                let newBalance = balance - amountReceived;

                setData(data => ({
                    ...data,
                    //status: newBalance === 0 ? 'PAID' : 'PARTIAL',
                    balance: newBalance,
                    amount_received: amountReceived
                }));

                setAmountReceivedError(newBalance !== 0 ? "You are submitting partial amount" : "");
            } else {
                setAmountReceivedError('Amount received cannot be greater than the balance.');
            }
        } else {
            setData(data => ({
                ...data,
                [name]: value,
            }));
        }
        setDisabled(false);
    }

    const handleSubmit = async () => {
        setDisabled(true);
        const payload = {...data};
        delete(payload['balance']);
        delete(payload['due_date']);
        delete(payload['amount']);
        delete(payload['status']);

        await updateAccount({dispatch, data:{...payload}}).then((res) => {
            setResponse(res)
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
            }
            setDisabled(true);
        });
    };

    return (
        <>
            <DialogTitle>
                {item?.business?.name}
            </DialogTitle>
            <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                { (data !== undefined && Object.keys(data).length > 0) &&
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            value={item?.account_type?.name }
                            label={"Payment Type"}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={data?.status === 'PLANNED'}
                            value={data?.amount}
                            label={"Amount"}
                            fullWidth
                            name="amount"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.amount}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            defaultValue={data?.balance ?? ""}
                            label={"Balance"}
                            fullWidth
                            name="balance"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.balance}
                        />
                    </Grid>

                    {['BANK_TRANSFER','CASH'].includes(data.payment_method) && (
                        <Grid item xs={12} sm={6}>
                            <MuiDatepicker
                                disabled={item?.status === 'PLANNED'}
                                error={response?.error?.data?.errors?.due_date}
                                setDisabled={setDisabled}
                                format={"YYYY-MM-DD"}
                                views={['year', 'month', 'day']}
                                label={"Due Date"}
                                past={false}
                                name="due_date"
                                data={data}
                                state={setData}
                            />
                        </Grid>
                    )}

                    {data.payment_method !== 'CASH' && (
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="label-payment-type">Received In</InputLabel>
                            <Select
                                className={['BANK_TRANSFER','CHEQUE'].includes(data.payment_method) ? 'highlight' : ''}
                                name="company_bank_id"
                                label="Payment Method"
                                value={data?.company_bank_id ?? ""}
                                onChange={handleChange}
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem key={"01had8m0d1vd0ah3s52yv3w61q"} value={"01had8m0d1vd0ah3s52yv3w61q"}>CBD</MenuItem>
                                <MenuItem key={"01had8m0d1vd0ah3s52yv3w61w"} value={"01had8m0d1vd0ah3s52yv3w61w"}>ENBD</MenuItem>
                                <MenuItem key={"01had8m0d1vd0ah3s52yv3w62y"} value={"01had8m0d1vd0ah3s52yv3w62y"}>GIB</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.company_bank_id}</FormHelperText>
                        </FormControl>
                    </Grid>)}

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="label-payment-type">Payment Method</InputLabel>
                            <Select
                                name="payment_method"
                                label="Payment Method"
                                value={data?.payment_method ?? ""}
                                onChange={handleChange}
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem key={"BANK_TRANSFER"} value={"BANK_TRANSFER"}>Bank Transfer</MenuItem>
                                <MenuItem key={"CASH"} value={"CASH"}>Cash</MenuItem>
                                <MenuItem key={"CHEQUE"} value={"CHEQUE"}>Cheque</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.payment_method}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid xs={12} item><Divider /></Grid>

                    <Grid item xs={12} sm={6}>
                        <MuiDatepicker
                            className={'highlight'}
                            error={response?.error?.data?.errors?.payment_date}
                            setDisabled={setDisabled}
                            format={"YYYY-MM-DD"}
                            views={['year','month','day']}
                            label={"Payment Date"}
                            past={true}
                            name="payment_date"
                            data={data}
                            state={setData}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            className={'highlight'}
                            defaultValue={data?.amount_received}
                            label={"Amount Received"}
                            fullWidth
                            name="amount_received"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={amountReceivedError || response?.error?.data?.errors?.amount_received}

                        />
                    </Grid>

                    {data.payment_method === 'CHEQUE' && (
                        <>
                            <Grid item xs={12}>
                                <Divider>Cheque Details</Divider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    defaultValue={data?.cheque_no ?? ""}
                                    label={"Cheque No"}
                                    fullWidth
                                    name="cheque_no"
                                    disabled={true}
                                    onBlur={handleChange}
                                    type={"number"}
                                    helperText={response?.error?.data?.errors?.cheque_no}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <MuiDatepicker
                                    //error={response?.error?.data?.errors?.cheque_date}
                                    setDisabled={setDisabled}
                                    format={"YYYY-MM-DD"}
                                    views={['year', 'month', 'day']}
                                    label={"Cheque Date"}
                                    past={false}
                                    disabled={true}
                                    name="cheque_date"
                                    data={data}
                                    state={setData}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <MuiDatepicker
                                    className={data.payment_method.includes('CHEQUE') ? 'highlight' : ''}
                                    error={response?.error?.data?.errors?.cheque_deposit_date}
                                    setDisabled={setDisabled}
                                    format={"YYYY-MM-DD"}
                                    views={['year','month','day']}
                                    label={"Cheque Deposit Date"}
                                    past={true}
                                    name="cheque_deposit_date"
                                    data={data}
                                    state={setData}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <TextField
                            defaultValue={data?.remarks ?? ""}
                            label={"Remarks"}
                            fullWidth
                            name="remarks"
                            onBlur={handleChange}
                            type={"text"}
                            helperText={response?.error?.data?.errors?.remarks}
                        />
                    </Grid>
                </Grid>
                }
            </DialogContent>
            <DialogActions sx={{p:3, pt:0}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button variant={"contained"} color={'primary'} onClick={ handleSubmit }>Update</Button>
            </DialogActions>
        </>
    );
}
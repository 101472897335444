import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    quickList:[],
    selected: {},
    partners: {},
    financials: {},
    bankDetails: {},
    bankBorrowings: {},
    creditCards: {},
    cheques: {},
    otherBorrowings: {},
    addresses: {},
    vatReturns: {},
    wpsDetails: {},
    customers: {},
    customersSuppliers: {},
    config:{
        from:0,
        to:0,
        lastPage:1,
        rowCount:0,
        pagination:{
            page:0,
            pageSize:5,
        },
        filters:{
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){

        case CONST.CLEAR_PROCEED_STATE:
            return {
                ...state,
                proceed: false,
                error:[],
                code:200
            }
        case CONST.CLEAR_ERROR_STATE:
            return {
                ...state,
                error:[],
            }
        case CONST.CLEAR_DATA:
            return {
                ...state,
                list:[],
                selected:{}
            }
        case CONST.SET_CONFIG:
            return {
                ...state,
                config:{
                    ...state.config,
                    ...action.payload
                }
            }
        case CONST.RESET_FILTER:
            return {
                ...state,
                config:{
                    ...state.config,
                    filters:INITIAL_STATE.config.filters
                }
            }
        case CONST.SET_SELECTED_BUSINESS:
            return {
                ...state,
                selected: state.list.filter(item => item.business_id === action.payload)[0],
                error:[],
                code:200
            }
        case CONST.FETCH_BUSINESS_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                code:action.payload.status,
                message: ["Business loaded successfully"]
            }
        case CONST.FETCH_BUSINESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_BUSINESSES_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                config:{
                    ...state.config,
                    from:action.payload.data.from,
                    to:action.payload.data.to,
                    lastPage:action.payload.data.last_page,
                    rowCount:action.payload.data.total,
                    pagination:{
                        ...state.config.pagination,
                        page:action.payload.data.current_page - 1,
                        pageSize:action.payload.data.per_page,
                    },
                },
                error: [],
                message: ["Businesses loaded successfully"]
            }
        case CONST.FETCH_BUSINESSES_FAILED:
            return {
                ...state,
                error: [],
                message: [action.payload]
            }
        case CONST.FETCH_BUSINESS_QUICKLIST_SUCCESS:
            console.log(action.payload.data.data)
            return {
                ...state,
                quickList: action.payload.data.data,
                error: [],
                message: ["Business quick list loaded successfully"]
            }
        case CONST.FETCH_BUSINESS_QUICKLIST_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_BUSINESS_SUCCESS:
            return {
                ...state,
                list:[...state.list,action.payload.data],
                selected: action.payload.data,
                error: [],
                proceed:true,
                message: ["Business added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_BUSINESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                selected: {},
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.RELATE_BUSINESS_SUCCESS:
        case CONST.UNRELATE_BUSINESS_SUCCESS:
        case CONST.UPDATE_BUSINESS_SUCCESS:
            const indexU = state.list.findIndex(object => object.business_id === action.payload.data.business_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Business updated successfully"],
                code:action.payload.status,
            }
        case CONST.UNRELATE_BUSINESS_FAILED:
        case CONST.RELATE_BUSINESS_FAILED:
        case CONST.UPDATE_BUSINESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.VERIFY_BUSINESS_SUCCESS:
            const indexvb = state.list.findIndex(object => object.business_id === action.payload.data.business_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexvb), action.payload.data, ...state.list.slice(indexvb + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Business verified successfully"],
                code:action.payload.status,
            }
        case CONST.VERIFY_BUSINESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.RETURN_BUSINESS_SUCCESS:
            const indexrb = state.list.findIndex(object => object.business_id === action.payload.data.business_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexrb), action.payload.data, ...state.list.slice(indexrb + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Business returned successfully"],
                code:action.payload.status,
            }
        case CONST.RETURN_BUSINESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.APPROVE_BUSINESS_SUCCESS:
            const indexab = state.list.findIndex(object => object.business_id === action.payload.data.business_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexab), action.payload.data, ...state.list.slice(indexab + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Business approved successfully"],
                code:action.payload.status,
            }
        case CONST.APPROVE_BUSINESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.REJECT_BUSINESS_SUCCESS:
            const indexreb = state.list.findIndex(object => object.business_id === action.payload.data.business_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexreb), action.payload.data, ...state.list.slice(indexreb + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Business approval successfully"],
                code:action.payload.status,
            }
        case CONST.REJECT_BUSINESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_BUSINESS_SUCCESS:
            const indexBD = state.list.findIndex(o => o.business_id === action.payload.data);
            return {
                ...state,
                list:[...state.list.slice(0,indexBD), ...state.list.slice(indexBD + 1)],
                selected:{},
                error: [],
                proceed:true,
                message: ["Business deleted successfully"],
                code:action.payload.status,
            }
        case CONST.FETCH_BUSINESS_CONFIG_SUCCESS:
            return {
                ...state,
                config: action.payload.data,
                error: [],
                message: ["Business config loaded successfully"]
            }
        case CONST.FETCH_BUSINESS_CONFIG_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_PARTNER:
            return {
                ...state,
                partners:{
                    ...state.partners,
                    selected: action.payload === null ? {} : state.partners.list.filter(item => item.id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_PARTNERS_SUCCESS:
            return {
                ...state,
                partners: {
                    ...state.partners,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business partner loaded successfully"]
            }
        case CONST.FETCH_PARTNERS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_PARTNER_SUCCESS:
            return {
                ...state,
                partners: {
                    list:[...state.partners.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business partners added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_PARTNER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                partners: {
                    ...state.partners,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_PARTNER_SUCCESS:
            const indexOU = state.partners.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                partners: {
                    list: [...state.partners.list.slice(0, indexOU), action.payload.data, ...state.partners.list.slice(indexOU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business partners updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_PARTNER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_PARTNER_SUCCESS:
            const indexD = state.partners.list.findIndex(o => o.id === action.payload.data);
            return {
                ...state,
                partners: {
                    list:[...state.partners.list.slice(0,indexD), ...state.partners.list.slice(indexD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business partners deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_PARTNER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_FINANCIAL:
            return {
                ...state,
                financials:{
                    ...state.financials,
                    selected: action.payload === null ? {} : state.financials.list.filter(item => item.id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_FINANCIALS_SUCCESS:
            return {
                ...state,
                financials: {
                    ...state.financials,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business Financial loaded successfully"]
            }
        case CONST.FETCH_FINANCIALS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_FINANCIAL_SUCCESS:
            return {
                ...state,
                financials: {
                    list:[...state.financials.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business financials added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_FINANCIAL_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                financials: {
                    ...state.financials,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_FINANCIAL_SUCCESS:
            const indexFU = state.financials.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                financials: {
                    list: [...state.financials.list.slice(0, indexFU), action.payload.data, ...state.financials.list.slice(indexFU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business financials updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_FINANCIAL_FAILED:
            return {
                ...state,
                financials: {
                    ...state.financials
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_FINANCIAL_SUCCESS:
            const indexFD = state.financials.list.findIndex(o => o.id === action.payload.data);
            return {
                ...state,
                financials: {
                    list:[...state.financials.list.slice(0,indexFD), ...state.financials.list.slice(indexFD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business financials deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_FINANCIAL_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_BANK_DETAIL:
            return {
                ...state,
                bankDetails:{
                    ...state.bankDetails,
                    selected: action.payload === null ? {} : state.bankDetails.list.filter(item => item.bank_detail_id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                bankDetails: {
                    ...state.bankDetails,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business Bank Cards loaded successfully"]
            }
        case CONST.FETCH_BANK_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_BANK_DETAIL_SUCCESS:
            return {
                ...state,
                bankDetails: {
                    list:[...state.bankDetails.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Bank Cards added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_BANK_DETAIL_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                bankDetails: {
                    ...state.bankDetails,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_BANK_DETAIL_SUCCESS:
            const indexBDU = state.bankDetails.list.findIndex(object => object.bank_detail_id === action.payload.data.bank_detail_id);
            return {
                ...state,
                bankDetails: {
                    list: [...state.bankDetails.list.slice(0, indexBDU), action.payload.data, ...state.bankDetails.list.slice(indexBDU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Bank Cards updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_BANK_DETAIL_FAILED:
            return {
                ...state,
                bankDetails: {
                    ...state.bankDetails
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_BANK_DETAIL_SUCCESS:
            const indexBDD = state.bankDetails.list.findIndex(o => o.bank_detail_id === action.payload.data);
            return {
                ...state,
                bankDetails: {
                    list:[...state.bankDetails.list.slice(0,indexBDD), ...state.bankDetails.list.slice(indexBDD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Bank Cards deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_BANK_DETAIL_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_BANK_BORROWING:
            return {
                ...state,
                bankBorrowings:{
                    ...state.bankBorrowings,
                    selected: action.payload === null ? {} : state.bankBorrowings.list.filter(item => item.id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_BANK_BORROWING_SUCCESS:
            return {
                ...state,
                bankBorrowings: {
                    ...state.bankBorrowings,
                    selected: action.payload.data,
                },
                error: [],
                message: ["Business Bank Borrowing loaded successfully"]
            }
        case CONST.FETCH_BANK_BORROWING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_BANK_BORROWINGS_SUCCESS:
            return {
                ...state,
                bankBorrowings: {
                    ...state.bankBorrowings,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business Bank Borrowings loaded successfully"]
            }
        case CONST.FETCH_BANK_BORROWINGS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_BANK_BORROWING_SUCCESS:
            return {
                ...state,
                bankBorrowings: {
                    list:[...state.bankBorrowings.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Bank Borrowings added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_BANK_BORROWING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                bankBorrowings: {
                    ...state.bankBorrowings,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_BANK_BORROWING_SUCCESS:
            const indexBBU = state.bankBorrowings.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                bankBorrowings: {
                    list: [...state.bankBorrowings.list.slice(0, indexBBU), action.payload.data, ...state.bankBorrowings.list.slice(indexBBU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Bank Cards updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_BANK_BORROWING_FAILED:
            return {
                ...state,
                bankBorrowings: {
                    ...state.bankBorrowings
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_BANK_BORROWING_SUCCESS:
            const indexBBD = state.bankBorrowings.list.findIndex(o => o.id === action.payload.data);
            return {
                ...state,
                bankBorrowings: {
                    list:[...state.bankBorrowings.list.slice(0,indexBBD), ...state.bankBorrowings.list.slice(indexBBD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Bank Borrowings deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_BANK_BORROWING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_CREDIT_CARD:
            return {
                ...state,
                creditCards:{
                    ...state.creditCards,
                    selected: action.payload === null ? {} : state.creditCards.list.filter(item => item.card_id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_CREDIT_CARD_SUCCESS:
            return {
                ...state,
                creditCards: {
                    ...state.creditCards,
                    selected: action.payload.data,
                },
                error: [],
                message: ["Business Credit Card loaded successfully"]
            }
        case CONST.FETCH_CREDIT_CARD_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_CREDIT_CARDS_SUCCESS:
            return {
                ...state,
                creditCards: {
                    ...state.creditCards,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business Credit Card loaded successfully"]
            }
        case CONST.FETCH_CREDIT_CARDS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_CREDIT_CARD_SUCCESS:
            return {
                ...state,
                creditCards: {
                    list:[...state.creditCards.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Credit Card added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_CREDIT_CARD_FAILED:
            //TODO: try to combine all Failed and make REQUEST_FAILED.
            return {
                ...state,
                error: action.payload.data.errors,
                creditCards: {
                    ...state.creditCards,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_CREDIT_CARD_SUCCESS:
            const indexCCU = state.creditCards.list.findIndex(object => object.card_id === action.payload.data.card_id);
            return {
                ...state,
                creditCards: {
                    list: [...state.creditCards.list.slice(0, indexCCU), action.payload.data, ...state.creditCards.list.slice(indexCCU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Credit Card updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_CREDIT_CARD_FAILED:
            return {
                ...state,
                creditCards: {
                    ...state.creditCards
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_CREDIT_CARD_SUCCESS:

            const indexCCD = state.creditCards.list.findIndex(o => o.card_id === action.payload.data);
            return {
                ...state,
                creditCards: {
                    list:[...state.creditCards.list.slice(0,indexCCD), ...state.creditCards.list.slice(indexCCD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Credit Card deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_CREDIT_CARD_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_OTHER_BORROWING:
            return {
                ...state,
                otherBorrowings:{
                    ...state.otherBorrowings,
                    selected: action.payload === null ? {} : state.otherBorrowings.list.filter(item => item.id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_OTHER_BORROWING_SUCCESS:
            return {
                ...state,
                otherBorrowings: {
                    ...state.otherBorrowings,
                    selected: action.payload.data,
                },
                error: [],
                message: ["Business Personal Borrowing loaded successfully"]
            }
        case CONST.FETCH_OTHER_BORROWING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_OTHER_BORROWINGS_SUCCESS:
            return {
                ...state,
                otherBorrowings: {
                    ...state.otherBorrowings,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business Personal Borrowings loaded successfully"]
            }
        case CONST.FETCH_OTHER_BORROWINGS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_OTHER_BORROWING_SUCCESS:
            return {
                ...state,
                otherBorrowings: {
                    list:[...state.otherBorrowings.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Personal Borrowing added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_OTHER_BORROWING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                otherBorrowings: {
                    ...state.otherBorrowings,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_OTHER_BORROWING_SUCCESS:
            const indexPBU = state.otherBorrowings.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                otherBorrowings: {
                    list: [...state.otherBorrowings.list.slice(0, indexPBU), action.payload.data, ...state.otherBorrowings.list.slice(indexPBU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Personal Borrowing updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_OTHER_BORROWING_FAILED:
            return {
                ...state,
                otherBorrowings: {
                    ...state.otherBorrowings
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_OTHER_BORROWING_SUCCESS:
            const indexPBD = state.otherBorrowings.list.findIndex(o => o.id === action.payload.data);
            return {
                ...state,
                otherBorrowings: {
                    list:[...state.otherBorrowings.list.slice(0,indexPBD), ...state.otherBorrowings.list.slice(indexPBD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Personal Borrowing deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_OTHER_BORROWING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_CHEQUE:
            return {
                ...state,
                cheques:{
                    ...state.cheques,
                    selected: action.payload === null ? {} : state.cheques.list.filter(item => item.cheque_id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_CHEQUE_SUCCESS:
            return {
                ...state,
                cheques: {
                    ...state.cheques,
                    selected: action.payload.data,
                },
                error: [],
                message: ["Business Cheque loaded successfully"]
            }
        case CONST.FETCH_CHEQUE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_CHEQUES_SUCCESS:
            return {
                ...state,
                cheques: {
                    ...state.cheques,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business Cheques loaded successfully"]
            }
        case CONST.FETCH_CHEQUES_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_CHEQUE_SUCCESS:
            return {
                ...state,
                cheques: {
                    list:[...state.cheques.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Cheques added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_CHEQUE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                cheques: {
                    ...state.cheques,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_CHEQUE_SUCCESS:
            const indexCU = state.cheques.list.findIndex(object => object.cheque_id === action.payload.data.cheque_id);
            return {
                ...state,
                cheques: {
                    list: [...state.cheques.list.slice(0, indexCU), action.payload.data, ...state.cheques.list.slice(indexCU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Cheques updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_CHEQUE_FAILED:
            return {
                ...state,
                cheques: {
                    ...state.cheques
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_CHEQUE_SUCCESS:
            const indexCD = state.cheques.list.findIndex(o => o.cheque_id === action.payload.data);
            return {
                ...state,
                cheques: {
                    list:[...state.cheques.list.slice(0,indexCD), ...state.cheques.list.slice(indexCD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Cheques deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_CHEQUE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_VAT_RETURN:
            return {
                ...state,
                vatReturns:{
                    ...state.vatReturns,
                    selected: action.payload === null ? {} : state.vatReturns.list.filter(item => item.id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_VAT_RETURN_SUCCESS:
            return {
                ...state,
                vatReturns: {
                    ...state.vatReturns,
                    selected: action.payload.data,
                },
                error: [],
                message: ["Business VAT Return loaded successfully"]
            }
        case CONST.FETCH_VAT_RETURN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_VAT_RETURNS_SUCCESS:
            return {
                ...state,
                vatReturns: {
                    ...state.vatReturns,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business VAT Returns loaded successfully"]
            }
        case CONST.FETCH_VAT_RETURNS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_VAT_RETURN_SUCCESS:
            return {
                ...state,
                vatReturns: {
                    list: Array.isArray(action.payload.data)
                        ? [...state.vatReturns.list, ...action.payload.data]
                        : [...state.vatReturns.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business VAT Returns added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_VAT_RETURN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                vatReturns: {
                    ...state.vatReturns,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_VAT_RETURN_SUCCESS:
            const indexVRU = state.vatReturns.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                vatReturns: {
                    list: [...state.vatReturns.list.slice(0, indexVRU), action.payload.data, ...state.vatReturns.list.slice(indexVRU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business VAT Returns updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_VAT_RETURN_FAILED:
            return {
                ...state,
                vatReturns: {
                    ...state.vatReturns
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_VAT_RETURN_SUCCESS:
            const indexVRD = state.vatReturns.list.findIndex(o => o.id === action.payload.data);
            return {
                ...state,
                vatReturns: {
                    list:[...state.vatReturns.list.slice(0,indexVRD), ...state.vatReturns.list.slice(indexVRD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business VAT Returns deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_VAT_RETURN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_WPS_DETAIL:
            return {
                ...state,
                wpsDetails:{
                    ...state.wpsDetails,
                    selected: action.payload === null ? {} : state.wpsDetails.list.filter(item => item.id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_WPS_DETAIL_SUCCESS:
            return {
                ...state,
                wpsDetails: {
                    ...state.wpsDetails,
                    selected: action.payload.data,
                },
                error: [],
                message: ["Business WPS Cards loaded successfully"]
            }
        case CONST.FETCH_WPS_DETAIL_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_WPS_DETAILS_SUCCESS:
            return {
                ...state,
                wpsDetails: {
                    ...state.wpsDetails,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business WPS Cards loaded successfully"]
            }
        case CONST.FETCH_WPS_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_WPS_DETAIL_SUCCESS:
            return {
                ...state,
                wpsDetails: {
                    list:[...state.wpsDetails.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business WPS Cards added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_WPS_DETAIL_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                wpsDetails: {
                    ...state.wpsDetails,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_WPS_DETAIL_SUCCESS:
            const indexWPSU = state.wpsDetails.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                wpsDetails: {
                    list: [...state.wpsDetails.list.slice(0, indexWPSU), action.payload.data, ...state.wpsDetails.list.slice(indexWPSU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business WPS Details updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_WPS_DETAIL_FAILED:
            return {
                ...state,
                wpsDetails: {
                    ...state.wpsDetails
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_WPS_DETAIL_SUCCESS:
            const indexWPSD = state.wpsDetails.list.findIndex(o => o.id === action.payload.data);
            return {
                ...state,
                wpsDetails: {
                    list:[...state.wpsDetails.list.slice(0,indexWPSD), ...state.wpsDetails.list.slice(indexWPSD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business WPS Cards deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_WPS_DETAIL_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_ADDRESS:
            return {
                ...state,
                addresses:{
                    ...state.addresses,
                    selected: action.payload === null ? {} : state.addresses.list.filter(item => item.id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_ADDRESS_SUCCESS:
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    selected: action.payload.data,
                },
                error: [],
                message: ["Business Address loaded successfully"]
            }
        case CONST.FETCH_ADDRESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_ADDRESSES_SUCCESS:
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    list: action.payload.data,
                },
                error: [],
                message: ["Business Addresses loaded successfully"]
            }
        case CONST.FETCH_ADDRESSES_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                addresses: {
                    list:[...state.addresses.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Addresses added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_ADDRESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                addresses: {
                    ...state.addresses,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_ADDRESS_SUCCESS:
            const indexAU = state.addresses.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                addresses: {
                    list: [...state.addresses.list.slice(0, indexAU), action.payload.data, ...state.addresses.list.slice(indexAU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Addresses updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_ADDRESS_FAILED:
            return {
                ...state,
                addresses: {
                    ...state.addresses
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_ADDRESS_SUCCESS:
            const indexAD = state.addresses.list.findIndex(o => o.id === action.payload.data);
            return {
                ...state,
                addresses: {
                    list:[...state.addresses.list.slice(0,indexAD), ...state.addresses.list.slice(indexAD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Addresses deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_ADDRESS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }


        case CONST.SET_SELECTED_CUSTOMER_SUPPLIER:
            return {
                ...state,
                customersSuppliers:{
                    ...state.customersSuppliers,
                    selected: action.payload === null ? {} : state.customersSuppliers.list.filter(item => item.customer_id === action.payload)[0]
                },
                error:[],
                code:200
            }
        case CONST.FETCH_CUSTOMER_SUPPLIER_SUCCESS:
            return {
                ...state,
                customersSuppliers: {
                    ...state.customersSuppliers,
                    selected:action.payload.data,
                },
                error: [],
                message: ["Business Customer Supplier loaded successfully"]
            }
        case CONST.FETCH_CUSTOMER_SUPPLIER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_CUSTOMER_SUPPLIERS_SUCCESS:
            return {
                ...state,
                customersSuppliers: {
                    ...state.customersSuppliers,
                    list: action.payload.data.data,
                },
                error: [],
                message: ["Business Customers Suppliers loaded successfully"]
            }
        case CONST.FETCH_CUSTOMER_SUPPLIERS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_CUSTOMER_SUPPLIER_SUCCESS:
            return {
                ...state,
                customersSuppliers: {
                    list:[...state.customersSuppliers.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Customers Suppliers added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_CUSTOMER_SUPPLIER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                customersSuppliers: {
                    ...state.customersSuppliers,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_CUSTOMER_SUPPLIER_SUCCESS:
            const indexCSU = state.customersSuppliers.list.findIndex(object => object.customer_id === action.payload.data.customer_id);
            return {
                ...state,
                customersSuppliers: {
                    list: [...state.customersSuppliers.list.slice(0, indexCSU), action.payload.data, ...state.customersSuppliers.list.slice(indexCSU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Customers Suppliers updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_CUSTOMER_SUPPLIER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_CUSTOMER_SUPPLIER_SUCCESS:
            const indexCSD = state.customersSuppliers.list.findIndex(o => o.customer_id === action.payload.data);
            return {
                ...state,
                customersSuppliers: {
                    list:[...state.customersSuppliers.list.slice(0,indexCSD), ...state.customersSuppliers.list.slice(indexCSD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Customers Suppliers deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_CUSTOMER_SUPPLIER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.SET_SELECTED_CUSTOMER:
            return {
                ...state,
                customers:{
                    ...state.customers,
                    selected: state.customers.list.find(item => item.customer_id === action.payload) || {},
                },
                error:[],
                code:200
            }
        case CONST.FETCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    selected:action.payload.data,
                },
                error: [],
                message: ["Business Customer loaded successfully"]
            }
        case CONST.FETCH_CUSTOMER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    list: action.payload.data.data,
                    selected:{},
                },
                error: [],
                message: ["Business Customers loaded successfully"]
            }
        case CONST.FETCH_CUSTOMERS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: {
                    list:[...state.customers.list, action.payload.data],
                    selected: action.payload.data,
                },
                error: [],
                proceed:false,
                message: ["Business Customers added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_CUSTOMER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                customers: {
                    ...state.customers,
                    selected: {},
                },
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_CUSTOMER_SUCCESS:
            const indexCUSU = state.customers.list.findIndex(object => object.customer_id === action.payload.data.customer_id);
            return {
                ...state,
                customers: {
                    list: [...state.customers.list.slice(0, indexCUSU), action.payload.data, ...state.customers.list.slice(indexCUSU + 1)],
                    selected: action.payload.data,
                },
                error: [],
                proceed: true,
                message: ["Business Customers updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_CUSTOMER_FAILED:
            return {
                ...state,
                customers: {
                    ...state.customers
                },
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_CUSTOMER_SUCCESS:
            const indexCUSD = state.customers.list.findIndex(o => o.customer_id === action.payload.data);
            return {
                ...state,
                customers: {
                    list:[...state.customers.list.slice(0,indexCUSD), ...state.customers.list.slice(indexCUSD + 1)],
                    selected:{}
                },
                error: [],
                proceed:true,
                message: ["Business Customers deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_CUSTOMER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.MARK_CUSTOMERS_FOR_INVOICE_DISCOUNTING_SUCCESS:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    list: action.payload.data.data
                },
                error: [],
                proceed:true,
                message: ["Business Customers marked for invoice discounting successfully"],
                code:action.payload.status,
            }
        case CONST.MARK_CUSTOMERS_FOR_INVOICE_DISCOUNTING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload.data.message],
                code:action.payload.status,
            }



        case CONST.UPLOAD_DOCUMENT_SUCCESS:
            let st = {};

            if(action.payload.data['entity_name'] === 'business'){
                st = {
                    ...state,
                    selected:{
                        ...state.selected,
                        [action.payload.data['type']] : action.payload.data['document_id']
                    },
                    documents:[...state.documents, action.payload.data],
                    message: ["Document Uploaded Successfully"],
                    code:action.payload.status,
                }
            }else{
                st = {
                    ...state,
                    [action.payload.data['entity_name']]:{
                        ...state[action.payload.data['entity_name']],
                        selected:{
                            ...state[action.payload.data['entity_name']].selected,
                            [action.payload.data['type']] : action.payload.data['document_id'],
                        }
                    },
                    documents:[...state.documents, action.payload.data],
                    message: ["Document Uploaded Successfully"],
                    code:action.payload.status,
                }
            }
            return st;
        case CONST.UPLOAD_DOCUMENT_FAILED:
            return {
                ...state,
                error: [],
                message: [],
                code:'',
            }
        case CONST.REMOVE_DOCUMENT_SUCCESS:
            return {
                ...state,
                documents: state.documents.filter((i) => i.document_id !== action.payload),
                error: [],
                message: [],
                code:200,
            }
        case CONST.REMOVE_DOCUMENT_FAILED:
            return {
                ...state,
                error: [],
                message: [],
                code:500,
            }
        default:
            return state;
    }
}

export default reducer ;
import * as React from 'react';
import {GridActionsCellItem } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {fetchListing, setConfig, fetchListings, UpdateListingForm} from "../../";
import moment from "moment/moment";
import {currencyFormatter} from "../../../../../Setup/Config";
import {useEffect} from "react";
import {setRightDrawer} from "../../../../Common/App";
import {StatusIndicator} from "../../../../../Components/Elements/StatusIndicator";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";

export const ListingTable = () => {

    const dispatch = useDispatch();
    const listings = useSelector((store) => store.listings);
    const filters = useSelector((store) => store.listings.config.filters);
    const pagination = useDeepEqualSelector((store) => store.listings.config.pagination);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchItems();
    },[pagination]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchListings({dispatch, data:{...filters, ...pagination}}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            await fetchListing({dispatch, id}).then((res) => {
                dispatch(setRightDrawer({component:<UpdateListingForm />, open:true}));
            });
        },
        [],
    );

    const columns = React.useMemo(
        () => [
            { field: 'status', headerName:'', type: 'string', width:50, align:'center',
                renderCell: (params) => {
                    return(<StatusIndicator params={params} />)
                },
            },
            { field: 'business_id', headerName:'Business Name', type: 'string', width:200, valueGetter: (params) => params.row?.business?.name},
            { field: 'service_id', headerName:'Service', type: 'string', width: 200, valueGetter: (params) => params.row?.service?.service_type},
            { field: 'tenor', headerName:'Tenor', type: 'string', width: 100, headerAlign:'right', align:'right', valueGetter: (params) => params.row?.service?.tenor + ' months'},
            { field: 'listing_start_date', headerName:'Start Date', type: 'string', width: 120, headerAlign:'center', align:'center', valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")},
            { field: 'listing_end_date', headerName:'End Date', type: 'string', width: 110, headerAlign:'center', align:'center', valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")},
            { field: 'min_funding_amount', headerName:'Min Funding', type: 'number', width:110, headerAlign:'center', align:'center', valueGetter: (params) => currencyFormatter.format(params.row?.min_funding_amount)},
            { field: 'max_funding_amount', headerName:'Max Funding', type: 'number', width:110, headerAlign:'center', align:'center', valueGetter: (params) => currencyFormatter.format(params.row?.max_funding_amount)},
            { field: 'amount', headerName:'Amount', type: 'number', width:150, valueGetter: (params) => currencyFormatter.format(params.row?.service?.amount)},
            { field: 'is_fully_funded', headerName:'Full Funded', type: 'string', width: 200, valueGetter: (params) => params.row?.is_fully_funded ? 'Yes' : 'No'},
            {
                field: 'actions',
                type: 'actions',
                width: 80,
                headerName:'Action',
                getActions: (params) => {
                    if(params.row?.status !== 'FUNDED'){
                        return [
                            <GridActionsCellItem
                                icon={<FindInPageIcon />}
                                label="View"
                                onClick={ viewItem(params.id) }

                            />
                        ]
                    }
                    return [];
                }

            },
        ],
        [],
    );


    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={listings.list}
                    sx={{ border:'none'}}
                    pagination
                    paginationMode="server"
                    paginationModel={pagination}
                    rowCount={listings?.config?.rowCount ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                    onPageChange={(newPageNo) => dispatch(setConfig({pagination: {...pagination, page: newPageNo}}))}
                    onPageSizeChange={(newPageSize) => dispatch(setConfig({pagination: {...pagination, pageSize: newPageSize}}))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'group', sort: 'asc' }],
                        },
                        pinnedColumns: { left:['status'],  right: ['actions'] },
                        pagination: { paginationModel: { ...pagination }}
                    }}
                />
            </>
        );
}

import React, {useEffect, useState} from 'react'
import {Grid, Stack, Box, CardHeader, CardContent, Card} from "@mui/material";
import {useDispatch,} from "react-redux";
import {Breadcrumbs} from "../../../../Components";
import {
    CustomerTable,
    fetchCustomers,
    fetchCustomer, setSelectedCustomer,
} from "../index";
import Button from "@mui/material/Button";
import ActionButtons from "./ActionButtons";
import CustomerDetailsCard from "./Cards/CustomerDetailsCard";
import CustomerConfigPanel from "./Panels/CustomerConfigPanel";
import {CustomerPropertiesPanel} from "./Panels/CustomerPropertiesPanel";
import {CustomerForm} from "./Forms/CustomerForm";
import { setModal } from "../../../Common/App";
import {useParams} from "react-router-dom";
import DocumentsList from "../../../Common/Documents/Components/DocumentsList";

export const Customers = () => {

    const dispatch = useDispatch();
    const [response, setResponse] = useState({});

    useEffect(() => {
        fetchData();
        dispatch(setModal({ component:null, open: false}))
    },[]);

    let fetchData = async () => {
        await fetchCustomers({dispatch, data:{'for_invoice_discounting': 1}}).then((res) => {
            setResponse(res);
        });
    }

    const handleActionBtn = (e) => {
        dispatch(setModal({component:<CustomerForm />, open:true}));
        dispatch(setSelectedCustomer({}));
    }

    return (
        <>
            {(response !== undefined && response.hasOwnProperty('success')) &&
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box justifyContent={"space-between"} display={"flex"}>
                            <Breadcrumbs items={'DASHBOARD/CUSTOMERS'} sx={{display:'inline-block'}} />
                            <Button variant="contained" data-id={"draft"} data-action={"AddItem"} data-is_customer={1} onClick={handleActionBtn}>Add Customer</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={"Customers"} />
                            <CardContent sx={{p:0}}>
                                <CustomerTable />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
            }
        </>
    );
}

export const CustomerView = () => {

    const { customer_id } = useParams();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});

    useEffect(() => {
        fetchData();
    },[customer_id])

    const fetchData = async() => {
        await fetchCustomer({dispatch, id:customer_id}).then((res) => {
            setResponse(res);
        });
    }

    return (
        <>
            {(response?.success !== undefined) && (
                <Grid container spacing={2} elevation={3}>
                    <Grid item xs={12}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <Breadcrumbs items={'DASHBOARD/CUSTOMERS'} />
                            <ActionButtons />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <CustomerDetailsCard />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={2}>
                            <DocumentsList entityId={customer_id} tab={"customers"} setResponse={setResponse} />
                            <CustomerPropertiesPanel />
                            <CustomerConfigPanel />
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
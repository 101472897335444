import React from "react";

export const LinkTel = ({ call, label }) => {
    const calltoLink = `tel:${call}`;

    return (
        <a
            style={{ textDecoration: 'none' }}
            href={calltoLink}
            onClick={(e) => {
                e.preventDefault(); // Prevent default behavior
                // You can add additional logic here if needed
            }}
        >
            {label}
        </a>
    );
};

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';
import {fetchBusinessDocuments} from "../../../Common/Documents";
import {updateExpiredDocumentSuccess} from "../../Dashboard";

/****************************************
 SET SELECTED VAT_RETURN
 ****************************************/
export const setSelectedVatReturn = (payload) => ({
    type: CONST.SET_SELECTED_VAT_RETURN,
    payload: payload
});

/****************************************
 FETCH VAT_RETURN
 ****************************************/
export const fetchVatReturnsSuccess = (payload) => ({
    type: CONST.FETCH_VAT_RETURNS_SUCCESS,
    payload: payload
});

export const fetchVatReturnsFailed = (payload) => ({
    type: CONST.FETCH_VAT_RETURNS_FAILED,
    payload: payload
});

export const fetchVatReturns = async ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/vatReturn`),
        [fetchVatReturnsSuccess],
        [fetchVatReturnsFailed]
    );
};

/****************************************
 ADD Business VAT_RETURN
 ****************************************/
export const addVatReturnSuccess = (payload) => ({
    type: CONST.ADD_VAT_RETURN_SUCCESS,
    payload: payload
});

export const addVatReturnFailed = (payload) => ({
    type: CONST.ADD_VAT_RETURN_FAILED,
    payload: payload
});

export const addVatReturn = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/vatReturn', data),
        [addVatReturnSuccess],
        [addVatReturnFailed]
    );
};

/****************************************
 * ADD VAT Return with Document
 ****************************************/
export const addVatReturnWithDocument = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('task/add-vat-return', data),
        [updateExpiredDocumentSuccess],
        []
    );
}

/****************************************
 Update Business VAT_RETURN
 ****************************************/
export const updateVatReturnSuccess = (payload) => ({
    type: CONST.UPDATE_VAT_RETURN_SUCCESS,
    payload: payload
});

export const updateVatReturnFailed = (payload) => ({
    type: CONST.UPDATE_VAT_RETURN_FAILED,
    payload: payload
});

export const updateVatReturn = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/vatReturn/${data.id}`, data),
        [updateVatReturnSuccess],
        [updateVatReturnFailed]
    );
};

/****************************************
 Update Business VAT_RETURN - directly from the attached vat file
 ****************************************/
export const uploadVatReturn = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`corporate/upload-vatReturn`, data),
        [addVatReturnSuccess, fetchBusinessDocuments],
        [addVatReturnFailed]
    );
};

/****************************************
 DELETE Business VAT_RETURN
 ****************************************/
export const deleteVatReturnSuccess = (payload) => ({
    type: CONST.DELETE_VAT_RETURN_SUCCESS,
    payload: payload
});

export const deleteVatReturnFailed = (payload) => ({
    type: CONST.DELETE_VAT_RETURN_FAILED,
    payload: payload
});

export const deleteVatReturn = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/vatReturn/${id}`),
        [deleteVatReturnSuccess],
        [deleteVatReturnFailed]
    );
};

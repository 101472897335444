import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {CardContent, CardHeader, Tooltip,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {currencyFormatter} from "../../../../../Setup/Config";
import {Panel} from "../../../../../Components";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import {useNavigate, useParams} from "react-router-dom";
import {fetchUpcomingPayment, setUpcomingConfig} from "../../index";
import {ServiceRequestDetailsCard} from "../Cards/ServiceRequestDetailsCard";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";

export const UpcomingPaymentPanel = () => {

    const {business_id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const items = useSelector((store) => store.dashboard?.upcomingPayments);
    const filters = useSelector((store) => store.dashboard?.upcomingPayments?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.dashboard?.upcomingPayments?.config?.pagination);
    const [response, setResponse] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    },[pagination])

    const fetchData = async () => {

        setIsLoading(true);
        await fetchUpcomingPayment({dispatch, data:{...filters, ...pagination, business_id}}).then((res) => {
            setResponse(res);
            setIsLoading(false);
        })
    }

    const getDetailPanelContent = useCallback(
        ({ row }) => row.loan !== null ? <ServiceRequestDetailsCard data={row} /> : null,
        [],
    );
    const getDetailPanelHeight = useCallback(() => 50, []);

    const columns = useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} />
                ),
            },
            { field: 'contract_no', headerAlign:'center', align:'center', headerName:'C No', type: 'string', flex:1, minWidth: 60, valueGetter: (params) => params.row?.loan?.loan_no,},
            { field: 'service_type', headerName:'Type', type: 'string', flex:1, minWidth:180, valueGetter: (params) => { return  params.row?.service?.service_type.name}},
            { field: 'amount', align:'right', headerAlign:'right', headerName:'Amount', type: 'number',flex:1, minWidth: 80, valueFormatter: ({ value }) => currencyFormatter.format(value),
                valueGetter: (params) => {
                    if (params.row.status === 'PARTIAL') {
                        return params.row.balance;
                    }
                    return params.row.amount;
                },
            },
            // { field: 'days', headerAlign:'center', align:'center', headerName:'Days', type: 'string',flex:1, minWidth: 80,
            //     valueGetter: params => {
            //         const dueDate = params.row.due_date && new Date(params.row.due_date);
            //         const currentDate = new Date();
            //         return (dueDate && dueDate < currentDate) ? Math.floor((currentDate - dueDate) / (1000 * 60 * 60 * 24)) : '';
            //
            //     }
            // },
            { field: 'due_date', headerAlign:'center', align:'center', headerName:'Due Date', type: 'string',flex:1, minWidth: 100, valueFormatter: ({ value }) => value && moment(value).format('DD/MM/YYYY')},
            { field: 'status', headerAlign:'center', align:'center', headerName:'Status', type: 'string',flex:1, minWidth:100,
                renderCell: (params) => {
                    const status = {
                        'PAID':'success',
                        'PARTIAL':'warning',
                        'OVERDUE':'error',
                        'PLANNED':'info',
                        'EXTENDED':'info',
                        'DEFAULT':'error',
                    }
                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Details',
                flex:1,
                minWidth:60,
                getActions: (params) => {
                    return [
                        <Tooltip title={"Show Details"}>
                            <GridActionsCellItem
                                hidden={!(params.row.loan !== null) }
                                icon={<PageviewIcon sx={{zIndex:-1}} />}
                                onClick={ () => navigate( '/business-finance/' + params.row.loan?.loan_id) }
                                label={"View"}
                            />
                        </Tooltip>
                    ];
                },
            },

        ],
        [],
    );


    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'Upcoming Repayments'} />
                <CardContent sx={{p:0, minHeight:'300px'}}>
                    <DataGridPro
                        autoHeight={false}
                        getRowId={(row) => row.account_id}
                        columns={columns}
                        rows={items.list || []}
                        sx={{ border:'none'}}
                        rowThreshold={0}
                        //getDetailPanelContent={auth.user.role_type !== "CLIENT" ? getDetailPanelContent : undefined}
                        getDetailPanelHeight={getDetailPanelHeight}
                        pagination
                        paginationMode="server"
                        paginationModel={pagination}
                        rowCount={items?.config?.rowCount ?? 0}
                        pageSizeOptions={[5]}
                        loading={isLoading}
                        onPaginationModelChange={(params) => dispatch(setUpcomingConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                        initialState={{
                            pinnedColumns: { right: ['actions'] },
                            pagination: { paginationModel: { ...pagination } },
                        }}
                    />
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

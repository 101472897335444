import React, { useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { Grid, TextField, DialogTitle, DialogContent, Alert, DialogActions, Button, Stack } from "@mui/material";
import { setModal } from "../../../../Common/App";
import {addFunding} from "../../";
import {fetchListings, fetchListing} from "../../../Listings";

export const AddFundingPanel = () => {

    const listing = useSelector((store) => store.listings.selected);
    const filters = useSelector((store) => store.listings.config.filters);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [amount, setAmount] = useState(0);
    const [response, setResponse] = useState({});
    const handleSubmit = async () => {

        setDisabled(true);

        await addFunding({dispatch, data:{listing_id:listing.id, amount}}).then((res)=>{
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                fetchListings({dispatch, data:{...filters}});
                fetchListing({dispatch, id:listing.id});
                dispatch(setModal({component:null, open:false}));
            }
        });
    };
    const handleCreditChange = (e) => {
        const newValue = parseFloat(e.target.value); // Convert input value to a number
        setDisabled(newValue <= 0);
        setAmount(newValue);
    }

    return(
        <>
            <DialogTitle>Are you sure, you want to fund this listing?</DialogTitle>
            <DialogContent sx={{paddingTop:'20px!important', paddingBottom:'0!important'}}>
                <Stack spacing={2}>
                    <TextField
                        placeholder={"Enter the bidding amount"}
                        fullWidth
                        required
                        onBlur={handleCreditChange}
                        type={"number"}
                    />
                    {response.error
                        ?<Alert severity="error">{response.error.data.message}</Alert>
                        :<Alert severity="info">Max Bix Amount {listing.max_funding_amount} AED</Alert>
                    }
                </Stack>
            </DialogContent>
            <DialogActions sx={{p:3}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Add Bid</Button>
            </DialogActions>
        </>
    )
}

import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected: {},
    documents:[],
    error:[],
    message:[],
    code: "",
    config:{
        total:0,
        filters:{
            page:0,
            pageSize:5,
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.CLEAR_PROCEED_STATE:
            return {
                ...state,
                proceed: false,
                error:[],
                code:200
            }
        case CONST.CLEAR_ERROR_STATE:
            return {
                ...state,
                error:[],
            }
        case CONST.SET_SELECTED_INVOICE:
            return {
                ...state,
                selected: action.payload === null ? {} : state.list.filter(item => item.invoice_id === action.payload)[0],
                error:[],
                code:200
            }
        case CONST.FETCH_INVOICE_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                code:action.payload.status,
                message: ["Invoice loaded successfully"]
            }
        case CONST.FETCH_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_INVOICES_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                error: [],
                message: ["Invoices loaded successfully"]
            }
        case CONST.FETCH_INVOICES_FAILED:
            return {
                ...state,
                error: [],
                message: [action.payload]
            }
        case CONST.ADD_INVOICE_SUCCESS:
            return {
                ...state,
                list:[...state.list, action.payload.data],
                selected: action.payload.data,
                error: [],
                proceed:true,
                message: ["Invoice added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                selected: {},
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_INVOICE_SUCCESS:
            const indexUI = state.list.findIndex(object => object.invoice_id === action.payload.data.invoice_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexUI), action.payload.data, ...state.list.slice(indexUI + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Invoice updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.VERIFY_INVOICE_SUCCESS:
            const indexvi = state.list.findIndex(object => object.invoice_id === action.payload.data.invoice_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexvi), action.payload.data, ...state.list.slice(indexvi + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Invoice verified successfully"],
                code:action.payload.status,
            }
        case CONST.VERIFY_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.RETURN_INVOICE_SUCCESS:
            const indexri = state.list.findIndex(object => object.invoice_id === action.payload.data.invoice_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexri), action.payload.data, ...state.list.slice(indexri + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Invoice returned successfully"],
                code:action.payload.status,
            }
        case CONST.RETURN_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.CONFIRM_INVOICE_SUCCESS:
            const indexci = state.list.findIndex(object => object.invoice_id === action.payload.data.invoice_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexci), action.payload.data, ...state.list.slice(indexci + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Invoice confirmed successfully"],
                code:action.payload.status,
            }
        case CONST.CONFIRM_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.APPROVE_INVOICE_SUCCESS:
            const indexai = state.list.findIndex(object => object.invoice_id === action.payload.data.invoice_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexai), action.payload.data, ...state.list.slice(indexai + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Invoice approved successfully"],
                code:action.payload.status,
            }
        case CONST.APPROVE_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.REJECT_INVOICE_SUCCESS:
            const indexrei = state.list.findIndex(object => object.invoice_id === action.payload.data.invoice_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexrei), action.payload.data, ...state.list.slice(indexrei + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Invoice approval successfully"],
                code:action.payload.status,
            }
        case CONST.REJECT_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_INVOICE_SUCCESS:
            const indexdi = state.list.findIndex(o => o.invoice_id === action.payload.data);
            return {
                ...state,
                list:[...state.list.slice(0,indexdi), ...state.list.slice(indexdi + 1)],
                selected:{},
                error: [],
                proceed:true,
                message: ["Invoice deleted successfully"],
                code:action.payload.status,
            }
        case CONST.UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                documents:[...state.documents, action.payload.data],
                message: ["Document Uploaded Successfully"],
                code:action.payload.status,
            }
        case CONST.UPLOAD_DOCUMENT_FAILED:
            return {
                ...state,
                error: [],
                message: [],
                code:'',
            }
        case CONST.REMOVE_DOCUMENT_SUCCESS:
            return {
                ...state,
                documents: state.documents.filter((i) => i.document_id !== action.payload),
                error: [],
                message: [],
                code:200,
            }
        case CONST.REMOVE_DOCUMENT_FAILED:
            return {
                ...state,
                error: [],
                message: [],
                code:500,
            }
        default:
            return state;
    }
}

export default Reducer;
import React from 'react'
import {setModal} from "../../../../Common/App";
import {NewServiceRequestModal} from "../../../Services/Components/Modals/NewServiceRequestModal";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";


export const NewRequestButton = ({status}) => {

    const dispatch = useDispatch();
    const permissions = useSelector(state => state.app.permissions);

    const handleClick = () => {
        dispatch(setModal({component:<NewServiceRequestModal />, open:true}));
    }

    return (
        <Button disabled={status === 'DEFAULT' && !permissions.includes("enable-default-funding-request-btn")} size="large" onClick={handleClick} variant="contained" color={status === 'DEFAULT' ? "error" : "primary"} sx={{ justifySelf: 'right' }}>
            Request Funds
        </Button>
    )
}
import React from "react";
import {
    Grid, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import { setSelectedVatReturn, updateVatReturn } from "../../index";
import MuiDatepicker from "../Datepicker";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import Toastr from "../../../../../Components/Elements/Toastr";
import Typography from "@mui/material/Typography";


export const VatReturnForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    useEffect(() => {
        setData(business.vatReturns.selected);
    }, [])

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updateVatReturn({dispatch, data}).then((res)=>{
            setResponse(res);
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component:null, open:false}))
        dispatch(setSelectedVatReturn(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                VAT Returns
                <Typography>VAT Returns for last 4 quarters</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Stack spacing={3} sx={{py:1}}>

                                <MuiDatepicker error={business?.error?.period_from} setDisabled={setDisabled} label={"Period From"} past={true} name="period_from" data={data} state={setData} />

                                <MuiDatepicker error={business?.error?.period_to} setDisabled={setDisabled} label={"Period To"} past={true} name="period_to" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Sales Amount"
                                    name="turnover_amount"
                                    defaultValue={data?.turnover_amount}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.turnover_amount}
                                    onBlur={handleChange} />

                                <TextField
                                    fullWidth
                                    label="Expenses Amount"
                                    name="expenses_amount"
                                    defaultValue={data?.expenses_amount}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.expenses_amount}
                                    onBlur={handleChange} />
                                <TextField
                                    fullWidth
                                    label="VAT Payable / (Refund) Amount"
                                    name="vat_payable"
                                    defaultValue={data?.vat_payable}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.vat_payable}
                                    onBlur={handleChange} />

                                <MuiDatepicker error={business?.error?.payment_date} setDisabled={setDisabled} label={"VAT Payment Date"} past={true} name="payment_date" data={data} state={setData} />

                                <TextField
                                    fullWidth
                                    label="Penalty Amount"
                                    name="penalty_amount"
                                    defaultValue={data?.penalty_amount}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.penalty_amount}
                                    onBlur={handleChange} />

                                <TextField
                                    fullWidth
                                    multiline={true}
                                    rows={3}
                                    label="VAT Penalty Reason"
                                    name="vat_penalty_reason"
                                    defaultValue={data?.vat_penalty_reason}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={business?.error?.vat_penalty_reason}
                                    onBlur={handleChange} />

                                <AttachDoc setResponse={setResponse} updateFunction={updateVatReturn} allowedFileType={['pdf']} onBlur={handleChange} label={"VAT Return Statement"} entity_name={"vatReturns"} entity_id={data?.id} name="vat_return_doc" data={data} state={setData} />

                            </Stack>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}


import React, {useState} from 'react'
import { Grid, Paper} from "@mui/material";
import BusinessTable from './Tables/BusinessTable';
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "../../../../Components";
import {useDispatch} from "react-redux";
import { resetFilter } from "../index";
import { useEffect} from "react";

export const Business = () => {

    const dispatch = useDispatch();
    const [isFiltersReset, setIsFiltersReset] = useState(false);

    useEffect(() => {
        const resetFilters = async () => {
            await dispatch(resetFilter());
            setIsFiltersReset(true);
        };

        resetFilters();
    }, [dispatch]);

    if (!isFiltersReset) {
        return null; // Or a loading indicator can be placed here
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumbs items={'DASHBOARD/BUSINESS'} />
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ display:'flex', flexDirection:'column', flex:1}}>
                        <Typography sx={{ flex: '1', p:2 }} variant="h6" component="div">
                            Business
                        </Typography>
                        <BusinessTable />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

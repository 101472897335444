import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

export const PaymentStatusChart = () => {
    const theme = useTheme();

    const schedule = useSelector((store) => store.accounts.selected?.loan?.schedule) || [];

    // Count the statuses
    const statusCounts = schedule.reduce((acc, item) => {
        const statusName = item.status.name;
        if (!acc[statusName]) {
            acc[statusName] = 0;
        }
        acc[statusName] += 1;
        return acc;
    }, {});

    // Convert to an array for the chart
    const data = Object.keys(statusCounts).map((key) => {
        let color;
        switch (key) {
            case 'PLANNED':
                color = theme.palette.primary.main;
                break;
            case 'PAID':
                color = theme.palette.success.main;
                break;
            case 'OVERDUE':
                color = theme.palette.error.main;
                break;
            case 'EXTENDED':
            case 'PARTIAL':
                color = theme.palette.warning.main;
                break;
            default:
                color = '#000000';
        }
        return {
            name: key,
            value: statusCounts[key],
            color: color
        };
    });

    const COLORS = data.map(entry => entry.color);

    const total = data.reduce((acc, entry) => acc + entry.value, 0);

    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <PieChart width={200} height={200}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))
                            }
                        </Pie>
                        <text x={100} y={100} textAnchor="middle" dominantBaseline="middle">
                            {total} Schedules
                        </text>
                    </PieChart>
                </Grid>
                <Grid item xs={6}>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        {
                            data.map((entry, index) => (
                                <li key={`item-${index}`} style={{ color: entry?.color, marginBottom: '5px' }}>
                                    <Typography variant="body2">{entry?.value} {entry?.name}</Typography>
                                </li>
                            ))
                        }
                    </ul>
                </Grid>
            </Grid>
        </Box>
    );
};

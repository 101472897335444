import React,{ useState, useEffect } from 'react';
import {Alert, Container as ContainerUI, Grid, Stack, TextField as TextFieldUI,} from "@mui/material";
import Typography from "@mui/material/Typography";
import { isBrowser } from 'react-device-detect';
import {styled} from "@mui/material/styles";
import {VerifyEmailForm} from "./Forms/VerifyEmailForm";
import {useDispatch, useSelector} from "react-redux";
import {Link as LinkUI, Link, useNavigate} from "react-router-dom";
import {resendOtp, verifyOtp} from "../Redux/Action";
import Button from "@mui/material/Button";

export const EmailVerification = () => {

    return isBrowser ? <EmailVerificationLg /> : <EmailVerificationSm />;
}

const EmailVerificationLg = () => {

    return (
        <Grid item container xs={12} sx={{ height: '80vh'}}>

            <Grid item alignSelf={"center"} xs={12} md={4} pr={4}>
                <Typography color={"#ffffff"} mb={1} sx={{ fontWeight: '300', fontSize:'30px', fontFamily:'Open Sans', lineHeight: '40px' }} variant={"h3"}>Unlock Cash Flow with Our Extensive Range of Solutions:</Typography>
                <Typography variant={"h5"} sx={{ fontSize: '1rem' }} color={"#ffffff"}>Factoring, Forfaiting, Invoice Discounting and many more…</Typography>
            </Grid>

            <Grid item container justifyContent={"center"} alignContent={"center"} xs={12} md={8} sx={{background:'#ffffff'}}>
                <VerifyEmailForm />
            </Grid>

        </Grid>
    )
}

const EmailVerificationSm = () => {

    const otpLength = 6;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showLoginButton, setShowLoginButton] = useState(false);
    const user = useSelector(state => state.auth.user);

    const [counter, setCounter] = useState(60); // Initial counter value in seconds
    const [disabled, setDisabled] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [response, setResponse] = useState({});

    const [otp, setOtp] = useState(Array(otpLength).fill(''));
    const inputRefs = Array(otpLength).fill(0).map((_, i) => React.createRef());
    const [focused, setFocused] = useState(0);

    let intervalId;

    const Container = styled(ContainerUI)(({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
            //backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
        }
    }));

    useEffect(() => {
        if (counter > 0 && showTimer) {
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);
        } else {
            // Reset the counter and disable the timer
            setCounter(60);
            clearInterval(intervalId);
            setShowTimer(false);
            setDisabled(false);
        }

        return () => clearInterval(intervalId);
    }, [counter, showTimer]);

    const handleInputChange = (index, value) => {
        // Allow only one character per TextField
        if (value.length > 1) {
            return;
        }

        // Update the value in the array
        const newValues = [...otp];
        newValues[index] = value;
        setOtp(newValues);

        // Move focus to the next TextField
        if (value && index < otpLength - 1) {
            setFocused(index + 1);
        }
    };

    const handlePaste = (event, index) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text');
        if (pastedText.length <= otpLength) {
            const newValues = [...otp];
            newValues.splice(index, pastedText.length, ...pastedText.split('').slice(0, otpLength - index));
            setOtp(newValues);
            setFocused(index + pastedText.length - 1);
        }
    };

    const TextField = styled(TextFieldUI)(({ theme }) => ({
        '.MuiFormLabel-root': {
            color: '#cccccc88',
            fontSize: '1rem',
            fontWeight: '400',
            top: '0px',
            left: '0px'
        },
        '& .MuiInputBase-input': {
            width: '50px',
            height: '50px',
            textAlign: 'center',
            fontWeight: 'bold',
            lineHeight:'50px',
            color: '#ffffff',
            borderRadius: '5px',
            fontSize: '1.1rem',
            padding: '5px 0',
        },
        '& .MuiInputBase-root': {
            '&::before': {
                content: "''",
                borderBottom: '1px solid #222222',
                borderColor: '#ffffff'
            },
            '&::after': {
                content: "''",
                borderBottom: `1px solid #ffffff`
            },
        }
    }));

    const Link = styled(LinkUI)(({ theme }) => ({
        color: '#8d8d8d',
        textDecoration: 'none',
        fontWeight: '500',
        letterSpacing:'1px',
        textShadow:'0 0 3px rgba(0,0,0,1)',
        fontSize:'1rem',
        '&:hover': {
            cursor:'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
            setFocused(index - 1);
        }
    };
    const handleResendClick = async () => {

        setDisabled(true);
        setShowTimer(true);

        await resendOtp({ dispatch, data: { email: user.email } }).then((res) => {
            setResponse(res);
        });
    };

    const handleSubmitBtn = async () => {

        await verifyOtp({ dispatch, data: { otp: otp.join(""), email: user.email } }).then((res) => {
            // Handle response
            setResponse(res);
            setShowLoginButton(res["success"]);
            if(res['success']){
                setTimeout(() => navigate('/login'), 5000)
            }
        });
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="end"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <Grid item sx={{ mb: 5 }}>
                <img src={'/images/ifund-logo-light.png'} width={150} alt={"Logo"} />
            </Grid>

            <Grid item sx={{ width: '85%', maxWidth:'400px!important' }} pb={8}>
                <Grid container direction="column" spacing={2}>
                    {!showLoginButton ? (
                    <>
                        <Grid item>
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{maxWidth:400, marginTop:3}}>
                            {otp.map((value, index) => (
                                <TextField
                                    key={index}
                                    variant="standard"
                                    color={"secondary"}
                                    size="small"
                                    value={value}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onKeyDown={(event) => handleKeyDown(event, index)}
                                    onPaste={(event) => handlePaste(event, index)}
                                    inputRef={inputRefs[index]}
                                    autoFocus={index === focused}
                                />
                            ))}
                            </Stack>
                        </Grid>

                        <Grid item>

                            {showTimer && (<Alert color={"info"}>{counter > 0 ? `Resend in ${counter} seconds` : ''}</Alert>)}

                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mt: 5 }}>

                                <Button disabled={disabled} variant={"contained"} color={"warning"} onClick={handleResendClick}>Resend</Button>

                                <Button variant={"contained"} color={"primary"} onClick={handleSubmitBtn}>Submit</Button>

                            </Stack>
                        </Grid>
                    </>
                        ) : (
                        <Grid item>
                            <Alert sx={{my:3}} color={"success"}>Your email has been verified successfully.</Alert>
                        </Grid>
                        )}

                    <Grid item container justifyContent="center">
                        <Link to={'/login'} className={'mb-3 bold d-block text-right'}>
                            Back to Login
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
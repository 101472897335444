import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";


export const clearData = () => ({
    type: CONST.CLEAR_DATA,
    payload: {}
});

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const setSelectedPermission = (payload) => ({
    type: CONST.SET_SELECTED_PERMISSION,
    payload: payload
});

export const fetchPermissionSuccess = (payload) => ({
    type: CONST.FETCH_PERMISSION_SUCCESS,
    payload: payload
});

export const fetchPermissionFailed = (payload) => ({
    type: CONST.FETCH_PERMISSION_FAILED,
    payload: payload
});

export const fetchPermission = ({dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`permission/${id}`),
        [fetchPermissionSuccess],
        [fetchPermissionFailed]
    );
};

export const fetchPermissionsSuccess = (payload) => ({
    type: CONST.FETCH_PERMISSIONS_SUCCESS,
    payload: payload
});

export const fetchPermissionsFailed = (payload) => ({
    type: CONST.FETCH_PERMISSIONS_FAILED,
    payload: payload
});

export const fetchPermissions = ({dispatch, filters}) => {

    let url = 'permission';

    if (filters && filters['all'] !== undefined && filters['all'] === true) {
        delete filters['all'];
        url = 'permission/quickList';
    }

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(url, { params: filters }),
        [fetchPermissionsSuccess],
        [fetchPermissionsFailed]
    );
};

export const addPermissionSuccess = (payload) => ({
    type: CONST.ADD_PERMISSION_SUCCESS,
    payload: payload
});

export const addPermissionFailed = (payload) => ({
    type: CONST.ADD_PERMISSION_FAILED,
    payload: payload
});

export const addPermission = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('permission', data),
        [addPermissionSuccess],
        [addPermissionFailed]
    );
};

export const updatePermissionSuccess = (payload) => ({
    type: CONST.UPDATE_PERMISSION_SUCCESS,
    payload: payload
});

export const updatePermissionFailed = (payload) => ({
    type: CONST.UPDATE_PERMISSION_FAILED,
    payload: payload
});

export const updatePermission = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`permission/${data['id']}`, data),
        [updatePermissionSuccess],
        [updatePermissionFailed]
    );
};

export const deletePermissionSuccess = (payload) => ({
    type: CONST.DELETE_PERMISSION_SUCCESS,
    payload: payload
});

export const deletePermissionFailed = (payload) => ({
    type: CONST.DELETE_PERMISSION_FAILED,
    payload: payload
});

export const deletePermission = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`permission/${data['id']}`),
        [deletePermissionSuccess],
        [deletePermissionFailed]
    );
};

import * as React from 'react';
import { Pagination as PaginationUI } from '@mui/material';
import {styled} from "@mui/material/styles";
import {useDispatch} from "react-redux";
import {fetchListings} from "../Redux/Action";

export const Pagination = ({config}) => {

    const dispatch = useDispatch();

    const Pagination = styled(PaginationUI)({
        '& .MuiPaginationItem-root': {
            color: '#000',
            '&.Mui-selected': {
                backgroundColor: '#3141a6',
                color: '#fff',
            }
        },
    })

    const handleChange = (event, value) => {
        fetchListings({dispatch, data:{...config.filters, ...config.pagination, page:value - 1}})
    }

    return (
        <Pagination
            count={config.lastPage}
            page={config.pagination.page + 1}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            size={"large"}
            defaultPage={6} boundaryCount={2}
        />
    );
}
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { RegistrationCompleteForm } from "../../index";
import {Grid} from "@mui/material";

export const InvestorCompleteRegistrationLg = () => {

    return (
        <Grid item container xs={12} sx={{ height: '80vh'}}>

            <Grid item alignSelf={"center"} xs={12} md={4}>
                <Typography color={"#ffffff"} mb={3} sx={{ fontWeight: '300', fontSize:'30px', fontFamily:'Open Sans', lineHeight: '4rem' }} variant={"h3"}>Complete Registration</Typography>
                <Typography variant={"h5"} sx={{ fontSize: '1.2rem' }} color={"#ffffff"}>What type of business are you forming?</Typography>
            </Grid>

            <Grid item container justifyContent={"center"} alignContent={"center"} xs={12} md={8} sx={{background:'#ffffff'}}>
                <RegistrationCompleteForm />
            </Grid>
        </Grid>

    );
}

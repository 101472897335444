import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const fetchProfileSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchProfileFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchProfile = async ({ dispatch }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/profile`),
        [fetchProfileSuccess],
        [fetchProfileFailed]
    );
};

export const updateProfileSuccess = (payload) => ({
    type: CONST.UPDATE_PROFILE_SUCCESS,
    payload: payload
});

export const updateProfileFailed = (payload) => ({
    type: CONST.UPDATE_PROFILE_FAILED,
    payload: payload
});

export const updateProfile = async ({ dispatch, data }) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`investor/profile`, data),
        [updateProfileSuccess],
        [updateProfileFailed]
    );
}


export const updateAccountSuccess = (payload) => ({
    type: CONST.UPDATE_PROFILE_SUCCESS,
    payload: payload
});

export const updateAccountFailed = (payload) => ({
    type: CONST.UPDATE_PROFILE_FAILED,
    payload: payload
});

export const updateAccount = async ({ dispatch, data }) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`investor/profile/account`, data),
        [updateAccountSuccess],
        [updateAccountFailed]
    );
}

export const updateBankDetailsSuccess = (payload) => ({
    type: CONST.UPDATE_BANK_DETAILS_SUCCESS,
    payload: payload
});

export const updateBankDetailsFailed = (payload) => ({
    type: CONST.UPDATE_BANK_DETAILS_FAILED,
    payload: payload
});

export const updateBankDetails = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`investor/profile/bank-details`, data),
        [updateBankDetailsSuccess],
        [updateBankDetailsFailed]
    );
}

export const updateSettingsSuccess = (payload) => ({
    type: CONST.UPDATE_SETTINGS_SUCCESS,
    payload: payload
});

export const updateSettingsFailed = (payload) => ({
    type: CONST.UPDATE_SETTINGS_FAILED,
    payload: payload
});

export const updateSettings = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`investor/profile/bank-details`, data),
        [updateSettingsSuccess],
        [updateSettingsFailed]
    );
}

export const updateAddressSuccess = (payload) => ({
    type: CONST.UPDATE_ADDRESS_SUCCESS,
    payload: payload
});

export const updateAddressFailed = (payload) => ({
    type: CONST.UPDATE_ADDRESS_FAILED,
    payload: payload
});

export const updateAddress = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`investor/profile/address`, data),
        [updateAddressSuccess],
        [updateAddressFailed]
    );
}

export const updateEmploymentSuccess = (payload) => ({
    type: CONST.UPDATE_EMPLOYMENT_SUCCESS,
    payload: payload
});

export const updateEmploymentFailed = (payload) => ({
    type: CONST.UPDATE_EMPLOYMENT_FAILED,
    payload: payload
});

export const updateEmployment = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`investor/profile/employment`, data),
        [updateEmploymentSuccess],
        [updateEmploymentFailed]
    );
}
import React, {useCallback, useState} from 'react';
import {
    Button as ButtonUI,
    Grid,
    TextField as TextFieldUI,
} from "@mui/material";
import Button from "@mui/material/Button";
import {doForgotPassword} from "../../../Administration/Users";
import { useDispatch, } from "react-redux";
import {Link as LinkUI, Link, useNavigate} from "react-router-dom";
import {isBrowser} from "react-device-detect";
import Toastr from "../../../../Components/Elements/Toastr";
import {setBlockUI} from "../../App";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ForgotPasswordForm} from "./Forms/ForgotPasswordForm";
import Alert from "@mui/material/Alert";
import {Loader} from "../../../../Components/Elements/Loader";

export const ForgotPassword = () => {

    return (
        <>
            {isBrowser ? <BrowserView /> : <MobileView /> }
        </>
    )
}

const BrowserView = () => {

    return (
        <Grid item container xs={12} sx={{ height: '80vh'}}>

            <Grid item alignSelf={"center"} xs={12} md={4} pr={4}>
                <Typography color={"#ffffff"} mb={1} sx={{ fontWeight: '300', fontSize:'30px', fontFamily:'Open Sans', lineHeight: '40px' }} variant={"h3"}>Unlock Cash Flow with Our Extensive Range of Solutions:</Typography>
                <Typography variant={"h5"} sx={{ fontSize: '1rem' }} color={"#ffffff"}>Factoring, Forfaiting, Invoice Discounting and many more…</Typography>
            </Grid>

            <Grid item container justifyContent={"center"} alignContent={"center"} xs={12} md={8} sx={{background:'#ffffff'}}>
                <ForgotPasswordForm />
            </Grid>

        </Grid>
    )
}
const MobileView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [response, setResponse] = useState({});
    const [username, setUsername] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async () => {
        dispatch(setBlockUI({open:true}));
        setDisabled(true);
        setIsLoading(true);
        await doForgotPassword({dispatch, data:{username}}).then((res) => {
            dispatch(setBlockUI({open:false}));
            setResponse(res);
            setIsLoading(false);
            if(res['success']) {
                setSuccessMessage("A password reset link has been sent to your email. Please check your email and follow the instructions to reset your password.");
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            }
        });
    };

    const handleUsernameChange = useCallback((e) => {
        setUsername(e.target.value);
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const TextField = styled(TextFieldUI)(({ theme }) => ({
        '.MuiFormLabel-root': {
            color: '#cccccc88',
            fontSize: '1rem',
            fontWeight: '400',
            top: '0px',
            left: '0px'
        },
        '& .MuiInputBase-input': {
            color: '#ffffff',
            borderRadius: '5px',
            fontSize: '1.1rem',
            padding: '5px 0',
            fontWeight: '300',
        },
        '& .MuiInputBase-root': {
            '&::before': {
                content: "''",
                borderBottom: '1px solid #222222',
                borderColor: '#ffffff'
            },
            '&::after': {
                content: "''",
                borderBottom: `1px solid #ffffff`
            },
        }
    }));

    const Button = styled(ButtonUI)(({ theme }) => ({
        color: '#ffffff',
        textShadow:'0 0 3px rgba(0,0,0,0.6)',
        borderRadius:'10px',
        background: 'linear-gradient(to bottom right, #4cc6b9 50%, #0ab5d0)',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.contrastText,
        }
    }));

    const Link = styled(LinkUI)(({ theme }) => ({
        color: '#8d8d8d',
        textDecoration: 'none',
        fontWeight: '500',
        letterSpacing:'1px',
        textShadow:'0 0 3px rgba(0,0,0,1)',
        fontSize:'1rem',
        '&:hover': {
            cursor:'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    return (
        <Grid
            container
            direction="column"
            justifyContent="end"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <Grid item sx={{ mb: 5 }}>
                <img src={'/images/ifund-logo-light.png'} width={150} alt={"Logo"} />
            </Grid>

            <Grid item sx={{ width: '85%', maxWidth:'400px!important' }} pb={8}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField
                            type="text"
                            id="username"
                            variant={"standard"}
                            color={"secondary"}
                            onBlur={handleUsernameChange}
                            defaultValue={username}
                            required
                            autoComplete="off"
                            label="Registered Email ID"
                            fullWidth
                            helperText={response?.error?.data?.errors?.username}
                        />
                    </Grid>

                    { successMessage &&
                        <Grid item>
                            <Alert severity="success">{successMessage}</Alert>
                        </Grid>
                    }

                { isLoading ?
                    <Loader  type={'linear'}/> :
                    <Grid item>
                        <Button
                            disabled={disabled}
                            color="secondary"
                            sx={{ width: '100%', mt:3, mb: 2 }}
                            className={'text-uppercase fs-5 p-2'}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Password Reset
                        </Button>
                    </Grid>
                }

                    <Grid item container justifyContent="center">
                        <Link to={'/login'} className={'mb-3 bold d-block text-right'}>
                            Back to Login
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Toastr response={response} />
        </Grid>
    )
}
import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import {doLogout} from "../../Auth";

export const fetchBusinessDocuments = (payload) => ({
    type: CONST.FETCH_BUSINESS_DOCUMENTS_SUCCESS,
    payload: payload
});

export const fetchLoanDocuments = (payload) => ({
    type: CONST.FETCH_LOAN_DOCUMENTS_SUCCESS,
    payload: payload
});



export const fetchDocumentsSuccess = (payload) => ({
     type: CONST.FETCH_DOCUMENTS_SUCCESS,
     payload: payload
});

export const fetchDocumentsFailed = (payload) => ({
    type: CONST.FETCH_DOCUMENTS_FAILED,
    payload: payload
});

export const fetchDocuments = async (dispatch, data) => {
    let res = {}
    await axiosPrivate.get('document', {params:data}).then((response) => {
        dispatch(fetchDocumentsSuccess(response));
        res['success'] = response;
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchDocumentsFailed(error?.response));
    });
    return res;
};


/****************************************
 Fetch Single Document - COMMON to all entity
 ****************************************/

export const fetchDocumentSuccess = (payload) => ({
    type: CONST.FETCH_DOCUMENT_SUCCESS,
    payload: payload
});

export const fetchDocumentFailed = (payload) => ({
    type: CONST.FETCH_DOCUMENT_FAILED,
    payload: payload
});

export const fetchDocument = async ({dispatch, id}) => {
    let res = {}
    await axiosPrivate.get('document/' + id).then((response) => {
        dispatch(fetchDocumentSuccess(response));
        res['success'] = response;
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(fetchDocumentFailed(error?.response));
    });
    return res;
};

/****************************************
 Upload Documents - COMMON to all entity
 ****************************************/
export const uploadDocumentSuccess = (payload) => {
    return {
        type: CONST.UPLOAD_DOCUMENT_SUCCESS,
        payload: payload
    }}

export const uploadDocumentFailed = (payload) => ({
    type: CONST.UPLOAD_DOCUMENT_FAILED,
    payload: payload
})
export const uploadDocument = async ({dispatch, props, file}) => {
    try {
        let payload = props.data;
        let data = new FormData();

        data.append('file', file);
        data.append('business_id', props.data.business_id);
        data.append('entity_id', props.entity_id);
        data.append('entity_name', props.entity_name);
        data.append('doc_expiry', props?.doc_expiry ?? '');
        data.append('type', props.name);
        data.append('multiple', props.multiple);
        data.append('doc_size', file.size);

        let config = {
            method: 'post',
            maxBodyLength: 3000,
            url: 'document',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'content-type': file.type,
            },
            data: data,
        };

        const response = await axiosPrivate(config);
        dispatch(uploadDocumentSuccess(response));
        payload[props.name] = response?.data?.document_id;
        props.updateFunction({dispatch, data:payload});

        return { success: true, data: response };
    } catch (error) {
        if (error?.response?.data?.message === 'The token has been blacklisted') {
            doLogout(dispatch);
        }
        dispatch(uploadDocumentFailed(error?.response));
        return { error: error.response };
    }
};

/****************************************
 Remove Documents - COMMON to all entity
 ****************************************/
export const removeDocumentSuccess = (payload) => ({
    type: CONST.REMOVE_DOCUMENT_SUCCESS,
    payload: payload
})

export const removeDocumentFailed = (payload) => ({
    type: CONST.REMOVE_DOCUMENT_FAILED,
    payload: payload
})

export const removeDocument = async ({dispatch, props, document_id}) => {
    let payload = props?.data;
    let res = {}

    await axiosPrivate.delete('document/' + document_id).then((response) => {
        res['success'] = response;
        dispatch(removeDocumentSuccess(document_id));
        payload[props.name] = null;
        props.updateFunction({dispatch, data:payload});
    }).catch((error)=>{
        res['error'] = error.response;
        if(error?.response?.data?.message === 'The token has been blacklisted'){
            doLogout(dispatch);
        }
        dispatch(removeDocumentFailed(error?.response));
    });
    return res
}
import React from 'react';
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {MetricTemplateCard} from "./MetricTemplateCard";

export const CurrentOutstandingCard = () => {

    const count = useSelector(store => store.dashboard.stats?.currentOutstanding);
    const displayedCount = useCounterEffect(0, count);

    return (
        <>
            <MetricTemplateCard unit={"AED"} title={"Current Outstanding"} displayedCount={displayedCount} link={"/current-outstanding"} linkText={"See All"} />
        </>
    );
}

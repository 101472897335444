import * as React from 'react';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchNotifications, setConfig} from "../../Redux/Action";
import {Card, CardContent, CardHeader, Chip} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment";

export const NotificationTable = (props) => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.notifications);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const filters = useSelector((store) => store.notifications?.config?.filters);

    useEffect(() => {
        fetchData();
    },[filters]);

    const fetchData = async () => {
        setIsLoading(true);
        await fetchNotifications({dispatch}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const columns = React.useMemo(
        () => [
            { field: 'type', headerName:'Type', type: 'string', flex:1, minWidth: 130,},
            { field: 'read_at', headerName:'Read', flex:1, type: 'string', minWidth: 120,
                renderCell: (params) => ( params.value === null ? <Chip label="Unread" size="small" color="error" /> : <Chip label="Read" size="small" color="success" />)
            },
            { field: 'created_at', headerName:'Created At', type: 'dateTime', flex:1, minWidth:200,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            { field: 'updated_at', headerName:'Updated At', type: 'dateTime', minWidth:200, flex:1,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            }
        ],
        [props.deleteItem, props.editItem],
    );

    return (
        <Card>
            <CardHeader title={"Notifications"} />
            <CardContent sx={{p:0}}>
                <DataGridPro
                    autoHeight={true}
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={items.list}
                    sx={{ border:'none'}}
                    rowCount={response?.success?.data?.total ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    pagination
                    loading={isLoading}
                    paginationMode="server"
                    paginationModel={filters}
                    onPaginationModelChange={(params) => dispatch(setConfig({filters: {...filters, page:params.page, pageSize: params.pageSize}}))}
                    onPageChange={(newPageNo) => dispatch(setConfig({filters: {...filters, pageNo: newPageNo}}))}
                    onPageSizeChange={(newPageSize) => dispatch(setConfig({filters: {...filters, pageSize: newPageSize}}))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'group', sort: 'asc' }],
                        },
                        pinnedColumns: {  right: ['actions'] },
                        pagination: { paginationModel: { ...filters } }
                    }}
                />
            </CardContent>
        </Card>
    );
}
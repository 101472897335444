import React from 'react'
import { Routes as Switch, Route } from 'react-router-dom'
import PrivateRoutes from './PrivateRoutes'

import { Dashboard } from "../Modules/Corporate/Dashboard";
import { Profile } from "../Modules/Administration/Profile";
import { Settings } from "../Modules/Administration/Settings";

import { Business, BusinessView } from "../Modules/Corporate/Business";
import { Invoices, InvoiceView } from "../Modules/Corporate/Invoices";
import { Customers, CustomerView } from "../Modules/Corporate/Customers";
import { Accounts, CurrentOutstanding, Overdues } from "../Modules/Corporate/Accounts";

import { Loan, LoanView } from "../Modules/Corporate/Loan";
import { ServiceInvoiceUpload } from "../Modules/Corporate/Services/Components/ServiceInvoiceUpload";
import PublicRoutes from "./PublicRoutes";
import {
    EmailVerification,
    ForgotPassword,
    ResetPassword,
    Login,
    Logout,
    Register,
    RegisterVerify
} from "../Modules/Common/Auth";
import {CurrentFunding} from "../Modules/Corporate/Loan/Components/CurrentFunding";
import {TotalFunded} from "../Modules/Corporate/Loan/Components/TotalFunded";
import {Main as Support} from "../Modules/Common/Support";

export const CorporateRoutes = () => {

    return (
        <Switch>

            <Route element={<PublicRoutes layout={'Auth'} />} >
                <Route exact path='/register' element={<Register/>}/>
                <Route exact path='/forgot-password' element={<ForgotPassword/>}/>
                <Route exact path='/reset-password/:token' element={<ResetPassword/>}/>
                <Route exact path='/verify/:token' element={<RegisterVerify/>}/>
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='/logout' element={<Logout/>}/>
                <Route exact path='/email-verification' element={<EmailVerification />}/>
                <Route exact path='/contact' element={<Support />}/>
            </Route>

            <Route element={<PrivateRoutes layout={'Business'} allowedRoles={['ADMIN','USER','CLIENT']} />} >
                <Route exact path='/' element={<Dashboard />}/>
                <Route exact path='/dashboard' element={<Dashboard />}/>
                <Route exact path='/profile' element={<Profile />}/>
                <Route exact path='/settings' element={<Settings />}/>
                <Route exact path='/business' element={<Business />}/>
                <Route exact path='/business/:business_id' element={<BusinessView />}/>
                <Route exact path='/my-business/' element={<BusinessView />}/>

                <Route exact path='/invoices' element={<Invoices />}/>
                <Route exact path='/invoices/:invoice_id' element={<InvoiceView />}/>
                <Route exact path='/customers' element={<Customers />}/>
                <Route exact path='/customers/:customer_id' element={<CustomerView />}/>

                <Route exact path='/accounts' element={<Accounts />}/>
                <Route exact path='/current-outstanding' element={<CurrentOutstanding />}/>
                <Route exact path='/overdue' element={<Overdues />}/>

                <Route exact path='/business-finance' element={<TotalFunded />}/>
                <Route exact path='/business-finance/total-funded' element={<TotalFunded />}/>
                <Route exact path='/business-finance/current-funding' element={<CurrentFunding />}/>
                <Route exact path='/business-finance/:loan_id' element={<LoanView />}/>

                <Route exact path={'/services/invoice-upload'} element={<ServiceInvoiceUpload />}/>

            </Route>

        </Switch>
    )
}

import * as React from 'react';
import { Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchTransactions} from "../Redux/Action";

export const TransactionView = () => {

    const dispatch = useDispatch();
    const filters = useSelector(state => state.transactions.config.filters);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async() => {
        await fetchTransactions({dispatch, data:{...filters}}).then((res) => {
            console.log(res)
        });
    }
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}>

            <Box sx={{ p:1, borderTop:'1px solid #ddd', backgroundColor:'#efefef'}}>
                <Typography lineHeight={4}>Transactions</Typography>
            </Box>
        </Box>
    );
}
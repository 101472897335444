import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {WpsDetailForm} from "../Forms/WpsDetailForm";
import {Grid, List, ListItem, Stack, Typography} from "@mui/material";
import { addWpsDetail, clearErrors, proceed, setSelectedWpsDetail,WpsDetailDeleteModal } from "../../index";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";

export const WpsDetailStep = () => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.wpsDetails).length > 0 ? business.wpsDetails.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addWpsDetail({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedWpsDetail(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<WpsDetailForm />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<WpsDetailDeleteModal />, open:true}));
        dispatch(setSelectedWpsDetail(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'salary_month', headerName:'Salary Month', type: 'string', flex:1, minWidth: 120},
            { field: 'no_of_staffs', headerName:'No of Employees', type: 'string', flex:1, minWidth:120 },
            { field: 'total_salary', headerName:'Total Salary ', type: 'number', flex:1, minWidth:140, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'amount_paid', headerName:'Salary Paid', type: 'number',  flex:1, minWidth:140, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'payment_date', headerName:'Payment Date', type: 'date', flex:1, minWidth: 140, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>

            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items || []} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] },
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add WPS Details</Button>
                    </Grid>
                    { items && items.length === 0 &&
                        <Grid item xs={12}>
                            <Instructions />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
        <Typography variant={"h3"}>WPS Details</Typography>
        <Typography variant={"body1"}>
            Please fill in the WPS details for the previous 3 months. To complete this section, keep the following documents ready for attachment
        </Typography>
        <List>
            <ListItem>WPS Statement</ListItem>
            <ListItem>MOL List</ListItem>
        </List>
    </InfoBox>)
}
import React, {useEffect} from 'react'
import {CardContent, CardHeader, Grid} from "@mui/material";
import {Breadcrumbs, Panel} from "../../../../Components";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {DisbursementCollectionChart} from "./Charts/DisbursementCollectionChart";
import {setConfig} from "../../Accounts";
import dayjs from "dayjs";
import {FundStatementTable} from "./Tables/FundStatementTable";
import {DateRangeSelectorPopover} from "./Popover/DateRangeSelectorPopover";

export const FundStatement = () => {

    return (
        <>
            <Grid container spacing={2} elevated={3}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/FUND_STATEMENT'} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <DisbursementCollectionChart />
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <CardHeader
                            title={"Disbursements & Collections"}
                        />
                        <CardContent>
                            <FundStatementTable />
                        </CardContent>
                    </Panel>
                </Grid>
            </Grid>
        </>
    );
}

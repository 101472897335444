import * as React from 'react';
import {Badge as BadgeUI, Tooltip} from "@mui/material";
import {styled} from "@mui/material/styles";
export const StatusIndicator = ({params}) => {

    const Badge = styled(BadgeUI)({
        border: 0,
        borderRadius: '8px!important',
        padding: '0px!important',
        height:'5px!important',
        minWidth:10,
    });

    const status = {
        'PENDING':'warning',
        'FUNDED':'success',
        'CANCELLED':'secondary',
        'ACTIVE':'primary',
        'RETURNED':'warning',
        'DELETED':'secondary',
    }

    return (
        <>
            <Tooltip title={params.value} arrow>
                <Badge badgeContent={""} color={status[params.value]} />
            </Tooltip>
        </>
    );
}
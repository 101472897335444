import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {OtherBorrowingForm} from "../Forms/OtherBorrowingForm";
import {Card, CardContent, CardHeader} from "@mui/material";
import {
    addOtherBorrowing,
    clearErrors,
    proceed,
    setSelectedOtherBorrowing,
    OtherBorrowingDeleteModal
} from "../../index";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect} from "react";
import {currencyFormatter} from "../../../../../Setup/Config";

export const OtherBorrowingCard = (props) => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    let items = Object.keys(business.otherBorrowings).length > 0 ? business.otherBorrowings.list.filter(i => i.is_draft !== 1) : [];
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addOtherBorrowing({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedOtherBorrowing(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<OtherBorrowingForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<OtherBorrowingDeleteModal />, open:true}));
        dispatch(setSelectedOtherBorrowing(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status) && auth.user.role_type === 'CLIENT') {
                return ['add-business-other-borrowings'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-other-borrowings') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
        },[business.status, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ];
            }

            return [];

        },[editable]
    );

    const columns = React.useMemo(
        () => [
            { field: 'lender_name', headerName:'Institution Name', type: 'string',flex:1, minWidth:220},
            { field: 'currency', headerName:'Currency', type: 'string',flex:1, minWidth:80 },
            { field: 'limit', headerName:'Borrowing Limit', type: 'number', flex:1, minWidth:130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'outstanding', headerName:'Outstanding', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'overdue', headerName:'Overdue', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'emi', headerName:'EMI', type: 'string', flex:1, minWidth:80, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'borrowing_start_date', headerName:'Borrowing Start Date', type: 'string', flex:1, minWidth:160, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"), },
            { field: 'borrowing_end_date', headerName:'Borrowing End Date', type: 'string', flex:1, minWidth:160, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"), },
            { field: 'remarks', headerName:'Remarks', type: 'string', flex:1, minWidth:220 },
            { field: 'actions', flex:1, minWidth: 120, type: 'actions', headerName:'Action', getActions: (params) => actions(params)},
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Other Borrowings'}
                    action={editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button>}
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={items || []}
                            sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        actions: editable(),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}

import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { styled, useTheme } from "@mui/material/styles";
import DrawerHeader from "./DrawerHeader";
import { Divider, List, ListItem as ListItemUI, ListItemButton, ListItemIcon, ListItemText, Drawer as MuiDrawer, IconButton } from "@mui/material";
import {
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
    Shield as ShieldIcon,
    Article as ArticleIcon,
    People as PeopleIcon,
    Group as GroupIcon,
    Payments as PaymentsIcon,
    Dashboard as DashboardIcon,
    Store as StoreIcon,
    Power as PowerIcon,
    Hive as HiveIcon,
    AttachMoney as AttachMoneyIcon,
    Notifications as NotificationsIcon,
    Settings as SettingsIcon,
    RequestQuote as RequestQuoteIcon,
    ReceiptLong as ReceiptLongIcon,
    Policy as PolicyIcon,
    Logout as LogoutIcon,
    Assessment as AssessmentIcon,
    AddCard as AddCardIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon
} from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../../Modules/Common/Auth";
import { setActiveMenu, setLeftDrawer } from "../../Modules/Common/App";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.down('sm')]: {
        width: theme.spacing(0),
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const ListItem = styled(ListItemUI)(({ theme, selected }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiListItemText-root': {
            color: theme.palette.primary.light,
        },
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.light,
        }
    },
    '& .MuiListItemText-root': {
        color: selected ? theme.palette.primary.light : theme.palette.secondary.dark,
    },

    '& .MuiListItem-root:hover': {
        '& .MuiListItemText-root': {
            color: theme.palette.primary.main,
        },
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
        },
    }
}));

const menuItems = [
    { permission: 'dashboard-menu', name: 'Dashboard', icon: <DashboardIcon />, link: '/dashboard' },
    { permission: 'business-menu', name: 'Business', icon: <StoreIcon />, link: '/business' },
    { permission: 'invoices-menu', name: 'Invoices', icon: <ArticleIcon />, link: '/invoices' },
    { permission: 'customers-menu', name: 'Customers', icon: <PeopleIcon />, link: '/customers' },
    { permission: 'fund-statement-menu', name: 'Fund Statement', icon: <AssessmentIcon />, link: '/fund-statement' },
    { permission: 'funding-request-menu', name: 'Funding Request', icon: <AddCardIcon />, link: '/funding-requests' },
    { permission: 'business-finance-menu', name: 'Business Finance', icon: <PaymentsIcon />, link: '/business-finance' },
    { permission: 'accounts-menu', name: 'Accounts', icon: <AttachMoneyIcon />, link: '/accounts' },
    { permission: 'requests-menu', name: 'Requests', icon: <RequestQuoteIcon />, link: '/requests' },
    { permission: 'transactions-menu', name: 'Transactions', icon: <ReceiptLongIcon />, link: '/transactions' },
    { permission: 'listing-menu', name: 'Listings', icon: <HiveIcon />, link: '/listings' },
    { permission: 'wallet-menu', name: 'Wallets', icon: <AccountBalanceWalletIcon />, link: '/wallets' },
    { permission: 'notification-menu', name: 'Notification', icon: <NotificationsIcon />, link: '/notifications' },
    { permission: 'setting-menu', name: 'Settings', icon: <SettingsIcon />, link: '/settings' },
    { permission: 'permission-menu', name: 'Permissions', icon: <PowerIcon />, link: '/permissions' },
    { permission: 'role-menu', name: 'Roles', icon: <ShieldIcon />, link: '/roles' },
    { permission: 'user-menu', name: 'Users', icon: <GroupIcon />, link: '/users' },
    { permission: 'monitor-menu', name: 'Monitor', icon: <PolicyIcon />, link: '/monitor' },
];

const LeftDrawer = () => {
    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const app = useSelector((store) => store.app);
    const theme = useTheme();
    const navigate = useNavigate();

    const handleLogout = () => {
        doLogout(dispatch).then(() => {
            navigate('/login');
        });
    };

    const setMenu = (menu) => {
        dispatch(setActiveMenu(menu));
    };

    const open = app.leftDrawer.open;

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={() => dispatch(setLeftDrawer({ component: null, open: false }))}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%' }}>

                {menuItems.map((item) => (
                    (permissions.includes(item.permission) || auth.user.is_super_admin) ? (
                        <ListItem divider key={item.name} disablePadding sx={{ display: 'block' }} component={Link} to={item.link}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText color={theme.secondary} primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>)
                        :''
                    )
                )}
                <ListItem key='logout' disablePadding sx={{ display: 'block', marginTop: 'auto' }} onClick={handleLogout}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default LeftDrawer;

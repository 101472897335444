import React from 'react';
import { isBrowser } from 'react-device-detect';
import {
    InvestorCompleteRegistrationLg,
    InvestorCompleteRegistrationSm,
} from '../index';

export const RegisterComplete = () => {

    return isBrowser ? <InvestorCompleteRegistrationLg /> : <InvestorCompleteRegistrationSm />;
};


import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    error: [],
    message:[],
    code: "",
    config:{
        from:0,
        to:0,
        lastPage:1,
        rowCount:0,
        pagination:{
            page:0,
            pageSize:3,
        },
        filters:{
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_SELECTED_SETTING:
            return {
                ...state,
                selected: state.list.filter(item => item.setting_id === action.payload)[0],
            }
        case CONST.FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                list:action.payload.data,
                error: [],
                message: ["Settings loaded successfully"]
            }
        case CONST.FETCH_SETTINGS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }

        case CONST.UPDATE_SETTING_SUCCESS:
            return {
                ...state,
                list: action.payload.data,
                error: [],
                message: ["Settings updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_SETTING_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }

        default:
            return state;
    }
}

export default Reducer ;
import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchActivity, setConfig} from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment/moment";
import Typography from "@mui/material/Typography";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";

export const ActivityTable = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.monitor);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const filters = useSelector((store) => store.monitor.config.filters);
    const pagination = useDeepEqualSelector((store) => store.monitor.config.pagination);

    useEffect(() => {
        fetchData();
    },[pagination]);

    const fetchData = async () => {

        setIsLoading(true);
        await fetchActivity({dispatch, filters:{...filters, ...pagination}}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const columns = React.useMemo(
        () => [
            { field: 'created_at', headerName: 'Created At', type: 'dateTime', flex: 1, minWidth: 150,
                valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY HH:mm:ss")
            },
            { field: 'user_id', headerName: 'User', type: 'string', flex: 1, minWidth: 100,
                valueGetter: ({ row }) => row.user?.first_name + ' ' + row.user?.last_name
            },
            { field: 'path', headerName: 'Path', type: 'string', flex: 1, minWidth: 200 },
            { field: 'status_code', headerName: 'Status', type: 'number', flex: 1, minWidth: 100,
                renderCell: (params) => {
                    const status = {
                        500:'error',
                        400:'warning',
                        200:'success',
                    }
                    return (
                        <StatusBall
                            variant="contained"
                            sx={{fontWeight:'normal'}}
                            label={params.value}
                            color={status[params.value]}
                        />
                    );
                }
            },
            { field: 'method', headerName: 'Method', type: 'string', flex: 1, minWidth: 100 },
            { field: 'payload', headerName: 'Payload', type: 'string', flex: 1, minWidth: 100 },
            { field: 'ip_address', headerName: 'IP Address', type: 'string', flex: 1, minWidth: 100 },
            { field: 'user_agent', headerName: 'User Agent', type: 'string', flex: 1, minWidth: 150 },
            { field: 'response_time', headerName: 'Response Time (s)', type: 'number', flex: 1, minWidth: 100 },
            { field: 'memory_usage', headerName: 'Mem Usage (bytes)', type: 'number', flex: 1, minWidth: 150,
                valueFormatter: ({ value }) => value && Math.round((value/1024/1024 + Number.EPSILON) * 100) / 100 + ' MB'
            },
        ],
        [],
    );

    return (
        <DataGridPro
            autoHeight
            getRowId={(row) => row.id}
            columns={columns}
            rows={ items.list ?? [] }
            sx={{ border:'none'}}
            pagination
            paginationMode="server"
            paginationModel={pagination}
            rowCount={items?.config?.rowCount ?? 0}
            pageSizeOptions={[5, 10, 25, 100]}
            loading={isLoading}
            onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
            initialState={{
                columns:{
                    columnVisibilityModel:{
                        //user_id:false,
                    }
                },
                sorting: {
                    sortModel: [{ field: 'group', sort: 'asc' }],
                },
                pinnedColumns: {  right: ['actions'] },
                pagination: { paginationModel: { ...pagination } }
            }}
        />
    );
}

import * as React from 'react';
import {Box, Card, CardContent, CardHeader, Grid} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {TransactionTable} from "../index";
import {TransactionFilterMenu} from "./Menu/TransactionFilterMenu";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setConfig} from "../Redux/Action";
import dayjs from "dayjs";

export const Transactions = () => {

    const dispatch = useDispatch();
    const filters = useSelector((store) => store.transactions?.config?.filters);

    useEffect(() => {
        dispatch(setConfig({filters: {...filters, page:0, pageSize:10, type:[], due_date: dayjs().format('YYYY-MM-DD'), status:[]}}));
    },[]);

    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/TRANSACTIONS'} sx={{display:'inline-block'}} />
                        <MemoizedAccountFilterMenu2 setResponse={setResponse} setIsLoading={setIsLoading} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Transactions" />
                        <CardContent sx={{p:0}}>
                            <TransactionTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

const MemoizedAccountFilterMenu2 = React.memo(TransactionFilterMenu);
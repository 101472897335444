import * as React from 'react';
import {
    Card,
    CardContent,
    CardHeader, FormControl,
    IconButton, InputLabel,
    Popover as PopoverUI, Select, Stack, TextField
} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {markAllReadNotification} from "../../../../Common/Auth";
import {Datepicker} from "../../../../../Components/Elements/Datepicker2";
import MenuItem from "@mui/material/MenuItem";
import {useEffect} from "react";
import {fetchTransactions, setConfig} from "../../Redux/Action";

export const TransactionFilterMenu = ({setIsLoading, setResponse}) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filters = useSelector((store) => store.transactions?.config?.filters);

    const isInitialLoad = React.useRef(true);

    useEffect(() => {
        // Skip initial load
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
            return;
        }
        loadData();
    }, [filters]);

    const loadData = async () => {
        setIsLoading(true);
        await fetchTransactions({dispatch, filters}).then((res) => {
            setResponse(res);
            setIsLoading(false);
        });
    }

    const handleChange = (e) => {
        dispatch(setConfig({filters: {...filters, [e.target.name]: e.target.value}}));
    }
    const handleClearFilter = () => {
        dispatch(setConfig({filters: {...filters, business_name:'', type:[], status:[], listing_id:'', created_at:''}}));
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const Popover = styled(PopoverUI)({
        '& .MuiPaper-root':{
            boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.2)',
            [theme.breakpoints.down('md')]: {
                minWidth: '100%', // Minimum width for desktop view
                minHeight:'100%',
                left:'0!important',
                top:'0!important',
                height:'100%',
            },
        }
    });

    const PopoverCard = styled(Card)({
        width: '100%',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root':{
            flex: '1',
            //overflowY:'auto',
            //maxHeight:'300px',
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'inherit',
            },
            '& .MuiListItemAvatar-root':{
                minWidth: 'auto',
                marginRight: '15px',
                '& svg':{
                    width:'0.8rem'
                }
            },
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 300,
        },
    });

    return (
        <>
            <Button onClick={handleClick} variant={"contained"}>Filter</Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PopoverCard>
                    <CardHeader title={"Advance Filter"} titleTypographyProps={{fontWeight:'bold', fontSize:'1rem'}}
                                action={<IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={handleClose}
                                            aria-label="close"
                                            size={'small'}
                                            sx={{ position: 'absolute', top: '8px', right: '15px' }}
                                        >
                                            <CloseIcon fontSize={'0.5rem'} />
                                        </IconButton>}>
                    </CardHeader>
                    <CardContent>
                        <Stack spacing={2}>
                            <TextField
                                label="Business Name"
                                name="business_name"
                                defaultValue={filters?.business_name}
                                inputlabelprops={{ shrink: true }}
                                onBlur={handleChange} />

                            <TextField
                                label="Refererence Code"
                                name="listing_id"
                                defaultValue={filters?.listing_id}
                                inputlabelprops={{ shrink: true }}
                                onBlur={handleChange} />

                            <Datepicker fullWidth value={filters?.created_at} handleChange={handleChange} label={"Transaction Date"} name="created_at" />

                            <FormControl fullWidth>
                                <InputLabel id="type">Type</InputLabel>
                                <Select
                                    value={filters?.type ?? []}
                                    name="status"
                                    MenuProps={{
                                        style: {zIndex: 35001}
                                    }}
                                    multiple
                                    fullWidth
                                    label="Type"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <MenuItem key="Investment" value="INVESTMENT">Investment</MenuItem>
                                    <MenuItem key="Transfer" value="TRANSFER">Transfer</MenuItem>
                                    <MenuItem key="Profit" value="PROFIT">Profit</MenuItem>
                                    <MenuItem key="Repayment" value="REPAYMENT">Repayment</MenuItem>
                                </Select>
                            </FormControl>
                            {/*<Button onClick={() => loadData()} variant={"contained"} size={"large"}>Search</Button>*/}
                            <Button  onClick={() => handleClearFilter()} variant={"contained"} size={"large"} color={"warning"}>Reset</Button>
                        </Stack>
                    </CardContent>
                </PopoverCard>
            </Popover>
        </>
    );
}


import React, {useEffect, useState} from "react";
import { Card, CardActions, CardContent, CardHeader, FormControl, InputLabel, Select, Stack, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import { updateInvoice } from "../../index";
import Toastr from "../../../../../Components/Elements/Toastr";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import {useLocation, useNavigate} from "react-router-dom";
import {addService} from "../../../Services";
import {setBlockUI} from "../../../../Common/App";

export const ServiceInvoiceForm = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const business = useSelector(state => state.business);
    const invoices = useSelector(state => state.invoices);
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);

    useEffect(()=>{
        dispatch(setBlockUI({open:false}));
        setData({
            'invoice_id': invoices.selected?.invoice_id,
            'business_id': business.selected.business_id,
            'customer_id': business.customers?.selected?.customer_id,
            'service_type': location?.state?.data?.service_type,
            'purpose': location?.state?.data?.service_type,
        });
    },[]);

    const handleChange = (e) => {

        setDisabled(false);
        setData(data => ({
            ...data,
            'invoice_id': invoices.selected?.invoice_id,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmitRequest = async () => {

        setDisabled(true);
        data['status'] = 'PENDING';
        dispatch(setBlockUI({open:true}));

        // after creating service it patches invoice
        await addService({dispatch, data}).then((res) => {
            setResponse(res);
            dispatch(setBlockUI({open:false}));
            if(res['success']){

                navigate('/dashboard');
            }
        });
    };

    return(
        <>
            { (data !== undefined && Object.keys(data).length > 0) &&
            <Card>
                <CardHeader title={'Invoice Form'} />
                <CardContent>
                    <Stack sx={{pt:1}} spacing={3}>
                        <TextField
                            fullWidth
                            label="Purchase Order No"
                            name="purchase_order_no"
                            defaultValue={data?.purchase_order_no}
                            inputlabelprops={{ shrink: true }}
                            helperText={invoices?.error?.purchase_order_no}
                            onBlur={handleChange} />

                        <MuiDatepicker setDisabled={setDisabled} label={"Purchase Order Date"} name="purchase_order_date" data={data} state={setData} />

                        <TextField
                            fullWidth
                            label="Invoice No"
                            name="invoice_no"
                            defaultValue={data?.invoice_no}
                            inputlabelprops={{ shrink: true }}
                            helperText={invoices?.error?.invoice_no}
                            onBlur={handleChange} />

                        <MuiDatepicker setDisabled={setDisabled} label={"Invoice Date"} name="invoice_date" data={data} state={setData} />

                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="label-currency">Currency</InputLabel>
                            <Select
                                defaultValue={data?.currency || ""}
                                name="currency"
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                fullWidth
                                label="Currency"
                                onChange={(e) => handleChange(e)}
                                helperText={invoices?.error?.currency}
                            >
                                <MenuItem key={"AED"} value={'AED'}>AED</MenuItem>
                                <MenuItem key={"USD"} value={'USD'}>USD</MenuItem>
                                <MenuItem key={"EUR"} value={'EUR'}>EUR</MenuItem>
                                <MenuItem key={"GBP"} value={'GBP'}>GBP</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            label="Invoice Amount"
                            name="invoice_amount"
                            defaultValue={data?.invoice_amount}
                            inputlabelprops={{ shrink: true }}
                            helperText={invoices?.error?.invoice_amount}
                            onBlur={handleChange} />

                        <TextField
                            fullWidth
                            label="Delivery Note No"
                            name="delivery_note_no"
                            defaultValue={data?.delivery_note_no}
                            inputlabelprops={{ shrink: true }}
                            helperText={invoices?.error?.delivery_note_no}
                            onBlur={handleChange} />

                        <AttachDoc setResponse={setResponse} updateFunction={updateInvoice} allowedFileType={['pdf']} onBlur={handleChange} label={"Delivery Note"} entity_name={"invoices"} entity_id={data?.invoice_id} name="delivery_note_doc" data={data} state={setData} />

                        <MuiDatepicker setDisabled={setDisabled} label={"Delivery Date"} name="delivery_date" data={data} state={setData} />

                        <MuiDatepicker setDisabled={setDisabled} label={"Invoice Acceptance Date"} name="invoice_acceptance_date" data={data} state={setData} />

                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="label-office">Incoterms</InputLabel>
                            <Select
                                defaultValue={data?.inco_terms || "DAP"}
                                name="inco_terms"
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                fullWidth
                                label="Payment Terms"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem key={"EXW"} value={'EXW'}>Ex Works</MenuItem>
                                <MenuItem key={"FCA"} value={'FCA'}>Free Carrier</MenuItem>
                                <MenuItem key={"CPT"} value={'CPT'}>Carriage Paid to</MenuItem>
                                <MenuItem key={"CIP"} value={'CIP'}>Carriage and Insurance Paid To</MenuItem>
                                <MenuItem key={"DAP"} value={'DAP'}>Delivered at Place</MenuItem>
                                <MenuItem key={"DPU"} value={'DPU'}>Delivered at Place Unloaded</MenuItem>
                                <MenuItem key={"DDP"} value={'DDP'}>Delivered Duty Paid</MenuItem>
                                <MenuItem key={"FAS"} value={'FAS'}>Free Alongside Ship</MenuItem>
                                <MenuItem key={"FOB"} value={'FOB'}>Free on Board</MenuItem>
                                <MenuItem key={"CFR"} value={'CFR'}>Cost and Freight</MenuItem>
                                <MenuItem key={"CIF"} value={'CIF'}>Cost Insurance and Freight</MenuItem>
                            </Select>
                        </FormControl>

                        <SelectCountry error={false} default={"ARE"} label={"Origin"} name={"origin"} data={data} state={setData} />

                        <SelectCountry error={false} default={"ARE"} label={"Destination"} name={"destination"} data={data} state={setData} />

                        <AttachDoc setResponse={setResponse} updateFunction={updateInvoice} allowedFileType={['pdf']} onBlur={handleChange} label={"Purchase Order Doc"} entity_name={"invoices"} entity_id={data?.invoice_id} name="purchase_order_doc" data={data} state={setData} />

                        <AttachDoc setResponse={setResponse} updateFunction={updateInvoice} allowedFileType={['pdf']} onBlur={handleChange} label={"Invoice Copy Doc"} entity_name={"invoices"} entity_id={data?.invoice_id} name="invoice_doc" data={data} state={setData} />

                    </Stack>
                </CardContent>
                <CardActions sx={{p:2, justifyContent:'right'}}>
                    <Button variant={"outlined"}>Cancel</Button>
                    <Button disabled={disabled} variant={"contained"} onClick={ handleSubmitRequest }>Request</Button>
                </CardActions>
            </Card>
            }
            <Toastr response={response} />
        </>
    )
}
import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    schedule:[],
    error: [],
    proceed: false,
    code: "",
    config:{
        from:0,
        to:0,
        lastPage:1,
        rowCount:0,
        pagination:{
            page:0,
            pageSize:10,
        },
        filters:{
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    const schedule = action?.payload?.data?.schedule || [];
    delete action.payload?.data?.schedule; //remove the key documents

    switch (action.type) {

        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.CLEAR_PROCEED_STATE:
            return {
                ...state,
                proceed: false,
                error: [],
                code: 200
            }
        case CONST.CLEAR_ERROR_STATE:
            return {
                ...state,
                error: [],
                code: 200,
            }
        case CONST.SET_SELECTED_LOAN:
            return {
                ...state,
                selected: action.payload === null ? {} : state.list.filter(item => item.loan_id === action.payload)[0],
                error: [],
                code: 200
            }
        case CONST.FETCH_LOAN_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                schedule: schedule,
                error: [],
                code: action.payload.status,
                message: ["Loan loaded successfully"]
            }
        case CONST.FETCH_LOAN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code: action.payload.status,
            }
        case CONST.FETCH_LOANS_SUCCESS:
            return {
                ...state,
                list: action.payload.data.data,
                error: [],
                config:{
                    ...state.config,
                    from:action.payload.data.from,
                    to:action.payload.data.to,
                    lastPage:action.payload.data.last_page,
                    rowCount:action.payload.data.total,
                    pagination:{
                        ...state.config.pagination,
                        page:action.payload.data.current_page - 1,
                        pageSize:action.payload.data.per_page,
                    },
                },
                message: ["Loans loaded successfully"]
            }
        case CONST.FETCH_LOANS_FAILED:
            return {
                ...state,
                error: [],
                message: [action.payload]
            }
        case CONST.ADD_LOAN_SUCCESS:

            return {
                ...state,
                list: [...state.list, action.payload.data],
                selected: action.payload.data,
                schedule: schedule,
                error: [],
                proceed: true,
                message: ["Loan added successfully"],
                code: action.payload.status,
            }
        case CONST.ADD_LOAN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                selected: {},
                message: [action.payload.data.message],
                code: action.payload.status,
            }
        case CONST.UPDATE_LOAN_SUCCESS:
            const indexUI = state.list.findIndex(object => object.loan_id === action.payload.data.loan_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexUI), action.payload.data, ...state.list.slice(indexUI + 1)],
                selected: action.payload.data,
                schedule: schedule,
                error: [],
                message: ["Loan updated successfully"],
                code: action.payload.status,
            }
        case CONST.UPDATE_LOAN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code: action.payload.status,
            }
        case CONST.VERIFY_LOAN_SUCCESS:
            const indexRD = state.list.findIndex(object => object.loan_id === action.payload.data.loan_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexRD), action.payload.data, ...state.list.slice(indexRD + 1)],
                selected: action.payload.data,
                schedule: schedule,
                error: [],
                message: ["Loan verified successfully"],
                code: action.payload.status,
            }
        case CONST.VERIFY_LOAN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code: action.payload.status,
            }
        case CONST.DOCUMENT_LOAN_SUCCESS:
            const indexD = state.list.findIndex(object => object.loan_id === action.payload.data.loan_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexD), action.payload.data, ...state.list.slice(indexD + 1)],
                selected: action.payload.data,
                schedule: schedule,
                error: [],
                message: ["Loan documentation completed successfully"],
                code: action.payload.status,
            }
        case CONST.DOCUMENT_LOAN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code: action.payload.status,
            }
        case CONST.DISBURSEMENT_SUCCESS:
            const indexDBS = state.list.findIndex(object => object.loan_id === action.payload.data.loan_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexDBS), action.payload.data, ...state.list.slice(indexDBS + 1)],
                selected: action.payload.data,
                schedule: schedule,
                error: [],
                message: ["Loan disbursed successfully"],
                code: action.payload.status,
            }
        case CONST.DISBURSEMENT_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code: action.payload.status,
            }
        case CONST.DELETE_LOAN_SUCCESS:
            const indexdi = state.list.findIndex(o => o.loan_id === action.payload.data);
            return {
                ...state,
                list: [...state.list.slice(0, indexdi), ...state.list.slice(indexdi + 1)],
                selected: {},
                error: [],
                proceed: true,
                message: ["Loan deleted successfully"],
                code: action.payload.status,
            }
        case CONST.DELETE_LOAN_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code: action.payload.status,
            }


        case CONST.UPDATE_LOAN_SCHEDULE_SUCCESS:
            const indexLSU = state.schedule.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                schedule: [...state.schedule.slice(0, indexLSU), action.payload.data, ...state.schedule.slice(indexLSU + 1)],
                error: [],
                message: ["Loan Schedule updated successfully"],
                code: action.payload.status,
            }
        case CONST.UPDATE_LOAN_SCHEDULE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code: action.payload.status,
            }

        default:
            return state;
    }
}

export default Reducer;
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogTitle, DialogContent, DialogActions, Button, Stack, TextField } from '@mui/material';
import { setModal } from '../../../../Common/App';
import { updateBankDetails } from '../../Redux/Action';

export const SupportingDocumentBankDetailsModal = ({ formData, setEditMode }) => {
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(false);
    const [data, setData] = useState({});
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        setData(formData);
    }, [formData]);

    const handleChange = (e) => {
        setData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleImageUpload = (file) => {
        setImageFile(file);
    };

    const handleProceedBtn = async () => {
        // if (!imageFile) {
        //     console.log('Please select an image file.');
        //     return;
        // }
        //
        // const formData = new FormData();
        // formData.append('image', imageFile); // Append the image file to FormData
        //
        // // Append other form data to FormData
        // Object.entries(data).forEach(([key, value]) => {
        //     formData.append(key, value);
        // });
        console.log(data)
        await updateBankDetails({ dispatch, data }).then((res) => {
            if (res['success']) {
                setDisabled(false);
                setEditMode(false);
            }
        });
    };

    return (
        <>
            <DialogTitle>Upload Supporting Document</DialogTitle>
            <DialogContent sx={{ paddingTop: '20px!important', paddingBottom: '0!important' }}>
                <Stack spacing={2}>
                    <TextField
                        name="supporting_doc"
                        label="Supporting Document"
                        variant="outlined"
                        fullWidth
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleImageUpload(e.target.files[0])} // Capture selected file
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button onClick={() => dispatch(setModal({ component: null, open: false }))}>Cancel</Button>
                <Button disabled={disabled} variant="contained" color="primary" onClick={handleProceedBtn}>
                    Proceed
                </Button>
            </DialogActions>
        </>
    );
};

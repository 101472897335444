import * as React from 'react';
import {
    CardContent,
    CardHeader,
    Grid
} from "@mui/material";
import { useSelector} from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {currencyFormatter} from "../../../../../Setup/Config";
import moment from "moment/moment";
import {Panel, PanelList} from "../../../../../Components";

export const LoanDetailsPanel = () => {

    const auth = useSelector(state => state.auth);
    const loans = useSelector(state => state.loans);
    const permissions = useSelector(state => state.app.permissions);

    return(
        <>
            { loans !== undefined &&
            <Panel sx={{mb:2}}>
                <CardHeader title={"Request Details"} titleTypographyProps={{variant:'subtitle1' }}  />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <PanelList sx={{p:0}}>
                                <ListItem>
                                    <ListItemText primary={"Request No"} secondary={loans.selected.loan_no} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Amount"} secondary={currencyFormatter.format(loans.selected.amount)} />
                                </ListItem>
                                {auth.user.role_type !== 'CLIENT' &&
                                    <ListItem>
                                        <ListItemText primary={"Load Amount"} secondary={loans.selected.load_amount ?? '-'} />
                                    </ListItem>
                                }
                                {auth.user.role_type !== 'CLIENT' &&
                                    <ListItem>
                                        <ListItemText primary={"Overdue Discount"} secondary={loans.selected.overdue_discount !== null ? loans.selected.overdue_discount + '%' : '-'} />
                                    </ListItem>
                                }
                                <ListItem>
                                    <ListItemText primary={"Discounting / Forfaiting Fee"} secondary={loans.selected.profit_rate !== null ? loans.selected.profit_rate + '%' : '-'} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Late Payment Fee Rate"} secondary={loans.selected.late_payment_fee_rate !== null ? loans.selected?.late_payment_fee_rate ?? 0 + '%' : '-'} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Late Payment Fee"} secondary={loans.selected.late_payment_fee !== null ? loans.selected?.late_payment_fee ?? 0 : '-'} />
                                </ListItem>
                                { ['show-loan-late-payment-fee-calculation'].some(i => permissions.includes(i)) &&
                                <ListItem>
                                    <ListItemText primary={"Late Payment Fee Calculation"} secondary={loans.selected.late_payment_fee_calculation !== null ? (loans.selected?.late_payment_fee_calculation === 0 ? "Full Amount" : "Remaining Balance" ) : '-'} />
                                </ListItem>
                                }
                                <ListItem>
                                    <ListItemText primary={"Start Date"} secondary={moment(loans.selected.start_date).format("DD/MM/YYYY")} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"End Date"} secondary={moment(loans.selected.end_date).format("DD/MM/YYYY")} />
                                </ListItem>
                                {auth.user.role_type !== 'CLIENT' &&
                                    <ListItem>
                                        <ListItemText primary={"Assigned To"} secondary={loans.selected.assigned_to?.first_name ?? '-'} />
                                    </ListItem>
                                }
                                <ListItem>
                                    <ListItemText primary={"Status"} secondary={loans.selected.status?.name } />
                                </ListItem>
                            </PanelList>
                        </Grid>
                    </Grid>
                </CardContent>
            </Panel>
            }
        </>
    )
}
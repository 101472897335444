import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { doLogout } from "../../../Common/Auth";
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';
import {updateExpiredDocumentSuccess} from "../../Dashboard";

/****************************************
 SET SELECTED WPS_DETAIL
 ****************************************/
export const setSelectedWpsDetail = (payload) => ({
    type: CONST.SET_SELECTED_WPS_DETAIL,
    payload: payload
});

/****************************************
 FETCH WPS_DETAIL
 ****************************************/
export const fetchWpsDetailsSuccess = (payload) => ({
    type: CONST.FETCH_WPS_DETAILS_SUCCESS,
    payload: payload
});

export const fetchWpsDetailsFailed = (payload) => ({
    type: CONST.FETCH_WPS_DETAILS_FAILED,
    payload: payload
});

export const fetchWpsDetails = async ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/wpsDetail`),
        [fetchWpsDetailsSuccess],
        [fetchWpsDetailsFailed]
    );
};

/****************************************
 ADD Business WPS_DETAIL
 ****************************************/
export const addWpsDetailSuccess = (payload) => ({
    type: CONST.ADD_WPS_DETAIL_SUCCESS,
    payload: payload
});

export const addWpsDetailFailed = (payload) => ({
    type: CONST.ADD_WPS_DETAIL_FAILED,
    payload: payload
});

export const addWpsDetail = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/wpsDetail', data),
        [addWpsDetailSuccess],
        [addWpsDetailFailed]
    );
};

/****************************************
 * ADD WPS Details with Document
 ****************************************/
export const addWpsDetailWithDocument = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('task/add-wps-detail', data),
        [updateExpiredDocumentSuccess],
        []
    );
}

/****************************************
 Update Business WPS_DETAIL
 ****************************************/
export const updateWpsDetailSuccess = (payload) => ({
    type: CONST.UPDATE_WPS_DETAIL_SUCCESS,
    payload: payload
});

export const updateWpsDetailFailed = (payload) => ({
    type: CONST.UPDATE_WPS_DETAIL_FAILED,
    payload: payload
});

export const updateWpsDetail = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/wpsDetail/${data.id}`, data),
        [updateWpsDetailSuccess],
        [updateWpsDetailFailed]
    );
};

/****************************************
 DELETE Business WPS_DETAIL
 ****************************************/
export const deleteWpsDetailSuccess = (payload) => ({
    type: CONST.DELETE_WPS_DETAIL_SUCCESS,
    payload: payload
});

export const deleteWpsDetailFailed = (payload) => ({
    type: CONST.DELETE_WPS_DETAIL_FAILED,
    payload: payload
});

export const deleteWpsDetail = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/wpsDetail/${id}`),
        [deleteWpsDetailSuccess],
        [deleteWpsDetailFailed]
    );
};

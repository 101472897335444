import * as React from 'react';
import {Card, CardContent, CardHeader, Chip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateLoanSchedule} from "../../Redux/LoanScheduleAction";
import moment from "moment/moment";
import {currencyFormatter} from "../../../../../Setup/Config";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useCallback, useState} from "react";
import Toastr from "../../../../../Components/Elements/Toastr";
import {formatDateColumns} from "../../../../../Setup/Config";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const LoanScheduleTable = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const schedule = useSelector(state => state.loans.schedule);
    const business = useSelector(state => state.business);
    const loan = useSelector((state) => state.loans.selected);
    const [response, setResponse] = useState({});

    const customer_banks = () => {
        let obj = [];
        if(business.bankDetails && (business.bankDetails.list)?.length > 0){
            business.bankDetails.list.map(i => {
                obj.push({'value': i.bank?.bank_id, 'label': i.bank?.bank_name +  " - " + i?.account_no});
            });
        }
        return obj;
    }

    const signatories = () => {
        let obj = [];
        if(business.partners && (business.partners.list)?.length > 0) {
            business.partners.list.map(i => {
                obj.push({'value': i.id, 'label': i.name});
            });
        }
        return obj;
    }

    const columns = [
        {field: 'loan_no', align:'center', headerAlign:'center', headerName: 'C No', type: 'string', width: 80, valueGetter: () => loan.loan_no,},
        { field: 'scheduled_date', align:'center', headerAlign:'center', headerName: 'Scheduled Date', type: 'date', editable: (['edit-loan-schedule-date'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE', flex:1, minWidth:150,
            valueFormatter: (params) => {
                return params.value !== null && params.value !== ''? moment(params.value).format("DD/MM/YYYY") : '';
            },
        },
        { field: 'cheque_date', align:'center', headerAlign:'center', headerName: 'CHQ Date', type: 'date', editable: (['edit-loan-schedule-cheque-date'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE', flex:1, minWidth: 100,
            valueFormatter: (params) => {
                return params.value !== null && params.value !== ''? moment(params.value).format("DD/MM/YYYY") : '';
            },
        },
        { field: 'cheque_no', headerName: 'CHQ No', align:'center', headerAlign:'center', type: 'string', flex:1, minWidth: 100, editable: (['edit-loan-schedule-cheque-no'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE'},
        { field: 'bank_id', headerName: 'Bank', type: 'singleSelect', editable: (['edit-loan-schedule-bank'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE', flex:1, minWidth:250,
            valueOptions: customer_banks(),
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const colDef = api.getColumn(field);
                const option = colDef.valueOptions.find(
                    ({ value: optionValue }) => value === optionValue
                );
                return option?.label;
            },
        },
        { field: 'amount', headerName: 'Amount', type: 'number', flex:1, minWidth:130, editable: (['edit-loan-schedule-amount'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE', valueFormatter: ({ value }) => currencyFormatter.format(value) },
        { field: 'cheque_signed_by', headerName: 'Signed By', type: 'singleSelect', editable: (['edit-loan-schedule-cheque-signed-by'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE', flex:1, minWidth:250,
            valueOptions: signatories(),
            valueFormatter: ({ id: rowId, value, field, api }) => {
                const colDef = api.getColumn(field);
                const option = colDef.valueOptions.find(
                    ({ value: optionValue }) => value === optionValue
                );
                return option?.label;
            },
        },
        { field: 'payment_method', headerName: 'Payment Method', type: 'singleSelect', flex:1, minWidth:150, editable: (['edit-loan-schedule-payment-method'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE',
            valueOptions: [
                { value: 'CASH', label: 'Cash' },
                { value: 'CHEQUE', label: 'Cheque' },
                { value: 'BANK_TRANSFER', label: 'Bank Transfer' }
            ],
            valueFormatter: ({ value }) => {
                const paymentMethodLabels = {
                    'CASH': 'Cash',
                    'CHEQUE': 'Cheque',
                    'BANK_TRANSFER': 'Bank Transfer'
                };
                return paymentMethodLabels[value];
            },
            renderCell: (params) => {
                const paymentMethodLabels = {
                    'CASH': 'Cash',
                    'CHEQUE': 'Cheque',
                    'BANK_TRANSFER': 'Bank Transfer'
                };
                return paymentMethodLabels[params.value] || params.value;
            }
        },
        { field: 'status', headerName:'Status', headerAlign:'center', align:'center', type: 'singleSelect',  width:120, editable: (['edit-loan-schedule-status'].some(i => permissions.includes(i)) || auth?.user?.role_type === 'ADMIN') && loan.status?.name !== 'ACTIVE',
            valueOptions: ['PLANNED','PAID','OVERDUE'],
            renderCell: (params) => {
                const status = {
                    'PLANNED':'primary',
                    'PAID':'success',
                    'PARTIAL':'warning',
                    'OVERDUE':'error',
                    'DEFAULT':'error',
                    'RESTRUCTURED':'warning',
                }
                return(<StatusBall
                    variant="contained"
                    sx={{fontWeight:'normal'}}
                    label={params.value?.name}
                    color={status[params.value?.name]}
                />)
            },
        },
    ];

    const processRowUpdate = useCallback(
        async (newRow, oldRow) => {

            formatDateColumns(newRow,['cheque_date', 'scheduled_date', 'repayment_date']); // Format all date columns

            const response = await updateLoanSchedule({dispatch, data:{...newRow}});
            setResponse(response);
            return response.data.data; // return the new row containing the row id
        },
        [],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        console.error(error)
    }, []);


    return (
        <Card >
            <CardHeader title={"Repayment Schedule"} titleTypographyProps={{variant:'subtitle1'}} />
            <CardContent sx={{p:0}}>
                {schedule !== undefined &&
                <DataGridPro
                    editMode={"cell"}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    getRowId={(row) => row.id}
                    rows={schedule}
                    columns={columns}
                    hideFooter={true}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'scheduled_date', sort: 'asc' }],
                        },
                        pinnedColumns: { right: ['status'] },
                        columns: {
                            columnVisibilityModel: {
                                scheduled_date: true,
                                repayment_date: (permissions).includes('show-loan-repayment-date') || auth.user.role_type === 'ADMIN',
                                last_reminder_date: (permissions).includes('show-loan-schedule-last-reminder-date') || auth.user.role_type === 'ADMIN',
                                bank_id:(permissions).includes('show-loan-schedule-bank') || auth.user.role_type === 'ADMIN',
                                amount:true,
                                balance:(permissions).includes('show-loan-schedule-balance') || auth.user.role_type === 'ADMIN',
                                cheque_signed_by:(permissions).includes('show-loan-schedule-cheque-signed-by') || auth.user.role_type === 'ADMIN',
                                cheque_no:(permissions).includes('show-loan-schedule-cheque-no') || auth.user.role_type === 'ADMIN',
                                cheque_date:(permissions).includes('show-loan-schedule-cheque-date') || auth.user.role_type === 'ADMIN',
                                cheque_deposit_date:(permissions).includes('show-loan-schedule-cheque-deposit-date') || auth.user.role_type === 'ADMIN',
                                cheque_clearing_date:(permissions).includes('show-loan-schedule-cheque-clearing-date') || auth.user.role_type === 'ADMIN',
                                cheque_bounced:(permissions).includes('show-loan-schedule-cheque-bounced') || auth.user.role_type === 'ADMIN',
                                status:true,
                            }
                        }
                    }}
                />
                }
            </CardContent>
            {Object.keys(response).length > 0 && <Toastr response={response} />}
        </Card>
    );
}


import React, {useEffect, useState} from 'react'
import {Grid, Stack, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../../Common/App";
import { verifyBusiness } from "../../index";
import {useNavigate} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

export const VerifyBusinessModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});
    const [tenorError, setTenorError] = useState('');

    const handleSubmit = async () => {

        let formData = {}
        formData['business_id'] = props.business_id;
        formData['config'] = data;

        setDisabled(true);

        await verifyBusiness({dispatch, data:{...formData}}).then((res)=>{

            if(res['success']){
                dispatch(setModal({component:null, open:false}));
                navigate('/business');
            }else{
                setResponse(res);
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "max_allowed_tenor") {
            const tenorValue = parseFloat(value);
            if (tenorValue % 0.5 !== 0) {
                setDisabled(true);
                setTenorError('Tenor must be in increments of 0.5');
                return;
            } else if (tenorValue > 120) {
                setDisabled(true);
                setTenorError('Tenor cannot exceed 120');
                return;
            } else {
                setTenorError('');
            }
        }

        setDisabled(false);
        setData(data => ({
            ...data,
            [name]: value,
        }));
    }

    return(
        <>
            <DialogTitle>Verify Business</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.credit_limit ?? ""}
                            label={"Discounting Limit Amount"}
                            fullWidth
                            required
                            name="credit_limit"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.credit_limit']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.profit_rate ?? ""}
                            label={"Forfaiting / Discounting Rate %"}
                            fullWidth
                            required
                            name="profit_rate"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.profit_rate']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.processing_fee ?? ""}
                            label={"Processing Fee %"}
                            fullWidth
                            required
                            name="processing_fee"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.processing_fee']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.max_allowed_tenor ?? ""}
                            label={"Tenor (Months)"}
                            fullWidth
                            required
                            name="max_allowed_tenor"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={tenorError || response?.error?.data?.errors?.['config.max_allowed_tenor']}
                            //error={!!tenorError}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.no_of_repayments ?? ""}
                            label={"No of Repayments"}
                            fullWidth
                            required
                            name="no_of_repayments"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.no_of_repayments']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.account_setup_fee ?? ""}
                            label={"Account Setup Fee"}
                            fullWidth
                            required
                            name="account_setup_fee"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.account_setup_fee']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.document_fee ?? ""}
                            label={"Document Fee"}
                            fullWidth
                            required
                            name="document_fee"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.document_fee']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.due_diligence_fee ?? ""}
                            label={"Due Diligence Fee"}
                            fullWidth
                            required
                            name="due_diligence_fee"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.due_diligence_fee']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.late_payment_rate ?? ""}
                            label={"Late Fee Rate %"}
                            fullWidth
                            required
                            name="late_payment_rate"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.late_payment_rate']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.late_payment_fee ?? ""}
                            label={"Late Payment Fee/Day"}
                            fullWidth
                            required
                            name="late_payment_fee"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.late_payment_fee']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider textAlign="left" sx={{ fontSize:'11px'}}>The below config is for Invoice Discounting</Divider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.drawdown_fee ?? ""}
                            label={"Drawdown Fee"}
                            fullWidth
                            required
                            name="drawdown_fee"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.drawdown_fee']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.retention_amount ?? ""}
                            label={"Retention Amount %"}
                            fullWidth
                            required
                            name="retention_amount"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.retention_amount']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.utilization_commitment ?? ""}
                            label={"Utilization Commitment %"}
                            fullWidth
                            required
                            name="utilization_commitment"
                            onBlur={handleChange}
                            type={"number"}
                            helperText={response?.error?.data?.errors?.['config.utilization_commitment']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            defaultValue={data?.other_conditions ?? ""}
                            label={"Other Conditions"}
                            fullWidth
                            required
                            name="other_conditions"
                            onBlur={handleChange}
                            helperText={response?.error?.data?.errors?.['config.other_conditions']}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false})) }>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Verify Business</Button>
            </DialogActions>
        </>
    )
}

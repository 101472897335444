import React from "react";

export const LinkMailto = ({ mailto, label }) => {
    const mailtoLink = `mailto:${mailto}`;

    return (
        <a
            style={{ textDecoration: 'none' }}
            href={mailtoLink}
            onClick={(e) => {
                window.location.href = mailtoLink;
                e.preventDefault();
            }}
        >
            {label}
        </a>
    );
};

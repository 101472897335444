import React from 'react';
import Box from "@mui/material/Box";
import {Typography, Grid } from "@mui/material";
import {CorporateRegistrationForm} from "../../index";

export const BusinessRegistrationLg = () => {

    return (
        <Grid item container xs={12} sx={{ height: '80vh'}}>

            <Grid item alignSelf={"center"} xs={12} md={4}>
                <Typography color={"#ffffff"} mb={1} sx={{ fontWeight: '300', fontSize:'30px', fontFamily:'Open Sans', lineHeight: '2rem' }} variant={"h3"}>Business Registration</Typography>
                <Typography variant={"h5"} sx={{ fontSize: '1rem' }} color={"#ffffff"}>Register your Business now for Exclusive Opportunities</Typography>
            </Grid>

            <Grid item container p={4} justifyContent={"center"} alignContent={"center"} xs={12} md={8} sx={{background:'#ffffff'}}>
                <Box sx={{ width:'100%', maxWidth:800}}>
                    <CorporateRegistrationForm />
                </Box>
            </Grid>
        </Grid>
    )
}
import React, {useEffect} from 'react'
import { setModal } from "../../../Common/App";
import {useDispatch, useSelector} from "react-redux";
import {fetchStats, fetchProductWiseStats} from "../Redux/Action";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {AccountTotalStats, ReserveFundsStats, UpcomingRePaymentStats, AvailableBalanceStats, ProductWiseStatsPanel} from "../index";
import {fetchMyWallet} from "../../Wallets";

export const Dashboard = () => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);

    useEffect(() => {
        dispatch(setModal({ component:null, open: false}))
        fetchMyWallet({dispatch})
        fetchStats({dispatch})
        fetchProductWiseStats({dispatch})
    },[]);

    return (
        <>
            <Grid spacing={2} mb={2} container direction="row" justifyContent="top" alignItems="stretch" pr={2}>
                <Grid item xs={12}>
                    <Box sx={{borderBottom:'1px solid #ddd', }}>
                        <Typography lineHeight={4} fontSize={'1.2rem'}>Hi, {auth.user.first_name}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={3} >
                    <AccountTotalStats />
                </Grid>
                <Grid item xs={6} md={3} >
                    <ReserveFundsStats />
                </Grid>
                <Grid item xs={6} md={3} >
                    <AvailableBalanceStats />
                </Grid>
                <Grid item xs={6} md={3} >
                    <UpcomingRePaymentStats />
                </Grid>
            </Grid>
            <Grid spacing={2} container direction="row" justifyContent="top" alignItems="stretch" pr={2}>
                <Grid item xs={12}>
                    <ProductWiseStatsPanel />
                </Grid>
            </Grid>
        </>
    );
}

import {List as ListUI, ListItem, ListItemText, Paper} from "@mui/material";
import * as React from "react";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

export const WalletStatsPanel = () => {

    const navigate = useNavigate();
    const wallet = useSelector(state => state.wallets.selected);

    const List = styled(ListUI)({
        bgcolor: 'background.paper',
        '& .MuiListItem-root': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            padding:'4px 10px',
            margin:0
        },
        '& .MuiListItem-root:last-child': {
            borderBottom: 'none',
            textAlign:'center',
            padding:'6px 0',
        }
    })


    return (
        <Paper>
            <List>
                <ListItem>
                    <ListItemText primary={"Available Balance"} secondary={wallet.balance ?? "-"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Currency"} secondary={wallet?.currency ?? "-"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Reserved Funds"} secondary={wallet?.reserved_funds ?? "-"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Withdrawal Limit"} secondary={wallet?.withdrawal_limit ?? "-"} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Button onClick={() => navigate('/statements')} variant={"contained"} color={"secondary"}>Statements</Button>} />
                </ListItem>
            </List>
        </Paper>
    )
}

import * as React from 'react';
import {Link as LinkUI} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { doLogout } from "../../Modules/Common/Auth";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

export const BusinessMainMenu = () => {

    const dispatch = useDispatch();
    const services = useSelector((store) => store.app.services);
    const business = useSelector(store => store.business?.list || []);
    //Account Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const Link = styled(LinkUI, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    }));

    return (
        <>
            {business !== undefined &&
            <Box sx={{ display: { md: 'flex' } }}>
                <Stack spacing={3} direction="row" sx={{ mr: 4 }} alignItems="center">
                    {Array.isArray(business) && business.filter((value) => ['VERIFIED','APPROVED','REJECTED','RETURNED'].includes(value.status)).length > 0 && (
                        <Link className="publicMenu" to="/dashboard" component={Typography} variant="normal">
                            Dashboard
                        </Link>
                    )}

                    {business.length > 0 && (
                        <Link className="publicMenu" to="/my-business" component={Typography} variant="normal">
                            My Business
                        </Link>
                    )}

                    {business.filter((value) => value.status === 'APPROVED').length > 0 && (
                        <>
                            {(services !== undefined && services.some((s) => s.short_code === 'ID')) && (
                                <Link className="publicMenu" to="/customers" component={Typography} variant="normal">
                                    Customers
                                </Link>
                            )}
                            <Link className="publicMenu" to="/business-finance" component={Typography} variant="normal">
                                Requests
                            </Link>
                        </>
                    )}
                </Stack>

            </Box>
            }
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                elevation={0}
                sx={{
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiButtonBase-root':{
                            width:200
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Link hidden={true} style={{ textDecoration:'none'}} to={'/profile'} >
                    <MenuItem>Profile </MenuItem>
                </Link>

                <Link hidden={true} style={{ textDecoration:'none'}} to={'/settings'} >
                    <MenuItem> Settings </MenuItem>
                </Link>

                <Link style={{ textDecoration:'none'}} to={'/dashboard'} >
                    <MenuItem> Dashboard </MenuItem>
                </Link>

                <Link style={{ textDecoration:'none'}} to={'/customers'} >
                    <MenuItem> My Business </MenuItem>
                </Link>

                {(services !== undefined && services.some((s) => s.short_code === 'ID')) && (
                <Link style={{ textDecoration:'none'}} to={'/customers'} >
                    <MenuItem> Customers </MenuItem>
                </Link>
                )}

                <Link style={{ textDecoration:'none'}} to={'/business-finance'} >
                    <MenuItem> Requests </MenuItem>
                </Link>
                <Divider />
                <MenuItem onClick={() => doLogout(dispatch)} > Logout </MenuItem>
            </Menu>
        </>
    );
}

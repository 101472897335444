import React, {useEffect, useState} from 'react'
import {CardContent, CardHeader, List as ListUI, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {Panel} from "../../../../../Components";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {fetchRole, fetchRoles} from "../../Redux/Action";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import { styled } from "@mui/material/styles";
import {setModal} from "../../../../Common/App";
import {RoleAssignedUserModal} from "../Modals/RoleAssignedUserModal";

export const RolePanel = () => {

    const dispatch = useDispatch();
    const [hoveredItem, setHoveredItem] = useState(null);
    const items = useSelector((store) => store.roles.list);
    const filters = useSelector((store) => store.roles?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.roles?.config?.pagination);
    const [isLoading, setIsLoading] = React.useState(true);
    const [response, setResponse] = React.useState({});

    useEffect(() => {
        fetchItems();
    },[pagination]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchRoles({ dispatch, }).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const List = styled(ListUI)(({ theme }) => ({
        '& .MuiListItem-root': {
            borderBottom: '1px dashed #e0e0e0',
            '& .role-item': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .role-user-count': {
                color: theme.palette.primary.main // Access the primary color from the theme
            },
            '& .role-user-count:hover': {
                color: theme.palette.error.main // Access the info color from the theme
            }
        },
    }));

    const primaryText = (item) => {
        return (
            <span
                className={'role-item'}
                style={{ cursor: 'pointer' }}
                onClick={() => handleRoleClick(item.id)} // handle click event
            >
                {item.name}
                    <span
                        onClick={() => handleClick(item.id)}
                        className='role-user-count'
                    >
                    {'(' + item.users_count + ')'}
                </span>
            </span>
            );
    };

    const handleRoleClick = async (itemId) => {
        await fetchRole({dispatch, id: itemId});
    }

    const handleClick = (itemId) => {
        // Handle click event
        dispatch(setModal({component:<RoleAssignedUserModal />, open:true}));
    }

    return (
        <Panel>
            <CardHeader title={"Roles"} />
            <CardContent>
                <List>
                    {items.map((item, index) => (
                        <ListItem
                            key={index}
                            onMouseEnter={() => setHoveredItem(index)} // Set hovered item index
                            onMouseLeave={() => setHoveredItem(null)} // Reset hovered item index
                        >
                            <ListItemText primary={primaryText(item)} secondary={item.department ?? '-'} />
                            {hoveredItem === index && (
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Panel>
    )
}
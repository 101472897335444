import React from 'react'
import Typography from "@mui/material/Typography";
import {Card as CardUI, CardActions, CardContent, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";

export const StatsTemplate = (props) => {

    const Card = styled(CardUI)({
        '& .MuiCardContent-root':{
            borderBottom:'1px solid #ddd'
        }
    })

    return (
        <>
            <Card sx={{display:'flex', height:'100%', justifyItems:'space-between', flexDirection:'column'}}>
                <CardContent sx={{p:{xs:1.5, xm:3}, flex:1}}>
                    <Grid>
                        <Typography sx={{ fontSize: '0.8rem', mb:2 }} variant="h3" color="text.primary">
                            {props.title}
                        </Typography>
                        <Typography sx={{ fontSize: '1.8rem', mb:1, fontWeight:'bold' }} variant="h4" color={props.color ?? "text.primary" } gutterBottom>
                            {props.displayedCount}
                        </Typography>
                    </Grid>
                </CardContent>
                {props.link !== false && (
                    <CardActions sx={{ py: 1, px: 2, fontSize: '.75rem' }}>
                        <Typography color={"error"} sx={{ fontSize: '.8rem', color:'#143c73!important' }}>
                            <Link to={props.link}>{props.linkText || 'See All'}</Link>
                        </Typography>
                    </CardActions>
                )}
            </Card>
        </>
    );
}

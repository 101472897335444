import * as React from 'react';
import {List, ListItem, ListItemText, Paper as PaperUI} from "@mui/material";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import {currencyFormatter} from "../../../../../Setup/Config";

export const ListingStats = () =>{

    const listing = useSelector(state => state.listings.selected);

    const Paper = styled(PaperUI)({
        color: 'darkslategray',
        boxShadow:'0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
    });

    const StatsList = styled(List)({
        flexGrow:1,
        padding:'20px 12px!important',
        '& .MuiListItem-root':{
            padding:0,
            '& .MuiListItemText-root':{
                margin:'5px',
                textAlign:'center',
                '& span':{
                    color:'#000',
                    fontSize:'1.55rem',
                    fontWeight:'800',
                },
                '& p':{
                    fontSize:'0.75rem',
                    marginBottom:'0!important'
                }
            }
        }
    });

    return (
        <>
            <Paper>
                <StatsList sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <ListItem>
                        <ListItemText primary={listing.service.profit_rate ? listing.service.profit_rate + '%' : '-'} secondary={"Profit"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={listing.service.tenor ?? '-'} secondary={"Tenor"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={listing.business.rating ?? '-'} secondary={"Business Rating"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={listing.service.amount ? "AED " + currencyFormatter.format(listing.service.amount) : '-'}  secondary={"Amount Requested"} />
                    </ListItem>
                </StatsList>
            </Paper>
        </>
    )
}


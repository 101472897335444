import { useEffect, useState } from 'react';

export const useAppTitle = (title) => {

    useEffect(() => {

        document.title = title;

    }, []);

};

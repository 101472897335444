import React from 'react';
import { CardContent, IconButton } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Popover } from "../../../../../Components/Elements/Popover";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {fetchMonthlyStats} from "../../Redux/Action";
import {useDispatch} from "react-redux";

export const MonthlyPaymentDateRangeSelectorPopover = () => {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState([null, null]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDateChange = (newDates) => {
        // Handle the change in date range
        setSelectedDate(newDates);
        // Check if both dates are selected before calling the custom function
        if (newDates[0] && newDates[1]) {
            handleCustomFunction(newDates);
        }
    };

    const handleCustomFunction = async (dates) => {
        // Your custom function logic here
        const formattedDates = dates.map(date => date.format('YYYY-MM-DD'));

        await fetchMonthlyStats({dispatch, dates:formattedDates}).then((res) => {
            if (res?.success?.status === 200) {
                console.log("Success");
            }
        });
    }

    return (
        <>
            <IconButton
                size="large"
                aria-label="select date range"
                color="primary"
                onClick={handleClick}
                sx={{ padding:'0!important'}}
            >
                <CalendarMonthIcon sx={{ fontSize: '1.7rem'}} />
            </IconButton>
            <Popover title={"Month Range Selector"} anchorEl={anchorEl} onClose={handleClose}>
                <CardContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            value={selectedDate}
                            onChange={handleDateChange}
                            format="YYYY-MM-DD"
                            localeText={{ start: 'From Date', end: 'To Date' }}
                        />
                    </LocalizationProvider>
                </CardContent>
            </Popover>
        </>
    );
};

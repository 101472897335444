import * as React from 'react';
import {
    Card,
    CardHeader,
    CardActions,
    List as ListUI,
    CardContent,
    ListItem,
    ListItemText,
    TextField,
    Button,
    Stack,
    Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateProfile} from "../../Redux/Action";

export const PersonalDetailsTab = () => {

    const dispatch = useDispatch();
    const [editMode, setEditMode] = React.useState(false);
    const profile = useSelector((store) => store.profile);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);

    const handleEditBtn = () => {
        setEditMode(!editMode);
    };

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData({
            salutation: profile.selected?.profile?.salutation,
            first_name: profile.selected?.first_name,
            last_name: profile.selected?.last_name,
            user_code: profile.selected?.profile?.user_code,
            passport_no: profile.selected?.profile?.passport_no,
            username: profile.selected?.username,
            birthdate: profile.selected?.profile?.birthdate,
            passport_expiry: profile.selected?.profile?.passport_expiry,
            nationality: profile.selected?.profile?.nationality,
        });
    }, [])

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
    });

    const handleSaveBtn = async () => {

        setDisabled(true);

        await updateProfile({dispatch, data}).then((res) => {
            if(res['success']){
                setDisabled(false);
                setEditMode(false);
            }
        });
    }

    const handleCancelBtn = () => {
        // Reset data and set editMode to false
        setData(profile?.selected?.profile);
        setEditMode(false);
    };

    return (
        <Card>
            <CardHeader title={"Personal Details"} />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <List>

                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Title"
                                        name="salutation"
                                        defaultValue={data?.salutation}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.salutation}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Title" secondary={data?.salutation ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        name="statutory_director"
                                        defaultValue={data?.first_name}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.statutory_director}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="First Name" secondary={data?.first_name ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="User Code"
                                        name="user_code"
                                        defaultValue={data?.user_code}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.user_code}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="User Code" secondary={data?.user_code ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Passport No"
                                        name="passport_no"
                                        defaultValue={data?.passport_no}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.passport_no}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Passport No" secondary={data?.passport_no ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Nationality"
                                        name="nationality"
                                        defaultValue={data?.nationality}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.nationality}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Nationality" secondary={data?.nationality ?? "-"} />
                                )}
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <List>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Date of Birth"
                                        name="birthdate"
                                        defaultValue={data?.birthdate}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.birthdate}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Date of Birth" secondary={data?.birthdate ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        name="last_name"
                                        defaultValue={data?.last_name}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.last_name}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Last Name" secondary={data?.last_name ?? "-"} />
                                )}
                            </ListItem>
                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Username"
                                        name="username"
                                        defaultValue={data?.username}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.username}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Username" secondary={data?.username ?? "-"} />
                                )}
                            </ListItem>

                            <ListItem>
                                {editMode ? (
                                    <TextField
                                        fullWidth
                                        label="Passport Expiry"
                                        name="passport_expiry"
                                        defaultValue={data?.passport_expiry}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={profile?.error?.passport_expiry}
                                        onBlur={handleChange} />
                                ) : (
                                    <ListItemText primary="Passport Expiry" secondary={data?.passport_expiry ?? "-"} />
                                )}
                            </ListItem>

                        </List>
                    </Grid>
                </Grid>
            </CardContent>
            {editMode &&
                <CardActions sx={{p:2, justifyContent:'end'}}>
                    <Stack spacing={2} justifyContent={"space-between"} direction={"row"}>
                        <Button variant="contained" color="secondary" size="small" onClick={handleCancelBtn}>Cancel</Button>
                        <Button variant="contained" size="small" onClick={handleSaveBtn}>Save</Button>
                    </Stack>
                </CardActions>
            }
        </Card>
    );
};

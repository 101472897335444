import React, {useEffect} from 'react'
import {Box, Grid} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import { useDispatch } from "react-redux";
import {setModal} from "../../App";
import Button from "@mui/material/Button";
import { NotificationTable } from "./Tables/NotificationTable";
import {setSelectedNotification} from "../Redux/Action";
import {AddNotification} from "./Forms/AddNotification";

export const Notifications = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setModal({ component:null, open: false}))
    },[]);

    const handleActionBtn = (e) => {
        dispatch(setModal({component:<AddNotification />, open:true}));
        dispatch(setSelectedNotification({}));
    }

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box justifyContent={"space-between"} display={"flex"}>
                            <Breadcrumbs items={'DASHBOARD/NOTIFICATIONS'} sx={{display:'inline-block'}} />
                            <Button variant="contained" data-id={"draft"} data-action={"AddItem"} data-is_customer={1} onClick={handleActionBtn}>Add Notification</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <NotificationTable />
                    </Grid>
                </Grid>
            </>
    );
}

import React, { useState} from 'react'
import {useDispatch, } from "react-redux";
import {setModal} from "../../../../Common/App";
import {exportBusiness, } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import Toastr from "../../../../../Components/Elements/Toastr";

export const ExportBusinessModal = (props) => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleSubmit = async () => {
        let data = {
            'business_id': props.business_id,
            'type' : props.type
        }
        setDisabled(true);
        await exportBusiness({dispatch, data}).then((res)=>{
            setResponse(res);
        });
        dispatch(setModal({component:null, open:false}));
    };


    return(
        <>
            <DialogTitle>Export Business</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    Are you sure, you want to export this business?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Export Business</Button>
            </DialogActions>
            {response && <Toastr response={response} />}
        </>
    )
}
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    DialogTitle,
    DialogContent,
    Grid, Typography,
    DialogActions, List, ListItem, ListItemText, Stack, TextField
} from "@mui/material";
import { setModal } from "../../../../Common/App";
import {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { returnTask, verifyTask } from "../../Redux/Action";
import MuiDatepicker from "../../../../Corporate/Business/Components/Datepicker";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";

export const ViewTaskModal = () => {

    const dispatch = useDispatch();
    const item = useSelector((store) => store.dashboard?.tasks.selected);
    const submitted = item.meta?.submitted ?? "";
    const verify = item.meta?.verify ?? "";
    const [response, setResponse] = useState({});
    const [showRemarks, setShowRemarks] = useState(false);  // State to control visibility of remarks
    const [remarks, setRemarks] = useState('');  // State to hold remarks text

    const [data, setData] = useState({
        task_id: item.id,
    });

    // This effect runs once on component mount to update `data` with all key-value pairs.
    useEffect(() => {
        const updatedData = { ...data };

        Object.entries(verify ? verify : submitted)
            .filter(([key]) => !['business_id', 'entity_id', 'task_id', 'mol_list_doc_id', 'second_file', 'file', 'document_id', 'doc_expiry', 'type', 'entity_name', 'owner_id'].includes(key))
            .forEach(([key, value]) => {
                updatedData[key] = value;
            });

        setData(updatedData);
    }, [submitted, verify]);


    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const detailsList = () => {
        console.log(verify ? verify : submitted)
        // Separate entries into editable and non-editable
        const entries = Object.entries(verify ? verify : submitted)
            .filter(([key]) => !['business_id', 'entity_id', 'task_id', 'mol_list_doc_id', 'second_file', 'file', 'document_id', 'doc_expiry', 'type', 'entity_name', 'owner_id'].includes(key));

        // Separate editable and non-editable fields
        const nonEditableFields = [];
        const editableFields = [];

        entries.forEach(([key, value]) => {
            // Determine if the field is editable or not
            if (['mol_list_doc_name', 'doc_name'].includes(key)) {
                // Non-editable fields with special conditions
                let displayKey = key === 'doc_name' ? 'Attached Document' : 'MOL List Document';
                let secondaryValue = (
                    <Typography component={Link} to={`${process.env.REACT_APP_BASE_URL}document/${submitted[key === 'doc_name' ? 'document_id' : 'mol_list_doc_id']}`} target="_blank" rel="noopener">
                        {value}
                    </Typography>
                );

                nonEditableFields.push(
                    <ListItem key={key}>
                        <ListItemText primary={displayKey} secondary={secondaryValue} />
                    </ListItem>
                );
            } else {
                // Editable fields
                if (['payment_date', 'period_from', 'period_to', 'payment_date', 'visa_expiry_date', 'passport_expiry_date', 'eid_expiry_date', 'license_issue_date', 'license_expiry_date', 'passport_expiry_date', 'visa_expiry_date', 'eid_expiry_date', 'trade_license_expiry_date', 'tenancy_expiry_date', 'tenancy_start_date', 'tenancy_end_date'].includes(key)) {
                    editableFields.push(
                        <ListItem key={key}>
                            <MuiDatepicker error={true} label={key.replace(/_/g, ' ')} name={key} data={data} state={setData} />
                        </ListItem>
                    );
                } else if (['license_issue_country'].includes(key)) {
                    editableFields.push(
                        <ListItem key={key}>
                            <SelectCountry error={response?.error?.data?.errors?.[key]} label={key.replace(/_/g, ' ')} name={key} data={data} state={setData} />
                        </ListItem>
                    );
                } else {
                    editableFields.push(
                        <ListItem key={key}>
                            <TextField
                                fullWidth
                                label={key.replace(/_/g, ' ')}
                                name={key}
                                inputProps={{ autoComplete: 'none' }}
                                defaultValue={data?.[key]}
                                InputLabelProps={{ shrink: true }}
                                onBlur={handleChange}
                                helperText={response?.error?.data?.errors?.[key]}
                            />
                        </ListItem>
                    );
                }
            }
        });

        // Combine non-editable fields first, followed by editable fields
        return [...nonEditableFields, ...editableFields];
    };


    const handleVerifyButton = async () => {
        await verifyTask({dispatch, data}).then((res) => {
            setResponse(res);
            if (res['success']) {
                dispatch(setModal({ component: null, open: false }));
            }
        });
    };

    const handleReturnButton = () => {
        setShowRemarks(true);  // Show the remarks text field and proceed button
    };

    const handleProceedButton = async () => {
        await returnTask({dispatch, data:{taskId: item.id, remarks}}).then((res) => {
            setResponse(res);
            if (res['success']) {
                dispatch(setModal({ component: null, open: false }));
            }
        });
    };

    const StyledList = styled(List)(({ theme }) => ({
        padding: 0,
        '& .MuiListItem-root': {
            padding: theme.spacing(2, 2),
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& .MuiFormLabel-root':{
                textTransform:'capitalize',
            },
            '& .MuiListItemText-primary': {
                textTransform: 'capitalize',
                color: theme.palette.primary.main,
            },
            '& .MuiListItemText-secondary': {
                color: theme.palette.primary.main,
            }
        }
    }));

    return (
        <>
            <DialogTitle>{item.status?.name === 'SUBMITTED' ? "Verify Task" : "View Task" }</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StyledList>
                            <ListItem>
                                <ListItemText primary="Business" secondary={item?.business?.name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Title" secondary={item?.title} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Description" secondary={item?.description} />
                            </ListItem>
                            {detailsList()}
                        </StyledList>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {showRemarks ? (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Remarks"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <Button variant="contained" color="primary" onClick={handleProceedButton}>Proceed</Button>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack sx={{ width: '100%' }} direction={"row"} justifyContent={"space-between"}>
                        {item.status?.name === 'SUBMITTED' && (
                            <Button variant="contained" color="warning" onClick={handleReturnButton}>Return</Button>
                        )}
                        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                            <Button onClick={() => dispatch(setModal({ component: null, open: false }))}>Close</Button>
                            {item.status?.name === 'SUBMITTED' && (
                                <Button variant="contained" color="primary" onClick={handleVerifyButton}>Verify</Button>
                            )}
                        </Stack>
                    </Stack>
                )}
            </DialogActions>
        </>
    );
};

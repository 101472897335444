import React, {useEffect} from 'react'
import {
    CardContent,
    CardHeader,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import {fetchServices, setConfig, setSelectedService} from "../../../../Corporate/Services";
import {GridActionsCellItem} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import PageviewIcon from "@mui/icons-material/Pageview";
import PropTypes from 'prop-types';
import {currencyFormatter} from "../../../../../Setup/Config";
import {Panel} from "../../../../../Components";
import { setModal } from "../../../../Common/App";
import {useNavigate} from "react-router-dom";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import Tooltip from "@mui/material/Tooltip";
import {fetchBusiness} from "../../../../Corporate/Business";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {ServiceRequestDetailsCard} from "../Cards/ServiceRequestDetailsCard";
import {VerifyServiceRequestModal, ApproveServiceRequestModal} from "../../../../Corporate/Services";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

export const FundingRequestsPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const permissions = useSelector((store) => store.app.permissions);
    const items = useSelector((store) => store.services);
    const filters = useSelector((store) => store.services.config.filters);
    const pagination = useDeepEqualSelector((store) => store.services.config.pagination);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();
    },[pagination])

    const fetchData = async () => {
        setIsLoading(true);
        await fetchServices({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setResponse(res);
            setIsLoading(false);
        })
    }

    const viewServiceRequest = async (params) => {

        dispatch(setSelectedService(params.row.service_id));

        // For Invoice discounting, navigate to invoice details
        if(params.row.service_type?.name === "INVOICE_DISCOUNTING") {

            navigate('/invoices/' + params.row.invoice.invoice_id)

        // If the service is funded, navigate to service details
        }else if(params.row.status === "FUNDED"){

            navigate('/services/' + params.row.service_id)

        // If the service is already having a loan, navigate to loan details
        }else if (params.row.loan !== null) {

            navigate('/business-finance/' + params.row.loan.loan_id);

        // If the service is in PENDING, open the verify modal
        }else if(params.row.status === 'PENDING'){

            await fetchBusiness({dispatch, id: params.row.business_id}).then((res) => {
                dispatch(setModal({component: <VerifyServiceRequestModal />, open: true, fullScreen: false}));
            });

        // If the service is in VERIFIED, open the approve modal which will enable the user to list the service or issue a loan
        }else if(params.row.status === 'VERIFIED' && permissions.includes('approve-service-request')) {

            await fetchBusiness({dispatch, id: params.row.business_id}).then((res) => {
                dispatch(setModal({component: <ApproveServiceRequestModal />, open: true, fullScreen: false}));
            });
        }
    }

    const viewBusiness = React.useCallback(
        (id) => async () => {
            navigate('/business/' + id);
        },
        [],
    );

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
                ),
            },
            { field:'service_no', align:'center', headerAlign:'center', headerName:'S No', type: 'string', flex:1, minWidth: 60,},
            { field: 'business_name', headerName:'Business', type: 'string', minWidth:220, flex: 1,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewBusiness(params.row?.business?.business_id)}>{ params.row?.business?.name }</Typography>
                }
            },
            {field: 'service_type', headerName: 'Type', type: 'string', flex: 1, minWidth: 60,
                valueGetter: (params) => { return params.value?.short_code },
            },
            { field: 'tenor', align:'center', headerAlign:'center', headerName:'Tenor', type: 'string', flex:1, minWidth: 60, valueGetter: (params) => {const repaymentPeriod = params?.row?.loan?.repayment_period;
                    return repaymentPeriod ? `${repaymentPeriod} m` : '';} },
            { field: 'amount', headerName:'Amount', type: 'number', flex:1, minWidth: 100,
                //valueFormatter: ({ value }) => currencyFormatter.format(value),
                valueGetter: (params) => { return params.row.overdue_amount !== null ? parseFloat(params.row.amount) + parseFloat(params.row.overdue_amount) : parseFloat(params.row.amount); }
            },
            { field: 'status', headerAlign:'center', align:'center',  headerName:'Status', type: 'string', flex:1, minWidth:140,
                renderCell: (params) => {

                    const status = {
                        'DRAFT': { color: 'secondary', name: 'Draft' },
                        'PENDING': { color: 'warning', name: 'Pending' },
                        'VERIFIED': { color: 'info', name: 'Verified' },
                        'IN_PROGRESS': { color: 'primary', name: 'In Progress' },
                        'ACTIVE': { color: 'success', name: 'Active' },
                        'REJECTED': { color: 'error', name: 'Rejected' },
                        'CANCELLED': { color: 'secondary', name: 'Cancelled' },
                        'CLOSED': { color: 'secondary', name: 'Closed' }
                    };

                    const statusInfo = status[params.value] || { color: 'default', name: params.value };

                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={statusInfo.name}
                        color={statusInfo.color}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width:70,
                getActions: (params) => {
                    if(params.row.loan !== null){
                        return [
                            <Tooltip title={"Show Details"}>
                                <GridActionsCellItem
                                    icon={<PageviewIcon sx={{zIndex:-1}} />}
                                    label="View"
                                    color={"primary"}
                                    onClick={ () => navigate( '/business-finance/' + params.row.loan?.loan_id) }
                                />
                            </Tooltip>
                        ];
                    }else if(params.row.status === 'PENDING' || params.row.status === 'VERIFIED') {

                        return [
                            <Tooltip title={"Take Action"}>
                                <GridActionsCellItem
                                    icon={<SettingsIcon sx={{zIndex: -1}}/>}
                                    color={"primary"}
                                    label={"View"}
                                    onClick={() => viewServiceRequest(params)}
                                />
                            </Tooltip>
                        ];
                    }
                    return [];
                },
            },
        ],
        [],
    );

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <ServiceRequestDetailsCard data={row} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);


    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'Funding Requests'} />
                <CardContent sx={{p:0, minHeight:'300px'}}>
                    <DataGridPro
                        autoHeight={false}
                        getRowId={(row) => row.service_id}
                        //getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={getDetailPanelHeight}
                        columns={columns}
                        rows={items.list || []}
                        sx={{ border:'none'}}
                        pagination
                        paginationMode="server"
                        paginationModel={pagination}
                        rowCount={items?.config?.rowCount ?? 0}
                        pageSizeOptions={[5]}
                        loading={isLoading}
                        onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                        initialState={{
                            pagination: { paginationModel: { ...pagination } },
                            pinnedColumns: { right: [ 'actions'] },
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }],
                            },
                            columns:{
                                columnVisibilityModel:{
                                    business_name: true,
                                }
                            }
                        }}
                    />
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );
    const shouldShowToggle = !['PENDING','VERIFIED'].includes(props.row.status);

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return shouldShowToggle && (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};

import React, {useEffect} from 'react'
import {CardContent, CardHeader, Tooltip,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import {fetchServices, setConfig} from "../../../Services";
import {GridActionsCellItem} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {currencyFormatter} from "../../../../../Setup/Config";
import {Panel} from "../../../../../Components";
import {useNavigate, useParams} from "react-router-dom";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import PageviewIcon from "@mui/icons-material/Pageview";
import {ServiceRequestDetailsCard} from "../Cards/ServiceRequestDetailsCard";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import moment from "moment";

export const FundingRequestsPanel = () => {

    const {business_id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const items = useSelector((store) => store.services);
    const filters = useSelector((store) => store.services.config.filters);
    const pagination = useDeepEqualSelector((store) => store.services.config.pagination);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();
    },[pagination])

    const fetchData = async () => {
        setIsLoading(true);
        await fetchServices({dispatch, data:{...filters, ...pagination, business_id}}).then((res) => {
            setIsLoading(false);
        })
    }

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
                ),
            },
            { field:'service_no', align:'center', headerAlign:'center', headerName:'S No', type: 'string', flex:1, minWidth: 80,},
            { field: 'business_name', headerName:'Business', type: 'string', minWidth:150, flex: 1,
                valueGetter: (params) => {
                    return params.row?.business?.name;
                }
            },
            {
                field: 'service_type',
                headerName: 'Service Type',
                type: 'string',
                flex: 1,
                minWidth: 120,
                renderCell: (params) => (
                    <Tooltip title={params.row.service_type?.name}>
                        <span>{params.row.service_type?.short_code}</span>
                    </Tooltip>
                )
            },
            { field: 'tenor', align:'center', headerAlign:'center', headerName:'Tenor', type: 'string', flex:1, minWidth: 60, valueGetter: (params) => {const repaymentPeriod = params?.row?.loan?.repayment_period;
                    return repaymentPeriod ? `${repaymentPeriod} m` : '';} },
            { field: 'amount', headerName:'Amount', type: 'number', flex:1, minWidth: 100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'start_date', headerAlign:'center', align:'center', headerName:'Start Date', type: 'string', flex:1, minWidth: 120, valueGetter: (params) => moment(params.row?.loan?.start_date).format('DD/MM/YYYY') },
            { field: 'status', headerAlign:'center', align:'center',  headerName:'Status', type: 'string', flex:1, minWidth:140,
                renderCell: (params) => {

                    const status = {
                        'DRAFT': { color: 'secondary', name: 'Draft' },
                        'PENDING': { color: 'warning', name: 'Pending' },
                        'VERIFIED': { color: 'info', name: 'Verified' },
                        'IN_PROGRESS': { color: 'info', name: 'In Progress' },
                        'ACTIVE': { color: 'success', name: 'Active' },
                        'REJECTED': { color: 'error', name: 'Rejected' },
                        'CANCELLED': { color: 'secondary', name: 'Cancelled' },
                        'CLOSED': { color: 'secondary', name: 'Closed' }
                    };

                    const statusInfo = status[params.value] || { color: 'default', name: params.value };

                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={statusInfo.name}
                        color={statusInfo.color}
                    >
                    </StatusBall>)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: auth.user.role_type !== "CLIENT" ? 'Action' : 'Details',
                flex:1,
                minWidth:60,
                getActions: (params) => {
                    if(params.row.loan !== null){
                        return [
                            <Tooltip title={"Show Details"}>
                                <GridActionsCellItem
                                    icon={<PageviewIcon sx={{zIndex:-1}} />}
                                    label="View"
                                    onClick={ () => navigate( '/business-finance/' + params.row.loan?.loan_id) }
                                />
                            </Tooltip>
                        ];
                    }
                    return [];
                },
            },
        ],
        [],
    );

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <ServiceRequestDetailsCard data={row} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'Funding Requests'} />
                <CardContent sx={{p:0, minHeight:'300px'}}>
                    <DataGridPro
                        autoHeight={false}
                        getRowId={(row) => row.service_id}
                        columns={columns}
                        rows={items.list || []}
                        sx={{ border:'none'}}
                        rowThreshold={0}
                        //getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={getDetailPanelHeight}
                        pagination
                        paginationMode="server"
                        paginationModel={pagination}
                        rowCount={items?.config?.rowCount ?? 0}
                        pageSizeOptions={[5]}
                        loading={isLoading}
                        onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                        initialState={{
                            pinnedColumns: { right: [ 'actions'] },
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }],
                            },
                            columns:{
                                columnVisibilityModel:{
                                    business_name: false,
                                }
                            },
                            pagination: { paginationModel: { ...pagination } }
                        }}
                    />
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );
    const shouldShowToggle = !['PENDING','VERIFIED'].includes(props.row.status);

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return shouldShowToggle && (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};

import React from 'react'
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {MetricTemplateCard} from "./MetricTemplateCard";

export const TotalFundedCard = () => {

    const count = useSelector(store => store.dashboard.stats?.totalFunding);
    const displayedCount = useCounterEffect(0, count);

    return (
        <>
            <MetricTemplateCard unit={"AED"} title={"Total Funded"} displayedCount={displayedCount} link={"/business-finance/total-funded"} linkText={"See All"} />
        </>
    );
}

import CONST from "./Constant";

const INITIAL_STATE = {
    upcoming_repayments:0,
    product_wise_stats:[],
    tasks:[],
};

const Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type){

        case CONST.FETCH_STATS_SUCCESS:
            return {
                ...state,
                upcoming_repayments: action.payload.data?.upcoming_repayments,

                error:[],
                code:200
            }
        case CONST.FETCH_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_PRODUCT_WISE_STATS_SUCCESS:
            return {
                ...state,
                product_wise_stats: action.payload.data?.product_wise_stats,
                account_total: action.payload.data?.account_total,
                error:[],
                code:200
            }
        case CONST.FETCH_PRODUCT_WISE_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_MONTHLY_STATS_SUCCESS:
            return {
                ...state,
                monthlyStats: action.payload.data,
                error:[],
                code:200
            }
        case CONST.FETCH_MONTHLY_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
import React, {useEffect, useState} from "react";
import {
    FormControl, FormHelperText, Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { setModal } from "../../../../Common/App";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import {setSelectedInvoice, updateInvoice} from "../../index";
import Toastr from "../../../../../Components/Elements/Toastr";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";

export const InvoiceForm = () => {

    const business = useSelector(state => state.business);
    const invoices = useSelector(state => state.invoices);
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const customers = business.customers.list.filter(o => o.status === 'APPROVED');

    useEffect(()=>{
        setData(invoices.selected);
    },[]);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            'business_id': business.selected.business_id,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {

        setDisabled(true);
        setDisabledSubmit(true);
        data['status'] = 'SUBMITTED';

        await updateInvoice({dispatch, data}).then((res)=>{
            setResponse(res);
            setDisabled(res.hasOwnProperty('success'));
            setDisabledSubmit(res.hasOwnProperty('success'));
            res.hasOwnProperty('success') && closeAndClearForm();
        });
    };

    const handleSave = async () => {
        setDisabled(true);
        await updateInvoice({dispatch, data}).then((res)=>{
            setResponse(res);
            setDisabled(res.hasOwnProperty('success'));
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component: null, state:false}));
        dispatch(setSelectedInvoice(null))
    };

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Invoice
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {data !== undefined &&
                    <Grid item md={12} xs={12} >
                        <Stack sx={{pt:1}} spacing={3}>
                            <FormControl sx={{ width:'100%' }}>
                                <InputLabel id="add-user-form-label-office">Select Customer</InputLabel>
                                <Select
                                    fullWidth
                                    disabled={(disabled || disabledSubmit)}
                                    value={data?.customer_id || ''}
                                    name="customer_id"
                                    MenuProps={{
                                        style: {zIndex: 35001},
                                    }}
                                    label="Select Customer"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    { customers.map(i => (
                                            <MenuItem key={i.customer_id} value={i.customer_id}>{i.company_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText>{response?.error?.customer_id}</FormHelperText>
                            </FormControl>

                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: (disabled || disabledSubmit),
                                }}
                                label="Purchase Order No"
                                name="purchase_order_no"
                                defaultValue={data?.purchase_order_no}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.purchase_order_no}
                                onBlur={handleChange} />

                            <MuiDatepicker setDisabled={setDisabled} label={"Purchase Order Date"} name="purchase_order_date" data={data} state={setData} />

                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: (disabled || disabledSubmit),
                                }}
                                label="Invoice No"
                                name="invoice_no"
                                defaultValue={data?.invoice_no}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.invoice_no}
                                onBlur={handleChange} />

                            <MuiDatepicker setDisabled={setDisabled} label={"Invoice Date"} name="invoice_date" data={data} state={setData} />

                            <FormControl sx={{ width:'100%' }}>
                                <InputLabel id="label-currency">Currency</InputLabel>
                                <Select
                                    defaultValue={data?.currency || ""}
                                    name="currency"
                                    MenuProps={{
                                        style: {zIndex: 35001}
                                    }}
                                    fullWidth
                                    label="Currency"
                                    onChange={(e) => handleChange(e)}
                                    helperText={response?.error?.currency}
                                >
                                    <MenuItem key={"AED"} value={'AED'}>AED</MenuItem>
                                    <MenuItem key={"USD"} value={'USD'}>USD</MenuItem>
                                    <MenuItem key={"EUR"} value={'EUR'}>EUR</MenuItem>
                                    <MenuItem key={"GBP"} value={'GBP'}>GBP</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: (disabled || disabledSubmit),
                                }}
                                label="Invoice Amount"
                                name="invoice_amount"
                                defaultValue={data?.invoice_amount}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.invoice_amount}
                                onBlur={handleChange} />

                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: (disabled || disabledSubmit),
                                }}
                                label="Delivery Note No"
                                name="delivery_note_no"
                                defaultValue={data?.delivery_note_no}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.delivery_note_no}
                                onBlur={handleChange} />

                            <AttachDoc setResponse={setResponse} updateFunction={updateInvoice} allowedFileType={['pdf','xls']} onBlur={handleChange} label={"Delivery Note"} entity_name={"invoices"} entity_id={data?.invoice_id} name="delivery_note_doc" data={data} state={setData} />

                            <MuiDatepicker setDisabled={setDisabled} label={"Delivery Date"} name="delivery_date" data={data} state={setData} />

                            <MuiDatepicker setDisabled={setDisabled} label={"Invoice Acceptance Date"} name="invoice_acceptance_date" data={data} state={setData} />

                            <FormControl sx={{ width:'100%' }}>
                                <InputLabel id="label-office">Incoterms</InputLabel>
                                <Select
                                    defaultValue={data?.inco_terms || "DAP"}
                                    name="inco_terms"
                                    MenuProps={{
                                        style: {zIndex: 35001}
                                    }}
                                    fullWidth
                                    label="Payment Terms"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem key={"EXW"} value={'EXW'}>Ex Works</MenuItem>
                                    <MenuItem key={"FCA"} value={'FCA'}>Free Carrier</MenuItem>
                                    <MenuItem key={"CPT"} value={'CPT'}>Carriage Paid to</MenuItem>
                                    <MenuItem key={"CIP"} value={'CIP'}>Carriage and Insurance Paid To</MenuItem>
                                    <MenuItem key={"DAP"} value={'DAP'}>Delivered at Place</MenuItem>
                                    <MenuItem key={"DPU"} value={'DPU'}>Delivered at Place Unloaded</MenuItem>
                                    <MenuItem key={"DDP"} value={'DDP'}>Delivered Duty Paid</MenuItem>
                                    <MenuItem key={"FAS"} value={'FAS'}>Free Alongside Ship</MenuItem>
                                    <MenuItem key={"FOB"} value={'FOB'}>Free on Board</MenuItem>
                                    <MenuItem key={"CFR"} value={'CFR'}>Cost and Freight</MenuItem>
                                    <MenuItem key={"CIF"} value={'CIF'}>Cost Insurance and Freight</MenuItem>
                                </Select>
                            </FormControl>

                            <SelectCountry error={false} default={"ARE"} disabled={disabled} disabledSubmit={disabledSubmit} label={"Origin"} name={"origin"} data={data} state={setData} />

                            <SelectCountry error={false} default={"ARE"} disabled={disabled} disabledSubmit={disabledSubmit} label={"Destination"} name={"destination"} data={data} state={setData} />

                            <AttachDoc setResponse={setResponse} updateFunction={updateInvoice} allowedFileType={['pdf','xls']} onBlur={handleChange} label={"Purchase Order Doc"} entity_name={"invoices"} entity_id={data?.invoice_id} name="purchase_order_doc" data={data} state={setData} />

                            <AttachDoc setResponse={setResponse} updateFunction={updateInvoice} allowedFileType={['pdf','xls',"png","jpeg"]} onBlur={handleChange} label={"Invoice Copy Doc"} entity_name={"invoices"} entity_id={data?.invoice_id} name="invoice_doc" data={data} state={setData} />

                        </Stack>
                    </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'info'} onClick={ handleSave }>Save</Button>
                <Button disabled={disabledSubmit} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Save & Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}
import React, {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    ListItemText,
    List as ListUI,
    ListItemSecondaryAction
} from "@mui/material";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ListItemIcon from "@mui/material/ListItemIcon";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {updateSetting} from "../../Redux/Action";

export const ApplicationSettingsTab = () => {

    const dispatch = useDispatch();
    const items = useSelector((store) => store.settings.list);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const desiredParameters = ['business_portal_activated', 'investor_portal_activated'];
        const newData = {};

        items.forEach(item => {
            if (desiredParameters.includes(item.parameter_name)) {
                newData[item.parameter_name] = item.parameter_value;
            }
        });

        setData(newData);
    }, [items]);

    const ActionList = styled(ListUI)({
        width: '100%',
        '& .MuiListItem-root': {
            backgroundColor: '#f6f6f6',
            padding: '0.3rem',
            borderRadius: '5px',
            marginBottom: '0.5rem',
        },
        '& .MuiListItemIcon-root': {
            justifyContent: 'center',
        }
    });

    const handleStateButton = async (parameter_name) => {

        const newData = [{parameter_name, 'parameter_value':data[parameter_name] === 'enabled' ? 'disabled' : 'enabled'}];
        await updateSetting({dispatch, data:{ ...newData }});
    }

    return (
        <>
            <Card>
                <CardHeader title={"Account Settings"} />
                <CardContent sx={{pb:'1rem'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Business Portal</Typography>
                                <Typography variant="body1" sx={{ fontSize: '0.75rem' }}>
                                    Adjust your account preferences here.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.business_portal_activated === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Activate Business Portal" secondary="Enables access to your business portal" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.business_portal_activated === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('business_portal_activated')}>
                                                    {data.business_portal_activated === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <>
                                <Typography variant="h6">Investor Portal</Typography>
                                <Typography variant="body1">
                                    Adjust your account preferences here.
                                </Typography>
                            </>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ActionList>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FiberManualRecordIcon sx={{ color: `${data.investor_portal_activated === 'enabled' ? 'green': 'red'}` }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Activate Investor Portal" secondary="Enables access to your investor portal" />
                                            <ListItemSecondaryAction>
                                                <Button variant="contained" color={data.investor_portal_activated === 'enabled' ? 'error' : 'primary'} onClick={() => handleStateButton('investor_portal_activated')}>
                                                    {data.investor_portal_activated === 'enabled' ? 'Disable' : 'Enable'}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </ActionList>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

const CONST = {
    'INITIALIZE': 'APP/INITIALIZE', // Set mode looks similar to this
    'SET_MODE': 'APP/SET_MODE',
    'SET_CONFIG': 'APP/SET_CONFIG',
    'FETCH_CONFIG_SUCCESS': 'APP/FETCH_CONFIG_SUCCESS',
    'FETCH_CONFIG_FAILED': 'APP/FETCH_CONFIG_FAILED',

    'SET_SELECTED_ACCOUNT':'ACCOUNTS/SET_SELECTED_ACCOUNT',
    'SET_APPBAR_HEIGHT':'APP/SET_APPBAR_HEIGHT',
    'SET_ACTIVE_MENU':'APP/SET_ACTIVE_MENU',
    'SET_MODAL':'APP/SET_MODAL',
    'SET_LEFT_DRAWER':'APP/SET_LEFT_DRAWER',
    'SET_RIGHT_DRAWER':'APP/SET_RIGHT_DRAWER',

    'FS_MODAL_STATE':'APP/FS_MODAL_STATE',
    'BLOCKUI_STATE':'APP/BLOCKUI_STATE',

    'FETCH_BANKS_SUCCESS':'BANKS/FETCH_BANKS_SUCCESS',
    'FETCH_BANKS_FAILED':'BANKS/FETCH_BANKS_FAILED',

    'FETCH_LOVS_SUCCESS':'LOVS/FETCH_LOVS_SUCCESS',
    'FETCH_LOVS_FAILED':'LOVS/FETCH_LOVS_FAILED',

    'HAS_BUSINESS_SUCCESS':'BUSINESS/HAS_BUSINESS_SUCCESS',
}

export default CONST;

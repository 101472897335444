import * as React from 'react';
import {Container as ContainerUI} from "@mui/material";
import {useSelector} from "react-redux";

export const Container = ({children, styles}) => {

    const config = useSelector((store) => store.app );

    return (
        <>
            <ContainerUI component="main" sx={{
                display:'flex', flexDirection:'column', flexGrow: 1,
                mt: config.appBarHeight + 'px',
                maxWidth: {
                    xs: '100vw',
                    sm: '90vw',
                    md: '92vw',
                    lg: '95vw',
                    xl: '55vw',
                },
                minWidth: {
                    xl: '1470px',
                },
                m: config.appBarHeight + 'px auto 0',
                p:2,
                ...styles
            }}>
                {children}
            </ContainerUI>
        </>
    );
}




import * as React from 'react';
import {Grid, Select, Stack, TextField, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";


export const DeclarationForm = () => {
    return (
        <Stack>
            <Grid container spacing={3}  justifyContent={"center"}>
                <Grid item xs={12}>
                    <Typography variant={"h2"} sx={{fontSize:'1.7rem'}} my={3}>Let's get started</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Select
                        fullWidth
                        label={"Title"}
                        variant={"outlined"}
                        required
                    >
                        <MenuItem value={10}>Mr</MenuItem>
                        <MenuItem value={20}>Mrs</MenuItem>
                        <MenuItem value={30}>Miss</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={5}>
                    <TextField
                        fullWidth
                        label={"First Name"}
                        variant={"outlined"}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <TextField
                        fullWidth
                        label={"Last Name"}
                        variant={"outlined"}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Email"}
                        variant={"outlined"}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={"Password"}
                        variant={"outlined"}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={"Password"}
                        variant={"outlined"}
                        required
                    />
                </Grid>

            </Grid>
        </Stack>
    )
}
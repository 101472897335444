import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    error: [],
    code: "",
    config:{
        from:0,
        to:0,
        lastPage:1,
        rowCount:0,
        pagination:{
            page:0,
            pageSize:5,
        },
        filters:{
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.CLEAR_DATA:
            return {
                ...state,
                list: [],
                selected: {},
            }
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.FETCH_ACTIVITY_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                config:{
                    ...state.config,
                    from:action.payload.data.from,
                    to:action.payload.data.to,
                    lastPage:action.payload.data.last_page,
                    rowCount:action.payload.data.total,
                    pagination:{
                        ...state.config.pagination,
                        page:action.payload.data.current_page - 1,
                        pageSize:action.payload.data.per_page,
                    },
                },
                error: [],
                code:action.payload.status,
                message: ["Activities loaded successfully"],
            }
        case CONST.FETCH_ACTIVITY_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";
import { updateInvoiceFailed, updateInvoiceSuccess } from "../../Invoices";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
//setSelectedService
export const resetServices = (payload) => ({
    type: CONST.RESET_ALL,
    payload: null
});
export const setSelectedService = (payload) => ({
    type: CONST.SET_SELECTED_SERVICE,
    payload: payload
})

/****************************************
 FETCH SERVICE
 ****************************************/
export const fetchServiceSuccess = (payload) => ({
    type: CONST.FETCH_SERVICE_SUCCESS,
    payload: payload
});

export const fetchServiceFailed = (payload) => ({
    type: CONST.FETCH_SERVICE_FAILED,
    payload: payload
});

export const fetchService = async ({ dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/service/${id}`),
        [fetchServiceSuccess],
        [fetchServiceFailed]
    );
};

/****************************************
 FETCH SERVICES
 ****************************************/
export const fetchServicesSuccess = (payload) => ({
    type: CONST.FETCH_SERVICES_SUCCESS,
    payload: payload
});

export const fetchServicesFailed = (payload) => ({
    type: CONST.FETCH_SERVICES_FAILED,
    payload: payload
});

export const fetchServices = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/service', { params: data }),
        [fetchServicesSuccess],
        [fetchServicesFailed]
    );
};

/****************************************
 ADD SERVICE
 ****************************************/
export const addServiceSuccess = (payload) => ({
    type: CONST.ADD_SERVICE_SUCCESS,
    payload: payload
});

export const addServiceFailed = (payload) => ({
    type: CONST.ADD_SERVICE_FAILED,
    payload: payload
});

export const addService = async ({ dispatch , data}) => {
    const successActions = [addServiceSuccess];
    const errorActions = [addServiceFailed];

    if (data['service_type'] === 'INVOICE_DISCOUNTING') {
        successActions.push(updateInvoiceSuccess);
        errorActions.push(updateInvoiceFailed);
    }

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/service', data),
        successActions,
        errorActions
    );
};


/****************************************
 ADD SERVICE
 ****************************************/
export const addServiceListingSuccess = (payload) => ({
    type: CONST.ADD_SERVICE_LISTING_SUCCESS,
    payload: payload
});

export const addServiceListingFailed = (payload) => ({
    type: CONST.ADD_SERVICE_LISTING_FAILED,
    payload: payload
});

export const addServiceListing = async ({ dispatch , data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/service/listing', data),
        [addServiceListingSuccess],
        [addServiceListingFailed]
    );
};

/****************************************
 UPDATE SERVICE
 ****************************************/
export const updateServiceSuccess = (payload) => ({
    type: CONST.UPDATE_SERVICE_SUCCESS,
    payload: payload
});

export const updateServiceFailed = (payload) => ({
    type: CONST.UPDATE_SERVICE_FAILED,
    payload: payload
});

export const updateService = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/service/${data['service_id']}`, data),
        [updateServiceSuccess],
        [updateServiceFailed]
    );
};


/****************************************
 VERIFY SERVICE REQUEST
 ****************************************/
export const verifyServiceSuccess = (payload) => ({
    type: CONST.VERIFY_SERVICE_SUCCESS,
    payload: payload
});

export const verifyServiceFailed = (payload) => ({
    type: CONST.VERIFY_SERVICE_FAILED,
    payload: payload
});

export const verifyService = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/service/${data['service_id']}/verify`, data),
        [verifyServiceSuccess],
        [verifyServiceFailed]
    );
};

/****************************************
 APPROVE SERVICE REQUEST
 ****************************************/
export const approveServiceSuccess = (payload) => ({
    type: CONST.APPROVE_SERVICE_SUCCESS,
    payload: payload
});

export const approveServiceFailed = (payload) => ({
    type: CONST.APPROVE_SERVICE_FAILED,
    payload: payload
});

export const approveService = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/service/${data['service_id']}/approve`, data),
        [approveServiceSuccess],
        [approveServiceFailed]
    );
};


/****************************************
 LIST SERVICE REQUEST
 ****************************************/
export const listServiceSuccess = (payload) => ({
    type: CONST.LIST_SERVICE_SUCCESS,
    payload: payload
});

export const listServiceFailed = (payload) => ({
    type: CONST.LIST_SERVICE_FAILED,
    payload: payload
});

export const listService = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/service/${data['service_id']}/list`, data),
        [listServiceSuccess],
        [listServiceFailed]
    );
};


/****************************************
 UPDATE SERVICE CHARGE
 ****************************************/
export const updateServiceChargeSuccess = (payload) => ({
    type: CONST.UPDATE_SERVICE_CHARGE_SUCCESS,
    payload: payload
});

export const updateServiceChargeFailed = (payload) => ({
    type: CONST.UPDATE_SERVICE_CHARGE_FAILED,
    payload: payload
});

export const updateServiceCharge = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/service/${data['service_id']}/charge`, data),
        [updateServiceChargeSuccess],
        [updateServiceChargeFailed]
    );
};

/****************************************
 DELETE SERVICE
 ****************************************/
export const deleteServiceSuccess = (payload) => ({
    type: CONST.DELETE_SERVICE_SUCCESS,
    payload: payload
});

export const deleteServiceFailed = (payload) => ({
    type: CONST.DELETE_SERVICE_FAILED,
    payload: payload
});

export const deleteService = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/service/${data['service_id']}`),
        [deleteServiceSuccess],
        [deleteServiceFailed]
    );
};

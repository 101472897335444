import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const setSelectedSupportQuery = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchSupportQuerySuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchSupportQueryFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchSupportQuery = ({dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`support/${id}`),
        [fetchSupportQuerySuccess],
        [fetchSupportQueryFailed]
    );
};

export const fetchSupportQueriesSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchSupportQueriesFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchSupportQueries = ({dispatch, filters}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('support', { params: filters }),
        [fetchSupportQueriesSuccess],
        [fetchSupportQueriesFailed]
    );
};

export const addSupportQuerySuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload
});

export const addSupportQueryFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload
});

export const addSupportQuery = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('support', data),
        [addSupportQuerySuccess],
        [addSupportQueryFailed]
    );
};

export const updateSupportQuerySuccess = (payload) => ({
    type: CONST.UPDATE_ITEM_SUCCESS,
    payload: payload
});

export const updateSupportQueryFailed = (payload) => ({
    type: CONST.UPDATE_ITEM_FAILED,
    payload: payload
});

export const updateSupportQuery = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`support/${data['id']}`, data),
        [updateSupportQuerySuccess],
        [updateSupportQueryFailed]
    );
};

export const deleteSupportQuerySuccess = (payload) => ({
    type: CONST.DELETE_ITEM_SUCCESS,
    payload: payload
});

export const deleteSupportQueryFailed = (payload) => ({
    type: CONST.DELETE_ITEM_FAILED,
    payload: payload
});

export const deleteSupportQuery = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`support/${data['id']}`),
        [deleteSupportQuerySuccess],
        [deleteSupportQueryFailed]
    );
};

const CONST = {
    'REGISTER_USER_SUCCESS': 'AUTH/REGISTER_USER_SUCCESS',
    'REGISTER_USER_FAILED': 'AUTH/REGISTER_USER_FAILED',
    'PERFORM_LOGIN_SUCCESS': 'APP/LOGIN_SUCCESS',
    'PERFORM_LOGIN_FAILED': 'APP/LOGIN_FAILED',
    'PERFORM_LOGIN_LOADING': 'APP/LOGIN_LOADING',
    'PERFORM_LOGOUT_SUCCESS': 'APP/LOGOUT_SUCCESS',
    'PERFORM_LOGOUT_FAILED': 'APP/LOGOUT_FAILED',
    'PERFORM_LOGOUT_LOADING': 'APP/LOGOUT_LOADING',
    'REFRESH_TOKEN_SUCCESS': 'AUTH/REFRESH_TOKEN_SUCCESS',
    'REFRESH_TOKEN_FAILED': 'AUTH/REFRESH_TOKEN_FAILED',
    'LOGIN_SUCCESS': 'AUTH/LOGIN_SUCCESS',
    'LOGIN_FAILED': 'AUTH/LOGIN_FAILED',
    'MARK_NOTIFICATION_READ_SUCCESS': 'NOTIFICATION/MARK_NOTIFICATION_READ_SUCCESS',
    'MARK_NOTIFICATION_READ_FAILED': 'NOTIFICATION/MARK_NOTIFICATION_READ_FAILED',
    'VERIFY_EMAIL_SUCCESS': 'AUTH/VERIFY_EMAIL_SUCCESS',
    'VERIFY_EMAIL_FAILED': 'AUTH/VERIFY_EMAIL_FAILED',
    'PERFORM_REFRESH_TOKEN': 'AUTH/PERFORM_REFRESH_TOKEN',
    'VERIFY_OTP_SUCCESS': 'AUTH/VERIFY_OTP_SUCCESS',
    'VERIFY_OTP_FAILED': 'AUTH/VERIFY_OTP_FAILED',
    'RESEND_OTP_SUCCESS': 'AUTH/RESEND_OTP_SUCCESS',
    'RESEND_OTP_FAILED': 'AUTH/RESEND_OTP_FAILED',
}

export default CONST;
import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {CreditCardForm} from "../Forms/CreditCardForm";
import {Card, CardContent, CardHeader} from "@mui/material";
import {addCreditCard, clearErrors, proceed, setSelectedCreditCard, CreditCardDeleteModal} from "../../index";
import Typography from "@mui/material/Typography";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect} from "react";
import {currencyFormatter} from "../../../../../Setup/Config";

export const CreditCard = (props) => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.creditCards).length > 0 ? business.creditCards.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCreditCard({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCreditCard(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<CreditCardForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<CreditCardDeleteModal />, open:true}));
        dispatch(setSelectedCreditCard(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.card_id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status) && auth.user.role_type === 'CLIENT') {
                return ['add-business-credit-cards'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-credit-card') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
            return false;
        },[business.status, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ];
            }

            return [];

        },[editable]
    );

    const columns = React.useMemo(
        () => [
            { field: 'card_id', headerName:'Card ID', type: 'string', hide: true},
            { field: 'bank_id', headerName:'Bank/Institution', type: 'string', flex:1, minWidth:250,
                valueGetter: (params) => {
                    return params.row?.bank?.bank_name;
                }
            },
            { field: 'type', headerName:'Card Type', type: 'string', flex:1, minWidth: 90 },
            { field: 'card_holder_name', headerName:'Card Holder Name', type: 'string', flex:1, minWidth: 220 },
            { field: 'limit', headerName:'Limit', type: 'number', flex:1, minWidth: 90, valueFormatter: ({ value }) => currencyFormatter.format(value)},
            { field: 'outstanding', headerName:'Outstanding', type: 'number', flex:1, minWidth: 120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'overdue', headerName:'Overdue', type: 'number', flex:1, minWidth: 90, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'remarks', headerName:'Remarks', type: 'string', flex:1, minWidth: 250 },
            { field: 'actions', flex:1, minWidth: 120, type: 'actions', headerName:'Action', getActions: (params) => actions(params)},
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Credit Cards'}
                    action={editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button>}
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                    <DataGridPro
                        autoHeight
                        getRowId={(row) => row.card_id}
                        pageSize={filters.pageSize}
                        onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                        onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                        columns={columns}
                        onRowClick={handleRowClick}
                        hideFooterPagination
                        hideFooter
                        rows={items || []} sx={{ border:'none'}}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }],
                            },
                            pinnedColumns: { left: ['card_holder_name'], right: ['actions'] },
                            columns: {
                                columnVisibilityModel: {
                                    card_id: false,
                                    actions: editable(),
                                },
                            },
                        }}
                    />:<Typography align={"center"} p={2}>No Records Found</Typography>}
                </CardContent>
            </Card>
        </>
    );
}
import * as React from 'react';
import {styled} from "@mui/material/styles";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import {currencyFormatter} from "../../../../../Setup/Config";

export const ServiceRequestDetailsCard = (props) => {

    const CustomList = styled(List)({
        color: 'darkslategray',
        display:'flex',
        paddingTop:'0!important',
        '& .MuiListItem-root': {
            padding: '1px!important',
            '& .MuiListItemText-root':{
                color:'gray',
                padding:'0 10px 5px',
                borderRadius:'4px',
                background: '#f8f8f8',
                marginBottom:'2px',
                marginTop:'0',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    return (
        <Box>{ props.data &&
            <CustomList>
                <ListItem>
                    <ListItemText primary={"Request No"} secondary={props.data?.loan_no ? <Link to={'/business-finance/' + props.data?.loan_id}>{props.data?.loan_no}</Link> : '-' }/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Discounting Amount"} secondary={ currencyFormatter.format(props.data?.amount)}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Repayment Period"} secondary={props?.data.repayment_period + ' days' || '-'}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={"Status"} secondary={props?.data.status?.name || '-'}/>
                </ListItem>
            </CustomList>
        }
        </Box>
    )
}
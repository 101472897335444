import * as React from 'react';
import {Grid, TextField, Typography} from "@mui/material";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateAddress} from "../../../../Investor/Profile";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import Toastr from "../../../../../Components/Elements/Toastr";

export const AddressForm = forwardRef((props, ref)  => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.profile.selected);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    useImperativeHandle(ref, ()=>{
        return {
            handleSubmit:() => handleSubmit()
        }
    });

    useEffect(()=>{
        setData({
            address_street: user?.profile?.address_street ?? "",
            address_city: user?.profile?.address_city ?? "",
            address_state: user?.profile?.address_state ?? "",
            address_country: user?.profile?.address_country ?? "",
            address_postcode: user?.profile?.address_postcode ?? "",
            nationality: user?.profile?.nationality ?? "",
            residence_country: user?.profile?.residence_country ?? "",
        });
    },[]);

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = async () => {
        let response = {}
        await updateAddress({dispatch, data}).then((res)=>{
            setResponse(res);
            response = res;
        });
        return response;
    };

    return (
        <>
            {(data !== undefined && Object.keys(data).length > 0) &&
            <Grid container spacing={4}  justifyContent={"center"}>
                <Grid item xs={12}>
                    <Typography variant={"h2"} sx={{fontSize:'1.7rem'}} my={3}>Address Details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Street Address"}
                        name={"address_street"}
                        variant={"outlined"}
                        defaultValue={data?.address_street}
                        inputlabelprops={{ shrink: true }}
                        onBlur={handleChange}
                        helperText={response?.error?.data?.errors?.address_street}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"City"}
                        name={"address_city"}
                        variant={"outlined"}
                        defaultValue={data?.address_city}
                        inputlabelprops={{ shrink: true }}
                        onBlur={handleChange}
                        helperText={response?.error?.data?.errors?.address_city}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"State"}
                        name={"address_state"}
                        variant={"outlined"}
                        defaultValue={data?.address_state}
                        inputlabelprops={{ shrink: true }}
                        onBlur={handleChange}
                        helperText={response?.error?.data?.errors?.address_state}
                    />
                </Grid>


                <Grid item xs={12}>
                    <SelectCountry default={data?.address_country} error={response?.error?.data?.errors?.residence_country} label={"Country"} name={"residence_country"} data={data} state={setData} />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Postcode"}
                        name={"address_postcode"}
                        variant={"outlined"}
                        defaultValue={data?.address_postcode}
                        inputlabelprops={{ shrink: true }}
                        onBlur={handleChange}
                        helperText={response?.error?.data?.errors?.address_postcode}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SelectCountry default={data?.nationality} error={response?.error?.data?.errors?.nationality} label={"Nationality"} name={"nationality"} data={data} state={setData} />
                </Grid>
                <Toastr response={response} />
            </Grid>
            }
        </>
    )
})
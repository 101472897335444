import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    error: [],
    code: "",
    config:{
        from:0,
        to:0,
        lastPage:1,
        rowCount:0,
        pagination:{
            page:0,
            pageSize:10,
        },
        filters:{
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.CLEAR_DATA:
            return {
                ...state,
                list:[],
                selected:{},
            }
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.SET_SELECTED_PERMISSION:
            return {
                ...state,
                selected: state.list.filter(item => item.permission_id === action.payload)[0],
            }
        case CONST.FETCH_PERMISSION_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                code:200,
                message: ["Permission loaded successfully"]
            }
        case CONST.FETCH_PERMISSION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_PERMISSIONS_SUCCESS:

            return {
                ...state,
                list:action.payload.data.data,
                error: [],
                message: ["Permissions loaded successfully"],
                code:action.payload.status,
                config:{
                    ...state.config,
                    from:action.payload.data.from,
                    to:action.payload.data.to,
                    lastPage:action.payload.data.last_page,
                    rowCount:action.payload.data.total,
                    pagination:{
                        ...state.config.pagination,
                        page:action.payload.data.current_page - 1,
                        pageSize:action.payload.data.per_page,
                    },
                },
            }
        case CONST.FETCH_PERMISSIONS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_PERMISSION_SUCCESS:
            return {
                ...state,
                list:[...state.list,action.payload.data],
                selected: {},
                error: [],
                message: ["Permissions added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_PERMISSION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_PERMISSION_SUCCESS:
            const indexU = state.list.findIndex(permission => permission.permission_id === action.payload.data.permission_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Permissions updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_PERMISSION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.DELETE_PERMISSION_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.permission_id !== action.payload.data),
                selected: {},
                error: [],
                message: ["Permissions deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_PERMISSION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
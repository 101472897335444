import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import {
    Stack, Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, Tab, Tabs
} from "@mui/material";
import { useDispatch } from "react-redux";
import { currencyFormatter } from "../../../../../Setup/Config";
import moment from "moment/moment";
import { fetchFundDetails } from "../../Redux/Action";
import {useNavigate} from "react-router-dom";
import {styled, useTheme} from "@mui/material/styles";

export const FundExpandDetails = ({ data, setPanelHeight }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const panelRef = useRef(null);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [transaction_type, setTransactionType] = useState(['DEBIT']);
    const [tab, setTab] = useState(0);
    const [realized, setRealized] = useState(1);
    const [pagination, setPagination] = useState({
        current_page: 0,
        pageSize: 10,
        total: 0,
        from: 0,
        to: 0,
        last_page: 0,
        data: []
    });

    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage, tab]);

    const fetchData = async (page, rowsPerPage) => {
        setIsLoading(true);
        const res = await fetchFundDetails({ dispatch, filters: { transaction_type, realized, month: data.month + '-' + data.year, page, pageSize: rowsPerPage } });
        if (res.success) {
            setPagination(res.data.data);
            setItems(res.data.data.data);
        }
        setIsLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleTabChange = (event, newTab) => {
        setIsLoading(true);
        setTab(newTab);
        setRealized(newTab === 2 ? 0 : 1);
        setTransactionType(newTab === 0 ? ['DEBIT'] : ['CREDIT']);
        setPage(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useLayoutEffect(() => {
        if (panelRef.current) {
            setPanelHeight(panelRef.current.scrollHeight);
        }
    }, [items]);

    const paymentTypeFormatter = (type) => {

        return type.replace(/_/g, ' ').toLowerCase();
    }

    const FundTabs = styled(Tabs)(({ theme }) => ({
        '& .MuiButtonBase-root': {
            textTransform:'capitalize',
        }
    }));

    return (
        <Stack ref={panelRef}
               sx={{ height: 'auto', boxSizing: 'border-box' }}
               direction="column"
        >
            <Paper variant="outlined" square sx={{ flex: 1, mx: 'auto', width: '100%', p: 1, backgroundColor: '#efefef' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {isLoading && <Typography>Loading...</Typography>}
                        <FundTabs value={tab} onChange={handleTabChange}>
                            <Tab label="Funded" />
                            <Tab label="Receipt" />
                            <Tab label="Outstanding" />
                        </FundTabs>
                        {!isLoading && items.length === 0 && <Typography>No data found</Typography>}
                        {!isLoading && Object.keys(items).length > 0 &&
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{ tab === 2 ? 'Due Date' : 'Payment Date'}</TableCell>
                                            <TableCell>Business Name</TableCell>
                                            <TableCell>Contract No</TableCell>
                                            <TableCell>Start Date</TableCell>
                                            <TableCell>End Date</TableCell>
                                            <TableCell>Payment Type</TableCell>
                                            <TableCell>Tenor</TableCell>
                                            <TableCell>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row) => (
                                            tab === 0 ?
                                                (
                                                    <TableRow key={row.account_id}>
                                                        <TableCell>{moment(row.start_date).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell><Typography
                                                            onClick={() => navigate("/dashboard/" + row.business_id)}
                                                            sx={{
                                                                fontSize: '0.86rem',
                                                                cursor: 'pointer',
                                                                textDecoration: 'none',
                                                                color: theme.text.link
                                                            }}>{row.business.name}</Typography>
                                                        </TableCell>
                                                        <TableCell><Typography
                                                            onClick={() => navigate("/business-finance/" + row.loan_id)}
                                                            sx={{
                                                                fontSize: '0.86rem',
                                                                cursor: 'pointer',
                                                                textDecoration: 'none',
                                                                color: theme.text.link
                                                            }}>{row.loan_no}</Typography>
                                                        </TableCell>
                                                        <TableCell>{moment(row.start_date).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell>{moment(row.end_date).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell
                                                            sx={{textTransform: 'capitalize'}}>Principal</TableCell>
                                                        <TableCell>{row.repayment_period + ' m'}</TableCell>
                                                        <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                                                    </TableRow>
                                                )
                                                :
                                                (
                                                    <TableRow key={row.account_id}>
                                                        <TableCell>{moment(tab === 2 ? row.due_date : row.payment_date).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell><Typography
                                                            onClick={() => navigate("/dashboard/" + row.business_id)}
                                                            sx={{
                                                                fontSize: '0.86rem',
                                                                cursor: 'pointer',
                                                                textDecoration: 'none',
                                                                color: theme.text.link
                                                            }}>{row.business.name}</Typography></TableCell>
                                                        <TableCell><Typography
                                                            onClick={() => navigate("/business-finance/" + row.loan.loan_id)}
                                                            sx={{
                                                                fontSize: '0.86rem',
                                                                cursor: 'pointer',
                                                                textDecoration: 'none',
                                                                color: theme.text.link
                                                            }}>{row.loan.loan_no}</Typography></TableCell>
                                                        <TableCell>{moment(row.loan.start_date).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell>{moment(row.loan.end_date).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell
                                                            sx={{textTransform: 'capitalize'}}>{paymentTypeFormatter(row.account_type?.name)}</TableCell>
                                                        <TableCell>{row.loan.repayment_period + ' m'}</TableCell>
                                                        <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                                                    </TableRow>
                                                )

                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={pagination.total}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                                />
                            </TableContainer>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};

import { useEffect, useState } from 'react';

export const useDomain = () => {
    const [subDomain, setSubDomain] = useState(null);

    useEffect(() => {
        const hostnameParts = window.location.hostname.split('.');
        const subDomain = hostnameParts.length > 2 ? hostnameParts[0] : null;
        setSubDomain(subDomain);
    }, []);

    return subDomain;
};

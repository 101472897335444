import React from 'react'
import {Breadcrumbs} from "../../../../Components";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {DefaultContractTable} from "./Tables/DefaultContractTable";

export const DefaultContracts = () => {

    return (
        <>
            <Grid container spacing={2}>

                <Grid item sm={8} xs={6}>
                    <Breadcrumbs items={'DASHBOARD/BUSINESS_FINANCE/Default Contracts'} />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader title={"Default Contracts"} />
                        <CardContent sx={{p:0, pb:0}}>
                            <DefaultContractTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
import * as React from 'react';
import {
    Card,
    CardContent,
    CardHeader, FormControl,
    IconButton, InputLabel,
    Popover as PopoverUI, Select, Stack, TextField
} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {Datepicker} from "../../../../../Components/Elements/Datepicker2";
import MenuItem from "@mui/material/MenuItem";
import {useEffect} from "react";
import {fetchAccounts, setConfig} from "../../Redux/Action";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";

export const AccountFilterMenu = ({setIsLoading, setResponse}) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filters = useSelector((store) => store.accounts.config.filters);
    const pagination = useDeepEqualSelector((store) => store.accounts.config.pagination);

    const isInitialLoad = React.useRef(true);

    useEffect(() => {
        // Skip initial load
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
            return;
        }
        loadData();
    }, [filters, pagination]);

    const loadData = async () => {
        setIsLoading(true);
        await fetchAccounts({dispatch, filters:{...filters, ...pagination}}).then((res) => {
            setResponse(res);
            setIsLoading(false);
        });
    }

    const handleChange = (e) => {
        dispatch(setConfig({filters: {...filters, ...pagination, [e.target.name]: e.target.value}}));
    }
    const handleClearFilter = () => {
        dispatch(setConfig({filters: {...filters, ...pagination, business_name:'', cheque_no:'', type:[], status:[], cheque_deposit_date:'', payment_date:'', due_date:''}}));
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const Popover = styled(PopoverUI)({
        '& .MuiPaper-root':{
            boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.2)',
            [theme.breakpoints.down('md')]: {
                minWidth: '100%', // Minimum width for desktop view
                minHeight:'100%',
                left:'0!important',
                top:'0!important',
                height:'100%',
            },
        }
    });

    const PopoverCard = styled(Card)({
        width: '100%',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root':{
            flex: '1',
            //overflowY:'auto',
            //maxHeight:'300px',
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'inherit',
            },
            '& .MuiListItemAvatar-root':{
                minWidth: 'auto',
                marginRight: '15px',
                '& svg':{
                    width:'0.8rem'
                }
            },
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 300,
        },
    });

    return (
        <>
            <Button onClick={handleClick} variant={"contained"}>Filter</Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PopoverCard>
                    <CardHeader title={"Advance Filter"} titleTypographyProps={{fontWeight:'bold', fontSize:'1rem'}}
                                action={<IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={handleClose}
                                            aria-label="close"
                                            size={'small'}
                                            sx={{ position: 'absolute', top: '8px', right: '15px' }}
                                        >
                                            <CloseIcon fontSize={'0.5rem'} />
                                        </IconButton>}>
                    </CardHeader>
                    <CardContent sx={{p:'1rem!important'}}>
                        <Stack spacing={2}>
                            <TextField
                                label="Business Name"
                                name="business_name"
                                defaultValue={filters?.business_name}
                                inputlabelprops={{ shrink: true }}
                                onBlur={handleChange} />

                            <TextField
                                label="Cheque No"
                                name="cheque_no"
                                defaultValue={filters?.cheque_no}
                                inputlabelprops={{ shrink: true }}
                                onBlur={handleChange} />

                            <Datepicker fullWidth value={filters?.cheque_deposit_date} handleChange={handleChange} label={"Chq Deposit Date"} name="cheque_deposit_date" />

                            <Datepicker fullWidth value={filters?.payment_date} handleChange={handleChange} label={"Payment Date"} name="payment_date" />

                            <Datepicker fullWidth value={filters?.due_date} handleChange={handleChange} label={"Due Date"} name="due_date" />

                            <FormControl fullWidth>
                                <InputLabel id="status">Status</InputLabel>
                                <Select
                                    value={filters?.status ?? []}
                                    name="status"
                                    MenuProps={{
                                        style: {zIndex: 35001}
                                    }}
                                    multiple
                                    fullWidth
                                    label="Status"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <MenuItem key="Draft" value="DRAFT">Draft</MenuItem>
                                    <MenuItem key="Pending" value="PLANNED">Pending</MenuItem>
                                    <MenuItem key="Partial" value="PARTIAL">Partial</MenuItem>
                                    <MenuItem key="Paid" value="PAID">Paid</MenuItem>
                                    <MenuItem key="Overdue" value="OVERDUE">Overdue</MenuItem>
                                    <MenuItem key="Default" value="DEFAULT">Default</MenuItem>
                                    <MenuItem key="Deleted" value="DELETED">Deleted</MenuItem>
                                </Select>
                            </FormControl>
                            {/*<Button onClick={() => loadData()} variant={"contained"} size={"large"}>Search</Button>*/}
                            <Button  onClick={() => handleClearFilter()} variant={"contained"} size={"large"} color={"warning"}>Reset</Button>
                        </Stack>
                    </CardContent>
                </PopoverCard>
            </Popover>
        </>
    );
}


import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {VatReturnForm} from "../Forms/VatReturnForm";
import UploadVatReturnsModal from '../Modals/UploadVatReturns';
import {Grid, Box, List, ListItem, Stack, Typography} from "@mui/material";
import { addVatReturn, clearErrors, proceed, setSelectedVatReturn,VatReturnDeleteModal} from "../../index";
import moment from "moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";
import {useState} from "react";

export const VatReturnStep = () => {

    const dispatch = useDispatch();

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.vatReturns).length > 0 ? business.vatReturns.list.filter(i => i.is_draft !== 1) : [];

    const [showUploadVatReturns, setShowUploadVatReturns] = useState(false);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });
    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addVatReturn({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedVatReturn(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<VatReturnForm />, open:true}));
        dispatch(proceed(false));

    }
    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<VatReturnDeleteModal />, open:true}));
        dispatch(setSelectedVatReturn(e.currentTarget.dataset.id));

    }

    const handleClick = () => {
        setShowUploadVatReturns(true);
    };

    const columns = React.useMemo(
        () => [
            { field: 'period_from', headerName:'Period From', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'period_to', headerName:'Period To', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'turnover_amount', headerName:'Sales Amt', type: 'number', flex:1, minWidth:110, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'expenses_amount', headerName:'Expenses Amt', type: 'number', flex:1, minWidth:140, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'vat_payable', headerName:'Payable Amt', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'payment_date', headerName:'Payment Date', type: 'date', flex:1, minWidth: 130, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>

            <Grid item xs={12}>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items || []}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] },
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction={"row"} spacing={2}>
                            <Stack direction={"row"} spacing={2}>
                                <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add VAT Return</Button>
                                <Button variant="contained" color="info" sx={{ display: 'inline-block' }} onClick={handleClick}> Upload VAT Return </Button>
                            </Stack>

                            <Box maxWidth={400}>
                                {showUploadVatReturns && <UploadVatReturnsModal />}
                            </Box>
                        </Stack>

                    </Grid>
                    { items && items.length === 0 &&
                        <Grid item xs={12}>
                            <Instructions />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
        <Typography variant={"h3"}>VAT Returns</Typography>
        <Typography variant={"body1"}>
            Please fill the required details as per VAT Returns for last 4 quarters/months. Each quarter/month statement needs to be filled separately. To complete this section, keep the following documents ready for attachment.
        </Typography>
        <List>
            <ListItem>VAT Return Statement </ListItem>
            <ListItem>VAT Payment Copy</ListItem>
        </List>
    </InfoBox>)
}
import {styled, useTheme} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect, useRef} from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {setAppBarHeight} from "../../Modules/Common/App";
import { Stack} from "@mui/material";
import {Link} from "react-router-dom";
import {InvestorAccountMenu} from "../Menu/InvestorAccountMenu";
import {InvestorMainMenu} from "../Menu/InvestorMainMenu";
import {InvestorNotificationMenu} from "../Menu/InvestorNotificationMenu";
import {isBrowser} from "react-device-detect";
import {Container} from "./Container";

export const AppBarInvestor = () => {

    const dispatch = useDispatch();
    const config = useSelector((store) => store.app );
    const open = config.leftDrawer.open;
    const DrawerWidth = config.leftDrawer.width;

    const AppBar = styled(MuiAppBar, {

        shouldForwardProp: (prop) => prop !== 'open',
        })(({ theme, open }) => ({
        background:theme.appBarPublic.backgroundColor,
        zIndex: theme.zIndex.drawer + 1,
        height:80,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: DrawerWidth,
            width: `calc(100% - ${DrawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        '& .MuiToolbar-root': {
            minHeight: 80,
        }
    }));

    const appBarRef = useRef(null)

    //To get the height of appbar and use it somewhere.
    useEffect(()=>{
        dispatch(setAppBarHeight(appBarRef.current.clientHeight));
    },[])

    return (
        <AppBar position="fixed" open={open} ref={appBarRef}>
            <Container styles={{p:'0!important', marginTop:'0!important'}}>
                <Toolbar sx={{px:'0!important'}}>
                    <Link to="/">
                        <Box component="img" src={'/images/cm-logo.png'} alt="Logo" sx={{ height: '44px', marginRight: '16px' }} />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { md: 'flex' } }}>
                        <Stack direction={"row"} spacing={1}>
                            { isBrowser &&  <InvestorMainMenu /> }
                            <InvestorNotificationMenu />
                            <InvestorAccountMenu />
                        </Stack>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

import React, {useState, useEffect} from 'react';
import Box from "@mui/material/Box";
import {Container as ContainerUI, FormControl, FormHelperText,
    InputLabel, Select,
    Stack,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {doRegister} from "../../../../Administration/Users";
import {resetUsers} from "../../../../Administration/Users";
import {styled, useTheme} from "@mui/material/styles";
import Toastr from "../../../../../Components/Elements/Toastr";
import PoweredBy from "../../../../../Components/Elements/PoweredBy";
import {setBlockUI} from "../../../App";
import MuiDatepicker from "../../../../Corporate/Business/Components/Datepicker";
import MenuItem from "@mui/material/MenuItem";

const Container = styled(ContainerUI)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'
    }
}));

export const InvestorCompleteRegistrationSm = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const users = useSelector((store)=> store.users);
    const [response, setResponse] = useState({});
    const [disabled,setDisabled] = useState(false);
    const [data, setData] = useState({});

    useEffect(()=>{
        dispatch(resetUsers(false));
    },[]);

    const handleSubmit = async () => {
        setResponse(false);
        setDisabled(true);
        dispatch(setBlockUI({open:true}));

        await doRegister({dispatch, data}).then((res)=>{
            dispatch(setBlockUI({open:false}));
            setResponse(res);
        });
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.id] :e.target.value,
        }));
    }

    const ALPHABETS = /^[a-zA-Z]+$/;

    return (<Container component="main" maxWidth={'xs'} sx={{p:0, height:'100vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center', }}>

            <Stack justifyContent={'space-between'} sx={{display:'flex','height':'100vh'}} alignItems={"center"}>

                <Box sx={{flexGrow:2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src={'/images/ifund-logo.png'} width={150}  alt={"logo"}/>
                </Box>

                <Box sx={{p:2,flexGrow:0.75}}>
                    <Stack spacing={2} sx={{ minWidth: '310px' }}>
                        { response.status !== 201 &&
                            <>
                                <TextField
                                    type="text"
                                    id="first_name"
                                    onKeyDown={(e) => {
                                        if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                            e.preventDefault();
                                        }
                                    }}
                                    autoComplete="off"
                                    onChange={(e) => handleChange(e)}
                                    value={ data['first_name'] }
                                    required
                                    label="First Name"
                                    helperText={users?.error?.first_name}
                                />
                                <TextField
                                    type="text"
                                    id="last_name"
                                    autoComplete="off"
                                    onChange={(e) => handleChange(e)}
                                    value={data['last_name']}
                                    required
                                    onKeyDown={(event) => {
                                        if (!ALPHABETS.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    label="Last Name"
                                    helperText={users?.error?.last_name}
                                />
                                <TextField
                                    type="email"
                                    id="email"
                                    onChange={(e) => handleChange(e)}
                                    value={data['email']}
                                    required
                                    label="Email"
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    helperText={users?.error?.email}
                                />
                                <TextField
                                    type="tel"
                                    id="phone"
                                    autoComplete="off"
                                    onChange={(e) => handleChange(e)}
                                    value={data['phone']}
                                    required
                                    label="Phone"
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    helperText={users?.error?.phone}
                                />
                                <TextField
                                    type="password"
                                    id="password"
                                    onChange={(e) => handleChange(e)}
                                    value={data['password']}
                                    autoComplete="off"
                                    required
                                    label="Password"
                                    sx={{ width: '100%' }}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    helperText={users?.error?.password}
                                />
                                <TextField
                                    type="text"
                                    id="company_name"
                                    onKeyDown={(e) => {
                                        if (!ALPHABETS.test(e.key) && e.key !== ' ') {
                                            e.preventDefault();
                                        }
                                    }}
                                    autoComplete="off"
                                    onChange={(e) => handleChange(e)}
                                    value={ data['company_name'] }
                                    required
                                    label="Company Name"
                                    helperText={users?.error?.company_name}
                                />

                                <MuiDatepicker error={users?.error?.company_incorporation_date} setDisabled={setDisabled} label={"Company Incorporation Date"} past={true} name="company_incorporation_date" data={data} state={setData} />

                                <TextField
                                    type="text"
                                    id="total_revenue"
                                    onChange={(e) => handleChange(e)}
                                    value={data['total_revenue']}
                                    required
                                    hidden={true}
                                    label="Total Revenue"
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    helperText={users?.error?.total_revenue}
                                />
                                <TextField
                                    type="text"
                                    id="amount"
                                    autoComplete="off"
                                    onChange={(e) => handleChange(e)}
                                    value={data['amount']}
                                    required
                                    label="Required Funding Amount"
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    helperText={users?.error?.amount}
                                />
                                <TextField
                                    type="text"
                                    id="tenor"
                                    onChange={(e) => handleChange(e)}
                                    value={data['tenor']}
                                    autoComplete="off"
                                    required
                                    label="Funding Tenor"
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    helperText={users?.error?.tenor}
                                />
                                <FormControl sx={{ width:'100%', display:'none' }}>
                                    <InputLabel id="service">Service</InputLabel>
                                    <Select
                                        defaultValue={"RECEIVABLE_DISCOUNTING"}
                                        name="service"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        fullWidth
                                        label="Service"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem key={""} value={""} disabled>Select Service</MenuItem>
                                        <MenuItem key={"RECEIVABLE_DISCOUNTING"} value={'RECEIVABLE_DISCOUNTING'}>Receivable Finance</MenuItem>
                                        {/*<MenuItem key={"INVOICE_DISCOUNTING"} value={'INVOICE_DISCOUNTING'}>Invoice Discounting</MenuItem>*/}
                                    </Select>
                                    <FormHelperText>{users?.error?.service}</FormHelperText>
                                </FormControl>
                                <TextField
                                    type="text"
                                    id="purpose"
                                    multiline
                                    rows={4}
                                    onChange={(e) => handleChange(e)}
                                    value={data['purpose']}
                                    autoComplete="off"
                                    required
                                    label="Purpose of Funding"
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    helperText={users?.error?.purpose}
                                />
                                <Button disabled={disabled} color="info" sx={{ width:'100%', fontSize:'15px', fontWeight:'bold'}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Get Started</Button>
                            </>
                        }

                        <Typography align={'center'}>
                            Already have an account? <Link to={'/login'}>Login</Link>
                        </Typography>

                        <Toastr response={response} />
                    </Stack>
                </Box>

                <Box sx={{p:2}}>
                    <PoweredBy />
                </Box>
            </Stack>
        </Container>
    )
}


import * as React from 'react';
import {FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateBankDetails} from "../../../../Investor/Profile";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import Toastr from "../../../../../Components/Elements/Toastr";
import Checkbox from "@mui/material/Checkbox";
import AttachDoc from "../../../Documents/Components/AttachDoc";
import {updateCheque} from "../../../../Corporate/Business";

export const BankDetailsForm = forwardRef((props, ref)  => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.profile.selected);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    useImperativeHandle(ref, ()=>{
        return {
            handleSubmit:() => handleSubmit()
        }
    });

    useEffect(()=>{
        setData({
            id: user?.bank?.id ?? "",
            bank_name: user?.bank?.bank_name ?? "",
            bank_branch: user?.bank?.bank_branch ?? "",
            account_name: user?.bank?.account_name ?? "",
            account_no: user?.bank?.account_no ?? "",
            iban_no: user?.bank?.iban_no ?? "",
            swift_code: user?.bank?.swift_code ?? "",
            bank_country: user?.bank?.bank_country ?? "",
            is_joint_account: user?.bank?.is_joint_account ?? 0,
            joint_account_holder_name: user?.bank?.joint_account_holder_name ?? "",
        });
    },[]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;

        setData(data => ({
            ...data,
            [name]: newValue,
        }));
    }

    const handleSubmit = async () => {
        let response = {}
        await updateBankDetails({dispatch, data}).then((res)=>{
            setResponse(res);
            response = res;
        });
        return response;
    };

    console.log(data)

    return (
        <>
            {(data !== undefined && Object.keys(data).length > 0) &&
                <Grid container spacing={4}  justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Typography variant={"h2"} sx={{fontSize:'1.7rem'}} my={3}>Bank Details</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Bank Name"}
                            name={"bank_name"}
                            variant={"outlined"}
                            defaultValue={data?.bank_name}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange}
                            helperText={response?.error?.data?.errors?.bank_name}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"Bank Branch"}
                            name={"bank_branch"}
                            variant={"outlined"}
                            defaultValue={data?.bank_branch}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange}
                            helperText={response?.error?.data?.errors?.bank_branch}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <SelectCountry default={data?.bank_country} error={response?.error?.data?.errors?.bank_country} label={"Bank Country"} name={"bank_country"} data={data} state={setData}/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Account Name"}
                            name={"account_name"}
                            variant={"outlined"}
                            defaultValue={data?.account_name}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange}
                            helperText={response?.error?.data?.errors?.account_name}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Account Number"}
                            name={"account_no"}
                            variant={"outlined"}
                            defaultValue={data?.account_no}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange}
                            helperText={response?.error?.data?.errors?.account_no}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            onBlur={handleChange}
                            name={"is_joint_account"}
                            control={<Checkbox checked={data?.is_joint_account === 1} onChange={handleChange} />}
                            label={"Is this a joint account?"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {data?.is_joint_account === 1 &&
                            <TextField
                                fullWidth
                                label={"Joint Account Holder Name"}
                                name={"joint_account_holder_name"}
                                variant={"outlined"}
                                defaultValue={data?.joint_account_holder_name}
                                inputlabelprops={{ shrink: true }}
                                onBlur={handleChange}
                                helperText={response?.error?.data?.errors?.joint_account_holder_name}
                            />
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"body"}>
                            Your bank account information is required to complete a mandatory anti-money laundering check. This will be the bank account from which you will transfer funds and into which we will make payments. We will not directly debit any amounts from your bank account.
                        </Typography>
                        <Typography variant={"body"}>
                            In order to identify your fund transfers, we also require your bank account number and IBAN/SWIFT code. You can enter these details now or you can provide them when you add your first funds.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"IBAN Number"}
                            name={"iban_no"}
                            variant={"outlined"}
                            defaultValue={data?.iban_no}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange}
                            helperText={response?.error?.data?.errors?.iban_no}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"SWIFT Code"}
                            name={"swift_code"}
                            variant={"outlined"}
                            defaultValue={data?.swift_code}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange}
                            helperText={response?.error?.data?.errors?.swift_code}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{marginRight:'auto'}}>
                        <AttachDoc multiple={true} setResponse={setResponse} updateFunction={updateCheque} allowedFileType={['pdf']} onBlur={handleChange} label={"Supporting Document"} entity_name={"profile"} entity_id={data?.supporting_doc} name="supporting_doc" data={data} state={setData} />
                    </Grid>

                    <Toastr response={response} />
                </Grid>
            }
        </>
    )
})
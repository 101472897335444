import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    error: [],
    code: "",
    config:{
        total:0,
        filters:{
            page:0,
            pageSize:25,
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.CLEAR_DATA:
            return {
                ...state,
                list: [],
                selected: {},
            }
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.RESET_ALL:
            return INITIAL_STATE;
        case CONST.SET_SELECTED_USER:
            return {
                ...state,
                selected: state.list.filter(item => item.user_id === action.payload)[0],
            }
        case CONST.FETCH_USER_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["User loaded successfully"],
                code:action.payload.status,
            }
        case CONST.FETCH_USER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_USERS_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                config:{
                    ...state.config,
                    total:action.payload.data.total
                },
                error: [],
                code:action.payload.status,
                message: ["Users loaded successfully"],
            }
        case CONST.FETCH_USERS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_COLLEAGUE_SUCCESS:
            return {
                ...state,
                list:action.payload.data,
                error: [],
                code:action.payload.status,
                message: ["Colleagues loaded successfully"],
            }
        case CONST.FETCH_COLLEAGUE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.REGISTER_USER_SUCCESS:
            return {
                ...state,
                selected: action.payload?.data?.user,
                error: [],
                message: ["User registered successfully! Check your inbox for verification email"],
                code:action.payload.status
            }
        case CONST.REGISTER_USER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.CAPTURE_LEAD_SUCCESS:
            return {
                ...state,
                selected: action.payload?.data?.user,
                error: [],
                message: ["Your request has been taken, one of our customer care agent will contact you soon."],
                code:action.payload.status
            }
        case CONST.CAPTURE_LEAD_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }

        case CONST.ADD_USER_SUCCESS:
            return {
                ...state,
                list:[...state.list,action.payload.data],
                selected: {},
                error: [],
                message: ["User added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_USER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_USER_SUCCESS:
            const indexU = state.list.findIndex(user => user.user_id === action.payload.data.user_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["User updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_USER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.DELETE_USER_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.user_id !== action.payload.data),
                selected: {},
                error: [],
                message: ["User deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_USER_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Password changed successfully"],
                code:action.payload.status,
            }
        case CONST.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
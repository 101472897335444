import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    MenuItem,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl, FormControlLabel, FormLabel, InputLabel,
    Radio, RadioGroup, Select,
    TextField,
    Typography, Stack
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePermission } from "../../index";
import {setRightDrawer} from "../../../../Common/App";


export const EditPermission = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.permissions);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({})
    const [response, setResponse] = useState({});

    useEffect(() => {
        setData(items.selected);
    },[items.selected])

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await updatePermission({dispatch, data}).then((res) => setResponse(res))
    };

    return(
        <Card sx={{ height: '100%', position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
            <CardHeader
                action={
                    <IconButton aria-label="share" onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>
                        <CloseIcon />
                    </IconButton>
                }
                avatar={
                    <Typography>Edit Permission</Typography>
                }
            />
            <CardContent sx={{ flex:1 }}>
                { (data !== undefined && Object.keys(data).length > 0) &&
                    <Stack spacing={3} sx={{py:1}}>
                        <TextField
                            fullWidth
                            label="Permission Name"
                            name="name"
                            onChange={(e) => handleChange(e)}
                            defaultValue={ data['name'] }
                            variant="outlined"
                            size="large"
                            helperText={items.error?.name}
                        />
                        <TextField
                            fullWidth
                            label="Label"
                            name="label"
                            onChange={(e) => handleChange(e)}
                            defaultValue={ data['label'] }
                            variant="outlined"
                            size="large"
                            helperText={items.error?.label}
                        />
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="add-user-form-label-office">Type</InputLabel>
                            <Select
                                name="type"
                                value={data['type']||''}
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                fullWidth
                                label="Type"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="feature">Feature</MenuItem>
                                <MenuItem value="menu">Menu</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="add-user-form-label-office">Group</InputLabel>
                            <Select
                                fullWidth
                                name="group"
                                value={data['group']||''}
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                label="Group"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="dashboard">Dashboard</MenuItem>
                                <MenuItem value="business">Business</MenuItem>
                                <MenuItem value="investor">Investor</MenuItem>
                                <MenuItem value="wallet">Wallet</MenuItem>
                                <MenuItem value="request">Request</MenuItem>
                                <MenuItem value="funding">Funding</MenuItem>
                                <MenuItem value="transaction">Transaction</MenuItem>
                                <MenuItem value="invoice-discounting">Invoice Discounting</MenuItem>
                                <MenuItem value="invoices">Invoices</MenuItem>
                                <MenuItem value="customers">Customers</MenuItem>
                                <MenuItem value="business-finance">Business Finance</MenuItem>
                                <MenuItem value="permission">Permission</MenuItem>
                                <MenuItem value="role">Role</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            defaultValue={ data['description'] }
                            variant="outlined"
                            size="large"
                            sx={{mb:3}}
                            multiline
                            rows={3}
                            helperText={items.error?.description}
                            onChange={(e) => handleChange(e)}
                        />

                        <FormControl>
                            <FormLabel id="status-label">Status</FormLabel>
                            <RadioGroup
                                row
                                value={data['is_active'] || ''}
                                onChange={(e) => handleChange(e)}
                                aria-labelledby="status-label"
                                name="is_active"
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Active" />
                                <FormControlLabel value="0" control={<Radio />} label="Suspend" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                }
            </CardContent>
            <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                <Button disabled={disabled} color="primary" variant="contained" onClick={ handleSubmit }>Save</Button>
                <Button variant={'contained'} color={'secondary'} onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>Close</Button>
            </CardActions>
        </Card>
    )
}

import React from "react";
import IconButton from "@mui/material/IconButton";
import { CardActions, CardContent, CardHeader, TextField, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState, useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import { updateRequest, approveRequest } from "../../index";
import { setRightDrawer} from "../../../../Common/App";
import {DrawerCard} from "../../../../../Components";
import Box from "@mui/material/Box";

export const UpdateRequestForm = () => {

    const dispatch = useDispatch();
    const request = useSelector((store) => store.requests.selected);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    useEffect(() => {
        setData(request);
    },[request])

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSaveBtn = async () => {
        const payload = { 'note': data['note'], 'id': data['id'] };
        await updateRequest({dispatch, data:{...payload}}).then((res) => {
            if(res['error']){
                setResponse(res)
            } else{
                dispatch(setRightDrawer({component:null, open:false}));
            }
        });
    };

    const handleApproveBtn = async () => {
        const payload = { status: 'APPROVED', 'note': data['note'], 'id': data['id'] };
        await approveRequest({dispatch, data:{...payload}}).then((res) => {
            if(res['error']){
                setResponse(res)
            } else{
                dispatch(setRightDrawer({component:null, open:false}));
            }
        });
    };

    return(
        <>
            { data  &&
                <DrawerCard>
                    <CardHeader
                        action={
                            <IconButton aria-label="share" onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>
                                <CloseIcon />
                            </IconButton>
                        }
                        avatar={
                            <Typography>Request</Typography>
                        }
                    />
                    <CardContent sx={{ flex:1 }}>
                        <Stack spacing={2}>
                            { !["DEPOSIT","WITHDRAWAL"].includes(request.type) &&
                                <TextField
                                    disabled
                                    fullWidth
                                    label="Listing"
                                    variant="outlined"
                                    size="large"
                                    value={ data['name'] }
                                />
                            }
                            <TextField
                                disabled
                                fullWidth
                                label="Wallet"
                                variant="outlined"
                                size="large"
                                value={ data?.wallet?.user?.first_name + ' ' + data?.wallet?.user?.last_name }
                            />
                            <TextField
                                disabled
                                fullWidth
                                label="Type"
                                variant="outlined"
                                size="large"
                                value={ data?.type }
                            />
                            <TextField
                                disabled
                                fullWidth
                                label="Amount"
                                variant="outlined"
                                size="large"
                                value={ data?.amount }
                            />
                            <TextField
                                disabled
                                fullWidth
                                label="Reference"
                                variant="outlined"
                                size="large"
                                value={ data?.reference }
                            />
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                label="Note"
                                name="note"
                                onBlur={handleChange}
                                variant="outlined"
                                size="large"
                                defaultValue={ data?.note }
                                helperText={request.error?.note}
                            />
                        </Stack>
                    </CardContent>
                    <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                        <Box>
                            <Button sx={{mr:2}} color="primary" variant="contained" onClick={ handleSaveBtn }>Save</Button>
                            <Button color="success" variant="contained" onClick={ handleApproveBtn }>Approve</Button>
                        </Box>
                        <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>Close</Button>
                    </CardActions>
                </DrawerCard>}
        </>
    )
}

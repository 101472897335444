import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import {handleAsyncAction} from "../../../../Setup/Axios/ApiHandlers";
import {fetchBusinessesFailed, fetchBusinessesSuccess} from "../../../Corporate/Business";


/****************************************
 SET TASK CONFIG
 ****************************************/
export const setTaskConfig = (payload) => ({
    type: CONST.SET_TASK_CONFIG,
    payload,
});

/****************************************
 SET UPCOMING PAYMENT CONFIG
 ****************************************/
export const setUpcomingConfig = (payload) => ({
    type: CONST.SET_UPCOMING_PAYMENT_CONFIG,
    payload,
});

/****************************************
 SET OVERDUE PAYMENT CONFIG
 ****************************************/
export const setOverdueConfig = (payload) => ({
    type: CONST.SET_OVERDUE_PAYMENT_CONFIG,
    payload,
});

/****************************************
 SET OVERDUE PAYMENT CONFIG
 ****************************************/
export const setDefaultPaymentConfig = (payload) => ({
    type: CONST.SET_DEFAULT_PAYMENT_CONFIG,
    payload,
});

/****************************************
 SET OVERDUE PAYMENT CONFIG
 ****************************************/
export const setBusinessOverviewConfig = (payload) => ({
    type: CONST.SET_BUSINESS_OVERVIEW_CONFIG,
    payload,
});

/****************************************
 FETCH USER STATS
 ****************************************/
export const fetchStatsSuccess = (payload) => ({
    type: CONST.FETCH_STATS_SUCCESS,
    payload: payload
});

export const fetchStatsFailed = (payload) => ({
    type: CONST.FETCH_STATS_FAILED,
    payload: payload
});

export const fetchStats = ({ dispatch }) => { // Change to accept an object with dispatch
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('dashboard/stats'), // Using arrow function to return the promise directly
        [fetchStatsSuccess],
        [fetchStatsFailed]
    );
};

/****************************************
 FETCH FUNDING STATS
 ****************************************/
export const fetchFundingStatsSuccess = (payload) => ({
    type: CONST.FETCH_FUNDING_STATS_SUCCESS,
    payload: payload
});

export const fetchFundingStatsFailed = (payload) => ({
    type: CONST.FETCH_FUNDING_STATS_FAILED,
    payload: payload
});

export const fetchFundingStats = ({ dispatch }) => { // Change to accept an object with dispatch
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('dashboard/stats/funding'), // Using arrow function to return the promise directly
        [fetchFundingStatsSuccess],
        [fetchFundingStatsFailed]
    );
};

/****************************************
 FETCH FUNDING STATS
 ****************************************/
export const fetchProposalStatsSuccess = (payload) => ({
    type: CONST.FETCH_PROPOSAL_STATS_SUCCESS,
    payload: payload
});

export const fetchProposalStatsFailed = (payload) => ({
    type: CONST.FETCH_PROPOSAL_STATS_FAILED,
    payload: payload
});

export const fetchProposalStats = ({ dispatch }) => { // Change to accept an object with dispatch
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('dashboard/stats/proposal'), // Using arrow function to return the promise directly
        [fetchProposalStatsSuccess],
        [fetchProposalStatsFailed]
    );
};

/****************************************
 FETCH MONTHLY STATS
 ****************************************/
export const fetchMonthlyStatsSuccess = (payload) => ({
    type: CONST.FETCH_MONTHLY_STATS_SUCCESS,
    payload: payload
});

export const fetchMonthlyStatsFailed = (payload) => ({
    type: CONST.FETCH_MONTHLY_STATS_FAILED,
    payload: payload
});

export const fetchMonthlyStats = ({ dispatch, dates }) => { // Change to accept an object with dispatch
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account/monthly-stats', { params: { startDate: dates[0], endDate: dates[1] } }), // Using arrow function to return the promise directly
        [fetchMonthlyStatsSuccess],
        [fetchMonthlyStatsFailed]
    );
};

/****************************************
 FETCH UPCOMING PAYMENTS
 ****************************************/
export const fetchUpcomingPaymentSuccess = (payload) => ({
    type: CONST.FETCH_UPCOMING_PAYMENTS_SUCCESS,
    payload: payload
})

export const fetchUpcomingPaymentFailed = (payload) => ({
    type: CONST.FETCH_UPCOMING_PAYMENTS_FAILED,
    payload: payload
})

export const fetchUpcomingPayment = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account', {params:data}),
        [fetchUpcomingPaymentSuccess],
        [fetchUpcomingPaymentFailed]
    );
}

/****************************************
 FETCH OVERDUE PAYMENTS
 ****************************************/
export const fetchAccountsSuccess = (payload) => ({
    type: CONST.FETCH_OVERDUE_PAYMENTS_SUCCESS,
    payload: payload
})

export const fetchAccountsFailed = (payload) => ({
    type: CONST.FETCH_OVERDUE_PAYMENTS_FAILED,
    payload: payload
})

export const fetchOverdueAccounts = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account', {params:data}),
        [fetchAccountsSuccess],
        [fetchAccountsFailed]
    );
}

/****************************************
 FETCH BUSINESS OVERDUE
 ****************************************/
export const fetchBusinessOverviewSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESS_OVERVIEW_SUCCESS,
    payload: payload
})

export const fetchBusinessOverviewFailed = (payload) => ({
    type: CONST.FETCH_BUSINESS_OVERVIEW_FAILED,
    payload: payload
})

export const fetchBusinessOverview = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/overview', {params:data}),
        [fetchBusinessOverviewSuccess],
        [fetchBusinessOverviewFailed]
    );
}


/****************************************
 FETCH BUSINESS OVERDUE
 ****************************************/

export const fetchBusinessOverviewByBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/overview', {params:data}),
        [],
        []
    );
}

/****************************************
 SYNC BUSINESS OVERVIEW
 ****************************************/
export const syncBusinessOverviewSuccess = (payload) => ({
    type: CONST.SYNC_BUSINESS_OVERVIEW_SUCCESS,
    payload: payload
});

export const syncBusinessOverviewFailed = (payload) => ({
    type: CONST.SYNC_BUSINESS_OVERVIEW_FAILED,
    payload: payload
});

export const syncBusinessOverview = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/business/overview/sync',data),
        [syncBusinessOverviewSuccess],
        [syncBusinessOverviewFailed]
    );
}


/****************************************
 * SET SELECTED TASKS
 ****************************************/
export const setSelectedTask = (payload) => ({
    type: CONST.SET_SELECTED_TASK,
    payload: payload
});

/****************************************
 * FETCH TASKS
****************************************/
export const fetchTasksSuccess = (payload) => ({
    type: CONST.FETCH_TASKS_SUCCESS,
    payload: payload
});

export const fetchTasksFailed = (payload) => ({
    type: CONST.FETCH_TASKS_FAILED,
    payload: payload
});

export const fetchTasks = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('task', {params:data}),
        [fetchTasksSuccess],
        [fetchTasksFailed]
    );
};

/****************************************
 * VERIFY TASK
****************************************/
export const verifyTaskSuccess = (payload) => ({
    type: CONST.VERIFY_TASK_SUCCESS,
    payload: payload
});

export const verifyTaskFailed = (payload) => ({
    type: CONST.VERIFY_TASK_FAILED,
    payload: payload
});

export const verifyTask = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`task/${data['task_id']}/verify`, data),
        [verifyTaskSuccess],
        [verifyTaskFailed]
    );
};

/****************************************
 * RETURN TASK
 ****************************************/
export const returnTaskSuccess = (payload) => ({
    type: CONST.RETURN_TASK_SUCCESS,
    payload: payload
});

export const returnTaskFailed = (payload) => ({
    type: CONST.RETURN_TASK_FAILED,
    payload: payload
});

export const returnTask = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`task/${data['taskId']}/return`, data),
        [returnTaskSuccess],
        [returnTaskFailed]
    );
};

/****************************************
 * Fetch DEFAULT Businesses
 ****************************************/
export const fetchDefaultPaymentSuccess = (payload) => ({
    type: CONST.FETCH_DEFAULT_PAYMENTS_SUCCESS,
    payload: payload
})

export const fetchDefaultPaymentFailed = (payload) => ({
    type: CONST.FETCH_DEFAULT_PAYMENTS_FAILED,
    payload: payload
})

export const fetchDefaultPayment = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/default', {params: filters}),
        [fetchDefaultPaymentSuccess],
        [fetchDefaultPaymentFailed]
    )
};

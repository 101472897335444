import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";


export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload: payload
});

export const resetWallets = (payload) => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedWallet = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchWalletSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchWalletFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchWallet = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/wallet/${id}`),
        [fetchWalletSuccess],
        [fetchWalletFailed]
    );
};

export const fetchMyWalletSuccess = (payload) => ({
    type: CONST.FETCH_MY_WALLET_SUCCESS,
    payload: payload
});

export const fetchMyWalletFailed = (payload) => ({
    type: CONST.FETCH_MY_WALLET_FAILED,
    payload: payload
});

export const fetchMyWallet = async ({ dispatch }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/my-wallet`),
        [fetchMyWalletSuccess],
        [fetchMyWalletFailed]
    );
};

export const fetchWalletsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchWalletsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchWallets = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('wallet', { params: data }),
        [fetchWalletsSuccess],
        [fetchWalletsFailed]
    );
};


export const addWalletSuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload
});

export const addWalletFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload
});

export const addWallet = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`wallet`, data),
        [addWalletSuccess],
        [addWalletFailed]
    );
};
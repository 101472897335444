import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';
import {updateExpiredDocumentSuccess} from "../../Dashboard";

/****************************************
 SET SELECTED FINANCIAL
 ****************************************/
export const setSelectedFinancial = (payload) => ({
    type: CONST.SET_SELECTED_FINANCIAL,
    payload: payload
});

/****************************************
 FETCH FINANCIAL
 ****************************************/
export const fetchFinancialsSuccess = (payload) => ({
    type: CONST.FETCH_FINANCIALS_SUCCESS,
    payload: payload
});

export const fetchFinancialsFailed = (payload) => ({
    type: CONST.FETCH_FINANCIALS_FAILED,
    payload: payload
});

export const fetchFinancials = ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/financial`),
        [fetchFinancialsSuccess],
        [fetchFinancialsFailed]
    );
};

/****************************************
 ADD Business Financial
 ****************************************/
export const addFinancialSuccess = (payload) => ({
    type: CONST.ADD_FINANCIAL_SUCCESS,
    payload: payload
});

export const addFinancialFailed = (payload) => ({
    type: CONST.ADD_FINANCIAL_FAILED,
    payload: payload
});

export const addFinancial = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/financial', data),
        [addFinancialSuccess],
        [addFinancialFailed]
    );
};

/****************************************
 * ADD Business Financial with Document
 ****************************************/
export const addFinancialWithDocument = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('task/add-financial', data),
        [updateExpiredDocumentSuccess],
        []
    );
}

/****************************************
 Update Business Financial
 ****************************************/
export const updateFinancialSuccess = (payload) => ({
    type: CONST.UPDATE_FINANCIAL_SUCCESS,
    payload: payload
});

export const updateFinancialFailed = (payload) => ({
    type: CONST.UPDATE_FINANCIAL_FAILED,
    payload: payload
});

export const updateFinancial = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/financial/${data.id}`, data),
        [updateFinancialSuccess],
        [updateFinancialFailed]
    );
};

/****************************************
 DELETE Business Financial
 ****************************************/
export const deleteFinancialSuccess = (payload) => ({
    type: CONST.DELETE_FINANCIAL_SUCCESS,
    payload: payload
});

export const deleteFinancialFailed = (payload) => ({
    type: CONST.DELETE_FINANCIAL_FAILED,
    payload: payload
});

export const deleteFinancial = ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/financial/${id}`),
        [deleteFinancialSuccess],
        [deleteFinancialFailed]
    );
};

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload: payload
});

export const setSelectedStatement = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchStatementSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchStatementFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchStatement = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/statement`, { params: data }),
        [fetchStatementSuccess],
        [fetchStatementFailed]
    );
};

export const fetchStatementsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchStatementsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchStatements = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/statement', { params: data }),
        [fetchStatementsSuccess],
        [fetchStatementsFailed],
    );
};


export const fetchStatementSummarySuccess = (payload) => ({
    type: CONST.FETCH_SUMMARY_SUCCESS,
    payload: payload
});

export const fetchStatementSummaryFailed = (payload) => ({
    type: CONST.FETCH_SUMMARY_FAILED,
    payload: payload
});

export const fetchStatementSummary = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/statement/summary', { params: data }),
        [fetchStatementSummarySuccess],
        [fetchStatementSummaryFailed],
    );
};


import * as React from 'react';
import {CardContent, CardHeader} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {Panel, PanelList} from "../../../../../Components";

export default function LoanConfigPanel() {

    const config = useSelector((state) => state.business.config);

    return (
        <>
            { (config !== undefined && config.length > 0) &&
                <Panel>
                    <CardHeader title={"Loan Configuration"} titleTypographyProps={{variant: 'heading'}}/>
                    <CardContent>
                        <PanelList>
                            <ListItem>
                                <ListItemText primary={"Credit Limit Requested:"} secondary={config.credit_limit_requested} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Credit Limit Recommended:"} secondary={config.credit_limit_recommended} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Credit Limit Approved:"} secondary={config.credit_limit_approved} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Max Allowed Tenor:"} secondary={config.max_allowed_tenor} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Profit Rate:"} secondary={config.profit_rate} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Discounting Percentage:"} secondary={config.discounting_percentage} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Account Setup Fee:"} secondary={config.account_setup_fee} />
                            </ListItem>
                        </PanelList>
                    </CardContent>
                </Panel>
            }
        </>
    );
}

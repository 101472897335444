import CONST from "./constant";

export const INITIAL_STATE = {
    user:{},
    isLoggedIn:false,
    error:undefined,
    fetching:false,
    otpSent: false
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.REGISTER_USER_SUCCESS:
            return {
                user: action.payload.data,
                isLoggedIn:true,
                error: undefined,
                fetching: false
            }
        case CONST.REGISTER_USER_FAILED:
            return {
                fetching: false,
                error: action.payload
            }
        case CONST.LOGIN_SUCCESS:
            return {
                user: action.payload.data,
                isLoggedIn:true,
                error: undefined,
                fetching: false
            }
        case CONST.LOGIN_FAILED:
            return {
                fetching: false,
                error: action.payload
            }
        case CONST.MARK_NOTIFICATION_READ_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    notifications: []
                }
            }
        case CONST.MARK_NOTIFICATION_READ_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case CONST.RESEND_OTP_SUCCESS:
            return {
                ...state,
                otpSent: true,
            }
        case CONST.PERFORM_LOGIN_LOADING:
            return {
                fetching: true
            }
        case CONST.PERFORM_LOGOUT_SUCCESS:
            return{
                user: undefined,
                isLoggedIn: false,
                token: undefined,
                fetching: false,
                error: undefined
            }
        case CONST.PERFORM_LOGOUT_FAILED:
            return {
                fetching: false,
                error: action.payload
            }
        case CONST.PERFORM_LOGOUT_LOADING:
            return {
                fetching: true
            }
        case CONST.PERFORM_REFRESH_TOKEN:
            return {
                user: action.payload.user,
                isLoggedIn: true,
                token: action.payload.access_token,
                error: undefined,
                fetching: false
            }
        default:
            return state;
    }
}

export default Reducer;
import React, {forwardRef, useCallback, useImperativeHandle} from "react";
import {
    FormControl, Grid, InputLabel, Select, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import MuiDatepicker from "../Datepicker";
import Divider from "@mui/material/Divider";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import {updateBusiness} from "../../";
import Toastr from "../../../../../Components/Elements/Toastr";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import {PhoneField} from "../../../../../Components/Elements/PhoneField";
import { debounce } from "lodash";

export const CompanyDetailForm = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const business = useSelector((store) => store.business);
    const lov = useSelector((store) => store.app.lov);
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    useImperativeHandle(ref, ()=>{
        return {
            handleSubmit:() => handleSubmit()
        }
    })

    useEffect(() => {
        const { name, business_details, trade_license_number, trade_license_doc, last_moa_date, tenancy_expiry_date, aecb_date, aecb_score, utility_bill_date, trade_license_issue_date, trade_license_authority, trade_license_expiry_date, business_profile, moa_doc, tenancy_doc, utility_bill_doc, aecb_report_doc, avatar_doc, ca_address_1, ca_address_2, ca_address_3, ca_address_4, ca_po_box, ca_city, ca_state, ca_country, contact_person, mobile, phone, email, website, linkedin, twitter, remarks, business_id } = business.selected || {};
        setData({ name, business_details, trade_license_number, last_moa_date, trade_license_doc, tenancy_expiry_date, aecb_date, aecb_score, utility_bill_date, trade_license_issue_date, trade_license_authority, trade_license_expiry_date, business_profile, moa_doc, tenancy_doc, utility_bill_doc, aecb_report_doc, avatar_doc, ca_address_1, ca_address_2, ca_address_3, ca_address_4, ca_po_box, ca_city, ca_state, ca_country, contact_person, mobile, phone, email, website, linkedin, twitter, remarks, business_id });
    }, [business.selected]);

    // Debounced handleChange function
    const debouncedHandleChange = useCallback(debounce((name, value) => {
        setData(data => ({
            ...data,
            [name]: value,
        }));
    }, 300), []);

    const handleChange = (e) => {
        debouncedHandleChange(e.target.name, e.target.value);
    };

    const handleSubmit = async () => {
        delete data['created_by'];
        let response = {}
        await updateBusiness({dispatch, data}).then((res)=>{
            setResponse(res);
            response = res;
        });
        return response;
    };

    return(
        <>
            { (data !== undefined && Object.keys(data).length > 0) &&
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Stack spacing={3} mb={2}>
                        <TextField
                            fullWidth
                            label="Company / Establishment Name"
                            name="name"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.name }
                            inputProps={
                                { readOnly: true, }
                            }
                            helperText={business.error?.name}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            label="Describe the nature of business activities"
                            name="business_details"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.business_details ?? "" }
                            helperText={business.error?.business_details}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Divider textAlign="left" sx={{ fontSize:'11px'}}>Company Registration Details</Divider>
                </Grid>

                <Grid item md={6} xs={12} >
                    <Stack spacing={3} >
                        <TextField
                            fullWidth
                            label="Trade License Number"
                            name="trade_license_number"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.trade_license_number }
                            inputProps={
                                { readOnly: true, }
                            }
                            helperText={business.error?.trade_license_number}
                        />

                        <MuiDatepicker error={business?.error?.trade_license_issue_date} setDisabled={setDisabled} label={"Trade License Issue Date"} name="trade_license_issue_date" past={true} data={data} state={setData} />

                        <AttachDoc multiple={false} doc_expiry={data?.trade_license_expiry_date} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['pdf']} onBlur={handleChange} label={"Trade License Doc"} entity_name={"business"} entity_id={data.business_id} name="trade_license_doc" data={data} state={setData} />

                        <MuiDatepicker error={business?.error?.last_moa_date} setDisabled={setDisabled} label={"Last MOA Updated Date"} past={true} name="last_moa_date" data={data} state={setData} />

                        <MuiDatepicker error={business?.error?.tenancy_expiry_date} setDisabled={setDisabled} label={"Ejari/Tenancy Expiry Date"} name="tenancy_expiry_date" past={false} data={data} state={setData} />

                        <MuiDatepicker error={business?.error?.utility_bill_date} setDisabled={setDisabled} label={"Utility Bill Date"} name="utility_bill_date" past={true} data={data} state={setData} />

                        <MuiDatepicker error={business?.error?.aecb_date} setDisabled={setDisabled} label={"AECB Report Date"} name="aecb_date" past={true} data={data} state={setData} />

                        <TextField
                            fullWidth
                            label="AECB Score"
                            name="aecb_score"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            inputProps={{ maxLength: 3 }}
                            defaultValue={ data?.aecb_score }
                            helperText={business.error?.aecb_score}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={3} mb={2}>

                        <FormControl fullWidth>
                            <InputLabel id="license-authority-label">Trade License Authority</InputLabel>
                            <Select
                                name="trade_license_authority"
                                defaultValue={data?.trade_license_authority}
                                label="Trade License Authority"
                                onChange={handleChange}
                            >
                                <MenuItem key={"null"} value="">Select</MenuItem>
                                {
                                    (lov.filter(i => i.name === 'trade_license_authority')).map((i,v) =><MenuItem key={v} value={i.value}>{i.label}</MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <MuiDatepicker error={business?.error?.trade_license_expiry_date} setDisabled={setDisabled} label={"Trade License Expiry Date"} name="trade_license_expiry_date" past={false} data={data} state={setData} />

                        <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['pdf','doc','docx']} onBlur={handleChange} label={"Company Profile Doc"} entity_name={"business"} entity_id={data.business_id} name="business_profile" data={data} state={setData} />

                        <AttachDoc multiple={true} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['pdf']} onBlur={handleChange} label={"MOA All Memorandum Multiple Files"} entity_name={"business"} entity_id={data.business_id} name="moa_doc" data={data} state={setData} />

                        <AttachDoc multiple={false} doc_expiry={data?.tenancy_expiry_date} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['pdf']} id={'business_id'} onBlur={handleChange} label={"Ejari/Tenancy"} entity_name={"business"} entity_id={data.business_id} name="tenancy_doc" data={data} state={setData} />

                        <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['pdf']} id={'business_id'} onBlur={handleChange} label={"Utility Bill"} entity_name={"business"} entity_id={data.business_id} name="utility_bill_doc" data={data} state={setData} />

                        <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['pdf']} id={'business_id'} onBlur={handleChange} label={"Attach AECB Report"} entity_name={"business"} entity_id={data.business_id} name="aecb_report_doc" data={data} state={setData} />

                        <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['jpg','png','jpeg']} onBlur={handleChange} label={"Company Logo"} entity_name={"business"} entity_id={data.business_id} name="avatar_doc" data={data} state={setData} />
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Divider textAlign="left" sx={{ fontSize:'11px'}}>Company Address</Divider>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={3}>

                        <TextField
                            fullWidth
                            // label="Building/Street Name"
                            label="Office Address"
                            name="ca_address_2"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.ca_address_2 }
                            helperText={business.error?.ca_address_2}
                        />
                        <TextField
                            fullWidth
                            hidden={true}
                            label="Unit No / Floor Number"
                            name="ca_address_1"
                            type={"number"}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.ca_address_1 }
                            helperText={business.error?.ca_address_1}
                        />

                        <TextField
                            fullWidth
                            label="Landmark"
                            name="ca_address_4"
                            hidden={true}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.ca_address_4 }
                            helperText={business.error?.ca_address_4}
                        />
                        <TextField
                            fullWidth
                            hidden={true}
                            label="Area"
                            name="ca_address_3"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.ca_address_3 }
                            helperText={business.error?.ca_address_3}
                        />
                        <TextField
                            fullWidth
                            label="PO Box"
                            name="ca_po_box"
                            type="number"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.ca_po_box }
                            helperText={business.error?.ca_po_box}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={3}>

                        <FormControl fullWidth>
                            <InputLabel id="ca_city-label">City/Emirate</InputLabel>
                            <Select
                                name="ca_city"
                                label="City/Emirate"
                                onChange={(e) => handleChange(e)}
                                value={data?.ca_city ?? ''}
                            >
                                <MenuItem value={''} key={""}>Select City</MenuItem>
                                <MenuItem value={'Abu Dhabi'} key={"Abu Dhabi"}>Abu Dhabi</MenuItem>
                                <MenuItem value={'Dubai'} key={"Dubai"}>Dubai</MenuItem>
                                <MenuItem value={'Sharjah'} key={"Sharjah"}>Sharjah</MenuItem>
                                <MenuItem value={'Ajman'} key={"Ajman"}>Ajman</MenuItem>
                                <MenuItem value={'Ras Al Khaimah'} key={"Ras Al Khaimah"}>Ras Al Khaimah</MenuItem>
                                <MenuItem value={'Umm Al Quwain'} key={"Umm Al Quwain"}>Umm Al Quwain</MenuItem>
                                <MenuItem value={'Fujairah'} key={"Fujairah"}>Fujairah</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth hidden={true}>
                            <InputLabel id="ca_state-label">State/Region</InputLabel>
                            <Select
                                name="ca_state"
                                label="State/Region"
                                onChange={(e) => handleChange(e)}
                                value={data?.ca_state ?? ' '}
                            >
                                <MenuItem value={' '} key={" "}>Select City</MenuItem>
                                <MenuItem value={'Abu Dhabi'}>Abu Dhabi</MenuItem>
                                <MenuItem value={'Dubai'}>Dubai</MenuItem>
                                <MenuItem value={'Sharjah'}>Sharjah</MenuItem>
                                <MenuItem value={'Ajman'}>Ajman</MenuItem>
                                <MenuItem value={'Ras Al Khaimah'}>Ras Al Khaimah</MenuItem>
                                <MenuItem value={'Umm Al Quwain'}>Umm Al Quwain</MenuItem>
                                <MenuItem value={'Fujairah'}>Fujairah</MenuItem>
                            </Select>
                        </FormControl>

                        <SelectCountry disabled={disabled} label={"Country"} name={"ca_country"} data={data} state={setData} />

                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Divider textAlign="left" sx={{ fontSize:'11px'}}>Contact & Communication Details</Divider>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            label="Contact Person"
                            name="contact_person"
                            onBlur={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.contact_person || "" }
                            helperText={business.error?.contact_person }
                        />
                        <PhoneField
                            fullWidth
                            label="Contact Mobile"
                            name="mobile"
                            type="tel"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            placeholder={"e.g. 9714XXXXXXX"}
                            size="large"
                            defaultValue={data?.mobile || ""}
                            helperText={business.error?.mobile}
                        />
                        {/*<TextField*/}
                        {/*    fullWidth*/}
                        {/*    label="Contact Mobile"*/}
                        {/*    name="mobile"*/}
                        {/*    placeholder={"e.g. 9714XXXXXXX"}*/}
                        {/*    type="tel"*/}
                        {/*    onChange={(e) => handleChange(e)}*/}
                        {/*    variant="outlined"*/}
                        {/*    size="large"*/}
                        {/*    defaultValue={ data?.mobile }*/}
                        {/*    helperText={business.error?.mobile}*/}
                        {/*/>*/}

                        <PhoneField
                            fullWidth
                            label="Office Phone"
                            name="phone"
                            type="tel"
                            placeholder={"e.g. 9714XXXXXXX"}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={data?.phone || ""}
                            helperText={business.error?.phone}
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.email }
                            helperText={business.error?.email}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            label="Website"
                            name="website"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.website }
                            helperText={business.error?.website}
                        />
                        <TextField
                            fullWidth
                            label="Linkedin"
                            name="linkedin"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.linkedin }
                            helperText={business.error?.linkedin}
                        />
                        <TextField
                            fullWidth
                            label="Twitter/Instagram"
                            name="twitter"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.twitter }
                            helperText={business.error?.twitter}
                        />
                        <TextField
                            fullWidth
                            hidden={true}
                            label="Instagram"
                            name="instagram"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.instagram }
                            helperText={business.error?.instagram}
                        />
                        <TextField
                            fullWidth
                            hidden={true}
                            label="Facebook"
                            name="facebook"
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            size="large"
                            defaultValue={ data?.facebook }
                            helperText={business.error?.facebook}
                        />
                    </Stack>
                </Grid>

                { auth.user.role_type !== 'CLIENT' &&
                <>
                    <Grid item xs={12}>
                        <Divider textAlign="left" sx={{ fontSize:'11px'}}>Any other information about the company</Divider>
                    </Grid>
                    <Grid item xs={12} md={12}>

                        <Stack spacing={3} mb={2} >
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label="Remarks"
                                name="remarks"
                                onChange={(e) => handleChange(e)}
                                variant="outlined"
                                size="large"
                                defaultValue={ data?.remarks }
                                helperText={business.error?.remarks}
                            />
                        </Stack>
                    </Grid>


                    <Grid item xs={12}>
                        <Divider textAlign="left" sx={{ fontSize:'11px'}}>Any other information about the company</Divider>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={3}>
                            <AttachDoc multiple={true} setResponse={setResponse} updateFunction={updateBusiness} allowedFileType={['xls','xlsx']} id={'business_id'} onBlur={handleChange} label={"Business Reports"} entity_name={"business"} entity_id={data.business_id} name="report" data={data} state={setData} />
                        </Stack>
                    </Grid>
                </>
                }

                <Toastr response={response} />
            </Grid>
            }
        </>
    )
})


import * as React from 'react';
import {Card, CardContent, CardHeader, Chip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {currencyFormatter} from "../../../../../Setup/Config";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect, useMemo} from "react";
import {fetchService} from "../../Redux/Action";

export const ServiceFundsTable = () => {

    const dispatch = useDispatch();
    const service = useSelector(state => state.services.selected);

    useEffect( () => {
        fetchData();
    },[]);

    const fetchData = async () => {
        await fetchService({dispatch, id:service?.service_id})
    };

    const rows = useMemo(() => {

        if(service?.funding === undefined) return [];

        const totalAmount = service?.funding.reduce((total, funding) => total + parseFloat(funding.amount), 0);
        const fundingRows = service?.funding || [];
        const totalRow = {
            id: 'total',
            wallet: {user: {first_name: '', last_name: '', nationality: 'Total'}},
            amount: totalAmount,
            payment_date: '',
        };
        return [...fundingRows, totalRow];
    }, [service?.funding]);

    const columns = [

        { field: 'name', headerName: 'Investor', type: 'string', flex:1, minWidth:150, valueGetter: (params) => params.row?.wallet?.user?.first_name + ' ' + params.row?.wallet?.user?.last_name },
        { field: 'email', headerName: 'Email', type: 'string', flex:1, minWidth:250, valueGetter: (params) => params.row?.wallet?.user?.email },
        { field: 'nationality', headerName: 'Nationality', type: 'string', flex:1, minWidth:100, valueGetter: (params) => params.row?.wallet?.user?.nationality },
        { field: 'amount', headerName: 'Amount', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
        { field: 'payment_date', headerAlign:'center', align:'center', headerName: 'Funded Date', type: 'date', minWidth: 120, flex:1,
            valueFormatter: (params) => {
                return params.value !== null && params.value !== ''? moment(params.value).format("DD/MM/YYYY") : '';
            },
        },
    ];

    return (
        <Card>
            <CardHeader title={"Funds"} titleTypographyProps={{variant:'subtitle1'}} />
            <CardContent sx={{p:0}}>
                <DataGridPro
                    getRowId={(row) => row.id}
                    rows={ rows }
                    columns={columns}
                    hideFooter={true}
                    sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'scheduled_date', sort: 'asc' }],
                        },
                        aggregation: {
                            model: {
                                amount: 'sum',
                            },
                        },
                    }}
                    autoHeight={true}
                />
            </CardContent>
        </Card>
    );
}


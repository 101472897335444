import React, { useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { setModal, setBlockUI } from "../../../../Common/App";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {FormControl, FormHelperText, InputLabel, Select, Stack, TextField} from "@mui/material";
import {addBusiness} from "../../index";
import {useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Toastr from "../../../../../Components/Elements/Toastr";

export const RegisterBusinessModal = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lov = useSelector((store) => store.app.lov);
    const [disabled, setDisabled] = useState(false);
    const [disabledField, setDisabledField] = useState(false);
    const [response, setResponse] = useState({});

    const [data, setData] = useState({
        name: "",
        trade_license_number: "",
        trade_license_authority: "",
    });

    const handleSubmit = async () => {

        if(data['name'] != null && data['trade_license_number'] != null){

            setDisabled(true);
            dispatch(setBlockUI({open:true}));

            await addBusiness({dispatch, data}).then((res)=>{
                setResponse(res);

                dispatch(setBlockUI({open:false}));
                if(res['success']){
                    setDisabledField(true);
                    dispatch(setModal({component:null, open:false}))
                    navigate('/business/' + res['data'].data.business_id);
                }
            });
        }
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const sortedLov = lov
        .filter(i => i.name === 'trade_license_authority')
        .sort((a, b) => a.label.localeCompare(b.label));

    return(
        <>
            <DialogTitle>Start here</DialogTitle>
            <DialogContent>
                <Stack spacing={2} m={2}>
                    <TextField
                        fullWidth
                        disabled={disabledField}
                        label="Type your business name"
                        name="name"
                        variant="outlined"
                        size="large"
                        value={data?.name}
                        onChange={(e) => handleChange(e)}
                        helperText={response?.error?.data?.errors?.name}
                    />
                    <TextField
                        fullWidth
                        disabled={disabledField}
                        label="Trade license number"
                        name="trade_license_number"
                        variant="outlined"
                        size="large"
                        value={data?.trade_license_number}
                        onChange={(e) => handleChange(e)}
                        helperText={response?.error?.data?.errors?.trade_license_number}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="license-authority-label">Trade License Authority</InputLabel>
                        <Select
                            name="trade_license_authority"
                            label="Trade License Authority"
                            value={data?.trade_license_authority ?? ""}
                            onChange={handleChange}
                        >
                            <MenuItem value="">Select</MenuItem>
                            {
                                sortedLov.map((i, v) => <MenuItem key={v} value={i.value}>{i.label}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>{response?.error?.data?.errors?.trade_license_authority}</FormHelperText>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions sx={{p:3, pt:0}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Create</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}
import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {ChequeForm} from "../Forms/ChequeForm";
import {Card, CardContent, CardHeader} from "@mui/material";
import { addCheque, clearErrors, proceed, setSelectedCheque, ChequeDeleteModal } from "../../index";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect, } from "react";
import {currencyFormatter} from "../../../../../Setup/Config";

export const ChequeCard = (props) => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.cheques).length > 0 ? business.cheques.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addCheque({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCheque(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<ChequeForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<ChequeDeleteModal />, open:true}));
        dispatch(setSelectedCheque(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.cheque_id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status) && auth.user.role_type === 'CLIENT') {
                return ['add-business-cheques'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-cheques') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
        },[business.status, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ];
            }

            return [];

        },[editable]
    );


    const columns = React.useMemo(
        () => [
            { field: 'cheque_id', headerName:'Cheque ID', type: 'string'},
            { field: 'cheque_date', headerName:'Date', type: 'date', flex:1, minWidth: 100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'cheque_no', headerName:'Cheque No', type: 'string',  flex:1, minWidth:90 },
            { field: 'bank_id', headerName:'Bank/Institution', type: 'string', flex:1, minWidth:350,
                valueGetter: (params) => {
                    return params.row?.bank?.bank_name;
                }
            },
            { field: 'party_name', headerName:'Issued To', type: 'string', flex:1, minWidth:160 },
            { field: 'amount', headerName:'Cheque Amount', type: 'number',  flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'return_reason', headerName:'Return Reason', type: 'string', flex:1, minWidth: 150 },
            { field: 'settlement_date', headerName:'Settlement Date', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'case_reference_no', headerName:'Case Reference No', type: 'string', flex:1, minWidth: 150 },
            { field: 'remarks', headerName:'Remarks', type: 'string', flex:1, minWidth: 150 },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                getActions: (params) => actions(params),
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Cheques'}
                    action={ editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button> }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.cheque_id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={items}
                            sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left:['cheque_date'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        cheque_id:false,
                                        actions: editable(),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}
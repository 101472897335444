import React, {useEffect} from 'react'
import {fetchBanks, fetchLOV, } from "../../../Common/App";
import { setModal } from "../../../Common/App";
import {useDispatch} from "react-redux";
import {DashboardAdministration} from "./DashboardAdministration";
import {fetchStats} from "../Redux/Action";
import {fetchBusinesses} from "../../../Corporate/Business";

export const Dashboard = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetchBusinesses({dispatch,data:{}})
        fetchBanks({dispatch})
        fetchLOV({dispatch})
        fetchStats({dispatch})
        dispatch(setModal({ component:null, open: false}))
    },[]);

    return (
        <>
            {
                <DashboardAdministration />
            }
        </>
    );
}

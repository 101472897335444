import * as React from 'react';
import {Box, Card , CardContent, CardHeader, Grid} from "@mui/material";
import {RepaymentTable} from "../index";
import {Breadcrumbs} from "../../../../Components";

export const Repayment = () => {

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/REPAYMENTS'} sx={{display:'inline-block'}} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Repayments" />
                        <CardContent sx={{p:0}}>
                            <RepaymentTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
    );
}
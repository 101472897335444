import * as React from 'react';
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {useDispatch, useSelector} from "react-redux";
import {setRightDrawer} from "../../Modules/Common/App";

export default function RightDrawer() {

    const app = useSelector((store) => store.app);
    const dispatch = useDispatch();
    const open = app.rightDrawer.open;
    const drawerWidth = app.rightDrawer.width;
    const component = app.rightDrawer.component;

    const Drawer = styled(MuiDrawer)(
        () => ({
            zIndex: 9999,
                ...({
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    }
                })
        }
    ));

    return (
        <>
            <Drawer
                transition="true"
                anchor="right"
                open={open}
                onClose={ () => dispatch(setRightDrawer({component:null, open:false})) }
                sx={{ width:drawerWidth, maxWidth:'500px', left:'auto'}}
            >
                {component ?? null}
            </Drawer>
        </>
    );
}

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const setSelectedTask = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchTaskSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchTaskFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchTask = ({dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`task/${id}`),
        [fetchTaskSuccess],
        [fetchTaskFailed]
    );
};

export const fetchTasksSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchTasksFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchTasks = ({dispatch, filters}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('task', { params: filters }),
        [fetchTasksSuccess],
        [fetchTasksFailed]
    );
};

export const addTaskSuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload
});

export const addTaskFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload
});

export const addTask = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('task', data),
        [addTaskSuccess],
        [addTaskFailed]
    );
};

export const updateTaskSuccess = (payload) => ({
    type: CONST.UPDATE_ITEM_SUCCESS,
    payload: payload
});

export const updateTaskFailed = (payload) => ({
    type: CONST.UPDATE_ITEM_FAILED,
    payload: payload
});

export const updateTask = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`task/${data['task_id']}`, data),
        [updateTaskSuccess],
        [updateTaskFailed]
    );
};

export const deleteTaskSuccess = (payload) => ({
    type: CONST.DELETE_ITEM_SUCCESS,
    payload: payload
});

export const deleteTaskFailed = (payload) => ({
    type: CONST.DELETE_ITEM_FAILED,
    payload: payload
});

export const deleteTask = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`task/${data['task_id']}`),
        [deleteTaskSuccess],
        [deleteTaskFailed]
    );
};

import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {VatReturnForm} from "../Forms/VatReturnForm";
import {Card, CardContent, CardHeader} from "@mui/material";
import { addVatReturn,  clearErrors, proceed, setSelectedVatReturn, VatReturnDeleteModal } from "../../index";
import Typography from "@mui/material/Typography";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment/moment";
import {useEffect} from "react";
import {currencyFormatter} from "../../../../../Setup/Config";
import {Link} from "react-router-dom";

export const VatReturnCard = (props) => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const documents = useSelector((store) => store.business.selected?.documents);
    let items = Object.keys(business.vatReturns).length > 0 ? business.vatReturns.list.filter(i => i.is_draft !== 1) : [];
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const documentLink = (document_type, value, id) => {

        const type = {
            'vat': 'vat_return_doc',
        };

        if (documents && documents.length > 0) {
            let doc = documents.filter((doc) => doc.type === type[document_type] && doc.entity_id === id);

            if (doc.length === 1) {
                return (
                    <Link style={{ textDecoration: 'none' }} to={process.env.REACT_APP_BASE_URL + 'document/' + doc[0].document_id} target="_blank" rel="noopener noreferrer">
                        {moment(value).format("DD/MM/YYYY")}
                    </Link>
                );
            }
        }

        return moment(value).format("DD/MM/YYYY");
    };

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addVatReturn({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedVatReturn(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<VatReturnForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<VatReturnDeleteModal />, open:true}));
        dispatch(setSelectedVatReturn(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status) && auth.user.role_type === 'CLIENT') {
                return ['add-business-vat-returns'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-vat-returns') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
            return false;
        },[business.status, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />
                ];
            }

            return [];

        },[editable]
    );

    const columns = React.useMemo(
        () => [
            { field: 'period_from', headerName:'Period From', type: 'string', flex:1, minWidth: 120,
                renderCell: (params) => documentLink('vat', params.row.period_from, params.id)
            },
            { field: 'period_to', headerName:'Period To', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'turnover_amount', headerName:'Sales Amt', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'expenses_amount', headerName:'Expenses Amt', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'vat_payable', headerName:'Payable Amt', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'payment_date', headerName:'Payment Date', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'penalty_amount', headerName:'Penalty Amt', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'vat_penalty_reason', headerName:'VAT Penalty Reason', type: 'string', flex:1, minWidth: 180 },
            { field: 'actions', flex:1, minWidth: 120, type: 'actions', headerName:'Action', getActions: (params) => actions(params)},
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'VAT Returns'}
                    action={editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button>}
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={items || []} sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left: ['company_name'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        actions: editable(),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}
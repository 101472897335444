const CONST = {
    'SET_CONFIG':'STATEMENTS/SET_CONFIG',
    'RESET_ALL':'STATEMENTS/RESET_ALL',
    'SET_SELECTED_ITEM':'STATEMENTS/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'STATEMENTS/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'STATEMENTS/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'STATEMENTS/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'STATEMENTS/FETCH_ITEMS_FAILED',
    'FETCH_SUMMARY_SUCCESS':'STATEMENTS/FETCH_SUMMARY_SUCCESS',
    'FETCH_SUMMARY_FAILED':'STATEMENTS/FETCH_SUMMARY_FAILED',
    'ADD_ITEM_SUCCESS':'STATEMENTS/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'STATEMENTS/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'STATEMENTS/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'STATEMENTS/UPDATE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'STATEMENTS/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'STATEMENTS/DELETE_ITEM_SUCCESS',
}

export default CONST;
import * as React from 'react';
import {Avatar, Box, Card, CardActions, CardContent, Stack, Tab, Tabs} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";


export const ProfileOverviewPanel = ({value, setValue}) => {

    const profile = useSelector((store) => store.profile.selected);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card>
            <CardContent sx={{p:0}}>
                <Stack direction={"row"} spacing={2} sx={{p:2}}>
                    <Avatar variant={"rounded"}  sx={{width: 100, height: 100}} />
                    <Stack spacing={1} flexGrow={1}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Box>
                                <Typography variant={"h5"}>{profile?.first_name + ' ' + profile?.last_name}</Typography>
                                <Typography variant={"subtitle1"}>{profile?.investor_type}</Typography>
                            </Box>
                            <Box>
                                <Button variant={"contained"} color={"primary"}>Upgrade</Button>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Personal"  />
                    <Tab label="Account"  />
                    <Tab label="Address" />
                    <Tab label="Bank Details" />
                    <Tab label="Employment" />
                    <Tab label="Settings" />
                </Tabs>
            </CardActions>
        </Card>
    );
}
import React, { useState} from 'react'
import { TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import {setModal} from "../../../../Common/App";
import { rejectBusiness } from "../../index";
import {useNavigate, } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export const DeclineBusinessModal = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();

    const handleSubmit = async () => {
        let data = {
            'reason': reason,
            'business_id': props.business_id
        }
        setDisabled(true);
        await rejectBusiness({dispatch, data});
        dispatch(setModal({component:null, open:false}));
        navigate('/business');
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value <= 0);
    }

    return(
        <>
            <DialogTitle>Reject Business</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to reject this business?
                </DialogContentText>
                <TextField
                    fullWidth
                    label={"Reason for Rejection"}
                    multiline
                    rows={4}
                    required
                    onBlur={handleReasonChange}
                    type={"text"}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'error'} onClick={ handleSubmit }>Reject Business</Button>
            </DialogActions>
        </>
    )
}

import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {isBrowser} from "react-device-detect";

export default function MuiDatepicker(props) {

    const handleChange = (newValue) => {
        if (typeof props.setDisabled === 'function') {
            props.setDisabled(false);
        }
        props.state({
            ...props.data,
            [props.name] : newValue?.format(props.format || "YYYY-MM-DD"),
        })
    };

    const errorText = Array.isArray(props?.error) ? props?.error.join(', ') : props?.error;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {isBrowser ?
            <DesktopDatePicker
                className={props?.className}
                sx={{ width: '100%' }}
                views={props.views || ['year', 'month', 'day']}
                label={props.label}
                format={props.format || "DD/MM/YYYY"}
                value={props?.data[props?.name] ? dayjs(props?.data[props?.name]) : null}
                onChange={handleChange}
                disabled={props?.disabled}
                disableFuture={ props?.past !== undefined && props?.past }
                disablePast={ props?.past !== undefined ? !props?.past : false }
                slotProps={{ textField: { helperText: errorText } }}
                renderInput={(params) => <TextField {...params}  />}
            />
                :
                <DatePicker
                    className={props?.className}
                    sx={{ width: '100%' }}
                    views={props.views || ['year', 'month', 'day']}
                    label={props.label}
                    format={props.format || "DD/MM/YYYY"}
                    value={dayjs(props?.data[props?.name]) || null}
                    onChange={handleChange}
                    disabled={props?.disabled}
                    disableFuture={ props?.past !== undefined && props?.past }
                    disablePast={ props?.past !== undefined ? !props?.past : false }
                    renderInput={(params) => <TextField {...params} helperText={"asdasd"} />}
                />
            }
        </LocalizationProvider>
    );
}
import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected: {},
    code:undefined,
    error:[],
    message:[],
    config:{
        filters:{
            page:0,
            pageSize:10,
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc'
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.SET_SELECTED_NOTIFICATION:
            return {
                ...state,
                selected: state.list.filter(item => item.id === action.payload)[0],
            }
        case CONST.FETCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                code:200,
                message: ["Notifications loaded successfully"]
            }
        case CONST.FETCH_NOTIFICATION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                list: action.payload.data.data,
                error: [],
                message: ["Notifications loaded successfully"]
            }
        case CONST.FETCH_NOTIFICATIONS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_NOTIFICATION_SUCCESS:
            return {
                ...state,
                list:[...state.list, ...action.payload.data],
                selected: {},
                error: [],
                message: ["Notifications added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_NOTIFICATION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_NOTIFICATION_SUCCESS:
            const indexU = state.list.findIndex(notification => notification.id === action.payload.data.id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Notifications updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_NOTIFICATION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.id !== action.payload),
                selected: {},
                error: [],
                message: ["Notifications deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_NOTIFICATION_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
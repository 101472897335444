import * as React from 'react';
import {useDispatch} from "react-redux";
import {disbursed} from "../../Redux/Action";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {setModal} from "../../../../Common/App";

export const DisburseLoanModal = ({loan_id}) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setDisabled(true);
        await disbursed({dispatch, data:{loan_id}}).then(res => {
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
                navigate('/business-finance');
            }
        });
    };

    return(
        <>
            <DialogTitle>Request Disbursement</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, the loan is disbursed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Done</Button>
            </DialogActions>
        </>
    )
}
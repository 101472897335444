import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {CustomerSupplierForm} from "../Forms/CustomerSupplierForm";
import {Chip, Grid, List, ListItem, Stack, Typography} from "@mui/material";
import {
    addCustomerSupplier,
    clearErrors,
    proceed,
    setSelectedCustomerSupplier,
    CustomerSupplierDeleteModal
} from "../../index";
import {useState} from "react";
import moment from "moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";

export const CustomerSupplierStep = () => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.customersSuppliers).length > 0 ? business.customersSuppliers.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{

        if(e.target.dataset.action === 'AddItem'){

            await addCustomerSupplier({dispatch, data:{ business_id: business.selected.business_id, is_customer:parseInt(e.target.dataset.is_customer)}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedCustomerSupplier(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<CustomerSupplierForm formName={e.target.dataset.form_title} formSubTitle={e.target.dataset.sub_title} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<CustomerSupplierDeleteModal />, open:true}));
        dispatch(setSelectedCustomerSupplier(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'company_name', headerName:'Name', type: 'string', flex:1, minWidth:350},
            { field: 'is_customer', headerName:'Type', type: 'string', flex:1, minWidth:110,
                renderCell: (params) => {
                    const type = {
                        0:'warning',
                        1:'info',
                    }
                    const val = {
                        0:'Supplier',
                        1:'Customer',
                    }
                    return(<StatusBall
                        variant={"contained"}
                        label={val[params.value]}
                        color={type[params.value]}
                    />)
                },
            },
            { field: 'dealing_since', headerName:'Dealing Since', type: 'date', flex:1, minWidth: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'payment_terms', headerName:'Payment Terms', type: 'string', flex:1, minWidth:120},
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        data-form_title={params.row.is_customer ? "Customers" : "Suppliers"}
                        data-sub_title={"Provide the details of your top " + (params.row.is_customer ? "customers" : "suppliers")}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        data-form_title={params.row.is_customer ? "Customers" : "Suppliers"}
                        data-sub_title={"Provide the details of your top " + (params.row.is_customer ? "customers" : "suppliers")}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>

            <Grid item xs={12}  >
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.customer_id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items || []} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" color={"info"} data-id={"draft"} data-form_title={"Customers"} data-sub_title={"Provide the details of your top customers"} data-is_customer={1} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Customer</Button>
                            <Button variant="contained" color={"info"} data-id={"draft"} data-form_title={"Suppliers"} data-sub_title={"Provide the details of your top suppliers"} data-is_customer={0} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Supplier</Button>
                        </Stack>
                    </Grid>
                    { items && items.length === 0 &&
                        <Grid item xs={12}>
                            <Instructions />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
                <Typography variant={"h3"}>Customers & Suppliers </Typography>
                <Typography variant={"body1"}>
                    To complete this section, please provide the details of your top 5 customers and suppliers. Please fill the details about the customer/supplier as mentioned. Each customer and supplier details need to be filled separately. You will be required to attach the following documents.
                </Typography>
                <List>
                    <ListItem>Customer profile, if available</ListItem>
                    <ListItem>Trade license</ListItem>
                    <ListItem>Customer Ledger (previous 1 year)</ListItem>
                </List>
            </InfoBox>)
}
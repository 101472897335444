import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {fetchColleague} from "../Redux/Action";
import { debounce } from 'lodash';

const filter = createFilterOptions();

export const UserAutocomplete = (props) => {
    const dispatch = useDispatch();
    const items = useSelector((store) => store.users.list);
    const business = useSelector((store) => store.business.selected);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [businessId, setBusinessId] = useState(null);

    useEffect(() => {
        setBusinessId(business.business_id);
    }, [business]);

    const debouncedFetchData = debounce((searchTerm) => {
        if (searchTerm.length >= 3) {
            fetchData(searchTerm);
        }
    }, 500);

    const fetchData = async (searchTerm) => {
        setLoading(true);
        await fetchColleague({ dispatch, filters: { keyword: searchTerm, business_id: businessId } });
        setLoading(false);
    };

    const handleInputChange = (event, value, reason) => {
        if(reason === "input"){
            debouncedFetchData(value);
        }
    };

    const handleChange = (event, value) => {

        if (value && value.user_id) {
            props.setDisabled(false);
            props.setData(prevData => ({
                ...prevData,
                user_id: value.user_id,
            }));
        }

    };

    useEffect(() => {
        if (items.length > 0) {
            setOpen(true);
        }
    }, [items]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onInputChange={handleInputChange}
            onChange={handleChange}
            options={items}
            loading={loading}
            filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();
                return options.filter((option) =>
                    option.first_name.toLowerCase().includes(inputValue) ||
                    option.last_name.toLowerCase().includes(inputValue) ||
                    option.email.toLowerCase().includes(inputValue) ||
                    option.mobile.includes(inputValue)
                );
            }}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
import * as React from 'react';
import {Box, Grid} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {fetchProfile, ProfileOverviewPanel, ProfileTabsPanel} from "../index";
import {useEffect} from "react";
import {useDispatch} from "react-redux";


export const Profile = () => {

    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        fetchData();
    });

    const fetchData = async () => {
        await fetchProfile({dispatch})
    }

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/PROFILE'} sx={{display:'inline-block'}} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ProfileOverviewPanel value={value} setValue={setValue} />
                </Grid>
                <Grid item xs={12}>
                    <ProfileTabsPanel value={value} />
                </Grid>
            </Grid>
    );
}
import React from 'react'
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import { useDispatch } from "react-redux";
import {AddUser, EditUser, DeleteUser, ChangePassword, fetchUser, setSelectedUser, UserTable } from '../index';
import {fetchRoles} from "../../Roles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {setModal, setRightDrawer} from "../../../Common/App";

export const Users = () => {

    const dispatch = useDispatch();

    const changePassword = React.useCallback(
        (id) => () => {
            dispatch(setModal({component:<ChangePassword />, open:true}));
            dispatch(setSelectedUser(id));
        },
        [dispatch],
    );

    const addItem = async () => {
        dispatch(setRightDrawer({component:<AddUser />, open:true}));
        await fetchRoles({dispatch, filters:{}});
    };

    const editItem = React.useCallback(
        (id) => () => {
            dispatch(setRightDrawer({component:<EditUser />, open:true}));
            fetchUser({dispatch, id});
            fetchRoles({dispatch, filters:{}});
        },
        [dispatch],
    );

    const deleteItem = React.useCallback(
        (id) => () => {
            //dispatch(setSelectedUser(id));
            dispatch(setModal({component:<DeleteUser id={id} />, open:true}));
        },
        [dispatch],
    );

    return (
        <>
            <Grid container spacing={2} elevated={3}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/USERS'} />
                        <Button variant="contained" onClick={addItem}>Add User</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={"Users"} />
                        <CardContent sx={{p:0}}>
                            <UserTable editItem={editItem} deleteItem={deleteItem} changePassword={changePassword} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    error: [],
    proceed: false,
    message:[],
    code: "",
    config:{
        total:0,
        filters:{
            page:0,
            pageSize:5,
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){

        case CONST.RESET_ALL:
            return INITIAL_STATE;
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.SET_SELECTED_ITEM:
            return {
                ...state,
                selected: state.list.filter(item => item.service_id === action.payload)[0],
            }
        case CONST.FETCH_ITEM_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Request loaded successfully"],
                code:action.payload.status,
            }
        case CONST.FETCH_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                config:{
                    ...state.config,
                    total:action.payload.data.total
                },
                error: [],
                code:action.payload.status,
                message: ["Requests loaded successfully"],
            }
        case CONST.FETCH_ITEMS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_ITEM_SUCCESS:
            return {
                ...state,
                list:[...state.list, action.payload.data],
                selected: action.payload.data,
                proceed: true,
                error: [],
                message: ["Request added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_ITEM_SUCCESS:
            const index = state.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                list: [...state.list.slice(0, index), action.payload.data, ...state.list.slice(index + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Request updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.APPROVE_ITEM_SUCCESS:
            const indexA = state.list.findIndex(object => object.id === action.payload.data.id);
            return {
                ...state,
                list: [...state.list.slice(0, indexA), action.payload.data, ...state.list.slice(indexA + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Request updated successfully"],
                code:action.payload.status,
            }
        case CONST.APPROVE_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
import * as React from 'react';
import {GridActionsCellItem } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useDispatch, useSelector} from "react-redux";
import {Chip} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {fetchRequest, setConfig, fetchRequests} from "../../";
import moment from "moment/moment";
import {useEffect} from "react";
import {setRightDrawer} from "../../../../Common/App";
import {UpdateRequestForm} from "../Forms/UpdateRequestForm";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const RequestTable = () => {

    const dispatch = useDispatch();
    const requests = useSelector((store) => store.requests);
    const filters = useSelector((store) => store.requests.config.filters);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchItems();
    },[filters]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchRequests({dispatch, data:{...filters}}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            await fetchRequest({dispatch, id}).then((res)=>{
                dispatch(setRightDrawer({component: <UpdateRequestForm />, open: true}));
            });
        },
        [],
    );

    const columns = React.useMemo(
        () => [
            { field: 'listing_id', headerName:'Listing', type: 'string', width:200, valueGetter: (params) => params.row?.user?.first_name},
            { field: 'wallet_id', headerName:'Wallet', type: 'string', width: 150, valueGetter: (params) => params.row?.wallet?.user?.first_name},
            { field: 'amount', headerName:'Amount', type: 'string', width: 80},
            { field: 'type', headerName:'Type', type: 'string', width: 120},
            { field: 'reference', headerName:'Reference', type: 'string', width: 120},
            { field: 'note', headerName:'Note', type: 'string', width: 220},
            { field: 'created_at', headerName:'Created At', type: 'date', width: 180,
                valueFormatter: ({value}) => value ? moment(value).format('DD/MM/YYYY h:MM:SS A') : '-'
            },
            { field: 'status', headerName:'Status', type: 'string', width:130,
                renderCell: (params) => {
                    const status = {
                        'DONE':'success',
                        'PENDING':'warning',
                        'APPROVED':'success',
                        'REJECTED':'error',
                    }
                    return(<StatusBall
                        variant="contained"
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                width: 150,
                headerName: 'Action',
                getActions: (params) => {
                    if (params.row.type !== 'INVESTMENT' && params.row.status === 'PENDING') {
                        return [
                            <GridActionsCellItem
                                icon={<FindInPageIcon />}
                                label="View"
                                onClick={ viewItem(params.id) }
                            />
                        ];
                    }
                    return [];
                },
            },
        ],
        [],
    );

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={requests.list}
                    sx={{ border:'none'}}
                    pagination
                    paginationMode="server"
                    paginationModel={filters}
                    rowCount={requests?.config?.total ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    onPaginationModelChange={(params) => dispatch(setConfig({filters: {...filters, page:params.page, pageSize: params.pageSize}}))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'group', sort: 'asc' }],
                        },
                        pinnedColumns: {  right: ['status','actions'] },
                        pagination: { paginationModel: { ...filters } }
                    }}
                />
            </>
        );
}

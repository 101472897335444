const CONST = {
    'SET_CONFIG':'LISTING/SET_CONFIG',
    'RESET_ALL':'LISTING/RESET_ALL',
    'SET_SELECTED_ITEM':'LISTING/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'LISTING/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'LISTING/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'LISTING/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'LISTING/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'LISTING/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'LISTING/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'LISTING/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'LISTING/UPDATE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'LISTING/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'LISTING/DELETE_ITEM_SUCCESS',
}

export default CONST;
import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {fetchTransaction, setConfig, fetchTransactions} from "../../";
import moment from "moment/moment";
import {useEffect} from "react";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";

export const TransactionTable = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const transactions = useSelector((store) => store.transactions);
    const filters = useSelector((store) => store.transactions.config.filters);
    const pagination = useDeepEqualSelector((store) => store.transactions.config.pagination);
    const [isLoading, setIsLoading] = React.useState(true);

    console.log(filters)

    useEffect(() => {
        fetchItems();
    },[filters]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchTransactions({dispatch, data:{...filters}}).then((res)=>{
            setIsLoading(false);
        });
    }

    const typeDecoder = (type) => {
        switch (type) {
            case 'CREDIT':
                return 'Credit';
            case 'DEBIT':
                return 'Debit';
            case 'INVESTMENT':
                return 'Investment';
            case 'TRANSFER':
                return 'Transfer';
            case 'FEES':
                return 'Charge';
            default:
                return 'N/A';
        }
    }

    const columns = React.useMemo(
        () => [
            { field: 'created_at', headerName:'Created At', type: 'date', width: 180,
                valueFormatter: ({value}) => value ? moment(value).format('DD/MM/YYYY h:MM:SS A') : '-'
            },
            { field: 'listing_id', headerName:'Reference', type: 'string', width:120,
                valueGetter: (params) => params.row?.listing_id,
                getActions: (params) => navigate(`/investor/listings/${params.row?.listing_id}`)
            },
            { field: 'business_id', headerName:'Business', type: 'string', width:250, valueGetter: (params) => params.row?.listing?.business?.name},
            { field: 'type', headerName:'Type', type: 'string', width: 100, valueGetter: (params) => typeDecoder(params.row?.type)},
            { field: 'credit', align:'right', headerAlign:'right', headerName:'Credit', type: 'string', width: 100},
            { field: 'debit', align:'right', headerAlign:'right', headerName:'Debit', type: 'string', width: 100},
            { field: 'balance', align:'right', headerAlign:'right', headerName:'Balance', type: 'string', width: 100},
        ],
        [],
    );

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={transactions.list}
                    sx={{ border:'none'}}
                    pagination
                    paginationMode="server"
                    paginationModel={filters}
                    rowCount={transactions?.config?.rowCount ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                    onPageChange={(newPageNo) => dispatch(setConfig({pagination: {...pagination, page: newPageNo}}))}
                    onPageSizeChange={(newPageSize) => dispatch(setConfig({pagination: {...pagination, pageSize: newPageSize}}))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'group', sort: 'asc' }],
                        },
                        pinnedColumns: {  right: ['status','actions'] },
                        pagination: { paginationModel: { ...filters } }
                    }}
                />
            </>
        );
}

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

/****************************************
 SET SELECTED PERSONAL BORROWINGS
 ****************************************/
export const setSelectedOtherBorrowing = (payload) => ({
    type: CONST.SET_SELECTED_OTHER_BORROWING,
    payload: payload
});

/****************************************
 FETCH PERSONAL BORROWINGS
 ****************************************/
export const fetchOtherBorrowingsSuccess = (payload) => ({
    type: CONST.FETCH_OTHER_BORROWINGS_SUCCESS,
    payload: payload
});

export const fetchOtherBorrowingsFailed = (payload) => ({
    type: CONST.FETCH_OTHER_BORROWINGS_FAILED,
    payload: payload
});

export const fetchOtherBorrowings = async ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/business/${business_id}/personalBorrowing`),
        [fetchOtherBorrowingsSuccess],
        [fetchOtherBorrowingsFailed]
    );
};

/****************************************
 ADD Business PERSONAL BORROWINGS
 ****************************************/
export const addOtherBorrowingSuccess = (payload) => ({
    type: CONST.ADD_OTHER_BORROWING_SUCCESS,
    payload: payload
});

export const addOtherBorrowingFailed = (payload) => ({
    type: CONST.ADD_OTHER_BORROWING_FAILED,
    payload: payload
});

export const addOtherBorrowing = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/personalBorrowing', data),
        [addOtherBorrowingSuccess],
        [addOtherBorrowingFailed]
    );
};

/****************************************
 Update Business PERSONAL BORROWINGS
 ****************************************/
export const updateOtherBorrowingSuccess = (payload) => ({
    type: CONST.UPDATE_OTHER_BORROWING_SUCCESS,
    payload: payload
});

export const updateOtherBorrowingFailed = (payload) => ({
    type: CONST.UPDATE_OTHER_BORROWING_FAILED,
    payload: payload
});

export const updateOtherBorrowing = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/personalBorrowing/${data.id}`, data),
        [updateOtherBorrowingSuccess],
        [updateOtherBorrowingFailed]
    );
};

/****************************************
 DELETE Business PERSONAL BORROWINGS
 ****************************************/
export const deleteOtherBorrowingSuccess = (payload) => ({
    type: CONST.DELETE_OTHER_BORROWING_SUCCESS,
    payload: payload
});

export const deleteOtherBorrowingFailed = (payload) => ({
    type: CONST.DELETE_OTHER_BORROWING_FAILED,
    payload: payload
});

export const deleteOtherBorrowing = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/personalBorrowing/${id}`),
        [deleteOtherBorrowingSuccess],
        [deleteOtherBorrowingFailed]
    );
};

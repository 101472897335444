import {useDispatch} from "react-redux";
import {Link as LinkUI, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {setBlockUI} from "../../../App";
import {styled} from "@mui/material/styles";
import {Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";
import {doForgotPassword} from "../../../../Administration/Users";
import {useDomain} from "../../../../../Hooks/useDomain";
import {Loader} from "../../../../../Components/Elements/Loader";
import Alert from '@mui/material/Alert';

export const ForgotPasswordForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef();
    const subDomain = useDomain();
    const [response, setResponse] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        dispatch(setBlockUI({open:false}));
        userRef.current.focus();
    }, [])

    const [data, setData] = useState({
        username : '',
    })
    const handleSubmit = async () => {
        setIsLoading(true);
        setDisabled(true)
        dispatch(setBlockUI({open:true}));

        await doForgotPassword({dispatch, data}).then((res) => {
            if(res['success']) {
                setSuccessMessage("A password reset link has been sent to your email. Please check your email and follow the instructions to reset your password.");
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            }
            dispatch(setBlockUI({open:false}));
            setResponse(res);
            setIsLoading(false);
        });
    };

    const handleChange = (e) => {

        setData(data => ({
            ...data,
            [e.target.id] :e.target.value,
        }));
    }

    const Link = styled(LinkUI)(({ theme }) => ({
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontSize:'1.2rem',
        '&:hover': {
            cursor:'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    return (
        <Stack spacing={3} sx={{width:400}}>

            <Typography variant={"h3"} sx={{fontWeight:'300', fontSize:40, fontFamily:'Open Sans'}} color={"primary"}>Forgot Password</Typography>

            <>
                <TextField
                    fullWidth
                    type="text"
                    id="username"
                    ref={userRef}
                    onChange={(e) => handleChange(e)}
                    value={data['username']}
                    required
                    autoComplete="off"
                    label="Registered Email ID"
                />

            </>

            { successMessage && <Alert severity="success">{successMessage}</Alert> }

            { isLoading ?
                <Loader  type={'linear'}/> :
                <Button disabled={disabled} color="primary" sx={{ width:'100%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Request Password Change</Button>
            }

            <Stack direction={"row"} spacing={2} justifyContent="space-between">
                <Link to={'/login'} className={'mb-3 d-block text-right'}>Login</Link>
                { !['admin', 'backoffice-dev', 'office'].includes(subDomain) && <Link to={'/register'}>Register Now</Link> }
            </Stack>
            <Toastr response={response} />
        </Stack>
    )

}
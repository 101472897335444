import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    error: [],
    test:{},
    message:[],
    code: "",
    config:{
        from:0,
        to:0,
        lastPage:1,
        rowCount:0,
        pagination:{
            page:0,
            pageSize:10,
        },
        filters:{
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.CLEAR_ERROR_STATE:
            return {
                ...state,
                error:[],
            }
        case CONST.SET_SELECTED_ITEM:
            return {
                ...state,
                selected: action.payload === null ? {} : state.list.filter(item => item.invoice_id === action.payload)[0],
                error:[],
                code:200
            }
        case CONST.FETCH_ITEM_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                code:action.payload.status,
                message: ["Summary loaded successfully"]
            }
        case CONST.FETCH_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: action.payload.data,
                error: [],
                code:action.payload.status,
                message: ["Summary loaded successfully"]
            }
        case CONST.FETCH_SUMMARY_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                error: [],
                message: ["Summarys loaded successfully"]
            }
        case CONST.FETCH_ITEMS_FAILED:
            return {
                ...state,
                error: [],
                message: [action.payload]
            }
        case CONST.ADD_ITEM_SUCCESS:

            const updatedList = [];
            updatedList.push(action.payload.data);
            console.log(updatedList)
            return {
                ...state,
                list: updatedList,
                selected: action.payload.data,
                error: [],
                proceed:true,
                message: ["Summary added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                selected: {},
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.UPDATE_ITEM_SUCCESS:
            const indexUI = state.list.findIndex(object => object.invoice_id === action.payload.data.invoice_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexUI), action.payload.data, ...state.list.slice(indexUI + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Summary updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_ITEM_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                proceed: false,
                message: [action.payload.data.message],
                code:action.payload.status,
            }
        case CONST.DELETE_ITEM_SUCCESS:
            const indexDI = state.list.findIndex(o => o.invoice_id === action.payload.data);
            return {
                ...state,
                list:[...state.list.slice(0,indexDI), ...state.list.slice(indexDI + 1)],
                selected:{},
                error: [],
                proceed:true,
                message: ["Summary deleted successfully"],
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer;
import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

/****************************************
 SET SELECTED CUSTOMER_SUPPLIER
 ****************************************/
export const setSelectedCustomerSupplier = (payload) => ({
    type: CONST.SET_SELECTED_CUSTOMER_SUPPLIER,
    payload: payload
});

/****************************************
 FETCH CUSTOMER_SUPPLIER
 ****************************************/
export const fetchCustomerSuppliersSuccess = (payload) => ({
    type: CONST.FETCH_CUSTOMER_SUPPLIERS_SUCCESS,
    payload: payload
});

export const fetchCustomerSuppliersFailed = (payload) => ({
    type: CONST.FETCH_CUSTOMER_SUPPLIERS_FAILED,
    payload: payload
});

export const fetchCustomerSuppliers = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/customer', { params: data }),
        [fetchCustomerSuppliersSuccess],
        [fetchCustomerSuppliersFailed]
    );
};

/****************************************
 ADD Business CUSTOMER_SUPPLIER
 ****************************************/
export const addCustomerSupplierSuccess = (payload) => ({
    type: CONST.ADD_CUSTOMER_SUPPLIER_SUCCESS,
    payload: payload
});

export const addCustomerSupplierFailed = (payload) => ({
    type: CONST.ADD_CUSTOMER_SUPPLIER_FAILED,
    payload: payload
});

export const addCustomerSupplier = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/customer', data),
        [addCustomerSupplierSuccess],
        [addCustomerSupplierFailed]
    );
};

/****************************************
 Update Business CUSTOMER_SUPPLIER
 ****************************************/
export const updateCustomerSupplierSuccess = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_SUPPLIER_SUCCESS,
    payload: payload
});

export const updateCustomerSupplierFailed = (payload) => ({
    type: CONST.UPDATE_CUSTOMER_SUPPLIER_FAILED,
    payload: payload
});

export const updateCustomerSupplier = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/customer/${data.customer_id}`, data),
        [updateCustomerSupplierSuccess],
        [updateCustomerSupplierFailed]
    );
};

/****************************************
 DELETE Business CUSTOMER_SUPPLIER
 ****************************************/
export const deleteCustomerSupplierSuccess = (payload) => ({
    type: CONST.DELETE_CUSTOMER_SUPPLIER_SUCCESS,
    payload: payload
});

export const deleteCustomerSupplierFailed = (payload) => ({
    type: CONST.DELETE_CUSTOMER_SUPPLIER_FAILED,
    payload: payload
});

export const deleteCustomerSupplier = ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/customer/${id}`),
        [deleteCustomerSupplierSuccess],
        [deleteCustomerSupplierFailed]
    );
};

import React from 'react';
import VatAttachDoc from "../VatAttachDoc";

const UploadVatReturns = () => {
  return (
    <>
      <VatAttachDoc
        name="vat_document"  // Ensure each prop has a value assigned
        label="Upload VAT Document"
        allowedFileType={['pdf', 'xlsx']}
        multiple
        data={null}  // Example, replace with actual data if needed
        setResponse={null}
      />
    </>
  );
};

export default UploadVatReturns;

const CONST = {
    'FETCH_CONFIGS_SUCCESS':'DASHBOARD/FETCH_CONFIGS_SUCCESS',
    'FETCH_CONFIGS_FAILED':'DASHBOARD/FETCH_CONFIGS_FAILED',
    'FETCH_STATS_SUCCESS':'DASHBOARD/FETCH_STATS_SUCCESS',
    'FETCH_STATS_FAILED':'DASHBOARD/FETCH_STATS_FAILED',
    'FETCH_MONTHLY_STATS_SUCCESS':'DASHBOARD/FETCH_MONTHLY_STATS_SUCCESS',
    'FETCH_MONTHLY_STATS_FAILED':'DASHBOARD/FETCH_MONTHLY_STATS_FAILED',
    'SET_UPCOMING_PAYMENT_CONFIG':'DASHBOARD/SET_UPCOMING_PAYMENT_CONFIG',
    'FETCH_UPCOMING_PAYMENTS_SUCCESS':'DASHBOARD/FETCH_UPCOMING_PAYMENTS_SUCCESS',
    'FETCH_UPCOMING_PAYMENTS_FAILED':'DASHBOARD/FETCH_UPCOMING_PAYMENTS_FAILED',
    'SET_OVERDUE_PAYMENT_CONFIG':'DASHBOARD/SET_OVERDUE_PAYMENT_CONFIG',
    'FETCH_OVERDUE_PAYMENTS_SUCCESS':'DASHBOARD/FETCH_OVERDUE_PAYMENTS_SUCCESS',
    'FETCH_OVERDUE_PAYMENTS_FAILED':'DASHBOARD/FETCH_OVERDUE_PAYMENTS_FAILED',
    'SET_BUSINESS_OVERVIEW_CONFIG':'DASHBOARD/SET_BUSINESS_OVERVIEW_CONFIG',
    'FETCH_BUSINESS_OVERVIEW_SUCCESS':'DASHBOARD/FETCH_BUSINESS_OVERVIEW_SUCCESS',
    'FETCH_BUSINESS_OVERVIEW_FAILED':'DASHBOARD/FETCH_BUSINESS_OVERVIEW_FAILED',
    'SET_SERVICE_REQUEST_CONFIG':'DASHBOARD/SET_SERVICE_REQUEST_CONFIG',
    'SET_SELECTED_SERVICE':'DASHBOARD/SET_SELECTED_SERVICE',
    'FETCH_SERVICES_SUCCESS':'DASHBOARD/FETCH_SERVICES_SUCCESS',
    'FETCH_SERVICES_FAILED':'DASHBOARD/FETCH_SERVICES_FAILED',
    'SET_TASK_CONFIG':'DASHBOARD/SET_TASK_CONFIG',
    'SET_SELECTED_TASK':'DASHBOARD/SET_SELECTED_TASK',
    'FETCH_TASKS_SUCCESS':'DASHBOARD/FETCH_TASKS_SUCCESS',
    'FETCH_TASKS_FAILED':'DASHBOARD/FETCH_TASKS_FAILED',
    'UPDATE_EXPIRED_DOCUMENT_SUCCESS':'DASHBOARD/UPDATE_EXPIRED_DOCUMENT_SUCCESS',
    'UPDATE_EXPIRED_DOCUMENT_FAILED':'DASHBOARD/UPDATE_EXPIRED_DOCUMENT_FAILED',
}

export default CONST;
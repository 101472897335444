import * as React from 'react';
import {useDispatch} from "react-redux";
import {sendCustomEmail} from "../../Redux/Action";
import {Button, DialogActions, DialogContentText, DialogTitle, Grid, DialogContent} from "@mui/material";
import {useState} from "react";
import {setModal} from "../../../../Common/App";
import {TextField} from "@mui/material";

export const SendCustomEmailModal = ({row}) => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [data, setData] = useState({
        business_id: row['business_id'],
        to: row['owner']['email'],
        subject: 'Default Loan Notification',
        message: '',
    });

    const handleSubmit = async () => {
        setDisabled(true);
        await sendCustomEmail({dispatch, data}).then(res => {
            setDisabled(false);
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    return(
        <>
            <DialogTitle>Send Email</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="To"
                                name="to"
                                defaultValue={data?.to}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.to}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Subject"
                                name="subject"
                                defaultValue={data?.subject}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.subject}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                rows={6}
                                multiline={true}
                                fullWidth
                                label="Message"
                                name="message"
                                defaultValue={data?.message}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.message}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Send</Button>
            </DialogActions>
        </>
    )
}
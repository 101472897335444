import * as React from 'react';
import { CardContent, CardHeader, IconButton, Button, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelList } from "../../../../../Components";
import { setModal } from "../../../../Common/App";
import { RelatedBusinessModal } from "../Modals/RelatedBusinessModal";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {unRelateBusiness} from "../../Redux/Action";

export const RelatedBusinessPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const permissions = useSelector((state) => state.app.permissions);
    const related = useSelector((state) => state.business.selected.related) || [];
    const auth = useSelector((store) => store.auth);

    const handleRemoveRelated = async (item) => {

        await unRelateBusiness({ dispatch, data: { business_id: item.business_id, related_business_id: item.related_business_id } });

    };

    return (
        <>
            <Panel>
                <CardHeader
                    title="Related Businesses"
                    titleTypographyProps={{ variant: 'headline' }}
                    action={
                        (['add-related-business'].some(i => permissions.includes(i)))
                        && <Button onClick={() => dispatch(setModal({ component: <RelatedBusinessModal />, open: true }))}>Add</Button>}
                />
                <CardContent>
                    <PanelList>
                        {related.map((item, index) => {
                            return (
                                <ListItem key={index} disablePadding
                                          secondaryAction={
                                              (['remove-related-business'].some(i => permissions.includes(i)) || auth.user.role_type === 'ADMIN') &&
                                              <IconButton onClick={() => handleRemoveRelated(item)}>
                                                  <DeleteIcon />
                                              </IconButton>
                                          }
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                component="a"
                                                onClick={() => navigate(`/business/${item?.related_business_id}`)}
                                                sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'inherit' }}
                                            >
                                                {item?.related_business?.name}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            )
                        })}
                    </PanelList>
                </CardContent>
            </Panel>
        </>
    );
};

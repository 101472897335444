import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";

const filter = createFilterOptions();

export default function BankAutocomplete(props) {


    const banks = useSelector((store) => store.app.banks);

    const sortedBanks = React.useMemo(() => {
        return [...banks].sort((a, b) => a.bank_name.localeCompare(b.bank_name));
    }, [banks]);

    const [value, setValue] = React.useState(banks.filter(bank => bank.bank_id === props.data?.bank?.bank_id)[0] || '');

    const handleChange = (event, newValue) => {
        //Activate the submit button
        props.setDisabled(false);

        if (typeof newValue === 'string') {
            setValue({
                bank_name: newValue,
            });
            props.setData(() => ({
                ...props.data,
                ['bank_id']: newValue,
            }));
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
                bank_name: newValue.inputValue,
            });
            props.setData(() => ({
                ...props.data,
                ['bank_id']: "new:"+newValue.inputValue,
            }));
        } else {
            setValue(newValue);

            props.setData(() => ({
                ...props.data,
                ['bank_id']: newValue?.bank_id,
            }));
        }
    }

    const StyledAutocomplete = styled(Autocomplete)({
        "& .MuiChip-label":{
            color:"Black"
        },
        "&.MuiAutocomplete-root .MuiFormLabel-root":{
            color:"Gray",
            top:'inherit',
        },
        "& .MuiButtonBase-root":{
            color:"Black"
        }
    });

    return (
        <StyledAutocomplete
            fullWidth
            defaultValue={banks.filter(bank => bank.bank_id === props.data?.bank?.bank_id)[0] || ''}
            value={value}
            onChange={handleChange}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.bank_name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        bank_name: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={sortedBanks}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.bank_name;
            }}
            renderOption={(props, option) => <li {...props}>{option.bank_name}</li>}
            freeSolo
            renderInput={(params) => (
                <TextField fullWidth {...params} label="Bank Name" />
            )}
        />
    );
}
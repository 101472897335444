import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const resetFundings = (payload) => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedFunding = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchFundingSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchFundingFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchFunding = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/funding/${id}`),
        [fetchFundingSuccess],
        [fetchFundingFailed]
    );
};

export const fetchFundingsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchFundingsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchFundings = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/funding', { params: data }),
        [fetchFundingsSuccess],
        [fetchFundingsFailed]
    );
};


export const addFundingSuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload
});

export const addFundingFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload
});

export const addFunding = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`investor/funding`, data),
        [addFundingSuccess],
        [addFundingFailed]
    );
};
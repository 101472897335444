const CONST = {
    'SET_CONFIG':'NOTIFICATION/SET_CONFIG',
    'FETCH_NOTIFICATION_SUCCESS':'NOTIFICATION/FETCH_NOTIFICATION_SUCCESS',
    'FETCH_NOTIFICATION_FAILED':'NOTIFICATION/FETCH_NOTIFICATION_FAILED',
    'FETCH_NOTIFICATIONS_SUCCESS':'NOTIFICATION/FETCH_NOTIFICATIONS_SUCCESS',
    'FETCH_NOTIFICATIONS_FAILED':'NOTIFICATION/FETCH_NOTIFICATIONS_FAILED',
    'SET_SELECTED_NOTIFICATION':'NOTIFICATION/SET_SELECTED_NOTIFICATION',
    'ADD_NOTIFICATION_SUCCESS':'NOTIFICATION/ADD_NOTIFICATION_SUCCESS',
    'ADD_NOTIFICATION_FAILED':'NOTIFICATION/ADD_NOTIFICATION_FAILED',
    'UPDATE_NOTIFICATION_SUCCESS':'NOTIFICATION/UPDATE_NOTIFICATION_SUCCESS',
    'UPDATE_NOTIFICATION_FAILED':'NOTIFICATION/UPDATE_NOTIFICATION_FAILED',
    'DELETE_NOTIFICATION_SUCCESS':'NOTIFICATION/DELETE_NOTIFICATION_SUCCESS',
    'DELETE_NOTIFICATION_FAILED':'NOTIFICATION/DELETE_NOTIFICATION_SUCCESS',
}

export default CONST;
import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const setSelectedRole = (payload) => ({
    type: CONST.SET_SELECTED_ROLE,
    payload: payload
});

export const fetchRoleSuccess = (payload) => ({
    type: CONST.FETCH_ROLE_SUCCESS,
    payload: payload
});

export const fetchRoleFailed = (payload) => ({
    type: CONST.FETCH_ROLE_FAILED,
    payload: payload
});

export const fetchRole = ({dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`role/${id}`),
        [fetchRoleSuccess],
        [fetchRoleFailed]
    );
};

export const fetchRolesSuccess = (payload) => ({
    type: CONST.FETCH_ROLES_SUCCESS,
    payload: payload
});

export const fetchRolesFailed = (payload) => ({
    type: CONST.FETCH_ROLES_FAILED,
    payload: payload
});

export const fetchRoles = ({dispatch, filters}) => {

    let url = 'role';

    if (filters && filters['all'] !== undefined && filters['all'] === true) {
        delete filters['all'];
        url = 'role/quickList';
    }

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(url, { params: filters }),
        [fetchRolesSuccess],
        [fetchRolesFailed]
    );
};

export const addRoleSuccess = (payload) => ({
    type: CONST.ADD_ROLE_SUCCESS,
    payload: payload
});

export const addRoleFailed = (payload) => ({
    type: CONST.ADD_ROLE_FAILED,
    payload: payload
});

export const addRole = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('role', data),
        [addRoleSuccess],
        [addRoleFailed]
    );
};

export const updateRoleSuccess = (payload) => ({
    type: CONST.UPDATE_ROLE_SUCCESS,
    payload: payload
});

export const updateRoleFailed = (payload) => ({
    type: CONST.UPDATE_ROLE_FAILED,
    payload: payload
});

export const updateRole = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`role/${data['id']}`, data),
        [updateRoleSuccess],
        [updateRoleFailed]
    );
};

export const deleteRoleSuccess = (payload) => ({
    type: CONST.DELETE_ROLE_SUCCESS,
    payload: payload
});

export const deleteRoleFailed = (payload) => ({
    type: CONST.DELETE_ROLE_FAILED,
    payload: payload
});

export const deleteRole = ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`role/${data['id']}`),
        [deleteRoleSuccess],
        [deleteRoleFailed]
    );
};

import React, {useEffect} from 'react'
import {CardContent, CardHeader, Tooltip,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro } from "@mui/x-data-grid-pro";
import {Panel} from "../../../../../Components";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import { fetchTasks, setTaskConfig, setSelectedTask} from "../../index";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import { StatusBall } from "../../../../../Components/Elements/StatusBall";
import {UploadExpiredDocumentModal} from "../Modals/UploadExpiredDocumentModal";
import {setModal} from "../../../../Common/App";
import {FinancialForm} from "../Forms/FinancialForm";
import {WpsDetailForm} from "../Forms/WpsDetailForm";
import {VatReturnForm} from "../Forms/VatReturnForm";
import {useParams} from "react-router-dom";
import moment from "moment/moment";

export const TasksPanel = () => {

    const {business_id} = useParams();
    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const items = useSelector((store) => store.dashboard?.tasks);
    const filters = useSelector((store) => store.dashboard?.tasks?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.dashboard?.tasks?.config?.pagination);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();
    },[pagination])

    const fetchData = async () => {
        setIsLoading(true);
        await fetchTasks({dispatch, data:{...filters, ...pagination, business_id}}).then((res) => {
            setResponse(res);
            setIsLoading(false);
        })
    }

    const handleActionBtn = async (params) => {
        dispatch(setSelectedTask(params.id));
        switch(params.row.action.name){
            case 'FINANCIAL_ADD':
                dispatch(setModal({component:<FinancialForm taskId={params.id} />, open:true}));
                break;
            case 'WPS_SALARY_ADD':
                dispatch(setModal({component:<WpsDetailForm taskId={params.id} />, open:true}));
                break;
            case 'VAT_RETURN_ADD':
                dispatch(setModal({component:<VatReturnForm taskId={params.id} />, open:true}));
                break;
            case 'DOCUMENT_EXPIRY':
                dispatch(setModal({component:<UploadExpiredDocumentModal />, open:true}));
                break;
            default:
                break;
        }
    }

    const getPreparedTitle = (params) => {

        return params.row.meta.requested.entity_name === 'partners' ? `${params.row.title} required for ${params.row.meta.requested.person_name}` : `${params.row.title}`
    }

    const columns = React.useMemo(
        () => [
            { field: 'title', headerName:'Document Type', type: 'string', flex: 1, minWidth: 330,
                valueGetter: (params) => getPreparedTitle(params),
            },
            { field: 'doc_expiry', headerName: 'Date', type:'string', flex: 1, minWidth: 60,
                valueGetter: (params) => moment(params.row?.meta?.requested?.doc_expiry).format('DD/MM/YYYY'),},
            { field: 'status', headerAlign:'center', align:'center', headerName:'Status', type: 'string',flex:1, minWidth:100,
                renderCell: (params) => {

                    const status = {
                        'PENDING':'primary',
                        'SUBMITTED':'info',
                        'RETURNED':'error',
                    }

                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value?.name}
                        color={status[params?.value?.name]}
                    />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                width:70,
                getActions: (params) => {
                    return [
                        <Tooltip title={"Show Details"}>
                            <GridActionsCellItem
                                hidden={!(params.row.loan !== null) }
                                icon={<PageviewIcon sx={{zIndex:-1}} />}
                                color={"primary"}
                                label={"View"}
                                onClick={() => handleActionBtn(params)}
                            />
                        </Tooltip>
                    ];
                },
            },

        ],
        [],
    );

    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'To-Do List: Documents Update'} />
                <CardContent sx={{p:0, minHeight:'300px'}}>
                    <DataGridPro
                        autoHeight={false}
                        getRowId={(row) => row.id}
                        columns={columns}
                        rows={items.list || []}
                        sx={{ border:'none'}}
                        rowThreshold={0}
                        pagination
                        paginationMode="server"
                        paginationModel={pagination}
                        rowCount={items?.config?.rowCount ?? 0}
                        pageSizeOptions={[5]}
                        loading={isLoading}
                        onPaginationModelChange={(params) => dispatch(setTaskConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                        initialState={{
                            pinnedColumns: { right: ['actions'] },
                            pagination: { paginationModel: { ...pagination } },
                        }}
                    />
                </CardContent>
            </Panel>
        </>
    );
}

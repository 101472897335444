import * as React from 'react';
import { TextField } from "@mui/material";
import { fetchListings } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash.debounce'; // Import debounce function

export const SearchPanel = () => {

    const dispatch = useDispatch();
    const config = useSelector(state => state.listings.config);
    const filters = config.filters;
    const pagination = config.pagination;

    // Debounce the handleSearch function
    const debouncedSearch = React.useMemo(
        () => debounce(async (value) => {
            if (value.length > 3 || value === '') { // Check if input length is more than 3 letters or if input is cleared
                await fetchListings({ dispatch, data: { ...filters, keyword: value, ...pagination } });
            }
        }, 300), // Adjust the debounce delay as needed (300ms in this example)
        [dispatch, filters]
    );

    // Memoize the handleSearch function to prevent unnecessary re-renders
    const handleSearch = React.useCallback(
        (e) => {
            const { value } = e.target;
            debouncedSearch(value); // Call the debounced function with the input value
        },
        [debouncedSearch]
    );

    return (
        <>
            <TextField
                placeholder={"Search eg: Microsoft, Lulu etc"}
                fullWidth
                variant="standard"
                onChange={handleSearch}
                InputProps={{ disableUnderline: true }}
                sx={{ borderBottom: '1px solid #ddd', p: 2 }}
                name={"keyword"}
            />
        </>
    );
};

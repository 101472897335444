import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    InputLabel,  Select,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {addUser} from "../../index";
import {setRightDrawer} from "../../../../Common/App";
import Toastr from "../../../../../Components/Elements/Toastr";

export const AddUser = () => {
    const dispatch = useDispatch();
    const items = useSelector((store)=> store.users);
    const roles = useSelector((store) => store.roles);
    const [data, setData] = useState();
    const [response, setResponse] = useState({});

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async() => {
        await addUser({dispatch, data}).then((res) => setResponse(res));
    };

    return(
        <Card sx={{ height: '100%', borderRadius:0, position:'relative', display: 'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'flex-start', alignContent:'stretch' }}>
            <CardHeader
                action={
                    <IconButton aria-label="share" onClick={ () => dispatch(setRightDrawer({component:null, open:false})) }>
                        <CloseIcon />
                    </IconButton>
                }
                avatar={
                    <Typography>Add User</Typography>
                }
            />
            <CardContent sx={{ flex:1, overflow:'auto' }}>
                <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.first_name}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    helperText={items.error?.last_name}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    type="email"
                    helperText={items.error?.email}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3}}
                    type="password"
                    helperText={items.error?.password}
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    type="tel"
                    defaultValue=""
                    variant="outlined"
                    size="large"
                    sx={{mb:3, flex:1}}
                    helperText={items.error?.mobile}
                    onChange={(e) => handleChange(e)}
                />

                <FormControl sx={{ width:'100%', mb:3 }}>
                    <InputLabel id="add-user-form-label-office">Role</InputLabel>
                    <Select
                        multiple
                        defaultValue={[]}
                        name="roles"
                        MenuProps={{
                            style: {zIndex: 35001}
                        }}
                        fullWidth
                        label="Role"
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value="">None</MenuItem>
                        {roles.list && roles.list.length > 0 && roles.list.map((role) => (
                            <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ width:'100%', mb:3  }}>
                    <InputLabel id="add-user-form-label-office">Class</InputLabel>
                    <Select
                        defaultValue={""}
                        name="role_type"
                        MenuProps={{
                            style: {zIndex: 35001}
                        }}
                        fullWidth
                        label="Class"
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem key={ "ADMIN" } value={ "ADMIN" }> { "Admin" } </MenuItem>
                        <MenuItem key={ "USER" } value={ "USER" }> { "User" } </MenuItem>
                        <MenuItem key={ "CLIENT" } value={ "CLIENT" }> { "Client" } </MenuItem>
                    </Select>
                </FormControl>

            </CardContent>
            <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                <Button variant={'contained'} color={'primary'} onClick={ handleSubmit }>Add User</Button>
                <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>Close</Button>
            </CardActions>
            <Toastr response={response} />
        </Card>
    )
}

import React from 'react';
import { isBrowser } from 'react-device-detect';
import { useDomain } from '../../../../Hooks/useDomain';
import {
    InvestorRegistrationLg,
    BusinessRegistrationLg,
    InvestorRegistrationSm,
    BusinessRegistrationSm
} from '../index';

export const Register = () => {

    const subDomain = useDomain();
    const isInvestor = ['investor', 'investor-dev'].includes(subDomain);
    const isBusiness = ['business', 'business-dev'].includes(subDomain);


    if (isInvestor && isBrowser) {
        return <InvestorRegistrationLg />;
    } else if (isInvestor && !isBrowser) {
        return <InvestorRegistrationSm />;
    } else if (isBusiness && isBrowser) {
        return <BusinessRegistrationLg />;
    } else if (isBusiness && !isBrowser) {
        return <BusinessRegistrationSm />;
    }else{
        return <></>
    }
};

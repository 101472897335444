import React from 'react'
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import {ActivityTable} from "./Tables/ActivityTable";

export const Monitor = () => {

    const dispatch = useDispatch();

    return (
        <>
            <Grid container spacing={2} elevated={3}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/MONITOR'} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={"Monitoring"} />
                        <CardContent sx={{p:0}}>
                            <ActivityTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

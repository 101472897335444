import * as React from 'react';
import {FormControl, Input, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from "@mui/material/Typography";
import {uploadDocument, removeDocument} from "../index";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {Link} from "react-router-dom";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {useState} from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function AttachDoc(props) {

    const dispatch = useDispatch();
    const documents = useSelector((store) => store.documents.files) || [];
    const [file, setFile] = useState();
    const [showFiles, setShowFiles] = useState(false);

    const fileTypes = {
        "text/csv":'csv',
        "application/pdf":"pdf",
        "image/png":"png",
        "image/jpeg":"jpeg",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":"xlsx",
        "application/vnd.ms-excel":"xls",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    }

    const handleChange = async (e) => {

        if(fileTypes.hasOwnProperty(e.target.files[0]?.type) && (props?.allowedFileType).includes(fileTypes[e.target.files[0]?.type])){
            await uploadDocument({dispatch, props, file:e.target.files[0]}).then((res) => {
                props.setResponse(res);
            });
        }else{
            props.setResponse({"error":{"status":422,"data":{"message":"Invalid File Type"}}});
        }
    };

    const handleRemoveImage = async (e) => {
        const document_id = e.currentTarget.dataset.id;
        await removeDocument({dispatch, props, document_id}).then((res) => {
            props.setResponse(res);
        });
    };

    const document = () => {
        // If props.data is undefined or props.data[props.name] is null or undefined, return an empty array
        if (props.data?.[props.name] === undefined || props.data?.[props.name] === null) {
            return [];
        }

        // If props.multiple is true, return documents filtered by type
        if (props.multiple) {
            return documents.filter(d => d.type === props.name);
        }

        // If both props.data and props.name are defined, return documents filtered by document_id
        if (props.data && props.name) {
            return documents.filter(d => d.document_id === props.data[props.name]);
        }

        // Default return if none of the conditions are met
        return [];
    }

    const UploadButton = styled(Button)({
        flex:1,
        position:'relative',
        display:'flex!important',
        flexDirection:'column',
        border:'1px dashed #aaa',
        borderRadius:'4px',
        height:'55px',
        '&:hover': {
            backgroundColor: '#efefef',
        },
        '& .MuiBox-root': {
            position:'absolute',
            right:'10px',
            top:'10px',
            width:'20px',
            height:'20px',
            '& .MuiTypography-root': {
                border:'2px solid #028dea',
                textDecoration:'none',
                borderRadius:'50%',
                display:'block',
                cursor:'pointer',
                textAlign:'center',
                backgroundColor:'#0099ff',
                color:'#000000',
            }
        }
    });

    return (
        <Stack spacing={1}>
            <FormControl>
                <label htmlFor={props.name} >
                    <Typography style={{fontSize:'11px', left:'11px', top:'-8px', position:'absolute', zIndex:'1', backgroundColor:'#fff', padding:'0 7px 0 6px'}}>{props.label}</Typography>
                    <Input
                        sx={{display:'none'}}
                        name={props.name}
                        type="file"
                        id={props.name}
                        multiple={props.multiple}
                        onChange={handleChange}
                    />
                    <UploadButton fullWidth size="large" component="span">
                        <CloudUploadIcon />
                        <Typography sx={{ fontSize:'11px', textTransform:'Capitalize'}}>Supported Files: {props.allowedFileType.join(', ').toUpperCase()}</Typography>
                        {
                            document()?.length > 0 &&
                            <Box>
                                <Typography component={Link} onClick={ ()=> setShowFiles(!showFiles)} sx={{ fontSize:'11px', textTransform:'Capitalize'}}>{document()?.length}</Typography>
                            </Box>
                        }
                    </UploadButton>
                </label>
            </FormControl>
            {
                ((document()?.length > 0) && showFiles) &&
                document()?.map((doc, index) => (
                    <FormControl key={doc.document_id} sx={{ background:'#f7f7ff', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', height:'65px', borderRadius:'4px', padding:'8px 15px'}}>
                        <DocumentScannerIcon sx={{ fontSize:'34px', 'color':'#396694'}} />
                        <Typography sx={{fontSize:'13px', paddingLeft: '5px'}}>{ doc?.doc_name || file?.name }<span style={{fontSize:'11px',display:'block'}}>{ doc?.doc_size || file?.size }</span></Typography>
                        <Button onClick={handleRemoveImage} data-id={ doc?.document_id } sx={{padding:'0'}}><DeleteForeverIcon sx={{ fontSize:'24px', 'color':'#990000', zIndex:0}} /></Button>
                    </FormControl>
                ))
            }
        </Stack>
    );
}

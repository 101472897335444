import React from 'react'
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {StatsTemplate} from "./StatsTemplate";

export const UpcomingRePaymentStats = () => {

    const count = useSelector(store => store.dashboard?.upcoming_repayments);
    const displayedCount = useCounterEffect(0, count);

    return (
        <>
            <StatsTemplate title={"Upcoming Repayments"} displayedCount={displayedCount} link={"/repayments"} linkText={"Next 7 Days"} />
        </>
    );
}

import {Card, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

export const RegistrationFailedCard = () => {

    return (<Card>
        <CardHeader title={"Sorry! You do not meet the requirement."} titleTypographyProps={{color:'error', fontSize:'16px', fontWeight:'bold'}} />
        <CardContent sx={{alignItems:'center', justifyContent:'center', paddingY:'50px'}}>
            <Typography color={'darkgrey'} sx={{textAlign:'center'}}>Your business must be atleast 6 months old in order to register</Typography>
            <Typography color={'darkgray'} sx={{textAlign:'center'}}>For more information, you can send an email to info@ifundfactoring.com</Typography>
        </CardContent>
    </Card>)
}
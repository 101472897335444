import React from "react";
import IconButton from "@mui/material/IconButton";
import {
    CardActions, CardContent, CardHeader, TextField, Typography, Stack, FormControl, InputLabel, Select
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {useState, useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import {fetchListing, fetchListings, updateListing} from "../../index";
import {setModal, setRightDrawer} from "../../../../Common/App";
import {DrawerCard} from "../../../../../Components";
import MuiDatepicker from "../../../../Corporate/Business/Components/Datepicker";
import MenuItem from "@mui/material/MenuItem";
import {updateService} from "../../../../Corporate/Services";

export const UpdateListingForm = () => {

    const dispatch = useDispatch();
    const listing = useSelector((store) => store.listings.selected);
    const filters = useSelector((store) => store.listings.config.filters);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    useEffect(() => {
        setData({
            business_name:listing.business.name,
            service_type:listing.service.service_type,
            amount:listing.service.amount,
            tenor:listing.service.tenor,
            listing_start_date:listing.listing_start_date,
            listing_end_date:listing.listing_end_date,
            max_funding_amount:listing.max_funding_amount,
            min_funding_amount:listing.min_funding_amount,
            status:listing.status,
        });
    },[listing])

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        delete(data.business_name);
        delete(data.service_type);
        delete(data.amount);
        delete(data.tenor);

        await updateListing({dispatch, data:{id:listing.id, ...data}}).then((res)=>{
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                if(data.status === 'FUNDED'){
                    updateService({dispatch, data:{service_id:listing.service.service_id, status:'FUNDED'}})
                }
                fetchListings({dispatch, data:{...filters}});
                fetchListing({dispatch, id:listing.id});
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    return(
        <>
            { data  &&
                <DrawerCard>
                    <CardHeader
                        action={
                            <IconButton aria-label="share" onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>
                                <CloseIcon />
                            </IconButton>
                        }
                        avatar={
                            <Typography>Listing</Typography>
                        }
                    />
                    <CardContent sx={{ flex:1 }}>
                        <Stack direction={"column"} spacing={2}>
                            <TextField
                                fullWidth
                                label="Business"
                                variant="outlined"
                                size="large"
                                value={ data?.business_name }
                            />
                            <TextField
                                fullWidth
                                label="Service"
                                variant="outlined"
                                size="large"
                                value={ data?.service_type }
                            />
                            <TextField
                                fullWidth
                                label="Amount"
                                variant="outlined"
                                size="large"
                                value={ data?.amount }
                            />
                            <TextField
                                fullWidth
                                label="Tenor"
                                name="tenor"
                                onBlur={(e) => handleChange(e)}
                                variant="outlined"
                                size="large"
                                value={ data?.tenor }
                                helperText={listing.error?.tenor}
                            />
                            <TextField
                                fullWidth
                                label="Min Funding Amount"
                                name="min_funding_amount"
                                onBlur={(e) => handleChange(e)}
                                variant="outlined"
                                size="large"
                                value={ data?.min_funding_amount }
                                sx={{mb:3}}
                                helperText={listing.error?.min_funding_amount}
                            />
                            <TextField
                                fullWidth
                                label="Max Funding Amount"
                                name="max_funding_amount"
                                onBlur={(e) => handleChange(e)}
                                variant="outlined"
                                size="large"
                                value={ data?.max_funding_amount }
                                helperText={listing.error?.max_funding_amount}
                            />
                            <MuiDatepicker
                                setDisabled={setDisabled}
                                label={"Listing Start Date"}
                                past={false}
                                name="listing_start_date"
                                data={data}
                                state={setData}
                                error={listing?.error?.listing_start_date}
                            />
                            <MuiDatepicker
                                setDisabled={setDisabled}
                                label={"Listing End Date"}
                                past={false}
                                name="listing_end_date"
                                data={data}
                                state={setData}
                                error={listing?.error?.listing_end_date}
                            />
                            <FormControl sx={{ width:'100%', mb:3  }}>
                                <InputLabel id="update-listing-form-status">Status</InputLabel>
                                <Select
                                    value={ data?.status }
                                    name="status"
                                    MenuProps={{
                                        style: {zIndex: 35001}
                                    }}
                                    fullWidth
                                    label="Status"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <MenuItem key={ "PENDING" } value={ "PENDING" }> { "PENDING" } </MenuItem>
                                    <MenuItem key={ 'ACTIVE' } value={ 'ACTIVE' }> { 'ACTIVE' } </MenuItem>
                                    <MenuItem key={ 'FUNDED' } value={ 'FUNDED' }> { 'FUNDED' } </MenuItem>
                                    <MenuItem key={ 'CANCELLED' } value={ 'CANCELLED' }> { 'CANCELLED' } </MenuItem>
                                    <MenuItem key={ 'DELETED' } value={ 'DELETED' }> { 'DELETED' } </MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </CardContent>
                    <CardActions className={'card-footer'} sx={{ p:2, display:'flex', justifyContent:'space-between'}}>
                        <Button disabled={disabled} color="primary" variant="contained" onClick={ handleSubmit }>Save</Button>
                        <Button variant={'contained'} color={'inherit'} onClick={()=> dispatch(setRightDrawer({component:null, open:false}))}>Close</Button>
                    </CardActions>
                </DrawerCard>}
        </>
    )
}

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import {handleAsyncAction} from "../../../../Setup/Axios/ApiHandlers";


/****************************************
 FETCH CHEQUE
 ****************************************/
export const fetchConfigSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESS_CONFIG_SUCCESS,
    payload: payload
})

export const fetchConfigFailed = (payload) => ({
    type: CONST.FETCH_BUSINESS_CONFIG_FAILED,
    payload: payload
})

export const fetchConfig = async ({dispatch, business_id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/'+ business_id + '/config'),
        [fetchConfigSuccess],
        [fetchConfigFailed]
    );
}

import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./RootReducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'ifund',
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer:persistedReducer,
    devTools: process.env.NODE_ENV !== 'prod',
    middleware: [thunk]
});

export const persistor = persistStore(store)
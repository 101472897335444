import React from 'react';
import {useSelector} from "react-redux";
import useCounterEffect from "../../../../../Hooks/useCounterEffect";
import {MetricTemplateCard} from "./MetricTemplateCard";

export const DiscountedInvoiceCard = () => {

    const invoices = useSelector(store => store.dashboard.stats?.invoices);
    const displayedCount = useCounterEffect(0, invoices);

    return (
        <>
            <MetricTemplateCard title={"Discounted Invoices"} displayedCount={displayedCount} link={'/invoices'} linkText={"See All"} />
        </>
    );
}

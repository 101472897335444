const CONST = {
    'SET_CONFIG':'REPAYMENTS/SET_CONFIG',
    'RESET_ALL':'REPAYMENTS/RESET_ALL',
    'SET_SELECTED_ITEM':'REPAYMENTS/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'REPAYMENTS/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'REPAYMENTS/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'REPAYMENTS/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'REPAYMENTS/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'REPAYMENTS/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'REPAYMENTS/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'REPAYMENTS/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'REPAYMENTS/UPDATE_ITEM_FAILED',
    'DELETE_ITEM_SUCCESS':'REPAYMENTS/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'REPAYMENTS/DELETE_ITEM_SUCCESS',
}

export default CONST;
import CONST from "./Constant";

const INITIAL_STATE = {
    list:[],
    selected:{},
    schedule:[],
    error: [],
    proceed: false,
    message:[],
    code: "",
    config:{
        from:0,
        to:0,
        lastPage:1,
        rowCount:0,
        pagination:{
            page:0,
            pageSize:5,
        },
        filters:{
            keyword:'',
            sort_by:'created_at',
            sort_dir:'desc',
            status: ["PENDING","VERIFIED","IN_PROGRESS","ACTIVE"]
        }
    },
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.SET_SELECTED_SERVICE:
            return {
                ...state,
                selected: state.list.filter(item => item.service_id === action.payload)[0],
            }
        case CONST.SET_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload
                },
            };
        case CONST.FETCH_SERVICE_SUCCESS:
            return {
                ...state,
                selected: action.payload.data,
                error: [],
                message: ["Service loaded successfully"],
                code:action.payload.status,
            }
        case CONST.FETCH_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                list:action.payload.data.data,
                config:{
                    ...state.config,
                    from:action.payload.data.from,
                    to:action.payload.data.to,
                    lastPage:action.payload.data.last_page,
                    rowCount:action.payload.data.total,
                    pagination:{
                        ...state.config.pagination,
                        page:action.payload.data.current_page - 1,
                        pageSize:action.payload.data.per_page,
                    },
                },
                error: [],
                code:action.payload.status,
                message: ["Services loaded successfully"],
            }
        case CONST.FETCH_SERVICES_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.ADD_SERVICE_SUCCESS:
            return {
                ...state,
                list:[...state.list, action.payload.data],
                selected: {},
                error: [],
                message: ["Service added successfully"],
                code:action.payload.status,
            }
        case CONST.ADD_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.UPDATE_SERVICE_SUCCESS:
            const indexU = state.list.findIndex(o => o.service_id === action.payload.data.service_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexU), action.payload.data, ...state.list.slice(indexU + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Service updated successfully"],
                code:action.payload.status,
            }
        case CONST.UPDATE_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.VERIFY_SERVICE_SUCCESS:
            const indexV = state.list.findIndex(o => o.service_id === action.payload.data.service_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexV), action.payload.data, ...state.list.slice(indexV + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Service verified successfully"],
                code:action.payload.status,
            }
        case CONST.VERIFY_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.APPROVE_SERVICE_SUCCESS:
            const indexA = state.list.findIndex(o => o.service_id === action.payload.data.service_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexA), action.payload.data, ...state.list.slice(indexA + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Service approved successfully"],
                code:action.payload.status,
            }
        case CONST.APPROVE_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.LIST_SERVICE_SUCCESS:
            const indexL = state.list.findIndex(o => o.service_id === action.payload.data.service_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexL), action.payload.data, ...state.list.slice(indexL + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Service listed successfully"],
                code:action.payload.status,
            }
        case CONST.LIST_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.FUND_SERVICE_SUCCESS:
            const indexF = state.list.findIndex(o => o.service_id === action.payload.data.service_id);
            return {
                ...state,
                list: [...state.list.slice(0, indexF), action.payload.data, ...state.list.slice(indexF + 1)],
                selected: action.payload.data,
                error: [],
                message: ["Service funded successfully"],
                code:action.payload.status,
            }
        case CONST.FUND_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        case CONST.DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                list:state.list.filter(item => item.service_id !== action.payload.data),
                selected: {},
                error: [],
                message: ["Service deleted successfully"],
                code:action.payload.status,
            }
        case CONST.DELETE_SERVICE_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;
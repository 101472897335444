import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from '../../../../Setup/Axios/ApiHandlers';

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});
export const proceed = (payload) => ({
    type: CONST.CLEAR_PROCEED_STATE,
    payload: payload,
});

export const clearErrors = (payload) => ({
    type: CONST.CLEAR_ERROR_STATE,
    payload: payload,
});

export const setSelectedInvoice = (payload) => ({
    type: CONST.SET_SELECTED_INVOICE,
    payload: payload,
});

export const fetchInvoiceSuccess = (payload) => ({
    type: CONST.FETCH_INVOICE_SUCCESS,
    payload: payload,
});

export const fetchInvoiceFailed = (payload) => ({
    type: CONST.FETCH_INVOICE_FAILED,
    payload: payload,
});

export const fetchInvoice = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/invoice/${id}`),
        [fetchInvoiceSuccess],
        [fetchInvoiceFailed]
    );
};

export const fetchInvoicesSuccess = (payload) => ({
    type: CONST.FETCH_INVOICES_SUCCESS,
    payload: payload,
});

export const fetchInvoicesFailed = (payload) => ({
    type: CONST.FETCH_INVOICES_FAILED,
    payload: payload,
});

export const fetchInvoices = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/invoice', { params: data }),
        [fetchInvoicesSuccess],
        [fetchInvoicesFailed]
    );
};

export const addInvoiceSuccess = (payload) => ({
    type: CONST.ADD_INVOICE_SUCCESS,
    payload: payload,
});

export const addInvoiceFailed = (payload) => ({
    type: CONST.ADD_INVOICE_FAILED,
    payload: payload,
});

export const addInvoice = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/invoice', data),
        [addInvoiceSuccess],
        [addInvoiceFailed]
    );
};

export const updateInvoiceSuccess = (payload) => ({
    type: CONST.UPDATE_INVOICE_SUCCESS,
    payload: payload,
});

export const updateInvoiceFailed = (payload) => ({
    type: CONST.UPDATE_INVOICE_FAILED,
    payload: payload,
});

export const updateInvoice = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/invoice/${data.invoice_id}`, data),
        [updateInvoiceSuccess],
        [updateInvoiceFailed]
    );
};

export const verifyInvoiceSuccess = (payload) => ({
    type: CONST.VERIFY_INVOICE_SUCCESS,
    payload: payload,
});

export const verifyInvoiceFailed = (payload) => ({
    type: CONST.VERIFY_INVOICE_FAILED,
    payload: payload,
});

export const verifyInvoice = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/invoice/verify/${data.invoice_id}`, data),
        [verifyInvoiceSuccess],
        [verifyInvoiceFailed]
    );
};

export const rejectInvoiceSuccess = (payload) => ({
    type: CONST.REJECT_INVOICE_SUCCESS,
    payload: payload,
});

export const rejectInvoiceFailed = (payload) => ({
    type: CONST.REJECT_INVOICE_FAILED,
    payload: payload,
});

export const rejectInvoice = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/invoice/reject/${data.invoice_id}`, data),
        [rejectInvoiceSuccess],
        [rejectInvoiceFailed]
    );
};

export const returnInvoiceSuccess = (payload) => ({
    type: CONST.RETURN_INVOICE_SUCCESS,
    payload: payload,
});

export const returnInvoiceFailed = (payload) => ({
    type: CONST.RETURN_INVOICE_FAILED,
    payload: payload,
});

export const returnInvoice = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/invoice/return/${data.invoice_id}`, data),
        [returnInvoiceSuccess],
        [returnInvoiceFailed]
    );
};

export const confirmInvoiceSuccess = (payload) => ({
    type: CONST.CONFIRM_INVOICE_SUCCESS,
    payload: payload,
});

export const confirmInvoiceFailed = (payload) => ({
    type: CONST.CONFIRM_INVOICE_FAILED,
    payload: payload,
});

export const confirmInvoice = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/invoice/confirm/${data.invoice_id}`, data),
        [confirmInvoiceSuccess],
        [confirmInvoiceFailed]
    );
};

export const approveInvoiceSuccess = (payload) => ({
    type: CONST.APPROVE_INVOICE_SUCCESS,
    payload: payload,
});

export const approveInvoiceFailed = (payload) => ({
    type: CONST.APPROVE_INVOICE_FAILED,
    payload: payload,
});

export const approveInvoice = async ({ dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/invoice/approve/${data.invoice_id}`, data),
        [approveInvoiceSuccess],
        [approveInvoiceFailed]
    );
};

export const deleteInvoiceSuccess = (payload) => ({
    type: CONST.DELETE_INVOICE_SUCCESS,
    payload: payload,
});

export const deleteInvoiceFailed = (payload) => ({
    type: CONST.DELETE_INVOICE_FAILED,
    payload: payload,
});

export const deleteInvoice = async ({ dispatch, invoice_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`corporate/invoice/${invoice_id}`),
        [deleteInvoiceSuccess],
        [deleteInvoiceFailed]
    );
};

export const updateInvoiceStatusSuccess = (payload) => ({
    type: CONST.UPDATE_INVOICE_SUCCESS,
    payload: payload,
});

export const updateInvoiceStatusFailed = (payload) => ({
    type: CONST.UPDATE_INVOICE_FAILED,
    payload: payload,
});

export const updateInvoiceStatus = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`corporate/invoice/${data.invoice_id}`, data),
        [updateInvoiceStatusSuccess],
        [updateInvoiceStatusFailed]
    );
};

export const uploadDocumentSuccess = (payload) => ({
    type: CONST.UPLOAD_DOCUMENT_SUCCESS,
    payload: payload,
});

export const uploadDocumentFailed = (payload) => ({
    type: CONST.UPLOAD_DOCUMENT_FAILED,
    payload: payload,
});

export const uploadDocument = async ({ dispatch, props, file }) => {
    let data = new FormData();
    data.append('file', file);
    data.append('invoice_id', props.data.invoice_id);
    data.append('entity_id', props.entity_id);
    data.append('entity_name', props.entity_name);
    data.append('type', props.name);
    data.append('doc_size', file.size);

    let config = {
        method: 'post',
        maxBodyLength: 3000,
        url: 'document',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'content-type': file.type,
        },
        data: data
    };

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate(config),
        [uploadDocumentSuccess],
        [uploadDocumentFailed]
    );
};

export const removeDocumentSuccess = (payload) => ({
    type: CONST.REMOVE_DOCUMENT_SUCCESS,
    payload: payload,
});

export const removeDocumentFailed = (payload) => ({
    type: CONST.REMOVE_DOCUMENT_FAILED,
    payload: payload,
});

export const removeDocument = async ({ dispatch, props, document_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete(`document/${document_id}`),
        [removeDocumentSuccess],
        [removeDocumentFailed]
    );
};

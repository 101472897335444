import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    Select,
    TextField
} from "@mui/material";
import * as React from "react";
import {Datepicker} from "../../../../../Components/Elements/Datepicker2";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {setConfig} from "../../Redux/Action";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import Button from "@mui/material/Button";

export const FilterCard = () => {

    const dispatch = useDispatch();

    const filters = useSelector((store) => store.accounts.config.filters);
    const pagination = useDeepEqualSelector((store) => store.accounts.config.pagination);

    const handleChange = (e) => {
        dispatch(setConfig({filters: {...filters, ...pagination, [e.target.name]: e.target.value}}));
    }

    const handleClearFilter = () => {
        dispatch(setConfig({filters: {...filters, ...pagination, business_name:'', loan_no:'', type:[], status:[], cheque_deposit_date:'', payment_date:'', due_date:''}}));
    }

    return (
        <Card>
            <CardHeader title={"Filters"} />
            <CardContent sx={{paddingBottom:'0.85rem!important'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Business Name"
                            name="business_name"
                            defaultValue={filters?.business_name}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="C No"
                            name="loan_no"
                            defaultValue={filters?.loan_no ?? ""}
                            inputlabelprops={{ shrink: true }}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <Datepicker fullWidth value={filters?.payment_date} handleChange={handleChange} label={"Payment Date"} name="payment_date" />
                    </Grid>

                    <Grid item xs={12}>
                        <Datepicker fullWidth value={filters?.due_date} handleChange={handleChange} label={"Due Date"} name="due_date" />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                                value={filters?.status ?? []}
                                name="status"
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                multiple
                                fullWidth
                                label="Status"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem key="Draft" value="DRAFT">Draft</MenuItem>
                                <MenuItem key="Planned" value="PLANNED">Planned</MenuItem>
                                <MenuItem key="Extended" value="EXTENDED">Extended</MenuItem>
                                <MenuItem key="Partial" value="PARTIAL">Partial</MenuItem>
                                <MenuItem key="Paid" value="PAID">Paid</MenuItem>
                                <MenuItem key="Overdue" value="OVERDUE">Overdue</MenuItem>
                                <MenuItem key="Default" value="DEFAULT">Default</MenuItem>
                                <MenuItem key="Deleted" value="DELETED">Deleted</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={() => handleClearFilter()} variant={"contained"} size={"large"} color={"warning"}>Reset</Button>
            </CardActions>
        </Card>
    )
}
import * as React from 'react';
import {Grid, TextField, Typography} from "@mui/material";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateProfile} from "../../../../Investor/Profile";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import {Datepicker} from "../../../../../Components/Elements/Datepicker2";
import Toastr from "../../../../../Components/Elements/Toastr";


export const PersonalForm = forwardRef((props, ref)  => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.profile.selected);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});

    useImperativeHandle(ref, ()=>{
        return {
            handleSubmit:() => handleSubmit()
        }
    });

    useEffect(()=>{
        setData({
            full_name: user?.profile?.full_name ?? "",
            mobile: user?.mobile ?? "",
            email: user?.email ?? "",
            birth_place: user?.profile?.birth_place ?? "",
            birth_date: user?.profile?.birth_date ?? "",
            passport_no: user?.profile?.passport_no ?? "",
            passport_expiry: user?.profile?.passport_expiry ?? "",
            phone: user?.profile?.phone ?? "",
        });
    },[]);

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = async () => {

        let response = {}
        await updateProfile({dispatch, data}).then((res)=>{
            setResponse(res);
            response = res;
        });
        return response;
    };

    return (
        <>
        {(data !== undefined && Object.keys(data).length > 0) &&
        <Grid container spacing={4} justifyContent={"center"}>
            <Grid item xs={12}>
                <Typography variant={"h2"} sx={{fontSize: '1.7rem'}} my={3}>Personal Information</Typography>
            </Grid>

            <Grid item container xs={12} spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Full Name"}
                        value={data?.full_name}
                        variant={"outlined"}
                        inputlabelprops={{shrink: true}}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Email"}
                        value={data?.email}
                        inputlabelprops={{shrink: true}}
                        variant={"outlined"}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={"Mobile"}
                        variant={"outlined"}
                        defaultValue={data?.mobile}
                        inputlabelprops={{shrink: true}}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={"Phone"}
                        name={"phone"}
                        variant={"outlined"}
                        defaultValue={data?.phone}
                        onBlur={handleChange}
                        inputlabelprops={{shrink: true}}
                        helperText={response?.error?.data?.errors?.phone}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={"Passport No"}
                        name={"passport_no"}
                        variant={"outlined"}
                        defaultValue={data?.passport_no}
                        onBlur={handleChange}
                        inputlabelprops={{shrink: true}}
                        helperText={response?.error?.data?.errors?.passport_no}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Datepicker value={data?.passport_expiry} error={response?.error?.data?.errors?.passport_expiry}
                                styles={{width: '100%'}} label={"Passport Expiry"} disablePast={true}
                                disableFuture={false} name={"passport_expiry"} handleChange={handleChange}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Datepicker value={data?.birth_date} error={response?.error?.data?.errors?.birth_date}
                                styles={{width: '100%'}} label={"Date of Birth"} disablePast={false}
                                disableFuture={true} name={"birth_date"} handleChange={handleChange}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectCountry default={data?.birth_place} error={response?.error?.data?.errors?.birth_place}
                                   label={"Place of Birth"} name={"birth_place"} data={data} state={setData}/>
                </Grid>
            </Grid>
            {Object.keys(response).length > 0 && <Toastr response={response}/>}
        </Grid>
        }
    </>
    )
})
import * as React from 'react';
import {Box, Chip as ChipUI} from "@mui/material";
import {styled} from "@mui/material/styles";

export const InfoBox = ({children, styles}) => {

    const Info = styled(Box)(({ theme }) => ({
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(2),
        '& h3': {
            fontSize: '0.9rem',
            color: theme.palette.grey[900],
            marginBottom: theme.spacing(1),
        },
        '& p': {
            fontSize: '0.85rem',
            color: theme.palette.grey[800],
        },
        '& ul': {
            padding: '0!important',
            margin: '0',
            marginBottom:'1rem',
            listStyleType: 'circle',
            '& li': {
                padding: '0.25rem 0',
                fontSize: '0.85rem',
                color: theme.palette.grey[800],
            },
        },
        '& .MuiTypography-body2': {
            fontSize:'0.85rem',
            fontStyle: 'italic',
            color: theme.palette.grey[600],
        },
        '& .MuiListItemText-root': {
            fontSize:'0.85rem',
            color: theme.palette.grey[800],
            '& span': {
                fontSize:'0.85rem',
                color: theme.palette.grey[900],
            },
            '& p': {
                fontSize:'0.70rem',
                color: theme.palette.grey[800],
            },
        },
    }));

    return (
        <>
            <Info sx={{...styles}}>{children}</Info>
        </>
    );
}
import * as React from 'react';
import {Box, Card, CardContent, CardHeader, Grid} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {ActionButtons, RequestTable} from "../index";

export const Requests = () => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/REQUESTS'} sx={{display:'inline-block'}} />
                        <ActionButtons />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Requests" />
                        <CardContent sx={{p:0}}>
                            <RequestTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
import * as React from 'react';
import {useDispatch} from "react-redux";
import { setModal } from "../../../../Common/App";
import {BusinessOverviewTable} from "../Tables/BusinessOverviewTable";
import {Panel} from "../../../../../Components";
import {CardContent, CardHeader, IconButton} from "@mui/material";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import {setBusinessOverviewConfig} from "../../Redux/Action";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";

export const BusinessOverviewModal = () => {

    const dispatch = useDispatch();
    const pagination = useDeepEqualSelector((store) => store.dashboard?.businessOverview?.config?.pagination);

    const handleZoomClick = () => {
        dispatch(setModal({component:null, open:false}))
        dispatch(setBusinessOverviewConfig({pagination: {...pagination, pageSize: 5}}));
    };

    return (
        <>
            <Panel>
                <CardHeader title={"Company Wise Overview"} action={
                    <IconButton title="Zoom/Expand" onClick={handleZoomClick}>
                        <ZoomInMapIcon />
                    </IconButton>} />
                <CardContent sx={{height:'100%', overflow:'auto'}}>
                    <BusinessOverviewTable
                        pageSizeOptions={[5, 15, 30, 50, 100]}
                    />
                </CardContent>
            </Panel>
        </>
    );
}
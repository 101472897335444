import {useDispatch} from "react-redux";
import {Link as LinkUI, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {fetchAppConfig, setBlockUI, setModal, setRightDrawer} from "../../../App";
import {doLogin} from "../../Redux/Action";
import {styled} from "@mui/material/styles";
import {Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";
import {doForgotPassword, doResetPassword} from "../../../../Administration/Users";

export const ChangePasswordForm = () => {

    const userRef = useRef();

    const dispatch = useDispatch();
    const [response, setResponse] = useState({});
    const [disabled,setDisabled] = useState();
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setBlockUI({open:false}));
        userRef.current.focus();
    }, [])

    const [data, setData] = useState({
        username : '',
    })

    const handleSubmit = async() => {
        dispatch(setBlockUI({open:true}));
        await doResetPassword({dispatch, data}).then((res) => {
            dispatch(setBlockUI({open:false}));
            setResponse(res);
            if(res['success']){
                setTimeout(() => navigate('/login'), 1000)
            }
        })
    }

    const handleChange = (e) => {

        setData(data => ({
            ...data,
            token: token,
            [e.target.id] :e.target.value,
        }));
        setDisabled(data['password'] === data['password_confirmation']);
    }

    const Link = styled(LinkUI)(({ theme }) => ({
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontSize:'1.2rem',
        '&:hover': {
            cursor:'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    const passwordError = (response) => {
        if (response?.error?.data?.errors?.password) {
            const passwordErrors = response?.error?.data?.errors?.password.filter(msg => !msg.includes('Password confirmation'));
            return passwordErrors.join(', ');
        }
        return "";
    };

    const passwordConfirmationError = (response) => {
        if(response?.error?.data?.errors?.password && response?.error?.data?.errors?.password.find(msg => msg.includes('Password confirmation'))){
            return "Passwords do not match";
        }
        return response?.error?.data?.errors?.password;
    }

    return (
        <Stack spacing={3} sx={{minWidth:400}}>

            <Typography variant={"h3"} sx={{fontWeight:'300', fontSize:40, fontFamily:'Open Sans'}} color={"primary"}>Change Password</Typography>

            <>
                <TextField
                    type="password"
                    id="password"
                    onChange={(e) => handleChange(e)}
                    value={data['password']}
                    autoComplete="off"
                    ref={userRef}
                    required
                    label="Password"
                    sx={{ width: '100%', mb:3 }}
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                    helperText={passwordError(response)}
                />
                <TextField
                    type="password"
                    id="password_confirmation"
                    onChange={(e) => handleChange(e)}
                    value={data['password_confirmation']}
                    autoComplete="off"
                    required
                    label="Re-Password"
                    sx={{ width: '100%', mb:3 }}
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                    }}
                    helperText={passwordConfirmationError(response)}
                />
            </>

            <Button color="primary" sx={{ width:'100%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Change Password</Button>

            <Stack direction={"row"} spacing={2} justifyContent="space-between">
                <Link to={'/forgot-password'} className={'mb-3 d-block text-right'}>Return to Login Page</Link>
                <Link to={'/register'}>Register Now</Link>
            </Stack>
            <Toastr response={response} />
        </Stack>
    )

}
import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});

// setSelectedAccount
export const resetAccounts = () => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedAccount = (payload) => ({
    type: CONST.SET_SELECTED_ACCOUNT,
    payload: payload
});

/****************************************
 FETCH PAYMENT
 ****************************************/
export const fetchAccountSuccess = (payload) => ({
    type: CONST.FETCH_ACCOUNT_SUCCESS,
    payload: payload
});

export const fetchAccountFailed = (payload) => ({
    type: CONST.FETCH_ACCOUNT_FAILED,
    payload: payload
});

export const fetchAccount = ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account/' + id),
        fetchAccountSuccess,
        fetchAccountFailed
    );
};

/****************************************
 FETCH PAYMENTS
 ****************************************/
export const fetchAccountsSuccess = (payload) => ({
    type: CONST.FETCH_ACCOUNTS_SUCCESS,
    payload: payload
});

export const fetchAccountsFailed = (payload) => ({
    type: CONST.FETCH_ACCOUNTS_FAILED,
    payload: payload
});

export const fetchAccounts = ({ dispatch, filters}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account', { params: filters }),
        fetchAccountsSuccess,
        fetchAccountsFailed
    );
};

/****************************************
 ADD PAYMENT
 ****************************************/
export const addAccountSuccess = (payload) => ({
    type: CONST.ADD_ACCOUNT_SUCCESS,
    payload: payload
});

export const addAccountFailed = (payload) => ({
    type: CONST.ADD_ACCOUNT_FAILED,
    payload: payload
});

export const addAccount = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/account', data),
        addAccountSuccess,
        addAccountFailed
    );
};

/****************************************
 UPDATE PAYMENT
 ****************************************/
export const updateAccountSuccess = (payload) => ({
    type: CONST.UPDATE_ACCOUNT_SUCCESS,
    payload: payload
});

export const updateAccountFailed = (payload) => ({
    type: CONST.UPDATE_ACCOUNT_FAILED,
    payload: payload
});

export const updateAccount = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/account/' + data['account_id'], data),
        updateAccountSuccess,
        updateAccountFailed
    );
};

/****************************************
 UPDATE PAYMENT
 ****************************************/
export const extendAccountSuccess = (payload) => ({
    type: CONST.EXTEND_ACCOUNT_SUCCESS,
    payload: payload
});

export const extendAccountFailed = (payload) => ({
    type: CONST.EXTEND_ACCOUNT_FAILED,
    payload: payload
});

export const extendAccount = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/account/extend/' + data['account_id'], data),
        extendAccountSuccess,
        extendAccountFailed
    );
};

/****************************************
 DELETE PAYMENT
 ****************************************/
export const deleteAccountSuccess = (payload) => ({
    type: CONST.DELETE_ACCOUNT_SUCCESS,
    payload: payload
});

export const deleteAccountFailed = (payload) => ({
    type: CONST.DELETE_ACCOUNT_FAILED,
    payload: payload
});

export const deleteAccount = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete('corporate/account/' + data['id']),
        deleteAccountSuccess,
        deleteAccountFailed
    );
};

import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardContent, CardHeader} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {setConfig, fetchRequests } from "../../../Requests";
import moment from "moment/moment";
import {currencyFormatter} from "../../../../../Setup/Config";
import {useEffect} from "react";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const WalletRequestsPanel = () => {

    const dispatch = useDispatch();
    const requests = useSelector((store) => store.requests);
    const filters = useSelector((store) => store.requests.config.filters);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchItems();
    },[filters]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchRequests({dispatch, data:{...filters}}).then((res)=>{
            setIsLoading(false);
        });
    }

    const columns = React.useMemo(
        () => [
            { field: 'type', headerName:'Type', type: 'string', width:140,},
            { field: 'amount', headerName:'Amount', headerAlign:'center', type: 'string', width: 150, align: 'right', valueGetter: (params) => 'AED ' + currencyFormatter.format(params.row?.amount ?? 0)},
            { field: 'reference', headerName:'Reference Code', type: 'string', width: 300},
            { field: 'created_at', headerName:'Added On', type: 'string', width: 120, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")},
            { field: 'status', headerName:'Status', headerAlign:'center', align:'center', type: 'string', width:150,
                renderCell: (params) => {

                    const status = {
                        'PENDING':'warning',
                        'DONE':'success',
                        'REJECTED':'error',
                    }
                    return(<StatusBall
                        variant="contained"
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader title={"Requests"} subheader={"Your recent requests"} />
                <CardContent sx={{p:'0!important'}}>
                    <DataGridPro
                        autoHeight
                        getRowId={(row) => row.id}
                        columns={columns}
                        rows={requests.list}
                        sx={{ border:'none'}}
                        pagination
                        paginationMode="server"
                        paginationModel={filters}
                        rowCount={requests?.config?.total ?? 0}
                        pageSizeOptions={[5, 10, 25, 100]}
                        loading={isLoading}
                        onPaginationModelChange={(params) => dispatch(setConfig({filters: {...filters, page:params.page, pageSize: params.pageSize}}))}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'group', sort: 'asc' }],
                            },
                            pinnedColumns: {  right: ['status','actions'] },
                            pagination: { paginationModel: { ...filters } }
                        }}
                    />
                </CardContent>
            </Card>
        </>
    );
}

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {updateInvoice, verifyInvoice} from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {Stack, TextField} from "@mui/material";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";

export const VerifyInvoiceModal = () => {
    const dispatch = useDispatch();
    const invoice = useSelector(state => state.invoices.selected);
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        setData(invoice);
    }, []);
    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name]:e.target.value,
        }));
    }

    const handleSubmit = async () => {

        setDisabled(true);
        await verifyInvoice({dispatch, data}).then((res) => {
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setModal({component:null, open:false}));
            }
        });
    };


    return(
        <>
            <DialogTitle>Verify Invoice</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{mb:2}}>
                    Are you sure, you want to Verify this invoice?
                </DialogContentText>
                <Stack spacing={2} direction={"column"}>
                    <TextField
                        label="Remarks"
                        placeholder={"Remarks"}
                        fullWidth
                        name={"remarks"}
                        required
                        type={"text"}
                        multiline
                        rows={4}
                        onBlur={handleChange}
                    />
                    <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateInvoice} allowedFileType={['pdf','jpeg','jpg','png','doc','docx','eml']} onBlur={handleChange} label={"Verification Email Screenshot"} entity_name={"invoices"} entity_id={data?.invoice_id} name="invoice_verification_doc" data={data} state={setData} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Verify Invoice</Button>
            </DialogActions>
        </>
    )
}

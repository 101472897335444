import React, {createContext, useState} from "react";
import AppBar from "../Components/Elements/AppBar";
import LeftDrawer from "../Components/Drawers/LeftDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from '@mui/material/styles';
import {theme} from '../Setup/Theme/theme';
import {AppBarBusiness, AppBarInvestor, FooterIfund, Container, Modal} from "../Components";
import BlockUI from "../Components/Elements/BlockUI";
import {Box, Grid, Typography, Container as ContainerUI} from "@mui/material";
import {Sidebar} from "../Modules/Investor/Listings";
import RightDrawer from "../Components/Drawers/RightDrawer";
import {Link} from "react-router-dom";
import {useDomain} from "../Hooks/useDomain";
import {useSelector} from "react-redux";
import {useAppTitle} from "../Hooks/useAppTitle";
import {isBrowser} from "react-device-detect";

export const LayoutContext = createContext({});

const LayoutProvider = ({ children, layout }) => {

    const [themeMode, setThemeMode] = useState('light');

    const app = useSelector(state => state.app);
    useAppTitle(app.config?.app_name || 'iFund');

    const toggleTheme = () => {
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };
    // Determine layout based on subdomain
    const getLayout = (layout) => {

        switch (layout) {
            case 'Auth':
                return AuthLayout;
            case 'Business':
                return BusinessLayout;
            case 'Investor':
                return InvestorLayout;
            case 'Admin':
                return AdministrationLayout;
            default:
                return undefined;
        }
    };

    const selectedLayout = getLayout(layout);

    if (!selectedLayout) {
        return <LayoutContext.Provider value={{ toggleTheme }}>{children}</LayoutContext.Provider>;
    }

    return <LayoutContext.Provider value={{ toggleTheme }}>{selectedLayout(children, themeMode)}</LayoutContext.Provider>
};


const AdministrationLayout = (children, themeMode) => {

    const selectedTheme = themeMode === 'dark' ? theme.administrationDarkTheme : theme.administrationLightTheme;

    return (
        <ThemeProvider theme={ selectedTheme }>
            <Box sx={{ display: 'flex', flexGrow:1, backgroundColor:theme.body }}>
                <CssBaseline />
                <AppBar  />
                <LeftDrawer />
                <Container>
                    { children }
                </Container>
                <BlockUI />
                <Modal />
                <RightDrawer />
            </Box>
        </ThemeProvider>
    )
}

const BusinessLayout = (children, themeMode) => {

    const selectedTheme = themeMode === 'dark' ? theme.businessDarkTheme : theme.businessLightTheme;

    return (
        <ThemeProvider theme={ selectedTheme }>
            <Box sx={{ display: 'flex', flexDirection:"column", flexGrow:1, backgroundColor:theme.body }}>
                <CssBaseline />
                <AppBarBusiness  />
                <Container maxWidth={'xl'} >
                    { children }
                </Container>
                <Modal />
                <BlockUI />
                <FooterIfund />
            </Box>
        </ThemeProvider>
    )
}

const InvestorLayout = (children, themeMode) => {

    const selectedTheme = themeMode === 'dark' ? theme.investorDarkTheme : theme.investorLightTheme;

    return (
        <ThemeProvider theme={ selectedTheme }>
            <Box sx={{ display: 'flex', flexGrow:1, backgroundColor:theme.body }}>
                <CssBaseline />
                <AppBarInvestor  />
                <Container styles={{pl:'0!important', pr:'0!important', py:'0!important', height: 'calc(100vh - 80px)', boxShadow:'-70px 0px 13px -74px rgba(0,0,0,0.3)',}}>
                    <Grid container spacing={2} sx={{flex:1, m:0, width:'100%'}}>
                        <Grid item md={4} sm={6} xs={12} sx={{ height: 'calc(100vh - 80px)', backgroundColor:'#ffffff', boxShadow:'65px 0px 20px -74px rgba(0,0,0,0.3)', borderRight:'1px solid #ddd', p:'0!important' }}>
                            <Sidebar />
                        </Grid>
                        <Grid item md={8} sm={6} xs={12} sx={{overflow:'auto', height: 'calc(100vh - 80px)',}}>
                            {children}
                        </Grid>
                    </Grid>
                </Container>
                <BlockUI />
                <Modal />
            </Box>
        </ThemeProvider>
    )
}


const AuthLayout = (children, themeMode) => {

    const subDomain = useDomain();

    const themeMap = {
        investor: themeMode === 'dark' ? theme.investorDarkTheme : theme.investorLightTheme,
        'investor-dev': themeMode === 'dark' ? theme.investorDarkTheme : theme.investorLightTheme,
        business: themeMode === 'dark' ? theme.businessDarkTheme : theme.businessLightTheme,
        'business-dev': themeMode === 'dark' ? theme.businessDarkTheme : theme.businessLightTheme,
    };

    const defaultTheme = themeMode === 'dark' ? theme.administrationDarkTheme : theme.administrationLightTheme;
    const selectedTheme = themeMap[subDomain] || defaultTheme;

    return (
        <ThemeProvider theme={ selectedTheme }>
            {isBrowser ? (
            <Box sx={{ background: `linear-gradient(to right, ${selectedTheme.auth.loginBgColor} 50%, #ffffff 50%)`, width:'100%'}} >
                <ContainerUI sx={{ height: '100vh' }} component="main" maxWidth={"xl"}>
                    <Grid container justifyContent={"space-between"} sx={{ height: '100vh' }}>

                        <Grid item container  xs={12} sx={{ height: '10vh' }}>
                            <Grid item md={4} alignSelf={"center"} pt={3}>
                                <img src={'/images/ifund-logo-light.png'} width={150} alt={"Logo"} />
                                <Typography variant={"h6"} color={"#ffffff"} className={"text-uppercase"} sx={{fontSize:'0.7rem'}}>Your Partner in Growth</Typography>
                            </Grid>
                            <Grid item md={8} sx={{ background:'#ffffff' }}>
                            </Grid>
                        </Grid>

                        {children}

                        <Grid item container xs={12} sx={{ height: '10vh' }}>
                            <Grid item alignSelf={"center"} xs={12} md={4}>
                                <Link to={"/contact"}><Typography variant={"h6"} color={"#ffffff"}>Need help?</Typography></Link>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{background:'#ffffff'}}>

                            </Grid>
                        </Grid>

                    </Grid>
                </ContainerUI>
            </Box>) : (
                <ContainerUI sx={{ height:'100vh', background: 'linear-gradient(to top right, #2d3d54 50%, #2d3d54)'}} component="main" maxWidth={"xl"} >
                    {children}
                </ContainerUI>
            )}
        </ThemeProvider>
    )
}


export default LayoutProvider;
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Breadcrumbs as BreadcrumbsUI} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import Box from "@mui/material/Box";
import {Link as LinkUI} from "react-router-dom";
import {styled} from "@mui/material/styles";

export const Breadcrumbs = (props) => {
    const items = props.items.split('/');
    const crumbs = {
        'DASHBOARD': {
            'label': 'Dashboard',
            'link': '/dashboard',
        },
        'MONITOR': {
            'label': 'Monitor',
            'link': '/monitor',
        },
        'BUSINESS': {
            'label': 'Business',
            'link': '/business',
        },
        'INVOICE_DISCOUNTING': {
            'label': 'Invoice Discounting',
            'link': '/invoice-discounting',
        },
        'FUNDING_REQUESTS': {
            'label': 'Funding Requests',
            'link': '/funding-requests',
        },
        'FUND_STATEMENT': {
            'label': 'Fund Statement',
            'link': '/fund-statement',
        },
        'INVOICES': {
            'label': 'Invoices',
            'link': '/invoices',
        },
        'CUSTOMERS': {
            'label': 'Customers',
            'link': '/customers',
        },
        'BUSINESS_FINANCE': {
            'label': 'Business Finance',
            'link': '/business-finance',
        },
        'REPAYMENTS': {
            'label': 'Repayments',
            'link': 'loans',
        },
        'PAYMENTS': {
            'label': 'Payments',
            'link': 'payments',
        },
        'USERS': {
            'label': 'Users',
            'link': '/users',
        },
        'ROLES': {
            'label': 'Roles',
            'link': '/roles',
        },
        'PERMISSIONS': {
            'label': 'Permissions',
            'link': '/permissions',
        },
        'NOTIFICATIONS': {
            'label': 'Notifications',
            'link': '/notifications',
        },
        'SETTINGS': {
            'label': 'Settings',
            'link': '/settings',
        },
        'SERVICES': {
            'label': 'Services',
            'link': '/services',
        },
        'FUNDINGS': {
            'label': 'Fundings',
            'link': '/fundings',
        },
        'WALLETS': {
            'label': 'Wallets',
            'link': '/wallets',
        },
        'LISTINGS': {
            'label': 'Listings',
            'link': '/listings',
        },
        'REQUESTS': {
            'label': 'Requests',
            'link': '/requests',
        },
        'TRANSACTIONS': {
            'label': 'Transactions',
            'link': '/transactions',
        },
        'MYWALLET': {
            'label': 'My Wallet',
            'link': '/my-wallet',
        },
        'POSITIONS': {
            'label': 'Positions',
            'link': '/positions',
        },
        'PROFILE': {
            'label': 'Profile',
            'link': '/profile',
        },
        'STATEMENTS': {
            'label': 'Statements',
            'link': '/statements',
        },
    };

    const Link = styled(LinkUI)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    }));

    return (
        <Box role="presentation" sx={{ display: 'flex', alignItems: 'center' }}>
            <BreadcrumbsUI aria-label="breadcrumb">
                {items.map((item, index) => (
                    index === items.length - 1 ? (
                        <Typography key={index} sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {crumbs[item]?.label || item}
                        </Typography>
                    ) : (
                        crumbs[item] ? (
                            <Link
                                key={index}
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                to={crumbs[item].link}
                            >
                                {index === 0 && <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
                                {index > 0 && <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
                                {crumbs[item].label}
                            </Link>
                        ) : (
                            <Typography key={index} sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
                                {index === 0 && <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
                                {index > 0 && <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
                                {item}
                            </Typography>
                        )
                    )
                ))}
            </BreadcrumbsUI>
        </Box>
    );
}

export const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArRsBW7fD3kt6DvHZDBY+
l/fzOpRVsgWFfLzzV7Q+sw5ERZBeL6auC/Vn+oLeMg8j3oQiyMo5Ewm8j6rrmwAG
oumXFweir3tJlHD+uKL+XyQeGY3MU/dPkHfykT9IlcrpXlv/IAY/K6vYmWnoqb2h
ow37SY1wf70eiG/6gJywwuDj0auhn8Odg+hTuLsBvj++dJ5f95hpx7i3DU0qPnug
fvgU7GKKX2xbpU3/2HdaanhGOxfupSYMQRwSjhrmjs9VyvCvMO9WsmNMWWVByfI1
FqM3bywhytvEkrOG/F9JvPXRxb6BIkuxbaf43lBNuPyBHMbw1VP+lkyYVJHmDkaF
SxKt2Il9OQHhzQ/UXlxx8IDq513D1smUEGKX/wWjbSqtEmMa/J4P4JH9QLJ0T1pN
dz77g25RVofkwEzB6XFp9Gk6TL5XGd64d96aKgZD69tMS90en+PtkcZ5pNIRzbyP
tfOlihAL3XEoAziP8MgQwEAUuUKvSJDGiADJYpLBriWl7+h+MQz8B9bFGJQcbthz
jAqS53/nC23B04Kj7rmR4pHe8v90Lewz58TaRF8I1ie53G5bHTelYkxpB8RB7Ii4
6JggvBTrcNcls7ZMm5tt00XE7ymy8eR1bQxEiS/LHTwBW1BcCfneuBLnQdavukYc
LBP/1qvz5tLVSo+lqDJlLnkCAwEAAQ==
-----END PUBLIC KEY-----`

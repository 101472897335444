import CONST from "./Constant";

const INITIAL_STATE = {
    activities:[],
    monthlyStats:[],
    stats:{},
    tasks:{
        list:[],
        selected:{},
        code:undefined,
        error:[],
        message:[],
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:5,
            },
            filters:{
                keyword:'',
                sort_by:'business_id',
                sort_dir:'DESC',
                status:['SUBMITTED']
            }
        },
    },
    overduePayments:{
        list:[],
        selected:{},
        code:undefined,
        error:[],
        message:[],
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:5,
            },
            filters:{
                keyword:'',
                sort_by:'due_date',
                sort_dir:'ASC',
                transaction_type:['CREDIT'],
                status:['OVERDUE']
            }
        },
    },
    defaultPayments:{
        list:[],
        selected:{},
        code:undefined,
        error:[],
        message:[],
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:5,
            },
            filters:{
                keyword:'',
                sort_by:'due_date',
                sort_dir:'ASC',
                transaction_type:['CREDIT'],
                status:['DEFAULT']
            }
        },
    },
    upcomingPayments:{
        list:[],
        selected:{},
        code:undefined,
        error:[],
        message:[],
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:5,
            },
            filters:{
                keyword:'',
                sort_by:'due_date',
                sort_dir:'ASC',
                transaction_type:['CREDIT'],
                status:['PARTIAL','PLANNED','EXTENDED']
            }
        },
    },
    businessOverview:{
        list:[],
        selected:{},
        last_sync_at:null,
        code:undefined,
        error:[],
        message:[],
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:5,
            },
            filters:{
                keyword:'',
                sort_by:'overdue',
                sort_dir:'desc',
            }
        },
    },
    security:{},
};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){
        case CONST.FETCH_STATS_SUCCESS:
            return {
                ...state,
                stats:{
                    ...state.stats,
                    ...action.payload.data
                },
                error:[],
                code:200
            }
        case CONST.FETCH_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_PROPOSAL_STATS_SUCCESS:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    ...action.payload.data,
                },
                error:[],
                code:200
            }
        case CONST.FETCH_PROPOSAL_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_FUNDING_STATS_SUCCESS:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    ...action.payload.data,
                },
                error:[],
                code:200
            }
        case CONST.FETCH_FUNDING_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.FETCH_MONTHLY_STATS_SUCCESS:
            return {
                ...state,
                monthlyStats: action.payload.data,
                error:[],
                code:200
            }
        case CONST.FETCH_MONTHLY_STATS_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: [action.payload],
                code:action.payload.status,
            }
        case CONST.SET_UPCOMING_PAYMENT_CONFIG:
            return {
                ...state,
                upcomingPayments: {
                    ...state.upcomingPayments,
                    config: {
                        ...state.upcomingPayments.config,
                        ...action.payload
                    },
                }
            };
        case CONST.FETCH_UPCOMING_PAYMENTS_SUCCESS:
            return {
                ...state,
                upcomingPayments: {
                    ...state.upcomingPayments,
                    list: action.payload.data.data,
                    config:{
                        ...state.upcomingPayments.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.upcomingPayments.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    message: ["Upcoming payments loaded successfully"],
                    code:action.payload.status,
                }
            }
        case CONST.FETCH_UPCOMING_PAYMENTS_FAILED:
            return {
                ...state,
                upcomingPayments: {
                    ...state.upcomingPayments,
                    error: action.payload.data.errors,
                    message: action.payload.data.message,
                    code:action.payload.status,
                }
            }

        case CONST.SET_TASK_CONFIG:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    config: {
                        ...state.tasks.config,
                        ...action.payload
                    },
                }
            };
        case CONST.SET_SELECTED_TASK:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    selected: action.payload === null ? {} : {...state.tasks.list.filter(item => item.id === action.payload)[0]}
                }
            }
        case CONST.FETCH_TASKS_SUCCESS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    list: action.payload.data.data,
                    config:{
                        ...state.tasks.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.tasks.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    message: ["Overdue payments loaded successfully"],
                    code:action.payload.status,
                }
            }
        case CONST.FETCH_TASKS_FAILED:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    error: action.payload.data.errors,
                    message: action.payload.data.message,
                    code:action.payload.status,
                }
            }
        case CONST.VERIFY_TASK_SUCCESS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    list: state.tasks.list.filter(item => item.id !== action.payload.data.id),
                    code:action.payload.status,
                    message: ["Task verified successfully"],
                }
            }
        case CONST.VERIFY_TASK_FAILED:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    code:action.payload.status,
                    error: action.payload.data.errors,
                    message: action.payload.data.message,
                }
            }
        case CONST.RETURN_TASK_SUCCESS:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    list: state.tasks.list.filter(item => item.id !== action.payload.data.id),
                    code:action.payload.status,
                    message: ["Task returned successfully"],
                }
            }
        case CONST.RETURN_TASK_FAILED:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    code:action.payload.status,
                    error: action.payload.data.errors,
                    message: action.payload.data.message,
                }
            }

        case CONST.SET_OVERDUE_PAYMENT_CONFIG:
            return {
                ...state,
                overduePayments: {
                    ...state.overduePayments,
                    config: {
                        ...state.overduePayments.config,
                        ...action.payload
                    },
                }
            };
        case CONST.FETCH_OVERDUE_PAYMENTS_SUCCESS:
            return {
                ...state,
                overduePayments: {
                    ...state.overduePayments,
                    list: action.payload.data.data,
                    config:{
                        ...state.overduePayments.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.overduePayments.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    message: ["Overdue payments loaded successfully"],
                    code:action.payload.status,
                }
            }
        case CONST.FETCH_OVERDUE_PAYMENTS_FAILED:
            return {
                ...state,
                overduePayments: {
                    ...state.overduePayments,
                    error: action.payload.data.errors,
                    message: action.payload.data.message,
                    code:action.payload.status,
                }
            }

        case CONST.SET_DEFAULT_PAYMENT_CONFIG:
            return {
                ...state,
                defaultPayments: {
                    ...state.defaultPayments,
                    config: {
                        ...state.defaultPayments.config,
                        ...action.payload
                    },
                }
            };
        case CONST.FETCH_DEFAULT_PAYMENTS_SUCCESS:
            return {
                ...state,
                defaultPayments: {
                    ...state.defaultPayments,
                    list: action.payload.data.data,
                    config:{
                        ...state.defaultPayments.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.defaultPayments.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    message: ["Overdue payments loaded successfully"],
                    code:action.payload.status,
                }
            }
        case CONST.FETCH_DEFAULT_PAYMENTS_FAILED:
            return {
                ...state,
                defaultPayments: {
                    ...state.defaultPayments,
                    error: action.payload.data.errors,
                    message: action.payload.data.message,
                    code:action.payload.status,
                }
            }

        case CONST.SET_BUSINESS_OVERVIEW_CONFIG:
            return {
                ...state,
                businessOverview: {
                    ...state.businessOverview,
                    config: {
                        ...state.businessOverview.config,
                        ...action.payload
                    },
                }
            };
        case CONST.FETCH_BUSINESS_OVERVIEW_SUCCESS:
            return {
                ...state,
                businessOverview: {
                    ...state.businessOverview,
                    list: action.payload.data.data,
                    config:{
                        ...state.businessOverview.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.businessOverview.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    last_sync_at:action.payload.data.last_sync_at,
                    error: [],
                    message: ["Business overview loaded successfully"],
                    code:action.payload.status,
                }
            }
        case CONST.FETCH_BUSINESS_OVERVIEW_FAILED:
            return {
                ...state,
                businessOverview: {
                    ...state.businessOverview,
                    error: action.payload.data.errors,
                    message: action.payload.data.message,
                    code:action.payload.status,
                }
            }
        case CONST.SYNC_BUSINESS_OVERVIEW_SUCCESS:
            return {
                ...state,
                businessOverview: {
                    ...state.businessOverview,
                    list: action.payload.data.data,
                    config:{
                        ...state.businessOverview.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.businessOverview.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    message: ["Business overview synced successfully"],
                    code:action.payload.status,
                }
            }
        case CONST.FETCH_SECURITY_STATS_SUCCESS:

        default:
            return state;
    }
}

export default Reducer ;
import React, {useEffect} from 'react';
import { Box, Card, CardContent, Grid, Tab, Tabs } from "@mui/material";
import { Breadcrumbs } from "../../../../Components";
import {
    GeneralSettingsTab,
    ApplicationSettingsTab,
    SecuritySettingsTab,
    DataSettingsTab,
    NotificationSettingsTab,
    BusinessRoleTab,
    fetchSettings
} from "../index";
import {useDispatch} from "react-redux";

export const Settings = () => {

    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        await fetchSettings({dispatch, filters:{}});
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let tabComponent;

    switch (value) {
        case 0:
            tabComponent = <GeneralSettingsTab />;
            break;
        case 1:
            tabComponent = <ApplicationSettingsTab />;
            break;
        case 2:
            tabComponent = <SecuritySettingsTab />;
            break;
        case 3:
            tabComponent = <BusinessRoleTab />;
            break;
        case 4:
            tabComponent = <NotificationSettingsTab />;
            break;
        default:
            tabComponent = null;
            break;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/SETTINGS'} sx={{display:'inline-block'}} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent sx={{p:'0!important'}}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="General" />
                                <Tab label="Application" />
                                <Tab label="Security" />
                                <Tab label="Business Role" />
                                <Tab label="Notification" />
                            </Tabs>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {tabComponent}
                </Grid>
            </Grid>
        </>
    );
};

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import {useDispatch, useSelector} from "react-redux";
import {styled, useTheme} from "@mui/material/styles";
import {useMediaQuery, Paper} from "@mui/material";
import {setModal} from "../../Modules/Common/App";
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const Modal = () => {

    const app = useSelector((store) => store.app);
    const dispatch = useDispatch();
    const open = app.modal.open;
    const component = app.modal.component;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const StyledDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogTitle-root': {
            cursor: 'move',
        },
    }));

    return (
        <>
            <StyledDialog
                fullWidth
                maxWidth={'sm'}
                fullScreen={fullScreen || app.modal.fullScreen}
                open={open}
                onClose={() => dispatch(setModal({component: null, open: false}))}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                {component ?? null}
            </StyledDialog>
        </>
    );
}

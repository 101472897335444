import * as React from 'react';
import { countries } from "../../Setup/Config";
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function SelectCountry(props) {
    const handleChange = (e) => {
        props.state({
            ...props.data,
            [e.target.name]: e.target.value,
        });
    }

    // Set a default value for the disabled prop if not passed
    const isDisabled = props.disabled || props.disabledSubmit || false;

    return (
        <>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel id={"label-" + props?.name}>{props?.label}</InputLabel>
                <Select
                    fullWidth
                    disabled={isDisabled}
                    value={(props.data[props.name] || props.default) ?? " "}
                    name={props?.name}
                    MenuProps={{
                        style: { zIndex: 35001 }
                    }}
                    label={props?.label}
                    onChange={(e) => handleChange(e)}
                >
                    <MenuItem value=" " key="None">Select Country</MenuItem>
                    <MenuItem value="ARE" key="ARE">United Arab Emirates</MenuItem>
                    {countries.map((i) => (
                        <MenuItem key={i.id} value={i.iso3}>{i.name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>{props?.error}</FormHelperText>
            </FormControl>
        </>
    );
}

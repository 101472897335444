import React from 'react';
import Box from "@mui/material/Box";
import {Typography, Grid, } from "@mui/material";
import {InvestorRegistrationForm} from "../../index";

export const InvestorRegistrationLg = () => {

    return (
        <Grid item container xs={12} sx={{ height: '80vh'}}>

            <Grid item alignSelf={"center"} xs={12} md={4}>
                <Typography color={"#ffffff"} mb={3} sx={{ fontWeight: '300', fontSize:'30px', fontFamily:'Open Sans', lineHeight: '4rem' }} variant={"h3"}>User Registration</Typography>
                <Typography variant={"h5"} sx={{ fontSize: '1.2rem' }} color={"#ffffff"}>What type of business are you forming?</Typography>
            </Grid>

            <Grid item container p={4} justifyContent={"center"} alignContent={"center"} xs={12} md={8} sx={{background:'#ffffff'}}>
                <Box sx={{ width:'100%', maxWidth:800}}>
                    <InvestorRegistrationForm />
                </Box>
            </Grid>
        </Grid>
    )
}





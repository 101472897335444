import * as React from 'react';

import {List as ListUI} from "@mui/material";
import {styled} from "@mui/material/styles";

export const PanelList = ({children, props}) => {

    const List = styled(ListUI)(({ theme }) => ({
        color: 'darkslategray',
        padding: theme.spacing(0), // Using theme.spacing for padding
        '& .MuiListItem-root': {
            padding: theme.spacing(1, 1, 1,2), // Set padding using theme.spacing
            minHeight: '50px',
            borderBottom: '1px solid #efeaea',
            backgroundColor: '#f8f8f8b0',
            position: 'relative',
            '& .MuiListItemText-root': {
                color: 'gray',
                margin: '0',
                '& span': {
                    fontSize: '11px',
                    display: 'inline-block'
                },
                '& p': {
                    fontSize: '12px',
                    fontWeight: 'bold'
                },
                // Add styles for links
                '& a': {
                    color: theme.link.active,
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize:'12px',
                    '&:hover': {
                        color: theme.palette.primary.dark, // Darken color on hover
                    },
                }
            }
        },
        '& .MuiListItemSecondaryAction-root': {
            right: '16px',
            top: '50%',
            transform: 'translateY(-50%)',
            position: 'absolute',
            '& .MuiButtonBase-root': {
                padding: '0px!important',
                marginLeft: '0',
                '& .MuiSvgIcon-root': {
                    width: '20px',
                },
            },
        }
    }));

    return (
        <List {...props}>
            {children}
        </List>
    )
}
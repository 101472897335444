import * as React from 'react';
import {Grid, Stack} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {ServiceFundsTable, ServiceRequestOverviewPanel, ActionButtons} from "../../Services";

export const ServiceView = () => {

    return (
        <>
            <Grid container justifyContent={"center"} spacing={2} >
                <Grid item xs={12}>
                    <Stack justifyContent={'space-between'} direction={'row'}>
                        <Breadcrumbs items={'DASHBOARD/SERVICES'} />
                        <ActionButtons />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Stack spacing={2} direction={"column"}>
                        <ServiceFundsTable />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Stack spacing={2} direction={"column"}>
                        <ServiceRequestOverviewPanel />
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}
import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {setConfig, fetchRepayments} from "../../";
import {currencyFormatter, currencyFormatterDecimal} from "../../../../../Setup/Config";
import {useEffect} from "react";
import moment from "moment";
import {Chip} from "@mui/material";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const RepaymentTable = () => {

    const dispatch = useDispatch();
    const repayments = useSelector((store) => store.repayments);
    const filters = useSelector((store) => store.repayments.config.filters);
    const pagination = useDeepEqualSelector((store) => store.repayments.config.pagination);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchItems();
    },[pagination]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchRepayments({dispatch, data:{...filters, ...pagination}}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const columns = React.useMemo(
        () => [
            { field: 'business_id', headerName:'Business', type: 'string', width:350, valueGetter: (params) => params.row?.service?.business?.name},
            { field: 'type', headerName:'Product', type: 'string', width: 220, valueGetter: (params) => params.row?.service?.service_type},

            { field: 'amount', headerName:'Amount', headerAlign:'center', align:'center', type: 'string', width: 120, valueGetter: (params) => (params.row?.amount !== undefined && params.row?.amount !== null) ? "AED " + currencyFormatterDecimal.format(params.row?.amount ?? 0) : "-"},
            { field: 'due_date', headerName: 'Due Date', type: 'string', width: 100, valueFormatter: ({value}) => value ? moment(value).format('DD/MM/YYYY') : '-'},
            { field: 'status', headerName:'Status', type: 'string', width:130,
                renderCell: (params) => {
                    const status = {
                        'PENDING':'warning',
                        'PAID':'success',
                        'OVERDUE':'error',
                        'DEFAULTED':'error',
                    }
                    return(<StatusBall
                        variant="contained"
                        label={params.value}
                        color={status[params.value]}
                    />)
                },
            },
        ],
        [],
    );

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={repayments?.list || []}
                    sx={{ border:'none'}}
                    pagination
                    paginationMode="server"
                    paginationModel={pagination}
                    rowCount={repayments?.config?.rowCount ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                    onPageChange={(newPageNo) => dispatch(setConfig({pagination: {...pagination, page: newPageNo}}))}
                    onPageSizeChange={(newPageSize) => dispatch(setConfig({pagination: {...pagination, pageSize: newPageSize}}))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'group', sort: 'asc' }],
                        },
                        pinnedColumns: {  right: ['status','actions'] },
                        pagination: { paginationModel: { ...pagination } }
                    }}
                />
            </>
        );
}

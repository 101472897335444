import React, {useEffect, useState} from 'react'
import {FormControl, InputLabel, Select, Stack, TextField} from "@mui/material";
import {useDispatch, useSelector,} from "react-redux";
import {useNavigate, } from "react-router-dom";
import { verifyCustomer, } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";
import MenuItem from "@mui/material/MenuItem";
import { setModal } from "../../../../Common/App";

export const VerifyCustomerModal = () => {

    const business = useSelector((state) => state.business);
    const customer = business.customers.selected;
    const config = customer.config;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [data, setData] = useState();
    const [response, setResponse] = useState({});

    useEffect(() => {
        let con = {}
        config.map((c) => con[c.config_type] = c.config_value)
        setData(con);
    },[]);

    const handleSubmit = async () => {

        let formData = {}
        formData['business_id'] = customer.business_id;
        formData['customer_id'] = customer.customer_id;
        formData['config'] = data;

        setDisabled(true);

        await verifyCustomer({dispatch, data:formData}).then((res) => {
            setResponse(res);
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
                navigate('/customers');
            }
        });
    };

    const handleChange = (e) => {

        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    return(
        <>
            <DialogTitle>Verify Customer</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to Verify this customer?
                </DialogContentText>
                {data !== undefined &&
                <Stack spacing={2} sx={{mt:2}}>
                    <TextField
                        defaultValue={data?.credit_limit_requested}
                        label={"Recommended Credit Limit"}
                        fullWidth
                        required
                        name={"credit_limit_recommended"}
                        onBlur={handleChange}
                        helperText={business.error?.credit_limit_recommended}
                    />
                    <TextField
                        defaultValue={data?.max_allowed_tenor}
                        label={"Max Allowed Tenor (in days)"}
                        fullWidth
                        required
                        name={"max_allowed_tenor"}
                        onBlur={handleChange}
                        type={"number"}
                        helperText={business.error?.max_allowed_tenor}
                    />
                    <TextField
                        defaultValue={data?.discounting_forfaiting_fee}
                        label={"Discounting / Forfaiting Fees (profit rate % )"}
                        fullWidth
                        required
                        name={"discounting_forfaiting_fee"}
                        onBlur={handleChange}
                        type={"number"}
                        helperText={business.error?.discounting_forfaiting_fee}
                    />
                    <TextField
                        defaultValue={data?.margin_required}
                        label={"Margin Required"}
                        fullWidth
                        required
                        name={"margin_required"}
                        onBlur={handleChange}
                        type={"number"}
                        helperText={business.error?.margin_required}
                    />
                    <FormControl sx={{ width:'100%' }}>
                        <InputLabel id="label-office">Payment Terms</InputLabel>
                        <Select
                            value={data?.payment_terms ?? ""}
                            name="payment_terms"
                            MenuProps={{
                                style: {zIndex: 35001}
                            }}
                            fullWidth
                            label="Payment Terms"
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem key={"30"} value={'30'}>30 days</MenuItem>
                            <MenuItem key={"45"} value={'45'}>45 days</MenuItem>
                            <MenuItem key={"75"} value={'75'}>75 days</MenuItem>
                            <MenuItem key={"90"} value={'90'}>90 days</MenuItem>
                            <MenuItem key={"120"} value={'120'}>120 days</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        defaultValue={data?.grace_period}
                        label={"Grace Period (in days)"}
                        fullWidth
                        required
                        name={"grace_period"}
                        onBlur={handleChange}
                        type={"number"}
                        helperText={business.error?.grace_period}
                    />
                    <FormControl sx={{ width:'100%' }}>
                        <InputLabel id="label-office">Payment Method</InputLabel>
                        <Select
                            value={data?.payment_method ?? ""}
                            name="payment_method"
                            MenuProps={{
                                style: {zIndex: 35001}
                            }}
                            fullWidth
                            label="Payment Method"
                            onChange={(e) => handleChange(e)}
                        >
                            <MenuItem key={""} value={''}>Select Payment Method</MenuItem>
                            <MenuItem key={"Cash"} value={'Cash'}>Cash</MenuItem>
                            <MenuItem key={"Cheque"} value={'Cheque'}>Cheque</MenuItem>
                            <MenuItem key={"Bank Transfer"} value={'Bank Transfer'}>Bank Transfer</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width:'100%' }}>
                        <InputLabel id="label-currency">Currency</InputLabel>
                        <Select
                            value={data?.currency ?? ""}
                            name="currency"
                            MenuProps={{
                                style: {zIndex: 35001}
                            }}
                            fullWidth
                            label="Currency"
                            onChange={(e) => handleChange(e)}
                            helperText={business.error?.currency}
                        >
                            <MenuItem key={""} value={''}>Select Currency</MenuItem>
                            <MenuItem key={"AED"} value={'AED'}>AED</MenuItem>
                            <MenuItem key={"USD"} value={'USD'}>USD</MenuItem>
                            <MenuItem key={"EUR"} value={'EUR'}>EUR</MenuItem>
                            <MenuItem key={"GBP"} value={'GBP'}>GBP</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        defaultValue={data?.retention_amount}
                        label={"Retention Amount (%)"}
                        fullWidth
                        required
                        name={"retention_amount"}
                        onBlur={handleChange}
                        type={"number"}
                        helperText={business.error?.retention_amount}
                    />
                    <TextField
                        defaultValue={data?.drawdown_fee}
                        label={"Drawdown Fee"}
                        fullWidth
                        required
                        name={"drawdown_fee"}
                        onBlur={handleChange}
                        type={"number"}
                        helperText={business.error?.drawdown_fee}
                    />
                    <TextField
                        defaultValue={data?.utilization_commitment}
                        label={"Utilizaiton Commitment (%)"}
                        fullWidth
                        required
                        name={"utilization_commitment"}
                        onBlur={handleChange}
                        type={"number"}
                        helperText={business.error?.utilization_commitment}
                    />
                    <TextField
                        defaultValue={data?.other_conditions}
                        label={"Other Condition"}
                        fullWidth
                        required
                        name={"other_conditions"}
                        onBlur={handleChange}
                        helperText={business.error?.other_conditions}
                    />
                </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Verify Customer</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}

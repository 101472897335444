import {Card, CardActions, CardContent, CardHeader, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import React from "react";

export const RegistrationSuccessCard = () => {

    return (<Card>
        <CardHeader title={"User Registered Successfully"} titleTypographyProps={{color:'success', fontSize:'16px', fontWeight:'bold'}} />
        <CardContent sx={{alignItems:'center', justifyContent:'center', paddingY:'50px'}}>
            <Typography color={'darkgrey'} sx={{textAlign:'center'}}>Please check your mailbox for Email Verification</Typography>
            <Typography color={'darkgray'} sx={{textAlign:'center'}}>You can access Ifund after email verification.</Typography>
        </CardContent>
        <CardActions sx={{display:'flex', flexDirection:'column', p:2, borderTop:'none!important'}} >

            <Stack direction={"row"} spacing={2} justifyContent="space-between">
                <Typography align={'center'}>
                    Already have an account? <Link to={'/login'}>Login</Link>
                </Typography>
            </Stack>
        </CardActions>
    </Card>)
}
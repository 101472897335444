import * as React from 'react';
import {Grid, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import {useEffect} from "react";
import {fetchMyWallet} from "../Redux/Action";
import Button from "@mui/material/Button";
import {setModal} from "../../../Common/App";
import {AddFundPanel, WithdrawFundPanel, WalletStatsPanel, BankDetailsPanel, WalletNoticePanel, WalletRequestsPanel} from "../index";
import {Breadcrumbs} from "../../../../Components";

export const MyWallet = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async() => {
        await fetchMyWallet({dispatch}).then((res) => {
            //console.log(res)
        });
    }
    const handleAddFunds = async () => {
        dispatch(setModal({component: <AddFundPanel />, open: true}));
    }

    const handleWithdrawal = async () => {
        dispatch(setModal({component: <WithdrawFundPanel />, open: true}));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Breadcrumbs items={'DASHBOARD/MYWALLET'} sx={{display:'inline-block'}} />
                    <Stack spacing={2} direction={"row"}>
                        <Button variant={"contained"} color={"primary"} onClick={handleAddFunds}>Add Funds</Button>
                        <Button variant={"contained"} color={"warning"} onClick={handleWithdrawal}>Withdraw</Button>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={4}>
                    <WalletStatsPanel />
                </Grid>
                <Grid item xs={12} md={8}>
                    <BankDetailsPanel />
                </Grid>
                <Grid item xs={12}>
                    <WalletNoticePanel />
                </Grid>
                <Grid item xs={12}>
                    <WalletRequestsPanel />
                </Grid>
            </Grid>
        </Grid>
    );
}
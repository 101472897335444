import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import { BankBorrowingForm } from "../Forms/BankBorrowingForm";
import {Grid, List, ListItem, Stack, Typography} from "@mui/material";
import { addBankBorrowing, clearErrors, proceed, setSelectedBankBorrowing, BankBorrowingDeleteModal} from "../../index";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import {InfoBox} from "../../../../../Components/Elements/InfoBox";

export const BankBorrowingStep = () => {

    const business = useSelector((store) => store.business);
    let items = Object.keys(business.bankBorrowings).length > 0 ? business.bankBorrowings.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addBankBorrowing({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedBankBorrowing(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<BankBorrowingForm />, open:true}))
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<BankBorrowingDeleteModal />, open:true}))
        dispatch(setSelectedBankBorrowing(e.currentTarget.dataset.id));
    }

    const columns = React.useMemo(
        () => [
            { field: 'bank_id', headerName:'Bank/Institution', type: 'string', flex:1, minWidth:250,
                valueGetter: (params) => {
                    return params.row?.bank?.bank_name;
                }
            },
            { field: 'currency', headerName:'Currency', type: 'string', flex:1, minWidth:90 },
            { field: 'borrowing_limit', headerName:'Borrowing Limit', type: 'number', flex:1, minWidth:130, valueFormatter: ({ value }) => currencyFormatter.format(value)  },
            { field: 'outstanding', headerName:'Outstanding', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value)  },
            { field: 'overdue', headerName:'Overdue', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value)  },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                width:80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ],
            },
        ],
        [],
    );

    return (
        <Grid container>

            <Grid item xs={12}  >
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    pageSize={filters.pageSize}
                    onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                    onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                    columns={columns}
                    hideFooterPagination
                    rows={items || []} sx={{ border:'none'}}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] }
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="contained" color={"info"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn} sx={{ display:'inline-block'}}>Add Bank Borrowings</Button>
                    </Grid>
                    { items && items.length === 0 &&
                        <Grid item xs={12}>
                            <Instructions />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

const Instructions = () => {

    return (<InfoBox>
                <Typography variant={"h3"}>Bank Borrowings</Typography>
                <Typography variant={"body1"}>
                    To complete this section, fill the details of all the borrowings made by the company. You will be required to attach the Loan Agreement or Facility Offer Letter
                </Typography>
            </InfoBox>)
}
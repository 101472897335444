const CONST = {
    'FETCH_CONFIGS_SUCCESS':'DASHBOARD/FETCH_CONFIGS_SUCCESS',
    'FETCH_CONFIGS_FAILED':'DASHBOARD/FETCH_CONFIGS_FAILED',
    'FETCH_STATS_SUCCESS':'DASHBOARD/FETCH_STATS_SUCCESS',
    'FETCH_STATS_FAILED':'DASHBOARD/FETCH_STATS_FAILED',
    'FETCH_FUNDING_STATS_SUCCESS':'DASHBOARD/FETCH_FUNDING_STATS_SUCCESS',
    'FETCH_FUNDING_STATS_FAILED':'DASHBOARD/FETCH_FUNDING_STATS_FAILED',
    'FETCH_PROPOSAL_STATS_SUCCESS':'DASHBOARD/FETCH_PROPOSAL_STATS_SUCCESS',
    'FETCH_PROPOSAL_STATS_FAILED':'DASHBOARD/FETCH_PROPOSAL_STATS_FAILED',
    'FETCH_MONTHLY_STATS_SUCCESS':'DASHBOARD/FETCH_MONTHLY_STATS_SUCCESS',
    'FETCH_MONTHLY_STATS_FAILED':'DASHBOARD/FETCH_MONTHLY_STATS_FAILED',
    'SET_UPCOMING_PAYMENT_CONFIG':'DASHBOARD/SET_UPCOMING_PAYMENT_CONFIG',
    'FETCH_UPCOMING_PAYMENTS_SUCCESS':'DASHBOARD/FETCH_UPCOMING_PAYMENTS_SUCCESS',
    'FETCH_UPCOMING_PAYMENTS_FAILED':'DASHBOARD/FETCH_UPCOMING_PAYMENTS_FAILED',

    'SET_OVERDUE_PAYMENT_CONFIG':'DASHBOARD/SET_OVERDUE_PAYMENT_CONFIG',
    'FETCH_OVERDUE_PAYMENTS_SUCCESS':'DASHBOARD/FETCH_OVERDUE_PAYMENTS_SUCCESS',
    'FETCH_OVERDUE_PAYMENTS_FAILED':'DASHBOARD/FETCH_OVERDUE_PAYMENTS_FAILED',

    'SET_DEFAULT_PAYMENT_CONFIG':'DASHBOARD/SET_DEFAULT_PAYMENT_CONFIG',
    'FETCH_DEFAULT_PAYMENTS_FAILED':'DASHBOARD/FETCH_DEFAULT_PAYMENTS_FAILED',
    'FETCH_DEFAULT_PAYMENTS_SUCCESS':'DASHBOARD/FETCH_DEFAULT_PAYMENTS_SUCCESS',

    'SET_BUSINESS_OVERVIEW_CONFIG':'DASHBOARD/SET_BUSINESS_OVERVIEW_CONFIG',
    'FETCH_BUSINESS_OVERVIEW_SUCCESS':'DASHBOARD/FETCH_BUSINESS_OVERVIEW_SUCCESS',
    'FETCH_BUSINESS_OVERVIEW_FAILED':'DASHBOARD/FETCH_BUSINESS_OVERVIEW_FAILED',
    'SYNC_BUSINESS_OVERVIEW_SUCCESS':'DASHBOARD/SYNC_BUSINESS_OVERVIEW_SUCCESS',
    'SYNC_BUSINESS_OVERVIEW_FAILED':'DASHBOARD/SYNC_BUSINESS_OVERVIEW_FAILED',
    'SET_SELECTED_TASK':'DASHBOARD/SET_SELECTED_TASK',
    'SET_TASK_CONFIG':'DASHBOARD/SET_TASK_CONFIG',
    'FETCH_TASKS_SUCCESS':'DASHBOARD/FETCH_TASKS_SUCCESS',
    'FETCH_TASKS_FAILED':'DASHBOARD/FETCH_TASKS_FAILED',
    'VERIFY_TASK_SUCCESS':'DASHBOARD/VERIFY_TASK_SUCCESS',
    'VERIFY_TASK_FAILED':'DASHBOARD/VERIFY_TASK_FAILED',
    'RETURN_TASK_SUCCESS':'DASHBOARD/RETURN_TASK_SUCCESS',
    'RETURN_TASK_FAILED':'DASHBOARD/RETURN_TASK_FAILED',
    'UPDATE_EXPIRED_DOCUMENT_SUCCESS':'DASHBOARD/UPDATE_EXPIRED_DOCUMENT_SUCCESS',
    'UPDATE_EXPIRED_DOCUMENT_FAILED':'DASHBOARD/UPDATE_EXPIRED_DOCUMENT_FAILED',
}

export default CONST;
import { doLogout } from "../../Modules/Common/Auth";

export const handleApiTokenError = (dispatch, request, error) => {
    if ((typeof request === 'function' && !request.toString().includes('logout')) && [401].includes(error?.response?.status)) {
        doLogout(dispatch)
    }
};

const handleNetworkError = (dispatch, error) => {
    if (error.code === "ERR_NETWORK") {
        if (!error.response) error.response = {}; // Ensure response object exists
        error.response.data = { ...error.response.data, message: "Server not reachable"}; // Add message to response data
        error.response = { ...error.response, status:502};
    }
};


export const handleAsyncAction = async (dispatch, request, successActions, failureAction, successData = null) => {
    try {
        const response = await request();
        const responseData = successData ? response[successData] : response;
        if (Array.isArray(successActions)) {
            successActions.forEach((action, index) => {
                if (index === 0) {
                    dispatch(action(response));
                } else {
                    dispatch(action(responseData));
                }
            });
        } else {
            dispatch(successActions(responseData));
        }
        return { success: true, data: responseData };
    } catch (error) {

        handleApiTokenError(dispatch, request, error);
        handleNetworkError(dispatch, error);

        if (!Array.isArray(failureAction)) {
            dispatch(failureAction(error?.response));
        } else {
            failureAction.forEach(action => dispatch(action(error?.response)));
        }

        return { success: false, error: error.response };
    }
};


import * as React from 'react';
import {useDispatch} from "react-redux";
import {
    Button,
    DialogTitle,
    DialogContent,
    Typography,
    TableContainer,
    TableBody,
    Table as TableUI,
    TableHead,
    TableRow,
    TableCell,
    Tooltip
} from "@mui/material";
import {setModal} from "../../../../Common/App";
import DialogActions from "@mui/material/DialogActions";
import {useEffect} from "react";
import {fetchBusinesses} from "../../../../Corporate/Business";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {styled, useTheme} from "@mui/material/styles";

export const BusinessAtProposalModal = ({status}) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [response, setResponse] = React.useState([]);

    useEffect(() => {
        fetchData();
    },[status]);

    const fetchData = async () => {

        await fetchBusinesses({dispatch, data:{status}}).then((res) => {
            if(res.success === true){
                setResponse(res?.data?.data?.data);
            }
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/dashboard/' + id);
        },
        [],
    );

    const Table = styled(TableUI)(({ theme }) => ({
        '& .link': {
            cursor: 'pointer',
            color: theme.palette.info.main,
            fontSize:'0.85rem',
        },
        '& .link:hover': {
            color: theme.palette.primary.dark,
        },
        '& .MuiTableCell-root': {  // Make sure this selector matches your TableCell class
            padding: '16px 6px',
        },
    }));

    const title = () => {

        switch(status[0]){
            case 'DRAFT':
                return "Operation Team's Queue";
            case 'SUBMITTED':
                return "Business Analyst's Queue";
            case 'VERIFIED':
                return "Business Head's Queue";
            case 'BH_APPROVED':
                return "Credit Review Team's Queue";
            case 'REVIEWED':
                return "Other's Queue";
            case 'CREDIT_APPROVED':
                return "CEO's Queue";
            case 'CEO_APPROVED':
                return "Board of Director's Queue";
            case 'BOD_APPROVED':
                return "Chairman's Queue";
            default:
                return '';
        }
    }

    return (
        <>
            <DialogTitle>{ title() }</DialogTitle>
            <DialogContent>
                {response.length === 0 ? <p>No data found</p> :
                <TableContainer>
                    <Table padding={"normal"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>S No</TableCell>
                                <TableCell>Business</TableCell>
                                <TableCell>Registered</TableCell>
                                <TableCell>Assignment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {response.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.business_no}</TableCell>
                                    <TableCell className={"link"}><Typography onClick={viewItem(item.business_id)}>{item.name}</Typography></TableCell>
                                    <TableCell>{moment(item.created_at).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell><Tooltip title={item.assigned_to?.email}>{(item.assigned_to?.first_name ?? "") + ' ' + (item.assigned_to?.last_name ?? "") ?? "-"}</Tooltip></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Close</Button>
            </DialogActions>
        </>
    );
}
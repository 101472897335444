import React, {useRef, useState, useEffect, useCallback} from 'react';
import { Grid,  Stack, Button as ButtonUI, TextField, TextField as TextFieldUI, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate, Link as LinkUI } from "react-router-dom";
import { doLogin } from "../Redux/Action";
import {useDispatch} from "react-redux";
import {isBrowser} from "react-device-detect";
import {styled} from "@mui/material/styles";
import {fetchAppConfig, setModal, setRightDrawer, setBlockUI} from "../../App";
import {LoginForm} from "../index";
import Toastr from "../../../../Components/Elements/Toastr";
import forge from 'node-forge';
import {publicKeyPem} from "../../../../Setup/Keys/public";

export const Login = () => {

    return (
        <>{isBrowser ? <BrowserView /> : <MobileView />}</>
    )
}

const BrowserView = () => {

    return (
        <Grid item container xs={12} sx={{ height: '80vh'}}>

            <Grid item alignSelf={"center"} xs={12} md={4} pr={4}>
                <Typography color={"#ffffff"} mb={1} sx={{ fontWeight: '300', fontSize:'30px', fontFamily:'Open Sans', lineHeight: '40px' }} variant={"h3"}>Unlock Cash Flow with Our Extensive Range of Solutions:</Typography>
                <Typography variant={"h5"} sx={{ fontSize: '1rem' }} color={"#ffffff"}>Factoring, Forfaiting, Invoice Discounting and many more…</Typography>
            </Grid>

            <Grid item container justifyContent={"center"} alignContent={"center"} xs={12} md={8} sx={{background:'#ffffff'}}>
                <LoginForm />
            </Grid>

        </Grid>
    )
}

const MobileView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        dispatch(setBlockUI({open:false}));
        userRef.current.focus();
    }, [])

    const handleUsernameChange = useCallback((e) => {
        setDisabled(false);
        setUsername(e.target.value);
    }, []);

    const handlePasswordChange = useCallback((e) => {
        setDisabled(false);
        setPassword(e.target.value);
    }, []);

    const encryptPassword = (password) => {
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        const encryptedPassword = publicKey.encrypt(password, 'RSA-OAEP', {
            md: forge.md.sha256.create()
        });
        return forge.util.encode64(encryptedPassword);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {

        setDisabled(true)
        const encryptedPassword = encryptPassword(password);
        const res = await doLogin({dispatch, data:{ username, password:encryptedPassword }});
        dispatch(setBlockUI({ open: false, message: '' }));
        dispatch(setModal({ open: false, component: null }));
        dispatch(setRightDrawer({ open: false, component: null }));
        setResponse(res);
        setDisabled(false);

        if (res['success']) {
            const user = res['data']['data'];
            await fetchAppConfig({ dispatch })
            const path = user['is_investor_user'] === 1 && user['is_registration_completed'] === 1? '/complete-registration' : '/dashboard';
            navigate(path);
        }
    }

    const TextField = styled(TextFieldUI)(({ theme }) => ({
        '.MuiFormLabel-root': {
            color: '#cccccc88',
            fontSize: '1rem',
            fontWeight: '400',
            top: '0px',
            left: '0px'
        },
        '& .MuiInputBase-input': {
            color: '#ffffff',
            borderRadius: '5px',
            fontSize: '1.1rem',
            padding: '5px 0',
            fontWeight: '300',
        },
        '& .MuiInputBase-root': {
            '&::before': {
                content: "''",
                borderBottom: '1px solid #222222',
                borderColor: '#ffffff'
            },
            '&::after': {
                content: "''",
                borderBottom: `1px solid #ffffff`
            },
        }
    }));

    const Link = styled(LinkUI)(({ theme }) => ({
        color: '#8d8d8d',
        textDecoration: 'none',
        fontWeight: '500',
        letterSpacing:'1px',
        textShadow:'0 0 3px rgba(0,0,0,1)',
        fontSize:'1rem',
        '&:hover': {
            cursor:'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    const Button = styled(ButtonUI)(({ theme }) => ({
        color: '#ffffff',
        textShadow:'0 0 3px rgba(0,0,0,0.6)',
        borderRadius:'10px',
        background: 'linear-gradient(to bottom right, #4cc6b9 50%, #0ab5d0)',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.contrastText,
        }
    }));

    return (
        <Grid
            container
            direction="column"
            justifyContent="end"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <Grid item sx={{ mb: 5 }}>
                <img src={'/images/ifund-logo-light.png'} width={150} alt={"Logo"} />
            </Grid>
            <Grid item sx={{ width: '85%', maxWidth:'400px!important' }} pb={8}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField
                            fullWidth
                            type="text"
                            id="username"
                            color={"secondary"}
                            ref={userRef}
                            variant={"standard"}
                            onBlur={handleUsernameChange}
                            onKeyDown={handleKeyDown}
                            defaultValue={username}
                            required
                            label="Username"
                            inputProps={{
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            helperText={response?.error?.data?.errors?.username}
                        />
                    </Grid>
                    <Grid item pt={1}>
                        <TextField
                            fullWidth
                            type="password"
                            id="password"
                            color={"secondary"}
                            variant={"standard"}
                            onBlur={handlePasswordChange}
                            onKeyDown={handleKeyDown}
                            defaultValue={password}
                            required
                            label="Password"
                            inputProps={{
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            helperText={response?.error?.data?.errors?.password}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={disabled}
                            color="secondary"
                            sx={{ width: '100%', mt:3, mb: 2 }}
                            className={'text-uppercase fs-5 p-2'}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Sign In
                        </Button>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Stack alignItems={"center"}>
                            <Link to={'/forgot-password'} className={'mb-3 bold d-block text-right'}>
                                Forgot Password?
                            </Link>
                            <Link to={'/register'}> Register Now</Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Toastr response={response} />
        </Grid>
    )
}


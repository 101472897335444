const CONST = {
    'SET_CONFIG':'TRANSACTION/SET_CONFIG',
    'RESET_ALL':'TRANSACTION/RESET_ALL',
    'SET_SELECTED_ITEM':'TRANSACTION/SET_SELECTED_ITEM',
    'FETCH_ITEM_SUCCESS':'TRANSACTION/FETCH_ITEM_SUCCESS',
    'FETCH_ITEM_FAILED':'TRANSACTION/FETCH_ITEM_FAILED',
    'FETCH_ITEMS_SUCCESS':'TRANSACTION/FETCH_ITEMS_SUCCESS',
    'FETCH_ITEMS_FAILED':'TRANSACTION/FETCH_ITEMS_FAILED',
    'ADD_ITEM_SUCCESS':'TRANSACTION/ADD_ITEM_SUCCESS',
    'ADD_ITEM_FAILED':'TRANSACTION/ADD_ITEM_FAILED',
    'UPDATE_ITEM_SUCCESS':'TRANSACTION/UPDATE_ITEM_SUCCESS',
    'UPDATE_ITEM_FAILED':'TRANSACTION/UPDATE_ITEM_FAILED',
    'APPROVE_ITEM_SUCCESS':'TRANSACTION/APPROVE_ITEM_SUCCESS',
    'APPROVE_ITEM_FAILED':'TRANSACTION/APPROVE_ITEM_FAILED',
    'UPDATE_ITEM_CHARGE_SUCCESS':'TRANSACTION/UPDATE_ITEM_CHARGE_SUCCESS',
    'DELETE_ITEM_SUCCESS':'TRANSACTION/DELETE_ITEM_SUCCESS',
    'DELETE_ITEM_FAILED':'TRANSACTION/DELETE_ITEM_SUCCESS',
}

export default CONST;
import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";
import {fetchRepayments, fetchRepaymentsSuccess} from "../../Repayments";


export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload: payload
});

export const resetPositions = (payload) => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedPosition = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchPositionSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchPositionFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchPosition = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/position/${id}`),
        [fetchPositionSuccess],
        [fetchPositionFailed]
    );
};

export const fetchPositionsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchPositionsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchPositions = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/position', { params: data }),
        [fetchPositionsSuccess],
        [fetchPositionsFailed],
    );
};


export const addPositionSuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload
});

export const addPositionFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload
});

export const addPosition = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`investor/position`, data),
        [addPositionSuccess],
        [addPositionFailed]
    );
};
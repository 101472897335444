import React from 'react';
import { useDomain } from '../Hooks/useDomain';
import { AdministrationRoutes } from '../Routing/AdministrationRoutes';
import { CorporateRoutes } from '../Routing/CorporateRoutes';
import { InvestorRoutes } from '../Routing/InvestorRoutes';
import { CommonRoutes } from '../Routing/CommonRoutes';

export const withPrivateRoutes = (Component) => {

    return () => {

        const subDomain = useDomain();

        let routes;

        switch (subDomain) {
            case 'admin':
            case 'office':
            case 'backoffice-dev':
                routes = <AdministrationRoutes />;
                break;
            case 'business':
            case 'business-dev':
                routes = <CorporateRoutes />;
                break;
            case 'investor':
            case 'investor-dev':
                routes = <InvestorRoutes />;
                break;
            default:
                routes = <CommonRoutes />;
                break;
        }
        return <Component routes={routes} />;
    };
};

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

/****************************************
 SET TASK CONFIG
 ****************************************/
export const setTaskConfig = (payload) => ({
    type: CONST.SET_TASK_CONFIG,
    payload,
});


/****************************************
 SET SERVICE CONFIG
 ****************************************/
export const setServiceConfig = (payload) => ({
    type: CONST.SET_SERVICE_REQUEST_CONFIG,
    payload,
});

/****************************************
 SET UPCOMING PAYMENT CONFIG
 ****************************************/
export const setUpcomingConfig = (payload) => ({
    type: CONST.SET_UPCOMING_PAYMENT_CONFIG,
    payload,
});

/****************************************
 SET OVERDUE PAYMENT CONFIG
 ****************************************/
export const setOverdueConfig = (payload) => ({
    type: CONST.SET_OVERDUE_PAYMENT_CONFIG,
    payload,
});

/****************************************
 SET OVERDUE PAYMENT CONFIG
 ****************************************/
export const setBusinessOverviewConfig = (payload) => ({
    type: CONST.SET_BUSINESS_OVERVIEW_CONFIG,
    payload,
});

/****************************************
 FETCH USER STATS
 ****************************************/
export const fetchStatsSuccess = (payload) => ({
    type: CONST.FETCH_STATS_SUCCESS,
    payload: payload
});

export const fetchStatsFailed = (payload) => ({
    type: CONST.FETCH_STATS_FAILED,
    payload: payload
});

export const fetchStats = ({ dispatch, business_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/dashboard/stats', { params: { business_id } }),
        [fetchStatsSuccess],
        [fetchStatsFailed]
    );
};

/****************************************
 FETCH MONTHLY STATS
 ****************************************/
export const fetchMonthlyStatsSuccess = (payload) => ({
    type: CONST.FETCH_MONTHLY_STATS_SUCCESS,
    payload: payload
});

export const fetchMonthlyStatsFailed = (payload) => ({
    type: CONST.FETCH_MONTHLY_STATS_FAILED,
    payload: payload
});

export const fetchMonthlyStats = ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account/monthly-stats', { params: { startDate: data.dates[0], endDate: data.dates[1], business_id: data.business_id }}),
        [fetchMonthlyStatsSuccess],
        [fetchMonthlyStatsFailed]
    );
};

/****************************************
 FETCH UPCOMING PAYMENTS
 ****************************************/
export const fetchUpcomingPaymentSuccess = (payload) => ({
    type: CONST.FETCH_UPCOMING_PAYMENTS_SUCCESS,
    payload: payload
})

export const fetchUpcomingPaymentFailed = (payload) => ({
    type: CONST.FETCH_UPCOMING_PAYMENTS_FAILED,
    payload: payload
})

export const fetchUpcomingPayment = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account', { params: data }),
        [fetchUpcomingPaymentSuccess],
        [fetchUpcomingPaymentFailed]
    );
}

/****************************************
 FETCH OVERDUE PAYMENTS
 ****************************************/
export const fetchAccountsSuccess = (payload) => ({
    type: CONST.FETCH_OVERDUE_PAYMENTS_SUCCESS,
    payload: payload
})

export const fetchAccountsFailed = (payload) => ({
    type: CONST.FETCH_OVERDUE_PAYMENTS_FAILED,
    payload: payload
})

export const fetchOverdueAccounts = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/account', { params: data }),
        [fetchAccountsSuccess],
        [fetchAccountsFailed]
    );
}

/****************************************
 FETCH BUSINESS OVERDUE
 ****************************************/
export const fetchBusinessOverviewSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESS_OVERVIEW_SUCCESS,
    payload: payload
})

export const fetchBusinessOverviewFailed = (payload) => ({
    type: CONST.FETCH_BUSINESS_OVERVIEW_FAILED,
    payload: payload
})

export const fetchBusinessOverview = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/overview', { params: data }),
        [fetchBusinessOverviewSuccess],
        [fetchBusinessOverviewFailed]
    );
}

/****************************************
 FETCH SERVICE REQUESTS
 ****************************************/
export const fetchServicesSuccess = (payload) => ({
    type: CONST.FETCH_SERVICES_SUCCESS,
    payload: payload
})

export const fetchServicesFailed = (payload) => ({
    type: CONST.FETCH_SERVICES_FAILED,
    payload: payload
})

export const fetchServices = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/service', { params: data }),
        [fetchServicesSuccess],
        [fetchServicesFailed]
    );
}

/****************************************
 * SET SELECTED TASKS
 ****************************************/
export const setSelectedTask = (payload) => ({
    type: CONST.SET_SELECTED_TASK,
    payload: payload
});

/****************************************
 * FETCH TASKS
 ****************************************/
export const fetchTasksSuccess = (payload) => ({
    type: CONST.FETCH_TASKS_SUCCESS,
    payload: payload
});

export const fetchTasksFailed = (payload) => ({
    type: CONST.FETCH_TASKS_FAILED,
    payload: payload
});

export const fetchTasks = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('task', {params:data}),
        [fetchTasksSuccess],
        [fetchTasksFailed]
    );
};


/****************************************
 * UPDATE EXPIRED DOCUMENT
****************************************/
export const updateExpiredDocumentSuccess = (payload) => ({
    type: CONST.UPDATE_EXPIRED_DOCUMENT_SUCCESS,
    payload: payload
});

export const updateExpiredDocumentFailed = (payload) => ({
    type: CONST.UPDATE_EXPIRED_DOCUMENT_FAILED,
    payload: payload
});

export const updateExpiredDocument = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('task/update-expired-document', data),
        [updateExpiredDocumentSuccess],
        [updateExpiredDocumentFailed]
    );
};

import * as React from 'react';
import {useEffect, } from "react";
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {BankDetailForm} from "../Forms/BankDetailForm";
import {Card, CardContent, CardHeader} from "@mui/material";
import {
    addBankDetail,
    clearErrors,
    proceed,
    setSelectedBankDetail,
    BankDetailDeleteModal
} from "../../index";
import Typography from "@mui/material/Typography";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Link} from "react-router-dom";
import {ShowAttachmentsModal} from "../Modals/ShowAttachmentsModal";

export const BankDetailCard = (props) => {

    const business = useSelector((store) => store.business);
    const documents = useSelector((store) => store.business.selected?.documents);
    let items = Object.keys(business.bankDetails).length > 0 ? business.bankDetails.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const documentLink = (document_type, value, id) => {

        const type = {'bank_id': 'bank_statement_doc',};

        if (documents && documents.length > 0) {
            let doc = documents.filter((doc) => doc.type === type[document_type] && doc.entity_id === id);

            if (doc.length === 1) {
                return (
                    <Link style={{ textDecoration: 'none' }} to={process.env.REACT_APP_BASE_URL + 'document/' + doc[0].document_id} target="_blank" rel="noopener noreferrer">
                        {value}
                    </Link>
                );
            } else if (doc.length > 1) {
                return (
                    <Typography
                        sx={{ cursor:'pointer', textDecoration:'underline', color:'#0d6efd' }}
                        onClick={() => dispatch(setModal({component: <ShowAttachmentsModal doc={doc} />, open:true}))}
                    >
                        {value}
                    </Typography>
                );
            }
        }

        return value;
    };

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addBankDetail({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedBankDetail(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<BankDetailForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<BankDetailDeleteModal />, open:true}))
        dispatch(setSelectedBankDetail(e.currentTarget.dataset.id));
    }

    //For document listing
    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.bank_detail_id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status) && auth.user.role_type === 'CLIENT') {
                return ['add-business-bankDetails'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-bank-details') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
            return false;
        },
        [business.status, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ];
            }

            return [];

        },[editable]
    );

    const columns = React.useMemo(
        () => [
            { field: 'bank_id', headerName:'Bank/Institution', type: 'string', flex:1, minWidth:250,
                renderCell: (params) => {
                    return documentLink('bank_id', params.row?.bank?.bank_name, params.id);
                }
            },
            { field: 'account_no', headerName:'Account no', type: 'string', flex:1, minWidth:200},
            { field: 'currency', headerName:'Currency', type: 'string',  flex:1, minWidth:80 },
            { field: 'rm_name', headerName:'RM Name', type: 'string', flex:1, minWidth:200 },
            { field: 'rm_email', headerName:'RM Email', type: 'string', flex:1, minWidth:200 },
            { field: 'rm_phone', headerName:'RM Phone', type: 'string', flex:1, minWidth:150 },
            { field: 'remarks', headerName:'Remarks', type: 'string', flex:1, minWidth:300 },
            { field: 'iban_no', headerName:'IBAN No', type: 'string', flex:1, minWidth:220 },
            { field: 'swift_code', headerName:'Swift Code', type: 'string', flex:1, minWidth:150 },
            { field: 'actions', flex:1, minWidth: 120, type: 'actions', headerName:'Action', getActions: (params) => actions(params)},
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Bank Details'}
                    action={ editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button> }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.bank_detail_id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={items || []} sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left:['bank_id'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        actions: editable(),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}
import * as React from 'react';
import { Card, CardHeader, CardActions, List as ListUI, CardContent, ListItem, ListItemText, TextField, Button, Grid, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateAccount} from "../../Redux/Action";
import Toastr from "../../../../../Components/Elements/Toastr";
import {setModal} from "../../../../Common/App";
import {ChangePasswordModal} from "../Modals/ChangePasswordModal";

export const AccountDetailsTab = () => {

    const dispatch = useDispatch();
    const [editMode, setEditMode] = React.useState(false);
    const profile = useSelector((store) => store.profile);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    const handleEditBtn = () => {
        setEditMode(!editMode);
    };

    const handleChange = (e) => {
        setData(data => ({
            ...data,
            [e.target.name] :e.target.value,
        }));
    }

    useEffect(() => {
        setData({
            email: profile?.selected?.email,
            mobile: profile?.selected?.mobile,
            phone: profile?.selected?.profile?.phone,
        });
    }, [])

    const List = styled(ListUI)({
        width: '100%',
        maxWidth: 360,
    });

    const handleSaveBtn = async () => {

        setDisabled(true);

        await updateAccount({dispatch, data}).then((res) => {
            setResponse(res);
            if(res['success']){
                setDisabled(false);
                setEditMode(false);
            }
        });
    }

    const handleCancelBtn = () => {
        // Reset data and set editMode to false
        setData(profile?.selected?.profile);
        setEditMode(false);
    };

    const handleChangePassword = () => {

        dispatch(setModal({component:<ChangePasswordModal />, open:true}));
    }
    console.log(response)
    return (
        <>
            <Card>
                <CardHeader title={"Account Details"} action={!editMode &&<Button onClick={handleEditBtn}>Edit</Button>} />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <List>

                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            defaultValue={data?.email}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.email}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Email" secondary={data?.email ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Mobile No"
                                            name="mobile"
                                            defaultValue={data?.mobile}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.mobile}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Mobile No" secondary={data?.mobile ?? "-"} />
                                    )}
                                </ListItem>
                                <ListItem>
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            label="Phone No"
                                            name="phone"
                                            defaultValue={data?.phone}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={profile?.error?.phone}
                                            onBlur={handleChange} />
                                    ) : (
                                        <ListItemText primary="Phone No" secondary={data?.phone ?? "-"} />
                                    )}
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <List>
                                {!editMode &&
                                <ListItem>
                                    <Button variant="contained" color="primary" size="small" disabled={disabled} onClick={handleChangePassword}>Change Password</Button>
                                </ListItem>
                                }
                            </List>
                        </Grid>
                    </Grid>

                </CardContent>
                {editMode &&
                    <CardActions sx={{p:2, justifyContent:'end'}}>
                        <Stack spacing={2} justifyContent={"space-between"} direction={"row"}>
                            <Button variant="contained" color="secondary" size="small" onClick={handleCancelBtn}>Cancel</Button>
                            <Button variant="contained" size="small" onClick={handleSaveBtn}>Save</Button>
                        </Stack>
                    </CardActions>
                }
            </Card>
            <Toastr response={response} />
        </>
    );
};

import React, {useEffect} from 'react'
import { Grid, Box} from "@mui/material";
import { Breadcrumbs } from "../../../../Components";
import {useDispatch,} from "react-redux";
import { fetchPermissions } from "../../Permissions";
import { RolePanel, setSelectedRole, ViewRole } from '../index';
export const Roles = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {

        dispatch(setSelectedRole({}))
        await fetchPermissions({dispatch, filters: {all:true, fields:['id','name','label','group']}});
    }

    return (
        <>
            <Grid container spacing={2} elevated={3}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/ROLES'} />
                    </Box>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} md={3}>
                        <RolePanel />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <ViewRole />
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}

import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Chip} from "@mui/material";
import {
    DataGridPro,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid-pro";
import {setConfig, fetchPositions} from "../../";
import {currencyFormatter} from "../../../../../Setup/Config";
import {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {RepaymentDetailsPanel} from "../Panels/RepaymentDetailsPanel";
import {fetchRepayment, fetchRepayments} from "../../../Repayments";

export const PositionTable = () => {

    const dispatch = useDispatch();
    const positions = useSelector((store) => store.positions);
    const filters = useSelector((store) => store.positions.config.filters);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchItems();
    },[filters]);

    const fetchItems = async () => {
        setIsLoading(true);
        await fetchPositions({dispatch, data:{...filters}}).then((res)=>{
            setResponse(res);
            setIsLoading(false);
        });
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <RepaymentDetailsPanel />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    const serviceNameMap = {
        "INVOICE_DISCOUNTING" : "Invoice Discounting",
        "RECEIVABLE_DISCOUNTING" : "Receivable Discounting",
    }

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} />
                ),
            },
            { field: 'business_id', headerName:'Business', type: 'string', width:250, valueGetter: (params) => params.row?.business?.name},
            { field: 'type', headerName:'Product', type: 'string', width: 220, valueGetter: (params) => serviceNameMap[params.row?.service?.service_type] || 'Unknown Product',},

            { field: 'amount', headerName:'Investment', type: 'string', width: 120, valueGetter: (params) => (params.row?.funding?.[0]?.total !== undefined && params.row?.funding?.[0]?.total !== null) ? "AED " + currencyFormatter.format(params.row?.funding?.[0]?.total ?? 0) : "-"},
            { field: 'profit', headerName:'Profit', type: 'string', width: 80, valueGetter: (params) => (params.row?.service?.profit_rate !== undefined && params.row?.service?.profit_rate !== null) ? params.row?.service?.profit_rate + " %" : "-"},
            { field: 'repaymentsSummary', headerName:'Repayments', type: 'string', width: 150},
        ],
        [],
    );

    return (
            <>

                <DataGridPro
                    autoHeight
                    getDetailPanelContent={getDetailPanelContent}
                    //getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelHeight={() => 'auto'}
                    getRowId={(row) => row.id}
                    columns={columns}
                    rows={positions?.list || []}
                    sx={{ border:'none'}}
                    pagination={false}
                    paginationMode="server"
                    paginationModel={filters}
                    rowCount={positions?.config?.total ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    hideFooterRowCount={true}
                    hideFooter={true}
                    onPaginationModelChange={(params) => dispatch(setConfig({filters: {...filters, page:params.page, pageSize: params.pageSize}}))}
                    onPageChange={(newPageNo) => dispatch(setConfig({filters: {...filters, pageNo: newPageNo}}))}
                    onPageSizeChange={(newPageSize) => dispatch(setConfig({filters: {...filters, pageSize: newPageSize}}))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'group', sort: 'asc' }],
                        },
                        pinnedColumns: {  right: ['repaymentsSummary'] },
                        pagination: { paginationModel: { ...filters } }
                    }}
                />
            </>
        );
}


const CustomDetailPanelToggle = (props) => {
    const dispatch = useDispatch();
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );


    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    // Fetch data when the panel is expanded
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                if (isExpanded) {
                    // Call the fetchRepayment function when the panel is expanded and no detail is available
                    await fetchRepayments({dispatch, data:{listing_id: id, pageSize:0}}); // Assuming id is the identifier for the row whose details need to be fetched
                }
            } catch (error) {
                console.error("Error fetching repayment:", error);
                // Handle error here (e.g., show an error message)
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isExpanded, hasDetail, id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}


CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";


export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload: payload
});

export const resetRequests = (payload) => ({
    type: CONST.RESET_ALL,
    payload: null
});

export const setSelectedRequest = (payload) => ({
    type: CONST.SET_SELECTED_ITEM,
    payload: payload
});

export const fetchRequestSuccess = (payload) => ({
    type: CONST.FETCH_ITEM_SUCCESS,
    payload: payload
});

export const fetchRequestFailed = (payload) => ({
    type: CONST.FETCH_ITEM_FAILED,
    payload: payload
});

export const fetchRequest = async ({ dispatch, id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`investor/request/${id}`),
        [fetchRequestSuccess],
        [fetchRequestFailed]
    );
};

export const fetchRequestsSuccess = (payload) => ({
    type: CONST.FETCH_ITEMS_SUCCESS,
    payload: payload
});

export const fetchRequestsFailed = (payload) => ({
    type: CONST.FETCH_ITEMS_FAILED,
    payload: payload
});

export const fetchRequests = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('investor/request', { params: data }),
        [fetchRequestsSuccess],
        [fetchRequestsFailed]
    );
};


export const addRequestSuccess = (payload) => ({
    type: CONST.ADD_ITEM_SUCCESS,
    payload: payload
});

export const addRequestFailed = (payload) => ({
    type: CONST.ADD_ITEM_FAILED,
    payload: payload
});

export const addRequest = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`investor/request`, data),
        [addRequestSuccess],
        [addRequestFailed]
    );
};

export const updateRequestSuccess = (payload) => ({
    type: CONST.UPDATE_ITEM_SUCCESS,
    payload: payload
});

export const updateRequestFailed = (payload) => ({
    type: CONST.UPDATE_ITEM_FAILED,
    payload: payload
});

export const updateRequest = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`request/${data['id']}`, data),
        [updateRequestSuccess],
        [updateRequestFailed]
    );
};

export const approveRequestSuccess = (payload) => ({
    type: CONST.APPROVE_ITEM_SUCCESS,
    payload: payload
});

export const approveRequestFailed = (payload) => ({
    type: CONST.APPROVE_ITEM_FAILED,
    payload: payload
});

export const approveRequest = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch(`request/${data['id']}/approve`, data),
        [approveRequestSuccess],
        [approveRequestFailed]
    );
};


import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosPublic = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json', 'Access':'1000' },
    withCredentials: true,
});


// Response interceptor
axiosPrivate.interceptors.response.use(
    response => response,
    async (error) => {
        // if (error.response.status === 401) {
        //     // Here you can implement logic to refresh the token
        //     try {
        //         // Example of refreshing token
        //         await axiosPublic.post('/refresh-token', {
        //             // Add any necessary payload here
        //             // For example, if the server requires user credentials for refreshing the token:
        //             username: 'example_username',
        //             password: 'example_password'
        //         });
        //         // Retry the original request after refreshing the token
        //         const originalRequest = error.config;
        //         return axiosPrivate(originalRequest);
        //     } catch (refreshError) {
        //         // Handle refresh token error
        //         console.error('Failed to refresh token:', refreshError);
        //         // Redirect to login page or show error message
        //     }
        // }

        // You can handle errors here
        return Promise.reject(error);
    }
);

export default axiosPrivate;
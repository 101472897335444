import React, {useEffect} from 'react'
import {CardContent, CardHeader,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, } from "@mui/x-data-grid-pro";
import moment from "moment";
import {Panel} from "../../../../../Components";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import {useNavigate} from "react-router-dom";
import {fetchDefaultPayment, setDefaultPaymentConfig} from "../../index";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {currencyFormatter} from "../../../../../Setup/Config";
import Typography from "@mui/material/Typography";
import {setModal} from "../../../../Common/App";
import {useTheme} from "@mui/material/styles";
import {ShowDefaultContractsModal} from "../Modals/ShowDefaultContractsModal";

export const DefaultPaymentPanel = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const items = useSelector((store) => store.dashboard?.defaultPayments);
    const filters = useSelector((store) => store.dashboard?.defaultPayments?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.dashboard?.defaultPayments?.config?.pagination);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();
    }, [pagination]);

    const fetchData = async () => {

        await fetchDefaultPayment({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/dashboard/' + id);
        },
        [],
    );

    const columns = [
        { field: 'default_date', headerName: 'Default Date', headerAlign:'center', align:'center', type: 'date', flex:1, maxWidth:120,
            valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY')
        },
        { field: 'name', headerName: 'Business Name', headerAlign:'left', align:'left', type: 'month', flex:1,
            renderCell: (params) => {
                return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.id)}>{params.value}</Typography>
            }
        },
        { field: 'default_amount', headerName: 'Amount', type: 'number', flex:1, maxWidth:120,
            valueGetter: (params) => currencyFormatter.format(params.row?.stats?.outstanding)
        },
        {
            field: 'actions', headerName: 'Actions', headerAlign:'center', align:'center', type: 'actions', flex:1, maxWidth:70,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PageviewIcon sx={{zIndex:-1}} />}
                    label="View"
                    onClick={() => dispatch(setModal({component:<ShowDefaultContractsModal data={params.row} />, open:true}))}
                />
            ],
        }
    ];

    return (
        <>
            <Panel>
                <CardHeader titleTypographyProps={{fontSize:'1rem'}} title={'Default Payments'} />
                <CardContent sx={{p:0, minHeight:'300px'}}>
                    <DataGridPro
                        autoHeight={false}
                        getRowId={(row) => row.business_id}
                        columns={columns}
                        rows={items?.list || []}
                        sx={{ border:'none'}}
                        rowThreshold={0}
                        pagination
                        paginationMode="server"
                        paginationModel={pagination}
                        rowCount={items?.config?.rowCount ?? 0}
                        pageSizeOptions={[5]}
                        loading={isLoading}
                        onPaginationModelChange={(params) => dispatch(setDefaultPaymentConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                        initialState={{
                            pinnedColumns: { right: ['actions'] },
                            columns:{
                                columnVisibilityModel:{
                                    business: auth.user.role_type !== 'CLIENT',
                                }
                            },
                            pagination: { paginationModel: { ...pagination } },
                        }}
                    />
                </CardContent>
            </Panel>
        </>
    );
}
import React from 'react'
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import { Breadcrumbs } from "../../../../Components";
import { useDispatch } from "react-redux";
import { fetchPermission, setSelectedPermission } from "../Redux/Action";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { PermissionTable, AddPermission, EditPermission, DeletePermission,  } from "../index";
import { setRightDrawer, setModal } from "../../../Common/App";

export const Permissions = () => {

    const dispatch = useDispatch();

    const addItem = () => {
        dispatch(setRightDrawer({component:<AddPermission />, open:true}))
    };

    const editItem = React.useCallback(
        (id) => () => {
            fetchPermission({dispatch, id});
            dispatch(setRightDrawer({component:<EditPermission />, open:true}))
        },
        [],
    );

    const deleteItem = React.useCallback(

        (id) => () => {
            dispatch(setSelectedPermission(id));
            dispatch(setModal({component:<DeletePermission />, open:true}))
        },
        [],
    );

    return (
        <>
            <Grid container spacing={2} elevated={3}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/PERMISSIONS'} />
                        <Button variant="contained" onClick={addItem}>Add Permission</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={"Permissions"} />
                        <CardContent sx={{p:0}}>
                            <PermissionTable editItem={editItem} deleteItem={deleteItem} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

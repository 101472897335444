import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    FormControlLabel,
    Grid, Stack, Typography,
    TextField,
    InputLabel, Select, FormHelperText,
    FormControl
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {setSelectedRole, updateRole} from "../../Redux/Action";
import { setModal } from "../../../../Common/App";
import { AddRoleModal } from "../../index";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";

export const ViewRole = () => {

    const dispatch = useDispatch();
    const item = useSelector((store) => store.roles.selected);
    const permissions = useSelector((store) => store.permissions.list);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});
    const [selectAll, setSelectAll] = useState({});
    const [checkedPermissions, setCheckedPermissions] = useState({});

    useEffect(() => {
        setData(item);
        const initialChecked = {};
        const initialSelectAll = {};
        permissions.forEach(permission => {
            initialChecked[permission.id] = item?.permissions?.includes(permission.id) || false;
            initialSelectAll[permission.group] = false;
        });
        setCheckedPermissions(initialChecked);
        setSelectAll(initialSelectAll);
    }, [item, permissions]);

    const handleChange = (e) => {
        setDisabled(false);
        setData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handlePermissionChange = (permissionId) => {

        setDisabled(false);
        setCheckedPermissions(prevState => ({
            ...prevState,
            [permissionId]: !prevState[permissionId]
        }));
    };

    const handleSelectAll = (groupName) => {
        const newCheckedPermissions = { ...checkedPermissions };
        const newSelectAll = { ...selectAll };
        permissions.forEach(permission => {
            if (permission.group === groupName) {
                newCheckedPermissions[permission.id] = !newSelectAll[groupName];
            }
        });
        setCheckedPermissions(newCheckedPermissions);
        newSelectAll[groupName] = !newSelectAll[groupName];
        setSelectAll(newSelectAll);
    };

    const handleSubmit = async () => {

        setDisabled(true);
        const selectedPermissions = Object.keys(checkedPermissions).filter(id => checkedPermissions[id]);
        const res = await updateRole({ dispatch, data: { ...data, permissions: selectedPermissions } });
        setResponse(res);
    };

    const groupedPermissions = permissions.reduce((acc, permission) => {
        if (!acc[permission.group]) {
            acc[permission.group] = [];
        }
        acc[permission.group].push(permission);
        return acc;
    }, {});

    return (
        <>
            {item ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={"Role Details"} />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Role Name"
                                            name="name"
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            size="large"
                                            value={data?.name}
                                            sx={{ mb: 2 }}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={item?.error?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{ width:'100%' }}>
                                            <InputLabel id="label-Title">Department</InputLabel>
                                            <Select
                                                fullWidth
                                                label="Verify Business"
                                                name="department"
                                                variant="outlined"
                                                value={data?.department ?? ""}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                <MenuItem value="">Select</MenuItem>
                                                <MenuItem value="Administration">Administration</MenuItem>
                                                <MenuItem value="Chairman">Chairman</MenuItem>
                                                <MenuItem value="CEO">CEO</MenuItem>
                                                <MenuItem value="BOD">Board of Directors</MenuItem>
                                                <MenuItem value="Business">Business</MenuItem>
                                                <MenuItem value="Credit">Credit</MenuItem>
                                            </Select>
                                            <FormHelperText></FormHelperText>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Role Description"
                                            name="description"
                                            onChange={(e) => handleChange(e)}
                                            variant="outlined"
                                            size="large"
                                            value={data?.description}
                                            sx={{ mb: 2 }}
                                            inputlabelprops={{ shrink: true }}
                                            helperText={item?.error?.description}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disabled}>Save</Button>
                                <Button variant="contained" color="secondary" onClick={ () => dispatch(setSelectedRole(null))}>Cancel</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {Object.entries(groupedPermissions).map(([groupName, permissionsInGroup]) => (
                        <Grid item xs={12} key={groupName}>
                            <Card>
                                <CardHeader
                                    title={groupName}
                                    action={
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={selectAll[groupName] ? "Deselect All" : "Select All"}
                                            onChange={() => handleSelectAll(groupName)}
                                            checked={selectAll && permissionsInGroup.every(permission => checkedPermissions[permission.id])}
                                        />
                                    }
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        {permissionsInGroup.map((permission) => (
                                            <Grid item xs={12} md={6} key={permission.id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkedPermissions[permission.id]}
                                                            onChange={() => handlePermissionChange(permission.id)}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={"Role Details"} />
                            <CardContent sx={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom:'1rem!important' }}>
                                <Stack direction={"column"} spacing={2}>
                                    <Typography>Please select a role from the list</Typography>
                                    <Button variant={"contained"} color={"primary"} onClick={() => dispatch(setModal({ component: <AddRoleModal />, open: true }))}>
                                        Add New Role
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

import {useDispatch} from "react-redux";
import {Link as LinkUI, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {fetchAppConfig, setBlockUI, setModal, setRightDrawer} from "../../../App";
import {doLogin} from "../../Redux/Action";
import {styled} from "@mui/material/styles";
import {Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";
import {useDomain} from "../../../../../Hooks/useDomain";
import forge from 'node-forge';
import {publicKeyPem} from "../../../../../Setup/Keys/public";


export const LoginForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef();
    const subDomain = useDomain();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        dispatch(setBlockUI({open:false}));
        userRef.current.focus();
    }, [])

    const encryptPassword = (password) => {
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        const encryptedPassword = publicKey.encrypt(password, 'RSA-OAEP', {
            md: forge.md.sha256.create()
        });
        return forge.util.encode64(encryptedPassword);
    };

    const handleSubmit = async () => {

        const encryptedPassword = encryptPassword(password);
        setPassword(encryptedPassword)
        const res = await doLogin({dispatch, data:{ username, password:encryptedPassword }});
        setResponse(res);

        if(res?.error?.status === 429){
            setDisabled(true);
        }

        if (res['success']) {
            const user = res['data']['data'];

            dispatch(setBlockUI({ open: false, message: '' }));
            dispatch(setModal({ open: false, component: null }));
            dispatch(setRightDrawer({ open: false, component: null }));

            await fetchAppConfig({ dispatch })
            const path = user['is_investor_user'] === 1 && user['is_registration_completed'] === 1? '/complete-registration' : '/dashboard';
            navigate(path);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const Link = styled(LinkUI)(({ theme }) => ({
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontSize:'1.2rem',
        '&:hover': {
            cursor:'pointer',
            color: theme.palette.primary.dark,
        }
    }))

    return (
        <Stack spacing={3} sx={{minWidth:400}}>

            <Typography variant={"h3"} sx={{fontWeight:'300', fontSize:40, fontFamily:'Open Sans'}} color={"primary"}>Dive in</Typography>

            <>
                <TextField
                    type="text"
                    id="username"
                    ref={userRef}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                    value={username}
                    required
                    label="Username"
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                        style: {fontSize: 20}
                    }}
                />
                <TextField
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    onKeyDown={handleKeyDown}
                    ref={userRef}
                    required
                    label="Password"
                    inputProps={{
                        form: {
                            autocomplete: 'off',
                        },
                        style: {fontSize: 20, letterSpacing:'3px'}
                    }}
                />

            </>

            <Button disabled={disabled} color="primary" sx={{ width:'100%', fontSize:'15px', mb:2}} className={' text-capitalize p-3'} variant="contained" onClick={ handleSubmit }>Sign In</Button>

            <Stack direction={"row"} spacing={2} justifyContent="space-between">
                <Link to={'/forgot-password'} className={'mb-3 d-block text-right'}>Forgot Password</Link>
                { !['admin', 'backoffice-dev', 'office'].includes(subDomain) && <Link to={'/register'}>Register Now</Link> }
            </Stack>
            <Toastr response={response} />
        </Stack>
    )
}
import React, {useState} from 'react'
import {TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { returnInvoice } from "../../index";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";

export const ReturnInvoiceModal = () => {

    const dispatch = useDispatch();
    const invoice = useSelector(state => state.invoices.selected);
    const [disabled, setDisabled] = useState(false);
    const [reason, setReason] = useState();
    const [response, setResponse] = useState({});
    const handleSubmit = async () => {
        setDisabled(true);

        let data = {
            'status': 'RETURNED',
            'reason':reason,
            'invoice_id': invoice.invoice_id
        }
        await returnInvoice({dispatch, data}).then((res) => {
            if(res['error']){
                setDisabled(false);
                setResponse(res);
            }else{
                dispatch(setModal({component:null, open:false}));
            }
        });
    };
    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setDisabled(e.target.value === '');
    }

    return(
        <>
            <DialogTitle>Return Invoice</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure, you want to Return this invoice?
                </DialogContentText>
                <TextField
                    placeholder={"Reason for Return"}
                    fullWidth
                    required
                    onBlur={handleReasonChange}
                    type={"text"}
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Return Invoice</Button>
            </DialogActions>
        </>
    )
}


import * as React from "react";
import {Card as CardUI, Stack, Typography, Card, CardHeader, CardContent, Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {currencyFormatterDecimal} from "../../../../../Setup/Config";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchStatementSummary} from "../../Redux/Action";
import {useParams} from "react-router-dom";

export const StatementSummaryPanel = () => {

    const dispatch = useDispatch();
    const {year, month} = useParams();
    const summary = useSelector((store) => store.statements.summary);
    const filters = useSelector((store) => store.transactions.config.filters);

    useEffect(() => {
        fetchData();
    },[filters]);

    const fetchData = async () => {
        await fetchStatementSummary({dispatch, data:{...filters}});
    }

    const Card = styled(CardUI)({
        '& .MuiCardHeader-root': {
            backgroundColor: '#f8f8f8',
            borderBottom: '1px solid #e0e0e0',
            '& span':{
                fontSize:'0.75rem'
            },
        },
        '& .MuiCardContent-root': {
            '& .MuiTypography-root': {
                fontSize: '1.2rem',
                fontWeight: 600,
            }
        }
    })

    return (
            <Grid container justifyContent={"space-between"} spacing={2}>
                <Grid item sm={3} xs={12}>
                    <Card>
                        <CardHeader title={"Opening Balance"} />
                        <CardContent>
                            <Stack>
                                <Typography variant={"h4"}>AED {currencyFormatterDecimal.format(summary?.opening_balance)}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Card>
                        <CardHeader title={"Total Credits"} />
                        <CardContent>
                            <Stack>
                                <Typography variant={"h4"}>AED {currencyFormatterDecimal.format(summary?.total_credits)}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Card>
                        <CardHeader title={"Total Debits"} />
                        <CardContent>
                            <Stack>
                                <Typography variant={"h4"}>AED {currencyFormatterDecimal.format(parseFloat(summary?.total_debits))}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Card>
                        <CardHeader title={"Closing Balance"} />
                        <CardContent>
                            <Stack>
                                <Typography variant={"h4"}>AED {currencyFormatterDecimal.format(summary?.closing_balance)}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
    );
}
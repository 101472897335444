import * as React from 'react';
import {Card as CardUI, Card, CardContent, CardHeader, List as ListUI, ListItem, ListItemText} from "@mui/material";

import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";

export const CompanyInfoPanel = () =>{

    const business = useSelector(state => state.listings.selected.business);

    const Card = styled(CardUI)({
        boxShadow:'0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
        border:'none',
        '& .MuiCardHeader-root':{
            borderBottom:'none',
            paddingBottom:'0',
            '& .MuiCardHeader-content':{
                '& .MuiCardHeader-title':{
                    fontSize:'1.1rem',
                },
                '& .MuiCardHeader-subheader p':{
                    fontSize:'.70rem'
                }
            },
        }
    });

    const List = styled(ListUI)({
        padding:0,
        '& .MuiListItem-root':{
            padding:0,
            '& .MuiListItemText-root':{
                '& span':{
                    fontSize:'1rem',
                    fontWeight:'bold',
                    marginBottom:'10px'
                },
                '& p':{
                    fontSize:'0.95rem',
                    textAlign:'justify',
                    lineHeight:'1.5',
                    marginBottom:'0!important'
                }
            }
        }
    });

    return (
        <>
            <Card>
                <CardHeader title={"Company Information"} />
                <CardContent>
                    <List>
                        <ListItem>
                            <ListItemText primary={"Introduction"} secondary={ business.name + " is an innovative technology solutions provider based in the U.A.E. NNTC delivers transformational projects combining best-of-breed solutions from the most advanced technology areas: video analytics, bespoke AI applications, VR and AR, automated UAV inspections, cyber-security, and hologram technology. Recognizable NNTC products include Health and Safety VR Training Simulators, Cybersecurity Software, Biometric KYC control for customer onboarding, Focus Video Analytics for malls, Drone-based and AI inventory solutions, and 3D Hologram tables. NNTC completes custom projects for enterprise customers, solving unique tasks by applying artificial intelligence where no out-of-the-shelf product can be applied. NNTC’s AI-powered custom projects stand out in the region and have helped optimize several business processes."} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={"Who are we"} secondary={business.name + " team of experts brings together engineers, programmers, and consultants to employ the innovative technologies created at the firm. The NNTC team has also implemented numerous VR and AR projects in the past that has helped businesses which encounter spatial, technological and resource constraints succeed. With 100+ completed solutions for various industries, 20+ projects in MENA and Asia Pacific regions, and 8 technology partners, NNTC aims to become a leader in the market for innovative turnkey solutions. NNTC is partnered with CROC, and together both organisations implemented VR training at Dubai Municipality’s Treatment Plants in Jabel Ali. Dubai specifically is increasingly embracing Smart City applications and a digital-first attitude for the efficiency, safety and sustainability of technology into infrastructure. This vision aligns well with the mission for growth at NNTC."} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={"Why seeking finance"} secondary={"Beehive funding will be utilized for assistant on working capital, supplier payment and hiring more staff."} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={"Directors / Officers (residence)"} secondary={"Director; Dmitry Doshchanyy (United Arab Emirates)"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={"Shareholders (residence)"} secondary={"Boris Ioffe (United Arab Emirates)"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={"Security held"} secondary={"Personal Guarantee, Security Cheque"} />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </>
    )
}


import React from "react";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {deleteRole} from "../../index";
import {setModal} from "../../../../Common/App";


export const RoleAssignedUserModal = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.roles);

    return(
        <>
            <DialogTitle>Users</DialogTitle>
            <DialogContent sx={{ width:500}}>
                <DialogContentText>
                    List of all the users assigned to this role will be displayed here.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Okay</Button>
            </DialogActions>
        </>
    )
}